import React from 'react';
import { Grid, Typography, styled } from '@mui/material';

import { StoreAssetUpdateImageModal, StoreAssetDeleteImageModal } from 'src/modals/storeAssets';

import { AssetInfoUpdateForm } from './AssetInfoUpdateForm';
import { AssetImageUpdateBox } from './AssetImageUpdateBox';

const StyledContainerGrid = styled(Grid)(({ theme }) => ({
  maxWidth: 1280,
  margin: '0 auto',
}));

const StoreAssetManagementDetailsTab: React.FC = () => {
  return (
    <StyledContainerGrid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Details</Typography>
      </Grid>

      <Grid item xs={6}>
        <AssetInfoUpdateForm />
      </Grid>

      <Grid item xs={6}>
        <AssetImageUpdateBox />
      </Grid>

      <StoreAssetUpdateImageModal />
      <StoreAssetDeleteImageModal />
    </StyledContainerGrid>
  );
};

export { StoreAssetManagementDetailsTab };
