import React from 'react';

import { HtmlRenderer, TableColumn, TableData } from '@vizsla/components';
import { DateFormatPatterns } from '@vizsla/constants';
import { formatDate } from '@vizsla/utils';
import { NO_APPLY_LABEL } from '@vizsla/constants';
import { UserAvatar } from '@vizsla/components';

import { UserAvatarFullName } from 'src/components/userAvatarFullName';

export function MessageTableView({ messages, pagination, loading }) {
  const columns: Array<TableColumn> = [
    {
      title: 'Name',
      key: 'name',
      render: (_, row) => {
        if (!row?.createdBy) return NO_APPLY_LABEL;

        return <UserAvatarFullName user={row.createdBy} fullName={row.createdBy.fullName} />;
      },
    },
    {
      title: 'Date',
      key: 'date',
      render: (_, row) => {
        return formatDate(row.createdAt, DateFormatPatterns.shortMonthDayYear);
      },
    },
    {
      title: 'Message',
      key: 'message',
      render: (_, row) => {
        return <HtmlRenderer html={row?.body as string} />;
      },
    },
  ];

  return (
    <TableData dataSource={messages} columns={columns} pagination={pagination} loading={loading} />
  );
}
