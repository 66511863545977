import _ from 'lodash';

import { User } from '@vizsla/graphql';

import { CampaignStatus } from 'src/types/campaign';

export const mapUsersList = (users?: Array<User>) => {
  return (
    users?.map(user => {
      const campaigns = _.uniqBy(
        user.attendees?.items,
        attendee => attendee.experience?.campaign?.id,
      ).filter(attendee => !_.isNil(attendee.experience?.campaign?.id)).length;

      const totalRaised = _.sumBy(user.attendees?.items, attendee => attendee.totalRaised || 0);
      const teamName = user.attendees?.items[0]?.teamMembership?.team?.name;

      return {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        avatar: user.avatar,
        campaigns,
        totalRaised,
        teamName,
      };
    }) || []
  );
};

export const mapDonorsList = (users?: Array<User>) => {
  return (
    users?.map(user => {
      const campaigns = _.uniqBy(user.donors?.items, donor => donor.campaign?.id).filter(
        donor => !_.isNil(donor.campaign?.id),
      ).length;

      const totalRaised = _.sumBy(user.attendees?.items, attendee => attendee.totalRaised || 0);
      const teamName = user.attendees?.items[0]?.teamMembership?.team?.name;

      return {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        avatar: user.avatar,
        campaigns,
        totalRaised,
        teamName,
      };
    }) || []
  );
};

export const mapUserStatistics = (user: User) => {
  const attendeesUniqueCampaings = _.uniqBy(
    user.attendees?.items,
    attendee => attendee.experience?.campaign?.id,
  );

  const activeCampaingsCount = attendeesUniqueCampaings.filter(
    attendee => attendee.experience?.campaign?.campaignStatus === CampaignStatus.live,
  ).length;

  const totalCampaignsCount = attendeesUniqueCampaings.length;

  const totalRaised = _.sumBy(user.attendees?.items, attendee => attendee.totalRaised || 0);

  return {
    activeCampaingsCount,
    totalCampaignsCount,
    totalRaised,
    totalDonorsCount: user.donors?.count,
  };
};
