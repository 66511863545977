import React from 'react';
import { Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { formatDate } from '@vizsla/utils';
import { UserAvatarFullNameInline } from '@vizsla/components';
import { DateFormatPatterns, NO_APPLY_LABEL } from '@vizsla/constants';

import { ORDERS_BY_DONOR } from 'src/graphql/orders';

import {
  Container,
  Content,
  ContentLoading,
  Paragraph,
  Subtitle,
  Title,
  Wrapper,
} from './TransactionsAttendee.style';
import { TransactionsCardsLayout } from '../../TransactionsCardLayout';

const CIRCULAR_PROGRESS_SIZE = 48;
export function TransactionsAttendeeCard() {
  const { orderId } = useParams();
  const { data: dataDonor } = useQuery(ORDERS_BY_DONOR, {
    variables: {
      orderId,
    },
  });

  const attendee = React.useMemo(
    () => dataDonor?.order?.registrationOptionFulfillment?.items[0].attendee.user,
    [dataDonor],
  );

  const attendeeJoinedIn = React.useMemo(
    () => formatDate(attendee?.createdAt, DateFormatPatterns.shortMonthDayYear),
    [attendee?.createdAt],
  );

  if (!attendee) {
    return (
      <TransactionsCardsLayout>
        <Container>
          <ContentLoading>
            <Skeleton variant="text" width={288} height={35} />

            <Wrapper>
              <Skeleton
                variant="circular"
                width={CIRCULAR_PROGRESS_SIZE}
                height={CIRCULAR_PROGRESS_SIZE}
              />
              <Skeleton variant="text" width={80} height={32} />
            </Wrapper>
            <Skeleton variant="rectangular" width={143} height={64} />
          </ContentLoading>
        </Container>
      </TransactionsCardsLayout>
    );
  }

  return (
    <TransactionsCardsLayout active>
      <Container>
        <Wrapper>
          <Title variant="h4">Attendee</Title>
        </Wrapper>
        <Wrapper>
          <UserAvatarFullNameInline user={attendee} showEmail />
        </Wrapper>
        <Content>
          <Wrapper>
            <Paragraph variant="subtitle2">Joined in:</Paragraph>
            <Subtitle> {attendeeJoinedIn}</Subtitle>
          </Wrapper>
          <Wrapper>
            <Paragraph variant="subtitle2">Gender:</Paragraph>
            <Subtitle> {attendee?.gender ?? NO_APPLY_LABEL}</Subtitle>
          </Wrapper>
          <Wrapper>
            <Paragraph variant="subtitle2">Work:</Paragraph>
            <Subtitle> {attendee?.companyName ?? NO_APPLY_LABEL}</Subtitle>
          </Wrapper>
        </Content>
      </Container>
    </TransactionsCardsLayout>
  );
}
