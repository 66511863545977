import React from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ExperienceLandingElementProps } from 'src/providers/campaign/ThankYouLanding.types';

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontSize: '18px',
  },
  title: {
    fontSize: '26px',
  },
}));

export const QuestionDonation: React.FC<ExperienceLandingElementProps> = ({
  experience,
  theme,
}) => {
  const { accentColor } = theme;
  const isHelpTitleVisible = theme?.isHelpTitleVisible ?? true;
  const isHelpDescriptionVisible = theme?.isHelpDescriptionVisible ?? true;

  const classes = useStyles({ accentColor });
  return (
    <Grid>
      {isHelpTitleVisible && (
        <Typography variant="h4" textAlign="center" marginBottom={1} className={classes.title}>
          Need help or have question about your donation?
        </Typography>
      )}
      {isHelpDescriptionVisible && (
        <Grid container>
          <Grid item xs={12}>
            <Typography>
              {`If you have any question, contact ${experience?.campaign?.contactEmail} or call ${experience?.campaign?.contactPhone?.number}`}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
