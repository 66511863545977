import React from 'react';
import { Grid, Typography } from '@mui/material';
import { FormSpy } from 'react-final-form';

import { DateFormatPatterns } from '@vizsla/constants';
import { PaletteColor } from '@vizsla/theme';
import { Field, NumberField, DateField, GridDefaultPadding } from '@vizsla/components';

import { TicketingOptionFormState } from 'src/constants/validationSchemas/ticketingOption';
import { SwitchSectionField } from 'src/components/forms';

const AGE_SECTION_FIELDS = ['ageRequirement', 'ageRequirementAsOfDate'];
const TICKETING_LIMITS_SECTION_FIELDS = ['attendeesPerTicket', 'maximumPerOrder'];
const ATTENDEE_CAP_SECTION_FIELDS = ['maximumAttendees'];
const GROUP_TICKETS_SECTION_FIELDS = ['minimumGroupTickets', 'maximumGroupTickets'];

export const TicketingOptionCreatePeopleStep: React.FC = () => {
  return (
    <FormSpy subscription={{ values: true, errors: true }}>
      {({ values, errors }) => {
        return (
          <Grid container mb={2} spacing={2}>
            <GridDefaultPadding container item xs={12}>
              <Grid item xs={12}>
                <Typography variant="h1" align="center">
                  Attendee Settings
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" align="center" color={PaletteColor.GrayText}>
                  Manage age requirements and attendees capacity
                </Typography>
              </Grid>
            </GridDefaultPadding>

            <GridDefaultPadding item xs={12}>
              <SwitchSectionField
                name={TicketingOptionFormState.AgeRequirementEnabled}
                heading="Age Requirement"
                fieldsToResetOnUncheck={AGE_SECTION_FIELDS}
              >
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={4}>
                    <Field
                      required
                      name="ageRequirement"
                      label="Age Requirement"
                      disabled={!values.ageRequirementEnabled}
                      component={NumberField}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Field
                      required
                      name="ageRequirementAsOfDate"
                      label="As of Date"
                      disabled={!values.ageRequirementEnabled}
                      component={DateField}
                      inputFormat={DateFormatPatterns.shortMonthDayYear}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </SwitchSectionField>
            </GridDefaultPadding>

            <GridDefaultPadding item xs={12}>
              <SwitchSectionField
                name={TicketingOptionFormState.TicketingLimitsEnabled}
                heading="Ticketing Limits"
                tooltip="Enable this section to be able to set ticketing limits"
                fieldsToResetOnUncheck={TICKETING_LIMITS_SECTION_FIELDS}
              >
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={4}>
                    <Field
                      required
                      name="attendeesPerTicket"
                      label="Attendees per Ticket"
                      disabled={!values.ticketingLimitsEnabled}
                      component={NumberField}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Field
                      required
                      name="maximumPerOrder"
                      label="Maximum per Order"
                      disabled={!values.ticketingLimitsEnabled}
                      component={NumberField}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </SwitchSectionField>
            </GridDefaultPadding>

            <GridDefaultPadding item xs={12}>
              <SwitchSectionField
                name={TicketingOptionFormState.AttendeeCapEnabled}
                heading="Attendee Cap"
                fieldsToResetOnUncheck={ATTENDEE_CAP_SECTION_FIELDS}
              >
                <Grid item xs={4}>
                  <Field
                    required
                    name="maximumAttendees"
                    label="Maximum Attendees"
                    disabled={!values.attendeeCapEnabled}
                    component={NumberField}
                    fullWidth
                  />
                </Grid>
              </SwitchSectionField>
            </GridDefaultPadding>

            <GridDefaultPadding item xs={12}>
              <SwitchSectionField
                name={TicketingOptionFormState.GroupTicketsEnabled}
                heading="Group Tickets"
                tooltip="Enable this section to allow group tickets"
                fieldsToResetOnUncheck={GROUP_TICKETS_SECTION_FIELDS}
              >
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={4}>
                    <Field
                      required
                      name="minimumGroupTickets"
                      label="Minimum"
                      disabled={!values.groupTicketsEnabled}
                      component={NumberField}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Field
                      required
                      name="maximumGroupTickets"
                      label="Maximum"
                      disabled={!values.groupTicketsEnabled}
                      component={NumberField}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </SwitchSectionField>
            </GridDefaultPadding>
          </Grid>
        );
      }}
    </FormSpy>
  );
};
