import React from 'react';
import _ from 'lodash';
import { Lock as LockIcon } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TableData } from '@vizsla/components';
import type { TableColumn } from '@vizsla/components';
import { sortQuestionsByMandatoryFirst } from '@vizsla/utils';
import { DefaultCampaignQuestion, Experience } from '@vizsla/graphql';

import { FontWeight, Shade } from 'src/theme';

const useStyles = makeStyles(() => ({
  tableCells: {
    '& .MuiTableCell-root:nth-child(1)': {
      width: '35%',
      maxWidth: 300,
      fontWeight: FontWeight.Bold,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& .MuiTableCell-root:nth-child(2)': {
      width: '10%',
    },
    '& .MuiTableCell-root:nth-child(3)': {
      width: '40%',
      maxWidth: 300,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& .MuiTableCell-root:nth-child(4)': {
      width: '10%',
    },
    '& .MuiTableCell-root:nth-child(5)': {
      width: '5%',
    },
  },

  lockIcon: {
    color: Shade.Gray[400],
  },
}));

interface CampaignCustomQuestionsTableProps {
  campaignQuestions: Array<DefaultCampaignQuestion>;
  settingQuestions?: boolean;
}

export const CampaignDefaultQuestionsTable: React.FC<CampaignCustomQuestionsTableProps> = ({
  campaignQuestions,
  settingQuestions = false,
}) => {
  const classes = useStyles();

  const preparedCampaignQuestionData = React.useMemo(() => {
    const filtered = campaignQuestions?.filter(
      campaignQuestion => campaignQuestion?.isEnabled && !_.isNil(campaignQuestion?.question),
    );
    return sortQuestionsByMandatoryFirst(filtered);
  }, [campaignQuestions]);

  const TABLE_COLUMNS: Array<TableColumn<DefaultCampaignQuestion>> = [
    {
      title: 'Question',
      key: 'text',
      dataPath: 'question.title',
    },
    {
      title: 'type',
      key: 'type',
      dataPath: 'question.type',
    },
    {
      title: 'allocation',
      key: 'allocation',
      dataPath: 'experience.items',
      render: (experienceList: Experience[] = []) => {
        return !_.isEmpty(experienceList)
          ? experienceList.map(exp => exp?.name).join(', ')
          : 'All Experiences';
      },
    },
    {
      title: 'required',
      key: 'isRequired',
      align: 'center',
      render: (valueByDataPath: undefined, record: DefaultCampaignQuestion) => {
        const isRequiredText = record?.isRequired ? 'Yes' : 'No';

        return record?.question?.isMandatory ? (
          <LockIcon className={classes.lockIcon} />
        ) : (
          isRequiredText
        );
      },
    },
    {
      title: '',
      key: 'empty',
      render: () => <span />,
    },
  ];

  return (
    <Grid className={classes.tableCells}>
      <TableData
        dataSource={preparedCampaignQuestionData}
        columns={TABLE_COLUMNS}
        loading={settingQuestions}
      />
    </Grid>
  );
};
