import React from 'react';

import { useSearch } from '@vizsla/hooks';
import { TableData } from '@vizsla/components';
import type { TableRowAction, TableColumn } from '@vizsla/components';
import { DateFormatPatterns } from '@vizsla/constants';
import { formatDate, replaceIfNil, formatMoney } from '@vizsla/utils';
import { User, Donation } from '@vizsla/graphql';

import { UserAvatarFullNameInline } from 'src/components/user';
import { getUserFullName } from 'src/utils/user';

const DONATIONS_TABLE_COLUMNS: Array<TableColumn<Donation>> = [
  {
    title: 'name',
    key: 'name',
    dataPath: 'donor.user',
    render: (user: User) => {
      return <UserAvatarFullNameInline user={user} />;
    },
  },
  {
    title: 'date',
    key: 'date',
    dataPath: 'createdAt',
    render: (date: string) => {
      return formatDate(date, DateFormatPatterns.shortMonthDayYear);
    },
  },
  {
    title: 'donor type',
    key: 'donorType',
    dataPath: 'type',
  },
  {
    title: 'status',
    key: 'status',
    dataPath: 'donor.donations.count',
    render: (donationCount: number) => {
      return donationCount > 1 ? 'Returning' : 'New';
    },
  },
  {
    // TODO change this column when data model for personal mission fundraisers is ready
    title: 'attendee',
    key: 'attendee',
    dataPath: 'attractingFundraiser.user',
    render: (user: User) => {
      if (user) {
        return getUserFullName(user);
      }
      return 'N/A';
    },
  },
  {
    // TODO change this column when data model for personal mission teams is ready
    title: 'team',
    key: 'team',
    dataPath: 'attractingTeam.name',
    render: (teamName: string) => {
      return replaceIfNil(teamName, 'N/A');
    },
  },
  {
    title: 'donation',
    key: 'donation',
    dataPath: 'amount',
    render: (amount: number) => {
      return formatMoney(amount);
    },
  },
];

interface CampaignPMRecentDonationsTableProps {
  dataSource: Donation[];
  rowActions: TableRowAction[];
  loading: boolean;
}

export const CampaignPMRecentDonationsTable: React.FC<CampaignPMRecentDonationsTableProps> = ({
  dataSource,
  rowActions,
  loading,
}) => {
  const { setSearchValue } = useSearch();

  return (
    <TableData
      dataSource={dataSource}
      columns={DONATIONS_TABLE_COLUMNS}
      rowActions={rowActions}
      onSearch={setSearchValue}
      loading={loading}
    />
  );
};
