import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import {
  AutocompleteProps,
  Autocomplete,
  TextField,
  AutocompleteRenderInputParams,
} from '@mui/material';

export type AutocompleteFieldProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = {
  label?: string;
} & FieldRenderProps<any, HTMLElement> &
  Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'onChange'>;

export function AutocompleteField<T = any>(props: AutocompleteFieldProps<T>) {
  const { input, meta, label, ...rest } = props;

  const { value, onChange } = input;

  const { error } = meta;

  const handleChange = (_: any, value: T | null) => {
    onChange(value);
  };

  const renderInput = (params: AutocompleteRenderInputParams): React.ReactNode => {
    return <TextField error={error} label={label} {...params} />;
  };

  return <Autocomplete value={value} onChange={handleChange} renderInput={renderInput} {...rest} />;
}
