import React from 'react';
import { Outlet } from 'react-router-dom';

import { OrganizationSettingsLayout } from 'src/layouts';

const OrganizationSettingsPageWrapper: React.FC = () => {
  return (
    <OrganizationSettingsLayout>
      <Outlet />
    </OrganizationSettingsLayout>
  );
};

export { OrganizationSettingsPageWrapper };
