import styled from '@emotion/styled';
import { Typography, Box, Avatar, Button, Grid } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const AvatarPicture = styled(Avatar)`
  width: 100px;
  height: 100px;
`;

export const ChanceAvataButton = styled(Button)`
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 5px;
`;

export const Title = styled(Typography)`
  margin-top: 5px;
`;

Title.defaultProps = {
  variant: 'h3',
};

export const TinyTitle = styled(Typography)`
  margin-left: 4px;
`;

TinyTitle.defaultProps = {
  variant: 'h4',
  align: 'inherit',
};

export const FormLabel = styled(Typography)`
  margin-bottom: 10px;
`;

FormLabel.defaultProps = {
  variant: 'h5',
  align: 'inherit',
};

export const FormContainer = styled(Grid)`
  border-radius: 8px;
  margin-top: 30px;
  width: 60%;
  box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.1);
  padding: 40px;
`;

export const Icon = styled(AccountCircleOutlinedIcon)``;
