import { gql } from '@apollo/client';
import { USER_FRAGMENT } from '../users';
const FILE_FRAGMENT = gql `
  fragment TeamFundraisingFile on File {
    id
    fileId
    filename
    downloadUrl
    createdAt

    createdBy {
      id
      firstName
      lastName
    }
  }
`;
const TEAM_FUNDRAISER_CAMPAIGN_FRAGMENT = gql `
  fragment TeamFundraisingCampaign on Campaign {
    id
    name
    description
    contactEmail
    city
    state

    contactPhone {
      code
      number
    }

    logo {
      fileId
      downloadUrl
    }

    image {
      fileId
      downloadUrl
    }

    dnsRecord {
      slug
    }

    campaignTemplates {
      templates(filter: { templateType: { type: { equals: "TEAM_FUNDRAISER" } } }) {
        items {
          structure
        }
      }
    }
  }
`;
const TEAM_FUNDRAISING_MANAGEMENT_FRAGMENT = gql `
  ${USER_FRAGMENT}
  ${FILE_FRAGMENT}
  ${TEAM_FUNDRAISER_CAMPAIGN_FRAGMENT}

  fragment TeamFundraisingManagementFragment on TeamFundraising {
    id
    createdAt
    name
    teamMissionTitle
    teamMissionDescription
    totalRaised
    fundraiserGoal
    webLink
    experience {
      id
      name
      experienceLogoImage {
        downloadUrl
      }
      experienceHeaderImage {
        downloadUrl
      }
    }

    avatar {
      ...TeamFundraisingFile
    }

    teamPhoto {
      ...TeamFundraisingFile
    }

    donations {
      items {
        amount
      }
    }

    gallery {
      items {
        ...TeamFundraisingFile
      }
    }

    campaign {
      ...TeamFundraisingCampaign
    }

    captains: members(filter: { role: { equals: "Team Captain" } }) {
      items {
        id
        role
        attendee {
          id
          user {
            ...UserFragment
            avatar {
              ...TeamFundraisingFile
            }
            phone {
              code
              number
            }
            address {
              street1
              street2
              city
              state
              country
              zip
            }
          }
        }
      }
    }

    members(sort: { attendee: { fundraising: { raised: DESC } } }) {
      count
      items {
        id
        role

        attendee {
          id
          createdAt

          user {
            ...UserFragment

            avatar {
              downloadUrl
            }

            phone {
              code
              number
            }

            address {
              city
              state
            }
          }

          fundraising {
            id
            goal

            allocatedDonations(
              groupBy: {
                query: {
                  donor: { id: { as: "donors", fn: { aggregate: COUNT, distinct: true } } }
                  amount: { as: "raised", fn: { aggregate: SUM } }
                }
              }
            ) {
              groups {
                donors: Int
                raised: Float
              }
            }

            hero {
              fileId
              downloadUrl
            }

            logo {
              fileId
              downloadUrl
            }
          }
        }
      }
    }
  }
`;
export const TEAM_FUNDRAISING_MANAGEMENT = gql `
  ${TEAM_FUNDRAISING_MANAGEMENT_FRAGMENT}

  query TeamFundraisingManagement($id: ID!) {
    teamFundraising(id: $id) {
      ...TeamFundraisingManagementFragment
    }
  }
`;
export const CURRENT_USER_TEAM_FUNDRAISING_PAGE = gql `
  query CurrentUserTeamFundraisingPage($teamID: ID!) {
    user {
      firstName
      lastName

      attendees(filter: { teamMembership: { team: { id: { equals: $teamID } } } }) {
        items {
          id
          fundraising {
            id
            goal
            raised
            hero {
              downloadUrl
            }
            logo {
              downloadUrl
            }
          }
        }
      }
    }
  }
`;
/** @deprecated Use {@link TOP_DONATION_TEAM_FUNDRAISERS} instead. */
export const TOP_DONATION_TEAM_FUNDRAISER = gql `
  query teamFundraisingTop($teamId: ID!) {
    teamFundraising: teamFundraisingsList(filter: { id: { equals: $teamId } }) {
      items {
        members {
          items {
            attendee {
              user {
                firstName
              }
              fundraising {
                allocatedDonations {
                  items {
                    amount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const TOP_DONATION_TEAM_FUNDRAISERS = gql `
  ${USER_FRAGMENT}

  query TopDonationTeamFundraisers($teamId: ID!) {
    fundraisers: individualFundraisingsList(
      filter: { attendee: { teamMembership: { team: { id: { equals: $teamId } } } } }
    ) {
      count
      items {
        id

        attendee {
          user {
            ...UserFragment
          }
        }

        allocatedDonations(
          groupBy: { query: { amount: { as: "total", fn: { aggregate: SUM } } } }
        ) {
          groups {
            total: Float
          }
        }
      }
    }
  }
`;
export const TOP_AVERAGE_DONATION_TEAM_FUNDRAISERS = gql `
  ${USER_FRAGMENT}

  query TOP_AVERAGE_DONATION_TEAM_FUNDRAISERS($teamId: ID!) {
    fundraisers: individualFundraisingsList(
      filter: { attendee: { teamMembership: { team: { id: { equals: $teamId } } } } }
    ) {
      count
      items {
        id

        attendee {
          user {
            ...UserFragment
          }
        }

        allocatedDonations(
          groupBy: { query: { amount: { as: "average", fn: { aggregate: AVG } } } }
        ) {
          groups {
            average: Float
          }
        }
      }
    }
  }
`;
export const TOP_DONORS_TEAM_FUNDRAISERS = gql `
  ${USER_FRAGMENT}

  query TopDonorsTeamFundraisers($teamId: ID!) {
    fundraisers: individualFundraisingsList(
      filter: { attendee: { teamMembership: { team: { id: { equals: $teamId } } } } }
    ) {
      count
      items {
        id

        attendee {
          user {
            ...UserFragment
          }
        }

        allocatedDonations(
          groupBy: { query: { donor: { id: { as: "donors", fn: { aggregate: COUNT } } } } }
        ) {
          groups {
            donors: Int
          }
        }
      }
    }
  }
`;
/** @deprecated Use {@link TOP_DONORS_TEAM_FUNDRAISERS} instead. */
export const DONOR_TOP_BY_TEAM = gql `
  query topDonorByTeam($teamId: ID!) {
    donors: usersList(
      filter: { allocatedDonations: { some: { teamFundraising: { id: { equals: $teamId } } } } }
    ) {
      items {
        id
        firstName
        lastName
        avatar {
          downloadUrl
        }

        allocatedDonations(
          groupBy: {
            sort: { alias: "total", direction: ASC }
            query: { amount: { as: "total", fn: { aggregate: COUNT } } }
          }
        ) {
          groups {
            total: Float
          }
        }
      }
    }
  }
`;
export const TEAM_FUNDRAISING_MANAGEMENT_BY_FILTER = gql `
  ${TEAM_FUNDRAISING_MANAGEMENT_FRAGMENT}

  query TeamFundraisingManagementByFilter($filter: TeamFundraisingFilter!) {
    teamFundraisingsList(filter: $filter) {
      count
      items {
        ...TeamFundraisingManagementFragment
      }
    }
  }
`;
const TEAM_FUNDRAISING_GALLERY_FRAGMENT = gql `
  ${FILE_FRAGMENT}

  fragment TeamFundraisingGalleryFragment on TeamFundraising {
    gallery {
      items {
        ...TeamFundraisingFile
      }
    }
  }
`;
export const TEAM_FUNDRAISING_UPDATE_GALLERY = gql `
  ${TEAM_FUNDRAISING_GALLERY_FRAGMENT}

  mutation TeamFundraisingUpdateGallery(
    $data: TeamFundraisingUpdateInput!
    $filter: TeamFundraisingKeyFilter!
  ) {
    teamFundraising: teamFundraisingUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...TeamFundraisingGalleryFragment
    }
  }
`;
const TEAM_FUNDRAISING_CONTENT_FRAGMENT = gql `
  fragment TeamFundraisingContentFragment on TeamFundraising {
    name
    teamMissionTitle
    teamMissionDescription
    totalRaised
    fundraiserGoal
    webLink
  }
`;
export const TEAM_FUNDRAISING_UPDATE_CONTENT = gql `
  ${TEAM_FUNDRAISING_CONTENT_FRAGMENT}

  mutation TeamFundraisingContentAttachImage(
    $data: TeamFundraisingUpdateInput!
    $filter: TeamFundraisingKeyFilter!
  ) {
    teamFundraising: teamFundraisingUpdate(data: $data, filter: $filter) {
      ...TeamFundraisingContentFragment
    }
  }
`;
const TEAM_FUNDRAISING_HEADER_FRAGMENT = gql `
  ${FILE_FRAGMENT}

  fragment TeamFundraisingHeaderFragment on TeamFundraising {
    avatar {
      ...TeamFundraisingFile
    }

    teamPhoto {
      ...TeamFundraisingFile
    }
  }
`;
export const TEAM_FUNDRAISING_UPDATE_HEADER = gql `
  ${TEAM_FUNDRAISING_HEADER_FRAGMENT}

  mutation TeamFundraisingUpdateHeader(
    $data: TeamFundraisingUpdateInput!
    $filter: TeamFundraisingKeyFilter!
  ) {
    teamFundraising: teamFundraisingUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...TeamFundraisingHeaderFragment
    }
  }
`;
export const TEAMSNAME_PER_EXPERIENCE = gql `
  query TeamsNamePerExperience($experienceId: ID!, $teamName: String) {
    experience(id: $experienceId) {
      teams(filter: { name: { equals: $teamName } }) {
        count
        items {
          name
        }
      }
    }
  }
`;
