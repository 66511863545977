import React from 'react';

import { TableColumn, TableData } from '@vizsla/components';
import { formatDate } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';

export function ActivityTableview({ activity, pagination, loading }) {
  const columns: Array<TableColumn> = [
    {
      title: 'Date',
      key: 'date',
      render: (_, row) => {
        return formatDate(row.createdAt, DateFormatPatterns.shortMonthDayYear);
      },
    },
    {
      title: 'Description',
      key: 'activity',
      render: (_, row) => row.description,
    },
  ];
  return (
    <TableData
      dataSource={activity}
      columns={columns}
      pagination={pagination}
      rowActions={[]}
      loading={loading}
    />
  );
}
