import React from 'react';
import { makeStyles } from '@mui/styles';
import { AddPhotoAlternateOutlined as AddPhotoIcon } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { FileInput, FileInputValue } from '8base-react-sdk';

import { File } from '@vizsla/graphql';
import { PaletteColor } from '@vizsla/theme';

const PREVIEW_SIZE = 48;

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    cursor: 'pointer',
  },

  preview: {
    width: PREVIEW_SIZE,
    height: PREVIEW_SIZE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: PaletteColor.PrimaryTeal,
  },

  text: {
    color: PaletteColor.PrimaryTeal,
    userSelect: 'none',
  },
});

export type ImageFile = File;

interface ImageSelectorButtonProps {
  label: string;

  onChange?: (files: ImageFile[]) => void;
}

const MAX_FILE_SIZE = 8 * 1024 * 1024;

function transformImage(data: FileInputValue): ImageFile[] {
  if (Array.isArray(data)) {
    return data.map(transformImage).flat();
  }

  return [
    {
      id: data.fileId,
      filename: data.filename,
      downloadUrl: data.downloadUrl,
    },
  ];
}

export function ImageSelectorButton(props: ImageSelectorButtonProps) {
  const styles = useStyles();

  const pickImage = async (picker: (opts: any) => Promise<void>) => {
    await picker({
      maxSize: MAX_FILE_SIZE,
      accept: ['image/jpeg', 'image/png'],
      fromSources: ['local_file_system'],
    });
  };

  const selectFiles = (files: FileInputValue) => {
    const payload = transformImage(files);
    props.onChange?.(payload);
  };

  return (
    <FileInput maxFiles={100} onChange={selectFiles}>
      {wrapper => (
        <Grid className={styles.container} onClick={() => pickImage(wrapper.pick)}>
          <div className={styles.preview}>
            <AddPhotoIcon htmlColor={PaletteColor.PrimaryTeal} />
          </div>

          <Typography variant="caption" className={styles.text}>
            {props.label}
          </Typography>
        </Grid>
      )}
    </FileInput>
  );
}
