import React from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';

import { User, useUserContactInfoQuery } from '@vizsla/graphql';
import { useCurrentUser, useNotification } from '@vizsla/hooks';
import { formatDate, getUserFullName } from '@vizsla/utils';
import { Shade } from '@vizsla/theme';
import { DateFormatPatterns } from '@vizsla/constants';
import { Button, UserAvatar } from '@vizsla/components';

import { ContactUserIdContext } from 'src/providers/peopleCRM';
import { PeopleCRMTabType } from 'src/types/peopleCRM';
import { USER_STATUS_UPDATE } from 'src/graphql';

import { ContactInfoCards } from '../ContactInfoCards';
import { ContactInfoTabs } from '../ContactInfoTabs';
import { useNavigateToInboxByRecipients } from '../../../../hooks/inbox';

interface ContactInfoModalContentProps {
  userId: string;
  tabType: PeopleCRMTabType;
}

const ContactInfoModalContent: React.FC<ContactInfoModalContentProps> = ({ userId, tabType }) => {
  const { data, loading } = useUserContactInfoQuery({
    variables: { userId },
    skip: !userId,
  });
  const navigateToInbox = useNavigateToInboxByRecipients();
  const { user: dataCurrentUser } = useCurrentUser();

  const rolesUser = React.useMemo(() => dataCurrentUser?.roles?.items ?? [], [dataCurrentUser]);

  const isVizslaAdministrator = rolesUser.some(roles => {
    return roles?.name?.includes('VIZSLA Administrator');
  });

  const user = (data?.user || {}) as User;

  const isCurrentUser = user.id === dataCurrentUser?.id;

  const [userUpdate, { loading: loadingUpdate }] = useMutation(USER_STATUS_UPDATE, {
    refetchQueries: ['UserContactInfo'],
    awaitRefetchQueries: true,
  });

  const notification = useNotification();

  if (loading && userId) {
    return <CircularProgress color="secondary" size={128} sx={{ display: 'block', mx: 'auto' }} />;
  }

  const userIsActive = user.status === 'active';

  const handleStateClick = async (id: string | undefined, userIsActive) => {
    const changeStatus = userIsActive ? 'inactive' : 'active';
    try {
      await userUpdate({
        variables: {
          data: { id, status: changeStatus },
        },
      });
      notification.success('User update success');
    } catch (error) {
      notification.error('error updating user');
      console.error(error);
    }
  };

  const handleMessageClick = () => {
    navigateToInbox(userId);
  };
  return (
    <Grid container item direction="column" spacing={4}>
      <Grid container item columnSpacing={4} direction="row" wrap="nowrap">
        <Grid item>
          <UserAvatar user={user} size="132px" />
        </Grid>

        <Grid container item direction="column">
          <Grid item>
            <Typography variant="h3">{getUserFullName(user)}</Typography>
          </Grid>

          <Grid item>
            <Typography variant="body2" color={Shade.Gray[500]}>
              {user?.roles?.items.map(role => role.name).join(', ')}
            </Typography>
          </Grid>

          <Grid container item direction="row" columnSpacing={2} my={1}>
            <Grid item>
              <Typography variant="body1">
                {/* TODO: Replace with real value */}
                <strong>User ID: </strong>43543
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <strong>Joined on: </strong>
                {formatDate(user.createdAt, DateFormatPatterns.shortDateWithSlash)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {/* TODO: Replace with real value */}
                <strong>Last activity: </strong>2 days ago
              </Typography>
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item>
              <Button disabled={isCurrentUser} sx={{ margin: 0 }} onClick={handleMessageClick}>
                Message
              </Button>
            </Grid>
            <Grid item>
              {isVizslaAdministrator &&
                (userIsActive ? (
                  <LoadingButton
                    variant="contained"
                    color="error"
                    loading={loadingUpdate}
                    onClick={() => {
                      handleStateClick(user?.id as string, userIsActive);
                    }}
                  >
                    {user.status ? 'Inactive' : 'Activate'}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    variant="contained"
                    color="success"
                    loading={loadingUpdate}
                    onClick={() => {
                      handleStateClick(user?.id as string, userIsActive);
                    }}
                  >
                    {user.status ? 'Activate' : 'Inactivate'}
                  </LoadingButton>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <ContactInfoCards user={user} />
      </Grid>

      <Grid container item justifyContent="center" spacing={4}>
        <ContactUserIdContext.Provider value={{ userId: user.id ? user.id : userId, tabType }}>
          <ContactInfoTabs />
        </ContactUserIdContext.Provider>
      </Grid>
    </Grid>
  );
};

export { ContactInfoModalContent };
