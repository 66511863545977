import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TemplateType } from '@vizsla/types';
import { Button } from '@vizsla/components';

const useStyles = makeStyles(theme => ({
  buttons: {},

  button: {
    margin: 0,
  },
}));

export interface CardOptionsDonationProps {
  campaignID: string;
  type: TemplateType;
}

export function CardOptionsDonation(props: CardOptionsDonationProps) {
  const styles = useStyles();

  return (
    <div>
      <Grid container className={styles.button}>
        <Button variant="text" size="small" color="primary" className={styles.button} disabled>
          Edit
        </Button>
      </Grid>
    </div>
  );
}
