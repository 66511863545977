import _ from 'lodash';

import { ExperienceBibAssignment } from '@vizsla/graphql';
import { concatDateWithTime, getNowDateTime, isDateGreaterThan } from '@vizsla/utils';

import {
  BibAutoAssignDates,
  BibAutoAssignDatesFormData,
  BibAutoAssignmentStatus,
} from 'src/types/bibAssignment';

export const getBibRangeBoundaries = (range: string): number[] => {
  return range.split('-').map(n => Number(n));
};

export const getRemainingBibPoolNumbers = (
  bibRanges: string[] = [],
  bibAssignments: ExperienceBibAssignment[] = [],
) => {
  const remainingCounter = bibRanges.reduce((counter, range) => {
    const [start, end] = getBibRangeBoundaries(range);

    return {
      ...counter,
      [range]: end - start + 1,
    };
  }, {} as Record<string, number>);

  return bibAssignments.reduce((counter, bib) => {
    const bibNumber = bib?.bibNumber as number;

    const parentRange = bibRanges.find(range => {
      const [start, end] = getBibRangeBoundaries(range);

      return bibNumber >= start && bibNumber <= end;
    });

    if (parentRange) {
      const count = counter[parentRange];

      return {
        ...counter,
        [parentRange]: count - 1,
      };
    }

    return counter;
  }, remainingCounter);
};

export const getAutoAssignDatesUpdateInput = (data: BibAutoAssignDatesFormData) => {
  const startDate = data?.autoAssignStartDate;
  const startTime = data?.autoAssignStartTime;
  const endDate = data?.autoAssignEndDate;
  const endTime = data?.autoAssignEndTime;

  return {
    bibAutoAssignStartDate: concatDateWithTime(startDate, startTime),
    bibAutoAssignEndDate: concatDateWithTime(endDate, endTime),
  };
};

export const getAutoAssignmentStatus = (dates: BibAutoAssignDates): BibAutoAssignmentStatus => {
  const now = getNowDateTime();

  if (_.isNil(dates?.start) || _.isNil(dates?.end)) {
    return BibAutoAssignmentStatus.Inactive;
  }
  if (isDateGreaterThan(dates?.start, now, 'minute')) {
    return BibAutoAssignmentStatus.Scheduled;
  }
  if (isDateGreaterThan(now, dates?.end, 'minute')) {
    return BibAutoAssignmentStatus.Completed;
  }
  return BibAutoAssignmentStatus.InProgress;
};
