import React from 'react';
import { useApolloClient } from '@apollo/client';

import {
  ExperienceUpdateInput,
  ExperienceStoreSettingsDocument,
  ExperienceStoreSettingsFragmentFragment,
  ExperienceStoreSettingsQuery,
  useExperienceStoreSettingsQuery,
  useExperienceStoreSettingsUpdateMutation,
} from '@vizsla/graphql';

export const useExperienceStoreSettings = (experienceId: string) => {
  const apolloClient = useApolloClient();

  const { data: experienceStoreSettingsQueryResult, loading } = useExperienceStoreSettingsQuery({
    variables: { id: experienceId },
  });

  const [experienceStoreSettingsUpdateMutation, { loading: updating }] =
    useExperienceStoreSettingsUpdateMutation();

  const experienceStoreSettings = experienceStoreSettingsQueryResult?.experience;

  const updateExperienceStoreSettingsInCache = React.useCallback(
    (experience: ExperienceStoreSettingsFragmentFragment) => {
      apolloClient.writeQuery<ExperienceStoreSettingsQuery>({
        query: ExperienceStoreSettingsDocument,
        variables: {
          id: experienceId,
        },
        data: {
          experience,
          __typename: 'Query',
        },
      });
    },
    [apolloClient, experienceId],
  );

  const updateExperienceStoreSettings = async (experienceUpdateInput: ExperienceUpdateInput) => {
    const { data } = await experienceStoreSettingsUpdateMutation({
      variables: {
        id: experienceId,
        data: experienceUpdateInput,
      },
    });

    if (!data) {
      throw new Error('Failed to update experienceStoreSettings');
    }

    updateExperienceStoreSettingsInCache(data.experienceUpdate);
  };

  return {
    experienceStoreSettings,
    updateExperienceStoreSettings,
    loading,
    updating,
  };
};
