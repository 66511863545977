import React from 'react';

import {
  CampaignRegistrationContext,
  CampaignRegistrationContextType,
} from 'src/providers/campaign';

export const useCampaignRegistration = () => {
  return React.useContext<CampaignRegistrationContextType>(CampaignRegistrationContext);
};
