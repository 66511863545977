import { Button, Grid } from '@mui/material';
import styled from '@emotion/styled';

import { Shade, BorderRadius } from '@vizsla/theme';

export const InnerBlock = styled.div`
  border: 1px solid ${Shade.Gray[100]};
  borderRadius: ${BorderRadius.M},
  padding: theme.spacing(3, 4),
`;

export const NotifGroupGrid = styled.div`
  border: 1px solid ${Shade.Gray[100]};
  border-radius: ${BorderRadius.M};
  padding: ${props => props.theme.spacing(3, 4)};
  grid-template-columns: 1fr 1fr;
  display: grid;
`;
export const ContentWrapper = styled(Grid)`
  padding-bottom: ${props => props.theme.spacing(3, 4)};
`;
export const ButtonSectionWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ButtonWrapper = styled.div({
  display: 'flex',
  width: '4rem',
});

export const Form = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  marginBottom: '2rem',
});

export const SelectAllButton = styled(Button)(props => ({
  color: props.theme.palette.secondary.light,
  backgroundColor: 'transparent',
  fontWeight: '500',
  marginTop: '0.5rem',
  marginLeft: '-0.5rem',
}));

export const GridContainer = styled.div({
  marginTop: '-1rem',
});
