import React from 'react';

import { CampaignsListContent } from 'src/components/campaign';
import { CampaignsListLayout } from 'src/layouts';

const CampaignsPage: React.FC = () => {
  return (
    <CampaignsListLayout>
      <CampaignsListContent />
    </CampaignsListLayout>
  );
};

export { CampaignsPage };
