import React from 'react';
import { makeStyles } from '@mui/styles';
import { List, ListItemIcon, ListItemText, Divider, Theme, Typography } from '@mui/material';

import { PaletteColor, Shade } from '@vizsla/theme';
import { ListNavLinkItem } from '@vizsla/components';

const useStyles = makeStyles((theme: Theme) => ({
  menuItemHeaderText: {
    fontSize: 10,
    letterSpacing: 2,
    color: 'grey',
  },
  wrapText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuItem: {
    borderRadius: 6,
    marginBottom: theme.spacing(1),
    '&.Mui-selected': {
      backgroundColor: Shade.Blue['25'],
      '& .MuiSvgIcon-root': {
        color: PaletteColor.PrimaryMain,
      },
      '& .MuiTypography-root': {
        color: PaletteColor.PrimaryMain,
      },
    },
  },
}));

interface CampaignMenuItemHeaderProps {
  children: React.ReactNode;
}

export const CampaignMenuItemHeader: React.FC<CampaignMenuItemHeaderProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <Divider>
      <Typography className={classes.menuItemHeaderText}>{children}</Typography>
    </Divider>
  );
};

type CampaignMenuItem = {
  to: string;
  text: string;
  icon: JSX.Element;
};

interface CampaignMenuProps {
  items: Array<CampaignMenuItem>;
}

export const CampaignMenu: React.FC<CampaignMenuProps> = ({ items }) => {
  const classes = useStyles();

  const renderMenuItem = ({ to, text, icon }: CampaignMenuItem) => {
    return (
      <ListNavLinkItem className={classes.menuItem} to={to} key={to}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText className={classes.wrapText}>{text}</ListItemText>
      </ListNavLinkItem>
    );
  };

  return <List>{items.map(renderMenuItem)}</List>;
};
