import {
  RegistrationPricingTierCreateManyInput,
  useRegistrationPricingTierCreateManyMutation,
  useRegistrationPricingTierDeleteByFilterMutation,
} from '@vizsla/graphql';

type RegistrationOptionsPricingTiersOutput = {
  createNewPricingTiers: (data: Array<RegistrationPricingTierCreateManyInput>) => Promise<void>;
  deletePricingTiers: (ids: Array<string>) => Promise<void>;
  creatingPricingTiers: boolean;
  deletingPricingTiers: boolean;
};

export const useRegistrationOptionPricingTiers = (): RegistrationOptionsPricingTiersOutput => {
  const [registrationPricingTierCreateManyMutation, { loading: creatingPricingTiers }] =
    useRegistrationPricingTierCreateManyMutation();

  const createNewPricingTiers = async (data: Array<RegistrationPricingTierCreateManyInput>) => {
    await registrationPricingTierCreateManyMutation({
      variables: {
        data,
      },
      refetchQueries: ['RegistrationOptionsList'],
    });
  };

  const [registrationPricingTierDeleteByFilterMutation, { loading: deletingPricingTiers }] =
    useRegistrationPricingTierDeleteByFilterMutation();

  const deletePricingTiers = async (ids: Array<string>) => {
    await registrationPricingTierDeleteByFilterMutation({
      variables: {
        filter: {
          id: {
            in: ids,
          },
        },
      },
    });
  };

  return {
    createNewPricingTiers,
    deletePricingTiers,
    creatingPricingTiers,
    deletingPricingTiers,
  };
};
