export function rangeFilter(count: number, from: number | undefined, to: number | undefined) {
  const hasFrom = from !== undefined;
  const hasTo = to !== undefined;

  if (!hasFrom && !hasTo) {
    return true;
  }
  if (hasFrom && hasTo) {
    return count >= from && count <= to;
  }
  if (hasFrom && !hasTo) {
    return count >= from;
  }
  if (!hasFrom && hasTo) {
    return count <= to;
  }

  return true;
}
