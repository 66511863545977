import React from 'react';

import { FiltersLayout } from '@vizsla/components';
import { useModal, useCurrentOrganization } from '@vizsla/hooks';

import { useStoreAssetsDashboardData } from 'src/hooks/storeAssets';
import { MODALS } from 'src/constants/modals';

const StoreAssetsDashboardLayout: React.FC = ({ children: storeAssetsDashboardContent }) => {
  const { openModal } = useModal();
  const { organizationId, organizationIsLoading } = useCurrentOrganization();

  const { loading, creating } = useStoreAssetsDashboardData(organizationId || undefined);

  const onOpenAssetCreateModal = () => {
    openModal(MODALS.STORE_ASSET_CREATE_MODAL);
  };

  const isButtonsDisabled = loading || creating || organizationIsLoading;

  return (
    <FiltersLayout
      withSearch
      withPagination
      rightButtonProps={{
        content: 'Add Asset',
        onClick: onOpenAssetCreateModal,
        disabled: isButtonsDisabled,
      }}
    >
      {storeAssetsDashboardContent}
    </FiltersLayout>
  );
};

export { StoreAssetsDashboardLayout };
