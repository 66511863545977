import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isNil } from 'lodash';

import { useNotification } from '@vizsla/hooks';
import {
  useStoreAssetQuery,
  StoreAssetQueryVariables,
  useGetAllAssetInventoryQuery,
  useGetAssetPictureQuery,
  GetAllAssetInventory,
} from '@vizsla/graphql';

import { useStoreAssetId } from 'src/hooks/storeAssets';
import { t } from 'src/utils/template';
import type { StoreAsset } from 'src/types/storeAssets';
import * as appRoutes from 'src/constants/routes';
import { FullscreenLoader } from 'src/components/shared';

interface StoreAssetManagementDataContextType {
  storeAsset: StoreAsset;
  queryVariables: StoreAssetQueryVariables;
  refetchAsset: () => void;
}

const StoreAssetManagementDataContext = React.createContext(
  {} as StoreAssetManagementDataContextType,
);

const StoreAssetManagementDataProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();

  const notification = useNotification();

  const storeAssetId = useStoreAssetId();

  const queryVariables: StoreAssetQueryVariables = {
    id: storeAssetId,
  };

  const {
    data: storeAssetData,
    loading: isStoreAssetLoading,
    refetch: refetchAsset,
  } = useGetAllAssetInventoryQuery({
    variables: { id: storeAssetId },
  });

  const { data: storeAssetPicture } = useGetAssetPictureQuery({
    variables: { id: storeAssetData?.getAllAssetInventory?.picture || '' },
  });

  const image = storeAssetPicture?.file || '';

  const storeAsset = React.useMemo(
    () =>
      ({
        ...storeAssetData?.getAllAssetInventory,
        image,
        unitCost: storeAssetData?.getAllAssetInventory?.unitPrice,
      } as StoreAsset),
    [storeAssetData, storeAssetPicture],
  );

  if (isStoreAssetLoading) {
    return <FullscreenLoader variant="light" />;
  }

  if (isNil(storeAsset)) {
    return null;
  }

  return (
    <StoreAssetManagementDataContext.Provider value={{ storeAsset, queryVariables, refetchAsset }}>
      {children}
    </StoreAssetManagementDataContext.Provider>
  );
};

export { StoreAssetManagementDataContext, StoreAssetManagementDataProvider };
