import React from 'react';
import {
  SwitchAccountOutlined,
  PaymentsOutlined,
  AutoAwesomeOutlined,
  LocalAtmOutlined,
  AttachMoneyOutlined,
} from '@mui/icons-material';
import { Box } from '@mui/material';

import { InfoCardGroup } from '@vizsla/components';
import { usePartnershipDashboardDataQuery } from '@vizsla/graphql';

export const PartnershipDetailsInfoCards: React.FC<{ partnershipId?: string }> = ({
  partnershipId,
}) => {
  const { loading, data: stats } = usePartnershipDashboardDataQuery({
    variables: { id: partnershipId || '' },
    skip: !partnershipId,
  });

  return (
    <Box width="100%">
      <InfoCardGroup
        data={[
          {
            loading,
            icon: <SwitchAccountOutlined color="secondary" fontSize="large" />,
            title: 'Total Commitments',
            value: stats?.total?.count || 0,
          },
          {
            loading,
            icon: <AttachMoneyOutlined color="secondary" fontSize="large" />,
            title: 'Cash',
            value: stats?.cash?.groups?.at(0)?.sum || 0,
          },
          {
            loading,
            icon: <AutoAwesomeOutlined color="secondary" fontSize="large" />,
            title: 'In-Kind',
            value: stats?.inKind?.groups?.at(0)?.sum || 0,
          },
          {
            loading,
            icon: <LocalAtmOutlined color="secondary" fontSize="large" />,
            title: 'Matching',
            value: stats?.matching?.groups?.at(0)?.sum || 0,
          },
          {
            loading,
            icon: <PaymentsOutlined color="secondary" fontSize="large" />,
            title: 'Total Amount',
            value: stats?.total?.groups?.at(0)?.sum || 0,
          },
        ]}
      />
    </Box>
  );
};
