import React from 'react';
import { Editor, EditorState } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { composeStyles, Border, BorderRadius, Shade } from '@vizsla/theme';

import clearFormatIcon from 'src/assets/img/editorIcons/clear.png';
import bold from 'src/assets/img/editorIcons/bold.png';
import underline from 'src/assets/img/editorIcons/underline.png';
import linkIcon from 'src/assets/img/editorIcons/link.png';
import unlinkIcon from 'src/assets/img/editorIcons/linkOff.png';
import unorderedIcon from 'src/assets/img/editorIcons/bulletList.png';
import orderedIcon from 'src/assets/img/editorIcons/numberList.png';
import undoIcon from 'src/assets/img/editorIcons/undo.png';
import redoIcon from 'src/assets/img/editorIcons/redo.png';
import colorPickerIcon from 'src/assets/img/editorIcons/bgColor.png';

interface StylesProps {
  height: number;
}

const useStyles = makeStyles<Theme, StylesProps>(theme => ({
  editor: {
    minHeight: ({ height }) => `${height}px`,
    maxHeight: `264px`,
  },
  editorWrapper: {
    borderColor: Shade.Gray[200],
    borderStyle: Border.Solid,
    borderRadius: BorderRadius.M,
    minHeight: ({ height }) => `${height}px`,
    borderWidth: '1px',
    color: 'black',
    '& .rdw-editor-main': {
      padding: theme.spacing(0, 2),
    },
    '& .public-DraftEditor-content': {
      overflowWrap: 'anywhere !important' as 'anywhere',
    },
  },
  toolbar: {
    borderColor: Shade.Gray[200],
    borderStyle: composeStyles(Border.None, Border.None, Border.Solid, Border.None),
    borderRadius: 0,
    backgroundColor: '#F5F6F73F',
    borderWidth: '1px',
    '& .rdw-option-wrapper': {
      width: 32,
      height: 32,
      padding: 0,
      border: Border.None,
      borderRadius: BorderRadius.S,
      '&:hover': {
        boxShadow: '0px 0px 2px #bfbdbd',
      },
    },
    '& .rdw-option-active': {
      boxShadow: 'none',
      backgroundColor: Shade.Gray[100],
    },
    '& .rdw-option-wrapper img': {
      width: 32,
      height: 32,
    },
  },
}));

const TOOLBAR_SETTINGS = {
  options: ['fontSize', 'inline', 'colorPicker', 'link', 'list', 'remove', 'history'],
  inline: {
    bold: { icon: bold },
    underline: { icon: underline },
    options: ['bold', 'underline'],
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
  },
  list: {
    unordered: { icon: unorderedIcon },
    ordered: { icon: orderedIcon },
    options: ['unordered', 'ordered'],
  },
  textAlign: {
    options: ['left', 'center', 'right', 'justify'],
  },
  colorPicker: {
    icon: colorPickerIcon,
  },
  link: {
    showOpenOptionOnHover: false,
    defaultTargetOption: '_self',
    options: ['link', 'unlink'],
    link: { icon: linkIcon },
    unlink: { icon: unlinkIcon },
  },
  remove: { icon: clearFormatIcon },
  history: {
    options: ['undo', 'redo'],
    undo: { icon: undoIcon },
    redo: { icon: redoIcon },
  },
};

interface RichTextEditorProps {
  editorState: EditorState;
  onEditorStateChange: (EditorState: EditorState) => void;
  height?: number;
  placeholder?: string;
}

const DEFAULT_HEIGHT = 104;

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
  editorState,
  onEditorStateChange,
  height = DEFAULT_HEIGHT,
  ...rest
}) => {
  const classes = useStyles({
    height,
  });

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      editorClassName={classes.editor}
      wrapperClassName={classes.editorWrapper}
      toolbarClassName={classes.toolbar}
      toolbar={TOOLBAR_SETTINGS}
      {...rest}
    />
  );
};
