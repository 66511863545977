import { gql } from '@apollo/client';

export const FILE_FRAGMENT = gql`
  fragment FileFragment on File {
    id
    fileId
    filename
    downloadUrl
  }
`;
