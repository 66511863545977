import { gql } from '@apollo/client';

export const DONOR_FRAGMENT = gql`
  fragment DonorFragment on Donor {
    id
    createdAt
    user {
      id
      firstName
      lastName
      email
      avatar {
        id
        downloadUrl
      }
    }
    campaign {
      id
    }
    donations {
      count
      items {
        id
        amount
      }
    }
  }
`;

export const DONOR_LIST_QUERY = gql`
  query DonorsList($filter: DonorFilter, $skip: Int, $first: Int) {
    donorsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        ...DonorFragment
      }
    }
  }
  ${DONOR_FRAGMENT}
`;

export const USERS_DONORS_LIST_QUERY = gql`
  query UsersDonorsList($organizationId: ID!, $skip: Int, $first: Int) {
    usersList(
      filter: {
        donors: { some: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } } }
      }
      skip: $skip
      first: $first
    ) {
      count
      items {
        id
        firstName
        lastName
        avatar {
          id
          downloadUrl
        }
        donors(filter: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }) {
          count
          items {
            id
            donations {
              items {
                id
                amount
              }
            }
            campaign {
              id
              name
            }
          }
        }
      }
    }
  }
`;
