import React from 'react';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Grid } from '@mui/material';
import { FormApi } from 'final-form';

import { TextField, Button } from '@vizsla/components';
import { useModal, useNotification } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';

import { MODALS } from 'src/constants/modals';
import { useExperienceBibAssignment, useExperienceId } from 'src/hooks/experiences';
import { t } from 'src/utils/template';
import { useRegistrationOptions } from 'src/hooks/registrationOption';
import { ExperienceBibPoolFormSchema } from 'src/constants/validationSchemas/bibAssignment';

import { getBibPoolInitialValues, getPreparedData } from '../utils/experienceBibPoolFormUtils';
import { ExperienceBibPoolFormAllocationSection } from './ExperienceBibPoolFormAllocationSection';
import { ExperienceBibPoolFormRangesSection } from './ExperienceBibPoolFormRangesSection';

export const ExperienceBibPoolCreateForm: React.FC = () => {
  const experienceId = useExperienceId();
  const notification = useNotification();
  const { closeModal } = useModal(MODALS.BIB_POOL_CREATE_MODAL);

  const { createBibPool } = useExperienceBibAssignment(experienceId);
  const { registrationOptions: registrationOptionsList, loading: registrationOptionsLoading } =
    useRegistrationOptions(experienceId);

  const INITIAL_VALUES = getBibPoolInitialValues();

  const onSubmit = async (formData: Record<string, any>, form: FormApi) => {
    const preparedData = getPreparedData(formData, registrationOptionsList);

    try {
      await createBibPool(preparedData);

      closeModal();
      form.reset();
      notification.success(t('experience_bib_pool_create_success'));
    } catch (error) {
      notification.error(error.message);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={INITIAL_VALUES}
      subscription={{ submitting: true, initialValues: true }}
      validate={values => validateWithSchema(ExperienceBibPoolFormSchema, values)}
      mutators={{
        ...arrayMutators,
      }}
      keepDirtyOnReinitialize
    >
      {({ submitting, pristine, handleSubmit, form }) => {
        const onCloseModal = () => {
          closeModal();
          form.reset();
        };
        return (
          <form>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Field
                  name="name"
                  label="Bib Pool Name"
                  component={TextField}
                  disabled={submitting}
                  variant="outlined"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <ExperienceBibPoolFormRangesSection />
              </Grid>
              <Grid item xs={12}>
                <ExperienceBibPoolFormAllocationSection />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button disabled={submitting} variant="text" onClick={onCloseModal}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      loading={submitting}
                      disabled={pristine || registrationOptionsLoading}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
};
