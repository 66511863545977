import React from 'react';
import { Field, Form } from 'react-final-form';
import { Grid } from '@mui/material';

import { useModal, useNotification } from '@vizsla/hooks';
import { concatDateWithTime, validateWithSchema } from '@vizsla/utils';
import { Button, TextField, SelectField } from '@vizsla/components';

import {
  useCampaignDiscounts,
  useCampaignId,
  useCheckCampaignDiscountName,
} from 'src/hooks/campaign';
import { CampaignAddDiscountsFormSchema } from 'src/constants/validationSchemas/campaign';
import { DISCOUNT_CATEGORY_OPTIONS } from 'src/constants/discount';
import { MODALS } from 'src/constants/modals';
import { t } from 'src/utils/template';

import { getDiscountInitialValues } from '../utils/campaignDiscountFormUtils';
import { CampaignDiscountFormAmountSection } from './CampaignDiscountFormAmountSection';
import { CampaignDiscountFormDateTimeSection } from './CampaignDiscountFormDateTimeSection';
import { CampaignDiscountFormUsageSection } from './CampaignDiscountFormUsageSection';
import { CampaignDiscountFormAllocationSection } from './CampaignDiscountFormAllocationSection';

export const CampaignDiscountForm: React.FC = () => {
  const campaignId = useCampaignId();
  const notification = useNotification();

  const { createDiscount, updateDiscount } = useCampaignDiscounts(campaignId);
  const { debouncedNameValidator } = useCheckCampaignDiscountName();
  const { closeModal, args } = useModal(MODALS.CAMPAIGN_DISCOUNTS_CREATE_MODAL);

  const INITIAL_VALUES = getDiscountInitialValues(args);

  const onCloseModal = () => {
    closeModal();
  };

  const onSubmit = async (data: any) => {
    const concatenatedStartDate = concatDateWithTime(data?.startDate, data?.startTime);
    const concatenatedEndDate = concatDateWithTime(data?.endDate, data?.endTime);
    const concatenatedDate = {
      startDate: concatenatedStartDate,
      startTime: concatenatedStartDate,
      endDate: concatenatedEndDate,
      endTime: concatenatedEndDate,
    };

    if (args?.type === 'Edit Discount Code') {
      if (args?.usesCount === 0) {
        try {
          await updateDiscount({
            data: {
              totalUses: null,
              ...data,
              experienceType: {
                reconnect: data.experienceType && {
                  id: data.experienceType,
                },
              },
              ...concatenatedDate,
            },
            discountId: args.id,
          });
          notification.success(t('discount_update_success', { discountName: data.name }));
        } catch (error) {
          notification.error(t('discount_update_error', { discountName: data.name }));
        }
      } else {
        notification.error(t('discount_update_usage_error', { discountName: data.name }));
      }
    } else {
      try {
        await createDiscount({ ...data, isEnabled: args?.isEnabled, ...concatenatedDate });
        notification.success(t('discount_create_success', { discountName: data.name }));
      } catch (error) {
        notification.error(t('discount_create_error', { discountName: data.name }));
      }
    }
    onCloseModal();
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={INITIAL_VALUES}
      subscription={{ validating: true, submitting: true, initialValues: true }}
      keepDirtyOnReinitialize
      validate={values =>
        validateWithSchema(CampaignAddDiscountsFormSchema, values, {
          context: {
            initialStartDate: INITIAL_VALUES.startDate,
            initialStartTime: INITIAL_VALUES.startTime,
            startDate: values.startDate,
            startTime: values.startTime,
            endDate: values.endDate,
          },
        })
      }
    >
      {({ submitting, pristine, handleSubmit, validating }) => {
        return (
          <form>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      validate={discountName =>
                        debouncedNameValidator(discountName, INITIAL_VALUES.name)
                      }
                      name="name"
                      label="Name"
                      component={TextField}
                      disabled={submitting}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="category"
                      label="Category"
                      options={DISCOUNT_CATEGORY_OPTIONS}
                      component={SelectField}
                      disabled={submitting}
                      required
                      fullWidth
                    />
                  </Grid>
                  <CampaignDiscountFormAmountSection />
                </Grid>
              </Grid>
              <CampaignDiscountFormDateTimeSection />
              <CampaignDiscountFormAllocationSection />
              <CampaignDiscountFormUsageSection />
              <Grid item>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button disabled={submitting} variant="text" onClick={onCloseModal}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      loading={submitting || validating}
                      disabled={pristine || validating}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
};
