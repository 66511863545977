import React from 'react';
import { Box, styled } from '@mui/material';
import {
  AssignmentOutlined as AssignmentIcon,
  AssignmentLateOutlined as AssignmentLateIcon,
  ShoppingCartOutlined as ShoppingCartIcon,
  PaymentsOutlined as PaymentsIcon,
} from '@mui/icons-material';

import { formatMoney, formatNumber } from '@vizsla/utils';

import { useStoreAsset } from 'src/hooks/storeAssets';
import { InformationCard } from 'src/components/shared';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

const StoreAssetManagementInfoCards: React.FC = () => {
  const { storeAsset } = useStoreAsset();

  const { total, aviable, sales: salesAmount, profit: profitAmount } = storeAsset;

  const totalInventory = total || 0;
  const availableInventory = aviable || 0;
  const sales = salesAmount || 0;
  const profit = profitAmount || 0;

  return (
    <StyledBox>
      <InformationCard
        icon={<AssignmentIcon color="secondary" fontSize="large" />}
        title="Total Inventory"
        value={formatNumber(totalInventory)}
      />

      <InformationCard
        icon={<AssignmentLateIcon color="secondary" fontSize="large" />}
        title="Available Inventory"
        value={formatNumber(availableInventory)}
      />

      <InformationCard
        icon={<ShoppingCartIcon color="secondary" fontSize="large" />}
        title="Sales"
        value={formatMoney(sales)}
      />

      <InformationCard
        icon={<PaymentsIcon color="secondary" fontSize="large" />}
        title="Profit"
        value={formatMoney(profit)}
      />
    </StyledBox>
  );
};

export { StoreAssetManagementInfoCards };
