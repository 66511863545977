import React from 'react';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';
import { Grid, Typography } from '@mui/material';

import { Field, RadioGroupField, SelectField } from '@vizsla/components';
import { ExperienceType } from '@vizsla/types';

import { ExperiencePrivacy } from 'src/types/experience';
import { useCampaignId } from 'src/hooks/campaign';
import { useExperiences } from 'src/hooks/experiences';

const PRIVACY_OPTIONS: Array<any> = [
  {
    label: (
      <React.Fragment>
        <Typography component="span" variant="h6">
          Public:
        </Typography>
        &nbsp;Anyone can register (Default).
      </React.Fragment>
    ),
    value: ExperiencePrivacy.public,
  },
  {
    label: (
      <React.Fragment>
        <Typography component="span" variant="h6">
          White-List:
        </Typography>
        &nbsp;Attendees must be on my Attendee List.
      </React.Fragment>
    ),
    value: ExperiencePrivacy.whiteList,
  },
  {
    label: (
      <React.Fragment>
        <Typography component="span" variant="h6">
          Fundraiser Qualifier:
        </Typography>
        &nbsp;Attendee should raised specific amount to participate.
      </React.Fragment>
    ),
    value: ExperiencePrivacy.fundraiserQualifier,
  },
];

export const PrivacyBlock: React.FC = () => {
  const campaignId = useCampaignId();
  const experienceFilter = {
    campaign: {
      id: {
        equals: campaignId,
      },
    },
    experienceType: {
      equals: ExperienceType.registration,
    },
  };

  const { data } = useExperiences(campaignId, experienceFilter);
  const experienceOptions = data.map(({ id, name }) => ({ label: name || '', value: id || '' }));

  return (
    <Grid container item direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6">Privacy</Typography>
      </Grid>
      <Grid item>
        <Field name="privacy" component={RadioGroupField} options={PRIVACY_OPTIONS} />
      </Grid>

      <FormSpy subscription={{ values: true }}>
        {({ values }: FormSpyRenderProps) => {
          const { privacy } = values;

          if (privacy === ExperiencePrivacy.public) {
            return null;
          }

          return (
            <Grid container padding={2}>
              <Grid item xs={4}>
                <Field
                  name="whiteListRegistrationExperience"
                  component={SelectField}
                  options={experienceOptions}
                  fullWidth
                />
              </Grid>
            </Grid>
          );
        }}
      </FormSpy>
    </Grid>
  );
};
