import React from 'react';
import { Typography } from '@mui/material';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';

import { generateSlug } from '@vizsla/utils';

import { useFullPublicWebLink } from 'src/hooks/publicPages';

export const CampaignPublicPageUrlSpyRender: React.FC = () => {
  const { getFullPublicWebLink } = useFullPublicWebLink();

  return (
    <FormSpy subscription={{ values: true }}>
      {(spyRenderProps: FormSpyRenderProps) => {
        const { name: campaignName } = spyRenderProps.values;

        if (campaignName) {
          const campaignSlug = generateSlug(campaignName);
          const publicCampaignPageUrl = getFullPublicWebLink(campaignSlug);

          return (
            <Typography paragraph variant="body2" mt={1}>
              Campaign Public Url: {publicCampaignPageUrl}
            </Typography>
          );
        }

        return null;
      }}
    </FormSpy>
  );
};
