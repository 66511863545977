import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { RouterEntitiesId } from '@vizsla/constants';

export const useCampaignId = () => {
  const params = useParams();

  return useMemo(() => params[RouterEntitiesId.campaign] as string, [params]);
};
