import React from 'react';
import { Grid, List, ListItem, SxProps, TextField } from '@mui/material';

import { ExperienceLandingActions, useLandingState } from '@vizsla/providers';
import {
  EditorTextInputFieldSizeType,
  EDITOR_TEST_TOTAL_RAISED,
  EDITOR_TEST_FUNDRAISING_GOAL,
} from '@vizsla/constants';
import { FontSize } from '@vizsla/theme';
import { calcPercentage, formatMoney } from '@vizsla/utils';

import { VisibilityButton } from '../../shared';

const actionButtonSxProp: SxProps = {
  width: '288px',
  height: '40px',
  padding: '4px',
  fontSize: FontSize.Default,
};

export const RaisedProgressBarSection: React.FC = () => {
  const { state, dispatch } = useLandingState();

  const isProgressBarGoalAmountVisible = state.theme.isProgressBarGoalAmountVisible ?? true;
  const isProgressBarRaisedAmountVisible = state.theme.isProgressBarRaisedAmountVisible ?? true;

  const percentage = calcPercentage(EDITOR_TEST_TOTAL_RAISED, EDITOR_TEST_FUNDRAISING_GOAL);

  const raisedAmountFieldValue = `${formatMoney(EDITOR_TEST_TOTAL_RAISED)} raised`;
  const goalAmountFieldValue = `${percentage}% of ${formatMoney(
    EDITOR_TEST_FUNDRAISING_GOAL,
  )} goal`;

  return (
    <List component="div">
      <ListItem disablePadding sx={theme => ({ paddingBottom: theme.spacing(1.5) })}>
        <Grid container spacing={1}>
          <Grid item>
            <TextField
              variant="outlined"
              size={EditorTextInputFieldSizeType.small}
              InputProps={{
                sx: actionButtonSxProp,
              }}
              value={raisedAmountFieldValue}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item>
            <VisibilityButton
              isVisible={isProgressBarRaisedAmountVisible}
              onClick={() =>
                dispatch({
                  type: ExperienceLandingActions.updateIsProgressBarRaisedAmountVisible,
                  payload: !isProgressBarRaisedAmountVisible,
                })
              }
            />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem disablePadding sx={theme => ({ paddingBottom: theme.spacing(3) })} divider>
        <Grid container spacing={1}>
          <Grid item>
            <TextField
              variant="outlined"
              size={EditorTextInputFieldSizeType.small}
              InputProps={{
                sx: actionButtonSxProp,
              }}
              value={goalAmountFieldValue}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item>
            <VisibilityButton
              isVisible={isProgressBarGoalAmountVisible}
              onClick={() =>
                dispatch({
                  type: ExperienceLandingActions.updateIsProgressBarGoalAmountVisible,
                  payload: !isProgressBarGoalAmountVisible,
                })
              }
            />
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );
};
