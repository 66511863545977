import React from 'react';
import { Grid, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useDnsRecordValidator } from '@vizsla/hooks';
import { DateFormatPatterns } from '@vizsla/constants';
import { getNowDate, toDateTimeFromISO } from '@vizsla/utils';
import { Field, TextField, DateField, TimeField, GridDefaultPadding } from '@vizsla/components';

import { useCampaignRegistration } from 'src/hooks/campaign';
import { CampaignPublicPageUrlSpyRender, CampaignTypeRadioGroup } from 'src/components/campaign';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  fieldContainer: {
    marginBottom: theme.spacing(2),
  },
}));

export const CampaignImpactGivingFormOne: React.FC = () => {
  const classes = useStyles();

  const { campaignType, changeCampaignType } = useCampaignRegistration();
  const { validateCampaignName } = useDnsRecordValidator();

  const currentDate = toDateTimeFromISO(getNowDate());

  return (
    <div className={classes.root}>
      <Grid item xs={12} p={1}>
        <Typography variant="subtitle2">What kind of campaign are you launching?</Typography>
      </Grid>
      <Grid className={classes.fieldContainer} item xs={12}>
        <CampaignTypeRadioGroup
          defaultValue={campaignType}
          onChangeCampaignType={changeCampaignType}
        />
      </Grid>
      <Grid container item>
        <GridDefaultPadding className={classes.fieldContainer} item xs={12}>
          <Field
            label="Campaign Name"
            name="name"
            component={TextField}
            validate={validateCampaignName}
            fullWidth
            required
          />
          <CampaignPublicPageUrlSpyRender />
        </GridDefaultPadding>
        <Grid container direction="row">
          <GridDefaultPadding item xs={6} p={1}>
            <Typography variant="subtitle2">Campaign Dates</Typography>
          </GridDefaultPadding>
        </Grid>
        <Grid container>
          <GridDefaultPadding item xs={6}>
            <Field
              label="Start Date"
              name="startDate"
              component={DateField}
              inputFormat={DateFormatPatterns.shortMonthDayYear}
              minDate={currentDate}
              fullWidth
              required
            />
          </GridDefaultPadding>
          <GridDefaultPadding item xs={6}>
            <Field label="Start Time" name="startTime" component={TimeField} fullWidth required />
          </GridDefaultPadding>
        </Grid>
      </Grid>
    </div>
  );
};
