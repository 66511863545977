import React from 'react';
import { Box, Typography } from '@mui/material';

import { useModal } from '@vizsla/hooks';
import { FullScreenModal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import { RegistrationOptionCreateForm } from 'src/components/registrationOption';

const RegistrationOptionCreateModal: React.FC = () => {
  const { isOpen, closeModal, args } = useModal(MODALS.REGISTRATION_OPTION_CREATE_MODAL);

  return (
    <FullScreenModal isOpen={isOpen} onClose={closeModal}>
      <Typography textAlign="center" variant="h1" mb={6}>
        Let&apos;s add Registration Option
        <br />
        to your Experience
      </Typography>
      <Box width="100%">
        <RegistrationOptionCreateForm experienceId={args?.experienceId || ''} />
      </Box>
    </FullScreenModal>
  );
};

export default RegistrationOptionCreateModal;
