import React from 'react';
import { Grid, Theme, Typography, Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMutation } from '@apollo/client';

import { Shade, BorderRadius } from '@vizsla/theme';
import { useCurrentUser, useCurrentOrganization } from '@vizsla/hooks';

import { CHECKOUT_SUBSCRIPTION, UPDATE_VIZSLA_STRIPE_PLAN } from 'src/graphql/stripe';

const useStyles = makeStyles((theme: Theme) => ({
  innerBlock: {
    border: `1px solid ${Shade.Gray[100]}`,
    borderRadius: BorderRadius.M,
    padding: theme.spacing(2, 3),
    marginBottom: '10px',
    marginTop: '10px',
  },
  innerBlockCurrentPlant: {
    borderRadius: BorderRadius.M,
    backgroundColor: '#F0FBFD',
    padding: theme.spacing(2, 3),
    marginBottom: '10px',
    marginTop: '10px',
  },
  price: {
    display: 'flex',
    justifyContent: 'center',
    alingItems: 'center',
    fontWeight: 'bold',
    fontSize: '24px',
  },
  textInterval: {
    display: 'flex',
    justifyContent: 'center',
    alingItems: 'center',
    margin: '1px',
    padding: '5px',
  },
  buttoConnect: {
    display: 'flex',
    justifyContent: 'right',
  },
  currentPlan: {
    display: 'flex',
    justifyContent: 'center',
    alingItems: 'center',
    height: '20px',
    width: '84px',
    backgroundColor: '#00BFD4',
    borderRadius: '25px',
    padding: '5px',
  },
}));

export const ShowPlans = ({ activePlans, products, vizlaUserStripePlan }) => {
  const classes = useStyles();
  const [loadingButton, setLoadingButton] = React.useState<boolean>(false);
  const [createCheckoutSession] = useMutation(CHECKOUT_SUBSCRIPTION);
  const [updateVizslaStripePlan] = useMutation(UPDATE_VIZSLA_STRIPE_PLAN);
  const { user } = useCurrentUser();
  const { organizationId } = useCurrentOrganization();
  const userId = React.useMemo(() => user?.id, [user]);

  const currentPrice = React.useMemo(() => {
    const plans = products.map(item => item.pricesInterval[activePlans]);
    if (vizlaUserStripePlan.length === 0) {
      return 0;
    }
    const planId = vizlaUserStripePlan[0].priceId;
    return (
      ([] as any[]).concat(...plans).find((item: any) => item.id === planId)?.unit_amount / 100
    );
  }, [vizlaUserStripePlan]);

  const onSubmit = async event => {
    setLoadingButton(true);
    const priceId = event.target.value;
    try {
      const checkout = await createCheckoutSession({
        variables: {
          data: {
            priceId,
            userId,
            organizationId,
          },
        },
      });
      const { url } = checkout.data.checkoutSubscription;
      window.open(url, '_self');
    } catch (error) {
      console.error(error);
    }
  };

  const onUpdate = async event => {
    setLoadingButton(true);
    const priceId = event.target.value;
    const vizslaPlanId = vizlaUserStripePlan[0].id;
    try {
      await updateVizslaStripePlan({
        variables: {
          data: {
            vizslaPlanId,
            priceId,
            userId,
            organizationId,
          },
        },
      });
      setLoadingButton(false);
      window.location.reload();
    } catch (error) {
      setLoadingButton(false);
      console.log(error);
    }
  };

  const currentPlan = element => {
    const current = vizlaUserStripePlan?.find(
      plan => plan.organizationId === organizationId && plan.priceId === element.id,
    );
    return !!current;
  };
  const ButtonPlan = element => {
    enum Color {
      Primary = 'primary',
      Secondary = 'secondary',
      Info = 'info',
    }
    const price = element.element.unit_amount / 100;
    let name = 'Upgrade';
    let color: Color = Color.Secondary;
    let connect: any = onUpdate;
    if (vizlaUserStripePlan.length === 0) {
      name = 'Connect';
      color = Color.Primary;
      connect = onSubmit;
    }

    if (price < currentPrice) {
      name = 'Downgrade';
      color = Color.Info;
      connect = onUpdate;
    }

    return (
      <Button
        id={element.element.id}
        value={element.element.id}
        variant="contained"
        size="small"
        color={color}
        onClick={event => {
          connect(event);
        }}
        disabled={loadingButton}
      >
        {name}
      </Button>
    );
  };
  return (
    <Box>
      {products.map(item => (
        <Box key={item.id}>
          {item.pricesInterval[activePlans].map(element => (
            <Grid
              key={element.id}
              container
              className={currentPlan(element) ? classes.innerBlockCurrentPlant : classes.innerBlock}
              justifyContent="space-between"
              alignItems="center"
              xs={12}
            >
              <Grid item xs={7} display="flex-row">
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <Typography fontWeight="bold" fontSize="24px">
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid item display="flex" justifyContent="center" alignItems="center">
                    {currentPlan(element) && (
                      <Box className={classes.currentPlan}>
                        <Typography
                          color="white"
                          fontSize="12px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          Current Plan
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Typography variant="body2">{item.description}</Typography>
              </Grid>
              <Grid
                container
                xs={4}
                direction="row"
                display="flex"
                justifyContent="right"
                alignItems="right"
              >
                <Grid item>
                  <Box>
                    <Typography className={classes.price}>${element.unit_amount / 100}</Typography>
                  </Box>
                </Grid>
                <Grid item>
                  {activePlans === 'monthly' && (
                    <Box className={classes.textInterval}>
                      <Typography
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        variant="body2"
                        fontSize="16px"
                      >
                        / mo.
                      </Typography>
                    </Box>
                  )}
                  {activePlans === 'yearly' && (
                    <Box className={classes.textInterval}>
                      <Typography
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        variant="body2"
                        fontSize="16px"
                      >
                        / yl.
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={1} className={classes.price}>
                {!currentPlan(element) && <ButtonPlan element={element} />}
              </Grid>
            </Grid>
          ))}
        </Box>
      ))}
    </Box>
  );
};
