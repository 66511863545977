import { gql } from '@apollo/client';

import { USER_FRAGMENT } from '@vizsla/graphql';

export const VIZSLA_ORGANIZATION_CREATE_MUTATION = gql`
  mutation OrganizationCreate($data: JSON!) {
    organizationCreate(data: $data) {
      success
    }
  }
`;

export const VIZSLA_ORGANIZATION_STAFF_QUERY = gql`
  query VizslaOrganizationStaff(
    $id: ID!
    $filter: VizslaOrganizationStaffFilter
    $skip: Int
    $first: Int
  ) {
    vizslaOrganization(id: $id) {
      id
      vizslaOrganizationStaff(filter: $filter, skip: $skip, first: $first) {
        count
        items {
          id
          user {
            ...UserFragment
            avatar {
              id
              downloadUrl
            }
          }
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const VizslaOrganizationUpdateNotificationSettings = gql`
  mutation VizslaOrganizationUpdateNotificationSettings(
    $id: ID!
    $connect: [NotificationSettingKeyFilter!]
    $disconnect: [NotificationSettingKeyFilter!]
  ) {
    vizslaOrganizationUpdate(
      data: { id: $id, notificationItems: { connect: $connect, disconnect: $disconnect } }
    ) {
      id
      name
      notificationItems {
        items {
          key
          id
        }
      }
    }
  }
`;
