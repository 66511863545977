import React from 'react';
import { Box, styled } from '@mui/material';

import { useModal } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';

import { StoreAssetInventoryAdjustForm } from './components';

const StyledModalContentBox = styled(Box)(({ theme }) => ({
  margin: 0,
  '& > *': {
    paddingBottom: theme.spacing(1),
  },
}));

const StoreAssetInventoryAdjustModal: React.FC = () => {
  const { isOpen, closeModal } = useModal(MODALS.STORE_ASSET_INVENTORY_ADJUST_MODAL);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth>
      <Modal.Title>Adjust Inventory</Modal.Title>

      <StyledModalContentBox>
        <Modal.Content>
          <StoreAssetInventoryAdjustForm closeModal={closeModal} />
        </Modal.Content>
      </StyledModalContentBox>
    </Modal>
  );
};

export { StoreAssetInventoryAdjustModal };
