import { gql } from '@apollo/client';

export const ORDERS_ORGANIZATION_QUERY = gql`
  query GetOrders($filter: TransactionVizslaViewExpFilter, $skip: Int, $first: Int) {
    transactionVizslaViewExpsList(
      filter: $filter
      sort: { OrderDate: DESC }
      skip: $skip
      first: $first
    ) {
      count
      items {
        id
        OrderDate
        OrderStatus
        Ordersubtype
        OrderItemTransType
        OrderitemCommentDetail
        OrderItemsId
        UsersFirsName
        UsersLastName
        CampaignName
        ExperienceName
        ExperienceId
        OrderTotalAmount
        OrderItemsAmount
        OrderPaymentMethod
        OrderitemCommentDetail
        Userid
      }
    }
  }
`;
