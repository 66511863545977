import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { nanoid } from 'nanoid';
/**
 * Generates an instance of tools to create a reactive cart ready-to-use.
 * @example
 * ```tsx
 * // useCart.ts
 *
 * export const useCart = createCartStore()
 *
 * const calculateTotal = (items: CartItem[]) => {
 *  return items.reduce((acc, item) => acc + item.price, 0)
 * };
 *
 * // CartResume.tsx
 *
 * function CartResume() {
 *  const { items, total } = useCart(state => ({
 *    items,
 *    total: calculateTotal(state.items),
 *  }))
 *
 *  return (
 *    <div>
 *      {items.map(item => (
 *        <div>
 *          <span>{item.name}</span>
 *          <span>${item.price}</span>
 *        </div>
 *      ))}
 *
 *      <span>Total: {total}</span>
 *    </div>
 *  )
 * }
 * ```
 */
export function createCartStore() {
    const INITIAL_STATE = {
        items: [],
    };
    return create(immer(setState => (Object.assign(Object.assign({}, INITIAL_STATE), { add(item) {
            setState(state => {
                state.items.push(Object.assign(Object.assign({}, item), { id: nanoid() }));
            });
        },
        edit(item) {
            setState(state => {
                state.items = state.items.map(prev => {
                    if (prev.id !== item.id)
                        return prev;
                    return Object.assign(Object.assign({}, prev), item);
                });
            });
        },
        editMany(items) {
            setState(state => {
                const newest = new Map(items.map(i => [i.id, i]));
                state.items = state.items.map(prev => {
                    if (!newest.has(prev.id))
                        return prev;
                    return Object.assign(Object.assign({}, prev), newest.get(prev.id));
                });
            });
        },
        remove(item) {
            setState(state => {
                state.items = state.items.filter(i => i.id !== item.id);
            });
        },
        removeMany(items) {
            setState(state => {
                const ids = new Set(items.map(i => i.id));
                const newItems = state.items.filter(i => !ids.has(i.id));
                return { items: newItems };
            });
        },
        clean() {
            setState(Object.assign({}, INITIAL_STATE));
        } }))));
}
