import React from 'react';
import { Grid } from '@mui/material';

import {
  CampaignIndividualFundraisingSettings,
  CampaignTeamFundraisingSettings,
} from 'src/components/campaign';

import { FundraisingSettings } from './FundraisingSettings';
import { CampaignDonationSettings } from './CampaignDonationSettings';

const CampaignFundraising: React.FC = () => {
  return (
    <Grid spacing={3} container flexDirection="column">
      <Grid item>
        <CampaignDonationSettings />
      </Grid>
      <Grid item>
        <FundraisingSettings />
      </Grid>
      <Grid item>
        <CampaignIndividualFundraisingSettings />
      </Grid>
      <Grid item>
        <CampaignTeamFundraisingSettings />
      </Grid>
    </Grid>
  );
};

export { CampaignFundraising };
