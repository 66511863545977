import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Theme, Typography } from '@mui/material';
import { Event } from '@mui/icons-material';

import { getStringifiedDateRange, isValidDate } from '@vizsla/utils';

import { PaletteColor, FontSize } from 'src/theme';

interface DateRangeProps {
  startDate: string;
  endDate?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  date: {
    color: PaletteColor.GrayText,
  },
  iconContainer: {
    width: 14,
    height: 14,
    '& .MuiSvgIcon-root': {
      fontSize: FontSize.Default,
      fill: PaletteColor.GrayText,
    },
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
}));

export const DateRange: React.FC<DateRangeProps> = ({ startDate, endDate }) => {
  const classes = useStyles();

  const getDateRange = () => {
    if (!isValidDate(startDate) && !isValidDate(endDate)) {
      return '-';
    }

    return getStringifiedDateRange(startDate, endDate);
  };

  return (
    <Typography variant="caption" className={classes.date}>
      <Grid container alignItems="center">
        <Grid item className={classes.iconContainer}>
          <Event />
        </Grid>
        <Grid item>{getDateRange()}</Grid>
      </Grid>
    </Typography>
  );
};
