import type { RouteProps } from '@vizsla/types';

import * as appRoutes from 'src/constants/routes';
import {
  OrganizationSettingsCheckout,
  OrganizationSettingsAccountDetails,
  OrganizationSettingsTeamMembers,
  OrganizationSettingsBilling,
  OrganizationSettingsIntegrations,
  OrganizationSettingsNotifications,
} from 'src/components/organization';

import { OrganizationSettingsPageIndex } from './OrganizationSettingsPageIndex';

export const organizationSettingsPageRoutes: RouteProps[] = [
  {
    index: true,
    element: OrganizationSettingsPageIndex,
  },
  {
    path: appRoutes.organizationSettingsAccountDetails,
    element: OrganizationSettingsAccountDetails,
  },
  {
    path: appRoutes.organizationSettingsTeamMembers,
    element: OrganizationSettingsTeamMembers,
  },
  {
    path: appRoutes.organizationSettingsBilling,
    element: OrganizationSettingsBilling,
  },
  {
    path: appRoutes.organizationSettingsIntegrations,
    element: OrganizationSettingsIntegrations,
  },
  {
    path: appRoutes.organizationSettingsCheckout,
    element: OrganizationSettingsCheckout,
  },
  {
    path: appRoutes.organizationSettingsNotifications,
    element: OrganizationSettingsNotifications,
  },
];
