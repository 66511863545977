import React from 'react';
import { styled } from '@mui/material';

import { ExperienceLanding, LandingHeader } from '@vizsla/components';
import { useExperienceLanding, useLandingState } from '@vizsla/providers';
import { File } from '@vizsla/graphql';
import { HeaderLogoAlignType } from '@vizsla/constants';

import {
  EXP_HEADER_IMAGE_1,
  EXP_HEADER_IMAGE_2,
  EXP_LOGO_IMAGE,
} from 'src/assets/img/experienceLandingImages';

const StyledRootDiv = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateAreas: '"header" "content"',
  gridTemplateRows: '64px auto',
  zIndex: 1,
}));

export const TeamFundraiserLadingWrapper: React.FC = () => {
  const { experience, loading } = useExperienceLanding();
  const { state: experienceLandingState } = useLandingState();

  const EXPERIENCE_HEADER_IMAGES: Array<File> = [EXP_HEADER_IMAGE_1, EXP_HEADER_IMAGE_2].map(
    (headerImage: string) => ({
      fileId: headerImage,
      downloadUrl: headerImage,
    }),
  );

  const headerLogoAlign =
    experienceLandingState?.theme?.headerLogoAlign || HeaderLogoAlignType.left;
  const headerBackground = experienceLandingState?.theme?.headerBackground || '';

  return (
    <StyledRootDiv>
      <LandingHeader
        logoImageUrl={EXP_LOGO_IMAGE}
        headerLogoAlign={headerLogoAlign}
        headerBackground={headerBackground}
        editorMode
      />

      <ExperienceLanding
        experience={experience}
        loading={loading}
        experienceLandingState={experienceLandingState}
        headerImages={EXPERIENCE_HEADER_IMAGES}
        editorMode
      />
    </StyledRootDiv>
  );
};
