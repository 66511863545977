import React from 'react';
import { Outlet } from 'react-router-dom';

import { AdminSettingsLayout } from 'src/layouts/AdminSettingsLayout';

const AdminSettingsPage: React.FC = () => {
  return (
    <AdminSettingsLayout>
      <Outlet />
    </AdminSettingsLayout>
  );
};

export { AdminSettingsPage };
