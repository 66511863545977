import React from 'react';
import { FileInput } from '8base-react-sdk';
import { makeStyles } from '@mui/styles';
import { Add as AddIcon } from '@mui/icons-material';

import { useModal } from '@vizsla/hooks';
import { TableBottomAction, TableColumn, TableData, TableRowAction } from '@vizsla/components';
import { File } from '@vizsla/graphql';

import { MODALS } from 'src/constants/modals';

import { useExperienceGallery } from './useExperienceGallery';

const MAX_IMAGE_SIZE = 8 * 1024 * 1024;

const useStyles = makeStyles({
  image: {
    maxWidth: 100,
    maxHeight: 100,
  },
});

export function CampaignExperienceGallery() {
  const { images, loading, attachImages, detachImage } = useExperienceGallery();
  const { openModal } = useModal();

  const styles = useStyles();

  const TABLE_COLUMNS: TableColumn<File>[] = [
    {
      key: 'image',
      title: 'Image',

      render(path, record) {
        return (
          <img
            className={styles.image}
            src={record.downloadUrl ?? undefined}
            alt={record.filename ?? 'Gallery photo'}
          />
        );
      },
    },

    {
      key: 'name',
      title: 'Name',

      render(path, record) {
        return <p>{record.filename}</p>;
      },
    },
  ];

  const ROW_ACTIONS: TableRowAction<File>[] = [
    {
      actionName: 'Delete',
      onAction(record) {
        openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
          deleteFunction: () => detachImage(record),
          objectName: record.filename,
        });
      },
    },
  ];

  const createBottomActions = (options: any): TableBottomAction[] => [
    {
      actionName: 'Add Images',
      icon: <AddIcon />,

      async onAction() {
        await options.picker({
          maxSize: MAX_IMAGE_SIZE,
          accept: ['image/jpeg', 'image/png'],
          fromSources: ['local_file_system'],
        });
      },
    },
  ];

  return (
    <FileInput maxFiles={1000} onChange={attachImages}>
      {wrapper => (
        <TableData
          loading={loading}
          dataSource={images}
          columns={TABLE_COLUMNS}
          rowActions={ROW_ACTIONS}
          bottomActions={createBottomActions({ picker: wrapper.pick })}
        />
      )}
    </FileInput>
  );
}
