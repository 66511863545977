import React from 'react';
import { Grid, Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { Modal } from '@vizsla/components';

import { AtributesField } from './Componets';

interface StoreAssetFormAttributesSectionProps {
  disabled?: boolean;
  setVariantsPossible: any;
  variantsPossible: any;
}

const useStyles = makeStyles(() => ({
  actionButton: {
    border: 0,
    textTransform: 'uppercase',
    '&:hover': {
      border: 0,
    },
    '&.Mui-disabled': {
      border: 0,
    },
  },
}));

const StoreAssetFormAttributesSection: React.FC<StoreAssetFormAttributesSectionProps> = ({
  disabled = false,
  setVariantsPossible = () => {},
  variantsPossible = [],
}) => {
  const classes = useStyles();

  const [varaintName, setVaraintName] = React.useState('');

  const setNewVariant = () => {
    if (varaintName) {
      setVariantsPossible([...variantsPossible, { id: Date.now(), [varaintName]: [] }]);
      setVaraintName('');
    }
  };

  const setVariantValue = (newVariant, variantName, variantArray) => {
    const mapNewVariants = variantsPossible.map(variant =>
      variant.id === newVariant.id ? { ...variant, [variantName]: variantArray } : variant,
    );
    setVariantsPossible(mapNewVariants);
  };

  const removeVariant = newVariant => {
    const mapNewVariants = variantsPossible.filter(variant => newVariant.id !== variant.id);
    setVariantsPossible(mapNewVariants);
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Modal.ContentText>Attributes</Modal.ContentText>
      </Grid>
      {variantsPossible.map(variant => (
        <AtributesField
          key={variant.id}
          variant={variant}
          setVariantValue={setVariantValue}
          removeVariant={removeVariant}
        />
      ))}

      {variantsPossible?.length < 3 && (
        <Grid item xs={12} display="flex" alignItems="center">
          <TextField
            variant="outlined"
            label="Attribute Name"
            value={varaintName}
            onChange={({ target: { value } }) => setVaraintName(value)}
          />
          <Button
            className={classes.actionButton}
            variant="outlined"
            size="medium"
            color="secondary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={setNewVariant}
          >
            Add
          </Button>
        </Grid>
      )}
    </React.Fragment>
  );
};

export { StoreAssetFormAttributesSection };
