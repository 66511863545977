import React from 'react';
import { makeStyles } from '@mui/styles';
import { List, ListItemText, Divider, Theme, Typography } from '@mui/material';

import { PaletteColor, Shade } from '@vizsla/theme';
import { ListNavLinkItem } from '@vizsla/components';

import * as appRoutes from 'src/constants/routes';

const useStyles = makeStyles((theme: Theme) => ({
  menuItemHeaderText: {
    fontSize: 10,
    letterSpacing: 2,
    color: 'grey',
  },
  wrapText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuItem: {
    borderRadius: 6,
    marginBottom: theme.spacing(1),
    '&.Mui-selected': {
      backgroundColor: Shade.Blue['25'],
      '& .MuiSvgIcon-root': {
        color: PaletteColor.PrimaryMain,
      },
      '& .MuiTypography-root': {
        color: PaletteColor.PrimaryMain,
      },
    },
  },
}));

interface CampaignMenuItemHeaderProps {
  children: React.ReactNode;
}

export const CampaignMenuItemHeader: React.FC<CampaignMenuItemHeaderProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <Divider>
      <Typography className={classes.menuItemHeaderText}>{children}</Typography>
    </Divider>
  );
};

const MENU_ITEMS = [
  {
    to: appRoutes.organizationSettingsAccountDetails,
    text: 'Account Details',
  },
  {
    to: appRoutes.organizationSettingsTeamMembers,
    text: 'Team Members',
  },
  {
    to: appRoutes.organizationSettingsBilling,
    text: 'Billing',
  },
  {
    to: appRoutes.organizationSettingsIntegrations,
    text: 'Integrations',
  },
  {
    to: appRoutes.organizationSettingsCheckout,
    text: 'Checkout',
  },
  {
    to: appRoutes.organizationSettingsNotifications,
    text: 'Notifications',
  },
];

type MenuItem = {
  to: string;
  text: string;
};

export const SideMenu: React.FC = () => {
  const classes = useStyles();

  const renderMenuItem = ({ to, text }: MenuItem) => {
    return (
      <ListNavLinkItem className={classes.menuItem} to={to} key={to}>
        <ListItemText className={classes.wrapText}>{text}</ListItemText>
      </ListNavLinkItem>
    );
  };

  return <List>{MENU_ITEMS.map(renderMenuItem)}</List>;
};
