import type {
  File,
  StoreAssetCreateInput,
  StoreAssetUpdateInput,
  ExperienceStoreAssetCreateInput,
  ExperienceStoreAssetUpdateMutationVariables,
  StoreHistoryEventCreateWithRecalcMutationVariables,
  StoreHistoryEventCreateWithRecalcInput,
} from '@vizsla/graphql';

import type {
  StoreAssetCreateOrUpdateFormValues,
  StoreAssetUpdateImageFormValues,
  StoreAssetInventoryAddOrAdjustFormValues,
  StoreHistoryEventType,
  ExperienceStoreAssetAddOrUpdateFormValues,
} from 'src/types/storeAssets';

const getImageDataFromValue = (imageValue: File | undefined) => {
  const imageData = imageValue
    ? {
        create: {
          fileId: imageValue.fileId,
          filename: imageValue.filename,
          public: imageValue.public,
        },
      }
    : undefined;

  return imageData;
};

export const getStoreAssetCreateDataInput = (
  values: StoreAssetCreateOrUpdateFormValues,
  organizationId: string,
) => {
  const imageData = getImageDataFromValue(values.image);

  // TO DO const stockMinimumRecipients = values?.stockMinimumRecipients || [];
  const stockMinimumRecipients = [];

  const stockMinimumRecipientsUsersId = stockMinimumRecipients.map(user => ({
    // TO DO id: user.id,
  }));

  const inputObject: StoreAssetCreateInput = {
    name: values.name || 'Asset name',
    image: imageData,
    description: values.description,
    swagbagAvailability: values.swagbagAvailability,
    price: values.price || 0,
    unitCost: values.unitCost || 0,
    continueSalesWhenOutOfStock: values.continueSalesWhenOutOfStock,
    notifyWhenStockMinimumIsAchieved: values.notifyWhenStockMinimumIsAchieved,
    stockMinimumBoundary: values.stockMinimumBoundary,
    // stockMinimumRecipients: {
    //   connect: stockMinimumRecipientsUsersId,
    // },
    note: values.note,
    vizslaOrganization: {
      connect: {
        id: organizationId,
      },
    },
  };

  return inputObject;
};

export const getStoreHistoryEventCreateDataInput = (
  values: StoreAssetInventoryAddOrAdjustFormValues,
  storeAssetId: string,
  eventType: StoreHistoryEventType,
) => {
  const recipients = values?.recipients || [];
  const recipientsId = recipients.map(user => user?.id || '');

  const inputObject: any = {
    type: String(eventType),
    quantity: values.quantity || 0,
    dateReceived: values.dateReceived,
    recipientsId,
    orderCost: values.orderCost,
    note: values.note,
    storeAssetId,
    variantsValues: values.variantsValues,
  };

  return inputObject;
};

export const getStoreAssetUpdateImageDataInput = (formValues: StoreAssetUpdateImageFormValues) => {
  const imageData = getImageDataFromValue(formValues.image);

  const inputObject: StoreAssetUpdateInput = {
    image: imageData,
  };

  return inputObject;
};

export const getExperienceStoreAssetDataInput = (
  formValues: ExperienceStoreAssetAddOrUpdateFormValues,
  experienceId: string,
) => {
  const inputObject: ExperienceStoreAssetCreateInput = {
    experience: {
      connect: {
        id: experienceId,
      },
    },
    storeAsset: {
      connect: {
        id: formValues.storeAsset?.id,
      },
    },
    price: formValues.price,
  };

  return inputObject;
};

export const getExperienceStoreAssetUpdateDataInput = (
  formValues: ExperienceStoreAssetAddOrUpdateFormValues,
  experienceStoreAssetId: string,
) => {
  const inputObject: ExperienceStoreAssetUpdateMutationVariables = {
    id: experienceStoreAssetId,
    data: {
      price: formValues.price,
    },
  };

  return inputObject;
};
