import { nanoid } from 'nanoid';

import { CampaignFaqAllocation } from '@vizsla/types';

import { PaletteColor, Shade } from 'src/theme';
import { ExperienceType } from 'src/types/experience';
import { CampaignStatus, CampaignType, PeopleTablesTab } from 'src/types/campaign';

export enum DateType {
  Single = 'single',
  Range = 'range',
}

export const STATUS_TRANSITIONS = {
  [CampaignStatus.draft]: [CampaignStatus.live, CampaignStatus.paused, CampaignStatus.completed],
  [CampaignStatus.paused]: [CampaignStatus.live, CampaignStatus.completed],
  [CampaignStatus.live]: [CampaignStatus.paused, CampaignStatus.completed],
  [CampaignStatus.completed]: [],
};

export const CAMPAIGN_FAQ_ALLOCATION_OPTIONS = ['Attendee', 'Donor'].map((allocation: string) => ({
  label: allocation,
  value: allocation,
}));

export const CAMPAIGN_REGISTRATION_INITIAL_FIRST_FORM_FIELDS = {
  name: '',
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  city: '',
  state: '',
};

export const CAMPAIGN_REGISTRATION_INITIAL_SECOND_FORM_FIELDS = {
  logo: null,
  image: null,
};

export const CAMPAIGN_REGISTRATION_INITIAL_THREE_FORM_FIELDS = {
  fundraisingGoal: null,
  attendanceFundraisersCountGoal: null,
  teamGoal: null,
};

export const EXPERIENCE_INITIAL_FORM_FIELDS = {
  uid: nanoid(),
  name: '',
  experienceCategory: '',
  sameAsCampaignDates: false,
  dateType: DateType.Single,
  experienceType: ExperienceType.registration,
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
};

export const CAMPAIGN_REGISTRATION_INITIAL_FOURTH_FORM_FIELDS = {
  experiences: [{ ...EXPERIENCE_INITIAL_FORM_FIELDS }],
};

export const CAMPAIGN_PERSONAL_MISSION_INITIAL_FIRST_FORM_FIELDS = {
  name: '',
  dateType: DateType.Single,
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
};

export const CAMPAIGN_PERSONAL_MISSION_INITIAL_SECOND_FORM_FIELDS = {
  logo: null,
  image: null,
};

export const CAMPAIGN_PERSONAL_MISSION_INITIAL_THREE_FORM_FIELDS = {
  fundraisingGoal: null,
  fundraiserGoal: null,
  teamGoal: null,
};

export const CAMPAIGN_IMPACT_GIVING_INITIAL_FIRST_FORM_FIELDS = {
  name: '',
  dateType: DateType.Single,
  startDate: '',
  startTime: '',
};

export const CAMPAIGN_IMPACT_GIVING_INITIAL_SECOND_FORM_FIELDS = {
  logo: null,
  image: null,
};

export const CAMPAIGN_IMPACT_GIVING_INITIAL_THREE_FORM_FIELDS = {
  totalRaised: null,
  donorGoal: null,
};

export const REGISTRATION_AND_TICKETING_PEOPLE_TABS = [
  PeopleTablesTab.Attendees,
  PeopleTablesTab.Fundraisers,
  PeopleTablesTab.Teams,
  PeopleTablesTab.Donors,
];

export const IMPACT_GIVING_PEOPLE_TABS = [PeopleTablesTab.Donors];
export const PERSONAL_MISSION_PEOPLE_TABS = [
  PeopleTablesTab.Fundraisers,
  PeopleTablesTab.Teams,
  PeopleTablesTab.Donors,
];

export const CAMPAIGN_TYPE_PEOPLE_TABS_MAP = {
  [CampaignType.registrationAndTicketing]: REGISTRATION_AND_TICKETING_PEOPLE_TABS,
  [CampaignType.personalMission]: PERSONAL_MISSION_PEOPLE_TABS,
  [CampaignType.impactGiving]: IMPACT_GIVING_PEOPLE_TABS,
};

export const DATE_RANGE_TYPE_OPTIONS = [
  {
    label: 'Single Date',
    value: DateType.Single,
  },
  {
    label: 'Date Range',
    value: DateType.Range,
  },
];
