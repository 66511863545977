import React from 'react';
import { Grid, Typography } from '@mui/material';
import _ from 'lodash';

import { usePagination, usePaginationQueryVariables, useSearchQueryVariables } from '@vizsla/hooks';
import { TableData } from '@vizsla/components';
import type { TableColumn, TableRowAction } from '@vizsla/components';
import { formatDate, formatMoney } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';
import { Donor, User, DonorFilter } from '@vizsla/graphql';

import { useCampaignId, useDonors } from 'src/hooks/campaign';
import { UserAvatarFullNameInline } from 'src/components/user';

const COLUMNS: Array<TableColumn<Donor>> = [
  {
    title: 'Name',
    key: 'name',
    dataPath: 'user',
    render: (user: User) => {
      if (_.isNil(user)) {
        return <Typography variant="body2">N/A</Typography>;
      }

      return <UserAvatarFullNameInline user={user} />;
    },
  },
  {
    title: 'Date',
    key: 'date',
    dataPath: 'createdAt',
    render: (createdAt: string) => (
      <Grid>
        <Typography variant="body2">
          {formatDate(createdAt, DateFormatPatterns.shortMonthDayYear)}
        </Typography>
      </Grid>
    ),
  },
  {
    title: 'Total Donated',
    key: 'totalDonated',
    render: (valueByDataPath: undefined, donor: Donor) => {
      if (_.isNil(donor.donations?.items)) {
        return <Typography variant="body2">N/A</Typography>;
      }

      const totalDonated = _.sumBy(donor.donations?.items, 'amount');

      return (
        <Grid>
          <Typography variant="subtitle2">{formatMoney(totalDonated)}</Typography>
        </Grid>
      );
    },
  },
];

const rowActions: Array<TableRowAction<Donor>> = [
  {
    actionName: 'View/Edit',
    onAction: () => {},
  },
  {
    actionName: 'Send Text Message',
    onAction: () => {},
  },
  {
    actionName: 'Compose an Email',
    onAction: () => {},
  },
  {
    actionName: 'Add a Note',
    onAction: () => {},
  },
];

export const CampaignPeopleDonorsTab: React.FC = () => {
  const campaignId = useCampaignId();

  const pagination = usePagination();
  const paginationQueryVariables = usePaginationQueryVariables();
  const searchQueryVariables = useSearchQueryVariables<DonorFilter>(searchValue => {
    return {
      user: {
        _fullText: searchValue,
      },
    };
  });

  const filterByCampaignId = {
    campaign: {
      id: {
        equals: campaignId,
      },
    },
  };

  const {
    donors,
    count: donorsTotalCount,
    loading,
  } = useDonors({
    campaignId,
    customFilter: {
      ...filterByCampaignId,
      ...searchQueryVariables,
    },
    queryVariables: {
      ...paginationQueryVariables,
    },
  });

  React.useEffect(() => {
    pagination.onCountChange(donorsTotalCount);
  }, [pagination, donorsTotalCount]);

  return (
    <TableData
      dataSource={donors}
      loading={loading}
      columns={COLUMNS}
      rowActions={rowActions}
      pagination={pagination}
    />
  );
};
