import { gql } from '@apollo/client';

export const CAMPAIGN_FAQ_EXPERIENCE_FRAGMENT = gql`
  fragment CampaignFAQExperienceFragment on Experience {
    id
    name
  }
`;

export const CAMPAIGN_FAQ_FRAGMENT = gql`
  fragment CampaignFaqFragment on CampaignFAQ {
    id
    question
    answer
    allocation {
      items {
        ...CampaignFAQExperienceFragment
      }
    }
    userGroup
    # TO DO (StoreAsset)
    # campaign {
    #   id
    # }
  }
  ${CAMPAIGN_FAQ_EXPERIENCE_FRAGMENT}
`;

export const CAMPAIGN_FAQS_LIST_QUERY = gql`
  query CampaignFAQsList($filter: CampaignFAQFilter) {
    campaignFAQSList(filter: $filter) {
      items {
        ...CampaignFaqFragment
      }
    }
  }
  ${CAMPAIGN_FAQ_FRAGMENT}
`;

export const CAMPAIGN_FAQ_CREATE_MUTATION = gql`
  mutation campaignFAQCreate($data: CampaignFAQCreateInput!) {
    campaignFAQCreate(data: $data) {
      ...CampaignFaqFragment
    }
  }
  ${CAMPAIGN_FAQ_FRAGMENT}
`;

export const CAMPAIGN_FAQ_UPDATE_MUTATION = gql`
  mutation campaignFAQUpdate($data: CampaignFAQUpdateInput!, $filter: CampaignFAQKeyFilter!) {
    campaignFAQUpdate(data: $data, filter: $filter) {
      ...CampaignFaqFragment
    }
  }
  ${CAMPAIGN_FAQ_FRAGMENT}
`;

export const CAMPAIGN_FAQ_DELETE_MUTATION = gql`
  mutation CampaignFaqDelete($id: ID!) {
    campaignFAQDelete(filter: { id: $id }) {
      success
    }
  }
`;
