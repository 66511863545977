import React from 'react';
import { Typography } from '@mui/material';

import { formatDate } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';
import { DiscountStatusTypes } from '@vizsla/types';

import { getExpiration } from 'src/utils/campaignDiscounts';

interface DiscountExpirationProps {
  date?: string | null;
  status: string;
}

export const DiscountExpiration: React.FC<DiscountExpirationProps> = ({ date, status }) => {
  const { isTimeEnded, additionalMessage, valueCount, units } = getExpiration(date);

  if (additionalMessage === 'Not Set') {
    return <React.Fragment>{additionalMessage}</React.Fragment>;
  }

  const isDiscountCodeNotLive = isTimeEnded || status !== DiscountStatusTypes.live;
  if (isDiscountCodeNotLive) {
    return (
      <Typography component="span">
        {formatDate(date, DateFormatPatterns.shortMonthDayYear)}&nbsp;
      </Typography>
    );
  }

  const isExpiresSoon = units !== 'days' || (valueCount && valueCount <= 5);

  return (
    <React.Fragment>
      <Typography component="span">
        {formatDate(date, DateFormatPatterns.shortMonthDayYear)}&nbsp;
      </Typography>
      <Typography
        component="span"
        color={theme => (isExpiresSoon ? theme.palette.error.main : theme.palette.grey['600'])}
      >
        ({`${valueCount} ${units} left`})
      </Typography>
    </React.Fragment>
  );
};
