import React from 'react';
import _ from 'lodash';

import { useApolloCacheQueryManager, useSearchQueryVariables } from '@vizsla/hooks';
import {
  DiscountCodeSetting,
  DiscountCodeSettingCreateInput,
  DiscountCodeSettingUpdateInput,
  CampaignDiscountsSettingsListQuery,
  CampaignDiscountsSettingsListQueryVariables,
  useCampaignDiscountSettingsCreateMutation,
  useCampaignDiscountSettingsDeleteMutation,
  useCampaignDiscountSettingsUpdateMutation,
  useCampaignDiscountsSettingsListQuery,
} from '@vizsla/graphql';
import { mutableList } from '@vizsla/utils';

import { CAMPAIGN_DISCOUNTS_SETTINGS_LIST_QUERY } from 'src/graphql';

interface UpdateDiscountSettingsInput {
  data: DiscountCodeSettingUpdateInput;
  discountId: string;
}

export const useCampaignDiscounts = (campaignId: string) => {
  const searchQueryVariables = useSearchQueryVariables('name');

  const campaignDiscountCodeSettingsQueryVariables = React.useMemo(() => {
    const variables = {
      filter: {
        campaign: {
          id: {
            equals: campaignId,
          },
        },
        ...searchQueryVariables,
      },
    };

    return { variables };
  }, [campaignId, searchQueryVariables]);

  const { data, loading } = useCampaignDiscountsSettingsListQuery({
    ...campaignDiscountCodeSettingsQueryVariables,
    skip: !campaignId,
  });

  const { updateQuery: updateCampaignDiscountSettingsQuery } = useApolloCacheQueryManager<
    CampaignDiscountsSettingsListQuery,
    CampaignDiscountsSettingsListQueryVariables
  >({
    query: CAMPAIGN_DISCOUNTS_SETTINGS_LIST_QUERY,
    typeName: 'DiscountCodeSettingListResponse',
    ...campaignDiscountCodeSettingsQueryVariables,
  });

  const discountsSettings = data?.discountCodeSettingsList?.items || [];

  const addCampaignDiscountSettingsToCache = React.useCallback(
    (newCampaignDiscountSettings: DiscountCodeSetting): void => {
      updateCampaignDiscountSettingsQuery(campaignDiscountSettingsQueryResult => {
        mutableList<DiscountCodeSetting>(
          campaignDiscountSettingsQueryResult.discountCodeSettingsList.items,
        ).add(newCampaignDiscountSettings);
      });
    },
    [updateCampaignDiscountSettingsQuery],
  );

  const updateCampaignDiscountSettingsInCacheById = React.useCallback(
    (updatedCampaignDiscountSetting: DiscountCodeSetting, discountId: string): void => {
      updateCampaignDiscountSettingsQuery(campaignDiscountSettingsQueryResult => {
        mutableList<DiscountCodeSetting>(
          campaignDiscountSettingsQueryResult.discountCodeSettingsList.items,
        ).updateById(discountId, updatedCampaignDiscountSetting);
      });
    },
    [updateCampaignDiscountSettingsQuery],
  );

  const removeCampaignDiscountSettingsFromCacheById = React.useCallback(
    (discountId: string): void => {
      updateCampaignDiscountSettingsQuery(campaignDiscountSettingsQueryResult => {
        mutableList<DiscountCodeSetting>(
          campaignDiscountSettingsQueryResult.discountCodeSettingsList.items,
        ).removeById(discountId);
      });
    },
    [updateCampaignDiscountSettingsQuery],
  );

  const [campaignDiscountSettingsCreateMutation, { loading: creating }] =
    useCampaignDiscountSettingsCreateMutation();

  const [campaignDiscountSettingsUpdateMutation, { loading: updating }] =
    useCampaignDiscountSettingsUpdateMutation();

  const [campaignDiscountSettingsDeleteMutation, { loading: deleting }] =
    useCampaignDiscountSettingsDeleteMutation();

  const getExperienceTypeData = (experienceType: any) => {
    if (!_.isNil(experienceType)) {
      return {
        connect: {
          id: experienceType,
        },
      };
    }
    return null;
  };

  const createDiscount = async (data: DiscountCodeSettingCreateInput) => {
    const experienceTypeData = getExperienceTypeData(data.experienceType);
    const res = await campaignDiscountSettingsCreateMutation({
      variables: {
        createData: {
          campaign: {
            connect: {
              id: campaignId,
            },
          },
          ...data,
          experienceType: experienceTypeData,
        },
      },
    });
    addCampaignDiscountSettingsToCache(res?.data?.discountCodeSettingCreate as DiscountCodeSetting);
  };

  const updateDiscount = async ({ data, discountId }: UpdateDiscountSettingsInput) => {
    const res = await campaignDiscountSettingsUpdateMutation({
      variables: {
        data: {
          ...data,
        },
        filter: {
          id: discountId,
        },
      },
    });

    updateCampaignDiscountSettingsInCacheById(
      res?.data?.discountCodeSettingUpdate as DiscountCodeSetting,
      discountId,
    );
  };

  const deleteDiscount = async (discountId: string) => {
    await campaignDiscountSettingsDeleteMutation({
      variables: {
        id: discountId,
      },
    });
    removeCampaignDiscountSettingsFromCacheById(discountId);
  };

  return {
    discountsSettings,
    createDiscount,
    updateDiscount,
    deleteDiscount,
    loading,
    creating,
    updating,
    deleting,
  };
};
