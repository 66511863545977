import { CSSProperties } from 'react';
import styled from '@emotion/styled';

export const InboxHeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;

export const InboxHeaderTitle = styled.h2(({ theme }) => ({
  ...(theme.typography.h2 as CSSProperties),
  fontWeight: 900,
  margin: 0,
}));

export const InboxHeaderActions = styled.div`
  display: flex;
  align-items: center;
`;
