import { DateTime } from 'luxon';
import * as yup from 'yup';
import DOMPurify from 'dompurify';

import { concatDateWithTime, getNowDateTime, isDateGreaterOrEquals } from '@vizsla/utils';
import { WEB_LINK, SPECIAL_SYMBOLS_REG_EXP, PhoneSchemaRequired } from '@vizsla/constants';

import { DateType } from 'src/constants/campaign';
import {
  DiscountAllocationTypes,
  DiscountAmountTypes,
  DiscountDatePickerTypes,
} from 'src/types/shared';

const DEFAULT_MAX_STRING_LENGTH = 100;
const DEFAULT_MAX_STRING_LENGTH_ERROR_MESSAGE = `Must be maximum ${DEFAULT_MAX_STRING_LENGTH} symbols`;

const campaignNameSchemaRequired = yup
  .string()
  .nullable()
  .required('Campaign name is required')
  .max(DEFAULT_MAX_STRING_LENGTH, DEFAULT_MAX_STRING_LENGTH_ERROR_MESSAGE);

export const CampaignRegistrationFormOneSchema = yup.object({
  name: campaignNameSchemaRequired,
  state: yup.string().nullable().required('State is required'),
  city: yup
    .string()
    .nullable()
    .required('City is required')
    .max(DEFAULT_MAX_STRING_LENGTH, DEFAULT_MAX_STRING_LENGTH_ERROR_MESSAGE),
  startDate: yup.string().nullable().required('Start Date is required'),
  startTime: yup.string().nullable().required('Start Time is required'),
  endDate: yup
    .string()
    .nullable()
    .required('End Date is required')
    .test('is-after-start', 'End Date must be after Start Date', function (value) {
      const { startDate } = this.parent;
      if (!startDate || !value) {
        return true;
      }
      return DateTime.fromISO(value) >= DateTime.fromISO(startDate);
    }),
  endTime: yup.string().nullable().required('End Time is required'),
});

export const CampaignRegistrationFormThreeSchema = yup.object({
  fundraisingGoal: yup.string().nullable().required('Fundraising Amount is required'),
});

export const CampaignPersonalMissionFormOneSchema = yup.object({
  name: campaignNameSchemaRequired,
  startDate: yup.string().nullable().required('Start Date is required'),
  startTime: yup.string().nullable().required('Start Time is required'),
});

export const CampaignPersonalMissionFormOneSchemaWithEndDate = yup.object({
  name: campaignNameSchemaRequired,
  startDate: yup.string().nullable().required('Start Date is required'),
  startTime: yup.string().nullable().required('Start Time is required'),
  endDate: yup.string().nullable().required('End Date is required'),
  endTime: yup.string().nullable().required('End Time is required'),
});

export const CampaignPersonalMissionFormThreeSchema = yup.object({
  fundraisingGoal: yup.string().nullable().required('Fundraising Amount is required'),
});

export const CampaignImpactGivingFormOneSchema = yup.object({
  name: campaignNameSchemaRequired,
  startDate: yup.string().nullable().required('Start Date is required'),
  startTime: yup.string().nullable().required('Start Time is required'),
});

export const CampaignImpactGivingGoalsFormSchema = yup.object({
  fundraisingGoal: yup.string().nullable().required('Fundraising Amount is required'),
});

const campaignTypeSchemaRequired = yup.string().nullable().required('Campaign Type is required');
const campaignWebLinkSchemaRequired = yup
  .string()
  .nullable()
  .required('Web Link is required')
  .matches(WEB_LINK, { message: 'Url is invalid' });

export const RegistrationCampaignBasicsDetailsFormSchema = yup.object({
  name: campaignNameSchemaRequired,
  campaignType: campaignTypeSchemaRequired,
  webLink: campaignWebLinkSchemaRequired,
  fundraisingGoal: yup.number().min(0).nullable().required('Fundraising Goal is required'),
  attendanceFundraisersCountGoal: yup.number().min(0).nullable().notRequired(),
  teamGoal: yup.number().min(0).nullable().notRequired(),
  startDate: yup.string().nullable().required('Start Date is required'),
  startTime: yup.string().nullable().required('Start Time is required'),
});

export const PersonalMissionCampaignBasicsDetailsFormSchema = yup.object({
  name: campaignNameSchemaRequired,
  campaignType: campaignTypeSchemaRequired,
  webLink: campaignWebLinkSchemaRequired,
  fundraisingGoal: yup.number().min(0).nullable().required('Fundraising Goal is required'),
  fundraiserGoal: yup.number().min(0).nullable().notRequired(),
  teamGoal: yup.number().min(0).nullable().notRequired(),
  startDate: yup.string().nullable().required('Start Date is required'),
  startTime: yup.string().nullable().required('Start Time is required'),
  endDate: yup
    .string()
    .nullable()
    .when('dateType', {
      is: DateType.Range,
      then: yup.string().nullable().required('End Date is required'),
      otherwise: yup.string().nullable().notRequired(),
    }),
  endTime: yup
    .string()
    .nullable()
    .when('dateType', {
      is: DateType.Range,
      then: yup.string().nullable().required('End Time is required'),
      otherwise: yup.string().nullable().notRequired(),
    }),
});

export const ImpactGivingCampaignBasicsDetailsFormSchema = yup.object({
  name: campaignNameSchemaRequired,
  campaignType: campaignTypeSchemaRequired,
  webLink: campaignWebLinkSchemaRequired,
  fundraisingGoal: yup.number().min(0).nullable().required('Fundraising Goal is required'),
  donorGoal: yup.number().min(0).nullable().notRequired(),
  startDate: yup.string().nullable().required('Start Date is required'),
  startTime: yup.string().nullable().required('Start Time is required'),
});

export const CampaignDonationSettingsSchema = yup.object({
  allowAnonymousDonations: yup.bool().nullable().required('required'),
  // allowSelfDonations: yup.bool().nullable().required('required'),
  // allowDonorsToOptIn: yup.bool().nullable().required('required'),
  // allowDonorsToDedicateDonations: yup.bool().nullable().required('required'),
  feesEnabled: yup.bool().nullable().required('required'),
  roundupEnabled: yup.bool().nullable().required('required'),
  coveredFees: yup.string().nullable().required('required'),
  percentageAmount: yup
    .number()
    .nullable()
    .required('required')
    .min(1, 'Must be more than 1')
    .max(100, 'Must be less than 100'),
  feesMessage: yup
    .string()
    .nullable()
    .when('feesEnabled', {
      is: true,
      then: yup.string().nullable().required('required'),
      otherwise: yup.string().nullable().notRequired(),
    })
    .max(500, 'Must be maximum 500 symbols'),
  roundupMessage: yup
    .string()
    .nullable()
    .when('roundupEnabled', {
      is: true,
      then: yup.string().nullable().required('required'),
      otherwise: yup.string().nullable().notRequired(),
    })
    .max(500, 'Must be maximum 500 symbols'),
});

export const CampaignContactsFormSchema = yup.object({
  contactEmail: yup.string().email().required('Email is required'),
  contactPhone: PhoneSchemaRequired,
});

export const CampaignTeamFundraisingSettingsSchema = yup.object({
  joiningTeamEnabled: yup.bool().nullable().required('required'),
  allowTeamsMakeTheirTeamInvite: yup
    .bool()
    .nullable()
    .when('joiningTeamEnabled', {
      is: true,
      then: yup.bool().nullable().required('required'),
      otherwise: yup.bool().nullable().notRequired(),
    }),
  allowAttendeeRequestAdmissionOntoTeam: yup
    .bool()
    .nullable()
    .when('joiningTeamEnabled', {
      is: true,
      then: yup.bool().nullable().required('required'),
      otherwise: yup.bool().nullable().notRequired(),
    }),
  maximumTeams: yup
    .number()
    .nullable()
    .when('joiningTeamEnabled', {
      is: true,
      then: yup.number().nullable().notRequired(),
      otherwise: yup.number().nullable().notRequired(),
    })
    .integer()
    .min(0, 'Must be more than 0'),
  maximumTeamMembers: yup
    .number()
    .nullable()
    .when('joiningTeamEnabled', {
      is: true,
      then: yup.number().nullable().notRequired(),
      otherwise: yup.number().nullable().notRequired(),
    })
    .integer()
    .min(0, 'Must be more than 0'),
  teamSharingEnabled: yup.bool().nullable().required('required'),
});

export const CampaignFaqValidationSchema = yup.object({
  allocation: yup
    .array()
    .min(1, 'Allocation is required')
    .of(yup.string().required('Allocation is required'))
    .required('Allocation is required'),
  userGroup: yup
    .array()
    .min(1, 'User Group is required')
    .of(yup.string().required('User Group is required'))
    .required('User Group is required'),
  question: yup.string().required('Question is required'),
  answer: yup
    .string()
    .nullable()
    .test('answer-is-required', 'Answer is required', answer => {
      if (answer) {
        const answerSntzd = DOMPurify.sanitize(answer, { ALLOWED_TAGS: [] }).replace(
          /[^a-zA-Z0-9 ]/g,
          '',
        );
        return answerSntzd.length > 0;
      }
      return true;
    }),
});

type DiscountContextSchema = {
  initialStartDate: string | null;
  initialStartTime: string | null;
  startTime: string | null | undefined;
  startDate: string | null | undefined;
  endDate: string | null | undefined;
};

export const CampaignAddDiscountsFormSchema = yup.object({
  name: yup.string().nullable().min(5, 'Should be at least 5 symbols').required('Name is required'),
  category: yup.string().nullable().required('Category is required'),
  amount: yup
    .number()
    .nullable()
    .when('amountType', {
      is: DiscountAmountTypes.percentage,
      then: yup
        .number()
        .nullable()
        .min(1, 'Must be more than 1')
        .max(99, 'Must be less than 99')
        .required('Amount is required'),
      otherwise: yup.number().nullable().required('Amount is required'),
    })
    .min(0, 'Must be more than 0'),
  amountType: yup.string().nullable().required('Amount Type is required'),
  startDate: yup.string().nullable().required('Start Date is required'),
  startTime: yup
    .string()
    .nullable()
    .required('Start Time is required')
    .test(
      'start-time-should-greater-or-equal-current-time',
      'Start Time should be greater or equal current time',
      (discountStartTime: string | null | undefined, { options }): boolean => {
        const { context } = options;
        const { initialStartDate, initialStartTime, startDate } = context as DiscountContextSchema;

        const discountFullStartDate = concatDateWithTime(startDate, discountStartTime);
        if (initialStartDate && initialStartTime) {
          const discountFullInitialStartDate = concatDateWithTime(
            initialStartDate,
            initialStartTime,
          );

          if (discountFullInitialStartDate === discountFullStartDate) {
            return true;
          }
        }
        return isDateGreaterOrEquals(discountFullStartDate, getNowDateTime(), 'hour');
      },
    ),
  endDate: yup
    .string()
    .nullable()
    .when('datePickerType', {
      is: DiscountDatePickerTypes.dateRange,
      then: yup
        .string()
        .nullable()
        .required('End Date is required')
        .test(
          'end-date-should-greater-or-equal-current-date',
          'End Date should be greater or equal current date',
          (discountEndDate: string | null | undefined): boolean => {
            return isDateGreaterOrEquals(discountEndDate, getNowDateTime(), 'day');
          },
        ),
      otherwise: yup.string().nullable().notRequired(),
    }),
  endTime: yup
    .string()
    .nullable()
    .when('datePickerType', {
      is: DiscountDatePickerTypes.dateRange,
      then: yup
        .string()
        .nullable()
        .required('End Time is required')
        .test(
          'end-time-should-greater-or-equal-current-time',
          'End Time should be greater or equal current time',
          (discountEndTime: string | null | undefined, { options }): boolean => {
            const { context } = options;
            const { endDate } = context as DiscountContextSchema;

            const discountFullEndDate = concatDateWithTime(endDate, discountEndTime);
            return isDateGreaterOrEquals(discountFullEndDate, getNowDateTime(), 'hour');
          },
        )
        .test(
          'end-time-should-greater-or-equal-start-time',
          'End Time should be greater or equal Start Time',
          (discountEndTime: string | null | undefined, { options }): boolean => {
            const { context } = options;
            const { startTime, startDate, endDate } = context as DiscountContextSchema;

            const discountFullStartDate = concatDateWithTime(startDate, startTime);
            const discountFullEndDate = concatDateWithTime(endDate, discountEndTime);
            return isDateGreaterOrEquals(discountFullEndDate, discountFullStartDate, 'hour');
          },
        ),
      otherwise: yup.string().nullable().notRequired(),
    }),
  allocationType: yup.string().nullable().required('Allocation Type is required'),
  experienceType: yup
    .string()
    .nullable()
    .when('allocationType', {
      is: DiscountAllocationTypes.byExperience,
      then: yup.string().nullable().required('Experience is required'),
      otherwise: yup.string().nullable().notRequired(),
    }),
  limitToOneUsePerAttendee: yup.bool().nullable().required('required'),
  totalUses: yup.number().nullable().notRequired().integer().min(0, 'Must be more than 0'),
});

export const CampaignGetDiscountFormSchema = yup.object({
  discountCodeName: yup
    .string()
    .nullable()
    .min(5, 'Discount Code is minimum 5 characters in length')
    .required('Discount Code is required'),
});

export const CampaignTeamAddSchema = yup.object({
  experience: yup.string().required('Experience is required'),
  teamName: yup.string().nullable().required('Team name is required'),
  teamCaptain: yup.object().nullable().required('Team captain is required'),
  teamFundraisingGoal: yup.number().nullable().required('Fundraising Goal is required'),
  webLink: yup
    .string()
    .matches(WEB_LINK, { message: 'Must be a valid URL' })
    .required('Team web Link required'),
});
