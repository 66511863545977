import React from 'react';
import { Form } from 'react-final-form';
import { Grid } from '@mui/material';
import { FormApi } from 'final-form';

import { useNotification } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { useExperienceUpdateMutation } from '@vizsla/graphql';
import { Field, PaperBlock, Button } from '@vizsla/components';

import { RichEditorField } from 'src/components/forms';
import {
  ExperienceWaiverSchema,
  MAX_LENGTH_WAIVER,
} from 'src/constants/validationSchemas/experience';
import { t } from 'src/utils/template';

type ExperienceWaiverValues = {
  waiverAllowMultipleAttendeeConsent: boolean;
  waiverTextBody: string;
};

type ExperienceWaiverProps = {
  experienceId: string;
  waiverAllowMultipleAttendeeConsent: boolean | null | undefined;
  waiverTextBody: string | null | undefined;
};

export const ExperienceWaiver: React.FC<ExperienceWaiverProps> = ({
  experienceId,
  waiverAllowMultipleAttendeeConsent,
  waiverTextBody,
}) => {
  const INITIAL_VALUES: ExperienceWaiverValues = {
    waiverAllowMultipleAttendeeConsent: waiverAllowMultipleAttendeeConsent ?? false,
    waiverTextBody: waiverTextBody ?? '',
  };

  const notification = useNotification();

  const [experienceUpdateMutation, { loading }] = useExperienceUpdateMutation();

  const onSubmit = async (values: ExperienceWaiverValues) => {
    try {
      await experienceUpdateMutation({
        variables: {
          data: {
            waiverEnabled: true,
            waiverTextBody: values.waiverTextBody,
            waiverAllowMultipleAttendeeConsent: values.waiverAllowMultipleAttendeeConsent,
          },
          filter: {
            id: experienceId,
          },
        },
      });
      notification.success(t('expirience_waiver_update_success'));
    } catch (e) {
      notification.error(t('expirience_waiver_update_error'));
    }
  };

  const onResetForm = (form: FormApi<ExperienceWaiverValues, Partial<ExperienceWaiverValues>>) => {
    form.reset();
  };

  return (
    <PaperBlock title="Waiver" collapsing defaultOpened>
      <Form
        validate={values => validateWithSchema(ExperienceWaiverSchema, values)}
        initialValues={INITIAL_VALUES}
        onSubmit={onSubmit}
        onResetForm={onResetForm}
      >
        {({ submitting, pristine, handleSubmit, form, valid }) => {
          return (
            <form>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Field
                    name="waiverTextBody"
                    component={RichEditorField}
                    maxTextLength={MAX_LENGTH_WAIVER}
                    placeholder="Copy and Paste the Waiver here"
                    note="This description will appear in the registration option the attendee views."
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="center" marginTop="22px">
                <Grid item>
                  <Button
                    disabled={submitting || pristine || loading}
                    variant="text"
                    onClick={() => onResetForm(form)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    loading={submitting || loading}
                    disabled={pristine || !valid}
                    onClick={handleSubmit}
                  >
                    {pristine ? 'Saved' : 'Save'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Form>
    </PaperBlock>
  );
};
