import { gql } from '@apollo/client';

export const CAMPAIGN_STAFF_FRAGMENT = gql`
  fragment CampaignStaffFragment on CampaignStaff {
    id
    user {
      id
      firstName
      lastName
      email
      avatar {
        id
        downloadUrl
      }
    }
    campaign {
      id
    }
    emailForwarding
  }
`;

export const CAMPAIGN_STAFF_CREATE_MUTATION = gql`
  mutation CampaignStaffCreateMany($dataToCreate: [CampaignStaffCreateManyInput]!) {
    createdStaffs: campaignStaffCreateMany(data: $dataToCreate) {
      count
      items {
        ...CampaignStaffFragment
      }
    }
  }
  ${CAMPAIGN_STAFF_FRAGMENT}
`;

export const CAMPAIGN_STAFF_UPDATE_BY_FILTER_MUTATION = gql`
  mutation CampaignStaffUpdateByFilter(
    $data: CampaignStaffUpdateByFilterInput!
    $filter: CampaignStaffFilter
  ) {
    updatedCampaignStaffs: campaignStaffUpdateByFilter(data: $data, filter: $filter) {
      items {
        ...CampaignStaffFragment
      }
    }
  }
  ${CAMPAIGN_STAFF_FRAGMENT}
`;

export const CAMPAIGN_STAFF_LIST_QUERY = gql`
  query CampaignStaffList($filter: CampaignStaffFilter) {
    campaignStaffsList(filter: $filter) {
      items {
        ...CampaignStaffFragment
      }
    }
  }
  ${CAMPAIGN_STAFF_FRAGMENT}
`;

export const CAMPAIGN_STAFF_DELETE_MUTATION = gql`
  mutation CampaignStaffDeleteByFilter($filter: CampaignStaffFilter!) {
    campaignStaffDeleteByFilter(filter: $filter) {
      success
    }
  }
`;
