import React from 'react';
import { Outlet } from 'react-router-dom';

import { useOrganizationId } from '@vizsla/hooks';

import { OrganizationProfileLayout } from 'src/layouts';

const AdminOrganizationProfilePage: React.FC = () => {
  const organizationId = useOrganizationId();

  return (
    <OrganizationProfileLayout organizationId={organizationId}>
      <Outlet />
    </OrganizationProfileLayout>
  );
};

export { AdminOrganizationProfilePage };
