import { useRegistrationOptionQuery } from '@vizsla/graphql';

export const useRegistrationOptionById = (id: string) => {
  const { data, loading, error } = useRegistrationOptionQuery({
    variables: {
      id,
    },
    skip: !id,
  });

  const registrationOption = data?.registrationOption;

  return {
    registrationOption,
    loading,
    error,
  };
};
