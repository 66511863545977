import React from 'react';

import type { TableRowAction } from '@vizsla/components';

// TODO configure columns and render actual table when data model is ready
// const TEAMS_TABLE_COLUMNS = [];

interface CampaignPMRecentTeamsTableProps {
  dataSource: any[];
  rowActions: TableRowAction[];
  loading: boolean;
}

export const CampaignPMRecentTeamsTable: React.FC<CampaignPMRecentTeamsTableProps> = ({
  dataSource,
  rowActions,
  loading,
}) => {
  return null;
};
