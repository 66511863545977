import React from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import _ from 'lodash';

import { usePaginationQueryVariables, usePagination, useSearchQueryVariables } from '@vizsla/hooks';
import { TableData } from '@vizsla/components';
import type { TableColumn, TableRowAction } from '@vizsla/components';
import { DateFormatPatterns } from '@vizsla/constants';
import { formatDate } from '@vizsla/utils';
import { TeamFundraising, TeamFundraisingFilter } from '@vizsla/graphql';

import { getUserFullName } from 'src/utils/user';
import {
  useCampaignId,
  useTeamsRT,
  TeamRTItem,
  useCampaignFundraisingSettings,
} from 'src/hooks/campaign';
import { TeamAvatarWithNameInline } from 'src/components/user';
import { CampaignFundraiserGoalProgress } from 'src/components/campaign';

const ROW_ACTIONS: Array<TableRowAction<TeamRTItem>> = [
  {
    actionName: 'View',
    onAction: () => {},
  },
];

export const CampaignPeopleRegistrationAndTicketingTeamsTable: React.FC = () => {
  const campaignId = useCampaignId();
  const pagination = usePagination();
  const paginationQueryVariables = usePaginationQueryVariables();
  const searchQueryVariables = useSearchQueryVariables<TeamFundraisingFilter>(searchValue => {
    return {
      name: {
        contains: searchValue,
      },
    };
  });

  const filterByCampaignId = {
    campaign: {
      id: {
        equals: campaignId,
      },
    },
  };

  const {
    teams,
    count: teamsTotalCount,
    loading,
  } = useTeamsRT({
    campaignId,
    customFilter: {
      ...filterByCampaignId,
      ...searchQueryVariables,
    },
    queryVariables: {
      ...paginationQueryVariables,
    },
  });

  React.useEffect(() => {
    pagination.onCountChange(teamsTotalCount);
  }, [pagination, teamsTotalCount]);

  const { teamSettings, loading: settingsLoading } = useCampaignFundraisingSettings(campaignId);

  const columns: Array<TableColumn<TeamRTItem>> = React.useMemo(
    () => [
      {
        title: 'Name',
        key: 'name',
        render: (_v, team: TeamRTItem) => {
          return <TeamAvatarWithNameInline team={team as TeamFundraising} />;
        },
      },
      {
        title: 'Creation Date',
        key: 'date',
        render: (_v, team: TeamRTItem) => (
          <Grid>
            <Typography variant="body2">
              {formatDate(team?.createdAt, DateFormatPatterns.shortMonthDayYear)}
            </Typography>
          </Grid>
        ),
      },
      {
        title: 'Captain',
        key: 'captain',
        render: (_v, team: TeamRTItem) => {
          const teamCaptain = team?.teamCaptain?.items[0];
          const user = teamCaptain?.attendee?.user;

          if (_.isNil(user)) {
            return <Typography variant="body2">N/A</Typography>;
          }

          return (
            <Grid>
              <Typography variant="body2">{getUserFullName(user)}</Typography>
            </Grid>
          );
        },
      },
      {
        title: 'Members',
        key: 'members',
        render: (_v, team: TeamRTItem) => {
          if (_.isNil(team.members?.count) || _.isNil(teamSettings?.maximumTeamMembers)) {
            return <Typography variant="body2">N/A</Typography>;
          }

          return `${team.members?.count}/${teamSettings?.maximumTeamMembers}`;
        },
      },
      {
        title: 'Total Raised',
        key: 'totalRaised',
        render: (_v, team: TeamRTItem) => {
          if (_.isNil(team.donations?.items)) {
            return <Typography variant="body2">N/A</Typography>;
          }

          const totalRaised = _.sumBy(team.donations?.items, 'amount');

          const fundraiserGoal = team.fundraiserGoal ?? 0;

          return (
            <CampaignFundraiserGoalProgress
              totalRaised={totalRaised}
              fundraiserGoal={fundraiserGoal}
            />
          );
        },
      },
    ],
    [teamSettings?.maximumTeamMembers],
  );

  return (
    <TableData
      dataSource={teams}
      loading={loading || settingsLoading}
      columns={columns}
      rowActions={ROW_ACTIONS}
      pagination={pagination}
    />
  );
};
