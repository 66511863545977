import { StoreAssetSimpleFragmentFragment, RegistrationOptionSwagbag } from '@vizsla/graphql';

export type StoreAssetSimple = StoreAssetSimpleFragmentFragment;

export enum UnitsOfMeasureType {
  Imperial = 'Imperial',
  Metric = 'Metric',
}

export enum UnitOfMeasure {
  Yards = 'Yards',
  Miles = 'Miles',
  Meters = 'Meters',
  Kilometers = 'Kilometers',
}

export enum CheckpointNotificationValue {
  OnlyAttendee = 'Only Attendee',
  OnlyCampaignStaff = 'Only Campaign Staff',
  AttendeeAndCampaignStaff = 'Attendee and Campaign Staff',
}

export type SwagBagAssetActionUpdateOrDeleteModalArgs = {
  storeAsset: RegistrationOptionSwagbag;
};

export enum SwagBagAssetFormFields {
  STORE_ASSET = 'storeAsset',
  PRICE = 'price',
}

export type SwagBagAssetAddOrUpdateFormValues = Partial<{
  [SwagBagAssetFormFields.STORE_ASSET]: StoreAssetSimple;
  [SwagBagAssetFormFields.PRICE]: number;
}>;
