import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Add, LocalActivityOutlined as LocalActivityOutlinedIcon } from '@mui/icons-material';

import { PaletteColor } from '@vizsla/theme';
import { useModal, useExperienceId } from '@vizsla/hooks';
import {
  TicketingOptionFragmentFragment as TicketingOption,
  TicketingPricingSetting,
} from '@vizsla/graphql';
import type { TableRowAction, TableColumn } from '@vizsla/components';
import { PaperBlock, TableData } from '@vizsla/components';

import { useTicketingOptions } from 'src/hooks/ticketingOption';
import { ConfirmationDialog } from 'src/components/shared';
import { TicketingOptionCreateModal, TicketingOptionEditModal } from 'src/modals';
import { MODALS } from 'src/constants/modals';
import { PricngTiersList } from 'src/components/experiencePricing';

const TICKETING_OPTIONS_TABLE_COLUMNS: Array<TableColumn<TicketingOption>> = [
  {
    title: 'name',
    key: 'name',
    dataPath: 'ticketName',
    render: (ticketName: string) => {
      return (
        <Typography variant="subtitle2" display="flex" alignItems="center">
          <Box
            sx={{
              '&> .MuiSvgIcon-root': {
                color: PaletteColor.PrimaryGray,
                verticalAlign: 'middle',
              },
            }}
          >
            <LocalActivityOutlinedIcon />
          </Box>
          <Box ml={2}>{ticketName}</Box>
        </Typography>
      );
    },
  },
  {
    title: 'quantity',
    key: 'quantity',
    dataPath: 'tableNumber',
    render: (tableNumber: number) => {
      return tableNumber ?? 0;
    },
  },
  {
    title: 'price',
    key: 'price',
    dataPath: 'pricingSettings',
    render: (pricingSettings?: TicketingPricingSetting) => (
      <PricngTiersList pricingSettings={pricingSettings} />
    ),
  },
];

export const TicketingOptionsTable: React.FC = () => {
  const experienceId = useExperienceId();
  const { deleteTicketingOption, ticketingOptions, loading, deleting } = useTicketingOptions();

  const { openModal } = useModal();

  const onEditTicketingOption = (id: string) => {
    if (id) {
      openModal(MODALS.TICKETING_OPTION_EDIT_MODAL, {
        ticketingOptionId: id,
      });
    }
  };

  const onDeleteTicketingOption = (id: string, ticketName: string) => {
    if (id) {
      openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
        deleteFunction: () => deleteTicketingOption(id),
        objectName: ticketName,
      });
    }
  };

  const onCreateTicketingOption = (experienceId: string) => {
    if (experienceId) {
      openModal(MODALS.TICKETING_OPTION_CREATE_MODAL, {
        experienceId,
      });
    }
  };

  const rowActions: Array<TableRowAction<TicketingOption>> = [
    {
      actionName: 'Edit',
      onAction: (ticketingOption: TicketingOption) => {
        onEditTicketingOption(ticketingOption?.id as string);
      },
    },
    {
      actionName: 'Delete',
      onAction: (ticketingOption: TicketingOption) =>
        onDeleteTicketingOption(
          ticketingOption?.id as string,
          ticketingOption?.ticketName as string,
        ),
    },
  ];

  const bottomActions = [
    {
      actionName: 'add ticket option',
      icon: <Add />,
      onAction: () => onCreateTicketingOption(experienceId),
    },
  ];

  return (
    <PaperBlock title="Ticket Options" collapsing defaultOpened>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TableData
            dataSource={ticketingOptions}
            columns={TICKETING_OPTIONS_TABLE_COLUMNS}
            rowActions={rowActions}
            bottomActions={bottomActions}
            loading={loading || deleting}
          />
          <ConfirmationDialog />
          <TicketingOptionCreateModal />
          <TicketingOptionEditModal />
        </Grid>
      </Grid>
    </PaperBlock>
  );
};
