import _ from 'lodash';

import { concatDateWithTime, getPastDaysFromNow } from '@vizsla/utils';
import { Nullable } from '@vizsla/types';

import { CampaignsListItem, CampaignType, CampaignDetailItem } from 'src/types/campaign';
import { DateType } from 'src/constants/campaign';
import {
  CampaignPersonalMissionFormOneSchema,
  CampaignPersonalMissionFormOneSchemaWithEndDate,
} from 'src/constants/validationSchemas/campaign';

export const getAttendeesCountFromCampaign = (campaign: CampaignsListItem) => {
  return campaign?.attendees?.groups?.[0]?.count ?? 0;
};

export const getFundraisersCountFromCampaign = (campaign: CampaignsListItem) => {
  return campaign?.fundraisers?.groups?.[0]?.count ?? 0;
};

export function isRegistrationAndTicketing(
  campaign: CampaignDetailItem | null | undefined,
): boolean {
  return campaign?.campaignType === CampaignType.registrationAndTicketing;
}

export function isPersonalMission(campaign: Nullable<CampaignDetailItem>): boolean {
  return campaign?.campaignType === CampaignType.personalMission;
}

export function isImpactGiving(campaign: Nullable<CampaignDetailItem>): boolean {
  return campaign?.campaignType === CampaignType.personalMission;
}

export function buildCampaignRegistrationFormData(formData: any): any {
  const {
    startDate: formStartDate,
    startTime: formStartTime,
    endDate: formEndDate,
    endTime: formEndTime,
    logo,
    image,
    experiences,
    ...campaignData
  } = formData;

  const startDate = concatDateWithTime(formStartDate, formStartTime);
  const endDate = concatDateWithTime(formEndDate, formEndTime);

  const createLogo = !_.isNil(logo)
    ? {
        logo: {
          create: {
            fileId: logo.fileId,
            fileName: logo.fileName,
            public: logo.public,
          },
        },
      }
    : {};

  const createImage = !_.isNil(image)
    ? {
        image: {
          create: {
            fileId: image.fileId,
            fileName: image.fileName,
            public: image.public,
          },
        },
      }
    : {};

  return {
    ...campaignData,
    ...createImage,
    ...createLogo,
    startDate,
    endDate,
  };
}

export function buildExperience(experienceFormValues: any, campaignFormData: any): any {
  const {
    name,
    experienceType,
    experienceCategory,
    sameAsCampaignDates,
    startDate: experienceFormStartDate,
    startTime: experienceFormStartTime,
    endDate: experienceEndDateFromState,
    endTime: experienceEndTimeFromState,
    dateType,
  } = experienceFormValues;

  const experience = {
    name,
    experienceType,
    experienceCategory,
  };

  if (sameAsCampaignDates) {
    return {
      ...experience,
      startDate: campaignFormData.startDate,
      endDate: campaignFormData.endDate,
    };
  }

  const startDate = concatDateWithTime(experienceFormStartDate, experienceFormStartTime);

  if (dateType === DateType.Single) {
    return {
      ...experience,
      startDate,
      endDate: null,
    };
  }

  const endDate = concatDateWithTime(experienceEndDateFromState, experienceEndTimeFromState);

  return {
    ...experience,
    startDate,
    endDate,
  };
}

export function buildExperiencesFormData(experiences: Array<any>, campaignFormData: any): any {
  return experiences.map((experience: any) => {
    return buildExperience(experience, campaignFormData);
  });
}

export function buildCampaignPersonalMissionFormData(campaignFormValues: any): any {
  const {
    startDate: startDateFromState,
    startTime: startTimeFormState,
    endDate: endDateFromState,
    endTime: endTimeFromState,
    dateType,
    logo,
    image,
    ...campaignData
  } = campaignFormValues;

  const startDate = concatDateWithTime(startDateFromState, startTimeFormState);

  const createLogo = !_.isNil(logo)
    ? {
        logo: {
          create: {
            fileId: logo.fileId,
            fileName: logo.fileName,
            public: logo.public,
          },
        },
      }
    : {};

  const createImage = !_.isNil(image)
    ? {
        image: {
          create: {
            fileId: image.fileId,
            fileName: image.fileName,
            public: image.public,
          },
        },
      }
    : {};

  if (dateType === DateType.Single) {
    return {
      ...campaignData,
      ...createLogo,
      ...createImage,
      startDate,
    };
  }

  const endDate = concatDateWithTime(endDateFromState, endTimeFromState);

  return {
    ...campaignData,
    ...createLogo,
    ...createImage,
    startDate,
    endDate,
  };
}

export function getCampaignPersonalMissionFormOneSchema(formData: any) {
  const isSingleDateType = formData.dateType === DateType.Single;

  return isSingleDateType
    ? CampaignPersonalMissionFormOneSchema
    : CampaignPersonalMissionFormOneSchemaWithEndDate;
}

export function buildCampaignImpactGivingFormData(campaignValues: any): any {
  const {
    startDate: startDateFromState,
    startTime: startTimeFormState,
    dateType,
    logo,
    image,
    ...campaignData
  } = campaignValues;

  const startDate = concatDateWithTime(startDateFromState, startTimeFormState);

  const createLogo = !_.isNil(logo)
    ? {
        logo: {
          create: {
            fileId: logo.fileId,
            fileName: logo.fileName,
            public: logo.public,
          },
        },
      }
    : {};

  const createImage = !_.isNil(image)
    ? {
        image: {
          create: {
            fileId: image.fileId,
            fileName: image.fileName,
            public: image.public,
          },
        },
      }
    : {};

  return {
    ...campaignData,
    ...createLogo,
    ...createImage,
    startDate,
  };
}

export function getCampaignTeamsPastDaysCountByPredicate(
  campaign: CampaignDetailItem | undefined | null,
  predicate: (days: number) => boolean,
) {
  if (!campaign) {
    return 0;
  }

  return (
    campaign.teams?.items?.reduce((count, team) => {
      const pastDaysFromNow = getPastDaysFromNow(team?.createdAt);
      if (predicate(pastDaysFromNow)) {
        return count + 1;
      }
      return count;
    }, 0) || 0
  );
}
