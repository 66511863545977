import React from 'react';
import { Grid, Box, Typography, styled } from '@mui/material';
import _ from 'lodash';

import { useModal, useNotification } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';
import { useRegistrationOptionSwagbagDeleteMutation } from '@vizsla/graphql';

import { MODALS } from 'src/constants/modals';
import { t } from 'src/utils/template';
import { SwagBagAssetActionUpdateOrDeleteModalArgs } from 'src/types/registrationOption';

const StyledModalContentBox = styled(Box)(({ theme }) => ({
  margin: 0,
  '& > *': {
    paddingBottom: theme.spacing(1),
  },
}));

const RegistrationOptionSwagBagAssetDeleteModal: React.FC = () => {
  const { isOpen, args, closeModal } = useModal(
    MODALS.REGISTRATION_OPTION_SWAG_BAG_ASSET_DELETE_MODAL,
  );

  const notification = useNotification();
  const modalArgs: SwagBagAssetActionUpdateOrDeleteModalArgs | undefined = args;
  const [registrationOptionSwagbagDeleteMutation, { loading, error }] =
    useRegistrationOptionSwagbagDeleteMutation();

  const swagBagStoreAsset = modalArgs?.storeAsset;

  const onDeleteStoreAsset = React.useCallback(async () => {
    if (_.isNil(swagBagStoreAsset)) {
      return;
    }

    try {
      const response = await registrationOptionSwagbagDeleteMutation({
        variables: {
          filter: {
            id: swagBagStoreAsset?.id,
          },
        },
      });

      notification.success(t('registration_option_swag_bag_delete_success'));

      closeModal();
    } catch (err) {
      notification.error(t('registration_option_swag_bag_delete_error'));
      console.error(err);
    }
  }, [closeModal, registrationOptionSwagbagDeleteMutation, swagBagStoreAsset, notification]);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth>
      <Modal.Title>Delete Swag Bag Item</Modal.Title>

      <StyledModalContentBox>
        <Modal.Content>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography textAlign="center">
                Are you sure you want to delete this Swag Bag Item? Related Store Asset will not be
                deleted.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Modal.Actions>
                <Modal.Action
                  type="secondary"
                  content="Cancel"
                  disabled={loading}
                  onAction={closeModal}
                />

                <Modal.Action
                  type="primary"
                  content="Delete"
                  loading={loading}
                  onAction={onDeleteStoreAsset}
                />
              </Modal.Actions>
            </Grid>
          </Grid>
        </Modal.Content>
      </StyledModalContentBox>
    </Modal>
  );
};

export { RegistrationOptionSwagBagAssetDeleteModal };
