import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { FileInputValue, FileValue } from '8base-react-sdk';
import { Avatar, Grid, IconButton, List, ListItem, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PhotoCameraOutlined, ClearSharp as ClearIcon } from '@mui/icons-material';

import { File } from '@vizsla/graphql';

import { BorderRadius, PaletteColor, Shade } from 'src/theme';
import { EMPTY_IMAGE } from 'src/assets';

import { FileInputWrap as FileInput } from './FileInputWrap';

interface StyleProps {
  height: number;
  width: number;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  imageContainer: {
    height: props => props.height + 2,
    width: props => props.width + 2,
    background: PaletteColor.White,
    borderRadius: BorderRadius.M,
    border: `1px dashed ${PaletteColor.PrimaryTeal}`,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
      fill: PaletteColor.PrimaryTeal,
      marginBottom: 4,
    },
    '&.filled': {
      borderColor: Shade.Gray[100],
    },
  },
  img: {
    borderRadius: BorderRadius.M,
    maxWidth: props => props.width,
    maxHeight: props => props.height,
    width: 'auto',
    height: 'auto',
  },
  label: {
    color: PaletteColor.TealText,
  },
  prompt: {
    color: PaletteColor.DefaultLight,
  },
}));

const DEFAULT_MAX_SIZE = 8 * 1024 * 1024;
const DEFAULT_MAX_COUNT_FILES = 1;

export interface ImageInputProps {
  label: string;
  name: string;
  height: number;
  width: number;
  value: FileInputValue | null;
  onChange: any;

  maxSize?: number; // in bytes
  imageDim?: [number, number];
  maxFiles?: number;
}

export const ImageInput: React.FC<ImageInputProps> = ({
  height,
  width,
  name,
  value,
  onChange,
  imageDim,
  label,
  maxSize = DEFAULT_MAX_SIZE,
  maxFiles = DEFAULT_MAX_COUNT_FILES,
  ...rest
}) => {
  const classes = useStyles({
    height,
    width,
  });

  const renderPrompt = () => {
    if (_.isNil(imageDim)) {
      return null;
    }

    const prompt = `Recommended size ${imageDim[0]} x ${imageDim[1]} pixels`;

    return (
      <Typography variant="caption" className={classes.prompt}>
        {prompt}
      </Typography>
    );
  };

  const onDeleteByFileId = (fileId: string) => {
    const filesList = (value as FileValue[]) || [];
    onChange(filesList.filter(image => image.fileId !== fileId));
  };

  const renderImage = () => {
    if (_.isNil(value) || maxFiles > DEFAULT_MAX_COUNT_FILES) {
      return (
        <div className={classes.imageContainer}>
          <PhotoCameraOutlined />
          <Typography variant="caption" className={classes.label}>
            {label}
          </Typography>
          {renderPrompt()}
        </div>
      );
    }

    const imageDownloadUrl = (value as FileValue)?.downloadUrl;

    return (
      <div className={clsx(classes.imageContainer, 'filled')}>
        <img className={classes.img} alt={name} src={imageDownloadUrl} />
      </div>
    );
  };

  const renderImagesList = () => {
    if (maxFiles <= DEFAULT_MAX_COUNT_FILES) {
      return null;
    }

    const filesList = (value as FileValue[]) || [];
    return (
      <List component="div" sx={theme => ({ marginTop: theme.spacing(3), maxWidth: '520px' })}>
        {filesList?.map(({ filename, fileId, downloadUrl }: File) => (
          <ListItem
            disablePadding
            sx={theme => ({ paddingBottom: theme.spacing(1.5) })}
            key={fileId}
          >
            <Avatar
              alt="Header Image"
              src={downloadUrl || EMPTY_IMAGE}
              sx={theme => ({ width: 48, height: 48, marginRight: theme.spacing(2) })}
              variant="rounded"
            />
            <Typography variant="caption" noWrap sx={{ flex: '1', marginRight: '5px' }}>
              {filename}
            </Typography>
            <IconButton onClick={() => onDeleteByFileId(fileId as string)} size="small">
              <ClearIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    );
  };

  const onPickImage = (pick: any) => {
    pick({
      maxSize,
      accept: ['image/jpeg', 'image/png'],
      fromSources: ['local_file_system'],
      imageDim,
    });
  };

  return (
    <FileInput maxFiles={maxFiles} onChange={onChange} value={value} {...rest}>
      {({ pick }) => {
        return (
          <Grid container direction="column" alignItems="center">
            <Grid item xs={12} onClick={() => onPickImage(pick)}>
              {renderImage()}
            </Grid>
            <Grid item xs={12}>
              {renderImagesList()}
            </Grid>
          </Grid>
        );
      }}
    </FileInput>
  );
};
