import React from 'react';
import { Grid, IconButton, Popover, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DeleteOutline as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';

import { Button } from '@vizsla/components';
import { DateFormatPatterns } from '@vizsla/constants';
import { usePopover } from '@vizsla/hooks';
import { concatDateWithTime, formatDate } from '@vizsla/utils';

import { PaletteColor } from 'src/theme';
import { PricingTierFormAction, PricingTierFieldValue } from 'src/types/pricingSettings';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrap: {
    width: '368px',
    padding: theme.spacing(2),
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: PaletteColor.PrimaryGray,
  },
}));

interface ScaledPriceDeleteTierPopoverProps {
  update: (value: PricingTierFieldValue) => void;
  currentValue: PricingTierFieldValue;
  disabled: boolean;
}

export const ScaledPriceDeleteTierPopover: React.FC<ScaledPriceDeleteTierPopoverProps> = ({
  update,
  currentValue,
  disabled,
}) => {
  const popover = usePopover();
  const classes = useStyles();

  const startDate = concatDateWithTime(currentValue.startDate, currentValue.startTime);
  const endDate = concatDateWithTime(currentValue.endDate, currentValue.endTime);

  const formattedStartDate = formatDate(startDate, DateFormatPatterns.smallDateWithLocalTime);
  const formattedEndDate = formatDate(endDate, DateFormatPatterns.smallDateWithLocalTime);
  const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

  const onDelete = React.useCallback(() => {
    popover.closePopover();
    setTimeout(
      () =>
        update({
          ...currentValue,
          formAction: PricingTierFormAction.toDelete,
        }),
      200,
    );
  }, [popover, currentValue, update]);

  return (
    <React.Fragment>
      <Grid item>
        <Tooltip title="Remove Price Tier">
          <IconButton
            aria-label="select-columns"
            size="large"
            onClick={popover.openPopover}
            disabled={disabled}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Popover
        anchorEl={popover.el}
        open={popover.isOpen}
        onClose={popover.closePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid className={classes.contentWrap}>
          <Grid marginBottom={2} container justifyContent="center">
            <Typography variant="subtitle1">Delete Pricing Tier</Typography>
            <IconButton
              aria-label="close"
              onClick={popover.closePopover}
              className={classes.closeIcon}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid marginBottom={4}>
            <Typography variant="body1" textAlign="center">
              {formattedDateRange}
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center" spacing={1}>
            <Button variant="contained" color="secondary" onClick={onDelete}>
              Delete
            </Button>
            <Button color="info" variant="text" onClick={popover.closePopover}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </React.Fragment>
  );
};
