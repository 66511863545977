import React from 'react';
import {
  DashboardOutlined as DashboardOutlinedIcon,
  FaceOutlined,
  LocalAtmOutlined,
  SubjectOutlined as SubjectOutlinedIcon,
  SupervisedUserCircleOutlined,
  Wallpaper as WallpaperIcon,
} from '@mui/icons-material';

import { buildUrl } from '@vizsla/utils';

import { useCampaignId } from 'src/hooks/campaign';
import { CampaignMenu, CampaignMenuItemHeader } from 'src/components/campaign';
import * as entitiesId from 'src/constants/entities-id';
import * as appRoutes from 'src/constants/routes';

export const PersonalMissionCampaignMenu: React.FC = () => {
  const campaignId = useCampaignId();

  const CAMPAIGN_MENU_ITEMS = [
    {
      to: buildUrl(appRoutes.campaignDashboard, { [entitiesId.campaign]: campaignId }),
      text: 'Dashboard',
      icon: <DashboardOutlinedIcon />,
    },
    {
      to: buildUrl(appRoutes.campaignBasics, { [entitiesId.campaign]: campaignId }),
      text: 'Basics',
      icon: <SubjectOutlinedIcon />,
    },
    {
      to: buildUrl(appRoutes.campaignFundraising, { [entitiesId.campaign]: campaignId }),
      text: 'Fundraising',
      icon: <LocalAtmOutlined />,
    },
  ];

  const DESIGN_CAMPAIGN_MENU = [
    {
      to: buildUrl(appRoutes.campaignPages, { [entitiesId.campaign]: campaignId }),
      text: 'Campaign Pages',
      icon: <WallpaperIcon />,
    },
  ];

  const MANAGE_CAMPAIGN_MENU_ITEMS = [
    {
      to: buildUrl(appRoutes.campaignPeople, { [entitiesId.campaign]: campaignId }),
      text: 'People',
      icon: <FaceOutlined />,
    },
    {
      to: buildUrl(appRoutes.campaignAdministration, { [entitiesId.campaign]: campaignId }),
      text: 'Administration',
      icon: <SupervisedUserCircleOutlined />,
    },
  ];

  return (
    <React.Fragment>
      <React.Fragment>
        <CampaignMenuItemHeader>CAMPAIGN</CampaignMenuItemHeader>
        <CampaignMenu items={CAMPAIGN_MENU_ITEMS} />
      </React.Fragment>
      <React.Fragment>
        <CampaignMenuItemHeader>DESIGN</CampaignMenuItemHeader>
        <CampaignMenu items={DESIGN_CAMPAIGN_MENU} />
      </React.Fragment>
      <React.Fragment>
        <CampaignMenuItemHeader>MANAGE</CampaignMenuItemHeader>
        <CampaignMenu items={MANAGE_CAMPAIGN_MENU_ITEMS} />
      </React.Fragment>
    </React.Fragment>
  );
};
