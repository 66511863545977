import { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import moment from 'moment';

const QUERY = gql`
  query CampaignAttendeesMetrics(
    $campaignId: ID!
    $todayDate: DateTime!
    $lastSevenDaysDate: DateTime!
  ) {
    campaign(id: $campaignId) {
      totalGoal: attendanceFundraisersCountGoal

      all: experiences(
        groupBy: {
          query: { attendees: { id: { as: "count", fn: { aggregate: COUNT, distinct: true } } } }
        }
      ) {
        groups {
          count: Int
        }
      }
    }

    all: attendeesList(filter: { experience: { campaign: { id: { equals: $campaignId } } } }) {
      count
    }

    lastSevenDays: attendeesList(
      filter: {
        AND: [
          { createdAt: { gte: $lastSevenDaysDate } }
          { experience: { campaign: { id: { equals: $campaignId } } } }
        ]
      }
    ) {
      count
    }

    today: attendeesList(
      filter: {
        AND: [
          { createdAt: { gte: $todayDate } }
          { experience: { campaign: { id: { equals: $campaignId } } } }
        ]
      }
    ) {
      count
    }
  }
`;

interface Response {
  campaign: {
    totalGoal: number;
  };

  all: {
    count: number;
  };

  lastSevenDays: {
    count: number;
  };

  today: {
    count: number;
  };
}

interface Variables {
  campaignId: string;

  todayDate: string;
  lastSevenDaysDate: string;
}

interface CampaignAttendeesMetrics {
  totalCount: number;
  totalGoal: number;

  totalCountLastSevenDays: number;
  totalCountToday: number;
}

export function useCampaignAttendeesMetrics(campaignId?: string) {
  const [data, setData] = useState<CampaignAttendeesMetrics>();
  const [loading, setLoading] = useState(false);

  const [executeQuery] = useLazyQuery<Response, Variables>(QUERY, { fetchPolicy: 'cache-first' });

  async function refetch(campaignId: string) {
    setLoading(true);

    const todayDate = moment().startOf('day').toISOString();
    const lastSevenDaysDate = moment().startOf('day').subtract(7, 'days').toISOString();

    const response = await executeQuery({
      variables: {
        campaignId,
        todayDate,
        lastSevenDaysDate,
      },
    });

    const campaign = response.data?.campaign;

    const totalGoal = response.data?.campaign?.totalGoal ?? 0;
    const totalCount = response.data?.all.count ?? 0;
    const totalCountToday = response.data?.today.count ?? 0;
    const totalCountLastSevenDays = response.data?.lastSevenDays.count ?? 0;

    const metrics: CampaignAttendeesMetrics = {
      totalGoal,
      totalCount,
      totalCountToday,
      totalCountLastSevenDays,
    };

    setData(metrics);
    setLoading(false);

    return metrics;
  }

  useEffect(() => {
    if (campaignId) {
      refetch(campaignId);
    }
  }, [campaignId]);

  return {
    data,
    loading,

    refetch,
  };
}
