import React from 'react';
import _ from 'lodash';
import { FormApi } from 'final-form';
import { Form, Field, FormSpy } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { DateFormatPatterns } from '@vizsla/constants';
import { validateWithSchema, concatDateWithTime, formatDate, getUserFullName } from '@vizsla/utils';
import { useCurrentOrganization } from '@vizsla/hooks';
import { Program, ProgramsListQuery, User } from '@vizsla/graphql';
import {
  Button,
  TextField,
  SelectField,
  QrAndCopyWebLink,
  FullscreenLoader,
} from '@vizsla/components';
import { PaletteColor } from '@vizsla/theme';

import { useCampaign, useCampaignId } from 'src/hooks/campaign';
import { PROGRAMS_QUERY } from 'src/graphql';
import {
  RegistrationCampaignBasicsDetailsFormSchema,
  PersonalMissionCampaignBasicsDetailsFormSchema,
  ImpactGivingCampaignBasicsDetailsFormSchema,
} from 'src/constants/validationSchemas/campaign';
import { CampaignType, CampaignDetailItem } from 'src/types/campaign';
import { DateType } from 'src/constants/campaign';
import { isPersonalMission } from 'src/utils/campaign';
import { useFullPublicWebLink } from 'src/hooks/publicPages';
import { PaperBlock } from 'src/components/shared';
import { CampaignDetailsGoalsInputs, CampaignDetailsDatesInputs } from 'src/components/campaign';

const CAMPAIGN_TYPE_OPTIONS = [
  {
    label: CampaignType.registrationAndTicketing,
    value: CampaignType.registrationAndTicketing,
  },
  {
    label: CampaignType.personalMission,
    value: CampaignType.personalMission,
  },
  {
    label: CampaignType.impactGiving,
    value: CampaignType.impactGiving,
  },
];

const VALIDATION_SCHEMES = {
  [CampaignType.registrationAndTicketing]: RegistrationCampaignBasicsDetailsFormSchema,
  [CampaignType.personalMission]: PersonalMissionCampaignBasicsDetailsFormSchema,
  [CampaignType.impactGiving]: ImpactGivingCampaignBasicsDetailsFormSchema,
};

const getInitialValues = (campaign?: CampaignDetailItem) => {
  if (_.isNil(campaign)) {
    return {};
  }

  return {
    ..._.pick(campaign, [
      'name',
      'code',
      'campaignType',
      'fundraisingGoal',
      'attendanceFundraisersCountGoal',
      'teamGoal',
      'donorGoal',
      'fundraiserGoal',
      'totalRaised',
    ]),
    webLink: campaign?.dnsRecord?.slug,
    startDate: campaign?.startDate,
    startTime: campaign?.startDate,
    endDate: campaign?.endDate,
    endTime: campaign?.endDate,
    dateType: _.isNil(campaign?.endDate) ? DateType.Single : DateType.Range,
    program: campaign?.program?.id,
  };
};

const getFieldsData = ({
  startDate,
  startTime,
  endDate,
  endTime,
  dateType: dateTypeValue,
  program,
  ...rest
}: any) => ({
  startDate: concatDateWithTime(startDate, startTime),
  endDate: dateTypeValue === DateType.Range ? concatDateWithTime(endDate, endTime) : null,
  program: !_.isNil(program)
    ? {
        connect: {
          id: program,
        },
      }
    : undefined,
  ...rest,
});

const useStyles = makeStyles((theme: Theme) => ({
  linkActionIcon: { cursor: 'pointer', fontSize: 16, color: PaletteColor.TealText },
  submitMessage: {
    transition: theme.transitions.create(['opacity']),
    color: PaletteColor.GrayText,
    '&.hidden': {
      opacity: 0,
    },
  },
}));

export const CampaignDetailsForm: React.FC = () => {
  const classes = useStyles();

  const campaignId = useCampaignId();

  const [showSubmitMsg, setShowSubmitMsg] = React.useState(false);

  const [dateType, setDateType] = React.useState<DateType>(DateType.Single);

  const [dateValidationErrors, setDateValidationErrors] = React.useState(undefined);

  const [initialValues, setInitialValues] = React.useState({});

  const [formattedWebLink, setFormattedWebLink] = React.useState<string | undefined>(undefined);

  const { getFullPublicWebLink } = useFullPublicWebLink();

  const updateDateErrors = newValue => {
    setDateValidationErrors(newValue);
  };

  React.useEffect(() => {
    if (showSubmitMsg) {
      const timer = setTimeout(() => {
        setShowSubmitMsg(false);
      }, 2000);
      return () => clearTimeout(timer);
    }

    return () => {};
  }, [showSubmitMsg]);

  const {
    data: campaign,
    loading: campaignLoading,
    updateCampaign,
    updating,
  } = useCampaign(campaignId);

  React.useEffect(() => {
    if (!_.isNil(campaign?.endDate)) {
      setDateType(DateType.Range);
    }
  }, [campaign, campaign?.endDate]);

  const { organizationId } = useCurrentOrganization();

  const { data: programsData, loading: programsLoading } = useQuery<ProgramsListQuery>(
    PROGRAMS_QUERY,
    {
      variables: {
        filter: {
          organization: {
            id: {
              equals: organizationId,
            },
          },
        },
      },
      skip: !organizationId,
    },
  );

  const programsOptions = React.useMemo(() => {
    if (programsLoading || _.isNil(programsData)) {
      return [];
    }

    return programsData.programsList.items.map((program: Program) => ({
      label: program.name,
      value: program.id,
    }));
  }, [programsData, programsLoading]);

  const shouldRenderDateTypeSwitch = isPersonalMission(campaign);

  React.useEffect(() => {
    setInitialValues(getInitialValues(campaign));
  }, [campaign]);

  React.useEffect(() => {
    const slug = campaign?.dnsRecord?.slug;
    if (_.isNil(slug)) {
      return;
    }

    setFormattedWebLink(getFullPublicWebLink(slug));
  }, [campaign, campaign?.webLink, getFullPublicWebLink]);

  if (campaignLoading || _.isNil(campaign)) {
    return <FullscreenLoader variant="light" />;
  }

  const onSubmit = async (data: any, form: FormApi) => {
    try {
      await updateCampaign(getFieldsData(data), ['ProgramsListQuery']);

      form.reset();

      setShowSubmitMsg(true);
    } catch (e) {
      console.error(e);
    }
  };

  const onFormChange = (form: FormApi) => {
    const {
      values: { webLink, dateType: dateTypeValue },
    } = form.getState();

    setFormattedWebLink(getFullPublicWebLink(webLink));
    setDateType(dateTypeValue ?? DateType.Single);
  };

  const onCancel = (form: FormApi) => {
    form.reset();
  };

  const validationSchema = VALIDATION_SCHEMES[campaign.campaignType as CampaignType];

  const creationDate = formatDate(campaign?.createdAt, DateFormatPatterns.fullDateStartsFromMonth);
  const updateDate = formatDate(campaign?.updatedAt, DateFormatPatterns.fullDateStartsFromMonth);
  const userFullName = getUserFullName(campaign?.createdBy as User);

  return (
    <PaperBlock title="Details" collapsing defaultOpened>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={values => validateWithSchema(validationSchema, values)}
      >
        {({ form, handleSubmit, submitting, pristine, hasValidationErrors, dirty }) => {
          const isSubmitDisabled = updating || submitting || pristine || hasValidationErrors;

          const isCancelDisabled = !dirty;

          return (
            <form onSubmit={handleSubmit}>
              <FormSpy onChange={() => onFormChange(form)} />
              <Grid container spacing={2} marginBottom={4}>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    name="name"
                    label="Campaign Name"
                    required
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="campaignType"
                    label="Campaign Type"
                    required
                    disabled
                    options={CAMPAIGN_TYPE_OPTIONS}
                    component={SelectField}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    name="webLink"
                    label="Web Link"
                    disabled
                    required
                    component={TextField}
                  />
                  {/* <QrAndCopyWebLink webLink={formattedWebLink} /> */}
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    name="code"
                    label="Internal Campaign Name or Code"
                    component={TextField}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} marginBottom={4}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Campaign Goals</Typography>
                </Grid>
                <Grid item xs={12}>
                  <CampaignDetailsGoalsInputs
                    campaignType={campaign.campaignType as CampaignType}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} marginBottom={4}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Campaign Dates</Typography>
                </Grid>
                <Grid item xs={12}>
                  <CampaignDetailsDatesInputs
                    shouldRenderDateTypeSwitch={shouldRenderDateTypeSwitch}
                    dateType={dateType}
                    errorState={dateValidationErrors}
                    errorStateCallback={updateDateErrors}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} marginBottom={4}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Is this Campaign part of a Program?</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="program"
                    label="Program Designation"
                    options={programsOptions}
                    component={SelectField}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container justifyContent="center" spacing={1} marginBottom={4}>
                <Typography
                  variant="body2"
                  className={clsx({
                    [classes.submitMessage]: true,
                    hidden: !showSubmitMsg,
                  })}
                >
                  Saved!
                </Typography>
              </Grid>
              <Grid item xs={12} container justifyContent="center" spacing={1} marginBottom={2}>
                <Button onClick={handleSubmit} disabled={isSubmitDisabled} loading={updating}>
                  Save
                </Button>
                <Button variant="text" onClick={() => onCancel(form)} disabled={isCancelDisabled}>
                  Cancel
                </Button>
              </Grid>
            </form>
          );
        }}
      </Form>
      <Grid item xs={12} justifyContent="center" container>
        <Grid item>
          <Typography variant="caption" sx={{ color: PaletteColor.GrayText }}>
            {`Created on: ${creationDate} by ${userFullName}. Last edited: ${updateDate}`}
          </Typography>
        </Grid>
      </Grid>
    </PaperBlock>
  );
};
