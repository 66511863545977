import _ from 'lodash';

import { ExperienceBibPool, RegistrationOption } from '@vizsla/graphql';

import { AllocationType } from '../constants/experienceBibPoolFormConstants';

export const getBibPoolInitialValues = (currentBibPool?: ExperienceBibPool) => {
  if (currentBibPool) {
    const assignedBibRanges = currentBibPool?.bibRanges?.map(bibRange => {
      if (!bibRange) {
        return { startNumber: undefined, endNumber: undefined };
      }

      const splittedBibRange = bibRange.split('-');
      return {
        startNumber: splittedBibRange[0],
        endNumber: splittedBibRange[1],
      };
    });

    const assignedTeams = currentBibPool?.teamAllocation?.items || [];
    const registrationOption = currentBibPool?.registrationOption?.id ?? null;
    const allocationType =
      currentBibPool?.allocationType === AllocationType.SpecificRegistrationOption
        ? registrationOption
        : currentBibPool?.allocationType;

    return {
      name: currentBibPool?.name ?? null,
      bibRanges: assignedBibRanges ?? [{ startNumber: undefined, endNumber: undefined }],
      allocationType,
      teamAllocation: assignedTeams ?? null,
    };
  }
  return {
    name: null,
    bibRanges: [{ startNumber: undefined, endNumber: undefined }],
    allocationType: null,
    teamAllocation: null,
  };
};

export type BibRangeType = {
  startNumber: number;
  endNumber: number;
};

export const getPreparedData = (
  formData: Record<string, any>,
  registrationOptionsList: Array<RegistrationOption>,
) => {
  const preparedBibRanges = formData.bibRanges.map(
    (bibRange: BibRangeType) => `${bibRange.startNumber}-${bibRange.endNumber}`,
  );

  const preparedTeamAllocation = formData.teamAllocation?.map(
    (allocatedTeam: Record<string, any>) => ({
      id: allocatedTeam.id,
    }),
  );

  const allocationValue = formData?.allocationType;

  if (
    registrationOptionsList.some(registrationOption => registrationOption.id === allocationValue)
  ) {
    return {
      ...formData,
      registrationOption: allocationValue,
      allocationType: AllocationType.SpecificRegistrationOption,
      teamAllocation: preparedTeamAllocation,
      bibRanges: preparedBibRanges,
    };
  }

  return {
    ...formData,
    registrationOption: null,
    teamAllocation: preparedTeamAllocation,
    bibRanges: preparedBibRanges,
  };
};

type FieldMessagesTypes = {
  startNumberMessage?: string;
  endNumberMessage?: string;
};

const binarySearchForBibRanges = (
  array: Array<Array<number>>,
  newBibRange: BibRangeType,
): number => {
  let start = -1;
  let end = array.length;
  while (1 + start < end) {
    const middle = Math.floor((start + end) / 2);
    if (newBibRange.startNumber === array[middle][0]) {
      return -1;
    }

    if (newBibRange.startNumber < array[middle][0]) {
      end = middle;
    } else {
      start = middle;
    }
  }

  const insertAtStart = end === 0;
  const insertAtEnd = end === array.length;

  const hasInsertionError =
    (!insertAtStart && newBibRange.startNumber <= array[end - 1][1]) ||
    (!insertAtEnd && newBibRange.endNumber >= array[end][0]);

  if (hasInsertionError) {
    return -1;
  }

  return end;
};

const checkBibRangesForAcceptableBoundaryValues = (
  newBibRange: BibRangeType,
  excludedBibNumbers: Array<number>,
  individualBibNumbers: Array<number>,
): string => {
  if (excludedBibNumbers.includes(newBibRange.startNumber)) {
    return 'Start Number has already been excluded';
  }
  if (excludedBibNumbers.includes(newBibRange.endNumber)) {
    return 'End Number has already been excluded';
  }
  if (individualBibNumbers.includes(newBibRange.startNumber)) {
    return 'Start Number has already been assigned individually';
  }
  if (individualBibNumbers.includes(newBibRange.endNumber)) {
    return 'End Number has already been assigned individually';
  }

  return '';
};

const checkBibRangeStartAndEndNumbers = (newBibRange: BibRangeType): FieldMessagesTypes => {
  const fieldMessages: FieldMessagesTypes = {
    startNumberMessage: undefined,
    endNumberMessage: undefined,
  };

  if (!newBibRange?.startNumber) {
    fieldMessages.startNumberMessage = 'Start Number is required';
  } else if (newBibRange.startNumber <= 0) {
    fieldMessages.startNumberMessage = 'Start Number must be more than 0';
  }
  if (!newBibRange?.endNumber) {
    fieldMessages.endNumberMessage = 'End Number is required';
  } else if (newBibRange.endNumber <= 0) {
    fieldMessages.endNumberMessage = 'End Number must be more than 0';
  }

  return fieldMessages;
};

export const validateBibRanges = (
  inputBibRanges: Array<BibRangeType> | null | undefined,
  { context }: any,
): Array<any> => {
  const { bibRanges, excludedBibNumbers, individualBibNumbers } = context as {
    bibRanges: Array<string>;
    excludedBibNumbers: Array<number>;
    individualBibNumbers: Array<number>;
  };

  const errors: Array<any> = [];

  const sortedExistingBibRanges = bibRanges
    .map((bibRange: string) => bibRange.split('-').map(bibNumber => Number(bibNumber)))
    .sort((bibRangeA, bibRangeB) => bibRangeA[0] - bibRangeB[0]);

  if (!_.isNil(inputBibRanges)) {
    // The following line is disabled to allow iterating over an array of strings using an abbreviated notation
    // eslint-disable-next-line no-restricted-syntax
    for (const newBibRange of inputBibRanges) {
      const fieldMessages = checkBibRangeStartAndEndNumbers(newBibRange);

      if (!fieldMessages.startNumberMessage && !fieldMessages.endNumberMessage) {
        if (newBibRange.startNumber >= newBibRange.endNumber) {
          errors.push({ message: 'End Number must be greater Start Number' });
        } else {
          const invalidBibNumberAmongSpecialError = checkBibRangesForAcceptableBoundaryValues(
            newBibRange,
            excludedBibNumbers,
            individualBibNumbers,
          );

          if (invalidBibNumberAmongSpecialError) {
            errors.push({ message: invalidBibNumberAmongSpecialError });
          } else {
            const insertionIndexForNewBibRange = binarySearchForBibRanges(
              sortedExistingBibRanges,
              newBibRange,
            );

            if (insertionIndexForNewBibRange === -1) {
              errors.push({ message: 'This bib range intersects/includes/contains another range' });
            } else {
              errors.push({ message: '' });
              sortedExistingBibRanges.splice(insertionIndexForNewBibRange, 0, [
                newBibRange.startNumber,
                newBibRange.endNumber,
              ]);
            }
          }
        }
      } else {
        errors.push(fieldMessages);
      }
    }
  }

  const errorsCount = errors.filter(error => error?.message !== '').length;

  return errorsCount !== 0 ? errors : [];
};
