import React from 'react';
import { Field, FormSpy, useForm } from 'react-final-form';
import { Avatar, Chip, Grid, Skeleton, Typography } from '@mui/material';

import {
  useDataBaseSelectField,
  DataBaseMultiSelectField,
  RadioGroupField,
} from '@vizsla/components';
import { CampaignTeamsListQuery, TeamFundraising } from '@vizsla/graphql';

import { useCampaignId } from 'src/hooks/campaign';
import { FontSize } from 'src/theme';
import { CAMPAIGN_TEAMS_LIST_QUERY } from 'src/graphql';
import { EMPTY_IMAGE } from 'src/assets';
import {
  ALLOCATION_TYPE_OPTIONS,
  AllocationType,
} from 'src/modals/experienceBibPoolCreateEditModals/constants/experienceBibPoolFormConstants';
import { useRegistrationOptions } from 'src/hooks/registrationOption';
import { useExperienceId } from 'src/hooks/experiences';

export const ExperienceBibPoolFormAllocationSection: React.FC = () => {
  const { change, getState } = useForm();
  const campaignId = useCampaignId();
  const experienceId = useExperienceId();

  const { registrationOptions: registrationOptionsList, loading: registrationOptionsLoading } =
    useRegistrationOptions(experienceId);

  const registrationOptions =
    registrationOptionsList.map(registrationOption => ({
      value: registrationOption.id,
      label: registrationOption.name,
    })) || [];

  const ALLOCATIONS_WITH_REG_OPTIONS = [...registrationOptions, ...ALLOCATION_TYPE_OPTIONS];

  const getTeamListQueryVariables = () => ({
    filter: {
      campaign: {
        id: {
          equals: campaignId,
        },
      },
    },
  });

  const multiSelectFieldProps = useDataBaseSelectField<CampaignTeamsListQuery, TeamFundraising>(
    {
      query: CAMPAIGN_TEAMS_LIST_QUERY,
      getQueryItems: data => data?.teamRTSList?.items,
      getCustomQueryVariables: getTeamListQueryVariables,
    },
    {
      getOptionLabel: (team: TeamFundraising) => team?.name || '',
      renderTag: (team: TeamFundraising) => {
        const teamName = team?.name;

        const renderUserAvatar = () => {
          const avatarUrl = team?.avatar?.downloadUrl;
          return <Avatar alt="" src={avatarUrl || EMPTY_IMAGE} />;
        };

        return (
          <Chip
            key={team?.id as string}
            variant="outlined"
            avatar={renderUserAvatar()}
            label={teamName}
            sx={{ m: '2px' }}
          />
        );
      },
    },
  );

  return (
    <FormSpy subscription={{ submitting: true, values: true, initialValues: true }}>
      {({ submitting }) => {
        const { values } = getState();
        const { allocationType } = values;

        const hasSpecificTeams = allocationType === AllocationType.SpecificTeams;

        if (!hasSpecificTeams) {
          change('teamAllocation', null);
        }

        return (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6">Allocation</Typography>
              <Typography variant="body2">
                Bib numbers will be assigned to attendees based on registration options and
                additional conditions selected
              </Typography>
            </Grid>
            {registrationOptionsLoading ? (
              <Skeleton variant="text" height={120} width="100%" />
            ) : (
              <React.Fragment>
                <Grid item xs={12} paddingBottom={2}>
                  <Field
                    name="allocationType"
                    options={ALLOCATIONS_WITH_REG_OPTIONS}
                    component={RadioGroupField}
                    disabled={submitting}
                    optionControlLabelProps={{
                      sx: {
                        '& .MuiButtonBase-root': {
                          padding: '4px',
                        },
                        '& .MuiFormControlLabel-label': {
                          fontSize: FontSize.Default,
                          paddingLeft: '4px',
                        },
                      },
                    }}
                    fullWidth
                  />
                </Grid>
                {hasSpecificTeams ? (
                  <Grid item xs={12}>
                    <Field
                      name="teamAllocation"
                      label="Team Allocation"
                      placeholder="Add Team"
                      component={DataBaseMultiSelectField}
                      disabled={submitting}
                      fullWidth
                      {...multiSelectFieldProps}
                    />
                  </Grid>
                ) : null}
              </React.Fragment>
            )}
          </Grid>
        );
      }}
    </FormSpy>
  );
};
