import { concatDateWithTimeInObj } from '@vizsla/utils';
import {
  EarlyBirdRegistrationOption,
  Experience,
  ExperienceUpdateInput,
  Experience_EarlyBirdRegistrationOptionUpdateInput,
} from '@vizsla/graphql';

import {
  EarlyBirdRegistrationsApplyTo,
  EarlyBirdRegistrationSettingsFormValues,
  EarlyBirdRegistrationsMode,
} from 'src/types/earlyBirdRegistration';

export const dataToInitialValues = (
  earlyBirdRegistrationSettings?: Experience | null,
): EarlyBirdRegistrationSettingsFormValues => {
  const earlyBirdRegistrationOptions =
    earlyBirdRegistrationSettings?.earlyBirdRegistrationOptions?.items?.map(option => {
      const { startDate, endDate, id, registrationOption } = option;

      return {
        id,
        startTime: startDate,
        startDate,
        endTime: endDate,
        endDate,
        registrationOption,
      };
    }) || [];

  const { earlyBirdRegistrationsApplyTo, earlyBirdRegistrationsMode } =
    (earlyBirdRegistrationSettings || {}) as Experience;

  const firstEarlyBirdRegistrationOption: EarlyBirdRegistrationOption =
    earlyBirdRegistrationOptions[0] || {};

  const { id, registrationOption, ...restAllEarlyBirdRegistrationOptions } =
    firstEarlyBirdRegistrationOption;

  return {
    earlyBirdRegistrationsApplyTo: earlyBirdRegistrationsApplyTo as EarlyBirdRegistrationsApplyTo,
    earlyBirdRegistrationsMode: earlyBirdRegistrationsMode as EarlyBirdRegistrationsMode,
    earlyBirdRegistrationOptions,
    allEarlyBirdRegistrationOptions: restAllEarlyBirdRegistrationOptions,
  };
};

export const prepareFormValues = (
  formValues: EarlyBirdRegistrationSettingsFormValues,
  experienceId: string,
): ExperienceUpdateInput => {
  const { earlyBirdRegistrationOptions, allEarlyBirdRegistrationOptions, ...restFormValues } =
    formValues;

  const isAttendeesAndTeams =
    formValues.earlyBirdRegistrationsApplyTo ===
    EarlyBirdRegistrationsApplyTo.allRegistrationOptions;

  const earlyBirdRegistrationOptionUpdateInputs:
    | Array<Experience_EarlyBirdRegistrationOptionUpdateInput>
    | undefined = earlyBirdRegistrationOptions?.map((option, index, array) => {
    const { registrationOption, ...rest } = option;
    if (isAttendeesAndTeams) {
      return {
        data: concatDateWithTimeInObj({ ...allEarlyBirdRegistrationOptions, id: rest.id }),
      };
    }
    return {
      data: concatDateWithTimeInObj(rest),
    };
  });

  return {
    id: experienceId,
    earlyBirdRegistrationOptions: earlyBirdRegistrationOptions
      ? {
          update: earlyBirdRegistrationOptionUpdateInputs,
        }
      : undefined,
    ...restFormValues,
  };
};
