import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Dashboard, TableChartSharp } from '@mui/icons-material';

import { useCampaignsPageStyles } from 'src/hooks/campaign';
import { CampaignLayoutView } from 'src/types/campaignsList';

interface CampaignsListLayoutSwitcherProps {
  campaignLayoutView: CampaignLayoutView;
  onChangeLayout: (event: React.ChangeEvent<any>, newButton: CampaignLayoutView) => void;
}

export const CampaignsListLayoutSwitcher: React.FC<CampaignsListLayoutSwitcherProps> = ({
  campaignLayoutView,
  onChangeLayout,
}) => {
  const classes = useCampaignsPageStyles();
  return (
    <ToggleButtonGroup
      exclusive
      value={campaignLayoutView}
      onChange={onChangeLayout}
      className={classes.toggle}
    >
      <ToggleButton
        value={CampaignLayoutView.Tile}
        disabled={campaignLayoutView === CampaignLayoutView.Tile}
      >
        <Dashboard fontSize="small" />
      </ToggleButton>
      <ToggleButton
        value={CampaignLayoutView.Grid}
        disabled={campaignLayoutView === CampaignLayoutView.Grid}
      >
        <TableChartSharp fontSize="small" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
