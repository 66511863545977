import { gql } from '@apollo/client';

export const StripePlans = gql`
  query StripePlans {
    stripePlans {
      id
      object
      name
      active
      description
      prices {
        id
        active
        nickname
        unit_amount
        recurring
      }
    }
  }
`;

export const CHECKOUT_SUBSCRIPTION = gql`
  mutation CheckoutSubscription($data: CheckoutSubscriptionInput!) {
    checkoutSubscription(data: $data) {
      id
      metadata
      url
    }
  }
`;

export const UPDATE_VIZSLA_STRIPE_PLAN = gql`
  mutation UpdateStripePlan($data: CheckoutUpdateSubscriptionInput!) {
    checkoutUpdateSubscription(data: $data) {
      success
    }
  }
`;

export const UsersStripePlans = gql`
  query UsersStripePlans($filter: VizslaStripePlanFilter) {
    vizslaStripePlansList(filter: $filter) {
      items {
        id
        priceId
        userId
        productId
        customerId
        subscriptionId
        organizationId
        status
      }
    }
  }
`;

export const StripeInvoices = gql`
  query StripeInvoices($customerId: String!) {
    stripeInvoices(customerId: $customerId) {
      id
      customer
      created
      description
      status
      amount_due
      amount_paid
      payment_settings
      hosted_invoice_url
    }
  }
`;

export const STRIPE_PAYMENT_METHODS = gql`
  query StripePaymentMethods($userId: String!) {
    stripePaymentMethods(userId: $userId) {
      items {
        __typename
        ... on CardPaymentMethod {
          id
          type
          primary
          reference
          brand
        }

        ... on BankPaymentMethod {
          id
          type
          primary
          reference
          name
        }

        ... on UnknownPaymentMethod {
          id
          type
          primary
        }
      }
    }
  }
`;

export const STRIPE_PAYMENT_METHODS_DEFAULT = gql`
  mutation StripePaymentMethodDefault($userId: String!, $paymentMethodId: String!) {
    stripePaymentMethodDefault(userId: $userId, paymentMethodId: $paymentMethodId) {
      __typename
      ... on CardPaymentMethod {
        id
        type
        primary
        reference
        brand
      }

      ... on BankPaymentMethod {
        id
        type
        primary
        reference
        name
      }

      ... on UnknownPaymentMethod {
        id
        type
        primary
      }
    }
  }
`;

export const STRIPE_PAYMENT_METHODS_INACTIVE = gql`
  mutation StripePaymentMethodInactive($userId: String!, $paymentMethodId: String!) {
    stripePaymentMethodInactive(userId: $userId, paymentMethodId: $paymentMethodId) {
      success
    }
  }
`;
