import React from 'react';
import { useMatch } from 'react-router-dom';

import {
  Experience,
  Maybe,
  useCampaignPageTemplateUpdateMutation,
  useExperienceLandingQuery,
  useTemplateStructureQuery,
} from '@vizsla/graphql';
import { TemplateType } from '@vizsla/types';

import { ThankYouPageContext } from './ThankYouContext';
import { LandingState } from './ThankYouLanding.types';

interface ThankYouProviderProps {
  children: React.ReactNode;
  experienceId: string;
  campaignId: string | undefined;
}

// can't use @vizsla/hooks in @vizsla/provider, it makes dependency loop
const useExperienceById = (id: string) => {
  const { data, loading } = useExperienceLandingQuery({
    variables: {
      id,
    },
  });

  return { experience: data?.experience as Maybe<Experience> | undefined, loading };
};

// TODO: move to packages/constants
const THANK_YOU_PAGE_EDITOR_ROUTE_NAME = '/editor/:id/thank-you';

export const ThakYouPageProvider: React.FC<ThankYouProviderProps> = ({
  children,
  experienceId,
  campaignId = '',
}) => {
  const isEditor = Boolean(useMatch(THANK_YOU_PAGE_EDITOR_ROUTE_NAME));
  const [updateTemplateStructure, { loading: updating }] = useCampaignPageTemplateUpdateMutation();

  const { experience, loading: experienceLoading } = useExperienceById(experienceId);

  const { data: templateStructureData, loading: templateStructureDataLoading } =
    useTemplateStructureQuery({
      variables: { campaignId, templateType: TemplateType.THANK_YOU },
      skip: !campaignId,
    });

  const templateStructure = templateStructureData?.templateStructuresList.items[0];
  const save = React.useCallback(
    async (landingState: LandingState) => {
      try {
        const structure = {
          appSchema: { nodes: landingState.nodes, theme: landingState.theme },
        };
        await updateTemplateStructure({
          variables: {
            data: {
              id: templateStructure?.id,
              structure,
            },
          },
        });
      } catch (error) {
        console.error({ error });
        throw error;
      }
    },
    [templateStructure?.id, updateTemplateStructure],
  );

  const loading = experienceLoading || templateStructureDataLoading;
  return (
    <ThankYouPageContext.Provider
      value={{
        experience,
        loading,
        updating,
        save,
        templateStructure,
        isEditor,
      }}
    >
      {children}
    </ThankYouPageContext.Provider>
  );
};
