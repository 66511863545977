var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { TextField } from '@mui/material';
import { getFieldError, hasFieldError } from '@vizsla/utils';
import { StripeInput } from '../StripeInput';
const StripeField = (_a) => {
    var { label, component, inputProps, meta, onStripeInputChange } = _a, rest = __rest(_a, ["label", "component", "inputProps", "meta", "onStripeInputChange"]);
    const { onChange, onBlur, onFocus } = inputProps;
    const [hasError, setHasError] = React.useState(false);
    const [helperText, setHelperText] = React.useState(null);
    const handleChange = (event) => {
        const { error } = event.target;
        const errorMessage = error === null || error === void 0 ? void 0 : error.message;
        if (errorMessage) {
            setHasError(true);
            setHelperText(errorMessage);
        }
        if (!errorMessage) {
            setHasError(false);
            setHelperText(null);
        }
        if (onStripeInputChange) {
            onStripeInputChange(event.target);
        }
        onChange(event.target.value);
    };
    const error = hasFieldError(meta);
    const metaHelperText = getFieldError(meta);
    return (_jsx(TextField, Object.assign({ label: label, fullWidth: true, error: hasError || error, helperText: helperText !== null && helperText !== void 0 ? helperText : metaHelperText, InputProps: {
            inputComponent: StripeInput,
            inputProps: { component },
        }, InputLabelProps: { shrink: true }, onChange: handleChange, onBlur: onBlur, onFocus: onFocus }, rest)));
};
export { StripeField };
