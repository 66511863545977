import React from 'react';
import { useMutation } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { Add, RoomOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import { useModal, useApolloCacheQueryManager } from '@vizsla/hooks';
import { TableData } from '@vizsla/components';
import type { TableBottomAction, TableRowAction, TableColumn } from '@vizsla/components';
import {
  ExperienceVenue,
  ExperienceGeneralSettingsListQuery,
  ExperienceGeneralSettingsListQueryVariables,
} from '@vizsla/graphql';
import { mutableList } from '@vizsla/utils';

import { useExperienceId } from 'src/hooks/experiences';
import { MODALS } from 'src/constants/modals';
import {
  EXPERIENCE_VENUE_DELETE_MUTATION,
  EXPERIENCE_GENERAL_SETTINGS_LIST_QUERY,
} from 'src/graphql';

export interface VenuesTableProps {
  venues: ExperienceVenue[];
}

const useStyles = makeStyles({
  container: {
    maxHeight: 300,
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    overflowWrap: 'normal',
    textAlign: 'start',
  },
});

export const VenuesTable: React.FC<VenuesTableProps> = ({ venues }) => {
  const classes = useStyles();
  const experienceId = useExperienceId();

  const [deleteVenue] = useMutation(EXPERIENCE_VENUE_DELETE_MUTATION);

  const { updateQuery: updateExperienceGeneralSettingsQuery } = useApolloCacheQueryManager<
    ExperienceGeneralSettingsListQuery,
    ExperienceGeneralSettingsListQueryVariables
  >({
    query: EXPERIENCE_GENERAL_SETTINGS_LIST_QUERY,
    typeName: 'Experience',
    variables: {
      id: experienceId,
    },
  });

  const { openModal } = useModal();

  const renderRowText = (text: string) => (
    <Box component="div" className={classes.ellipsis} width={200}>
      {text}
    </Box>
  );

  const renderName = (name: string) => (
    <Typography variant="button" display="flex" flexDirection="row" alignContent="center">
      <Box marginRight={1}>
        <RoomOutlined />
      </Box>
      {renderRowText(name)}
    </Typography>
  );

  const deleteAction = async (venueId: string) => {
    try {
      await deleteVenue({
        variables: {
          id: venueId,
        },
      });

      updateExperienceGeneralSettingsQuery(query => {
        if (query?.experience?.venues?.items) {
          mutableList(query.experience.venues.items).removeById(venueId);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const columns: TableColumn<ExperienceVenue>[] = [
    {
      title: 'name',
      key: 'name',
      dataPath: 'name',
      render: renderName,
    },
    {
      title: 'address',
      key: 'address',
      dataPath: 'address',
      render: (address: string) => renderRowText(address),
    },
    {
      title: 'notes',
      key: 'notes',
      dataPath: 'notes',
      render: (notes: string) => renderRowText(notes),
    },
  ];
  const rowActions: Array<TableRowAction<ExperienceVenue>> = [
    {
      actionName: 'Edit',
      onAction: venue =>
        openModal(MODALS.EXPERIENCE_VENUE_CREATE_MODAL, {
          experienceId,
          initialValues: venue,
        }),
    },
    {
      actionName: 'Delete',
      onAction: venue => {
        openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
          deleteFunction: () => deleteAction(venue?.id as string),
          objectName: venue?.name,
          objectType: 'venue',
        });
      },
    },
  ];
  const bottomActions: Array<TableBottomAction> = [
    {
      actionName: 'add venue',
      icon: <Add />,
      onAction: () => openModal(MODALS.EXPERIENCE_VENUE_CREATE_MODAL, { experienceId }),
    },
  ];

  return (
    <TableData
      tableContainerProps={{ className: classes.container }}
      tableProps={{ stickyHeader: true }}
      hideTitle={_.isEmpty(venues)}
      dataSource={venues}
      columns={columns}
      bottomActions={bottomActions}
      rowActions={rowActions}
    />
  );
};
