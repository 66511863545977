import _ from 'lodash';

export function pickTextColorBasedOnBgColor(
  bgColor: string,
  lightColor: string,
  darkColor: string,
): string {
  const hexColor = getFullHex(bgColor);
  const rCoeff = 0.299;
  const gCoeff = 0.587;
  const bCoeff = 0.114;

  const rColor = parseInt(hexColor.substring(1, 3), 16);
  const gColor = parseInt(hexColor.substring(3, 5), 16);
  const bColor = parseInt(hexColor.substring(5, 7), 16);

  const luminance = (rColor * rCoeff + gColor * gCoeff + bColor * bCoeff) / 255;
  return luminance > 0.5 ? darkColor : lightColor;
}

function getFullHex(color: string) {
  if (color.length === 4) {
    const redPart = color[1].concat(color[1]);
    const greenPart = color[2].concat(color[2]);
    const bluePart = color[3].concat(color[3]);
    return `#${redPart}${greenPart}${bluePart}`;
  }
  return color;
}

export function rgbToHex(r?: number, g?: number, b?: number) {
  if (!_.isNumber(r) || !_.isNumber(g) || !_.isNumber(b)) {
    return '#ffffff';
  }
  return `#${[r, g, b]
    .map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join('')}`;
}
