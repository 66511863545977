import React from 'react';
import { Divider, Grid } from '@mui/material';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';

import { Field, DateField, NumberField, SelectField, TimeField, Button } from '@vizsla/components';
import { validateWithSchema } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';
import { Maybe } from '@vizsla/graphql';

import { TAX_DEDUCTIBLE_OPTIONS } from 'src/constants/pricingSettings';
import { PricingTierFieldValue, TaxDeductibleType } from 'src/types/pricingSettings';
import { MONEY_FORMAT, PERCENT_FORMAT } from 'src/constants/formats';
import {
  PricingTierContextSchema,
  ScaledPricingTierSchema,
} from 'src/constants/validationSchemas/pricingSettings';

import { getPricingTierTimingLimits } from '../utils';

interface PricingTierEditForm {
  onSubmit: (values: any) => void;
  initialValues?: PricingTierFieldValue;
  minDateTimeValue?: Maybe<string>;
  maxDateTimeValue?: Maybe<string>;
}

const DEFAULT_INITIAL_VALUES = {
  taxDeductibleType: TaxDeductibleType.Percents,
};

export const ScaledPriceTierForm: React.FC<PricingTierEditForm> = ({
  onSubmit,
  initialValues,
  minDateTimeValue,
  maxDateTimeValue,
}) => {
  const renderTaxDeductibleAmountField = (currentTaxDeductibleTypeValue: TaxDeductibleType) => {
    if (currentTaxDeductibleTypeValue === TaxDeductibleType.Percents) {
      return (
        <Field
          name="taxDeductiblePercentsAmount"
          label="Tax Deductible Amount"
          required
          component={NumberField}
          numberFormat={PERCENT_FORMAT}
        />
      );
    }

    return (
      <Field
        name="taxDeductibleCurrencyAmount"
        label="Tax Deductible Amount"
        required
        component={NumberField}
        numberFormat={MONEY_FORMAT}
      />
    );
  };

  const handleCancel = (form: FormApi) => {
    form.reset();
  };

  return (
    <Form
      onSubmit={data => {
        onSubmit(data);
      }}
      initialValues={initialValues ?? DEFAULT_INITIAL_VALUES}
      validate={values => {
        const context = {
          minDateTimeValue,
          maxDateTimeValue,
        } as PricingTierContextSchema;

        return validateWithSchema(ScaledPricingTierSchema, values, { context });
      }}
      render={({ form, handleSubmit, values }) => {
        const {
          actualMinStartDateTime,
          actualMaxStartDateTime,
          minStartTime,
          maxStartTime,
          actualMinEndDateTime,
          actualMaxEndDateTime,
          minEndTime,
          maxEndTime,
        } = getPricingTierTimingLimits({
          minDateTimeValue,
          maxDateTimeValue,
          currentStartDate: values?.startDate,
          currentStartTime: values?.startTime,
          currentEndDate: values?.endDate,
          currentEndTime: values?.endTime,
        });

        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} marginBottom={4}>
              <Grid item xs={6}>
                <Field
                  required
                  name="startDate"
                  label="Start Date"
                  component={DateField}
                  inputFormat={DateFormatPatterns.shortMonthDayYear}
                  minDate={actualMinStartDateTime}
                  maxDate={actualMaxStartDateTime}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  required
                  name="startTime"
                  label="Start Time"
                  component={TimeField}
                  minTime={minStartTime}
                  maxTime={maxStartTime}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  required
                  name="endDate"
                  label="End Date"
                  component={DateField}
                  minDate={actualMinEndDateTime}
                  maxDate={actualMaxEndDateTime}
                  inputFormat={DateFormatPatterns.shortMonthDayYear}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  required
                  name="endTime"
                  label="End Time"
                  component={TimeField}
                  minTime={minEndTime}
                  maxTime={maxEndTime}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} marginTop={2} marginBottom={2}>
                <Divider />
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    name="price"
                    label="Price (USD)"
                    required
                    component={NumberField}
                    numberFormat={MONEY_FORMAT}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                {renderTaxDeductibleAmountField(values.taxDeductibleType)}
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="taxDeductibleType"
                  label="Tax Deductible Type"
                  required
                  component={SelectField}
                  options={TAX_DEDUCTIBLE_OPTIONS}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-start" spacing={1}>
              <Button variant="contained" color="secondary" onClick={handleSubmit}>
                Save
              </Button>
              <Button color="info" variant="text" onClick={() => handleCancel(form)}>
                Cancel
              </Button>
            </Grid>
          </form>
        );
      }}
    />
  );
};
