import * as yup from 'yup';

import { AddressSchema, PhoneSchemaRequired } from '@vizsla/constants';

export const ContactCrmCreateSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Middle Name is required'),
  email: yup.string().email('Invalid Email').required('Email is required'),
  phone1: PhoneSchemaRequired,
});
