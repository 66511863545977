import * as yup from 'yup';
import _ from 'lodash';

import { CreditCardValidationSchema } from '@vizsla/stripe';
import { PhoneSchemaRequired } from '@vizsla/constants';

import { OfflineDonorType, PaymentMethod } from 'src/types/offlineDonation';

export const OfflineDonationCampaignInfoSchema = yup.object({
  campaignInfo: yup.object({
    campaign: yup
      .string()
      .nullable()
      .required('Campaign is required')
      .test(
        'team-should-relate-to-selected-campaign',
        'Campaign should relate to selected team. Either change campaign or team',
        (campaignId: string | null | undefined, { options }): boolean => {
          const { context } = options;
          if (_.isNil(context?.teamCampaignId)) {
            return true;
          }
          return context?.teamCampaignId === campaignId;
        },
      )
      .test(
        'team-should-relate-to-selected-campaign',
        'Campaign should relate to selected fundraiser. Either change campaign or team',
        (campaignId: string | null | undefined, { options }): boolean => {
          const { context } = options;
          if (_.isNil(context?.fundraiserCampaignId)) {
            return true;
          }
          return context?.fundraiserCampaignId === campaignId;
        },
      ),
    fundraisingTeam: yup.object().nullable().notRequired(),
    fundraiser: yup.object().nullable().notRequired(),
    programDestination: yup.string().nullable().required('Program Destination is required'),
  }),
});

export const OfflineDonationDonorInfoSchema = yup.object({
  donorInfo: yup.object({
    donorType: yup.string().nullable().oneOf(['individual', 'organization']).required(),
    donorFirstName: yup
      .string()
      .nullable()
      .when('donorType', {
        is: OfflineDonorType.individual,
        then: yup.string().required('First Name is required'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    donorLastName: yup
      .string()
      .nullable()
      .when('donorType', {
        is: OfflineDonorType.individual,
        then: yup.string().required('Last Name is required'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    donorEmail: yup.string().email('Invalid Email').required('Email is required'),
    donorPartnershipOrganization: yup
      .object()
      .nullable()
      .when('donorType', {
        is: OfflineDonorType.organization,
        then: yup.object().required('Partnership Organization is required'),
        otherwise: yup.object().nullable().notRequired(),
      }),
    donorPhone: PhoneSchemaRequired,
    dedication: yup.string().nullable().notRequired(),
  }),
});

export const OfflineDonationPaymentInfo = yup.object({
  paymentInfo: yup.object({
    method: yup
      .string()
      .nullable()
      .oneOf([PaymentMethod.cash, PaymentMethod.check, PaymentMethod.card])
      .required(),
    amount: yup.number().positive().nullable().required('Amount is required'),
    from: yup
      .string()
      .nullable()
      .when('method', {
        is: PaymentMethod.check,
        then: yup.string().nullable().required('"From" field is required'),
        otherwise: yup.string().notRequired(),
      }),
    bank: yup
      .string()
      .nullable()
      .when('method', {
        is: PaymentMethod.check,
        then: yup.string().nullable().required('Bank is required'),
        otherwise: yup.string().notRequired(),
      }),
    number: yup
      .string()
      .nullable()
      .when('method', {
        is: PaymentMethod.check,
        then: yup.string().nullable().required('Check number is required'),
        otherwise: yup.string().notRequired(),
      }),
    date: yup.string().when('method', {
      is: (method: PaymentMethod) =>
        method === PaymentMethod.check || method === PaymentMethod.cash,
      then: yup.string().nullable().required('Date is required'),
      otherwise: yup.string().nullable(),
    }),
    billingAddress: yup
      .object()
      .nullable()
      .when('method', {
        is: PaymentMethod.card,
        then: yup.object({
          street1: yup.string().nullable().required('Street is required'),
          state: yup.string().nullable().required('State is required'),
          city: yup.string().nullable().required('City is required'),
          zip: yup.string().nullable().required('ZIP is required'),
        }),
        otherwise: yup.object().notRequired(),
      }),
    creditCard: yup.object().when('method', {
      is: PaymentMethod.card,
      then: CreditCardValidationSchema,
      otherwise: yup.object().notRequired(),
    }),
  }),
});
