import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Field, Form, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import { validateWithSchema } from '@vizsla/utils';
import { useNotification } from '@vizsla/hooks';
import { useExperienceGeneralSettingsListQuery } from '@vizsla/graphql';
import { RadioGroupField, Button, TextField } from '@vizsla/components';

import { t } from 'src/utils/template';
import { useCampaign, useCampaignId } from 'src/hooks/campaign';
import { useExperienceId } from 'src/hooks/experiences';
import { TooltipTextWrapper } from 'src/components/shared';
import { useEarlyBirdRegistrationSettings } from 'src/hooks/earlyBirdRegistration';
import { EARLY_BIRD_REGISTRATION_OPTION_CREATE_SCHEMA } from 'src/constants/validationSchemas/earlyBirdRegistrationOption';
import {
  EarlyBirdRegistrationSettingsFormValues,
  EarlyBirdRegistrationsMode,
} from 'src/types/earlyBirdRegistration';

import {
  APPLY_TO_SELECT_OPTIONS,
  FORM_SPACING,
  REGISTRATIONS_MODE_SELECT_OPTIONS,
} from '../constants';
import { EarlyBirdRegistrationOptionsFields, EarlyBirdRegistrationURL } from '.';
import { dataToInitialValues, prepareFormValues } from '../utils';

const TOOLTIP_TEXT =
  'Note that You are not able to change Registration Options for Early Bird while you do not have any Registration Option itself';

const EarlyBirdRegistrationOptions: React.FC<{ earlyBirdRegistrationAvailable: boolean }> = ({
  earlyBirdRegistrationAvailable,
}) => {
  const notification = useNotification();

  const experienceId = useExperienceId();
  const campaignId = useCampaignId();

  const { data: experienceGeneralSettingsListQueryResult } = useExperienceGeneralSettingsListQuery({
    variables: {
      id: experienceId,
    },
    skip: !experienceId,
  });

  const { data: campaign } = useCampaign(campaignId);

  const { earlyBirdRegistrationSettings, updateEarlyBirdRegistrationSettings, loading } =
    useEarlyBirdRegistrationSettings(experienceId);

  const experience = experienceGeneralSettingsListQueryResult?.experience;

  const initialValues = React.useMemo(() => {
    return {
      ...dataToInitialValues(earlyBirdRegistrationSettings),
      earlyBirdUrlCode: earlyBirdRegistrationSettings?.earlyBirdUrlCode,
    };
  }, [earlyBirdRegistrationSettings]);

  const hasEarlyBirdRegistrationOptions = Boolean(
    earlyBirdRegistrationSettings?.earlyBirdRegistrationOptions?.count,
  );

  const [code, setCode] = React.useState('');

  React.useEffect(() => {
    if (earlyBirdRegistrationSettings?.earlyBirdUrlCode) {
      setCode(earlyBirdRegistrationSettings?.earlyBirdUrlCode);
    }
  }, [earlyBirdRegistrationSettings]);

  const onSubmit = async (formValues: EarlyBirdRegistrationSettingsFormValues) => {
    const data = prepareFormValues(formValues, experienceId);
    try {
      await updateEarlyBirdRegistrationSettings(data);
      notification.success(
        t('entity_update_success', { objectName: 'Early Bird Registration Settings' }),
      );
    } catch (error: any) {
      notification.error(
        t('entity_update_error', {
          objectName: 'Early Bird Registration Settings',
          errorMessage: error.message,
        }),
      );
    }
  };

  const handleCancel = (reset: () => void) => () => {
    reset();
  };

  const handleChanges = ({ values }) => {
    if (values.earlyBirdUrlCode) {
      setCode(values.earlyBirdUrlCode);
    } else {
      setCode('');
    }
  };

  return (
    <Form<EarlyBirdRegistrationSettingsFormValues>
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={formValues =>
        validateWithSchema(EARLY_BIRD_REGISTRATION_OPTION_CREATE_SCHEMA, formValues, {
          context: { experience },
        })
      }
      subscription={{
        submitting: true,
        initialValues: true,
        values: true,
        pristine: true,
        error: true,
      }}
      mutators={{
        ...arrayMutators,
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => {
        const shouldDisableSaveButton =
          submitting || pristine || loading || !earlyBirdRegistrationAvailable;
        const earlyBirdRegistrationsMode =
          values.earlyBirdRegistrationsMode as EarlyBirdRegistrationsMode;

        return (
          <form onSubmit={handleSubmit}>
            <FormSpy subscription={{ values: true }} onChange={handleChanges} />

            <Typography mb={1} variant="h4">
              Early Bird Registration Settings
            </Typography>
            <Grid container item direction="column" spacing={FORM_SPACING}>
              <Grid item>
                <TooltipTextWrapper text={TOOLTIP_TEXT}>
                  <Field
                    name="earlyBirdRegistrationsApplyTo"
                    component={RadioGroupField}
                    options={APPLY_TO_SELECT_OPTIONS}
                    disabled={!hasEarlyBirdRegistrationOptions}
                    row
                  />
                </TooltipTextWrapper>
              </Grid>
              {hasEarlyBirdRegistrationOptions && (
                <Grid item>
                  <FieldArray
                    name="earlyBirdRegistrationOptions"
                    allFieldsName="allEarlyBirdRegistrationOptions"
                    component={EarlyBirdRegistrationOptionsFields}
                  />
                </Grid>
              )}

              {/* <Grid item xs={12}>
                <Field
                  name="earlyBirdRegistrationsMode"
                  row
                  fullWidth
                  component={RadioGroupField}
                  options={REGISTRATIONS_MODE_SELECT_OPTIONS}
                  disabled={!earlyBirdRegistrationAvailable}
                />
              </Grid> */}

              {earlyBirdRegistrationsMode === EarlyBirdRegistrationsMode.everyoneWithTheLink && (
                <Grid item xs={3}>
                  <Field
                    label="Early Bird Url Code"
                    name="earlyBirdUrlCode"
                    fullWidth
                    component={TextField}
                  />
                </Grid>
              )}

              {/* {earlyBirdRegistrationsMode === EarlyBirdRegistrationsMode.everyoneWithTheLink && (
                <Grid item xs={12}>
                  <EarlyBirdRegistrationURL
                    campaignWebLink={campaign?.dnsRecord?.slug}
                    code={code}
                  />
                </Grid>
              )} */}
              <Grid container item justifyContent="center">
                <Button
                  onClick={handleCancel(form.reset)}
                  disabled={pristine || submitting}
                  variant="text"
                  color="info"
                >
                  Cancel
                </Button>
                <Button
                  loading={submitting}
                  disabled={shouldDisableSaveButton}
                  onClick={form.submit}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export default EarlyBirdRegistrationOptions;
