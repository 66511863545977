import React from 'react';

import { ShowInvoices } from '@vizsla/components';
import { useUsersStripePlansQuery } from '@vizsla/graphql';
import { useCurrentOrganization } from '@vizsla/hooks';

export const OrganizationPaymentHistory: React.FC = () => {
  const { organizationId } = useCurrentOrganization();
  const { data: userStripePlan } = useUsersStripePlansQuery({
    variables: {
      filter: { organizationId: { equals: organizationId } },
    },
  });
  return (
    <ShowInvoices
      customerId={userStripePlan?.vizslaStripePlansList?.items?.at(0)?.customerId || ''} // TODO Replace for organization ID
    />
  );
};
