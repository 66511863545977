import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useFormState } from 'react-final-form';
import { isNil } from 'lodash';

import { formatMoney } from '@vizsla/utils';
import { Field, NumberField } from '@vizsla/components';

import { MONEY_FORMAT } from 'src/constants/formats';
import {
  ExperienceStoreAssetFormFields,
  ExperienceStoreAssetAddOrUpdateFormValues,
} from 'src/types/storeAssets';

const FormPriceSection: React.FC = () => {
  const { values } = useFormState<ExperienceStoreAssetAddOrUpdateFormValues>();

  const selectedStoreAsset = values[ExperienceStoreAssetFormFields.STORE_ASSET];

  if (isNil(selectedStoreAsset)) {
    return null;
  }

  return (
    <React.Fragment>
      <Grid item xs={12} mt={2}>
        <Field
          fullWidth
          name={ExperienceStoreAssetFormFields.PRICE}
          label="Price ($)"
          required
          component={NumberField}
          numberFormat={MONEY_FORMAT}
        />
      </Grid>

      <Grid item xs={12} mt={0.5} textAlign="right">
        <Typography variant="body2">
          Recommended price: {formatMoney(selectedStoreAsset.price, 2)}
        </Typography>
      </Grid>
    </React.Fragment>
  );
};

export { FormPriceSection };
