import React from 'react';
import { FileCopyOutlined as FileCopyIcon } from '@mui/icons-material';

import { InformationCard } from '@vizsla/components';

interface RecurringCardProps {
  recurringValue: number | null | undefined;
  loading: boolean;
}

export const RecurringCard: React.FC<RecurringCardProps> = ({ recurringValue, loading }) => (
  <InformationCard
    icon={<FileCopyIcon color="secondary" fontSize="large" />}
    title="Recurring"
    value={recurringValue}
    loading={loading}
  />
);
