import React, { useContext } from 'react';
import { styled } from '@mui/material';

// import { ExperienceLanding } from '@vizsla/components';
import { useExperienceLanding, useLandingState } from '@vizsla/providers';
import { File } from '@vizsla/graphql';
import { HeaderLogoAlignType } from '@vizsla/constants';

import {
  EXP_HEADER_IMAGE_1,
  EXP_HEADER_IMAGE_2,
  EXP_LOGO_IMAGE,
} from 'src/assets/img/experienceLandingImages';
import { ThankYouPageContext } from 'src/providers/campaign/ThankYouContext';
import { LandingStateContext } from 'src/providers/campaign/ThankYouLandingContext';
import { LandingHeader } from 'src/components/ThankYouTemplateEditor/LandingHeader';
import { ExperienceLanding } from 'src/components/ThankYouTemplateEditor/SectionTemplate/SectionTemplate';

const StyledRootDiv = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateAreas: '"header" "content"',
  gridTemplateRows: '64px auto',
  zIndex: 1,
}));

export const ThakYouPageWrapper: React.FC = () => {
  const { experience, loading } = useContext(ThankYouPageContext);
  const { state: thankYouLandingState } = useContext(LandingStateContext);
  const EXPERIENCE_HEADER_IMAGES: Array<File> = [EXP_HEADER_IMAGE_1, EXP_HEADER_IMAGE_2].map(
    (headerImage: string) => ({
      fileId: headerImage,
      downloadUrl: headerImage,
    }),
  );
  const headerLogoAlign = thankYouLandingState?.theme?.headerLogoAlign || HeaderLogoAlignType.left;
  const headerBackground = thankYouLandingState?.theme?.headerBackground || '';

  return (
    <StyledRootDiv>
      <LandingHeader
        logoImageUrl={EXP_LOGO_IMAGE}
        headerLogoAlign={headerLogoAlign}
        headerBackground={headerBackground}
        editorMode
      />

      <ExperienceLanding
        experience={experience}
        loading={loading}
        experienceLandingState={thankYouLandingState}
        headerImages={EXPERIENCE_HEADER_IMAGES}
        editorMode
      />
    </StyledRootDiv>
  );
};
