import React from 'react';
import { Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { SideMenu } from './SideMenu';

const useStyles = makeStyles((theme: Theme) => ({
  pageContent: {
    flexGrow: 1,
    flexBasis: 0,
    paddingRight: theme.spacing(1),
  },
  sidebar: {
    padding: theme.spacing(0, 3, 0, 0),
    '&>*': {
      margin: theme.spacing(1, 0),
    },
    '&>*:first-child': {
      margin: theme.spacing(0, 0, 1, 0),
    },
  },
}));

export const OrganizationSettingsLayout: React.FC = ({ children: pageContent }) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" spacing={4}>
      <Grid item minWidth={280} maxWidth={300}>
        <Box
          className={classes.sidebar}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <SideMenu />
        </Box>
      </Grid>
      <Grid item className={classes.pageContent}>
        {pageContent}
      </Grid>
    </Grid>
  );
};
