import React from 'react';
import { Grid } from '@mui/material';
import { useForm, useFormState } from 'react-final-form';
import { isNil, get } from 'lodash';

import { User, UsersListQuery, VizslaOrganizationStaff } from '@vizsla/graphql';
import { getUserFullName } from '@vizsla/utils';
import { BASE_NUMBER_FORMAT } from '@vizsla/constants';
import { useCurrentOrganization } from '@vizsla/hooks';
import {
  Modal,
  Field,
  CheckboxField,
  NumberField,
  DataBaseMultiSelectField,
  useDataBaseSelectField,
  UserChip,
} from '@vizsla/components';

import { VIZSLA_ORGANIZATION_STAFF_QUERY } from 'src/graphql';
import { StoreAssetFormFields, StoreAssetCreateOrUpdateFormValues } from 'src/types/storeAssets';

interface StoreAssetFormStockControlSectionProps {
  disabled?: boolean;
}

const StoreAssetFormStockControlSection: React.FC<StoreAssetFormStockControlSectionProps> = ({
  disabled = false,
}) => {
  const { organizationId } = useCurrentOrganization();

  const { batch, mutators } = useForm();
  const { values } = useFormState<StoreAssetCreateOrUpdateFormValues>();

  const { notifyWhenStockMinimumIsAchieved } = values;

  const multiSelectFieldProps = useDataBaseSelectField<UsersListQuery, User>(
    {
      query: VIZSLA_ORGANIZATION_STAFF_QUERY,
      getQueryItems: data => {
        const vizslaOgranizationsStaff = get(
          data,
          ['vizslaOrganization', 'vizslaOrganizationStaff', 'items'],
          [],
        );

        return vizslaOgranizationsStaff.map((staff: VizslaOrganizationStaff) => staff.user);
      },
      getCustomQueryVariables: () => ({
        id: organizationId,
      }),
    },
    {
      getOptionLabel: (user: User) => `${getUserFullName(user as User)} (${user?.email as string})`,
      renderTag: (user: User) => {
        return <UserChip key={user?.id as string} user={user} sx={{ m: '2px' }} />;
      },
    },
  );

  React.useEffect(() => {
    if (!isNil(notifyWhenStockMinimumIsAchieved) && !notifyWhenStockMinimumIsAchieved) {
      const stockFileds = [
        StoreAssetFormFields.STOCK_MINIMUM_BOUNDARY,
        // TO DO StoreAssetFormFields.STOCK_MINIMUM_RECIPIENTS,
      ];

      batch(() => {
        for (const fieldName of stockFileds) {
          mutators.resetField(fieldName);
        }
      });
    }
  }, [mutators, batch, notifyWhenStockMinimumIsAchieved]);

  const isNotificationGridHidden = !notifyWhenStockMinimumIsAchieved;

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Modal.ContentText>Stock Control</Modal.ContentText>
      </Grid>

      <Grid item xs={12} mt={-1.5}>
        <Field
          name={StoreAssetFormFields.CONTINUE_SALES_wHEN_OUT_OF_STOCK}
          label="Continue to offer when out of stock"
          component={CheckboxField}
          required
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12} mt={-2}>
        <Field
          name={StoreAssetFormFields.NOTIFY_WHEN_STOCK_MINIMUM_IS_ACHIEVED}
          label="Notify staff when stock is below specified level"
          component={CheckboxField}
          required
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} hidden={isNotificationGridHidden}>
        <Field
          fullWidth
          name={StoreAssetFormFields.STOCK_MINIMUM_BOUNDARY}
          label="Stock Minimum Alert"
          component={NumberField}
          numberFormat={BASE_NUMBER_FORMAT}
          required
          disabled={disabled}
        />
      </Grid>

      {/* TO DO <Grid item xs={12} hidden={isNotificationGridHidden}>
        <Field
          fullWidth
          name={StoreAssetFormFields.STOCK_MINIMUM_RECIPIENTS}
          label="Send to"
          component={DataBaseMultiSelectField}
          required
          disabled={disabled}
          {...multiSelectFieldProps}
        />
      </Grid> */}
    </React.Fragment>
  );
};

export { StoreAssetFormStockControlSection };
