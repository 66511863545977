import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Link, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  TeamFundraising,
  RegistrationAndTicketingRecentActivityQuery,
  RegistrationAndTicketingRecentActivityQueryVariables,
  DonationAllocation,
} from '@vizsla/graphql';
import { PaperBlock } from '@vizsla/components';
import { FontSize, PaletteColor } from '@vizsla/theme';

import {
  CampaignRTRecentTeamsTable,
  CampaignDonationsTable,
  CampaignAttendeesTab,
  CampaignFundraisersTab,
} from 'src/components/campaign';
import {
  CampaignDetailItem,
  PeopleTablesTab,
  RegistrationAndTicketingRecentActivityTab,
} from 'src/types/campaign';
import { REGISTRATION_AND_TICKETING_RECENT_ACTIVITY_QUERY } from 'src/graphql';

const ROWS_COUNT = 5;

const useStyles = makeStyles(() => ({
  showSummary: {
    color: PaletteColor.GrayText,
  },
  viewAllLink: {
    fontSize: FontSize.Default,
  },
}));

interface Props {
  campaign: CampaignDetailItem;
}

const PEOPLE_TABLES_MAP = {
  [RegistrationAndTicketingRecentActivityTab.Attendees]: PeopleTablesTab.Attendees,
  [RegistrationAndTicketingRecentActivityTab.Fundraisers]: PeopleTablesTab.Fundraisers,
  [RegistrationAndTicketingRecentActivityTab.Teams]: PeopleTablesTab.Teams,
  [RegistrationAndTicketingRecentActivityTab.Donations]: PeopleTablesTab.Donors,
};

export function RegistrationAndTicketingRecentActivity(props: Props) {
  const classes = useStyles();

  const [active, setActiveTab] = React.useState<RegistrationAndTicketingRecentActivityTab>(
    RegistrationAndTicketingRecentActivityTab.Attendees,
  );

  const handleChangeTab = (newTab: RegistrationAndTicketingRecentActivityTab) => {
    setActiveTab(newTab);
  };

  const { data, loading } = useQuery<
    RegistrationAndTicketingRecentActivityQuery,
    RegistrationAndTicketingRecentActivityQueryVariables
  >(REGISTRATION_AND_TICKETING_RECENT_ACTIVITY_QUERY, {
    skip: !props.campaign?.id,

    variables: {
      id: props.campaign?.id as string,
      first: ROWS_COUNT,
    },
  });

  const teams = React.useMemo(() => data?.campaign?.teams?.items ?? [], [data]);
  const donations = React.useMemo(() => data?.campaign?.allocatedDonations?.items ?? [], [data]);

  const tableData = React.useMemo(() => {
    const tableDataMap = {
      [RegistrationAndTicketingRecentActivityTab.Teams]: {
        dataSource: teams,

        rowActions: [
          {
            actionName: 'View/Edit',
            onAction: () => {},
          },
          {
            actionName: 'Send Text Message',
            onAction: () => {},
          },
          {
            actionName: 'Call a Team Member',
            onAction: () => {},
          },
          {
            actionName: 'Compose an Email',
            onAction: () => {},
          },
          {
            actionName: 'Add a Note',
            onAction: () => {},
          },
        ],
      },

      [RegistrationAndTicketingRecentActivityTab.Donations]: {
        dataSource: donations,

        rowActions: [
          {
            actionName: 'View/Edit',
            onAction: () => {},
          },
          {
            actionName: 'Send Text Message',
            onAction: () => {},
          },
          {
            actionName: 'Call a Team Member',
            onAction: () => {},
          },
          {
            actionName: 'Compose an Email',
            onAction: () => {},
          },
          {
            actionName: 'Add a Note',
            onAction: () => {},
          },
        ],
      },
    };

    return tableDataMap[active];
  }, [active, donations, teams]);

  const recentActivityTable = React.useMemo(() => {
    switch (active) {
      case RegistrationAndTicketingRecentActivityTab.Teams: {
        return (
          <CampaignRTRecentTeamsTable
            loading={loading}
            dataSource={tableData.dataSource as TeamFundraising[]}
            rowActions={tableData.rowActions}
          />
        );
      }

      case RegistrationAndTicketingRecentActivityTab.Donations: {
        return (
          <CampaignDonationsTable
            loading={loading}
            dataSource={tableData.dataSource as DonationAllocation[]}
            rowActions={tableData.rowActions}
          />
        );
      }

      default: {
        return null;
      }
    }
  }, [active, loading, tableData]);

  const viewAllURL = React.useMemo(() => {
    const campaignId = props.campaign?.id;
    const tab = PEOPLE_TABLES_MAP[active];

    return `/campaigns/${campaignId}/people?tab=${tab}`;
  }, [props.campaign, active]);

  return (
    <React.Fragment>
      <PaperBlock title="Recent Activity">
        <Tabs centered value={active} onChange={(event, tab) => handleChangeTab(tab)}>
          <Tab
            label={RegistrationAndTicketingRecentActivityTab.Attendees}
            value={RegistrationAndTicketingRecentActivityTab.Attendees}
          />

          <Tab
            label={RegistrationAndTicketingRecentActivityTab.Fundraisers}
            value={RegistrationAndTicketingRecentActivityTab.Fundraisers}
          />

          <Tab
            label={RegistrationAndTicketingRecentActivityTab.Teams}
            value={RegistrationAndTicketingRecentActivityTab.Teams}
          />

          <Tab
            label={RegistrationAndTicketingRecentActivityTab.Donations}
            value={RegistrationAndTicketingRecentActivityTab.Donations}
          />
        </Tabs>

        {recentActivityTable}

        {active === RegistrationAndTicketingRecentActivityTab.Attendees && (
          <CampaignAttendeesTab first={ROWS_COUNT} />
        )}

        {active === RegistrationAndTicketingRecentActivityTab.Fundraisers && (
          <CampaignFundraisersTab first={ROWS_COUNT} />
        )}

        {!loading && (
          <React.Fragment>
            <Box display="flex" justifyContent="center">
              <Link component={RouterLink} to={viewAllURL} className={classes.viewAllLink}>
                View All
              </Link>
            </Box>
          </React.Fragment>
        )}
      </PaperBlock>
    </React.Fragment>
  );
}
