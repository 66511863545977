import React from 'react';
import { Box, BoxProps, Grid, Paper, Switch, SwitchProps, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface StylesProps {
  enabled?: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>(theme => ({
  root: {
    transition: '200ms',
    background: ({ enabled }) => (enabled ? 'transparent' : theme.palette.grey[50]),
  },
  image: {
    margin: theme.spacing(0, 2, 0, 0),
    '&>*': {
      width: 40,
      height: 40,
    },
  },
}));

export interface SwitchCardProps extends Omit<BoxProps, 'title'> {
  image?: React.ReactNode;
  title?: string;
  renderContent?: (checked: boolean | unknown) => React.ReactNode;
  switchProps?: SwitchProps;
}

export const SwitchCard: React.FC<SwitchCardProps> = ({
  renderContent,
  switchProps,
  image,
  title,
  ...props
}) => {
  const classes = useStyles({ enabled: switchProps?.checked });

  return (
    <Paper variant="outlined" component={Box} padding={3} classes={classes} {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            {image && <Box className={classes.image}>{image}</Box>}
            {title && <Typography variant="subtitle1">{title}</Typography>}
            <Box ml="auto">
              <Switch {...switchProps} />
            </Box>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          {renderContent && renderContent(switchProps?.checked)}
        </Grid>
      </Grid>
    </Paper>
  );
};
