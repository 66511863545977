import React from 'react';
import { Grid, Box, Typography, styled } from '@mui/material';
import _ from 'lodash';

import { useModal, useNotification } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { useExperienceStoreAssets } from 'src/hooks/storeAssets';
import { MODALS } from 'src/constants/modals';
import { t } from 'src/utils/template';
import type { ExperienceStoreAssetActionUpdateOrDeleteModalArgs } from 'src/types/storeAssets';

const StyledModalContentBox = styled(Box)(({ theme }) => ({
  margin: 0,
  '& > *': {
    paddingBottom: theme.spacing(1),
  },
}));

const ExperienceStoreAssetDeleteModal: React.FC = () => {
  const { isOpen, args, closeModal } = useModal(MODALS.EXPERIENCE_STORE_ASSET_DELETE_MODAL);

  const notification = useNotification();

  const modalArgs: ExperienceStoreAssetActionUpdateOrDeleteModalArgs | undefined = args;

  const { deleteExperienceStoreAsset, deleting } = useExperienceStoreAssets();

  const experienceStoreAsset = modalArgs?.experienceStoreAsset;

  const onDeleteStoreAsset = React.useCallback(async () => {
    if (_.isNil(experienceStoreAsset)) {
      return;
    }

    try {
      await deleteExperienceStoreAsset({
        id: experienceStoreAsset.id,
      });

      notification.success(t('experience_store_item_delete_success'));

      closeModal();
    } catch (err) {
      notification.error(t('experience_store_item_delete_error'));
      console.error(err);
    }
  }, [closeModal, deleteExperienceStoreAsset, experienceStoreAsset, notification]);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth>
      <Modal.Title>Delete Store Item</Modal.Title>

      <StyledModalContentBox>
        <Modal.Content>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography textAlign="center">
                Are you sure you want to delete Store Item? Related Store Asset will not be deleted.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Modal.Actions>
                <Modal.Action
                  type="secondary"
                  content="Cancel"
                  disabled={deleting}
                  onAction={closeModal}
                />

                <Modal.Action
                  type="primary"
                  content="Delete"
                  loading={deleting}
                  onAction={onDeleteStoreAsset}
                />
              </Modal.Actions>
            </Grid>
          </Grid>
        </Modal.Content>
      </StyledModalContentBox>
    </Modal>
  );
};

export { ExperienceStoreAssetDeleteModal };
