import { get, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { PricingType } from '@vizsla/types';
import { isBetween } from './date';
export const ResolvePriceFromSettings = (setting, date) => {
    var _a, _b, _c;
    // validate that setting exists and pricing is enabled
    if (!setting || !setting.pricingEnabled)
        return 0;
    const pricingTiers = get(setting, ['pricingTiers', 'items'], []);
    const tiers = sortBy(pricingTiers, ['endDate']);
    // if pricing setting is fixed, return the fixed price
    if (setting.pricingType === PricingType.Fixed) {
        return (_b = (_a = tiers.at(0)) === null || _a === void 0 ? void 0 : _a.price) !== null && _b !== void 0 ? _b : 0;
    }
    if (setting.pricingType === PricingType.Scaled) {
        // if pricing setting is scaled, find the tier that matches the date
        const tier = pricingTiers.find(tier => {
            const range = [
                DateTime.fromISO(tier.startDate).startOf('day'),
                DateTime.fromISO(tier.endDate).endOf('day'),
            ];
            return isBetween(date, range);
        });
        return (_c = tier === null || tier === void 0 ? void 0 : tier.price) !== null && _c !== void 0 ? _c : 0;
    }
    // if pricing setting is not fixed or scaled, return 0
    return 0;
};
