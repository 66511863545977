import React from 'react';
import { Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';

const DEFAULT_IMAGE_SIZE = 180;

const useStyles = makeStyles({
  preview: {
    display: 'block',
    maxWidth: '100%',
    minHeight: DEFAULT_IMAGE_SIZE,
    maxHeight: DEFAULT_IMAGE_SIZE,
    borderRadius: 10,
    objectFit: 'contain',
  },
});

export function ExperienceImageLoader() {
  const styles = useStyles();

  return (
    <Skeleton
      variant="rectangular"
      width={DEFAULT_IMAGE_SIZE * 1.5}
      height={DEFAULT_IMAGE_SIZE}
      className={styles.preview}
    />
  );
}
