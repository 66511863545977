import React from 'react';
import { Box, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CampaignChangeStatusChip, CampaignStaffAvatarsGroup } from 'src/components/campaign';
import { useCampaignId } from 'src/hooks/campaign';
import { ExperienceCreateModal } from 'src/modals';
import { BorderRadius } from 'src/theme';
import { CampaignType, CampaignDetailItem } from 'src/types/campaign';
import { EMPTY_IMAGE } from 'src/assets';

import { RegistrationAndTicketingCampaignMenu } from './RegistrationAndTicketingCampaignMenu';
import { PersonalMissionCampaignMenu } from './PersonalMissionCampaignMenu';
import { ImpactGivingCampaignMenu } from './ImpactGivingCampaignMenu';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: theme.spacing(2),
  },
  campaignImage: {
    width: '100%',
    height: '130px',
    objectFit: 'cover',
    borderRadius: BorderRadius.M,
  },
  campaignName: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 3,
    alignItems: 'center',
  },
  pageContent: {
    flexGrow: 1,
    flexBasis: 0,
    paddingRight: theme.spacing(1),
  },
  sidebar: {
    padding: theme.spacing(0, 3, 0, 0),
    '&>*': {
      margin: theme.spacing(1, 0),
    },
    '&>*:first-child': {
      margin: theme.spacing(0, 0, 1, 0),
    },
  },
}));

interface CampaignLayoutProps {
  campaign: CampaignDetailItem;
  children: React.ReactNode;
}

export const CampaignLayout: React.FC<CampaignLayoutProps> = ({
  campaign,
  children: pageContent,
}) => {
  const classes = useStyles();

  const campaignId = useCampaignId();

  const renderMenuByCampaignType = () => {
    switch (campaign?.campaignType) {
      case CampaignType.registrationAndTicketing: {
        return <RegistrationAndTicketingCampaignMenu />;
      }
      case CampaignType.impactGiving: {
        return <ImpactGivingCampaignMenu />;
      }
      case CampaignType.personalMission: {
        return <PersonalMissionCampaignMenu />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Grid container>
      <Grid
        className={classes.header}
        container
        item
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Box className={classes.campaignName}>
            <Typography variant="h2" marginRight={2}>
              {campaign?.name}
            </Typography>
            <CampaignChangeStatusChip campaignId={campaignId} />
          </Box>
        </Grid>
        <Grid item marginRight={1} marginLeft="auto">
          <CampaignStaffAvatarsGroup />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={4}>
        <Grid item minWidth={280} maxWidth={300}>
          <Box
            className={classes.sidebar}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <img
              alt="campaign-logo"
              className={classes.campaignImage}
              src={campaign?.image?.downloadUrl ?? EMPTY_IMAGE}
            />
            {renderMenuByCampaignType()}
          </Box>
        </Grid>
        <Grid item className={classes.pageContent}>
          {pageContent}
        </Grid>
      </Grid>
      <ExperienceCreateModal />
    </Grid>
  );
};
