import React from 'react';
import {
  Box,
  Divider,
  ListItemButton,
  ListItemText,
  Switch,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ExperienceLandingActions, useLandingState } from '@vizsla/providers';

import { PaletteColor } from 'src/theme';

const useStyles = makeStyles<Theme>(theme => ({
  integration: {
    padding: theme.spacing(0.5),
    transition: '500ms',
  },
  whiteBackground: {
    background: PaletteColor.White,
  },
  greyBackground: {
    background: theme.palette.grey['50'],
  },
}));

interface ActivitySwitchProps {
  title: string;
}

export const SwitchLandingActivity: React.FC<ActivitySwitchProps> = ({ title }) => {
  const classes = useStyles();

  const { state, dispatch } = useLandingState();

  const isActivityVisible = state.theme.isActivityVisible ?? true;

  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.integration}
      >
        <Box display="flex" alignItems="center">
          <Box display="flex" flexDirection="column">
            <ListItemButton>
              <ListItemText
                primary={
                  <Typography fontSize={14} fontWeight={700}>
                    {title}
                  </Typography>
                }
              />
            </ListItemButton>
          </Box>
        </Box>
        <Switch
          value={isActivityVisible}
          defaultChecked
          onClick={() =>
            dispatch({
              type: ExperienceLandingActions.updateIsActivityVisible,
              payload: !isActivityVisible,
            })
          }
        />
      </Box>
      <Divider />
    </React.Fragment>
  );
};
