export const getPartnershipOrganizationSearchFilter = (
  searchText: string,
  organizationId: string,
) => {
  const splitByWordsFilter = searchText.split(' ').map((substr: string) =>
    ['name', 'email'].map((fieldName: string) => ({
      [fieldName]: { contains: substr },
    })),
  );

  const resultFilter = {
    OR: [
      { name: { contains: searchText } },
      { email: { contains: searchText } },
      ...splitByWordsFilter.flat(1),
    ],
    organization: {
      id: {
        equals: organizationId,
      },
    },
  };

  return resultFilter;
};
