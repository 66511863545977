import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  CircularProgress,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { LocalOfferOutlined } from '@mui/icons-material';
import clsx from 'clsx';

import { BorderRadius } from 'src/theme';

const useStyles = makeStyles<Theme, { loading: boolean }>(theme => ({
  root: {
    width: '100%',
    minWidth: 250,
    height: 335,
    borderRadius: BorderRadius.M,
  },
  box: {
    borderRadius: BorderRadius.M,
    borderColor: ({ loading }) =>
      loading ? theme.palette.grey['600'] : theme.palette.secondary.light,
    borderStyle: 'dashed',
    borderWidth: 2,
    height: '100%',
    width: '100%',
    background: ({ loading }) => (loading ? theme.palette.grey['50'] : 'none'),
  },
  text: {
    color: ({ loading }) => (loading ? theme.palette.grey['600'] : theme.palette.secondary.light),
  },
}));

interface AddDiscountButtonProps extends ButtonBaseProps {
  loading?: boolean;
}

export const AddDiscountButton: React.FC<AddDiscountButtonProps> = ({
  loading = false,
  ...props
}) => {
  const classes = useStyles({ loading });

  return (
    <ButtonBase
      focusRipple
      className={clsx(classes.root, classes.text)}
      disabled={loading}
      {...props}
    >
      <Box className={classes.box}>
        <Grid height="100%" container direction="column" justifyContent="center">
          <Grid item>
            {loading ? (
              <CircularProgress thickness={5} className={classes.text} size={30} />
            ) : (
              <LocalOfferOutlined className={classes.text} fontSize="large" />
            )}
          </Grid>
          <Grid item>
            <Typography variant="h6" className={classes.text}>
              Add Discount
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </ButtonBase>
  );
};
