export enum ExperienceType {
  registration = 'Registration',
  ticketing = 'Ticketing',
}

export enum ExperiencePrivacy {
  public = 'Public',
  whiteList = 'White-List',
  fundraiserQualifier = 'Fundraiser Qualifier',
}

export enum TransactionFeesType {
  organization = 'Organization Pay Transaction Fee',
  supporter = 'Supporter Pays Transaction Fee',
  organizationSupporter = 'Organization & Supporter Split Transaction Fee',
}

export enum TeamCapabilities {
  CanJoinOrCreate = 'canJoinOrCreate',
  RequireJoinOrCreate = 'requireJoinOrCreate',
}

export enum ExperienceImagesModalType {
  Create = 'create',
  Edit = 'edit',
}
