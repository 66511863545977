import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { omit } from 'lodash';
import { ObjectSchema } from 'yup';

import { Button, Form } from '@vizsla/components';
import { validateWithSchema } from '@vizsla/utils';
import { useModal } from '@vizsla/hooks';
import { MODALS } from '@vizsla/constants';

import { ButtonsWrapper, FormWrapper } from '../styles';
import { CommitmentTypesE, PartnershipCommitmentDBRowT, PartnershipCommitmentT } from '../../types';
import {
  cashValidationSchema,
  inKindValidationSchema,
  matchingValidationSchema,
} from '../validation';
import { InKindCommitment } from './CommitmentTypes/InKind';
import { useCommitmentsCreate } from './useCommitmentsCreate';
import { MatchingCommitment } from './CommitmentTypes/Matching';
import { TileRadioButton } from '../../../../shared';
import { CashCommitment } from './CommitmentTypes/Cash';

export const CommitmentsSwitch: React.FC = () => {
  const { closeModal: close, args } = useModal(MODALS.PARTNERSHIP_COMMITMENT_CREATE);
  const { commitment }: { commitment?: PartnershipCommitmentDBRowT; partnershipId?: string } =
    args ?? {};
  const initialValues: PartnershipCommitmentT | null =
    { ...(commitment ? { ...omit(commitment, 'id') } : {}), type: CommitmentTypesE.cash } ?? null;
  const [commitmentType, setCommitmentType] = React.useState<CommitmentTypesE>(
    CommitmentTypesE.cash,
  );
  const { onSubmit, loading } = useCommitmentsCreate();
  const [formLoading, setFormLoading] = React.useState(false);
  const [CommitmentComponent, setCommitmentComponent] = React.useState<React.FC>(
    () => CashCommitment,
  );
  const [validationSchema, setValidationSchema] =
    React.useState<ObjectSchema<any>>(cashValidationSchema);

  React.useEffect(() => {
    setFormLoading(true);
    switch (commitmentType) {
      case CommitmentTypesE.cash:
        setCommitmentComponent(() => CashCommitment);
        setValidationSchema(cashValidationSchema);
        break;
      case CommitmentTypesE.matching:
        setCommitmentComponent(() => MatchingCommitment);
        setValidationSchema(matchingValidationSchema);
        break;
      case CommitmentTypesE.inKind:
        setCommitmentComponent(() => InKindCommitment);
        setValidationSchema(inKindValidationSchema);
        break;
      default:
        setValidationSchema(cashValidationSchema);
        setCommitmentComponent(CashCommitment);
    }
    setFormLoading(false);
  }, [commitmentType]);

  return formLoading ? (
    <CircularProgress />
  ) : (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={values => validateWithSchema(validationSchema, values)}
      render={({ form, values, handleSubmit }) => (
        <FormWrapper>
          <ButtonsWrapper>
            <TileRadioButton
              text="Cash"
              width="100%"
              height="88px"
              checked={commitmentType === CommitmentTypesE.cash}
              onClick={() => {
                form.reset();
                form.change('type', CommitmentTypesE.cash);
                setCommitmentType(CommitmentTypesE.cash);
              }}
            />
            <TileRadioButton
              text="In-Kind"
              width="100%"
              height="88px"
              checked={commitmentType === CommitmentTypesE.inKind}
              onClick={() => {
                form.reset();
                form.change('type', CommitmentTypesE.inKind);
                setCommitmentType(CommitmentTypesE.inKind);
              }}
            />
            <TileRadioButton
              checked={commitmentType === CommitmentTypesE.matching}
              text="Matching"
              width="100%"
              height="88px"
              onClick={() => {
                form.change('type', CommitmentTypesE.matching);
                setCommitmentType(CommitmentTypesE.matching);
              }}
            />
          </ButtonsWrapper>
          <CommitmentComponent />
          <ButtonsWrapper>
            <Button
              color="info"
              type="button"
              onClick={() => {
                close();
              }}
              loading={loading}
            >
              Cancel
            </Button>
            <Button type="submit" onClick={handleSubmit} loading={loading}>
              Add
            </Button>
          </ButtonsWrapper>
        </FormWrapper>
      )}
    />
  );
};
