import { gql } from '@apollo/client';

export const USER_STATUS_UPDATE = gql`
  mutation User_status_update($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      status
    }
  }
`;

export const USER_ACTIVITY_LOG = gql`
  query UserActivityLog($userId: String!, $skip: Int, $first: Int) {
    activityLogsList(filter: { userId: { equals: $userId } }, first: $first, skip: $skip) {
      items {
        id
        type
        description
        createdAt
      }
    }
  }
`;

export const USER_MESSAGE_HISTORY = gql`
  query GetHistoryMessages($userId: ID!, $skip: Int, $first: Int) {
    inboxMessagesList(
      filter: {
        inbox: { type: { in: ["Notification"] }, recipients: { some: { id: { equals: $userId } } } }
        createdBy: { id: { not_equals: $userId } }
      }
      first: $first
      skip: $skip
    ) {
      items {
        id
        body
        createdAt
        createdBy {
          fullName
          avatar {
            downloadUrl
          }
        }
      }
    }
  }
`;
