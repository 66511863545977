import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PaletteColor, Shade } from '@vizsla/theme';
import { HeaderLogoAlignType } from '@vizsla/constants';
import { Theme, useLandingState } from '@vizsla/providers';

interface StylesParams {
  theme: Theme;
  headerLogoAlign: HeaderLogoAlignType;
}

interface LandingHeaderLogoProps {
  url?: string;
  align: HeaderLogoAlignType;
  editorMode?: boolean;
}

const useStyles = makeStyles<Theme, StylesParams>({
  logo: {
    width: 100,
    height: 48,
    marginLeft: props => (props.headerLogoAlign === HeaderLogoAlignType.left ? 0 : 'auto'),
    marginRight: '-10px',
  },

  preview: {
    width: 180,
    height: 48,
    marginLeft: props => (props.headerLogoAlign === HeaderLogoAlignType.left ? 0 : 'auto'),
    marginRight: '-10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: props => props.theme.accentColor,
    borderRadius: 5,
  },

  previewText: {
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
    userSelect: 'none',
    cursor: 'default',
    color: 'white',
  },
});

export function LandingHeaderLogo(props: LandingHeaderLogoProps) {
  const {
    state: { theme },
  } = useLandingState();

  const styles = useStyles({ theme, headerLogoAlign: props.align });

  if (props.editorMode) {
    return (
      <Tooltip
        placement="bottom-end"
        title="You can change the logo by experience in the details page"
      >
        <div className={styles.preview}>
          <Typography variant="body1" className={styles.previewText}>
            (EXPERIENCE HEADER IMAGE)
          </Typography>
        </div>
      </Tooltip>
    );
  }

  return <img className={styles.logo} src={props.url} />;
}
