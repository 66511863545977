import React, { useContext } from 'react';
import { Grid, List, ListItem, SxProps, Typography } from '@mui/material';

import { FontSize } from '@vizsla/theme';
import { EditorTextInputFieldSizeType } from '@vizsla/constants';

import { VisibilityButton } from 'src/components/shared';
import { ExperienceLandingActions } from 'src/providers/campaign/ThankYouLanding.types';
import { LandingStateContext } from 'src/providers/campaign/ThankYouLandingContext';

import { EditorTextInputField } from '../EditorTextInputField';

export const ThankYouMessage = () => {
  const { state, dispatch } = useContext(LandingStateContext);
  const isThankYouMessageVisible = state.theme.isThankYouMessageVisible ?? true;
  const { thankYouMessage } = state.theme;

  const thankYouMessageSxProp: SxProps = {
    width: '336px',
    fontSize: FontSize.Default,
  };

  const handleDescriptionInputFieldChange = (thankYouMessage: string) =>
    dispatch({
      type: ExperienceLandingActions.updateThankYouMessage,
      payload: thankYouMessage,
    });

  return (
    <List component="div">
      <ListItem disablePadding sx={theme => ({ paddingBottom: theme.spacing(3) })}>
        <Grid container columnSpacing={5}>
          <Grid item>
            <EditorTextInputField
              onChange={handleDescriptionInputFieldChange}
              fieldSize={EditorTextInputFieldSizeType.small}
              fieldValue={thankYouMessage}
              fieldLabel="Description"
              inputProps={{
                sx: thankYouMessageSxProp,
              }}
              rows={3}
              maxRows={4}
              multiline
            />
          </Grid>
          <Grid item xs={1}>
            <VisibilityButton
              isVisible={isThankYouMessageVisible}
              onClick={() =>
                dispatch({
                  type: ExperienceLandingActions.updateisThankYouMessageVisible,
                  payload: !isThankYouMessageVisible,
                })
              }
            />
          </Grid>
          {/* <Grid item xs={10} display="flex" justifyContent="left" alignItems="center">
            <Typography fontSize="14px">Description</Typography>
          </Grid> */}
        </Grid>
      </ListItem>
    </List>
  );
};
