import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { IconButton } from '@mui/material';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 3rem;
  width: 664px;
`;

export const Button = styled(LoadingButton)``;
export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const CloseButtonIcon = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 8,
  top: 8,
  color: theme.palette.grey[500],
}));

export const SwitchButton = styled(LoadingButton)(props => ({
  width: '100%',
  padding: '1rem 0',
}));
