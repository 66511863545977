import type { RouteProps } from '@vizsla/types';

import * as appRoutes from 'src/constants/routes';

import {
  AdminSettingsPage,
  AdminOrganizationProfilePage,
  adminOrganizationProfilePageRoutes,
  AdminOrganizationsPage,
} from './admin';
import { HomePage } from './home/HomePage/HomePage';
import { InboxPage } from './inbox/InboxPage/InboxPage';
import { CampaignsPage, CampaignPageWrapper, campaignPageRoutes } from './campaigns';
import { ExperiencesPage } from './experiences/ExperiencesPage/ExperiencesPage';
import { ExperiencesCreatePage } from './experiences/ExperiencesCreatePage/ExperiencesCreatePage';
import { OrganizationSettingsPageWrapper, organizationSettingsPageRoutes } from './settings';
import ExperienceTemplate from './campaignPages/ExperienceTemplate/ExperienceTemplate';
import { ThankYouTemplate } from './campaignPages/ThankYouTemplate/ThankYouTemplate';
import { IndividualFundraiserTemplate } from './campaignPages/IndividualFundraiser/IndividualFundraiserTemplate';
import { TeamFundraiserTemplate } from './campaignPages/TeamFundraiser/TeamFundraiserTemplate';
import {
  StoreAssetsDashboard,
  StoreAssetManagementWrapper,
  storeAssetManagementRoutes,
} from './storeAssets';
import { PeopleCRMPageWrapper } from './crm';
import { SelectPorcessRegister } from './selectProcessRegister/SelectProcessReister';
import { ProfilePage } from './profile/profile';
import { TransactionsPage, TransactionsRegistration } from './transactions';
import { TransactionsDonationsPage } from './transactions/TransactionsDonations';
import { TransactionsAssets } from './transactions/TransactionsAssets';
import { PartnershipDashboard } from './partnerships';
import { PartnershipDetailsPage } from './partnerships/DetailsPage';

export const STATIC_ROUTES: Record<string, Array<RouteProps>> = {
  AdminRoutes: [
    {
      path: appRoutes.adminRoot,
      element: null,
    },
    {
      path: appRoutes.adminOrganizations,
      element: AdminOrganizationsPage,
    },
    {
      path: appRoutes.adminOrganization,
      element: null,
    },
    {
      path: appRoutes.adminOrganizationProfile,
      element: AdminOrganizationProfilePage,
      childRoutes: adminOrganizationProfilePageRoutes,
    },
    {
      path: appRoutes.adminSettings,
      element: AdminSettingsPage,
    },
    {
      path: appRoutes.myProfile,
      element: ProfilePage,
    },
  ],
  OrganizationRoutes: [
    {
      path: appRoutes.home,
      element: HomePage,
    },
    {
      path: appRoutes.inbox,
      element: InboxPage,
    },
    {
      path: appRoutes.campaigns,
      element: CampaignsPage,
    },
    {
      path: appRoutes.transactions,
      element: TransactionsPage,
    },
    {
      path: appRoutes.partnerships,
      element: PartnershipDashboard,
    },
    {
      path: appRoutes.partnershipDetails,
      element: PartnershipDetailsPage,
    },
    {
      path: appRoutes.transactionsDonations,
      element: TransactionsDonationsPage,
    },
    {
      path: appRoutes.transactionsAssets,
      element: TransactionsAssets,
    },
    {
      path: appRoutes.transactionsRegistration,
      element: TransactionsRegistration,
    },
    {
      path: appRoutes.campaign,
      element: CampaignPageWrapper,
      childRoutes: campaignPageRoutes,
    },
    {
      path: appRoutes.experiences,
      element: ExperiencesPage,
    },
    {
      path: appRoutes.experiencesCreate,
      element: ExperiencesCreatePage,
    },
    {
      path: appRoutes.organizationSettings,
      element: OrganizationSettingsPageWrapper,
      childRoutes: organizationSettingsPageRoutes,
    },
    {
      path: appRoutes.editorExperienceTemplate,
      element: ExperienceTemplate,
    },
    {
      path: appRoutes.editorIndividualFundraiserTemplate,
      element: IndividualFundraiserTemplate,
    },
    {
      path: appRoutes.editorTeamFundraiserTemplate,
      element: TeamFundraiserTemplate,
    },
    {
      path: appRoutes.editorThankYouTemplate,
      element: ThankYouTemplate,
    },
    {
      path: appRoutes.crm,
      element: PeopleCRMPageWrapper,
    },
    {
      path: appRoutes.checkOrRegister,
      element: SelectPorcessRegister,
    },
    {
      path: appRoutes.storeAssets,
      element: StoreAssetsDashboard,
    },
    {
      path: appRoutes.storeAssetsAssetPage,
      element: StoreAssetManagementWrapper,
      childRoutes: storeAssetManagementRoutes,
    },
    {
      path: appRoutes.myProfile,
      element: ProfilePage,
    },
  ],
};
