import React from 'react';

import { TableData, UserAvatarFullNameInline } from '@vizsla/components';
import type { TableColumn } from '@vizsla/components';
import { Attendee, User } from '@vizsla/graphql';
import {
  usePagination,
  usePaginationQueryVariables,
  useModal,
  useSearchQueryVariables,
} from '@vizsla/hooks';
import { replaceIfNil } from '@vizsla/utils';

import { CampaignFundraiserGoalProgress } from 'src/components/campaign';
import { useOthersList } from 'src/hooks/peopleCRM';
import { MODALS } from 'src/constants/modals';

// TODO: fix when the actual requirements come
const OTHERS_TABLE_COLUMNS: Array<TableColumn> = [
  {
    title: 'NAME',
    key: 'name',
    render: (user?: User) => {
      if (!user) {
        return 'N/A';
      }

      return <UserAvatarFullNameInline user={user} />;
    },
  },
  {
    title: 'EMAIL',
    key: 'email',
    render: (user?: any) => {
      if (!user) {
        return 'N/A';
      }
      return user?.email;
    },
  },
  {
    title: 'PHONE',
    key: 'phone1',
    render: (user?: any) => {
      if (!user) {
        return 'N/A';
      }
      const string = `+${user?.phone1?.code}-${user?.phone1?.number}`;
      return string;
    },
  },
];

const OthersTable: React.FC = () => {
  const pagination = usePagination();
  const paginationQueryVariables = usePaginationQueryVariables();
  const searchQueryVariables = useSearchQueryVariables(true);

  const { others, loading } = useOthersList({
    paginationQueryVariables: { ...paginationQueryVariables },
    searchQueryVariables: { ...searchQueryVariables },
  });

  React.useEffect(() => {
    pagination.onCountChange(others?.count || 0);
  }, [pagination, others]);

  const { openModal } = useModal();

  return (
    <TableData
      dataSource={others?.items || []}
      columns={OTHERS_TABLE_COLUMNS}
      emptyMessage="There are no others in the organization"
      loading={loading}
      pagination={pagination}
      onRowClick={dataRow => {
        openModal(MODALS.PEOPLE_CRM_CONTACT_INFO_MODAL, { other: dataRow });
      }}
    />
  );
};

export { OthersTable };
