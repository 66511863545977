import React from 'react';
import { Form, Field } from 'react-final-form';
import { useMutation } from '@apollo/client';
import { Grid, LinearProgress, Typography, styled } from '@mui/material';

import { validateWithSchema } from '@vizsla/utils';
import { Button, TextField, NumberField } from '@vizsla/components';
import { useOrganizationById, useOrganizationId, useNotification } from '@vizsla/hooks';
import { VizslaOrganizationUpdateInput } from '@vizsla/graphql';

import { VizslaOrganizationUpdateSchema } from 'src/constants/validationSchemas/organization';
import { VIZSLA_ORGANIZATION_UPDATE_MUTATION } from 'src/graphql';
import { EMPTY_IMAGE } from 'src/assets';
import { ImageInputField } from 'src/components/forms';

const StyledImage = styled('img')(({ theme }) => ({
  display: 'block',
  maxWidth: '100%',
  maxHeight: 500,
  objectFit: 'contain',
  margin: '0 auto',
}));

const StyledImageGrid = styled(Grid)(() => ({
  '& > *': {
    '& > *': {
      margin: '0 auto',
    },
  },
}));

const OrganizationInfo: React.FC = () => {
  const notification = useNotification();
  const organizationId = useOrganizationId();
  const { organization, organizationIsLoading, refetch } = useOrganizationById(organizationId);

  const [vizslaOrganizationUpdate, { loading: loadingOrganizationInfo }] =
    useMutation<VizslaOrganizationUpdateInput>(VIZSLA_ORGANIZATION_UPDATE_MUTATION, {
      refetchQueries: ['OrganizationsList', 'VizslaOrganizationQuery'],
      awaitRefetchQueries: true,
    });

  React.useEffect(() => {
    refetch();
  }, []);

  const onSubmit = async (data: any) => {
    const logo = {
      create: {
        fileId: data?.logo?.fileId,
        filename: data?.logo?.filename,
        meta: data?.logo?.meta,
      },
    };

    try {
      await vizslaOrganizationUpdate({
        variables: {
          data: {
            id: organizationId,
            name: data?.name,
            ein: String(data?.ein),
            logo,
            contactAddress: {
              country: data?.contactAddress?.country,
              city: data?.contactAddress?.city,
              state: data?.contactAddress?.state,
              zip: data?.contactAddress?.zip,
              street1: data?.contactAddress?.street1,
              street2: data?.contactAddress?.street2,
            },
            contactPhone: {
              code: data?.contactPhone?.code || '+1',
              number: String(data?.contactPhone?.number),
            },
          },
        },
      });
      notification.success('Organization update success');
      refetch();
    } catch (e) {
      notification.error('Error updating organization');
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (organization?.logo?.downloadUrl) {
      setImage(organization?.logo?.downloadUrl);
    }
  }, [organization]);

  const [image, setImage] = React.useState(
    organization?.logo?.downloadUrl ? organization?.logo?.downloadUrl : EMPTY_IMAGE,
  );

  const INITIAL_VALUES = {
    ...organization,
  };

  if (organizationIsLoading) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h2">Organization Profile</Typography>
      </Grid>
      <Grid item xs={12}>
        <Form
          onSubmit={onSubmit}
          initialValues={INITIAL_VALUES}
          validate={values => validateWithSchema(VizslaOrganizationUpdateSchema, values)}
        >
          {({ handleSubmit, submitting, pristine, hasValidationErrors, values }) => {
            const isSubmitDisabled =
              organizationIsLoading || submitting || pristine || hasValidationErrors;

            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Grid container spacing={2}>
                      <StyledImageGrid item xs={12}>
                        <Field
                          fullWidth
                          name="logo"
                          label="Add Image"
                          width={250}
                          height={250}
                          component={ImageInputField}
                          disabled={submitting}
                          imageDim={[200, 200]}
                          value={{ downloadUrl: image }}
                        />
                      </StyledImageGrid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">General</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="name"
                          label="Organization Name"
                          requierd
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="ein"
                          label="EIN Number"
                          requierd
                          formatMask="##-#######"
                          component={NumberField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">Contact Information</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="contactEmail"
                          disabled
                          requierd
                          label="Email"
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="contactPhone.number"
                          label="Phone Number *"
                          formatMask="+1 (###) ###-####"
                          component={NumberField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">Address</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          name="contactAddress.street1"
                          label="Address"
                          requierd
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="contactAddress.city"
                          label="City"
                          requierd
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="contactAddress.state"
                          label="State/Province"
                          requierd
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="contactAddress.zip"
                          label="Zip Code"
                          required
                          isNumericString
                          formatMask="#####"
                          component={NumberField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="contactAddress.country"
                          label="Country"
                          requierd
                          disabled
                          component={TextField}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 'auto',
                      marginTop: '20px',
                    }}
                  >
                    <Button
                      onClick={handleSubmit}
                      loading={loadingOrganizationInfo}
                      disabled={isSubmitDisabled}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </Grid>
    </Grid>
  );
};

export { OrganizationInfo };
