import React from 'react';
import _ from 'lodash';
import { Box, Skeleton, Theme, Typography } from '@mui/material';
import { LocalActivityOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { useModal } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';
import { TicketingOption } from '@vizsla/graphql';

import { useTicketingOptionById } from 'src/hooks/ticketingOption';
import {
  TicketingOptionEditForm,
  TicketingOptionEditFormSkeleton,
} from 'src/components/ticketingOption';
import { MODALS } from 'src/constants/modals';

const useStyles = makeStyles((theme: Theme) => ({
  dialogWrapper: {
    '& .MuiDialog-container': {
      justifyContent: 'flex-end',
    },

    '& .MuiDialog-paper': {
      width: '50%',
      maxWidth: '100%',
      maxHeight: '100%',
      minHeight: '100%',
      margin: 0,
    },
  },

  titleIcon: {
    marginRight: theme.spacing(3),
  },
}));

const TicketingOptionEditModal: React.FC = () => {
  const classes = useStyles();
  const { isOpen, closeModal, args } = useModal(MODALS.TICKETING_OPTION_EDIT_MODAL);

  const { ticketingOption, loading, error } = useTicketingOptionById(args?.ticketingOptionId);

  const isTicketingOptionNotFound = _.isNil(ticketingOption);
  const isTicketingOptionReady = !loading && !error && !isTicketingOptionNotFound;

  const renderTitle = () => {
    if (loading) {
      return <Skeleton variant="text" height={64} width="40%" />;
    }

    if (isTicketingOptionNotFound) {
      return null;
    }

    return (
      <Typography variant="h2" align="left">
        <Box display="flex" alignItems="flex-end">
          <LocalActivityOutlined fontSize="large" className={classes.titleIcon} />
          {ticketingOption?.ticketName}
        </Box>
      </Typography>
    );
  };

  const renderForm = () => {
    if (loading) {
      return <TicketingOptionEditFormSkeleton />;
    }

    if (!isTicketingOptionReady) {
      return null;
    }

    return <TicketingOptionEditForm ticketingOption={ticketingOption as TicketingOption} />;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      customClasses={[classes.dialogWrapper]}
      slideDirection="left"
    >
      <Modal.Title>{renderTitle()}</Modal.Title>
      <Modal.Content>{renderForm()}</Modal.Content>
    </Modal>
  );
};

export default TicketingOptionEditModal;
