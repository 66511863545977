import React from 'react';

import { DateFormatPatterns, NO_APPLY_LABEL, NO_VALUE_LABEL } from '@vizsla/constants';
import { TableData, TableColumn, UserAvatarFullNameInline } from '@vizsla/components';
import { useModal, usePagination } from '@vizsla/hooks';
import { formatDate, formatMoney } from '@vizsla/utils';

import { MODALS } from 'src/constants/modals';

import { AttendeeCRMRow, useAttendeesCRM } from './useAttendeesCRM';

const ATTENDEES_TABLE_COLUMNS: TableColumn<AttendeeCRMRow>[] = [
  {
    title: 'NAME',
    key: 'name',

    render(root, attendee) {
      if (!attendee?.user) return NO_APPLY_LABEL;

      return <UserAvatarFullNameInline user={attendee.user} />;
    },
  },

  {
    title: 'CAMPAIGNS',
    key: 'campaigns',

    render(root, attendee) {
      const [campaigns] = attendee?.user?.campaigns?.groups ?? [];

      return campaigns?.count ?? 0;
    },
  },

  {
    title: 'TOTAL RAISED',
    key: 'totalRaised',

    render(root, attendee) {
      const [raised] = attendee?.fundraising?.raised?.groups ?? [];

      return formatMoney(raised?.total ?? 0, 2);
    },
  },

  {
    title: 'TEAM NAME',
    key: 'team',

    render(root, attendee) {
      return attendee.teamMembership?.team?.name ?? NO_APPLY_LABEL;
    },
  },

  {
    title: 'LAST ACTIVITY',
    key: 'lastActivity',

    render(root, attendee) {
      const [donation] = attendee?.user?.allocatedDonations?.items ?? [];

      if (!donation) return NO_VALUE_LABEL;
      return formatDate(donation?.createdAt, DateFormatPatterns.fullMonthDayYear);
    },
  },
];

export function AttendeesTable() {
  const pagination = usePagination();

  const { openModal } = useModal();

  const { data, loading } = useAttendeesCRM();

  const handleRowClick = (row: AttendeeCRMRow) => {
    openModal(MODALS.PEOPLE_CRM_CONTACT_INFO_MODAL, { userId: row?.user?.id });
  };

  return (
    <TableData
      dataSource={data}
      columns={ATTENDEES_TABLE_COLUMNS}
      emptyMessage="There are no attendees in the organization"
      loading={loading}
      pagination={pagination}
      onRowClick={handleRowClick}
    />
  );
}
