import React, { useContext } from 'react';
import { FormControlLabel, Grid, List, ListItem, Radio, RadioGroup } from '@mui/material';

import { PageBackgroundType } from '@vizsla/constants';

import { FontSize } from 'src/theme';
import { ExperienceLandingActions } from 'src/providers/campaign/ThankYouLanding.types';
import { LandingStateContext } from 'src/providers/campaign/ThankYouLandingContext';

import { ColorInputField, ColorLibraryField } from '../../shared';

export const PageBackgroundSection: React.FC = () => {
  const { state, dispatch } = useContext(LandingStateContext);

  const isCurrentBackgroundWhite = state.theme.pageBackgroundType === PageBackgroundType.white;

  return (
    <List component="div" disablePadding>
      <ListItem disablePadding sx={{ paddingBottom: '8px' }} divider={isCurrentBackgroundWhite}>
        <RadioGroup
          name="pageBackgroundType"
          row
          value={state.theme.pageBackgroundType}
          onChange={event =>
            dispatch({
              type: ExperienceLandingActions.updatePageBackgroundType,
              payload: event.target.value,
            })
          }
        >
          <FormControlLabel
            color="primary"
            label="White"
            control={<Radio />}
            value={PageBackgroundType.white}
            sx={{
              '& .MuiButtonBase-root': {
                padding: '4px',
                marginLeft: '5px',
              },
              '& .MuiFormControlLabel-label': {
                fontSize: FontSize.Default,
              },
            }}
          />
          <FormControlLabel
            color="primary"
            label="Add Color Effect"
            control={<Radio />}
            value={PageBackgroundType.colorEffect}
            sx={{
              '& .MuiButtonBase-root': {
                padding: '4px',
                marginLeft: '5px',
              },
              '& .MuiFormControlLabel-label': {
                fontSize: FontSize.Default,
              },
            }}
          />
        </RadioGroup>
      </ListItem>
      {!isCurrentBackgroundWhite && (
        <React.Fragment>
          <ListItem disablePadding sx={{ paddingBottom: '8px' }}>
            <Grid container spacing={1}>
              <Grid item>
                <ColorLibraryField
                  color={state.theme.pageBackgroundFirstColor || ''}
                  colorLibrary={state.theme.colorLibrary || []}
                  onChange={color =>
                    dispatch({
                      type: ExperienceLandingActions.updatePageBackgroundFirstColor,
                      payload: color,
                    })
                  }
                  onChangeLibrary={colorLibrary =>
                    dispatch({
                      type: ExperienceLandingActions.updateColorLibrary,
                      payload: colorLibrary,
                    })
                  }
                  label="Color 1"
                />
              </Grid>
              <Grid item>
                <ColorInputField
                  color={state.theme.pageBackgroundFirstColor || ''}
                  onChange={color =>
                    dispatch({
                      type: ExperienceLandingActions.updatePageBackgroundFirstColor,
                      payload: color,
                    })
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem disablePadding sx={{ paddingBottom: '8px' }} divider>
            <Grid container spacing={1}>
              <Grid item>
                <ColorLibraryField
                  color={state.theme.pageBackgroundSecondColor || ''}
                  colorLibrary={state.theme.colorLibrary || []}
                  onChange={color =>
                    dispatch({
                      type: ExperienceLandingActions.updatePageBackgroundSecondColor,
                      payload: color,
                    })
                  }
                  onChangeLibrary={colorLibrary =>
                    dispatch({
                      type: ExperienceLandingActions.updateColorLibrary,
                      payload: colorLibrary,
                    })
                  }
                  label="Color 2"
                />
              </Grid>
              <Grid item>
                <ColorInputField
                  color={state.theme.pageBackgroundSecondColor || ''}
                  onChange={color =>
                    dispatch({
                      type: ExperienceLandingActions.updatePageBackgroundSecondColor,
                      payload: color,
                    })
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        </React.Fragment>
      )}
    </List>
  );
};
