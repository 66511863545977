import React from 'react';
import {
  List,
  ListItem,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  SxProps,
} from '@mui/material';

import { FontSize } from '@vizsla/theme';
import { ExperienceLandingActions, useLandingState } from '@vizsla/providers';
import {
  EditorTextInputFieldSizeType,
  RegistrationsSectionDisplayStyleType,
} from '@vizsla/constants';

import { EditorTextInputField } from '../EditorTextInputField';

export const RegistrationOptionsSection = () => {
  const { state, dispatch } = useLandingState();

  const {
    registrationsSectionTitle,
    registrationsSectionDescription,
    registrationsSectionDisplayStyle,
  } = state.theme;

  const regSectionTitleSxProp: SxProps = {
    width: '336px',
    height: '40px',
    padding: '4px',
    fontSize: FontSize.Default,
  };

  const regSectionDescriptionSxProp: SxProps = {
    width: '336px',
    fontSize: FontSize.Default,
  };

  const regSectionDisplayStyleRadioBtnSxProp: SxProps = {
    '& .MuiButtonBase-root': {
      padding: '4px',
      marginLeft: '5px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: FontSize.Default,
    },
  };

  const handleTitleInputFieldChange = (registrationsSectionTitle: string) =>
    dispatch({
      type: ExperienceLandingActions.updateRegistrationsSectionTitle,
      payload: registrationsSectionTitle,
    });

  const handleDescriptionInputFieldChange = (registrationsSectionDescription: string) =>
    dispatch({
      type: ExperienceLandingActions.updateRegistrationsSectionDescription,
      payload: registrationsSectionDescription,
    });

  const handleDisplayStyleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({
      type: ExperienceLandingActions.updateRegistrationsSectionDisplayStyle,
      payload: event.target.value,
    });

  return (
    <List component="div">
      <ListItem disablePadding sx={theme => ({ paddingBottom: theme.spacing(2) })}>
        <EditorTextInputField
          onChange={handleTitleInputFieldChange}
          fieldSize={EditorTextInputFieldSizeType.small}
          fieldValue={registrationsSectionTitle}
          fieldLabel="Title"
          inputProps={{
            sx: regSectionTitleSxProp,
          }}
        />
      </ListItem>
      <ListItem disablePadding sx={theme => ({ paddingBottom: theme.spacing(3) })}>
        <EditorTextInputField
          onChange={handleDescriptionInputFieldChange}
          fieldSize={EditorTextInputFieldSizeType.small}
          fieldValue={registrationsSectionDescription}
          fieldLabel="Description"
          inputProps={{
            sx: regSectionDescriptionSxProp,
          }}
          rows={3}
          maxRows={4}
          multiline
        />
      </ListItem>
      <ListItem disablePadding sx={theme => ({ paddingBottom: theme.spacing(3) })} divider>
        <Typography
          variant="body2"
          component="span"
          sx={theme => ({ paddingRight: theme.spacing(1.5) })}
        >
          Display Style:
        </Typography>
        <RadioGroup
          name="registrationsSectionDisplayStyle"
          row
          value={registrationsSectionDisplayStyle}
          onChange={handleDisplayStyleChange}
        >
          <FormControlLabel
            color="primary"
            label="Cards"
            control={<Radio />}
            value={RegistrationsSectionDisplayStyleType.cards}
            sx={regSectionDisplayStyleRadioBtnSxProp}
          />
          <FormControlLabel
            color="primary"
            label="List"
            control={<Radio />}
            value={RegistrationsSectionDisplayStyleType.list}
            sx={regSectionDisplayStyleRadioBtnSxProp}
          />
        </RadioGroup>
      </ListItem>
    </List>
  );
};
