import React from 'react';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FileInput } from '8base-react-sdk';

import { MODALS } from '@vizsla/constants';
import { File } from '@vizsla/graphql';
import { useModal } from '@vizsla/hooks';

import { ExperienceImageLayout } from './ExperienceImageLayout';

interface ExperienceHasImageProps {
  file?: File;
  fetching: boolean;
  updating: boolean;

  detach: () => Promise<void>;
  replace: (file: File) => Promise<void>;
}

const DEFAULT_MAX_IMAGE_SIZE = 8 * 1024 * 1024;

const useStyles = makeStyles({
  button: {
    margin: 0,
  },
});

export function ExperienceHasImage(props: ExperienceHasImageProps) {
  const styles = useStyles();

  const { openModal } = useModal();

  const openExplorer = async (picker: (opts: any) => Promise<void>) => {
    await picker({
      maxSize: DEFAULT_MAX_IMAGE_SIZE,
      accept: ['image/jpeg', 'image/png'],
      fromSources: ['local_file_system'],
    });
  };

  const replaceImage = async (file: File | File[]) => {
    const payload: File | undefined = Array.isArray(file) ? file.at(0) : file;

    if (payload) {
      await props.replace(payload);
    }
  };

  const detachImage = async () => {
    await props.detach();
  };

  const confirmDetach = () => {
    openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
      deleteFunction: () => detachImage(),
      objectName: props.file?.filename ?? 'Experience Header Image',
    });
  };

  return (
    <ExperienceImageLayout image={props.file} loading={props.updating}>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <FileInput onChange={replaceImage}>
              {wrapper => (
                <Button
                  className={styles.button}
                  variant="text"
                  color="primary"
                  disabled={props.fetching || props.updating}
                  onClick={() => openExplorer(wrapper.pick)}
                >
                  Edit Image
                </Button>
              )}
            </FileInput>
          </Grid>

          <Grid item>
            <Button
              className={styles.button}
              variant="text"
              color="error"
              disabled={props.fetching || props.updating}
              onClick={confirmDetach}
            >
              Delete Image
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ExperienceImageLayout>
  );
}
