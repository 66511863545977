import React from 'react';

import {
  StoreAssetQuery,
  StoreAssetDocument,
  StoreAssetQueryVariables,
  GetAllAssetInventoryDocument,
} from '@vizsla/graphql';
import { useApolloCacheQueryManager } from '@vizsla/hooks';

import { StoreAssetManagementDataContext } from 'src/providers/storeAssets';

export const useStoreAsset = () => {
  const { storeAsset, queryVariables, refetchAsset } = React.useContext(
    StoreAssetManagementDataContext,
  );

  const { updateQuery: updateStoreAssetQuery } = useApolloCacheQueryManager<
    StoreAssetQuery,
    StoreAssetQueryVariables
  >({
    query: GetAllAssetInventoryDocument,
    typeName: 'AllAssetInventory',
    variables: queryVariables,
  });

  const modifyStoreAssetCountInCache = React.useCallback(
    (newTotalCount: number) => {
      updateStoreAssetQuery(query => {
        // TO DO query.storeAsset!.countOfTotal = newTotalCount;
        // query!.storeAsset!.countOfAvailable = newTotalCount;
      });
    },
    [updateStoreAssetQuery],
  );

  return { storeAsset, modifyStoreAssetCountInCache, refetchAsset };
};
