import React from 'react';
import { Grid } from '@mui/material';

import { CampaignMetricsRaisedCard } from './CampaignMetricsRaisedCard';
import { CampaignMetricsTeamsCard } from './CampaignMetricsTeamsCard';
import { CampaignMetricsAttendeesCard } from './CampaignMetricsAttendeesCard';

export function CampaignMetricsCards() {
  return (
    <Grid container item spacing={2}>
      <GridItem>
        <CampaignMetricsRaisedCard />
      </GridItem>

      <GridItem>
        <CampaignMetricsAttendeesCard />
      </GridItem>

      <GridItem>
        <CampaignMetricsTeamsCard />
      </GridItem>
    </Grid>
  );
}

const GridItem: React.FC = props => (
  <Grid item xs={6} md={12} lg={4}>
    {props.children}
  </Grid>
);
