import React from 'react';
import { DateTime } from 'luxon';
import { Avatar, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Add } from '@mui/icons-material';

import { TableData } from '@vizsla/components';
import type { TableBottomAction, TableColumn, TableRowAction } from '@vizsla/components';
import { useModal, useNotification } from '@vizsla/hooks';
import { DateFormatPatterns } from '@vizsla/constants';
import { EarlyBirdRegistrationInvitation } from '@vizsla/graphql';

import { useEarlyBirdRegistrationInvitations } from 'src/hooks/earlyBirdRegistration';
import {
  EarlyBirdRegistrationInvitationSubmitValues,
  EarlyBirdRegistrationInvitationType,
} from 'src/types/earlyBirdRegistration';
import { MODALS } from 'src/constants/modals';
import { EarlyBirdRegistrationInvitationCreateModal } from 'src/modals';
import { useExperienceId } from 'src/hooks/experiences';
import { getUserFullName } from 'src/utils/user';
import { t } from 'src/utils/template';

import { FORM_SPACING } from '../constants';

// TODO add registered column value (check if user is registered in experience at any Registration Option)

// TODO add possibility to send invitation

interface RenderData {
  avatar?: string;
  name?: string;
}

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: 400,
  },
  avatar: {
    width: 30,
    height: 30,
  },
});

const EarlyBirdRegistrationInvitations: React.FC = () => {
  const classes = useStyles();

  const notification = useNotification();

  const experienceId = useExperienceId();
  const {
    earlyBirdRegistrationInvitations,
    createEarlyBirdRegistrationInvitation,
    deleteEarlyBirdRegistrationInvitation,
    loading,
    creating,
  } = useEarlyBirdRegistrationInvitations(experienceId);

  const { openModal } = useModal();

  const handleSubmit = async (submitValues: EarlyBirdRegistrationInvitationSubmitValues) => {
    try {
      await createEarlyBirdRegistrationInvitation(submitValues);
      notification.success(t('entity_create_success', { objectName: 'Early Bird Invitation' }));
    } catch (error: any) {
      notification.error(
        t('entity_create_error', {
          objectName: 'Early Bird Invitation',
          errorMessage: error.message,
        }),
      );
    }
  };

  const handleDelete = async (record: EarlyBirdRegistrationInvitation) => {
    try {
      await deleteEarlyBirdRegistrationInvitation(String(record.id));
      notification.success(t('entity_delete_success', { objectName: 'Early Bird Invitation' }));
    } catch (error: any) {
      notification.error(
        t('entity_delete_error', {
          objectName: 'Early Bird Invitation',
          errorMessage: error.message,
        }),
      );
    }
  };

  const columns: Array<TableColumn> = [
    {
      key: 'name',
      title: 'name',
      render: row => {
        // eslint-disable-next-line no-underscore-dangle
        const renderData = row._renderData as RenderData;

        return (
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Avatar className={classes.avatar} src={renderData?.avatar} />
            </Grid>
            <Grid item>
              <Typography variant="button">{renderData.name}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      key: 'sentDate',
      title: 'sent',
      dataPath: 'sentDate',
      render: (sentDate?: string) =>
        sentDate
          ? DateTime.fromISO(sentDate).toFormat(DateFormatPatterns.shortMonthDayYear)
          : 'Not sent yet',
    },
    {
      key: 'registered',
      title: 'registered',
      render: () => {
        return 'N/A';
      },
    },
  ];

  const bottomActions: Array<TableBottomAction> = [
    {
      actionName: 'invite attendee or team',
      icon: <Add />,
      onAction: () => {
        openModal(MODALS.EARLY_BIRD_REGISTRATION_CREATE_MODAL, {
          onSubmit: handleSubmit,
        });
      },
    },
  ];

  const rowActions: Array<TableRowAction> = [
    {
      actionName: 'delete',
      onAction: handleDelete,
      disabled: loading || creating,
    },
  ];

  const invitations = React.useMemo(
    () =>
      earlyBirdRegistrationInvitations?.map(invitation => {
        const user = invitation.attendee?.user;
        const userName = getUserFullName(user);

        const teamName = invitation.team?.name;

        const invitationType = invitation.invitationType as EarlyBirdRegistrationInvitationType;

        return {
          ...invitation,
          _renderData: {
            avatar:
              invitation.team?.avatar?.downloadUrl ||
              invitation.attendee?.user?.avatar?.downloadUrl,
            name:
              invitationType === EarlyBirdRegistrationInvitationType.attendee ? userName : teamName,
          },
        };
      }) || [],
    [earlyBirdRegistrationInvitations],
  );

  return (
    <Grid container spacing={FORM_SPACING} direction="column">
      <Grid item>
        <Typography variant="h4">Early Bird Invitations</Typography>
      </Grid>
      <Grid item>
        <TableData
          tableContainerProps={{ className: classes.tableContainer }}
          hideTitle={!invitations.length}
          columns={columns}
          disableEmptyMessage
          dataSource={invitations}
          loading={loading || creating}
          bottomActions={bottomActions}
          rowActions={rowActions}
        />
      </Grid>
      <EarlyBirdRegistrationInvitationCreateModal />
    </Grid>
  );
};

export default EarlyBirdRegistrationInvitations;
