import React from 'react';

import { useModal } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { TicketingOptionCreateForm } from 'src/components/ticketingOption';
import { MODALS } from 'src/constants/modals';

const TicketingOptionCreateModal: React.FC = () => {
  const { isOpen, closeModal } = useModal(MODALS.TICKETING_OPTION_CREATE_MODAL);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth>
      <Modal.Content>
        <TicketingOptionCreateForm />
      </Modal.Content>
    </Modal>
  );
};

export default TicketingOptionCreateModal;
