import React from 'react';
import { CardMedia, Grid, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { formatMoney, replaceIfNil } from '@vizsla/utils';

import { BorderRadius, PaletteColor, Shade } from 'src/theme';
import { DateRange } from 'src/components/shared';
import { CampaignStatus, CampaignType } from 'src/types/campaign';
import { CampaignStatusChip } from 'src/components/campaign';
import { EMPTY_IMAGE } from 'src/assets';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: 300,
    borderRadius: BorderRadius.M,
  },
  cardWrapper: {
    marginBottom: theme.spacing(4),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  imgWrap: {
    borderRadius: `${BorderRadius.M} ${BorderRadius.M} 0 0`,
  },
  content: {
    padding: theme.spacing(2, 2, 3, 3),
  },
  cardTitle: {
    color: Shade.Blue[500],
    marginBottom: theme.spacing(2),
    overflowWrap: 'break-word',
  },
  subtitle: {
    color: PaletteColor.GrayText,
    marginBottom: theme.spacing(5),
  },
  info: {},
  label: {
    color: PaletteColor.GrayText,
    marginRight: theme.spacing(0.5),
  },
  footerText: {
    marginBottom: theme.spacing(4),
  },
}));

interface CampaignCardProps {
  name: string;
  campaignType: CampaignType;
  startDate: string;
  endDate: string;
  imageUrl?: string;
  experiencesCount?: number;
  fundraisingGoal: number;
  fundraiserGoal?: number;
  attendeesGoal?: number;
  donorGoal?: number;
  teams?: number;
  totalRaised?: number;
}

export const CampaignCard: React.FC<CampaignCardProps> = ({
  name,
  imageUrl,
  campaignType,
  startDate,
  endDate,
  experiencesCount,
  fundraisingGoal,
  fundraiserGoal,
  attendeesGoal,
  donorGoal,
  teams,
  totalRaised,
}) => {
  const classes = useStyles();

  const renderCampaignType = () => {
    return (
      <div className={classes.info}>
        <Typography className={classes.label} variant="caption">
          Type:
        </Typography>
        <Typography variant="caption">{campaignType}</Typography>
      </div>
    );
  };

  const renderExperiences = () => {
    return (
      <div className={classes.info}>
        <Typography className={classes.label} variant="caption">
          Experiences:
        </Typography>
        <Typography variant="caption">{experiencesCount || 0}</Typography>
      </div>
    );
  };

  const renderFundraisingGoal = () => {
    return (
      <div className={classes.info}>
        <Typography variant="caption" className={classes.label}>
          Fundraising Goal:
        </Typography>
        <Typography variant="caption">{formatMoney(fundraisingGoal || 0)}</Typography>
      </div>
    );
  };

  const renderFundraiserGoal = () => {
    return (
      <div className={classes.info}>
        <Typography variant="caption" className={classes.label}>
          Fundraiser Goal:
        </Typography>
        <Typography variant="caption">{formatMoney(fundraiserGoal || 0)}</Typography>
      </div>
    );
  };

  const renderAttendeesGoal = () => {
    return (
      <div className={classes.info}>
        <Typography className={classes.label} variant="caption">
          Attendees Goal:
        </Typography>
        <Typography variant="caption">{replaceIfNil(attendeesGoal, 'Not Set')}</Typography>
      </div>
    );
  };

  const renderDonorsGoal = () => {
    return (
      <div className={classes.info}>
        <Typography variant="caption" className={classes.label}>
          Donors Goal:
        </Typography>
        <Typography variant="caption">{replaceIfNil(donorGoal, 'Not Set')}</Typography>
      </div>
    );
  };

  const renderTotalRaisedGoal = () => {
    return (
      <div className={classes.info}>
        <Typography variant="caption" className={classes.label}>
          Total Raised:
        </Typography>
        <Typography variant="caption">{replaceIfNil(totalRaised, 'Not Set')}</Typography>
      </div>
    );
  };

  const renderTeams = () => {
    return (
      <div className={classes.info}>
        <Typography variant="caption" className={classes.label}>
          Teams:
        </Typography>
        <Typography variant="caption">{replaceIfNil(teams, 'Not Set')}</Typography>
      </div>
    );
  };

  const renderCampaignInfoByType = () => {
    switch (campaignType) {
      case CampaignType.registrationAndTicketing: {
        return (
          <React.Fragment>
            {renderExperiences()}
            {renderFundraisingGoal()}
            {renderAttendeesGoal()}
            {renderTeams()}
          </React.Fragment>
        );
      }
      case CampaignType.personalMission: {
        return (
          <React.Fragment>
            {renderFundraisingGoal()}
            {renderFundraiserGoal()}
            {renderTeams()}
          </React.Fragment>
        );
      }
      case CampaignType.impactGiving: {
        return (
          <React.Fragment>
            {renderTotalRaisedGoal()}
            {renderDonorsGoal()}
          </React.Fragment>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Paper variant="elevation" elevation={1} className={classes.card}>
      <CardMedia
        className={classes.imgWrap}
        component="img"
        alt="Campaign"
        height="168"
        image={imageUrl || EMPTY_IMAGE}
        title="Campaign"
      />
      <div className={classes.content}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.header}
        >
          <DateRange startDate={startDate as string} endDate={endDate as string} />
          <CampaignStatusChip status={CampaignStatus.draft} />
        </Grid>
        <Typography className={classes.cardTitle} variant="h4">
          {replaceIfNil(name, '-')}
        </Typography>

        {renderCampaignType()}
        {renderCampaignInfoByType()}
      </div>
    </Paper>
  );
};
