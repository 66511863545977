import React from 'react';
import { MonetizationOnOutlined } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';

import { Field, CheckboxField, DateField, TimeField } from '@vizsla/components';
import { DateFormatPatterns } from '@vizsla/constants';
import { getNowDate, toDateTimeFromISO } from '@vizsla/utils';

import { SwitchCardField } from 'src/components/forms';

export const FundraisingObligation: React.FC = () => {
  const currentDate = toDateTimeFromISO(getNowDate());
  return (
    <Field
      name="isObligation"
      component={SwitchCardField}
      title="Fundraising Obligation"
      height={410}
      image={<MonetizationOnOutlined />}
      renderContent={(checked: boolean) => (
        <React.Fragment>
          <Typography my={1} variant="body2">
            If not fulfilled, charge the remaining fundraising <br /> commitment balance to the
            attendees credit card.
          </Typography>

          <Field
            name="isWithoutPenalty"
            disabled={!checked}
            component={CheckboxField}
            label={<Typography variant="body2">Withdrawal without penalty</Typography>}
          />

          <Grid container spacing={2} my={1}>
            <Grid item flexGrow={1} flexBasis={0}>
              <Field
                name="date"
                label="Date *"
                disabled={!checked}
                inputFormat={DateFormatPatterns.fullMonthDayYear}
                component={DateField}
                minDate={currentDate}
              />
            </Grid>
            <Grid item flexGrow={1} flexBasis={0}>
              <Field name="time" label="Time *" disabled={!checked} component={TimeField} />
            </Grid>
          </Grid>
          <Typography variant="h6" mt={2}>
            Charge Date & Time
          </Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item flexGrow={1} flexBasis={0}>
              <Field
                name="chargeDate"
                inputFormat={DateFormatPatterns.fullMonthDayYear}
                disabled={!checked}
                component={DateField}
                label="Charge Date *"
                minDate={currentDate}
              />
            </Grid>
            <Grid item flexGrow={1} flexBasis={0}>
              <Field
                name="chargeTime"
                disabled={!checked}
                label="Charge Time *"
                component={TimeField}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    />
  );
};
