import React from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, Grid, IconButton, Theme, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { useModal } from '@vizsla/hooks';

import { PaletteColor } from 'src/theme';
import { MODALS } from 'src/constants/modals';

import { CampaignDiscountForm } from './components';

const useStyles = makeStyles((theme: Theme) => ({
  dialogBlock: {
    padding: theme.spacing('40px', '50px', '60px', '50px'),
  },
  dialogContent: {
    paddingTop: '32px',
  },
  closeTitleButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: PaletteColor.PrimaryGray,
  },
}));

const CampaignDiscountCreateModal: React.FC = () => {
  const classes = useStyles();

  const { isOpen, closeModal, args } = useModal(MODALS.CAMPAIGN_DISCOUNTS_CREATE_MODAL);

  const onCloseModal = () => {
    closeModal();
  };

  return (
    <Dialog open={isOpen} onClose={onCloseModal} fullWidth maxWidth="sm">
      <Grid container direction="column" className={classes.dialogBlock}>
        <Grid item>
          <Typography variant="h3" align="center">
            {args?.type ?? 'Add Discount Code'}
          </Typography>
          <IconButton onClick={onCloseModal} className={classes.closeTitleButton} size="large">
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item className={classes.dialogContent}>
          <CampaignDiscountForm />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CampaignDiscountCreateModal;
