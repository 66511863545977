import { isNil } from 'lodash';

import { StoreAssetUpdateInput } from '@vizsla/graphql';

import { StoreAsset, StoreAssetCreateOrUpdateFormValues } from 'src/types/storeAssets';

export const getInfoUpdateFormValues = (storeAsset: StoreAsset | undefined) => {
  let values: StoreAssetCreateOrUpdateFormValues = {};

  if (isNil(storeAsset)) {
    return values;
  }

  // TO DO const stockMinimumRecipients = storeAsset.stockMinimumRecipients?.items || [];
  const stockMinimumRecipients = [];
  // this arrays comes from the database with Maybe<Maybe<string>[]> type
  // const sizeAttributes = (storeAsset.sizeAttributes || []).map(size => size || 'size');
  const sizeAttributes = [];
  // const styleAttributes = (storeAsset.styleAttributes || []).map(style => style || 'style');
  const styleAttributes = [];

  values = {
    name: storeAsset.name || undefined,
    description: storeAsset.description || undefined,
    storeAvailability: storeAsset.storeAvailability || true,
    swagbagAvailability: storeAsset.swagbagAvailability || false,
    // sizeAttributes,
    // styleAttributes,
    price: storeAsset.price || undefined,
    unitCost: storeAsset.unitCost || undefined,
    continueSalesWhenOutOfStock: storeAsset.continueSalesWhenOutOfStock || false,
    notifyWhenStockMinimumIsAchieved: storeAsset.notifyWhenStockMinimumIsAchieved || false,
    stockMinimumBoundary: storeAsset.stockMinimumBoundary || undefined,
    // stockMinimumRecipients,
    note: storeAsset.note || undefined,
  };

  return values;
};

export const getInfoUpdateMutationInput = (formValues: StoreAssetCreateOrUpdateFormValues) => {
  // const stockMinimumRecipients = formValues.stockMinimumRecipients || [];
  // const stockMinimumRecipientsUsersId = stockMinimumRecipients.map(user => ({
  //   id: user.id,
  // }));

  const inputObject: StoreAssetUpdateInput = {
    name: formValues.name || 'Asset name',
    description: formValues.description,
    // TO DO (StoreAsset)
    // sizeAttributes: formValues.sizeAttributes,
    // styleAttributes: formValues.styleAttributes,
    // storeAvailability: formValues.storeAvailability,
    swagbagAvailability: formValues.swagbagAvailability,
    price: formValues.price,
    unitCost: formValues.unitCost,
    continueSalesWhenOutOfStock: formValues.continueSalesWhenOutOfStock,
    notifyWhenStockMinimumIsAchieved: formValues.notifyWhenStockMinimumIsAchieved,
    stockMinimumBoundary: formValues.stockMinimumBoundary,
    // TO DO (StoreAsset)
    // stockMinimumRecipients: {
    //   connect: stockMinimumRecipientsUsersId,
    // },
    note: formValues.note,
  };

  return inputObject;
};
