import { MONEY_FORMAT, PERCENT_FORMAT } from 'src/constants/formats';
import {
  DiscountAllocationTypes,
  DiscountAmountTypes,
  DiscountDatePickerTypes,
} from 'src/types/shared';

export const getAmountFormatType = (type: string) => {
  if (type === 'percentage') {
    return PERCENT_FORMAT;
  }
  return MONEY_FORMAT;
};

export const getDiscountInitialValues = (editedData?: any) => {
  return {
    name: editedData?.type ? editedData?.name : null,
    category: editedData?.category ?? null,
    amount: editedData?.amount ?? undefined,
    amountType: editedData?.amountType ?? DiscountAmountTypes.dollar,
    datePickerType: editedData?.datePickerType ?? DiscountDatePickerTypes.dateRange,
    startDate: editedData?.startDate ?? null,
    startTime: editedData?.startTime ?? null,
    endDate: editedData?.endDate ?? null,
    endTime: editedData?.endTime ?? null,
    allocationType: editedData?.allocationType ?? DiscountAllocationTypes.byExperience,
    experienceType: editedData?.experienceType?.id ?? null,
    limitToOneUsePerAttendee: editedData?.limitToOneUsePerAttendee ?? false,
    totalUses: editedData?.totalUses ?? undefined,
  };
};
