import React from 'react';

import { LandingState, LandingAction, INITIAL_STATE } from './ThankYouLanding.types';

type LandingStateContextType = {
  state: LandingState;
  dispatch: (action: LandingAction) => void;
  reset: () => void;
};
export const LandingStateContext = React.createContext<LandingStateContextType>({
  state: INITIAL_STATE,
  dispatch: () => {},
  reset: () => {},
});
