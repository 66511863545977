import styled from '@emotion/styled';

import { Field, RadioGroupField } from '@vizsla/components';

export const InLineFields = styled.div`
  position: relative;
  display: flex;
  gap: 1rem;
  justify-content: center;
  width: 100%;
`;

export const CampaignWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PdfWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: start;
  align-items: center;
  width: 100%;
  ${({ theme }) => `color:${theme.customPalette.nav.icon.fill.default}`};
`;

export const FlexField = styled(Field)`
  flex: 1;
`;

export const FlexRowRadioGroupField = styled(RadioGroupField)`
  display: flex;
  flex-direction: row;
`;

export const LinkText = styled.p(({ theme }) => ({
  color: theme.customPalette.nav.icon.fill.default,
  cursor: 'pointer',
}));
