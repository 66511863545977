import React from 'react';

import { TableData, UserAvatarFullNameInline } from '@vizsla/components';
import type { TableColumn } from '@vizsla/components';
import { DateFormatPatterns, NO_APPLY_LABEL } from '@vizsla/constants';
import { formatDate } from '@vizsla/utils';
import { Attendee } from '@vizsla/graphql';
import { usePagination } from '@vizsla/hooks';

import { CampaignFundraiserGoalProgress } from 'src/components/campaign';

const COLUMNS: TableColumn<Attendee>[] = [
  {
    title: 'name',
    key: 'name',

    render(root, attendee) {
      return <UserAvatarFullNameInline user={attendee.user} />;
    },
  },

  {
    title: 'date',
    key: 'date',

    render(root, attendee) {
      return formatDate(attendee.createdAt, DateFormatPatterns.shortMonthDayYear);
    },
  },

  {
    title: 'Registration Option',
    key: 'registrationOption',

    render(root, attendee) {
      return attendee.registrationOption?.name ?? NO_APPLY_LABEL;
    },
  },

  {
    title: 'team',
    key: 'team',

    render(root, attendee) {
      return attendee.teamMembership?.team?.name ?? NO_APPLY_LABEL;
    },
  },

  {
    title: 'total raised',
    key: 'totalRaised',

    render(root, attendee) {
      if (attendee.fundraising) {
        const groups: any[] = attendee.fundraising.allocatedDonations?.groups ?? [];
        const raised: number = groups?.[0]?.raised ?? 0;

        const goal = attendee.fundraising.goal ?? 0;

        return <CampaignFundraiserGoalProgress totalRaised={raised} fundraiserGoal={goal} />;
      }

      return NO_APPLY_LABEL;
    },
  },
];

interface Props {
  dataSource: Attendee[];

  loading?: boolean;
  showNavigation?: boolean;
}

export function CampaignAttendeesTable(props: Props) {
  const pagination = usePagination();

  return (
    <TableData
      dataSource={props.dataSource}
      loading={props.loading ?? true}
      pagination={props.showNavigation ? pagination : undefined}
      columns={COLUMNS}
    />
  );
}
