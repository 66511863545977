import React from 'react';
import {
  Select,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  ListSubheader,
} from '@mui/material';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import _ from 'lodash';

import { hasFieldError, getFieldError } from 'src/utils/form';

type Option = {
  label?: string;
  value?: string;
};

type GroupHeader = {
  groupName: string;
};

const isGroupHeader = (arg: Option | GroupHeader): arg is GroupHeader => {
  return _.has(arg, 'groupName');
};

export interface SelectFieldProps {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  label?: React.ReactNode;
  fullWidth?: boolean;
  options: Array<Option | GroupHeader>;
  disabled?: boolean;
  required?: boolean;
}

export const SelectField: React.FC<SelectFieldProps> = ({
  input,
  meta,
  fullWidth,
  label,
  options,
  disabled,
  required = false,
  ...selectProps
}) => {
  const { name, value, onBlur, onChange, multiple } = input;

  const handleChange = React.useCallback(
    e => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const id = `select-${name}`;

  const error = hasFieldError(meta);

  const helperText = getFieldError(meta);

  const renderOption = (option: Option | GroupHeader) => {
    if (isGroupHeader(option)) {
      return <ListSubheader>{option.groupName}</ListSubheader>;
    }

    return (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  };

  return (
    <FormControl variant="outlined" error={error} fullWidth={fullWidth} required={required}>
      <InputLabel id={id} disabled={disabled}>
        {label}
      </InputLabel>
      <Select
        labelId={id}
        name={name}
        value={multiple ? value || [] : value}
        onChange={handleChange}
        onBlur={onBlur}
        label={label}
        multiple={multiple}
        disabled={disabled}
        {...selectProps}
      >
        {options?.map(option => renderOption(option))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
