import React from 'react';
import { Box, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { formatMoney } from '@vizsla/utils';
import { Campaign } from '@vizsla/graphql';

import { Display, PaletteColor, Shade } from 'src/theme';
import { DeterminateProgress } from 'src/components/shared';
import { getAttendeesCountFromCampaign } from 'src/utils/campaign';

const useStyles = makeStyles<Theme>(theme => ({
  progress: {
    marginRight: theme.spacing(1),
  },
}));

const statistics = [
  { title: 'TOTAL RAISED', titleValue: 0, percentage: 0, total: 0, id: 1, isCurrency: true },
  { title: 'ATTENDEES', titleValue: 0, percentage: 0, total: 0, id: 2 },
  { title: 'TEAMS', titleValue: 0, percentage: 0, total: 0, id: 3 },
];

const defaultCalculateColor = (value: number) => {
  if (value <= 0) {
    return Shade.Gray[200];
  }
  if (value < 20) {
    return PaletteColor.ErrorMain;
  }

  if (value < 40) {
    return PaletteColor.WarningProgress;
  }
  return PaletteColor.SuccessMain;
};

export const ProgressBar = ({ campaings = [] }) => {
  const classes = useStyles();
  const stats = campaings.reduce(
    (accum: any, campaign: Campaign) => {
      const setValues = accum.map((acm, index) => {
        const AcmCopy = { ...acm };
        if (index === 0) {
          AcmCopy.titleValue += campaign.totalRaised;
          AcmCopy.total += campaign.fundraisingGoal;
        } else if (index === 1) {
          AcmCopy.titleValue += getAttendeesCountFromCampaign(campaign);
          AcmCopy.total += campaign.attendanceFundraisersCountGoal;
        } else if (index === 2) {
          AcmCopy.titleValue += campaign?.teams?.count ?? 0;
          AcmCopy.total += campaign?.teamGoal ?? 0;
        }
        AcmCopy.percentage = ((AcmCopy.titleValue * 100) / AcmCopy.total).toFixed(0);
        return AcmCopy;
      });
      return setValues;
    },
    [...statistics],
  );

  return (
    <Box mb="17px" mt="7px">
      <Grid container xs={12}>
        {stats.map(statistic => (
          <Grid key={statistic.id} container xs={2}>
            <Typography color="#676767" mr="4px">
              {statistic.title}:
            </Typography>
            <Typography mr="4px" fontWeight={800}>
              {statistic.isCurrency ? formatMoney(statistic.titleValue) : statistic.titleValue}
            </Typography>
            <DeterminateProgress
              value={statistic.percentage}
              className={classes.progress}
              size={15}
            />
            <Grid display="flex" justifyContent="center" alignItems="center">
              <Typography color={defaultCalculateColor(statistic.percentage)} variant="body2">
                {statistic.percentage}%
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
