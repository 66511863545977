import React from 'react';
import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { BorderRadius, PaletteColor } from '@vizsla/theme';
import { TemplateType } from '@vizsla/types';

import { EMPTY_IMAGE } from 'src/assets';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: BorderRadius.M,
  },

  image: {
    height: '180px',
  },

  title: {
    marginBottom: theme.spacing(1),
  },

  description: {
    color: PaletteColor.GrayText,
    lineHeight: '20px',
    paddingBottom: '16px',
  },

  content: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: theme.spacing(2, 3),
    height: '100%',
  },
}));

interface CampaignPageCardLayoutProps {
  campaignID: string;

  title: string;
  description: string;
  type: TemplateType;
  imageURI?: string;

  children: React.ReactNode;

  onEdit?(): void;
}

export function CampaignPageCardLayout(props: CampaignPageCardLayoutProps) {
  const styles = useStyles();

  return (
    <Grid item xs={12} lg={4} xl={3}>
      <Card className={styles.card}>
        <CardMedia component="img" src={props.imageURI ?? EMPTY_IMAGE} className={styles.image} />

        <CardContent className={styles.content}>
          <Typography variant="h5" className={styles.title}>
            {props.title}
          </Typography>

          <Typography variant="body2" component="p" className={styles.description}>
            {props.description}
          </Typography>

          {props.children}
        </CardContent>
      </Card>
    </Grid>
  );
}
