import React from 'react';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import { ExperienceTypeRadioGroup, ExperienceTypeRadioProps } from 'src/components/experience';
import { ExperienceType } from 'src/types/experience';

interface ExperienceTypeRadioGroupFieldProps
  extends Omit<ExperienceTypeRadioProps, 'onChangeExperienceType'> {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  label?: React.ReactNode;
  fullWidth?: boolean;
}

export const ExperienceTypeRadioGroupField: React.FC<ExperienceTypeRadioGroupFieldProps> = ({
  input,
  ...props
}) => {
  const { onChange: onChangeInput, value, name } = input;

  const onChangeExperienceType = React.useCallback(
    (experienceType: ExperienceType) => {
      onChangeInput(experienceType);
    },
    [onChangeInput],
  );

  return (
    <ExperienceTypeRadioGroup
      {...props}
      name={name}
      defaultValue={value}
      onChangeExperienceType={onChangeExperienceType}
    />
  );
};
