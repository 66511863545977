import React from 'react';

import { useModal, useIsMounted } from '@vizsla/hooks';
import { FullScreenModal } from '@vizsla/components';

import {
  CampaignRegistrationContextType,
  CampaignRegistrationContext,
  CampaignRegistrationProvider,
} from 'src/providers/campaign';
import {
  CampaignRegistrationAndTicketingSteps,
  CampaignPersonalMissionSteps,
  CampaignImpactGivingSteps,
} from 'src/components/campaign';
import { MODALS } from 'src/constants/modals';
import { CampaignType } from 'src/types/campaign';

const CampaignCreateModal: React.FC = () => {
  const { isOpen, closeModal } = useModal(MODALS.CAMPAIGN_CREATE_MODAL);

  const isMounted = useIsMounted();

  const onCloseModal = () => {
    closeModal();
  };

  const renderStepsByCampaignType = (campaignType: CampaignType): React.ReactNode | null => {
    switch (campaignType) {
      case CampaignType.registrationAndTicketing: {
        return <CampaignRegistrationAndTicketingSteps onCloseModal={onCloseModal} />;
      }
      case CampaignType.personalMission: {
        return <CampaignPersonalMissionSteps onCloseModal={onCloseModal} />;
      }
      case CampaignType.impactGiving: {
        return <CampaignImpactGivingSteps onCloseModal={onCloseModal} />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <FullScreenModal isOpen={isOpen} onClose={onCloseModal}>
      {isMounted && (
        <CampaignRegistrationProvider>
          <CampaignRegistrationContext.Consumer>
            {(campaignTypeContextValue: CampaignRegistrationContextType) =>
              renderStepsByCampaignType(campaignTypeContextValue.campaignType)
            }
          </CampaignRegistrationContext.Consumer>
        </CampaignRegistrationProvider>
      )}
    </FullScreenModal>
  );
};

export default CampaignCreateModal;
