import React from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Grid, Theme } from '@mui/material';

import { PaletteColor, Shade } from '@vizsla/theme';
import { COGNITO_ERROR_CODES, CompleteRegistrationSchema } from '@vizsla/constants';
import { validateWithSchema } from '@vizsla/utils';
import { useAppAuth } from '@vizsla/hooks';
import { Button, Field, AuthTextField } from '@vizsla/components';

import * as appRoutes from 'src/constants/routes';
import { OrganizationAuthLayout } from 'src/layouts';

const useStyles = makeStyles((theme: Theme) => ({
  loginForm: {
    marginRight: 'auto',
    width: 440,
  },
  textContainer: {
    width: 440,
  },
  signupTextContainer: {
    marginTop: theme.spacing(4),
  },
  signupText: {
    color: Shade.Gray[400],
  },
  submitButton: {
    margin: theme.spacing(2.5, 0, 0, 0),
  },
  checkBoxLabel: {
    '& .MuiFormControlLabel-label': {
      color: PaletteColor.White,
    },
  },
  checkBox: {
    color: Shade.Gray[400],
    '&.Mui-checked': {
      color: Shade.Teal[600],
    },
  },
}));

type SignupErrorType = keyof typeof COGNITO_ERROR_CODES;

const AuthCompleteRegistrationPage: React.FC = () => {
  const classes = useStyles();

  const { loginByInvite } = useAppAuth();
  const navigate = useNavigate();

  const onSubmit = React.useCallback(
    async data => {
      try {
        await loginByInvite(data);

        return navigate(appRoutes.authConfirmationEmail);
      } catch (e) {
        const errorCode: SignupErrorType = e?.code;
        const errorField =
          COGNITO_ERROR_CODES[errorCode]?.field || COGNITO_ERROR_CODES.UnknownError.field;
        const errorMessage = e.message ?? 'Incorrect email';

        // todo: replace with toast message
        return {
          [errorField]: errorMessage,
        };
      }
    },
    [navigate, loginByInvite],
  );
  return (
    <OrganizationAuthLayout title="Complete Registration." caption="Set up new password.">
      <Form
        onSubmit={onSubmit}
        validate={values => validateWithSchema(CompleteRegistrationSchema, values)}
      >
        {({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
          const isDisabled = submitting || pristine || hasValidationErrors;

          return (
            <form onSubmit={handleSubmit} className={classes.loginForm}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Field name="email" label="Email Address" component={AuthTextField} />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="tempPassword"
                    label="Confirmation Code"
                    component={AuthTextField}
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Field
                      name="newPassword"
                      label="New Password"
                      component={AuthTextField}
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="confirmNewPassword"
                      label="Confirm password"
                      component={AuthTextField}
                      type="password"
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="left">
                  <Grid item>
                    <Button
                      className={classes.submitButton}
                      type="submit"
                      color="secondary"
                      loading={submitting}
                      disabled={isDisabled}
                    >
                      Complete
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Form>
    </OrganizationAuthLayout>
  );
};

export { AuthCompleteRegistrationPage };
