import React from 'react';
import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { CommitmentTypesLabelsDisct } from '../types';
import { CashCommitmentStatusE, cashCommitmentStatusDict } from '../constants';

const renderRowText = (text: string) => <Typography>{text}</Typography>;

export const columns = [
  {
    title: 'START DATE',
    key: 'createdAt',
    dataPath: 'createdAt',
    render: renderRowText,
  },
  {
    title: 'EXPIRATION DATE',
    key: 'expirationDate',
    dataPath: 'expirationDate',
    render: renderRowText,
  },
  {
    title: 'TYPE',
    key: 'type',
    dataPath: 'type',
    render: type => renderRowText(CommitmentTypesLabelsDisct[type]),
  },
  {
    title: 'ALLOCATION',
    key: 'allocation',
    dataPath: 'allocation',
    render: allocation => {
      if (allocation?.campaign) {
        return renderRowText(`Campaign: ${allocation?.campaign?.name ?? ''}`);
      }
      return renderRowText(`Experience: ${allocation?.experience?.name ?? ''}`);
    },
  },
  {
    title: 'AMOUNT',
    key: 'amount',
    dataPath: 'amount',
    render: renderRowText,
  },
  {
    title: 'USED/RECEIVED',
    key: 'usedReceived',
    dataPath: 'usedReceived',
    render: renderRowText,
  },
  {
    title: 'PAYMENT',
    key: 'status',
    dataPath: 'status',
    render: row => {
      if (row) {
        if (row === CashCommitmentStatusE.pending)
          return (
            <LoadingButton onClick={() => {}} size="small">
              Pay
            </LoadingButton>
          );
        return renderRowText('Successful');
      }
      return renderRowText('N/A');
    },
  },
];
