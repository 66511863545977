import React from 'react';
import _ from 'lodash';

import { TableData, TeamAvatarWithNameInline, UserAvatarFullNameInline } from '@vizsla/components';
import type { TableColumn } from '@vizsla/components';
import { CampaignTeamMember, TeamFundraising } from '@vizsla/graphql';
import { formatDate, formatMoney, formatNumber } from '@vizsla/utils';
import { usePagination, usePaginationQueryVariables, useSearchQueryVariables } from '@vizsla/hooks';
import { DateFormatPatterns } from '@vizsla/constants';

import { useTeamsList } from 'src/hooks/peopleCRM';

const TEAMS_TABLE_COLUMNS: Array<TableColumn> = [
  {
    title: 'NAME',
    key: 'name',
    render: (team?: TeamFundraising) => {
      return <TeamAvatarWithNameInline team={team} />;
    },
  },
  {
    title: 'CREATION DATE',
    key: 'creationDate',
    dataPath: 'createdAt',
    render: (creationDateString?: string) => {
      if (!creationDateString) {
        return 'N/A';
      }

      return formatDate(creationDateString, DateFormatPatterns.shortDateWithSlash);
    },
  },
  {
    title: 'CAPTAIN',
    key: 'captain',
    dataPath: 'captain.items',
    render: (members: CampaignTeamMember[] = []) => {
      if (!members.length) {
        return 'N/A';
      }

      return <UserAvatarFullNameInline user={members[0].attendee?.user || undefined} />;
    },
  },
  {
    title: 'MEMBERS',
    key: 'members',
    dataPath: 'membersCount.count',
    render: (membersCount: number | undefined) => {
      if (_.isNil(membersCount)) {
        return 'N/A';
      }

      return formatNumber(membersCount);
    },
  },
  {
    title: 'TOTAL RAISED',
    key: 'totalRaised',
    render: (team?: TeamFundraising) => {
      if (!team) {
        return 'N/A';
      }

      return formatMoney(team.totalRaised);
    },
  },
];

const TeamsTable: React.FC = () => {
  const pagination = usePagination();
  const paginationQueryVariables = usePaginationQueryVariables();

  const searchQueryVariables = useSearchQueryVariables(true);

  const { teams, loading } = useTeamsList({ searchQueryVariables, paginationQueryVariables });

  React.useEffect(() => {
    pagination.onCountChange(teams?.teamFundraisingsList?.count || 0);
  }, [pagination, teams]);

  const dataSource = teams?.teamFundraisingsList?.items || [];

  return (
    <TableData
      dataSource={dataSource}
      columns={TEAMS_TABLE_COLUMNS}
      emptyMessage="There are no teams in the organization"
      loading={loading}
      pagination={pagination}
    />
  );
};

export { TeamsTable };
