import React from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { useModal } from '@vizsla/hooks';
import { formatMoney, buildUrl } from '@vizsla/utils';
import { TableBottomAction, TableColumn, TableData, TableRowAction } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import { useExperienceStoreAssets } from 'src/hooks/storeAssets';
import type { ExperienceStoreAsset } from 'src/types/storeAssets';
import * as appRoutes from 'src/constants/routes';
import { storeAsset as storeAssetEntityId } from 'src/constants/entities-id';
import { TableNameCellContent } from 'src/components/storeAssets/StoreAssetsDashboardTable/components';

interface ExperienceStoreAssetsTableProps {
  isStoreEnabled: boolean;
}

const ExperienceStoreAssetsTable: React.FC<ExperienceStoreAssetsTableProps> = ({
  isStoreEnabled,
}) => {
  const navigate = useNavigate();

  const { openModal } = useModal();

  const { isExperienceStoreAssetsLoading, creating, updating, deleting, experienceStoreAssets } =
    useExperienceStoreAssets();

  const columns: Array<TableColumn<ExperienceStoreAsset>> = React.useMemo(
    () => [
      {
        key: 'item',
        title: 'Item',
        render: (experienceStoreAsset: ExperienceStoreAsset) => {
          const { storeAsset } = experienceStoreAsset;
          return (
            <TableNameCellContent
              assetName={storeAsset?.name || ''}
              assetImageUrl={storeAsset?.image?.downloadUrl || null}
            />
          );
        },
      },
      {
        key: 'total',
        title: 'Total',
        render: (experienceStoreAsset: ExperienceStoreAsset) => {
          const changeData: any = experienceStoreAsset;
          return changeData?.storeAsset?.total || '-';
        },
      },
      {
        key: 'available',
        title: 'Available',
        render: (experienceStoreAsset: ExperienceStoreAsset) => {
          const changeData: any = experienceStoreAsset;
          return changeData?.storeAsset?.available || '-';
        },
      },
      {
        key: 'price',
        title: 'Price',
        render: (experienceStoreAsset: ExperienceStoreAsset) => {
          return formatMoney(experienceStoreAsset.price);
        },
      },
    ],
    [experienceStoreAssets],
  );

  const rowActions: Array<TableRowAction<ExperienceStoreAsset>> = React.useMemo(
    () => [
      {
        actionName: 'View in Assets',
        onAction: experienceStoreAsset => {
          const assetUrl = buildUrl(appRoutes.storeAssetsAssetPage, {
            [storeAssetEntityId]: experienceStoreAsset.storeAsset?.id || '',
          });
          navigate(assetUrl);
        },
      },
      {
        actionName: 'Edit',
        onAction: experienceStoreAsset => {
          const modalArgs = {
            experienceStoreAsset,
          };
          openModal(MODALS.EXPERIENCE_STORE_ASSET_UPDATE_MODAL, modalArgs);
        },
      },
      {
        actionName: 'Delete',
        onAction: experienceStoreAsset => {
          const modalArgs = {
            experienceStoreAsset,
          };
          openModal(MODALS.EXPERIENCE_STORE_ASSET_DELETE_MODAL, modalArgs);
        },
      },
    ],
    [experienceStoreAssets],
  );

  const bottomActions: Array<TableBottomAction> = React.useMemo(
    () => [
      {
        icon: <AddIcon />,
        actionName: 'Add Item',
        onAction: () => openModal(MODALS.EXPERIENCE_STORE_ASSET_ADD_MODAL),
      },
    ],
    [experienceStoreAssets],
  );

  const isTableLoading = isExperienceStoreAssetsLoading || creating || updating || deleting;
  const isStoreDisabled = !isStoreEnabled;

  return (
    <TableData
      loading={isTableLoading}
      disabled={isStoreDisabled}
      dataSource={experienceStoreAssets}
      columns={columns}
      rowActions={rowActions}
      bottomActions={bottomActions}
    />
  );
};

export { ExperienceStoreAssetsTable };
