import React from 'react';
import { Grid, InputAdornment, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Face, Group, MonetizationOnOutlined } from '@mui/icons-material';

import { NumberField, Field } from '@vizsla/components';
import { BASE_NUMBER_FORMAT } from '@vizsla/constants';

import { useCampaignRegistration } from 'src/hooks/campaign';
import { CardInput } from 'src/components/shared';
import { CampaignGoal, CampaignType } from 'src/types/campaign';
import { PaletteColor } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 680,
    padding: theme.spacing(2, 0, 4, 0),
  },
  title: {
    color: PaletteColor.PrimaryGray,
  },
  subtitle: {
    marginBottom: theme.spacing(3),
  },
}));

export const CampaignGoalsForm: React.FC = () => {
  const classes = useStyles();

  const { isCreatingCampaign, campaignType } = useCampaignRegistration();

  const renderFundraisingCardInput = () => (
    <Grid item>
      <CardInput
        title="Fundraising"
        icon={MonetizationOnOutlined}
        input={
          <Field
            label="Amount (USD)"
            name={CampaignGoal.fundraising}
            component={NumberField}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            numberFormat={BASE_NUMBER_FORMAT}
            required
            disabled={isCreatingCampaign}
          />
        }
      />
    </Grid>
  );

  const renderFundraisersCardInput = () => (
    <Grid item>
      <CardInput
        title="Fundraisers"
        icon={Face}
        input={
          <Field
            label="Fundraisers"
            name={CampaignGoal.fundraiser}
            component={NumberField}
            disabled={isCreatingCampaign}
            numberFormat={BASE_NUMBER_FORMAT}
          />
        }
      />
    </Grid>
  );

  const renderAttendeesCardInput = () => (
    <Grid item>
      <CardInput
        title="Attendees"
        icon={Face}
        input={
          <Field
            label="Attendees"
            name={CampaignGoal.attendanceFundraisers}
            disabled={isCreatingCampaign}
            component={NumberField}
            numberFormat={BASE_NUMBER_FORMAT}
          />
        }
      />
    </Grid>
  );

  const renderTeamsCardInput = () => (
    <Grid item>
      <CardInput
        title="Teams"
        icon={Group}
        input={
          <Field
            label="Teams"
            name={CampaignGoal.team}
            component={NumberField}
            numberFormat={BASE_NUMBER_FORMAT}
            disabled={isCreatingCampaign}
          />
        }
      />
    </Grid>
  );

  const renderDonorsCardInput = () => (
    <Grid item>
      <CardInput
        title="Donors"
        icon={Group}
        input={
          <Field
            label="Donors"
            name={CampaignGoal.donor}
            component={NumberField}
            numberFormat={BASE_NUMBER_FORMAT}
            disabled={isCreatingCampaign}
          />
        }
      />
    </Grid>
  );

  const renderGoals = () => {
    switch (campaignType) {
      case CampaignType.registrationAndTicketing: {
        return (
          <React.Fragment>
            {renderFundraisingCardInput()}
            {renderAttendeesCardInput()}
            {renderTeamsCardInput()}
          </React.Fragment>
        );
      }
      case CampaignType.personalMission: {
        return (
          <React.Fragment>
            {renderFundraisingCardInput()}
            {renderFundraisersCardInput()}
            {renderTeamsCardInput()}
          </React.Fragment>
        );
      }
      case CampaignType.impactGiving: {
        return (
          <React.Fragment>
            {renderFundraisingCardInput()}
            {renderDonorsCardInput()}
          </React.Fragment>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="subtitle2" className={classes.title}>
        Campaign Goals
      </Typography>
      <Typography variant="body2" className={classes.subtitle}>
        Set and track your goals. A goal without a plan is just a dream!
      </Typography>

      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        {renderGoals()}
      </Grid>
    </div>
  );
};
