import {
  concatDateWithTime,
  getMaxDateTime,
  getMinDateTime,
  isDateEquals,
  toDateTimeFromISO,
  trimTime,
} from '@vizsla/utils';

interface GetPricingTierTimingLimitsArgs {
  minDateTimeValue: string | null | undefined;
  maxDateTimeValue: string | null | undefined;
  currentStartDate: string | null | undefined;
  currentStartTime: string | null | undefined;
  currentEndDate: string | null | undefined;
  currentEndTime: string | null | undefined;
}
export const getPricingTierTimingLimits = ({
  minDateTimeValue,
  maxDateTimeValue,
  currentStartDate,
  currentStartTime,
  currentEndDate,
  currentEndTime,
}: GetPricingTierTimingLimitsArgs) => {
  const currentStartDateTime = concatDateWithTime(currentStartDate, currentStartTime);
  const currentEndDateTime = concatDateWithTime(currentEndDate, currentEndTime);

  const actualMinStartDateTime = minDateTimeValue;
  const actualMaxStartDateTime = getMinDateTime(currentEndDateTime, maxDateTimeValue);

  const actualMinEndDateTime = getMaxDateTime(currentStartDateTime, minDateTimeValue);
  const actualMaxEndDateTime = maxDateTimeValue;

  const isActualMinStartDateEqualsCurrentStartDate = isDateEquals(
    trimTime(minDateTimeValue),
    trimTime(currentStartDate),
  );
  const minStartTime = isActualMinStartDateEqualsCurrentStartDate ? actualMinStartDateTime : null;

  const isActualMaxStartDateEqualsCurrentStartDate = isDateEquals(
    trimTime(actualMaxStartDateTime),
    trimTime(currentStartDate),
  );
  const maxStartTime = isActualMaxStartDateEqualsCurrentStartDate ? actualMaxStartDateTime : null;

  const isActualMinEndDateEqualsCurrentEndDate = isDateEquals(
    trimTime(actualMinEndDateTime),
    trimTime(currentEndDate),
  );
  const minEndTime = isActualMinEndDateEqualsCurrentEndDate ? actualMinEndDateTime : null;

  const isActualMaxEndDateEqualsCurrentEndDate = isDateEquals(
    trimTime(actualMaxEndDateTime),
    trimTime(currentEndDate),
  );
  const maxEndTime = isActualMaxEndDateEqualsCurrentEndDate ? actualMaxEndDateTime : null;

  return {
    actualMinStartDateTime: toDateTimeFromISO(actualMinStartDateTime),
    actualMaxStartDateTime: toDateTimeFromISO(actualMaxStartDateTime),
    minStartTime: toDateTimeFromISO(minStartTime),
    maxStartTime: toDateTimeFromISO(maxStartTime),
    actualMinEndDateTime: toDateTimeFromISO(actualMinEndDateTime),
    actualMaxEndDateTime: toDateTimeFromISO(actualMaxEndDateTime),
    minEndTime: toDateTimeFromISO(minEndTime),
    maxEndTime: toDateTimeFromISO(maxEndTime),
  };
};
