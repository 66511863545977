import React from 'react';
import { Navigate } from 'react-router-dom';

import { buildUrl } from '@vizsla/utils';

import * as entitiesId from 'src/constants/entities-id';
import * as appRoutes from 'src/constants/routes';
import { useStoreAssetId } from 'src/hooks/storeAssets';

const StoreAssetManagementIndex: React.FC = () => {
  const storeAssetId = useStoreAssetId();

  const defaultUrl = buildUrl(appRoutes.storeAssetsAssetDetails, {
    [entitiesId.storeAsset]: storeAssetId,
  });

  return <Navigate to={defaultUrl} />;
};

export { StoreAssetManagementIndex };
