import React from 'react';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import { Grid, IconButton } from '@mui/material';
import { FieldArray } from 'react-final-form-arrays';
import { AddSharp as AddIcon, ClearSharp as ClearIcon } from '@mui/icons-material';

import { BottomActionButton, RadioGroupField, Field } from '@vizsla/components';
import {
  CAMPAIGN_QUESTION_INPUT_TYPE_OPTIONS,
  CustomQuestionFormatLabels,
} from '@vizsla/constants';
import { CampaignQuestionInputType, CampaignQuestionFormat } from '@vizsla/types';

import { SelectField } from './SelectField';
import { TextField } from './TextField';

type CampaignCustomQuestionTypeFieldProps = {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  mutators: Record<string, (...args: any[]) => any>;
  label?: React.ReactNode;
  disabled?: boolean;
};

type GetFormParamsProps = {
  showOptionsField: boolean;
  inputFormatOptions: Array<{ value: CampaignQuestionFormat; label: string }> | null;
};

const getInputFormats = (type: CampaignQuestionInputType): Array<CampaignQuestionFormat> | null => {
  switch (type) {
    case CampaignQuestionInputType.text: {
      return [
        CampaignQuestionFormat.singleLine,
        CampaignQuestionFormat.multiLine,
        CampaignQuestionFormat.number,
        CampaignQuestionFormat.money,
        CampaignQuestionFormat.email,
        CampaignQuestionFormat.phone,
      ];
    }
    case CampaignQuestionInputType.date: {
      return [CampaignQuestionFormat.date, CampaignQuestionFormat.dateTime];
    }
    case CampaignQuestionInputType.select: {
      return [CampaignQuestionFormat.singleChoice, CampaignQuestionFormat.multipleChoice];
    }
    case CampaignQuestionInputType.checkbox:
    case CampaignQuestionInputType.radio:
    case CampaignQuestionInputType.switch:
    default:
      return null;
  }
};

const getFormParams = (value: CampaignQuestionInputType): GetFormParamsProps => {
  const showOptionsField =
    value === CampaignQuestionInputType.radio ||
    value === CampaignQuestionInputType.select ||
    value === CampaignQuestionInputType.checkbox ||
    value === CampaignQuestionInputType.switch;

  const inputFormats = getInputFormats(value);

  const inputFormatOptions = inputFormats
    ? inputFormats.map(format => ({
        value: format,
        label: CustomQuestionFormatLabels[format],
      }))
    : null;

  return { showOptionsField, inputFormatOptions };
};

export const CampaignCustomQuestionTypeField: React.FC<CampaignCustomQuestionTypeFieldProps> = ({
  label,
  input,
  meta,
  mutators,
  disabled = false,
  ...rest
}) => {
  const { name, value } = input;
  const { push } = mutators;

  const { showOptionsField, inputFormatOptions } = getFormParams(value);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          name={name}
          label={label}
          component={SelectField}
          options={CAMPAIGN_QUESTION_INPUT_TYPE_OPTIONS}
          fullWidth
          required
          disabled={disabled}
          {...rest}
        />
      </Grid>
      {showOptionsField && (
        <FieldArray name="options">
          {({ fields }) =>
            fields.map((name, index) => (
              <Grid item xs={12} key={name}>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <Field
                      name={name}
                      fullWidth
                      label={`Option ${index + 1}`}
                      component={TextField}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={2} justifyContent="center" display="flex">
                    <IconButton onClick={() => fields.remove(index)} size="large">
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))
          }
        </FieldArray>
      )}
      {showOptionsField && (
        <Grid item xs={12}>
          <BottomActionButton onClick={() => push('options', undefined)} icon={<AddIcon />}>
            Add Option
          </BottomActionButton>
        </Grid>
      )}
      {inputFormatOptions && (
        <Grid item xs={12}>
          <Grid>Format</Grid>
          <Field
            name="format"
            component={RadioGroupField}
            options={inputFormatOptions}
            optionContainerProps={{ xs: 6 }}
            disabled={disabled}
            fullWidth
          />
        </Grid>
      )}
    </Grid>
  );
};
