import React from 'react';
import { Box, Theme } from '@mui/material';
import {
  EventOutlined,
  PersonOutlined,
  OfflineBoltOutlined,
  MonetizationOn,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@apollo/client';

import { VizslaTotalMetricsQuery } from '@vizsla/graphql';

import { Display, JustifyContent } from 'src/theme';
import { InformationCard } from 'src/components/shared';
import { VIZSLA_TOTAL_METRICS_QUERY } from 'src/graphql';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    display: Display.Flex,
    flexDirection: 'row',
    '&>*:not(:first-child):not(:last-child)': {
      margin: theme.spacing(0, 1),
    },
    '&>*:first-child': {
      marginRight: theme.spacing(1),
    },
    '&>*:last-child': {
      marginLeft: theme.spacing(1),
    },
  },
  circular: {
    display: Display.Flex,
    justifyContent: JustifyContent.Center,
    width: '100%',
  },
}));

export const AdminOrganizationsInfoCards: React.FC = () => {
  const classes = useStyles();

  const { data, loading } = useQuery<VizslaTotalMetricsQuery>(VIZSLA_TOTAL_METRICS_QUERY);

  const organizationsCount = data?.vizslaOrganizationsList.count;
  const peopleCount = data?.usersList.count;
  const campaignsCount = data?.campaignsList.count;
  const experiencesCount = data?.experiencesList.count;

  return (
    <Box className={classes.root}>
      <InformationCard
        icon={<EventOutlined color="secondary" fontSize="large" />}
        title="Organizations"
        value={organizationsCount}
        loading={loading}
      />
      <InformationCard
        icon={<EventOutlined color="secondary" fontSize="large" />}
        title="People"
        value={peopleCount}
        loading={loading}
      />
      <InformationCard
        icon={<OfflineBoltOutlined color="secondary" fontSize="large" />}
        title="Campaigns"
        value={campaignsCount}
        loading={loading}
      />
      <InformationCard
        icon={<PersonOutlined color="secondary" fontSize="large" />}
        title="Experiences"
        value={experiencesCount}
        loading={loading}
      />
      {/* todo: Replace with db value */}
      <InformationCard
        icon={<MonetizationOn color="secondary" fontSize="large" />}
        title="Raised"
        value={0}
        loading={loading}
      />
    </Box>
  );
};
