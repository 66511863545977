import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { CompanyAvatarFullNameInline, TableColumn, TableData } from '@vizsla/components';
import { NO_APPLY_LABEL } from '@vizsla/constants';
import { buildUrl } from '@vizsla/utils';

import * as appRoutes from 'src/constants/routes';

import { usePartnershipQuery } from './usePartnershipQuery';

function sumDonations(donations) {
  return donations.reduce((total, donation) => total + donation.amount, 0);
}
const renderRowText = (text?: string) => {
  return <Typography>{text || NO_APPLY_LABEL}</Typography>;
};

const PARTNERSHIPS_TABLE_COLUMNS: Array<TableColumn> = [
  {
    title: 'Name',
    key: 'fullNameAvatar',
    dataPath: 'fullNameAvatar',
    render: row => {
      return !row ? (
        renderRowText(undefined)
      ) : (
        <CompanyAvatarFullNameInline name={row?.name} avatar={row?.avatar} />
      );
    },
  },

  {
    title: 'Type',
    key: 'type',
    dataPath: 'type',
    render: renderRowText,
  },
  {
    title: 'Commitments',
    key: 'Commitments',
    dataPath: 'donations',
    render: row => {
      return renderRowText(row?.items?.length || NO_APPLY_LABEL);
    },
  },
  {
    title: 'Total amount',
    key: 'donations',
    dataPath: 'donations',
    render: row => {
      return <Typography>{row?.items ? `$${sumDonations(row.items)}` : NO_APPLY_LABEL}</Typography>;
    },
  },
];
export function PartnershipsTableView() {
  const { loading, pagination, partnerships } = usePartnershipQuery();
  const navigate = useNavigate();

  return (
    <TableData
      dataSource={partnerships}
      columns={PARTNERSHIPS_TABLE_COLUMNS}
      rowActions={[]}
      emptyMessage="There are no Partnerships in the organization"
      loading={loading}
      pagination={pagination}
      onRowClick={e => {
        return navigate(
          buildUrl(appRoutes.partnershipDetails, {
            partnershipId: e.id as string,
          }),
        );
      }}
    />
  );
}
