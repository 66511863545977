export var StripeIntegrationStatus;
(function (StripeIntegrationStatus) {
    StripeIntegrationStatus["Connected"] = "Connected";
    StripeIntegrationStatus["Disconnected"] = "Disconnected";
})(StripeIntegrationStatus || (StripeIntegrationStatus = {}));
export var StripeCardFieldName;
(function (StripeCardFieldName) {
    StripeCardFieldName["Cvc"] = "cvc";
    StripeCardFieldName["Expiry"] = "expiry";
    StripeCardFieldName["Number"] = "number";
})(StripeCardFieldName || (StripeCardFieldName = {}));
