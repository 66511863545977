import React from 'react';
import { Grid, List, ListItem } from '@mui/material';

import { useLandingState, ExperienceLandingActions } from '@vizsla/providers';
import { ThemeColorLabels } from '@vizsla/constants';

import { ColorInputField, ColorLibraryField } from '../shared';

export const ThemeColorsSection = () => {
  const { state, dispatch } = useLandingState();

  return (
    <List component="div" disablePadding>
      <ListItem disablePadding sx={{ paddingBottom: '8px' }}>
        <Grid container spacing={1}>
          <Grid item>
            <ColorLibraryField
              color={state.theme.accentColor || ''}
              colorLibrary={state.theme.colorLibrary || []}
              onChange={color =>
                dispatch({ type: ExperienceLandingActions.updateAccentColor, payload: color })
              }
              onChangeLibrary={colorLibrary =>
                dispatch({
                  type: ExperienceLandingActions.updateColorLibrary,
                  payload: colorLibrary,
                })
              }
              label={ThemeColorLabels.accent}
            />
          </Grid>
          <Grid item>
            <ColorInputField
              color={state.theme.accentColor || ''}
              onChange={color =>
                dispatch({ type: ExperienceLandingActions.updateAccentColor, payload: color })
              }
            />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem disablePadding sx={{ paddingBottom: '8px' }} divider>
        <Grid container spacing={1}>
          <Grid item>
            <ColorLibraryField
              color={state.theme.progressBarEnd || ''}
              colorLibrary={state.theme.colorLibrary || []}
              onChange={color =>
                dispatch({ type: ExperienceLandingActions.updateProgressBarColor, payload: color })
              }
              onChangeLibrary={colorLibrary =>
                dispatch({
                  type: ExperienceLandingActions.updateColorLibrary,
                  payload: colorLibrary,
                })
              }
              label={ThemeColorLabels.progressBar}
            />
          </Grid>
          <Grid item>
            <ColorInputField
              color={state.theme.progressBarEnd || ''}
              onChange={color =>
                dispatch({ type: ExperienceLandingActions.updateProgressBarColor, payload: color })
              }
            />
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );
};
