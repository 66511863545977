import React from 'react';
import { Grid, Button, Autocomplete, TextField, Icon } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const setVariant = variant => {
  const mapVariants = Object.entries(variant).filter(entry => entry[0] != 'id' && entry[0]);
  return mapVariants[0][1] as string[];
};

const AtributesField = ({ variant, setVariantValue, removeVariant }) => {
  const variantName = React.useMemo(
    () => Object.keys(variant).filter(name => name !== 'id' && name !== 'isDeletable')[0],
    [variant],
  );
  const [options, setOptions] = React.useState<Array<string>>(setVariant(variant));
  const [preOptions] = React.useState<Array<string>>(setVariant(variant));

  const setData = (value: string[]) => {
    const optionCopy = [...value];
    setOptions(optionCopy);
    setVariantValue(variant, variantName, optionCopy);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
        <Grid xs={11} mr="4px">
          <Autocomplete
            multiple
            freeSolo
            autoComplete={false}
            options={options}
            onChange={(event, value, reason) => setData(value)}
            renderInput={params => <TextField {...params} label={`${variantName} atribute`} />}
            defaultValue={preOptions}
          />
        </Grid>
        {variantName !== 'Size' && variantName !== 'Style' && (
          <Icon>
            <HighlightOffIcon color="secondary" onClick={() => removeVariant(variant)} />
          </Icon>
        )}
      </Grid>
    </React.Fragment>
  );
};

export { AtributesField };
