import { useQuery } from '@apollo/client';
import React from 'react';

import { Donor, DonorsListQuery, DonorsListQueryVariables } from '@vizsla/graphql';

import { DONOR_LIST_QUERY } from 'src/graphql';

interface UseDonorsProps {
  campaignId: string;
  customFilter?: Record<string, unknown>;
  queryVariables?: DonorsListQueryVariables;
}
export const useDonors = ({ campaignId, customFilter, queryVariables = {} }: UseDonorsProps) => {
  const filterByCampaignId = {
    campaign: {
      id: {
        equals: campaignId,
      },
    },
  };

  const filter = customFilter ?? filterByCampaignId;

  const { data, loading } = useQuery<DonorsListQuery, DonorsListQueryVariables>(DONOR_LIST_QUERY, {
    variables: {
      filter,
      ...queryVariables,
    },
    skip: !campaignId,
  });

  const donors = React.useMemo(() => (data?.donorsList?.items || []) as Donor[], [data]);

  const count = React.useMemo(() => data?.donorsList?.count || 0, [data]);

  return {
    donors,
    loading,
    count,
  };
};
