import styled from '@emotion/styled';
import { Skeleton as SkeletonBase, Typography } from '@mui/material';
import { HelpOutline as HelpIconBase } from '@mui/icons-material';

import { BorderRadius, PaletteColor, Shadow } from '@vizsla/theme';

/// Container

const CONTAINER_SPACING = 1;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${Shadow.Elevation1};
  padding: ${p => p.theme.spacing(2)};
  gap: ${p => p.theme.spacing(2)};
  border-radius: ${BorderRadius.S};
`;

/// Skeleton

export const Skeleton = styled(SkeletonBase)`
  height: 10rem;
  border-radius: ${BorderRadius.S};
`;

Skeleton.defaultProps = {
  variant: 'rectangular',
};

/// Rows

export const Rows = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/// Section

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(CONTAINER_SPACING)};
`;

/// Header

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(0.5)};
  color: ${p => p.theme.palette.text.primary};
`;

/// Label

export const LabelText = styled(Typography)`
  font-weight: ${p => p.theme.typography.fontWeightBold};
  text-transform: uppercase;
`;

LabelText.defaultProps = {
  variant: 'caption',
};

/// ValueText

export const ValueText = styled(Typography)`
  font-weight: ${p => p.theme.typography.fontWeightBold};
  font-size: ${p => p.theme.typography.h2.fontSize};
`;

ValueText.defaultProps = {
  variant: 'body1',
};

/// HelpIcon

export const HelpIcon = styled(HelpIconBase)`
  color: ${PaletteColor.GrayText};
  font-size: ${p => p.theme.spacing(2)};
  cursor: pointer;
`;

/// ExtraGrid

export const ExtraGrid = styled.div`
  display: flex;
  gap: ${p => p.theme.spacing(CONTAINER_SPACING)};
  flex-wrap: wrap;
`;

/// ExtraContainer

export const ExtraContainer = styled.div`
  display: flex;
  min-width: 5rem;
  flex-direction: column;
  flex: 1;
`;

/// ExtraLabelText

export const ExtraLabelText = styled(Typography)`
  text-transform: uppercase;
  color: ${PaletteColor.GrayText};
`;

ExtraLabelText.defaultProps = {
  variant: 'caption',
};

/// ExtraValueText

export const ExtraValueText = styled(Typography)`
  font-weight: ${p => p.theme.typography.fontWeightBold};
`;

ExtraValueText.defaultProps = {
  variant: 'body2',
};
