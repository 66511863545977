import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Link, Tabs, Tab, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Donation,
  ImpactGivingRecentActivityQuery,
  ImpactGivingRecentActivityQueryVariables,
} from '@vizsla/graphql';

import { PaperBlock } from 'src/components/shared';
import { CampaignIGRecentDonationsTable } from 'src/components/campaign';
import { IMPACT_GIVING_RECENT_ACTIVITY_QUERY } from 'src/graphql';
import {
  ImpactGivingRecentActivityTab,
  CampaignDetailItem,
  PeopleTablesTab,
} from 'src/types/campaign';
import { FontSize, PaletteColor } from 'src/theme';

const useStyles = makeStyles(() => ({
  showSummary: {
    color: PaletteColor.GrayText,
  },
  viewAllLink: {
    fontSize: FontSize.Default,
  },
}));

interface ImpactGivingRecentActivityProps {
  campaign: CampaignDetailItem;
}

const PEOPLE_TABLES_MAP = {
  [ImpactGivingRecentActivityTab.Donations]: PeopleTablesTab.Donors,
};

export const ImpactGivingRecentActivity: React.FC<ImpactGivingRecentActivityProps> = ({
  campaign,
}) => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = React.useState<ImpactGivingRecentActivityTab>(
    ImpactGivingRecentActivityTab.Donations,
  );

  const onChangeActiveTab = (_: React.ChangeEvent<any>, newTab: ImpactGivingRecentActivityTab) => {
    setActiveTab(newTab);
  };

  const { data: recentActivity, loading } = useQuery<
    ImpactGivingRecentActivityQuery,
    ImpactGivingRecentActivityQueryVariables
  >(IMPACT_GIVING_RECENT_ACTIVITY_QUERY, {
    variables: {
      id: campaign?.id as string,
    },
    skip: !campaign?.id,
  });

  const recentDonations = React.useMemo(
    () => recentActivity?.campaign?.donations?.items ?? [],
    [recentActivity],
  );
  const donationsCount = recentDonations.length;
  const totalDonations = recentActivity?.campaign?.donations?.count ?? 0;

  const tableData = React.useMemo(() => {
    const tableDataMap = {
      [ImpactGivingRecentActivityTab.Donations]: {
        dataSource: recentDonations,
        showing: donationsCount,
        total: totalDonations,
        rowActions: [
          {
            actionName: 'View/Edit',
            onAction: () => {},
          },
          {
            actionName: 'Send Text Message',
            onAction: () => {},
          },
          {
            actionName: 'Call a Team Member',
            onAction: () => {},
          },
          {
            actionName: 'Compose an Email',
            onAction: () => {},
          },
          {
            actionName: 'Add a Note',
            onAction: () => {},
          },
        ],
      },
    };

    return tableDataMap[activeTab];
  }, [activeTab, donationsCount, recentDonations, totalDonations]);

  const recentActivityTable = React.useMemo(() => {
    switch (activeTab) {
      case ImpactGivingRecentActivityTab.Donations: {
        return (
          <CampaignIGRecentDonationsTable
            loading={loading}
            dataSource={tableData.dataSource as Donation[]}
            rowActions={tableData.rowActions}
          />
        );
      }

      default: {
        return null;
      }
    }
  }, [activeTab, loading, tableData.dataSource, tableData.rowActions]);

  const showingSummary = `Showing ${tableData.showing} ${activeTab} of ${tableData.total}`;

  return (
    <React.Fragment>
      <PaperBlock title="Recent Activity">
        <Tabs centered value={activeTab} onChange={onChangeActiveTab}>
          <Tab
            label={ImpactGivingRecentActivityTab.Donations}
            value={ImpactGivingRecentActivityTab.Donations}
          />
        </Tabs>

        {recentActivityTable}

        {!loading && (
          <React.Fragment>
            <Typography variant="caption" className={classes.showSummary}>
              {showingSummary}
            </Typography>
            <Box display="flex" justifyContent="center">
              <Link
                component={RouterLink}
                to={`/campaigns/${campaign?.id}/people?tab=${PEOPLE_TABLES_MAP[activeTab]}`}
                className={classes.viewAllLink}
              >
                View All
              </Link>
            </Box>
          </React.Fragment>
        )}
      </PaperBlock>
    </React.Fragment>
  );
};
