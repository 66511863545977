import React from 'react';
import { Grid } from '@mui/material';

import { CampaignMetricsCards } from 'src/components/campaign';
import { CampaignDetailItem } from 'src/types/campaign';

import { ImpactGivingRecentActivity } from './ImpactGivingRecentActivity';

interface DashboardProps {
  campaign: CampaignDetailItem;
}

export const ImpactGivingCampaignDashboard: React.FC<DashboardProps> = ({ campaign }) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container alignItems="flex-start" spacing={4}>
          <CampaignMetricsCards />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ImpactGivingRecentActivity campaign={campaign} />
      </Grid>
    </React.Fragment>
  );
};
