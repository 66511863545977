import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { Button as ButtonBase } from '@vizsla/components';

/// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};
`;

/// Content

export const ContentLoading = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${p => p.theme.spacing(1)};
  gap: ${p => p.theme.spacing(1)};
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${p => p.theme.spacing(1)};
`;
/// Wrapper

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(1)};
`;

/// Label

export const Label = styled(Typography)`
  text-transform: uppercase;
  font-weight: ${p => p.theme.typography.fontWeightBold};
`;

Label.defaultProps = {
  variant: 'caption',
};

/// Title

export const Title = styled(Typography)``;

Title.defaultProps = {
  variant: 'h2',
};

/// Paragraph

export const Paragraph = styled(Typography)`
  color: ${p => p.theme.palette.grey[500]};
`;

/// Button

export const Button = styled(ButtonBase)`
  margin: 0;
`;

Button.defaultProps = {
  variant: 'contained',
};

export const Subtitle = styled(Typography)`
  font-weight: ${p => p.theme.typography.fontWeightBold};
`;
