import React from 'react';
import { Grid, Tab, Tabs } from '@mui/material';

import { ContactInfoTabType } from 'src/types/peopleCRM';

import { ContactProfile } from './ContactProfile';
import { ActivityPage } from './ActivityPage';
import { ContactTeams } from './ContactTeams';

type TabData = {
  key: ContactInfoTabType;
  label: string;
};

const TABS: TabData[] = [
  {
    key: ContactInfoTabType.profile,
    label: 'PROFILE',
  },
  {
    key: ContactInfoTabType.activity,
    label: 'ACTIVITY',
  },
  {
    key: ContactInfoTabType.impact,
    label: 'IMPACT',
  },
  {
    key: ContactInfoTabType.donations,
    label: 'DONATIONS',
  },
  {
    key: ContactInfoTabType.teams,
    label: 'TEAMS',
  },
];

const MAP_CONTACT_INFO_PAGES_TO_TAB_TYPES: { [key in ContactInfoTabType]: React.ReactElement } = {
  [ContactInfoTabType.profile]: <ContactProfile />,
  [ContactInfoTabType.activity]: (
    <Grid item>
      <ActivityPage />
    </Grid>
  ),
  [ContactInfoTabType.impact]: <Grid item>Impact page</Grid>,
  [ContactInfoTabType.donations]: <Grid item>Dontaions page</Grid>,
  [ContactInfoTabType.teams]: <ContactTeams />,
};

const ContactInfoTabs: React.FC = () => {
  const defaultTab = TABS[0];

  const [currentTab, setCurrentTab] = React.useState(defaultTab.key);

  const handleTabChange = (_, tabId: ContactInfoTabType) => {
    setCurrentTab(tabId);
  };

  return (
    <React.Fragment>
      <Grid item justifyContent="center">
        <Tabs value={currentTab} onChange={handleTabChange}>
          {TABS.map(tab => (
            <Tab key={tab.key} value={tab.key} label={tab.label} />
          ))}
        </Tabs>
      </Grid>

      <Grid container item spacing={5} direction="column">
        {MAP_CONTACT_INFO_PAGES_TO_TAB_TYPES[currentTab]}
      </Grid>
    </React.Fragment>
  );
};

export { ContactInfoTabs };
