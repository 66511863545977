import React from 'react';
import { Grid } from '@mui/material';

import { useNotification } from '@vizsla/hooks';

import { useExperienceStoreSettings } from 'src/hooks/storeAssets';
import { t } from 'src/utils/template';
import { ExperienceStoreDataProvider } from 'src/providers/storeAssets';
import { ExperienceStoreAssetsTable } from 'src/components/storeAssets';
import {
  ExperienceStoreAssetAddModal,
  ExperienceStoreAssetUpdateModal,
  ExperienceStoreAssetDeleteModal,
} from 'src/modals/storeAssets';
import { PaperBlock } from 'src/components/shared';

interface ExperienceStoreSettingsProps {
  experienceId: string;
}

const ExperienceStoreSettings: React.FC<ExperienceStoreSettingsProps> = ({ experienceId }) => {
  const { experienceStoreSettings, updateExperienceStoreSettings, updating } =
    useExperienceStoreSettings(experienceId);

  const notification = useNotification();

  const handleSwitchIsStoreEnabled = React.useCallback(
    async (checked: boolean) => {
      try {
        await updateExperienceStoreSettings({
          isStoreEnabled: checked,
        });
      } catch (error) {
        console.error(error);
        notification.error(t('experience_store_enable_error'));
      }
    },
    [updateExperienceStoreSettings, notification],
  );

  const isStoreEnabled = Boolean(experienceStoreSettings?.isStoreEnabled);

  return (
    <ExperienceStoreDataProvider>
      <PaperBlock
        loading={updating}
        title="Store"
        switchProps={{
          checked: isStoreEnabled,
          onSwitch: handleSwitchIsStoreEnabled,
        }}
        defaultChecked={isStoreEnabled}
        collapsing
      >
        <Grid container>
          <Grid item xs={12}>
            <ExperienceStoreAssetsTable isStoreEnabled={isStoreEnabled} />
          </Grid>
        </Grid>
      </PaperBlock>

      <ExperienceStoreAssetAddModal />
      <ExperienceStoreAssetUpdateModal />
      <ExperienceStoreAssetDeleteModal />
    </ExperienceStoreDataProvider>
  );
};

export { ExperienceStoreSettings };
