import React from 'react';

import {
  ActionButtonBackgroundType,
  ACTION_BUTTON_BACKGROUND_TYPE_OPTIONS,
} from '@vizsla/constants';
import { FontSize } from '@vizsla/theme';

import { Select } from '../../shared';

interface ActionButtonBackgroundTypeFieldProps {
  onChange: (backgroundType: ActionButtonBackgroundType) => void;
  backgroundType: ActionButtonBackgroundType;
}

export const ActionButtonBackgroundTypeField: React.FC<ActionButtonBackgroundTypeFieldProps> = ({
  onChange,
  backgroundType,
}) => {
  return (
    <Select
      options={ACTION_BUTTON_BACKGROUND_TYPE_OPTIONS}
      onChange={event => onChange(event?.target?.value as ActionButtonBackgroundType)}
      value={backgroundType}
      sx={{
        fontSize: FontSize.Default,
        width: '107px',
        height: '40px',
      }}
    />
  );
};
