import React from 'react';

import { addSlugToURL } from '@vizsla/utils';
import { useClient } from '@vizsla/hooks';

type FullPublicWebLinkHookResult = {
  fullPublicWebLink: string | undefined;
  getFullPublicWebLink: (slug?: string) => string;
};

export const useFullPublicWebLink = (slug?: string): FullPublicWebLinkHookResult => {
  const { clientsUrl } = useClient();

  const getFullPublicWebLink = (slug?: string) => {
    if (!clientsUrl.publicClientUrl || !slug) return '';
    return addSlugToURL(clientsUrl.publicClientUrl, slug);
  };

  const fullPublicWebLink = React.useMemo(() => getFullPublicWebLink(slug), [slug]);

  return {
    fullPublicWebLink,
    getFullPublicWebLink,
  };
};
