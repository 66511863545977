import React from 'react';
import { Grid } from '@mui/material';
import { FormSpy } from 'react-final-form';

import { Field, CheckboxField, NumberField, RadioGroupField } from '@vizsla/components';

import { SwitchSectionField } from 'src/components/forms';
import { PricingTiersField } from 'src/components/experiencePricing';
import { PRICING_TYPE_SELECT_FIELD_OPTIONS } from 'src/constants/pricingSettings';

export const RegistrationOptionCreateFormStepThree: React.FC = () => {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values, form }) => {
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SwitchSectionField
                name="attendeesCapEnabled"
                heading="Attendee Cap"
                fieldsToResetOnUncheck={['maximumAttendees']}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="maximumAttendees"
                      label="Maximum Attendees"
                      component={NumberField}
                      fullWidth
                      disabled={!values.attendeesCapEnabled}
                    />
                  </Grid>
                </Grid>
              </SwitchSectionField>
            </Grid>
            <Grid item xs={12}>
              <SwitchSectionField name="pricingSettings.pricingEnabled" heading="Pricing">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="pricingSettings.pricingType"
                      component={RadioGroupField}
                      containerOptions={{ direction: 'row', container: true }}
                      options={PRICING_TYPE_SELECT_FIELD_OPTIONS}
                      disabled={!values.pricingSettings.pricingEnabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="pricingSettings.pricingTiers"
                      component={PricingTiersField}
                      mutators={form.mutators}
                      variant={values.pricingSettings.pricingType}
                      disabled={!values.pricingSettings.pricingEnabled}
                    />
                  </Grid>
                  {/* This section is disabled by current business logic */}
                  {/* <Grid item xs={12}>
                    <Field
                      name="pricingSettings.allowCostToBeFundraisingCredit"
                      label="Allow Registration Cost to be Fundraising Credit."
                      component={CheckboxField}
                      disabled={!values.pricingSettings.pricingEnabled}
                    />
                  </Grid> */}
                </Grid>
              </SwitchSectionField>
            </Grid>
          </Grid>
        );
      }}
    </FormSpy>
  );
};
