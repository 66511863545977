import React from 'react';
import { Skeleton } from '@mui/material';

import { formatDate, formatMoney } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';

import { useSelectedTransactionRow } from 'src/hooks/transactions';

import {
  Button,
  Container,
  Content,
  ContentLoading,
  Donation,
  Paragraph,
  Subtitle,
  Title,
  Wrapper,
} from './TransactionRegistrationCard.style';
import { TransactionsCardsLayout } from '../../TransactionsCardLayout';

export function TransactionRegistrationCard() {
  const { data: dataTransaction } = useSelectedTransactionRow();

  const formatData = React.useMemo(
    () => formatDate(dataTransaction?.OrderDate, DateFormatPatterns.shortMonthDayYear),
    [dataTransaction],
  );

  const formatAmount = React.useMemo(
    () => formatMoney(dataTransaction?.OrderItemsAmount),
    [dataTransaction],
  );

  if (!dataTransaction) {
    return (
      <TransactionsCardsLayout>
        <Container>
          <ContentLoading>
            <Skeleton variant="text" width={130} height={35} />
            <Wrapper>
              <Skeleton variant="text" width={200} height={35} />
            </Wrapper>
            <Skeleton variant="rectangular" width={143} height={64} />
          </ContentLoading>
        </Container>
      </TransactionsCardsLayout>
    );
  }

  return (
    <TransactionsCardsLayout active>
      <Container>
        <Wrapper>
          <Title variant="h4">Online Registration</Title>
        </Wrapper>
        <Wrapper>
          <Donation>{formatAmount}</Donation>
          <Button>Successful</Button>
        </Wrapper>
        <Content>
          <Wrapper>
            <Paragraph variant="subtitle2">Date and time:</Paragraph>
            <Subtitle>{formatData}</Subtitle>
          </Wrapper>
          <Wrapper>
            <Paragraph variant="subtitle2">Type:</Paragraph>
            <Subtitle> {dataTransaction?.Ordersubtype}</Subtitle>
          </Wrapper>
        </Content>
      </Container>
    </TransactionsCardsLayout>
  );
}
