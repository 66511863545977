import React from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';

import { Campaign, useCampaignListByOrganizationQuery } from '@vizsla/graphql';
import { useCurrentOrganization } from '@vizsla/hooks';
import { Field, SelectField } from '@vizsla/components';

import { AddOfflineDonationTeamSelectField } from './AddOfflineDonationTeamSelectField';
import { AddOfflineDonationFundraiserSelectField } from './AddOfflineDonationFundraiserSelectField';

const PROGRAMS_LIST_OPTIONS = [
  {
    value: 'General Funds',
    label: 'General Funds',
  },
];

export const AddOfflineDonationCampaignInfoStep: React.FC = () => {
  const { organizationId } = useCurrentOrganization();

  const { data: campaignsData, loading } = useCampaignListByOrganizationQuery({
    variables: {
      orgId: organizationId ?? '',
    },
    skip: !organizationId,
  });

  const campaignList = campaignsData?.campaignsList?.items || [];

  const campaignListOptions = campaignList.map((campaign: Campaign) => ({
    value: campaign.id || '',
    label: campaign.name || '',
  }));

  const defaultCampaign = campaignListOptions[0]?.value;

  const defaultProgram = PROGRAMS_LIST_OPTIONS[0]?.value;

  if (loading) {
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} direction="column">
      <Grid item xs={12}>
        <Field
          fullWidth
          name="campaignInfo.campaign"
          label="Campaign"
          required
          initialValue={defaultCampaign}
          options={campaignListOptions}
          component={SelectField}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">Select team or fundraiser:</Typography>
      </Grid>
      <Grid item xs={12}>
        <AddOfflineDonationTeamSelectField />
      </Grid>
      <Grid item xs={12} marginBottom={5}>
        <AddOfflineDonationFundraiserSelectField />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="campaignInfo.programDestination"
          label="Program Destination"
          required
          initialValue={defaultProgram}
          options={PROGRAMS_LIST_OPTIONS}
          component={SelectField}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
