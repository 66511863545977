import React from 'react';
import _ from 'lodash';

import { TableData } from '@vizsla/components';
import type { TableRowAction, TableColumn } from '@vizsla/components';
import { DateFormatPatterns } from '@vizsla/constants';
import { formatDate } from '@vizsla/utils';
import { User, TeamFundraising, Donation, CampaignTeamMember } from '@vizsla/graphql';

import { CampaignFundraiserGoalProgress } from 'src/components/campaign';
import { TeamAvatarWithNameInline } from 'src/components/user';
import { TeamRole } from 'src/types/team';
import { getUserFullName } from 'src/utils/user';

const TEAMS_TABLE_COLUMNS: TableColumn<TeamFundraising>[] = [
  {
    title: 'name',
    key: 'name',
    render: (valueByDataPath: undefined, team: TeamFundraising) => {
      return <TeamAvatarWithNameInline team={team} />;
    },
  },
  {
    title: 'creation date',
    key: 'creationDate',
    dataPath: 'createdAt',
    render: (createdAt: string) => {
      return formatDate(createdAt, DateFormatPatterns.shortMonthDayYear);
    },
  },
  {
    title: 'captain',
    key: 'captain',
    dataPath: 'members.items',
    render: (members: CampaignTeamMember[] = []) => {
      const teamCaptain = members.find(member => member?.role === TeamRole.TeamCaptain);
      return getUserFullName(teamCaptain?.attendee?.user as User);
    },
  },
  {
    title: 'members',
    key: 'members',
    dataPath: 'members.count',
    render: (count: string, team: TeamFundraising) => {
      const maxTeamCapacity =
        team?.campaign?.fundraisingSettings?.teamSettings?.maximumTeamMembers ?? 0;
      return `${count}/${maxTeamCapacity}`;
    },
  },
  {
    title: 'total raised',
    key: 'totalRaised',
    dataPath: 'donations.items',
    render: (donations: Donation[] = [], team: TeamFundraising) => {
      const fundraiserGoal = team?.fundraiserGoal ?? 0;
      const totalRaised = _.sumBy(donations, 'amount');

      return (
        <CampaignFundraiserGoalProgress totalRaised={totalRaised} fundraiserGoal={fundraiserGoal} />
      );
    },
  },
];

interface CampaignRTRecentTeamsTableProps {
  dataSource: TeamFundraising[];
  rowActions: TableRowAction[];
  loading: boolean;
}

export const CampaignRTRecentTeamsTable: React.FC<CampaignRTRecentTeamsTableProps> = ({
  dataSource,
  rowActions,
  loading,
}) => {
  return (
    <TableData
      dataSource={dataSource}
      columns={TEAMS_TABLE_COLUMNS}
      rowActions={rowActions}
      loading={loading}
    />
  );
};
