import React from 'react';
import { Grid, styled } from '@mui/material';
import { Form } from 'react-final-form';

import { useExperienceImages, useNotification } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { Modal, Field } from '@vizsla/components';

import { t } from 'src/utils/template';
import { ImageInputField } from 'src/components/forms';
import { ExperienceHeaderImagesCreateSchema } from 'src/constants/validationSchemas/experience';
import { useExperienceId } from 'src/hooks/experiences';

import { prepareImagesData } from '../utils';

const StyledFormNode = styled('form')(({ theme }) => ({
  display: 'contents',
}));

interface ExperienceHeaderImageCreateFormProps {
  closeModal: () => void;
}

/** @deprecated Changed by `VSL-1129`. */
export const ExperienceHeaderImageCreateForm: React.FC<ExperienceHeaderImageCreateFormProps> = ({
  closeModal,
}) => {
  const notification = useNotification();
  const experienceId = useExperienceId();

  const { createExperienceHeaderImages, updatingHeaderImages } = useExperienceImages(experienceId);

  const onSubmit = React.useCallback(
    async (formValues: Record<string, any>) => {
      const preparedData = prepareImagesData(formValues?.experienceHeaderImages);
      try {
        await createExperienceHeaderImages(preparedData);
        notification.success(t('experience_header_images_add_success'));
        closeModal();
      } catch (err) {
        notification.error(t('experience_header_images_add_error'));
      }
    },
    [closeModal, createExperienceHeaderImages, notification],
  );

  const validate = React.useCallback(async (formValues: Record<string, any>) => {
    return validateWithSchema(ExperienceHeaderImagesCreateSchema, formValues);
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting, pristine, validating, invalid }) => {
        const isSubmitLoading = submitting || validating || updatingHeaderImages;
        const isSubmitDisabled = isSubmitLoading || pristine || invalid;

        return (
          <StyledFormNode onSubmit={handleSubmit}>
            <Grid container alignItems="center" direction="column">
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="experienceHeaderImages"
                  label="New Images"
                  width={300}
                  height={200}
                  component={ImageInputField}
                  maxFiles={10}
                  imageDim={[1200, 400]}
                />
              </Grid>

              <Grid item xs={12} mt={2}>
                <Modal.Actions>
                  <Modal.Action
                    type="secondary"
                    content="Cancel"
                    onAction={closeModal}
                    disabled={isSubmitDisabled}
                  />

                  <Modal.Action
                    type="primary"
                    content="Save"
                    onAction={handleSubmit}
                    disabled={isSubmitDisabled}
                    loading={isSubmitLoading}
                  />
                </Modal.Actions>
              </Grid>
            </Grid>
          </StyledFormNode>
        );
      }}
    />
  );
};
