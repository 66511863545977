import React from 'react';
import { Box, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { calcPercentage, formatMoney, formatPercentage } from '@vizsla/utils';

import { DeterminateProgress } from 'src/components/shared';
import { Shade, PaletteColor } from 'src/theme';

const useStyles = makeStyles<Theme>(theme => ({
  progress: {
    margin: theme.spacing(0, 1),
  },
}));

const calculateFundraisingProgressColor = (value: number) => {
  if (value <= 0) {
    return Shade.Gray[200];
  }
  if (value < 20) {
    return PaletteColor.ErrorMain;
  }

  if (value < 60) {
    return PaletteColor.WarningProgress;
  }
  return PaletteColor.SuccessMain;
};

interface CampaignFundraiserGoalProgressProps {
  totalRaised: number;
  fundraiserGoal: number;
}

export const CampaignFundraiserGoalProgress: React.FC<CampaignFundraiserGoalProgressProps> = ({
  totalRaised = 0,
  fundraiserGoal = 0,
}) => {
  const classes = useStyles();
  const percentage = calcPercentage(totalRaised, fundraiserGoal);

  return (
    <Box display="flex" alignItems="center">
      <Typography>{formatMoney(totalRaised)}</Typography>
      <DeterminateProgress
        value={percentage}
        size={20}
        className={classes.progress}
        calculateColor={calculateFundraisingProgressColor}
        renderAfter={textColor => (
          <Typography color={textColor} variant="body2">
            {formatPercentage(percentage)}
          </Typography>
        )}
      />
    </Box>
  );
};
