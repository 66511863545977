export enum EditorCampaignPagesRoutes {
  EXPERIENCE = 'editorExperienceTemplate',
  INDIVIDUAL_FUNDRAISER = 'editorIndividualFundraiserTemplate',
  TEAM_FUNDRAISER = 'editorTeamFundraiserTemplate',
  DONATION = 'editorDonationTemplate',
  THANK_YOU = 'editorThankYouTemplate',
}

export enum CampaignPagesRoutesPerTemplate {
  EXPERIENCE = 'experience',
  INDIVIDUAL_FUNDRAISER = 'fundraiser',
  TEAM_FUNDRAISER = 'team',
  // todo: replace with experience donation page
  DONATION = 'fundraiserDonation',
  THANK_YOU = 'fundraiserDonationFinal',
}
