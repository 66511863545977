import React from 'react';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';
import { Grid, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DateFormatPatterns, USA_STATES_OPTIONS } from '@vizsla/constants';
import { useDnsRecordValidator } from '@vizsla/hooks';
import { getNowDate, toDateTimeFromISO } from '@vizsla/utils';
import {
  Field,
  TextField,
  DateField,
  SelectField,
  TimeField,
  GridDefaultPadding,
} from '@vizsla/components';
import { PaletteColor } from '@vizsla/theme';

import { useCampaignRegistration } from 'src/hooks/campaign';
import { CampaignTypeRadioGroup, CampaignPublicPageUrlSpyRender } from 'src/components/campaign';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  fieldContainer: {
    marginBottom: theme.spacing(2),
  },
  fieldBottomText: {
    color: PaletteColor.GrayText,
  },
}));

export const CampaignRegistrationFormOne: React.FC = () => {
  const classes = useStyles();

  const { campaignType, changeCampaignType } = useCampaignRegistration();
  const { validateCampaignName } = useDnsRecordValidator();

  const currentDate = toDateTimeFromISO(getNowDate());

  return (
    <div className={classes.root}>
      <Grid item xs={12} p={1}>
        <Typography variant="subtitle2">What kind of campaign are you launching?</Typography>
      </Grid>
      <Grid className={classes.fieldContainer} item xs={12}>
        <CampaignTypeRadioGroup
          defaultValue={campaignType}
          onChangeCampaignType={changeCampaignType}
        />
      </Grid>
      <Grid container item>
        <GridDefaultPadding className={classes.fieldContainer} item xs={12}>
          <Field
            label="Campaign Name"
            name="name"
            component={TextField}
            validate={validateCampaignName}
            fullWidth
            required
          />
          <CampaignPublicPageUrlSpyRender />
        </GridDefaultPadding>
        <GridDefaultPadding item xs={12} p={1}>
          <Typography variant="subtitle2">Where</Typography>
        </GridDefaultPadding>
        <GridDefaultPadding item xs={6}>
          <Field label="City" name="city" component={TextField} fullWidth required />
        </GridDefaultPadding>
        <GridDefaultPadding item xs={6}>
          <Field
            label="State"
            name="state"
            component={SelectField}
            options={USA_STATES_OPTIONS}
            fullWidth
            required
          />
        </GridDefaultPadding>
        <GridDefaultPadding item xs={12}>
          <Typography variant="subtitle2">When</Typography>
        </GridDefaultPadding>
        <Grid container item>
          <GridDefaultPadding item xs={6}>
            <Field
              label="Start Date"
              name="startDate"
              component={DateField}
              inputFormat={DateFormatPatterns.shortMonthDayYear}
              minDate={currentDate}
              fullWidth
              required
            />
          </GridDefaultPadding>
          <GridDefaultPadding item xs={6}>
            <Field label="Start Time" name="startTime" component={TimeField} fullWidth required />
          </GridDefaultPadding>
          <GridDefaultPadding item xs={6}>
            <FormSpy subscription={{ values: true }}>
              {(spyRenderProps: FormSpyRenderProps) => {
                const { startDate, endDate } = spyRenderProps.values;
                const minEndDate = toDateTimeFromISO(startDate || currentDate);

                if (startDate && !endDate) {
                  spyRenderProps.form.change('endDate', startDate);
                }

                return (
                  <Field
                    label="End Date"
                    name="endDate"
                    component={DateField}
                    inputFormat={DateFormatPatterns.shortMonthDayYear}
                    minDate={minEndDate}
                    fullWidth
                    required
                  />
                );
              }}
            </FormSpy>
          </GridDefaultPadding>
          <GridDefaultPadding item xs={6}>
            <Field label="End Time" name="endTime" component={TimeField} fullWidth required />
          </GridDefaultPadding>
        </Grid>
      </Grid>
    </div>
  );
};
