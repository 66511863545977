import React from 'react';
import { Skeleton } from '@mui/material';

import { TemplateType } from '@vizsla/types';

import { useCampaign } from 'src/hooks/campaign';

import { CampaignPageCardLayout } from './CampaignPageCardLayout';
import { CardOptionsExperience } from './CardOptionsExperience';
import { CardOptionsDonation } from './CardOptionsDonation';
import { CardOptionsIndividualFundraiser } from './CardOptionsIndividualFundraiser';
import { CardOptionsTeamFundraiser } from './CardOptionsTeamFundraiser';
import { CardOptionsThankYou } from './CardOptionsThankYou';

export interface CampaignPageCardProps {
  campaignID: string;

  title: string;
  description: string;
  type: TemplateType;
  imageURI?: string;
}

export function CampaignPageCard(props: CampaignPageCardProps) {
  const { data: campaign, loading } = useCampaign(props.campaignID);

  if (!campaign || loading) {
    return <Skeleton variant="rectangular" />;
  }

  if (props.type === TemplateType.EXPERIENCE) {
    return (
      <CampaignPageCardLayout {...props}>
        <CardOptionsExperience {...props} campaign={campaign} />
      </CampaignPageCardLayout>
    );
  }

  if (props.type === TemplateType.DONATION) {
    return (
      <CampaignPageCardLayout {...props}>
        <CardOptionsDonation {...props} />
      </CampaignPageCardLayout>
    );
  }

  if (props.type === TemplateType.INDIVIDUAL_FUNDRAISER) {
    return (
      <CampaignPageCardLayout {...props}>
        <CardOptionsIndividualFundraiser {...props} />
      </CampaignPageCardLayout>
    );
  }

  if (props.type === TemplateType.TEAM_FUNDRAISER) {
    return (
      <CampaignPageCardLayout {...props}>
        <CardOptionsTeamFundraiser {...props} />
      </CampaignPageCardLayout>
    );
  }

  if (props.type === TemplateType.THANK_YOU) {
    return (
      <CampaignPageCardLayout {...props}>
        <CardOptionsThankYou {...props} />
      </CampaignPageCardLayout>
    );
  }

  return null;
}
