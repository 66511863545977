import { DateTime } from 'luxon';

import { PricingTierFormAction, PricingType, TaxDeductibleType } from 'src/types/pricingSettings';

export const TAX_DEDUCTIBLE_OPTIONS = [
  {
    value: TaxDeductibleType.Percents,
    label: '% Percentage',
  },
  {
    value: TaxDeductibleType.Currency,
    label: '$ Currency',
  },
];

const NOW = DateTime.now().toISO();

export const INITIAL_PRICING_TIER = {
  price: 0,
  startDate: NOW,
  startTime: NOW,
  endDate: NOW,
  endTime: NOW,
  taxDeductibleCurrencyAmount: 0,
  taxDeductiblePercentsAmount: 0,
  taxDeductibleType: TaxDeductibleType.Percents,
  formAction: PricingTierFormAction.toCreate,
};

export const PRICING_TYPE_SELECT_FIELD_OPTIONS = [
  {
    label: 'Fixed Price',
    value: PricingType.Fixed,
  },
  {
    label: 'Scaled Price',
    value: PricingType.Scaled,
  },
];
