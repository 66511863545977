import React from 'react';
import { useMutation } from '@apollo/client';

import { useNotification, useDnsRecord, useCurrentOrganization } from '@vizsla/hooks';
import { Experience, Campaign } from '@vizsla/graphql';

import { CampaignType, CampaignStatus } from 'src/types/campaign';
import { CAMPAIGN_CREATE_MUTATION, EXPERIENCE_CREATE_MANY_MUTATION } from 'src/graphql';
import { t } from 'src/utils/template';
import { isRegistrationAndTicketing } from 'src/utils/campaign';

export interface CampaignRegistrationContextType {
  campaignType: CampaignType;
  changeCampaignType: (campaignType: CampaignType) => void;
  isCreatingCampaign: boolean;
  createCampaign: (campaignData: any, experiencesData?: Array<Experience>) => Promise<void>;
  createdCampaign: Campaign | null;
}

export const CampaignRegistrationContext = React.createContext<CampaignRegistrationContextType>(
  {} as CampaignRegistrationContextType,
);

interface CampaignRegistrationProviderProps {
  children: React.ReactNode;
}

export const CampaignRegistrationProvider: React.FC<CampaignRegistrationProviderProps> = ({
  children,
}) => {
  const notification = useNotification();

  const [campaignType, setCampaignType] = React.useState<CampaignType>(
    CampaignType.registrationAndTicketing,
  );

  const { organizationId } = useCurrentOrganization();

  const [createdCampaign, setCreatedCampaign] = React.useState<Campaign | null>(null);

  const { createDnsRecord } = useDnsRecord();

  const createDnsRecordByCampaignName = async (campaignName: string, campaignId: string) => {
    try {
      await createDnsRecord(campaignName, campaignId);
    } catch (error) {
      notification.error(t('dns_record_create_error'));
    }
  };

  const [createCampaignMutation, { loading: creatingCampaign }] = useMutation(
    CAMPAIGN_CREATE_MUTATION,
    {
      refetchQueries: ['CampaignsPage'],
      awaitRefetchQueries: true,
      onCompleted: campaignCreateResultData => {
        const createdCampaign = campaignCreateResultData?.campaignCreate;

        createDnsRecordByCampaignName(
          createdCampaign?.name as string,
          createdCampaign?.id as string,
        );
      },
    },
  );
  const [createExperiencesMutation, { loading: creatingExperiences }] = useMutation(
    EXPERIENCE_CREATE_MANY_MUTATION,
    {
      refetchQueries: ['CampaignsPage'],
      awaitRefetchQueries: true,
    },
  );

  const isCreatingCampaign = creatingCampaign || creatingExperiences;

  const createCampaign = async (campaignData: any, experiencesData?: Array<Experience>) => {
    try {
      const campaignResult = await createCampaignMutation({
        variables: {
          data: {
            ...campaignData,
            campaignStatus: CampaignStatus.draft,
            vizslaOrganization: {
              connect: {
                id: organizationId,
              },
            },
          },
        },
      });

      const createdCampaign = campaignResult?.data?.campaignCreate || null;
      const isRegistrationAndTicketingCampaign = isRegistrationAndTicketing(createdCampaign);

      if (isRegistrationAndTicketingCampaign) {
        const experiencesCreateManyResult = await createExperiencesMutation({
          variables: {
            data: experiencesData?.map(experience => {
              return {
                ...experience,
                campaign: {
                  connect: {
                    id: createdCampaign.id,
                  },
                },
              };
            }),
          },
        });
        const createdExperiencesCount =
          experiencesCreateManyResult?.data?.experienceCreateMany?.count || 0;

        setCreatedCampaign({ ...createdCampaign, experiences: { count: createdExperiencesCount } });
        return;
      }

      setCreatedCampaign(createdCampaign);
    } catch (error) {
      console.error({ error });
    }
  };

  const changeCampaignType = (campaignType: CampaignType) => {
    setCampaignType(campaignType);
  };

  return (
    <CampaignRegistrationContext.Provider
      value={{
        campaignType,
        isCreatingCampaign,
        changeCampaignType,
        createCampaign,
        createdCampaign,
      }}
    >
      {children}
    </CampaignRegistrationContext.Provider>
  );
};
