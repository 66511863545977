import { useMemo } from 'react';
import { useMutation } from '@apollo/client';

import { File, ORGANIZATION_LOGO_UPDATE } from '@vizsla/graphql';

import { useSelectedVizslaOrganization } from './selectedOrganization';

interface MutationResult {
  vizslaOrganizationUpdate: {
    logo?: File;
  };
}

interface MutationPayload {
  create: File;
  disconnect?: { id: string };
}

export function useSelectedOrganizationNegativeLogo() {
  const { data, loading: fetching, setData } = useSelectedVizslaOrganization();

  const [execute, { loading: updating }] = useMutation<MutationResult>(ORGANIZATION_LOGO_UPDATE);

  async function attach(image: File) {
    if (!data?.id) return;

    const payload: MutationPayload = {
      create: {
        fileId: image.fileId,
        filename: image.filename,
        meta: image.meta,
        mods: image.mods,
      },
    };

    if (data.negativeLogo?.id) {
      payload.disconnect = { id: data.negativeLogo.id };
    }

    const { data: response } = await execute({
      variables: {
        filter: { id: data.id },
        data: { negativeLogo: payload },
      },
    });

    setData({ ...data, ...response?.vizslaOrganizationUpdate });
  }

  async function detach() {
    console.log(data);
    if (!data?.id || !data?.negativeLogo?.id) return;

    const { data: response } = await execute({
      variables: {
        filter: { id: data.id },
        data: {
          negativeLogo: {
            disconnect: { id: data.negativeLogo.id },
          },
        },
      },
    });

    setData({ ...data, ...response?.vizslaOrganizationUpdate });
  }

  const uri = useMemo(() => data?.negativeLogo?.downloadUrl ?? undefined, [data]);

  return {
    uri,

    fetching,
    updating,

    attach,
    detach,
  };
}
