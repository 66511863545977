import { FormState } from 'final-form';

export type FormStateValues = FormState<Record<string, any>, Partial<Record<string, any>>>;

export enum CoveredFeesTypes {
  custom = 'custom',
  fixed = 'fixed',
}

export enum DiscountCategoryTypes {
  partner = 'Partner',
  team = 'Team',
  vip = 'VIP',
  other = 'Other',
}

export enum DiscountAmountTypes {
  dollar = 'dollar',
  percentage = 'percentage',
}

export enum DiscountDatePickerTypes {
  singleDate = 'singleDate',
  dateRange = 'dateRange',
}

export enum DiscountAllocationTypes {
  byExperience = 'byExperience',
  allExperiences = 'allExperiences',
}

export enum DiscountStatusTypes {
  live = 'Live',
  scheduled = 'Scheduled',
  completed = 'Completed',
  expired = 'Expired',
}
