import React from 'react';
import DOMPurify from 'dompurify';

interface Props {
  html: string | Node;
}

export const HtmlRenderer: React.FC<Props> = ({ html }) => {
  const sanitizedHtml = React.useMemo(() => DOMPurify.sanitize(html), [html]);

  /* eslint-disable-next-line react/no-danger */
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};
