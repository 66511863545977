import React from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// import { ExperienceLandingElementProps } from '../ExperienceLanding.types';
import { ExperienceLandingElementProps } from 'src/providers/campaign/ThankYouLanding.types';

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontSize: '18px',
  },
  title: {
    fontSize: '26px',
  },
}));

export const MessageThankYouLanding: React.FC<ExperienceLandingElementProps> = ({
  experience,
  theme,
}) => {
  const { thankYouMessage, accentColor } = theme;
  const isThankYouMessageVisible = theme?.isThankYouMessageVisible ?? true;

  const classes = useStyles({ accentColor });

  return (
    <Grid>
      {isThankYouMessageVisible && (
        <Typography
          variant="body1"
          textAlign="center"
          marginBottom={5}
          className={classes.subtitle}
        >
          {thankYouMessage ?? 'Thank you message for the donor'}
        </Typography>
      )}
    </Grid>
  );
};
