import { gql } from '@apollo/client';

const CAMPAIGN_DISCOUNTS_SETTINGS_FRAGMENT = gql`
  fragment CampaignDiscountsSettingsFragment on DiscountCodeSetting {
    id
    name
    category
    amount
    amountType
    datePickerType
    startDate
    startTime
    endDate
    endTime
    allocationType
    experienceType {
      id
      name
    }
    limitToOneUsePerAttendee
    totalUses
    usesCount
    isEnabled
  }
`;

export const CAMPAIGN_DISCOUNTS_SETTINGS_LIST_QUERY = gql`
  query CampaignDiscountsSettingsList($filter: DiscountCodeSettingFilter!) {
    discountCodeSettingsList(filter: $filter) {
      items {
        ...CampaignDiscountsSettingsFragment
      }
    }
  }
  ${CAMPAIGN_DISCOUNTS_SETTINGS_FRAGMENT}
`;

export const CAMPAIGN_DISCOUNT_SETTINGS_CREATE_MUTATION = gql`
  mutation CampaignDiscountSettingsCreate($createData: DiscountCodeSettingCreateInput!) {
    discountCodeSettingCreate(data: $createData) {
      ...CampaignDiscountsSettingsFragment
    }
  }
  ${CAMPAIGN_DISCOUNTS_SETTINGS_FRAGMENT}
`;

export const CAMPAIGN_DISCOUNT_SETTINGS_UPDATE_MUTATION = gql`
  mutation CampaignDiscountSettingsUpdate(
    $data: DiscountCodeSettingUpdateInput!
    $filter: DiscountCodeSettingKeyFilter!
  ) {
    discountCodeSettingUpdate(data: $data, filter: $filter) {
      ...CampaignDiscountsSettingsFragment
    }
  }
  ${CAMPAIGN_DISCOUNTS_SETTINGS_FRAGMENT}
`;

export const CAMPAIGN_DISCOUNT_SETTINGS_DELETE_MUTATION = gql`
  mutation CampaignDiscountSettingsDelete($id: ID!) {
    discountCodeSettingDelete(filter: { id: $id }) {
      success
    }
  }
`;

export const CAMPAIGN_DISCOUNT_COUNTER_BY_NAME = gql`
  query CampaignDiscountCounterByName($campaignId: ID!, $discountName: String!) {
    campaign(id: $campaignId) {
      discountCodeSettings(filter: { name: { equals: $discountName } }) {
        count
      }
    }
  }
`;

export const CAMPAIGN_DISCOUNT_SETTINGS_QUERY = gql`
  query CampaignDiscountSettings($campaignId: ID!, $filter: DiscountCodeSettingFilter!) {
    campaign(id: $campaignId) {
      discountCodeSettings(filter: $filter) {
        items {
          ...CampaignDiscountsSettingsFragment
        }
      }
    }
  }
  ${CAMPAIGN_DISCOUNTS_SETTINGS_FRAGMENT}
`;
