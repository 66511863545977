import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { useFormState, useForm } from 'react-final-form';
import { FileInput } from '8base-react-sdk';
import { PictureAsPdf, Close } from '@mui/icons-material';

import { Field, NumberField, RichTextEditorField, TextField } from '@vizsla/components';
import { validateHtmlTextLength } from '@vizsla/utils';

import {
  CampaignWrapper,
  FlexField,
  FlexRowRadioGroupField,
  InLineFields,
  LinkText,
  PdfWrapper,
} from './styles';
import { SelectCampaign } from '../../../../../campaign/SelectCampaign';
import { SelectExperience } from '../../../../../experience/SelectExperience';
import { AGREEMENT_FILE, CAMPAIGN, EXPERIENCE } from '../../../constants';

export const InKindCommitment: React.FC = () => {
  const { values, errors, submitFailed } = useFormState();

  const { change } = useForm();
  const { inKindType } = values;

  const setCampaign = e => {
    change(CAMPAIGN, e);
    change(EXPERIENCE, null);
  };

  const setExperience = e => {
    change(EXPERIENCE, e);
    change(CAMPAIGN, null);
  };

  return (
    <React.Fragment>
      <InLineFields>
        <FlexField
          component={NumberField}
          name="amount"
          label="Amount (USD)"
          numberFormat={{ prefix: '$', thousandSeparator: true }}
          InputProps={{ fullWidth: true, size: 'medium' }}
          required
        />
        <FlexField
          component={TextField}
          name="serviceProvided"
          label="Good/Service Provided"
          InputProps={{ fullWidth: true, size: 'medium' }}
        />
      </InLineFields>
      <Field
        component={RichTextEditorField}
        name="description"
        label="Description"
        validate={value => validateHtmlTextLength(value, 1, 500)}
        maxTextLength={500}
        height={100}
      />

      <Typography fontWeight={600}>Allocation</Typography>
      <Field
        name="inKindType"
        options={[
          { label: 'Campaign', value: CAMPAIGN },
          { label: 'Experiences', value: EXPERIENCE },
        ]}
        component={FlexRowRadioGroupField}
        defaultValue={CAMPAIGN}
        optionContainerProps={{
          display: 'flex',
          flexDirection: 'row',
          direction: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          spacing: 2,
        }}
      />
      {inKindType === CAMPAIGN ? (
        <CampaignWrapper>
          <SelectCampaign name={CAMPAIGN} label="Campaign" onChange={setCampaign} required />
          {submitFailed && (
            <Typography variant="caption" color="red">
              {errors?.campaign}
            </Typography>
          )}
        </CampaignWrapper>
      ) : (
        <CampaignWrapper>
          <SelectExperience
            name={EXPERIENCE}
            label="Experience"
            onChange={setExperience}
            required
          />
          {submitFailed && (
            <Typography variant="caption" color="red">
              {errors?.experience}
            </Typography>
          )}
        </CampaignWrapper>
      )}

      <Field component={TextField} name="notes" label="Other Benefits/Notes" />

      {values[AGREEMENT_FILE] ? (
        <PdfWrapper>
          {values[AGREEMENT_FILE]?.filename?.replace('.pdf', '')}
          <PictureAsPdf />
          <IconButton
            onClick={() => {
              change(AGREEMENT_FILE, null);
            }}
          >
            <Close />
          </IconButton>
        </PdfWrapper>
      ) : (
        <FileInput
          onChange={e => {
            const file = e as any;
            change(AGREEMENT_FILE, {
              ...file,
            });
          }}
        >
          {wrapper => (
            <Grid item>
              <LinkText
                onClick={async () => {
                  const res = await wrapper.pick({
                    maxSize: 1000000,
                    accept: ['application/pdf', 'application/x-pdf'],
                    fromSources: ['local_file_system'],
                  });
                  return res;
                }}
              >
                Upload Agreement
              </LinkText>
            </Grid>
          )}
        </FileInput>
      )}
    </React.Fragment>
  );
};
