import React from 'react';
import { Form } from 'react-final-form';
import { FormApi } from 'final-form';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { Button, TextField, SelectField, Field } from '@vizsla/components';
import { useModal, useNotification } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { Experience, useCampaignFaqCreateMutation } from '@vizsla/graphql';

import { useCampaignFAQs, useCampaignId } from 'src/hooks/campaign';
import { useExperiences } from 'src/hooks/experiences';
import { RichEditorField } from 'src/components/forms';
import { t } from 'src/utils/template';
import { MODALS } from 'src/constants/modals';
import { CAMPAIGN_FAQ_ALLOCATION_OPTIONS } from 'src/constants/campaign';
import { CampaignFaqValidationSchema } from 'src/constants/validationSchemas/campaign';

export const CampaignFaqAddDialog: React.FC = () => {
  const { isOpen, closeModal } = useModal(MODALS.CAMPAIGN_FAQ_ADD_MODAL);

  const notification = useNotification();

  const campaignId = useCampaignId();
  const { createCampaignFaq, creating } = useCampaignFAQs(campaignId);

  const { data: experiences } = useExperiences(campaignId);
  const ALLOCATION_OPTIONS = experiences.map((experience: Experience) => ({
    label: experience.name || '',
    value: experience.id || '',
  }));

  const onSubmit = async (campaignFaqData: any, form: FormApi) => {
    try {
      const faqAllocationFormated =
        campaignFaqData?.allocation?.map(id => ({
          id,
        })) ?? [];

      await createCampaignFaq({
        variables: {
          data: {
            userGroup: campaignFaqData?.userGroup,
            ...campaignFaqData,
            allocation: {
              connect: faqAllocationFormated,
            },
            campaign: {
              connect: {
                id: campaignId,
              },
            },
          },
        },
      });

      notification.success(t('campaign_faq_create_success'));
      setTimeout(form.reset);
      closeModal();
    } catch (error) {
      notification.error(t('campaign_faq_create_error'));
      console.error({ error });
    }
  };

  const onCloseModal = () => {
    closeModal();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onCloseModal}>
      <DialogTitle>
        <Grid>
          <IconButton
            aria-label="close"
            onClick={onCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
            size="large"
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid marginTop={4}>
          <Typography variant="h3" textAlign="center">
            Add FAQ
          </Typography>
        </Grid>
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        validate={values => validateWithSchema(CampaignFaqValidationSchema, values)}
      >
        {({ handleSubmit, submitting, hasValidationErrors, pristine, form, values }) => {
          const isLoading = submitting || creating;
          const isSubmitDisabled = isLoading || hasValidationErrors || pristine;

          return (
            <form onSubmit={handleSubmit}>
              <DialogContent sx={{ padding: theme => theme.spacing(4, 8, 4, 8) }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="allocation"
                      label="Allocation"
                      multiple
                      component={SelectField}
                      options={ALLOCATION_OPTIONS}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="userGroup"
                      label="User Groups"
                      multiple
                      component={SelectField}
                      options={CAMPAIGN_FAQ_ALLOCATION_OPTIONS}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="question"
                      label="Question"
                      component={TextField}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="answer"
                      label="Answer"
                      rows={4}
                      component={RichEditorField}
                      height={300}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ padding: theme => theme.spacing(0, 0, 4, 0) }}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      variant="text"
                      size="large"
                      color="info"
                      style={{ width: 100 }}
                      onClick={onCloseModal}
                      disabled={isLoading}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      style={{ width: 100 }}
                      size="large"
                      color="primary"
                      onClick={handleSubmit}
                      loading={isLoading}
                      disabled={isSubmitDisabled}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </form>
          );
        }}
      </Form>
    </Dialog>
  );
};
