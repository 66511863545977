import React from 'react';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Field,
  TextField,
  SelectField,
  DateField,
  TimeField,
  CheckboxField,
  RadioGroupField,
  GridDefaultPadding,
} from '@vizsla/components';
import { DateFormatPatterns } from '@vizsla/constants';

import {
  EXPERIENCE_REGISTRATION_CATEGORY_OPTIONS,
  EXPERIENCE_TICKETING_CATEGORY_OPTIONS,
} from 'src/constants/experience';
import { DateType } from 'src/constants/campaign';
import { ExperienceType } from 'src/types/experience';
import {
  getExperienceMaxStartDate,
  getExperienceMinStartDate,
  getExperienceMinEndDate,
  getExperienceMaxEndDate,
} from 'src/utils/experience';
import { ExperienceTypeRadioGroupField } from 'src/components/experience';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  fieldContainer: {
    marginBottom: theme.spacing(2),
  },
}));

interface ExperienceFormProps {
  formName: string;
  formIndex: number;
}

export const ExperienceForm: React.FC<ExperienceFormProps> = ({ formName, formIndex }) => {
  const classes = useStyles();

  const getExperienceCategoryOptions = (currentExperienceType: string) =>
    currentExperienceType === ExperienceType.registration
      ? EXPERIENCE_REGISTRATION_CATEGORY_OPTIONS
      : EXPERIENCE_TICKETING_CATEGORY_OPTIONS;

  return (
    <div className={classes.root}>
      <Grid className={classes.fieldContainer} container>
        <Field
          name={`${formName}.experienceType`}
          component={ExperienceTypeRadioGroupField}
          defaultValue={ExperienceType.registration}
          width={330}
        />
      </Grid>
      <Grid className={classes.fieldContainer} container>
        <GridDefaultPadding item xs={6} p={1}>
          <Field
            label="Experience Name"
            name={`${formName}.name`}
            component={TextField}
            fullWidth
            required
          />
        </GridDefaultPadding>
        <GridDefaultPadding item xs={6} p={1}>
          <FormSpy subscription={{ values: true }}>
            {(spyRenderProps: FormSpyRenderProps) => {
              const {
                values: { experiences },
              } = spyRenderProps;

              const currentExperience = experiences[formIndex];
              const currentExperienceType =
                currentExperience.experienceType || ExperienceType.registration;

              return (
                <Field
                  label="Experience Category"
                  name={`${formName}.experienceCategory`}
                  component={SelectField}
                  options={getExperienceCategoryOptions(currentExperienceType)}
                  fullWidth
                  required
                />
              );
            }}
          </FormSpy>
        </GridDefaultPadding>
      </Grid>
      <Grid container direction="row">
        <GridDefaultPadding item xs={6}>
          <Typography variant="subtitle2">Experience Dates</Typography>
        </GridDefaultPadding>
      </Grid>
      <Grid container direction="row">
        <GridDefaultPadding item xs={6}>
          <Field
            label="Same as Campaign Dates"
            name={`${formName}.sameAsCampaignDates`}
            component={CheckboxField}
          />
        </GridDefaultPadding>
      </Grid>
      <FormSpy subscription={{ values: true }}>
        {(spyRenderProps: FormSpyRenderProps) => {
          const { values } = spyRenderProps;
          const { startDate: campaignStartDate, endDate: campaignEndDate } = values;
          const experience = values.experiences[formIndex];
          const { sameAsCampaignDates } = experience;

          const visibleDates = !sameAsCampaignDates;
          const visibleDateRange = experience.dateType === DateType.Range;

          spyRenderProps.form.change(
            `${formName}.endDate`,
            visibleDateRange ? experience.endDate : null,
          );

          const maxStartDate = getExperienceMaxStartDate(
            visibleDateRange ? experience.endDate : null,
            campaignEndDate,
          );
          const minStartDate = getExperienceMinStartDate(campaignStartDate);

          const minEndDate = getExperienceMinEndDate(experience.startDate, campaignStartDate);
          const maxEndDate = getExperienceMaxEndDate(campaignEndDate);

          if (!visibleDates) {
            return null;
          }

          return (
            <Grid container>
              <GridDefaultPadding item xs={12}>
                <Field
                  label=""
                  name={`${formName}.dateType`}
                  component={RadioGroupField}
                  row
                  options={[
                    {
                      label: 'Single Date',
                      value: DateType.Single,
                    },
                    {
                      label: 'Date Range',
                      value: DateType.Range,
                    },
                  ]}
                />
              </GridDefaultPadding>
              <GridDefaultPadding item xs={6}>
                <Field
                  label="Start Date"
                  name={`${formName}.startDate`}
                  component={DateField}
                  inputFormat={DateFormatPatterns.fullMonthDayYear}
                  maxDate={maxStartDate}
                  minDate={minStartDate}
                  fullWidth
                  required
                />
              </GridDefaultPadding>
              <GridDefaultPadding item xs={6}>
                <Field
                  label="Start Time"
                  name={`${formName}.startTime`}
                  component={TimeField}
                  fullWidth
                  required
                />
              </GridDefaultPadding>
              {visibleDateRange && (
                <React.Fragment>
                  <GridDefaultPadding item xs={6}>
                    <Field
                      label="End Date"
                      name={`${formName}.endDate`}
                      component={DateField}
                      inputFormat={DateFormatPatterns.fullMonthDayYear}
                      maxDate={maxEndDate}
                      minDate={minEndDate}
                      fullWidth
                      required
                    />
                  </GridDefaultPadding>
                  <GridDefaultPadding item xs={6}>
                    <Field
                      label="End Time"
                      name={`${formName}.endTime`}
                      component={TimeField}
                      fullWidth
                      required
                    />
                  </GridDefaultPadding>
                </React.Fragment>
              )}
            </Grid>
          );
        }}
      </FormSpy>
    </div>
  );
};
