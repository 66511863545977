import React from 'react';
import { Grid } from '@mui/material';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import { Field, NumberField, SelectField } from '@vizsla/components';

import { MONEY_FORMAT, PERCENT_FORMAT } from 'src/constants/formats';
import { TAX_DEDUCTIBLE_OPTIONS } from 'src/constants/pricingSettings';
import {
  PricingTierFieldValue,
  PricingTierFormAction,
  TaxDeductibleType,
} from 'src/types/pricingSettings';

interface FixedPriceTierFieldProps {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
}

export const FixedPriceTierField: React.FC<FixedPriceTierFieldProps> = ({ input }) => {
  const idx = React.useMemo(
    () =>
      input.value.findIndex(
        (fieldValue: PricingTierFieldValue) =>
          fieldValue.formAction !== PricingTierFormAction.toDelete,
      ),
    [input.value],
  );

  const renderTaxDeductibleAmountField = () => {
    if (input.value[idx]?.taxDeductibleType === TaxDeductibleType.Percents) {
      return (
        <Field
          name={`${input.name}[${idx}].taxDeductiblePercentsAmount`}
          label="Tax Deductible Amount"
          required
          component={NumberField}
          numberFormat={PERCENT_FORMAT}
        />
      );
    }

    return (
      <Field
        name={`${input.name}[${idx}].taxDeductibleCurrencyAmount`}
        label="Tax Deductible Amount"
        required
        component={NumberField}
        numberFormat={MONEY_FORMAT}
      />
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Field
          name={`${input.name}[${idx}].price`}
          label="Price (USD)"
          required
          component={NumberField}
          numberFormat={MONEY_FORMAT}
        />
      </Grid>
      <Grid item xs={4}>
        {renderTaxDeductibleAmountField()}
      </Grid>
      <Grid item xs={4}>
        <Field
          name={`${input.name}[${idx}].taxDeductibleType`}
          label="Tax Deductible Type"
          required
          component={SelectField}
          options={TAX_DEDUCTIBLE_OPTIONS}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
