import React from 'react';

import { FiltersLayout } from '@vizsla/components';

export function MessageHistoryLayout({ children }) {
  return (
    <FiltersLayout withSearch withPagination searchPlaceholder="Search by user">
      {children}
    </FiltersLayout>
  );
}
