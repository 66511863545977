import * as yup from 'yup';

import { CAMPAIGN, EXPERIENCE } from '../constants';

export const cashValidationSchema = yup.object().shape(
  {
    amount: yup.number().min(1).required('Amount is required'),
    decisionDate: yup
      .date()
      .typeError('Partner Decision Date must be a Date')
      .required('Partner Decision Date is required'),
    notes: yup.string().max(500, 'Notes must be at most 500 characters').notRequired(),
    campaign: yup.string().when(EXPERIENCE, {
      is: experience => !experience,
      then: yup.string().required('Campaign is required'),
      otherwise: yup.string().nullable(true),
    }),
    experience: yup.string().when(CAMPAIGN, {
      is: campaign => !campaign,
      then: yup.string().required('Experience is required'),
      otherwise: yup.string().nullable(true),
    }),
  },
  [[EXPERIENCE, CAMPAIGN]],
);
export const inKindValidationSchema = yup.object().shape(
  {
    amount: yup.number().min(1).required('Amount is required'),
    serviceProvided: yup.string().max(100).notRequired(),
    notes: yup.string().max(500, 'Notes must be at most 500 characters').notRequired(),
    campaign: yup.string().when(EXPERIENCE, {
      is: experience => !experience,
      then: yup.string().required('Campaign is required'),
      otherwise: yup.string().nullable(true),
    }),
    experience: yup.string().when(CAMPAIGN, {
      is: campaign => !campaign,
      then: yup.string().required('Experience is required'),
      otherwise: yup.string().nullable(true),
    }),
  },
  [[EXPERIENCE, CAMPAIGN]],
);
export const matchingValidationSchema = yup.object().shape(
  {
    matchLimit: yup.number().min(1).required('Match Limit is required'),
    amount: yup.number().min(1).required('Total Amount is required'),
    campaign: yup.string().when(EXPERIENCE, {
      is: experience => !experience,
      then: yup.string().required('Campaign is required'),
      otherwise: yup.string().nullable(true),
    }),
    experience: yup.string().when(CAMPAIGN, {
      is: campaign => !campaign,
      then: yup.string().required('Experience is required'),
      otherwise: yup.string().nullable(true),
    }),
    startDate: yup.date().required('Start Date is required'),
    startTime: yup.date().required('Start Time is required'),
    endDate: yup.date().notRequired(),
    endTime: yup.date().notRequired(),
    notes: yup.string().max(500, 'Notes must be at most 500 characters').notRequired(),
  },
  [[EXPERIENCE, CAMPAIGN]],
);
