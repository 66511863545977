import { makeStyles } from '@mui/styles';

const useCampaignsPageStyles = makeStyles({
  toggle: {
    height: 40,
    '&>*': {
      width: 50,
    },
  },
});

export default useCampaignsPageStyles;
