import React from 'react';
import { Grid, Theme, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useModal } from '@vizsla/hooks';
import { Button } from '@vizsla/components';

import { useExperienceId, useExperienceBibAssignment } from 'src/hooks/experiences';
import { BibAssignmentMethod } from 'src/types/bibAssignment';
import { MODALS } from 'src/constants/modals';

import { CampaignExperienceBibBatchAssignmentForm } from './CampaignExperienceBibBatchAssignmentForm';
import { CampaignExperienceBibAutoAssignmentForm } from './CampaignExperienceBibAutoAssignmentForm';

const useStyles = makeStyles((theme: Theme) => ({
  assignmentMethod: {
    '&>.MuiButtonBase-root': {
      padding: theme.spacing(0.55, 4),
    },
  },
  resetBtn: {
    marginLeft: 0,
  },
}));

export const CampaignExperienceBibAssignmentMethodSection: React.FC = () => {
  const classes = useStyles();

  const { openModal } = useModal(MODALS.RESET_BIB_ASSIGNMENT_MODAL);

  const experienceId = useExperienceId();

  const { poolBibAssignments } = useExperienceBibAssignment(experienceId);

  const [assignmentMethod, setAssignmentMethod] = React.useState<BibAssignmentMethod>(
    BibAssignmentMethod.Auto,
  );

  const onChangeAssignmentMethod = (
    _: React.ChangeEvent<any>,
    newAssignmentMethod: BibAssignmentMethod,
  ) => {
    setAssignmentMethod(newAssignmentMethod);
  };

  const onResetBibAssignment = () => {
    openModal(MODALS.RESET_BIB_ASSIGNMENT_MODAL);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item>
        <Typography variant="h6">Assignment Method</Typography>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item>
            <ToggleButtonGroup
              size="medium"
              exclusive
              value={assignmentMethod}
              onChange={onChangeAssignmentMethod}
              className={classes.assignmentMethod}
            >
              <ToggleButton
                value={BibAssignmentMethod.Auto}
                disabled={assignmentMethod === BibAssignmentMethod.Auto}
              >
                {BibAssignmentMethod.Auto}
              </ToggleButton>
              <ToggleButton
                value={BibAssignmentMethod.Batch}
                disabled={assignmentMethod === BibAssignmentMethod.Batch}
              >
                {BibAssignmentMethod.Batch}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item>
            <Button variant="text">Import Bibs</Button>
            <Button
              variant="text"
              color="error"
              className={classes.resetBtn}
              disabled={!poolBibAssignments.length}
              onClick={onResetBibAssignment}
            >
              Reset Bib Assignment
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {assignmentMethod === BibAssignmentMethod.Auto ? (
        <Grid item>
          <CampaignExperienceBibAutoAssignmentForm />
        </Grid>
      ) : (
        <Grid item>
          <CampaignExperienceBibBatchAssignmentForm />
        </Grid>
      )}
    </Grid>
  );
};
