import React from 'react';
import { Box, Theme } from '@mui/material';
import { FlagOutlined, MonetizationOnOutlined, PersonOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@apollo/client';

import { formatMoney } from '@vizsla/utils';
import { CampaignsStatisticsQuery } from '@vizsla/graphql';
import { useCurrentOrganization } from '@vizsla/hooks';

import { InformationCard } from 'src/components/shared';
import { CAMPAIGNS_STATISTICS_QUERY } from 'src/graphql';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    '&>*:not(:first-child):not(:last-child)': {
      margin: theme.spacing(0, 1),
    },
    '&>*:first-child': {
      marginRight: theme.spacing(1),
    },
    '&>*:last-child': {
      marginLeft: theme.spacing(1),
    },
  },
}));

export const CampaignsListInfoCards: React.FC = () => {
  const classes = useStyles();

  const { organizationId } = useCurrentOrganization();
  const { data, loading } = useQuery<CampaignsStatisticsQuery>(CAMPAIGNS_STATISTICS_QUERY, {
    variables: {
      organizationId,
    },
  });

  const totalCount = data?.totalCount?.count;
  const totalAttendees = data?.totalAttendees?.campaigns?.groups[0]?.count;
  const totalCompleted = data?.totalCompleted.count;
  const totalActive = data?.totalActive.count;

  return (
    <Box className={classes.root}>
      <InformationCard
        sx={{ height: '64px' }}
        title="Total
              Users"
        value={totalCount}
        loading={loading}
      />
      <InformationCard
        sx={{ height: '64px' }}
        title="New Users
              (last 30 days)"
        value={totalActive}
        loading={loading}
      />
      <InformationCard
        sx={{ height: '64px' }}
        title="Sessions
              (last 30 days)"
        value={totalCompleted}
        loading={loading}
      />
      <InformationCard
        sx={{ height: '64px' }}
        title="Pending
              Invitations"
        value={totalAttendees}
        loading={loading}
      />
    </Box>
  );
};
