import React from 'react';
import { Grid, Menu, MenuItem, Divider, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';

import { useModal } from '@vizsla/hooks';

import DEFAULT_IMG from 'src/assets/empty.jpg';
import { MODALS } from 'src/constants/modals';

import {
  AvatarImage,
  CaptainContainer,
  GoalContainer,
  HeaderCard,
  Icon,
  TeammareCard,
  Text,
  TextContainer,
  UserAvatar,
  TextLigth,
} from './TeammatesCard.style';

export function TeammatesCard({ member, resendInviteFunction }) {
  const {
    user: { avatar, firstName, lastName, email, updatedAt, status },
    roles,
  } = member;
  const [anchorMenuEl, setAnchorMenuEl] = React.useState<null | HTMLElement>(null);
  const [anchorCardEl, setAnchorCardEl] = React.useState<null | HTMLElement>(null);
  const [underline, setUnderline] = React.useState('none');
  const { openModal } = useModal();

  const handleCloseMenu = () => {
    setAnchorMenuEl(null);
  };

  const open = Boolean(anchorMenuEl);
  const role = status === 'inactive' ? 'Pending' : roles[0];

  const handleOpenEditModal = () =>
    openModal(MODALS.ORGANIZATION_MEMBER_EDIT_MODAL, {
      organizationMember: member,
    });

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenuEl(event.currentTarget);
  };

  const handleClickCard = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorCardEl(event.currentTarget);
  };

  return (
    <TeammareCard
      onMouseEnter={() => setUnderline('underline')}
      onMouseLeave={() => setUnderline('nose')}
    >
      <Icon onClick={handleClickMenu}>
        <MoreHorizOutlined htmlColor="#676767" />
      </Icon>

      <HeaderCard>
        <UserAvatar variant="circular">
          <AvatarImage src={avatar?.downloadUrl || DEFAULT_IMG} />
        </UserAvatar>
        <TextContainer>
          <Text
            sx={{
              textDecoration: underline,
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            fontWeight="bold"
            fontSize="14px"
            onClick={handleClickCard}
          >
            {firstName} {lastName}
          </Text>
          <TextLigth>{email}</TextLigth>
        </TextContainer>
      </HeaderCard>
      <GoalContainer mt="4px">
        <CaptainContainer>
          <Text fontSize="12px" color="#FFFFFF" textAlign="center">
            {role}
          </Text>
        </CaptainContainer>
        <TextLigth ml="4px" color="#6D6D6D">
          Last Access: {updatedAt}
        </TextLigth>
      </GoalContainer>

      <Menu anchorEl={anchorMenuEl} open={open} onClose={handleCloseMenu}>
        <Grid minWidth="200px">
          <MenuItem onClick={handleOpenEditModal}>Edit</MenuItem>
          <MenuItem onClick={() => resendInviteFunction(member)}>Resend Invitation</MenuItem>
        </Grid>
      </Menu>
    </TeammareCard>
  );
}
