import React from 'react';
import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, Grid, Typography } from '@mui/material';
import { omit } from 'lodash';

import {
  useCreatePartnershipContactMutation,
  useUpdatePartnershipContactMutation,
} from '@vizsla/graphql';
import { Form, Field, TextField, NumberField } from '@vizsla/components';
import { validateWithSchema } from '@vizsla/utils';
import { useModal, useNotification } from '@vizsla/hooks';
import { MODALS } from '@vizsla/constants';

import { Button, ButtonsWrapper, CloseButtonIcon, FormWrapper } from './styles';
import { PartnershipContactDBRowT, PartnershipContactT } from '../types';
import { partnershipContactValidationSchema } from './validation';

export const CreatePartnershipContactModal: React.FC = () => {
  const [createContact] = useCreatePartnershipContactMutation();
  const [updateContact] = useUpdatePartnershipContactMutation();
  const { isOpen, closeModal: close, args } = useModal(MODALS.PARTNERSHIP_CONTACT_CREATE);

  const [loading, setLoading] = React.useState(false);

  const { contact, partnershipId }: { contact?: PartnershipContactDBRowT; partnershipId?: string } =
    args ?? {};
  const notifications = useNotification();
  const initialValues: PartnershipContactT | null = omit(contact, 'id') ?? null;

  const onSubmit = React.useCallback(
    async (form: PartnershipContactT) => {
      setLoading(true);
      try {
        const vars = {
          email: form.email,
          firstName: form.firstName,
          lastName: form.lastName,
          notes: form?.notes,
          phone: form.phone.toString(),
          title: form.title,
        };
        if (!contact?.id) {
          await createContact({
            variables: {
              data: {
                ...vars,
                partnershipOrganizationId: partnershipId,
              },
            },
            refetchQueries: ['ListPartnershipContacts'],
          });
          notifications.success('Contact created successfully');
        } else {
          await updateContact({
            variables: {
              data: {
                ...vars,
                id: contact.id,
                partnershipOrganizationId: partnershipId,
              },
            },
            refetchQueries: ['ListPartnershipContacts'],
          });
          notifications.success('Contact updated successfully');
        }
      } catch (error) {
        notifications.error(`There was an error while creating a contact: ${error.message}`);
        setLoading(false);
        close();
        return;
      }
      setLoading(false);

      close();
    },
    [createContact, updateContact, close, contact, partnershipId],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        close();
      }}
    >
      <DialogTitle>
        <Grid>
          <CloseButtonIcon
            aria-label="close"
            onClick={() => {
              close();
            }}
            size="large"
          >
            <Close />
          </CloseButtonIcon>
        </Grid>
        <Grid marginTop={4}>
          <Typography variant="h3" textAlign="center">
            Add Contact
          </Typography>
        </Grid>
      </DialogTitle>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={values => validateWithSchema(partnershipContactValidationSchema, values)}
        render={({ form, values, handleSubmit }) => (
          <FormWrapper>
            <Field component={TextField} name="firstName" label="First Name" />
            <Field component={TextField} name="lastName" label="Last Name" />
            <Field component={TextField} name="title" label="Title" />
            <Field component={TextField} name="email" label="Email" />

            <Field
              component={NumberField}
              name="phone"
              label="Phone Number"
              formatMask="+1 (###) ###-####"
            />
            <Field component={TextField} name="notes" label="Notes" />

            <ButtonsWrapper>
              <Button
                color="info"
                type="button"
                onClick={() => {
                  close();
                }}
                loading={loading}
              >
                Cancel
              </Button>
              <Button type="submit" onClick={handleSubmit} loading={loading}>
                Save
              </Button>
            </ButtonsWrapper>
          </FormWrapper>
        )}
      />
    </Dialog>
  );
};
