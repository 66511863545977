import React from 'react';

import { MessageHistoryLayout } from './MessageHistoryLayout';
import { MessagePageContent } from './MessagePageContent';

export function MessageHistory() {
  return (
    <MessageHistoryLayout>
      <MessagePageContent />
    </MessageHistoryLayout>
  );
}
