import React from 'react';
import { CardMedia, Divider, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { formatMoney, calcPercentage } from '@vizsla/utils';
import { Campaign } from '@vizsla/graphql';

import { BorderRadius, PaletteColor, Shade } from 'src/theme';
import { DateRange, DeterminateProgress } from 'src/components/shared';
import { CampaignStatusChip } from 'src/components/campaign';
import { CampaignStatus, CampaignType } from 'src/types/campaign';
import { EMPTY_IMAGE } from 'src/assets';
import { getAttendeesCountFromCampaign } from 'src/utils/campaign';

const IMAGE_HEIGHT = 168;
const CARD_HEIGHT = 400;

interface StylesParams {
  hideMetrics: boolean;
}

const useStyles = makeStyles<Theme, StylesParams>((theme: Theme) => ({
  nameLink: {
    color: PaletteColor.BlueText,
  },
  cardWrapper: {
    marginBottom: theme.spacing(4),
  },
  header: {
    marginBottom: theme.spacing(1.5),
  },
  imgWrap: {
    borderRadius: `${BorderRadius.M} ${BorderRadius.M} 0 0`,
  },
  content: {
    padding: theme.spacing(2, 2, 2, 3),
    height: ({ hideMetrics }) => (!hideMetrics ? CARD_HEIGHT - IMAGE_HEIGHT : 'auto'),
  },
  cardTitle: {
    color: Shade.Blue[500],
    marginBottom: theme.spacing(1.5),
  },
  subtitle: {
    color: PaletteColor.GrayText,
    marginBottom: theme.spacing(5),
  },
  label: {
    color: PaletteColor.GrayText,
    marginRight: theme.spacing(0.5),
  },
  footerText: {
    marginBottom: theme.spacing(4),
  },
  divider: {
    marginTop: 'auto',
    marginBottom: theme.spacing(1.5),
  },
  statsWrap: {
    height: 38,
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
  },

  statsTitle: {
    fontSize: 10,
    textTransform: 'uppercase',
  },
  statsValue: {
    color: PaletteColor.DefaultText,
    '&.zeroOrNil': {
      color: PaletteColor.GrayText,
    },
  },
  progressCircle: {
    marginRight: theme.spacing(1),
  },
}));

interface CampaignTileProps {
  campaign: Campaign;
  hideMetrics?: boolean;
}

export const CampaignTileContent: React.FC<CampaignTileProps> = ({
  campaign,
  hideMetrics = false,
}) => {
  const classes = useStyles({ hideMetrics });

  const renderStat = (
    title: string,
    goal: number,
    value: number,
    formatFunction: (val: number) => string | number = (val: number) => val,
  ) => {
    const progress = calcPercentage(value, goal);

    return (
      <Grid className={classes.statsWrap}>
        <Typography variant="caption" className={classes.statsTitle}>
          {title}
        </Typography>
        <Grid container alignItems="center">
          <DeterminateProgress className={classes.progressCircle} size={16} value={progress || 0} />
          <Typography variant="subtitle2">{formatFunction(value)}</Typography>
        </Grid>
      </Grid>
    );
  };

  const teamGoal = campaign?.teamGoal ?? 0;
  const teamsCount = campaign?.teams?.count ?? 0;

  return (
    <Grid container>
      <CardMedia
        className={classes.imgWrap}
        component="img"
        alt="Campaign"
        height={IMAGE_HEIGHT}
        image={campaign.image?.downloadUrl ?? EMPTY_IMAGE}
        title="Campaign"
      />
      <Grid container className={classes.content} flexDirection="column">
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.header}
        >
          <DateRange startDate={campaign.startDate} endDate={campaign.endDate} />
          <CampaignStatusChip status={campaign.campaignStatus as CampaignStatus} />
        </Grid>
        <Grid item width="100%">
          <Typography variant="h4" noWrap textOverflow="ellipsis" className={classes.cardTitle}>
            {campaign.name}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption" className={classes.label}>
            Type:
          </Typography>
          <Typography variant="caption">{campaign.campaignType}</Typography>
        </Grid>
        {campaign.campaignType === CampaignType.registrationAndTicketing && (
          <Grid item>
            <Typography variant="caption" className={classes.label}>
              Experiences:
            </Typography>
            <Typography variant="caption">{campaign.experiences?.count}</Typography>
          </Grid>
        )}
        {!hideMetrics && (
          <React.Fragment>
            <Divider className={classes.divider} />
            <Grid item container>
              {renderStat(
                'Total raised',
                Number(campaign.fundraisingGoal),
                Number(campaign.totalRaised),
                formatMoney,
              )}
              {renderStat(
                'Attendees',
                Number(campaign.attendanceFundraisersCountGoal),
                getAttendeesCountFromCampaign(campaign),
              )}
              {renderStat('Teams', teamGoal, teamsCount)}
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};
