import React from 'react';
import { styled } from '@mui/styles';
import { Box, CircularProgress, Grid } from '@mui/material';
import _ from 'lodash';

import { PageBackgroundType } from '@vizsla/constants';
import { PaletteColor, theme } from '@vizsla/theme';
import { GradientDivider } from '@vizsla/components';

import {
  ExperienceLandingNodesType,
  ExperienceLandingProps,
  StyledExperienceDescriptionProps,
} from 'src/providers/campaign/ThankYouLanding.types';
import { ThankYouHeaderMediaSection } from 'src/components/ThankYouTemplateEditor/SectionTemplate/ThankYouHeaderMediaSection';
import { MessageThankYouLanding } from 'src/components/ThankYouTemplateEditor/SectionTemplate/MessageThankYouLanding';
import { DonationInformation } from 'src/components/ThankYouTemplateEditor/SectionTemplate/DonationInformation';
import { ManageYourDonation } from 'src/components/ThankYouTemplateEditor/SectionTemplate/ManageYourDonation';
import { QuestionDonation } from 'src/components/ThankYouTemplateEditor/SectionTemplate/QuestionDonation';

import { Headline } from './Headline';

const StyledExperienceDescription = styled('div')((props: StyledExperienceDescriptionProps) => ({
  background: `${
    props?.hasBackgroundColorEffect ? props?.theme?.pageBackgroundSecondColor : PaletteColor.White
  }`,
  padding: theme.spacing(0, 11, 5.5, 11),
}));

const StyledExperienceGeneralInfo = styled('div')((props: StyledExperienceDescriptionProps) => ({
  background: `${
    props?.hasBackgroundColorEffect ? props?.theme?.pageBackgroundFirstColor : PaletteColor.White
  }`,
  padding: theme.spacing(5, 11, 0, 11),
}));

const StyledExperienceMainSection = styled('main')(() => ({
  display: 'grid',
  gridArea: 'content',
  overflow: 'auto',
  padding: '0',

  '&::after': {
    display: 'block',
    content: '""',
    height: 0,
    width: '100%',
  },
}));

const EXP_GENERAL_INFO_LANDING_NODES: ExperienceLandingNodesType = {
  Headline_ID: {
    element: Headline,
    divider: 'none',
  },
};

const EXP_DESCRIPTION_LANDING_NODES: ExperienceLandingNodesType = {
  OurStory_ID: {
    element: MessageThankYouLanding,
    divider: 'after',
  },
  RegistrationOptions_ID: {
    element: DonationInformation,
    divider: 'after',
  },
  Gallery_ID: {
    element: ManageYourDonation,
    divider: 'after',
  },
  Leaderboard_ID: {
    element: QuestionDonation,
    divider: 'none',
  },
  // SocialNetwork_ID: {
  //   element: SocialNetworksSection,
  //   divider: 'none',
  // },
};

const ExperienceLanding: React.FC<ExperienceLandingProps> = ({
  experience,
  loading,
  experienceLandingState,
  headerImages,
  editorMode,
}) => {
  const landingRootNode = experienceLandingState?.nodes?.root;
  const landingNodesList = landingRootNode?.props?.children?.nodes || [];

  const heroMediaType = experienceLandingState?.theme?.heroMediaType;
  const theme = experienceLandingState?.theme;

  const hasBackgroundColorEffect = theme?.pageBackgroundType === PageBackgroundType.colorEffect;

  if (_.isNil(experience) || loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress style={{ width: '65px', height: '65px' }} color="secondary" />
      </Box>
    );
  }

  const renderExperienceLandingNodes = (experienceLandingNodes: ExperienceLandingNodesType) => {
    return (
      <Grid container>
        {landingNodesList.map(node => {
          if (!experienceLandingNodes[node]?.element) {
            return null;
          }

          const Element: React.ElementType = experienceLandingNodes[node].element;
          const hasDividerBefore = experienceLandingNodes[node]?.divider === 'before';
          const hasDividerAfter = experienceLandingNodes[node]?.divider === 'after';

          return (
            <Grid item key={node} marginBottom={8} xs={12}>
              {hasDividerBefore && <GradientDivider color={theme?.accentColor} />}
              <Element experience={experience} theme={theme} />
              {hasDividerAfter && <GradientDivider color={theme?.accentColor} />}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <div>
      <StyledExperienceMainSection>
        <ThankYouHeaderMediaSection
          heroMediaType={heroMediaType}
          experienceHeaderImages={headerImages}
          editorMode={editorMode}
        />
        <Box>
          <StyledExperienceGeneralInfo
            hasBackgroundColorEffect={hasBackgroundColorEffect}
            theme={theme}
          >
            {renderExperienceLandingNodes(EXP_GENERAL_INFO_LANDING_NODES)}
          </StyledExperienceGeneralInfo>
          <StyledExperienceDescription
            hasBackgroundColorEffect={hasBackgroundColorEffect}
            theme={theme}
          >
            {renderExperienceLandingNodes(EXP_DESCRIPTION_LANDING_NODES)}
          </StyledExperienceDescription>
        </Box>
      </StyledExperienceMainSection>
    </div>
  );
};

export { ExperienceLanding };
