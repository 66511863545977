import {
  EarlyBirdRegistrationsApplyTo,
  EarlyBirdRegistrationsMode,
} from 'src/types/earlyBirdRegistration';

export const APPLY_TO_SELECT_OPTIONS = [
  {
    label: EarlyBirdRegistrationsApplyTo.allRegistrationOptions,
    value: EarlyBirdRegistrationsApplyTo.allRegistrationOptions,
  },
  // {
  //   label: EarlyBirdRegistrationsApplyTo.specificOptions,
  //   value: EarlyBirdRegistrationsApplyTo.specificOptions,
  // },
];

export const REGISTRATIONS_MODE_SELECT_OPTIONS = [
  {
    label: EarlyBirdRegistrationsMode.everyoneWithTheLink,
    value: EarlyBirdRegistrationsMode.everyoneWithTheLink,
  },
  {
    label: EarlyBirdRegistrationsMode.specificAttendeesAndTeams,
    value: EarlyBirdRegistrationsMode.specificAttendeesAndTeams,
  },
];

export const FORM_SPACING = 3;
