import React from 'react';
import { Form } from 'react-final-form';
import { FormApi } from 'final-form';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { Button, TextField, SelectField, Field, NumberField } from '@vizsla/components';
import { useModal, useNotification, useCurrentOrganization } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { OrganizationRoles } from '@vizsla/types';
import { useAddOrganizationMemberMutation } from '@vizsla/graphql';

import { t } from 'src/utils/template';
import { MODALS } from 'src/constants/modals';
import { OrganizationAddMemberSchema } from 'src/constants/validationSchemas/organizationAddMember';

interface OrganizationMemberAddModalProps {
  refetchUsers: () => void;
}

export const OrganizationMemberAddModal: React.FC<OrganizationMemberAddModalProps> = ({
  refetchUsers,
}) => {
  const { organization } = useCurrentOrganization();
  const { isOpen, closeModal } = useModal(MODALS.ORGANIZATION_MEMBER_ADD_MODAL);

  const notification = useNotification();

  const rolesArray = Object.values(OrganizationRoles);
  const ROLES_OPTIONS = rolesArray.map(rol => ({
    label: rol || '',
    value: rol || '',
  }));
  const [addOrganizationMemberMutation] = useAddOrganizationMemberMutation();

  const onSubmit = async (campaignFaqData: any, form: FormApi) => {
    try {
      await addOrganizationMemberMutation({
        variables: {
          data: {
            firstName: campaignFaqData.firstName,
            lastName: campaignFaqData.lastName,
            email: campaignFaqData.email,
            contactPhone: {
              code: '1',
              number: campaignFaqData.phone.number,
            },
            // title: campaignFaqData.title,
            role: campaignFaqData.role,
            organizationName: organization?.name,
            organizationId: organization?.id,
          },
        },
      });

      notification.success(t('organization_add_member_success'));
      setTimeout(form.reset);
      closeModal();
      refetchUsers();
    } catch (error) {
      notification.error(t('organization_add_member_error'));
      console.error({ error });
    }
  };

  const onCloseModal = () => {
    closeModal();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onCloseModal}>
      <DialogTitle>
        <Grid>
          <IconButton
            aria-label="close"
            onClick={onCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
            size="large"
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid marginTop={4}>
          <Typography variant="h3" textAlign="center">
            Add Team Member
          </Typography>
          <Typography variant="body2" textAlign="center">
            Invite new team member to {organization?.name || ''}.
          </Typography>
        </Grid>
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        validate={values => validateWithSchema(OrganizationAddMemberSchema, values)}
      >
        {({ handleSubmit, submitting, hasValidationErrors, form }) => {
          const isLoading = submitting;
          const isSubmitDisabled = isLoading || hasValidationErrors;
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent sx={{ padding: theme => theme.spacing(4, 8, 4, 8) }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="firstName"
                      label="First Name"
                      required
                      component={TextField}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="lastName"
                      label="Last Name"
                      required
                      component={TextField}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      label="Email Address"
                      component={TextField}
                      required
                      e2e-id="email"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="phone.number"
                      label="Phone Number"
                      required
                      formatMask="+1 (###) ###-####"
                      isNumericString
                      component={NumberField}
                      fullWidth
                    />
                  </Grid>
                  {/*         <Grid item xs={12}>
                    <Field
                      fullWidth
                      name="title"
                      label="Title"
                      required
                      component={TextField}
                      disabled={isLoading}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <Field
                      name="role"
                      label="Rol"
                      component={SelectField}
                      options={ROLES_OPTIONS}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ padding: theme => theme.spacing(0, 0, 4, 0) }}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      style={{ width: 152 }}
                      size="large"
                      color="primary"
                      onClick={handleSubmit}
                      loading={isLoading}
                      disabled={isSubmitDisabled}
                    >
                      Send Invitation
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </form>
          );
        }}
      </Form>
    </Dialog>
  );
};
