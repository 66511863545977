import React from 'react';
import {
  DateRange as DateRangeIcon,
  Bolt as BoltIcon,
  List as ListIcon,
  Flag as FlagIcon,
  CategoryOutlined as CategoryIcon,
  AttachMoney as MoneyIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';

import {
  DateFilterSettings,
  CheckboxFilterSettings,
  RangeNumberFilterSettings,
} from '@vizsla/providers';
import { Button, FiltersLayout, NumberFieldProps } from '@vizsla/components';
import { MONEY_FORMAT } from '@vizsla/constants';
import { Description, OrderStatus } from '@vizsla/types';

type NumberFieldInputProps = Pick<NumberFieldProps, 'className' | 'numberFormat' | 'formatMask'>;

type TransactionCustomFilters =
  | CheckboxFilterSettings
  | RangeNumberFilterSettings
  | DateFilterSettings
  | RangeNumberFilterSettings<NumberFieldInputProps>;
const SUBTYPE_OPTIONS = [
  {
    label: 'Registration Option',
    value: Description.registrationOption,
  },
  {
    label: 'Donation',
    value: Description.donation,
  },
  {
    label: 'Swagbag',
    value: Description.swagbag,
  },
  {
    label: 'Asset',
    value: Description.asset,
  },
  {
    label: 'Refund',
    value: Description.refund,
  },
  {
    label: 'Note',
    value: Description.note,
  },
  {
    label: 'Discount',
    value: Description.discount,
  },
];
const STATUS_OPTIONS = [
  {
    label: 'Pending',
    value: OrderStatus.pending,
  },
  {
    label: 'Canceled',
    value: OrderStatus.canceled,
  },
  {
    label: 'Refused',
    value: OrderStatus.refused,
  },
  {
    label: 'Delivery',
    value: OrderStatus.delivery,
  },
  {
    label: 'Refunded',
    value: OrderStatus.refunded,
  },
  {
    label: 'Completed',
    value: OrderStatus.completed,
  },
  {
    label: 'Paid',
    value: OrderStatus.paid,
  },
];

const TRANSACTION_CUSTOM_FILTERS: TransactionCustomFilters[] = [
  {
    type: 'date',
    filterName: 'Date Range',
    dateFilterKey: 'transactionDateFilter',
    fromToFields: ['OrderDate', 'OrderDate'],
    icon: <DateRangeIcon />,
  },
  {
    type: 'checkbox',
    filterName: 'Status',
    filterValueName: 'OrderStatus',
    options: STATUS_OPTIONS,
    icon: <BoltIcon />,
  },
  {
    type: 'checkbox',
    filterName: 'Subtype',
    filterValueName: 'Ordersubtype',
    options: SUBTYPE_OPTIONS,
    icon: <ListIcon />,
  },
  {
    type: 'range-count',
    filterName: 'Amount Range',
    filterValueName: 'OrderItemsAmount',
    inputProps: {
      numberFormat: MONEY_FORMAT,
    },
    icon: <MoneyIcon />,
  },
];

export function TransactionListLayout({ children }) {
  return (
    <React.Fragment>
      <Grid padding={2} container spacing={2}>
        <Grid xs={6}>
          <Typography variant="h2">Transactions</Typography>
        </Grid>

        <Grid display="flex" alignItems="center" justifyContent="flex-end" xs={6}>
          {/* TODO: Out of the scope of VSL-675 */}
          <Button disabled startIcon={<AddIcon />}>
            Add Donation
          </Button>
        </Grid>
      </Grid>

      <FiltersLayout
        withSearch
        withPagination
        searchPlaceholder="Search by Experience, Campaign and Subtype"
        customFilters={TRANSACTION_CUSTOM_FILTERS}
      >
        {children}
      </FiltersLayout>
    </React.Fragment>
  );
}
