import React, { useContext } from 'react';
import { CardMedia, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';

import { File } from '@vizsla/graphql';
import { HeroMediaType } from '@vizsla/constants';

import { Theme } from 'src/providers/campaign/ThankYouLanding.types';
import { LandingStateContext } from 'src/providers/campaign/ThankYouLandingContext';

const useStyle = makeStyles<Theme, { theme: Theme }>({
  content: {
    height: '600px',
    position: 'relative',
    backgroundColor: props => props.theme.accentColor,
    zIndex: -1,

    '&>.MuiTypography-root': {
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, 0)',
    },
  },

  editorMessage: {
    color: 'white',
    cursor: 'default',
    textAlign: 'center',
    fontWeight: 600,
  },
});

SwiperCore.use([Autoplay]);

const EMPTY_MESSAGE = 'THERE ARE NO EXPERIENCE HEADER IMAGES';

interface ThankYouMediaSectionProps {
  heroMediaType: HeroMediaType;
  experienceHeaderImages: Array<File> | null;
  editorMode?: boolean;
}

export const ThankYouHeaderMediaSection: React.FC<ThankYouMediaSectionProps> = ({
  heroMediaType,
  experienceHeaderImages = [],
  editorMode,
}) => {
  const {
    state: { theme },
  } = useContext(LandingStateContext);

  const styles = useStyle({ theme });

  if (editorMode) {
    return (
      <div className={styles.content}>
        <Typography variant="body1" component="p" className={styles.editorMessage}>
          (EXPERIENCE IMAGE)
        </Typography>
      </div>
    );
  }

  if (experienceHeaderImages?.length === 0) {
    return (
      <div className={styles.content}>
        <Typography variant="body1" component="p" className={styles.editorMessage}>
          {EMPTY_MESSAGE}
        </Typography>
      </div>
    );
  }

  const renderMediaCards = () =>
    experienceHeaderImages?.map((heroImage: File) => (
      <SwiperSlide key={heroImage?.fileId}>
        <CardMedia
          component="img"
          height="600"
          image={heroImage?.downloadUrl || ''}
          alt="campaign image"
        />
      </SwiperSlide>
    ));

  if (heroMediaType === HeroMediaType.multiple) {
    return (
      <Swiper
        spaceBetween={30}
        centeredSlides
        slidesPerView={1}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className="mySwiper"
        style={{ zIndex: '-1', width: '100%' }}
      >
        {renderMediaCards()}
      </Swiper>
    );
  }

  return (
    <CardMedia
      component="img"
      height="600"
      image={experienceHeaderImages?.[0]?.downloadUrl || ''}
      alt="campaign image"
    />
  );
};
