import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Avatar,
  Box,
  Popover,
  ClickAwayListener,
  Divider,
  Typography,
  List,
  ListItemButton,
  ListItem,
  Theme,
  Skeleton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

import { Application } from '@vizsla/types';
import { CURRENT_USER_QUERY } from '@vizsla/graphql';
import { useChangeUserCurrentApp, useAppAuth, useSwitchUserRoleModal } from '@vizsla/hooks';
import { Button } from '@vizsla/components';

import { BorderRadius, PaletteColor, Shade } from 'src/theme';
import { getInitials } from 'src/utils/user';

interface MenuItem {
  label: string;
  linkTo: string;
}

const menuItems: MenuItem[] = [
  {
    label: 'My Profile',
    linkTo: '/myProfile',
  },
  {
    label: 'Contact Support',
    linkTo: '',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  logOutLink: {
    color: PaletteColor.ErrorMain,
  },
  avatar: {
    backgroundColor: PaletteColor.SecondaryMain,
  },
  popoverPaper: {
    borderRadius: BorderRadius.M,
    marginTop: theme.spacing(2),
    minWidth: 250,
  },
  popoverBlock: {
    padding: theme.spacing(4, 0, 3, 0),
  },
}));

export const ProfilePopover: React.FC = () => {
  const classes = useStyles();

  const { changeUserCurrentApp, updating: isAppChanging } = useChangeUserCurrentApp();
  const { openSwitchUserRoleModal } = useSwitchUserRoleModal();

  const { logout } = useAppAuth();

  const [anchorEl, setAnchorEl] = React.useState<Element | null>();

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const resetRoleForDebug = React.useCallback(async () => {
    await changeUserCurrentApp(Application.Unknown);
    await logout();
  }, [changeUserCurrentApp, logout]);

  const { loading, data } = useQuery(CURRENT_USER_QUERY);

  const initials = getInitials(data?.user);

  const isOpened = Boolean(anchorEl);

  return (
    <Box>
      {loading ? (
        <Skeleton animation="wave" variant="circular">
          <Avatar />
        </Skeleton>
      ) : (
        <React.Fragment>
          <Avatar onClick={handleClick} sx={{ cursor: 'pointer' }} className={classes.avatar}>
            {initials}
          </Avatar>
          <Popover
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorEl={anchorEl}
            open={isOpened}
            classes={{ paper: classes.popoverPaper }}
          >
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
              <Box>
                <Box className={classes.popoverBlock} textAlign="center">
                  <Box>
                    <Avatar sx={{ margin: '0 auto' }} className={classes.avatar}>
                      {initials}
                    </Avatar>
                  </Box>

                  <Box>
                    <Typography variant="subtitle2">
                      {data?.user?.firstName} {data?.user?.lastName}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="caption" color={Shade.Gray[400]}>
                      {data?.user?.email}
                    </Typography>
                  </Box>

                  <Box mt={2}>
                    <Button variant="hyperlink" onClick={() => openSwitchUserRoleModal()}>
                      Switch Role
                    </Button>
                  </Box>

                  <Box mt={0}>
                    <Button variant="hyperlink" onClick={resetRoleForDebug} loading={isAppChanging}>
                      Reset Role (debug)
                    </Button>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.popoverBlock}>
                  <List disablePadding>
                    {menuItems.map(item => (
                      <Link key={item.label} to={item.linkTo} style={{ textDecoration: 'none' }}>
                        <ListItem disablePadding>
                          <ListItemButton>
                            <Typography variant="body2">{item.label}</Typography>
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </Box>

                <Divider />

                <Button onClick={logout} variant="hyperlink" className={classes.logOutLink}>
                  <Typography color="inherit" variant="body2" padding={2}>
                    Log Out
                  </Typography>
                </Button>
              </Box>
            </ClickAwayListener>
          </Popover>
        </React.Fragment>
      )}
    </Box>
  );
};
