import React from 'react';

interface ContactUserIdContextType {
  userId: string;
  tabType: string;
}

const ContactUserIdContext = React.createContext<ContactUserIdContextType>(
  {} as ContactUserIdContextType,
);

export { ContactUserIdContext };
