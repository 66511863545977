import React from 'react';
import { Grid } from '@mui/material';
import { Form, FormProps } from 'react-final-form';
import { useMutation, useQuery } from '@apollo/client';

import { useApolloCacheQueryManager, useNotification } from '@vizsla/hooks';
import { Button } from '@vizsla/components';
import { concatDateWithTime, validateWithSchema } from '@vizsla/utils';
import { ExperienceType } from '@vizsla/types';
import {
  ExperienceUpdateInput,
  ExperienceUpdateMutation,
  ExperienceUpdateMutationVariables,
  ExperienceGeneralSettingsListQuery,
  ExperienceGeneralSettingsListQueryVariables,
  ExperienceWhiteListRegistrationExperienceRelationInput,
} from '@vizsla/graphql';

import { useExperienceId } from 'src/hooks/experiences';
import { DateType } from 'src/constants/campaign';
import { ExperiencePrivacy } from 'src/types/experience';
import {
  EXPERIENCE_GENERAL_SETTINGS_LIST_QUERY,
  EXPERIENCE_GENERAL_UPDATE_MUTATION,
} from 'src/graphql';
import { ExperienceVenueModal } from 'src/modals';
import { ExperienceGeneralSettingsUpdateSchema } from 'src/constants/validationSchemas/experience';
import { PaperBlock } from 'src/components/shared';
import { t } from 'src/utils/template';

import { WhereBlock } from './WhereBlock';
import { PrivacyBlock } from './PrivacyBlock';
import { GeneralBlock } from './GeneralBlock';
import { WhenBlock } from './WhenBlock';

export type ExperienceGeneralSettingsFormValues = {
  name?: string | null;
  experienceCategory?: string | null;
  dateRangeType?: DateType | null;
  startDate?: string | null;
  startTime?: string | null;
  endDate?: string | null;
  endTime?: string | null;
  hideFromPublic?: boolean | null;
  timeZone?: string | null;
  privacy?: ExperiencePrivacy | null;
  whiteListRegistrationExperience?: ExperienceWhiteListRegistrationExperienceRelationInput;
};

const SPACING = 3;

const prepareData = ({
  startDate: startDateFromState,
  startTime: startTimeFromState,
  endDate: endDateFromState,
  endTime: endTimeFromState,
  dateRangeType,
  whiteListRegistrationExperience: whiteListRegistrationExperienceId,
  ...rest
}: ExperienceGeneralSettingsFormValues) => {
  const startDate = concatDateWithTime(startDateFromState, startTimeFromState);
  const endDate =
    dateRangeType === DateType.Range
      ? concatDateWithTime(endDateFromState, endTimeFromState)
      : null;

  const whiteListRegistrationExperience = whiteListRegistrationExperienceId
    ? {
        connect: {
          id: whiteListRegistrationExperienceId,
        },
      }
    : null;

  return {
    startDate,
    endDate,
    dateRangeType,
    whiteListRegistrationExperience,
    ...rest,
  };
};

export const CampaignExperienceGeneralSettings: React.FC = () => {
  const experienceId = useExperienceId();

  const notification = useNotification();

  const { data, loading } = useQuery<ExperienceGeneralSettingsListQuery>(
    EXPERIENCE_GENERAL_SETTINGS_LIST_QUERY,
    {
      variables: {
        id: experienceId,
      },
      skip: !experienceId,
    },
  );

  const { updateQuery: updateExperienceGeneralSettingsQuery } = useApolloCacheQueryManager<
    ExperienceGeneralSettingsListQuery,
    ExperienceGeneralSettingsListQueryVariables
  >({
    query: EXPERIENCE_GENERAL_SETTINGS_LIST_QUERY,
    variables: {
      id: experienceId,
    },
    typeName: 'experience',
  });

  const [updateExperienceGeneralSettings, { loading: updating }] = useMutation<
    ExperienceUpdateMutation,
    ExperienceUpdateMutationVariables
  >(EXPERIENCE_GENERAL_UPDATE_MUTATION);

  const experience = data?.experience;
  const experienceType = experience?.experienceType;

  const venues = experience?.venues?.items || [];

  const initialValues: ExperienceGeneralSettingsFormValues = {
    name: experience?.name || null,
    startDate: experience?.startDate || null,
    startTime: experience?.startDate || null,
    endDate: experience?.endDate || null,
    endTime: experience?.endDate || null,
    experienceCategory: experience?.experienceCategory || null,
    privacy: (experience?.privacy as ExperiencePrivacy) || null,
    hideFromPublic: experience?.hideFromPublic ?? false,
    timeZone: experience?.timeZone || null,
    dateRangeType: (experience?.dateRangeType as DateType) || null,
    whiteListRegistrationExperience:
      (experience?.whiteListRegistrationExperience?.id as any) || null,
  };

  const onSubmit: FormProps['onSubmit'] = async values => {
    try {
      const { data } = await updateExperienceGeneralSettings({
        variables: {
          filter: {
            id: experienceId,
          },
          data: prepareData(values) as ExperienceUpdateInput,
        },
      });

      const updatedExperience = data?.experienceUpdate;

      if (updatedExperience) {
        updateExperienceGeneralSettingsQuery(query => {
          query.experience = updatedExperience;
        });
      }
      notification.success(t('experience_general_settings_update_success'));
    } catch (error) {
      console.error({ error });
      notification.error(t('experience_general_settings_update_error'));
    }
  };

  return (
    <PaperBlock loading={loading} title="General Settings" collapsing defaultOpened>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={values => validateWithSchema(ExperienceGeneralSettingsUpdateSchema, values)}
      >
        {({ handleSubmit, values, form, pristine, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={SPACING}>
              <GeneralBlock spacing={SPACING} experienceType={experienceType} />
              <WhenBlock spacing={SPACING} values={values} />
              <WhereBlock venues={venues} />
              {experienceType === ExperienceType.ticketing && <PrivacyBlock />}
              <Grid item container justifyContent="center">
                <Grid item>
                  <Button onClick={form.reset} disabled={pristine} variant="text">
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={pristine || updating || hasValidationErrors}
                    type="submit"
                    loading={updating}
                  >
                    {pristine ? 'Saved' : 'Save'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
      <ExperienceVenueModal />
    </PaperBlock>
  );
};
