import {
  LandingState,
  LandingAction,
  ExperienceLandingActions,
  GeneralLandingActions,
} from 'src/providers/campaign/ThankYouLanding.types';

import * as actions from './ThankYouStateActions';

function thankYouReducer(
  state: LandingState,
  action: LandingAction<ExperienceLandingActions | GeneralLandingActions>,
) {
  switch (action.type) {
    case ExperienceLandingActions.updateAccentColor: {
      return actions.updateAccentColor(state, action.payload);
    }
    case ExperienceLandingActions.updateColorLibrary: {
      return actions.updateColorLibrary(state, action.payload);
    }
    case ExperienceLandingActions.updateProgressBarColor: {
      return actions.updateProgressBarColor(state, action.payload);
    }
    case ExperienceLandingActions.updatePageBackgroundType: {
      return actions.updatePageBackgroundType(state, action.payload);
    }
    case ExperienceLandingActions.updatePageBackgroundFirstColor: {
      return actions.updatePageBackgroundFirstColor(state, action.payload);
    }
    case ExperienceLandingActions.updatePageBackgroundSecondColor: {
      return actions.updatePageBackgroundSecondColor(state, action.payload);
    }
    case ExperienceLandingActions.updateRegistrationButtonLabel: {
      return actions.updateRegistrationButtonLabel(state, action.payload);
    }
    case ExperienceLandingActions.updateRegistrationButtonBackgrondType: {
      return actions.updateRegistrationButtonBackgrondType(state, action.payload);
    }
    case ExperienceLandingActions.updateIsRegistrationButtonVisible: {
      return actions.updateIsRegistrationButtonVisible(state, action.payload);
    }
    case ExperienceLandingActions.updateDonationButtonLabel: {
      return actions.updateDonationButtonLabel(state, action.payload);
    }
    case ExperienceLandingActions.updateDonationButtonBackgrondType: {
      return actions.updateDonationButtonBackgrondType(state, action.payload);
    }
    case ExperienceLandingActions.updateIsDonationButtonVisible: {
      return actions.updateIsDonationButtonVisible(state, action.payload);
    }
    case ExperienceLandingActions.updateShareButtonLabel: {
      return actions.updateShareButtonLabel(state, action.payload);
    }
    case ExperienceLandingActions.updateShareButtonBackgrondType: {
      return actions.updateShareButtonBackgrondType(state, action.payload);
    }
    case ExperienceLandingActions.updateIsShareButtonVisible: {
      return actions.updateIsShareButtonVisible(state, action.payload);
    }
    case ExperienceLandingActions.updateIsProgressBarRaisedAmountVisible: {
      return actions.updateIsProgressBarRaisedAmountVisible(state, action.payload);
    }
    case ExperienceLandingActions.updateRegistrationsSectionTitle: {
      return actions.updateRegistrationsSectionTitle(state, action.payload);
    }
    case ExperienceLandingActions.updateRegistrationsSectionDescription: {
      return actions.updateRegistrationsSectionDescription(state, action.payload);
    }
    case ExperienceLandingActions.updateIsProgressBarGoalAmountVisible: {
      return actions.updateIsProgressBarGoalAmountVisible(state, action.payload);
    }
    case ExperienceLandingActions.resetThemeColors: {
      return actions.resetThemeColors(state);
    }
    case ExperienceLandingActions.updateHeroMediaType: {
      return actions.updateHeroMediaType(state, action.payload);
    }
    case ExperienceLandingActions.updateHeaderLogoAlign: {
      return actions.updateHeaderLogoAlign(state, action.payload);
    }
    case ExperienceLandingActions.updateIsLeaderboardVisible: {
      return actions.updateIsLeaderboardVisible(state, action.payload);
    }
    case ExperienceLandingActions.updateIsActivityVisible: {
      return actions.updateIsActivityVisible(state, action.payload);
    }
    case ExperienceLandingActions.updateisOurStoryTitleVisible: {
      return actions.updateisOurStoryTitleVisible(state, action.payload);
    }
    case ExperienceLandingActions.updateisThankYouMessageVisible: {
      return actions.updateisThankYouMessageVisible(state, action.payload);
    }
    case ExperienceLandingActions.updateisDonationInformationTitleVisible: {
      return actions.updateisDonationInformationTitleVisible(state, action.payload);
    }
    case ExperienceLandingActions.updateisDonationInformationDescriptionVisible: {
      return actions.updateisDonationInformationDescriptionVisible(state, action.payload);
    }
    case ExperienceLandingActions.updateisManageDonationTitleVisible: {
      return actions.updateisManageDonationTitleVisible(state, action.payload);
    }
    case ExperienceLandingActions.updateisManageDonationDescriptionVisible: {
      return actions.updateisManageDonationDescriptionVisible(state, action.payload);
    }
    case ExperienceLandingActions.updateisHelpTitleVisible: {
      return actions.updateisHelpTitleVisible(state, action.payload);
    }
    case ExperienceLandingActions.updateisHelpDescriptionVisible: {
      return actions.updateisHelpDescriptionVisible(state, action.payload);
    }
    case ExperienceLandingActions.updateOurStorySectionTitle: {
      return actions.updateOurStorySectionTitle(state, action.payload);
    }
    case ExperienceLandingActions.updateThankYouMessage: {
      return actions.updateThankYouMessage(state, action.payload);
    }
    case ExperienceLandingActions.updateRegistrationsSectionDisplayStyle: {
      return actions.updateRegistrationsSectionDisplayStyle(state, action.payload);
    }
    case GeneralLandingActions.set: {
      return actions.set(action.payload);
    }

    case ExperienceLandingActions.UPDATE_GALLERY_TITLE: {
      return actions.updateGalleryTitle(state, action.payload);
    }

    case ExperienceLandingActions.UPDATE_GALLERY_DESCRIPTION: {
      return actions.updateGalleryDescription(state, action.payload);
    }

    case ExperienceLandingActions.UPDATE_GALLERY_IMAGES_PER_ROWS: {
      return actions.updateGalleryImagesPerRows(state, action.payload);
    }

    case ExperienceLandingActions.UPDATE_GALLERY_ROWS: {
      return actions.updateGalleryRows(state, action.payload);
    }

    case ExperienceLandingActions.UPDATE_GALLERY_IMAGES: {
      return actions.updateGalleryImages(state, action.payload);
    }

    default: {
      return state;
    }
  }
}
export { thankYouReducer };
