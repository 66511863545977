import React from 'react';
import { Grid } from '@mui/material';
import { Form } from 'react-final-form';
import { FormApi } from 'final-form';

import {
  useNotification,
  usePaginationQueryVariables,
  useSearchQueryVariables,
  useCurrentOrganization,
} from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { Modal } from '@vizsla/components';
import { ALL_STORE_SIZES, ALL_STORE_STYLES } from '@vizsla/constants';

import { useStoreAssetsDashboardData } from 'src/hooks/storeAssets';
import { StoreAssetCreateOrUpdateFormSchema } from 'src/constants/validationSchemas/storeAssets';
import type { StoreAssetCreateOrUpdateFormValues } from 'src/types/storeAssets';
import { t } from 'src/utils/template';
import { resetFieldMutators } from 'src/utils/form';
import {
  StoreAssetFormAdditionalInformationSection,
  StoreAssetFormAvailabilitySection,
  StoreAssetFormStockControlSection,
  StoreAssetFormAttributesSection,
} from 'src/components/storeAssets/sharedFormSections';

import { getStoreAssetCreateDataInput } from '../../utils';
import { FormCommonInfoSection } from './sections';

interface StoreAssetCreateFormProps {
  closeModal: () => void;
}

const StoreAssetCreateForm: React.FC<StoreAssetCreateFormProps> = ({ closeModal }) => {
  const { organizationId, organizationIsLoading } = useCurrentOrganization();
  const searchQueryVariables = useSearchQueryVariables('name');
  const paginationQueryVariables = usePaginationQueryVariables();

  const { createStoreAsset, creating } = useStoreAssetsDashboardData(
    organizationId || undefined,
    paginationQueryVariables,
    searchQueryVariables,
  );

  const notification = useNotification();

  const initialValues: StoreAssetCreateOrUpdateFormValues = {
    storeAvailability: true,
    swagbagAvailability: false,
    continueSalesWhenOutOfStock: false,
    notifyWhenStockMinimumIsAchieved: false,
  };

  const onSubmit = React.useCallback(
    async (formValues: StoreAssetCreateOrUpdateFormValues, form: FormApi<any>) => {
      const filterVAriantPossible = variantsPossible.reduce((accum, variant) => {
        const key = Object.keys(variant).filter(
          variant => variant !== 'id' && variant !== 'isDeletable',
        )[0];
        return { ...accum, [key]: variant[key] };
      }, {} as any);
      const data = getStoreAssetCreateDataInput(formValues, organizationId ?? '');
      const inputData = { ...data, variantsPossible: filterVAriantPossible };
      try {
        await createStoreAsset(inputData);
        notification.success(t('store_asset_create_success'));
        closeModal();
      } catch {
        notification.error(t('store_asset_create_error'));
      }
    },
    [closeModal, notification, organizationId, createStoreAsset],
  );

  const validate = React.useCallback(async (formValues: StoreAssetCreateOrUpdateFormValues) => {
    return validateWithSchema(StoreAssetCreateOrUpdateFormSchema, formValues);
  }, []);

  const [variantsPossible, setVariantsPossible] = React.useState([
    { Size: [...ALL_STORE_SIZES], id: 1 },
    { Style: [...ALL_STORE_STYLES], id: 2 },
  ]);

  const loading = creating || organizationIsLoading;

  return (
    <Form
      initialValues={initialValues}
      mutators={{ ...resetFieldMutators() }}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting, pristine, validating, invalid }) => {
        const isSubmitLoading = submitting || validating || loading;
        const isSubmitDisabled = isSubmitLoading || pristine || validating || invalid;
        return (
          <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <FormCommonInfoSection disabled={submitting} />

              <StoreAssetFormAttributesSection
                disabled={submitting}
                setVariantsPossible={setVariantsPossible}
                variantsPossible={variantsPossible}
              />

              <StoreAssetFormAvailabilitySection disabled={submitting} />

              <StoreAssetFormStockControlSection disabled={submitting} />

              <StoreAssetFormAdditionalInformationSection disabled={submitting} />

              <Grid item xs={12}>
                <Modal.Actions>
                  <Modal.Action
                    type="secondary"
                    content="Cancel"
                    onAction={closeModal}
                    disabled={isSubmitDisabled}
                  />

                  <Modal.Action
                    type="primary"
                    content="Add"
                    onAction={handleSubmit}
                    disabled={isSubmitDisabled}
                    loading={isSubmitLoading}
                  />
                </Modal.Actions>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export { StoreAssetCreateForm };
