import React from 'react';
import { Grid, Tabs, Tab } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { buildUrl } from '@vizsla/utils';

import * as entitiesId from 'src/constants/entities-id';
import * as appRoutes from 'src/constants/routes';
import { useStoreAssetId } from 'src/hooks/storeAssets';

type TabData = {
  id: string;
  label: string;
  url: string;
};

const StoreAssetManagementTabs: React.FC = ({ children }) => {
  const navigate = useNavigate();

  const storeAssetId = useStoreAssetId();

  const TABS = React.useMemo(() => {
    const getUrl = (route: string) => buildUrl(route, { [entitiesId.storeAsset]: storeAssetId });

    const tabs: Array<TabData> = [
      {
        id: 'details',
        label: 'Details',
        url: getUrl(appRoutes.storeAssetsAssetDetails),
      },
      {
        id: 'inventory',
        label: 'Inventory',
        url: getUrl(appRoutes.storeAssetsAssetInventory),
      },
    ];

    return tabs;
  }, [storeAssetId]);

  const defaultTab = TABS[0];

  const [currentTab, setCurrentTab] = React.useState(defaultTab.id);

  React.useEffect(() => {
    const tabInfo = TABS.find(tabData => tabData.id === currentTab);
    const tabUrl = tabInfo?.url || defaultTab.url;

    navigate(tabUrl);
  }, [TABS, currentTab, defaultTab, navigate]);

  const handleTabChange = React.useCallback((_: any, tabId: string) => {
    setCurrentTab(tabId);
  }, []);

  if (!currentTab) {
    return null;
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Tabs value={currentTab} onChange={handleTabChange} centered>
          {TABS.map(tabData => (
            <Tab key={tabData.id} value={tabData.id} label={tabData.label} />
          ))}
        </Tabs>
      </Grid>

      <Grid item container>
        {children}
      </Grid>
    </React.Fragment>
  );
};

export { StoreAssetManagementTabs };
