import React from 'react';

import { TableData, UserAvatarFullNameInline, TableColumn } from '@vizsla/components';
import { useModal, usePagination } from '@vizsla/hooks';
import { DateFormatPatterns, NO_APPLY_LABEL, NO_VALUE_LABEL } from '@vizsla/constants';
import { formatDate, formatMoney } from '@vizsla/utils';

import { MODALS } from 'src/constants/modals';

import { DonorCRMRow, useDonorsCRM } from './useDonorsCRM';

// TODO: fix when the actual requirements come
const DONORS_TABLE_COLUMNS: TableColumn<DonorCRMRow>[] = [
  {
    title: 'NAME',
    key: 'name',

    render(root, user) {
      if (!user) return NO_APPLY_LABEL;
      return <UserAvatarFullNameInline user={user} />;
    },
  },

  {
    title: 'CAMPAIGNS',
    key: 'campgaigns',
    dataPath: 'campaigns',

    render(root, user) {
      const [campaigns] = user.campaigns?.groups ?? [];

      return campaigns?.count ?? NO_APPLY_LABEL;
    },
  },

  {
    title: 'TOTAL DONATED',
    key: 'totalDonated',

    render(root, user) {
      const [donated] = user.donated?.groups ?? [];

      return formatMoney(donated?.total ?? 0);
    },
  },

  {
    title: 'LAST ACTIVITY',
    key: 'lastActivity',

    render(root, user) {
      const [donation] = user.allocatedDonations?.items ?? [];

      if (!donation) return NO_VALUE_LABEL;
      return formatDate(donation.createdAt, DateFormatPatterns.fullMonthDayYear);
    },
  },
];

export function DonorsTable() {
  const pagination = usePagination();

  const { data, loading } = useDonorsCRM();
  const { openModal } = useModal();

  const handleOpenRow = (row: DonorCRMRow) => {
    openModal(MODALS.PEOPLE_CRM_CONTACT_INFO_MODAL, { userId: row.id });
  };

  return (
    <TableData
      dataSource={data}
      columns={DONORS_TABLE_COLUMNS}
      emptyMessage="There are no donors in the organization"
      loading={loading}
      pagination={pagination}
      onRowClick={handleOpenRow}
    />
  );
}
