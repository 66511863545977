import { InboxMessageFilterOption, InboxTypeFilterOption } from '@vizsla/types';
export const INBOX_MESSAGE_FILTER_OPTIONS = [
    InboxMessageFilterOption.Read,
    InboxMessageFilterOption.Unread,
    InboxMessageFilterOption.Sent,
];
export const INBOX_TYPES_FILTER_OPTIONS = [
    InboxTypeFilterOption.DirectMessage,
    InboxTypeFilterOption.NewDonor,
    InboxTypeFilterOption.ReturningDonor,
    InboxTypeFilterOption.FromOrganizations,
    InboxTypeFilterOption.Notification,
    InboxTypeFilterOption.Support,
];
export const INBOX_FILTER_OPTIONS = [
    ...INBOX_MESSAGE_FILTER_OPTIONS,
    ...INBOX_TYPES_FILTER_OPTIONS,
];
