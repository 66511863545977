import React from 'react';
import _ from 'lodash';
import { Box, CircularProgress, Switch } from '@mui/material';

import { useNotification } from '@vizsla/hooks';
import { CampaignQuestion, CampaignQuestionType } from '@vizsla/types';

import { t } from 'src/utils/template';

interface CampaignQuestionEnabledSwitchProps {
  isEnabled: boolean;
  isMandatory: boolean;
  question: CampaignQuestion;
  onToggle: (
    questionId: string | null | undefined,
    isEnabled: boolean,
    type: CampaignQuestionType,
  ) => Promise<CampaignQuestion | null>;
}

export const CampaignQuestionEnabledSwitch: React.FC<CampaignQuestionEnabledSwitchProps> = ({
  isEnabled,
  isMandatory,
  question: campaignQuestion,
  onToggle,
}) => {
  const notification = useNotification();

  const [isUpdating, setUpdating] = React.useState<boolean>(false);

  const onToggleIsEnabled = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isMandatory) {
      return;
    }

    const { checked: isEnabled } = event.target;
    const questionType = !_.isNil(campaignQuestion?.question)
      ? CampaignQuestionType.Default
      : CampaignQuestionType.Custom;

    try {
      setUpdating(true);
      await onToggle(campaignQuestion?.id, isEnabled, questionType);

      notification.success(
        t('campaign_question_toggle_enable_success', {
          action: isEnabled ? 'enabled' : 'disabled',
        }),
      );
    } catch (e) {
      notification.error(
        t('campaign_question_toggle_enable_error', {
          action: isEnabled ? 'enable' : 'disable',
        }),
      );

      console.error(e);
    } finally {
      setUpdating(false);
    }
  };

  if (isUpdating) {
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center" pr={2}>
        <CircularProgress size={20} />
      </Box>
    );
  }

  return (
    <Switch
      disabled={isMandatory}
      checked={isMandatory || isEnabled}
      onChange={onToggleIsEnabled}
    />
  );
};
