import React from 'react';
import { Grid, Box } from '@mui/material';

import { Field } from '@vizsla/components';

import { ImageInputField } from 'src/components/forms';

const LOGO_IMAGE_DIM = [300, 120] as [number, number];
const COVER_IMAGE_DIM = [900, 600] as [number, number];

export const CampaignUploadLogoForm: React.FC = () => {
  return (
    <Box mb={2}>
      <Grid container flexDirection="column" alignItems="center" justifyContent="center">
        <Grid item xs={12} mb={2}>
          <Field
            name="logo"
            label="Add Campaign Logo"
            width={300}
            height={120}
            imageDim={LOGO_IMAGE_DIM}
            component={ImageInputField}
          />
        </Grid>
        <Grid item xs={12} mb={2}>
          <Field
            name="image"
            label="Add Campaign Image"
            height={200}
            width={300}
            imageDim={COVER_IMAGE_DIM}
            component={ImageInputField}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
