import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Link } from '@mui/material';

import { Button } from '@vizsla/components';

import { FontSize } from 'src/theme';

const useStyles = makeStyles(() => ({
  navigationButtonContainer: {
    flex: 1,
    alignItems: 'center',
    '& .MuiLink-button': {
      fontSize: FontSize.Default,
    },
    '& .MuiGrid-container': {
      height: '100%',
    },
  },
}));

interface CampaignRegistrationStepperControllerProps {
  canContinue: boolean;
  onContinue: () => void;
  onBack?: () => void;
  onSkip?: () => void;
  canCancel?: boolean;
  onCancel?: () => void;
  onCreate?: () => void;
  shouldShowBackButton?: boolean;
  shouldShowSkipButton?: boolean;
  isCreating?: boolean;
  isLastStep?: boolean;
}

export const CampaignRegistrationStepperController: React.FC<
  CampaignRegistrationStepperControllerProps
> = props => {
  const {
    onBack = () => null,
    onSkip = () => null,
    onCancel = () => null,
    onContinue = () => null,
    onCreate = () => null,
    isCreating = false,
    shouldShowBackButton = false,
    shouldShowSkipButton = false,
    isLastStep = false,
    canContinue = true,
    canCancel = true,
  } = props;
  const classes = useStyles();

  const renderBackButton = () => {
    if (!shouldShowBackButton) {
      return null;
    }

    return (
      <Link component="button" variant="body2" onClick={onBack}>
        Back
      </Link>
    );
  };

  const renderSkipButton = () => {
    if (shouldShowSkipButton) {
      return (
        <Link component="button" variant="body2" onClick={onSkip}>
          Skip
        </Link>
      );
    }

    return null;
  };

  const renderContinueOrCreateButton = () => {
    if (!isLastStep) {
      return (
        <Button
          variant="contained"
          style={{ width: 100 }}
          size="large"
          color="primary"
          onClick={onContinue}
          disabled={!canContinue}
        >
          Continue
        </Button>
      );
    }

    return (
      <Button
        variant="contained"
        style={{ width: 100 }}
        size="large"
        color="primary"
        type="submit"
        onClick={onCreate}
        loading={isCreating}
        disabled={!canContinue || isCreating}
      >
        Create
      </Button>
    );
  };

  return (
    <Grid container p={1}>
      <Grid className={classes.navigationButtonContainer}>
        <Grid container>{renderBackButton()}</Grid>
      </Grid>
      <Grid item>
        <Button
          variant="text"
          size="large"
          color="info"
          style={{ width: 100 }}
          onClick={onCancel}
          disabled={!canCancel}
        >
          Cancel
        </Button>
        {renderContinueOrCreateButton()}
      </Grid>
      <Grid className={classes.navigationButtonContainer}>
        <Grid container justifyContent="flex-end">
          {renderSkipButton()}
        </Grid>
      </Grid>
    </Grid>
  );
};
