import React from 'react';
import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, Grid, Typography } from '@mui/material';
import { omit } from 'lodash';

import { useCreatePartnershipCommitmentMutation } from '@vizsla/graphql';
// IMPORTANT add all validation schemas from invision
import { useModal, useNotification } from '@vizsla/hooks';
import { MODALS } from '@vizsla/constants';

import { CloseButtonIcon } from './styles';
import { CommitmentTypesE, PartnershipCommitmentDBRowT, PartnershipCommitmentT } from '../types';
import { CommitmentsSwitch } from './CommitmentsSwitch';

export const CreatePartnershipCommitmentModal: React.FC = () => {
  const { isOpen, closeModal: close, args } = useModal(MODALS.PARTNERSHIP_COMMITMENT_CREATE);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        close();
      }}
      maxWidth="lg"
    >
      <DialogTitle>
        <Grid>
          <CloseButtonIcon
            aria-label="close"
            onClick={() => {
              close();
            }}
            size="large"
          >
            <Close />
          </CloseButtonIcon>
        </Grid>
        <Grid marginTop={4}>
          <Typography variant="h3" textAlign="center">
            Add Commitment
          </Typography>
        </Grid>
      </DialogTitle>
      <CommitmentsSwitch />
    </Dialog>
  );
};
