import React from 'react';

import { PaginationProvider } from '@vizsla/providers';

import { StoreAssetEventFilterProvider } from 'src/providers/storeAssets';
import {
  StoreAssetInventoryAddModal,
  StoreAssetInventoryAdjustModal,
} from 'src/modals/storeAssets';

import { StoreAssetManagementInventoryTab } from './StoreAssetManagementInventoryTab';

const StoreAssetManagementInventoryTabContainer: React.FC = () => {
  return (
    <StoreAssetEventFilterProvider>
      <PaginationProvider>
        <StoreAssetManagementInventoryTab />
        <StoreAssetInventoryAddModal />
        <StoreAssetInventoryAdjustModal />
      </PaginationProvider>
    </StoreAssetEventFilterProvider>
  );
};

export { StoreAssetManagementInventoryTabContainer };
