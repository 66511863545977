import React from 'react';
import { Grid, styled } from '@mui/material';
import { Form } from 'react-final-form';

import { useExperienceImages, useNotification } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { Modal, Field } from '@vizsla/components';
import { File } from '@vizsla/graphql';

import { t } from 'src/utils/template';
import { ImageInputField } from 'src/components/forms';
import { ExperienceHeaderImageUpdateSchema } from 'src/constants/validationSchemas/experience';
import { useExperienceId } from 'src/hooks/experiences';

import { getImageDataFromValue } from '../utils';

const StyledFormNode = styled('form')(({ theme }) => ({
  display: 'contents',
}));

interface ExperienceHeaderImageEditFormProps {
  experienceHeaderImage: File;
  closeModal: () => void;
}

export const ExperienceHeaderImageEditForm: React.FC<ExperienceHeaderImageEditFormProps> = ({
  experienceHeaderImage,
  closeModal,
}) => {
  const notification = useNotification();
  const experienceId = useExperienceId();

  const initialValues = {
    experienceHeaderImage,
  };

  const { updateExperienceHeaderImage, updatingHeaderImages } = useExperienceImages(experienceId);

  const onSubmit = React.useCallback(
    async (formValues: Record<string, any>) => {
      const preparedImage = getImageDataFromValue(formValues?.experienceHeaderImage);
      try {
        await updateExperienceHeaderImage(preparedImage, experienceHeaderImage.id as string);
        notification.success(t('experience_header_images_update_success'));
        closeModal();
      } catch (err) {
        notification.error(t('experience_header_images_update_error'));
      }
    },
    [closeModal, experienceHeaderImage, notification, updateExperienceHeaderImage],
  );

  const validate = React.useCallback(async (formValues: Record<string, any>) => {
    return validateWithSchema(ExperienceHeaderImageUpdateSchema, formValues);
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({ handleSubmit, submitting, pristine, validating, invalid }) => {
        const isSubmitLoading = submitting || validating || updatingHeaderImages;
        const isSubmitDisabled = isSubmitLoading || pristine || invalid;

        return (
          <StyledFormNode onSubmit={handleSubmit}>
            <Grid container alignItems="center" direction="column">
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="experienceHeaderImage"
                  label="New Image"
                  width={300}
                  height={200}
                  component={ImageInputField}
                  maxFiles={1}
                  imageDim={[1200, 400]}
                />
              </Grid>

              <Grid item xs={12} mt={2}>
                <Modal.Actions>
                  <Modal.Action
                    type="secondary"
                    content="Cancel"
                    onAction={closeModal}
                    disabled={isSubmitDisabled}
                  />

                  <Modal.Action
                    type="primary"
                    content="Save"
                    onAction={handleSubmit}
                    disabled={isSubmitDisabled}
                    loading={isSubmitLoading}
                  />
                </Modal.Actions>
              </Grid>
            </Grid>
          </StyledFormNode>
        );
      }}
    />
  );
};
