import React from 'react';
import { Grid, Typography } from '@mui/material';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';

import { Field, DateField, TimeField, NumberField, RadioGroupField } from '@vizsla/components';
import { DateFormatPatterns } from '@vizsla/constants';
import { getNowDateTime, toDateTimeFromISO } from '@vizsla/utils';

import { SwitchSectionField } from 'src/components/forms';
import { MONEY_FORMAT } from 'src/constants/formats';
import { CHECKPOINT_NOTIFICATION_OPTIONS } from 'src/constants/registrationOption';

export const RegistrationOptionCreateFormStepFour: React.FC = () => {
  const minStartDate = toDateTimeFromISO(getNowDateTime());

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        return (
          <Grid container spacing={2} xs={12}>
            <Grid item xs={12}>
              <SwitchSectionField
                name="fundraisingMinimumEnabled"
                heading="Fundraising Minimum"
                fieldsToResetOnUncheck={['fundraisingMinimum']}
              >
                <Grid item xs={12} marginBottom={2}>
                  <Typography variant="body2">
                    Establish the minimum fundraising required to attend. Once a registration is
                    purchased, the minimum commitment can not be edited.
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Field
                      disabled={!values.fundraisingMinimumEnabled}
                      name="fundraisingMinimum"
                      label="Minimum Fundraising (USD)"
                      numberFormat={MONEY_FORMAT}
                      component={NumberField}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </SwitchSectionField>
            </Grid>

            <Grid item xs={12}>
              <SwitchSectionField
                name="checkpointEnabled"
                heading="Checkpoint"
                fieldsToResetOnUncheck={[
                  'checkpointAmount',
                  'checkpointDate',
                  'checkpointNotification',
                ]}
              >
                <Grid item xs={12} marginBottom={2}>
                  <Typography variant="body2">
                    Provide notification if specified amount has not been met by the specified date.
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Field
                      name="checkpointAmount"
                      label="Amount (USD)"
                      component={NumberField}
                      numberFormat={MONEY_FORMAT}
                      disabled={!values.checkpointEnabled}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="checkpointDate"
                      label="Date"
                      component={DateField}
                      inputFormat={DateFormatPatterns.shortMonthDayYear}
                      minDate={minStartDate}
                      fullWidth
                      disabled={!values.checkpointEnabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      disabled={!values.checkpointEnabled}
                      name="checkpointNotification"
                      options={CHECKPOINT_NOTIFICATION_OPTIONS}
                      component={RadioGroupField}
                    />
                  </Grid>
                </Grid>
              </SwitchSectionField>
            </Grid>
            <Grid item xs={12}>
              <SwitchSectionField
                name="registrationAvailabilityEnabled"
                heading="Registration Availability"
                fieldsToResetOnUncheck={[
                  'registrationStartDate',
                  'registrationStartTime',
                  'registrationEndDate',
                  'registrationEndTime',
                ]}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      required
                      name="registrationStartDate"
                      label="Start Date"
                      component={DateField}
                      inputFormat={DateFormatPatterns.shortMonthDayYear}
                      minDate={minStartDate}
                      fullWidth
                      disabled={!values.registrationAvailabilityEnabled}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      required
                      name="registrationStartTime"
                      label="Start Time"
                      component={TimeField}
                      fullWidth
                      disabled={!values.registrationAvailabilityEnabled}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginTop={1}>
                  <Grid item xs={6}>
                    <FormSpy subscription={{ values: true }}>
                      {(spyRenderProps: FormSpyRenderProps) => {
                        const { registrationStartDate, registrationEndDate } =
                          spyRenderProps.values;
                        const minEndDate = toDateTimeFromISO(registrationStartDate || minStartDate);

                        if (registrationStartDate && !registrationEndDate) {
                          spyRenderProps.form.change('registrationEndDate', registrationStartDate);
                        }

                        return (
                          <Field
                            label="End Date"
                            name="registrationEndDate"
                            component={DateField}
                            inputFormat={DateFormatPatterns.shortMonthDayYear}
                            disabled={!values.registrationAvailabilityEnabled}
                            minDate={minEndDate}
                            fullWidth
                            required
                          />
                        );
                      }}
                    </FormSpy>
                  </Grid>

                  <Grid item xs={6}>
                    <FormSpy subscription={{ values: true }}>
                      {(spyRenderProps: FormSpyRenderProps) => {
                        const { registrationStartTime } = spyRenderProps.values;

                        return (
                          <Field
                            required
                            name="registrationEndTime"
                            label="End Time"
                            component={TimeField}
                            minTime={toDateTimeFromISO(registrationStartTime)}
                            fullWidth
                            disabled={!values.registrationAvailabilityEnabled}
                          />
                        );
                      }}
                    </FormSpy>
                  </Grid>
                </Grid>
              </SwitchSectionField>
            </Grid>
          </Grid>
        );
      }}
    </FormSpy>
  );
};
