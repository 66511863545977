import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Form } from 'react-final-form';
import { FormApi } from 'final-form';

import { useModal, useNotification } from '@vizsla/hooks';
import { Field, TextField, Modal } from '@vizsla/components';
import { Campaign, useCampaignDeleteMutation } from '@vizsla/graphql';

import { CampaignTile } from 'src/components/campaign';
import { t } from 'src/utils/template';
import { MODALS } from 'src/constants/modals';
import { CampaignStatus } from 'src/types/campaign';

const CampaignDeleteModal: React.FC = () => {
  const { isOpen, closeModal, args } = useModal(MODALS.CAMPAIGN_DELETE_MODAL);

  const notification = useNotification();

  const [campaignDeleteMutation, { loading }] = useCampaignDeleteMutation({
    refetchQueries: ['CampaignsPage'],
  });

  const initialValues = {
    campaignName: '',
  };

  const campaign = args?.campaign as Campaign | undefined;
  if (!campaign) {
    return null;
  }

  const onCancel = (form: FormApi) => {
    closeModal();
    form?.reset();
  };

  const checkNotEqualToCampaignName = (value: string) => value !== campaign.name;

  const onSubmit = async (values: Record<string, any>, form: FormApi) => {
    try {
      if (campaign.campaignStatus !== CampaignStatus.draft) {
        notification.error(t('campaign_status_error'));
        return;
      }

      await campaignDeleteMutation({
        variables: { data: { id: campaign.id, force: true } },
      });

      notification.success(t('campaign_delete_success'));
      onCancel(form);
    } catch {
      notification.error(t('campaign_delete_error'));
    }
  };

  return (
    <Form
      onSubmit={onSubmit as any}
      initialValues={initialValues}
      render={({ form, handleSubmit, invalid }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Modal isOpen={isOpen} onClose={() => onCancel(form)}>
              <Modal.Content>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item>
                    <IconButton
                      aria-label="close"
                      onClick={() => onCancel(form)}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500],
                      }}
                      size="large"
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography variant="h2">Delete Campaign</Typography>
                  </Grid>
                  <Grid container justifyContent="center" item>
                    <Grid item>
                      <Typography variant="body1">
                        Are you sure you want to delete the following campaign?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">This action can&apos;t be reversed.</Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={7}>
                    <CampaignTile campaign={campaign} hideMetrics />
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container justifyContent="center">
                      <Grid container>
                        <Typography variant="subtitle2">
                          Enter campaign name to confirm deletion:
                        </Typography>
                        <Field
                          name="campaignName"
                          component={TextField}
                          validate={checkNotEqualToCampaignName}
                          required
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Modal.Content>
              <Modal.Actions>
                <Modal.Action type="secondary" content="Cancel" onAction={() => onCancel(form)} />
                <Modal.Action
                  type="primary"
                  color="error"
                  content="Delete"
                  onAction={handleSubmit}
                  disabled={invalid}
                  loading={loading}
                />
              </Modal.Actions>
            </Modal>
          </form>
        );
      }}
    />
  );
};

export default CampaignDeleteModal;
