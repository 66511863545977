import { DiscountAmountTypes, DiscountDatePickerTypes } from 'src/types/shared';

export const AMOUNT_OPTIONS = [
  {
    label: '$',
    value: DiscountAmountTypes.dollar,
  },
  {
    label: '%',
    value: DiscountAmountTypes.percentage,
  },
];

export const DATE_PICKER_OPTIONS = [
  {
    label: 'Single Date',
    value: DiscountDatePickerTypes.singleDate,
  },
  {
    label: 'Date Range',
    value: DiscountDatePickerTypes.dateRange,
  },
];
