import React, { useEffect, useMemo } from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useForm } from 'react-final-form';
import _ from 'lodash';

import { formatNumber } from '@vizsla/utils';
import { useStoreAssetsQuery, useRegistrationOptionSwagbagListQuery } from '@vizsla/graphql';
import { useCurrentOrganization } from '@vizsla/hooks';
import { DEFAULT_NUMBER_OF_SEARCH_OPTIONS } from '@vizsla/components';

import { SearchInput } from 'src/components/shared';
import {
  SwagBagAssetFormFields,
  SwagBagAssetAddOrUpdateFormValues,
} from 'src/types/registrationOption';
import { EMPTY_IMAGE } from 'src/assets';

interface FormSearchSectionSwagBagProps {
  setSearchValue: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  searchValue: string | null;
  registrationOptionId: string;
  availableSwagBagItems: any;
}
const FormSearchSection: React.FC<FormSearchSectionSwagBagProps> = ({
  setSearchValue,
  searchValue,
  registrationOptionId,
  availableSwagBagItems,
}) => {
  const { organizationId } = useCurrentOrganization();

  const {
    data: dataTableSwagBag,
    loading: loadingSwagBag,
    error,
  } = useRegistrationOptionSwagbagListQuery({
    variables: {
      filter: {
        registrationOption: {
          id: {
            equals: registrationOptionId,
          },
        },
      },
    },
  });
  const swagBagItems = dataTableSwagBag?.registrationOptionSwagbagsList?.items || [];

  const existingSwagBagAssetsId = React.useMemo(() => {
    // await for SER-3928 fix
    if (swagBagItems.length === 0) {
      return undefined;
    }

    return swagBagItems.map(experienceStoreAsset => {
      return String(experienceStoreAsset.storeAsset?.id);
    });
  }, [swagBagItems]);
  const { change, getState, batch } = useForm<SwagBagAssetAddOrUpdateFormValues>();
  const { values } = getState();

  useEffect(() => {}, [searchValue]);

  const { data, loading } = useStoreAssetsQuery({
    variables: {
      filter: {
        id: {
          not_in: existingSwagBagAssetsId,
        },
        swagbagAvailability: {
          equals: true,
        },
        vizslaOrganization: {
          id: { equals: organizationId },
        },
        name: {
          contains: searchValue,
        },
      },
      first: DEFAULT_NUMBER_OF_SEARCH_OPTIONS,
    },
    skip: !searchValue,
  });

  const storeAssets = data?.storeAssetsList.items || [];

  const debouncedSetSearchValue = _.debounce(setSearchValue, 500);

  const renderItemsList = () => {
    if (_.isNil(searchValue)) {
      return null;
    }

    if (loading) {
      return (
        <Typography textAlign="center" padding={2}>
          <CircularProgress />
        </Typography>
      );
    }

    if (storeAssets.length === 0) {
      return (
        <Typography variant="body1" textAlign="center" padding={2}>
          Not found
        </Typography>
      );
    }

    return (
      <List>
        {storeAssets.map(storeAsset => (
          <ListItem key={storeAsset.id} disablePadding>
            <ListItemButton
              onClick={() => {
                batch(() => {
                  change(SwagBagAssetFormFields.STORE_ASSET, storeAsset);
                  change(SwagBagAssetFormFields.PRICE, storeAsset.price || 0);
                });
              }}
            >
              <ListItemAvatar>
                <Avatar
                  src={storeAsset.image?.downloadUrl || EMPTY_IMAGE}
                  alt={storeAsset.name || 'Asset'}
                />
              </ListItemAvatar>

              <ListItemText>{storeAsset.name}</ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };

  const selectedStoreAsset = values[SwagBagAssetFormFields.STORE_ASSET];
  const availableItem = availableSwagBagItems.find(item => item.id === selectedStoreAsset?.id);

  const itemAvailability = useMemo(() => {
    return formatNumber(availableItem?.aviable || 0);
  }, [availableItem]);

  if (!selectedStoreAsset) {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <SearchInput onChange={debouncedSetSearchValue} />
        </Grid>

        <Grid item xs={12}>
          {renderItemsList()}
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Avatar
          variant="rounded"
          sx={{ width: 150, height: 150, margin: '0 auto' }}
          src={selectedStoreAsset.image?.downloadUrl || EMPTY_IMAGE}
          alt={selectedStoreAsset.name || 'Asset'}
        />
      </Grid>

      <Grid item xs={12} mt={2}>
        <Typography variant="h4" textAlign="center">
          {selectedStoreAsset.name}
        </Typography>
      </Grid>

      <Grid item xs={12} mt={0.5}>
        <Typography variant="body2" textAlign="center">
          Quantity Available: <strong>{itemAvailability}</strong>
        </Typography>
      </Grid>
    </React.Fragment>
  );
};

export { FormSearchSection };
