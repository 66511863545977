import React from 'react';
import { Collapse, Grid, ListItemButton, ListItemText, Tooltip, Typography } from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  RestartAltRounded as RestartAltRoundedIcon,
} from '@mui/icons-material';
import { Skeleton } from '@mui/lab';

type CollapsingListProps = {
  children: React.ReactElement;
  title: string;
  loading?: boolean;
  resetBtnLabel?: string;
  hasResetIcon?: boolean;
  onReset?: () => void;
};

export const CollapsingList: React.FC<CollapsingListProps> = ({
  children,
  title,
  loading,
  resetBtnLabel = '',
  hasResetIcon,
  onReset = () => null,
}) => {
  const [isOpen, setIsOpened] = React.useState(false);

  const handleClick = () => {
    setIsOpened(!isOpen);
  };

  const onClickResetBtn = (event: React.MouseEvent) => {
    event.stopPropagation();
    onReset();
  };

  if (loading) {
    return <Skeleton width="100%" height="50px" variant="text" />;
  }

  if (loading) {
    return <Skeleton width="100%" height="50px" variant="text" />;
  }

  return (
    <React.Fragment>
      <ListItemButton divider={!isOpen} onClick={handleClick}>
        <ListItemText
          primary={
            <Typography fontSize={14} fontWeight={700}>
              {title}
            </Typography>
          }
        />

        {hasResetIcon && isOpen && (
          <Tooltip title={resetBtnLabel}>
            <RestartAltRoundedIcon
              color="disabled"
              onClick={onClickResetBtn}
              style={{ marginRight: '15px' }}
            />
          </Tooltip>
        )}

        {isOpen ? <ExpandLess /> : <ExpandMore color="disabled" />}
      </ListItemButton>

      <Collapse in={!loading && isOpen} timeout="auto" unmountOnExit>
        <Grid paddingY={1}>{children}</Grid>
      </Collapse>
    </React.Fragment>
  );
};
