import React from 'react';
import { Grid, Typography } from '@mui/material';

import { useModal, useNotification } from '@vizsla/hooks';
import { Button, Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import { useExperienceBibAssignment, useExperienceId } from 'src/hooks/experiences';
import { BibAssignmentType } from 'src/types/bibAssignment';
import { t } from 'src/utils/template';

export const ExperienceResetBibAssignmentModal: React.FC = () => {
  const notification = useNotification();
  const { isOpen, closeModal } = useModal(MODALS.RESET_BIB_ASSIGNMENT_MODAL);

  const experienceId = useExperienceId();
  const { poolBibAssignments, deleteAssignmentsByType, deletingMultipleAssignments } =
    useExperienceBibAssignment(experienceId);

  const onResetBibAssignment = async () => {
    try {
      await deleteAssignmentsByType([BibAssignmentType.Pool, BibAssignmentType.Reserve]);
      notification.success(t('experience_assignment_reset_success'));

      closeModal();
    } catch (e) {
      notification.error(t('experience_assignment_reset_error'));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth customClasses={[`maxWidth: 'sm'`]}>
      <Modal.Title>
        <Typography variant="h3" align="center" paddingBottom={2}>
          Reset Bib Assignment
        </Typography>
      </Modal.Title>
      <Modal.Content>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              You are about to reset {poolBibAssignments.length} bib numbers.
            </Typography>
            <Typography variant="body1" align="center">
              Are you sure you want to do it?
            </Typography>

            <Typography color="error" variant="body2" align="center" pt={2} pb={3}>
              This action can&apos;t be reversed.
            </Typography>
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Button onClick={closeModal as any} color="info">
              Cancel
            </Button>
            <Button
              onClick={onResetBibAssignment}
              loading={deletingMultipleAssignments}
              color="error"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};
