import { useParams } from 'react-router-dom';
import { useTopAverageDonationExperienceQuery } from '@vizsla/graphql';
export function useSelectedTeamTopFundraisersAverageDonations() {
    var _a, _b;
    const { experienceId } = useParams();
    const { data: response, loading, refetch, } = useTopAverageDonationExperienceQuery({
        variables: { id: experienceId },
        skip: !experienceId,
    });
    const data = ((_b = (_a = response === null || response === void 0 ? void 0 : response.topAverageDonationByExperience) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : []);
    return { data, loading, refetch };
}
