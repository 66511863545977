import React from 'react';

import { StoreAssetsDashboardLayout } from 'src/layouts';
import { StoreAssetsDashboardContent } from 'src/components/storeAssets';

const StoreAssetsDashboard: React.FC = () => {
  return (
    <StoreAssetsDashboardLayout>
      <StoreAssetsDashboardContent />
    </StoreAssetsDashboardLayout>
  );
};

export { StoreAssetsDashboard };
