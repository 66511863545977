import React from 'react';
import { Typography } from '@mui/material';

import type { TableColumn } from '@vizsla/components';
import type { Attendee, IndividualFundraising } from '@vizsla/graphql';
import { DateFormatPatterns } from '@vizsla/constants';
import { TableData, UserAvatarFullNameInline } from '@vizsla/components';
import { formatDate } from '@vizsla/utils';
import { usePagination } from '@vizsla/hooks';

import { CampaignFundraiserGoalProgress } from 'src/components/campaign';

const NONE_LABEL = 'N/A';

const COLUMNS: TableColumn<IndividualFundraising>[] = [
  {
    title: 'Name',
    key: 'user',
    dataPath: 'attendee',

    render: (attendee: Attendee) => {
      if (attendee.user) {
        return <UserAvatarFullNameInline user={attendee.user} />;
      }

      return <Typography variant="body2">{NONE_LABEL}</Typography>;
    },
  },

  {
    title: 'Date',
    key: 'date',
    dataPath: 'createdAt',

    render: (createdAt: string) => {
      const date = formatDate(createdAt, DateFormatPatterns.shortMonthDayYear);

      return <Typography variant="body2">{date}</Typography>;
    },
  },

  {
    title: 'Registration',
    key: 'registration',
    dataPath: 'attendee',

    render(attendee: Attendee) {
      const registration = attendee.registrationOption?.name ?? NONE_LABEL;

      return <Typography variant="body2">{registration}</Typography>;
    },
  },

  {
    title: 'Team',
    key: 'team',
    dataPath: 'attendee',

    render(attendee: Attendee) {
      const team = attendee.teamMembership?.team?.name ?? NONE_LABEL;

      return <Typography variant="body2">{team}</Typography>;
    },
  },

  {
    title: 'Total Raised',
    key: 'raised',

    render(root, fundraising) {
      const groups: any[] = fundraising.allocatedDonations?.groups ?? [];
      const raised: number = groups?.[0]?.raised ?? 0;

      const goal = fundraising.goal ?? 0;

      return <CampaignFundraiserGoalProgress totalRaised={raised} fundraiserGoal={goal} />;
    },
  },
];

interface Props {
  dataSource: IndividualFundraising[];

  loading?: boolean;
  showNavigation?: boolean;
}

export function CampaignFundraisersTable(props: Props) {
  const pagination = usePagination();

  return (
    <TableData
      dataSource={props.dataSource}
      loading={props.loading ?? true}
      pagination={props.showNavigation ? pagination : undefined}
      columns={COLUMNS}
    />
  );
}
