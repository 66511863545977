import React from 'react';
import _ from 'lodash';
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Switch,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LocalOfferOutlined, MoreHorizOutlined } from '@mui/icons-material';

import { useModal, useNotification, usePopover } from '@vizsla/hooks';
import { concatDateWithTime } from '@vizsla/utils';
import { DiscountCodeSetting } from '@vizsla/graphql';
import { Button } from '@vizsla/components';

import { BorderRadius } from 'src/theme';
import { useCampaignDiscounts, useCampaignId } from 'src/hooks/campaign';
import { MODALS } from 'src/constants/modals';
import { DiscountAmountTypes, DiscountStatusTypes } from 'src/types/shared';
import { getFormattedAmount, getRemaining, getStatus } from 'src/utils/campaignDiscounts';
import { t } from 'src/utils/template';

import { DiscountExpiration } from './DiscountExpiration';

interface BodyItemProps {
  label: string;
  value?: string | number | React.ReactNode | null;
}

const BodyItem: React.FC<BodyItemProps> = ({ label, value }) => (
  <p>
    <Typography variant="body1" color={theme => theme.palette.grey['600']} component="span">
      {label}:&nbsp;
    </Typography>
    <Typography variant="body1" component="span">
      {_.isNil(value) ? 'Not Set' : value}
    </Typography>
  </p>
);

const useStyles = makeStyles<Theme, { isEnabled: boolean }>(theme => ({
  paper: {
    borderStyle: 'dashed',
    boxShadow: 'none',
    borderRadius: BorderRadius.M,
    borderWidth: 2,
    borderColor: theme.palette.grey['400'],
    minHeight: 335,
    minWidth: 250,
    background: ({ isEnabled }) => (isEnabled ? 'none' : theme.palette.grey['100']),
  },
  grid: {
    padding: theme.spacing(2, 3),
    height: '100%',
  },
  editBtn: {
    margin: 0,
  },
}));

export interface DiscountCardProps {
  discountSettings: DiscountCodeSetting;
}

export const DiscountCard: React.FC<DiscountCardProps> = ({ discountSettings }) => {
  const DISCOUNT_VALUES = {
    id: discountSettings.id ?? '',
    name: discountSettings.name ?? '',
    totalUses: discountSettings.totalUses,
    usesCount: discountSettings.usesCount ?? 0,
    category: discountSettings.category,
    amount: discountSettings.amount ?? 0,
    startDate: discountSettings.startDate,
    startTime: discountSettings.startTime,
    endTime: discountSettings.endTime,
    endDate: discountSettings.endDate,
    totalAmount: discountSettings.totalAmount ?? 0,
    amountType: discountSettings.amountType,
    isEnabled: discountSettings.isEnabled ?? false,
  };

  const classes = useStyles({ isEnabled: DISCOUNT_VALUES.isEnabled });

  const campaignId = useCampaignId();
  const notification = useNotification();
  const discountCodeActions = usePopover();
  const { openModal } = useModal();

  const { deleteDiscount, updateDiscount, loading, updating, deleting } =
    useCampaignDiscounts(campaignId);

  const remaining = getRemaining(DISCOUNT_VALUES.totalUses, DISCOUNT_VALUES.usesCount);
  const status = getStatus(
    remaining,
    DISCOUNT_VALUES.startDate,
    DISCOUNT_VALUES.startTime,
    DISCOUNT_VALUES.endDate,
    DISCOUNT_VALUES.endTime,
  );

  const onOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    discountCodeActions.openPopover(event);
  };

  const handleSwitchIsEnabled = React.useCallback(async () => {
    const isCompletedOrExpired =
      status === DiscountStatusTypes.completed || status === DiscountStatusTypes.expired;
    if (!isCompletedOrExpired || DISCOUNT_VALUES.isEnabled) {
      try {
        await updateDiscount({
          data: { isEnabled: !DISCOUNT_VALUES.isEnabled },
          discountId: DISCOUNT_VALUES.id,
        });
        notification.success(t('discount_update_success', { discountName: DISCOUNT_VALUES.name }));
      } catch (error) {
        notification.error(t('discount_update_error', { discountName: DISCOUNT_VALUES.name }));
      }
    }
  }, [
    DISCOUNT_VALUES.id,
    DISCOUNT_VALUES.isEnabled,
    DISCOUNT_VALUES.name,
    status,
    notification,
    updateDiscount,
  ]);

  const handleDelete = () => {
    openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
      deleteFunction: () => deleteDiscount(DISCOUNT_VALUES.id),
      objectName: DISCOUNT_VALUES.name,
      objectType: 'Discount code',
    });
  };

  const handleClone = () => {
    openModal(MODALS.CAMPAIGN_DISCOUNTS_CREATE_MODAL, {
      ...discountSettings,
      isEnabled: false,
    });
  };

  const handleEdit = () => {
    openModal(MODALS.CAMPAIGN_DISCOUNTS_CREATE_MODAL, {
      type: 'Edit Discount Code',
      ...discountSettings,
    });
  };

  const getDeleteAvailability = (status: string, usesCount: number): boolean => {
    const isLiveOrCompleted =
      status === DiscountStatusTypes.live || status === DiscountStatusTypes.completed;
    const hasAtLeastOneUsage = usesCount > 0;

    return isLiveOrCompleted || hasAtLeastOneUsage || loading || updating || deleting;
  };

  React.useEffect(() => {
    const isCompletedOrExpired =
      status === DiscountStatusTypes.completed || status === DiscountStatusTypes.expired;
    if (isCompletedOrExpired && DISCOUNT_VALUES.isEnabled) {
      handleSwitchIsEnabled();
    }
  }, [DISCOUNT_VALUES.isEnabled, handleSwitchIsEnabled, status]);

  return (
    <Paper className={classes.paper} variant="outlined">
      <Grid component={Box} className={classes.grid} container direction="column">
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <LocalOfferOutlined />
            </Grid>
            <Grid item>
              <Typography variant="h5">{DISCOUNT_VALUES.name}</Typography>
            </Grid>
            <Grid item marginLeft="auto">
              <Switch
                checked={DISCOUNT_VALUES.isEnabled}
                disabled={updating || deleting}
                onChange={handleSwitchIsEnabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lineHeight={1.8}>
          <BodyItem label="Status" value={status} />
          <BodyItem label="Category" value={DISCOUNT_VALUES.category} />
          <BodyItem
            label="Amount"
            value={getFormattedAmount(DISCOUNT_VALUES.amount, DISCOUNT_VALUES.amountType)}
          />
          <BodyItem label="Uses" value={DISCOUNT_VALUES.usesCount} />
          <BodyItem label="Remaining" value={remaining.toLocaleString('en-US')} />
          <BodyItem
            label="Total"
            value={getFormattedAmount(DISCOUNT_VALUES.totalAmount, DiscountAmountTypes.dollar)}
          />
          <BodyItem
            label="Expiration"
            value={
              <DiscountExpiration
                date={concatDateWithTime(DISCOUNT_VALUES.endDate, DISCOUNT_VALUES.endTime)}
                status={status}
              />
            }
          />
        </Grid>
        <Grid item>
          <Grid container alignItems="center" mt="auto" spacing={2}>
            <Grid item>
              <Button className={classes.editBtn} variant="text" color="info" onClick={handleEdit}>
                Edit
              </Button>
            </Grid>
            <Grid item ml="auto">
              <IconButton onClick={onOpenPopover} size="large">
                <MoreHorizOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Menu
        keepMounted
        anchorEl={discountCodeActions.el}
        open={discountCodeActions.isOpen}
        onClose={discountCodeActions.closePopover}
      >
        <MenuItem onClick={handleClone}>Clone</MenuItem>
        <MenuItem
          disabled={getDeleteAvailability(status, DISCOUNT_VALUES.usesCount)}
          onClick={handleDelete}
        >
          Delete
        </MenuItem>
      </Menu>
    </Paper>
  );
};
