import React from 'react';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { useModal, useNotification, FormStep, useFormStepper } from '@vizsla/hooks';

import { useTicketingOptions, useTicketingOptionsPricingTiers } from 'src/hooks/ticketingOption';
import { FormStepperController } from 'src/components/forms';
import { t } from 'src/utils/template';
import {
  validateTicketingOptionCreatePeopleStep,
  validateTicketingOptionCreateBasicsStep,
  validateTicketingOptionCreatePricingForm,
} from 'src/constants/validationSchemas/ticketingOption';
import { MODALS } from 'src/constants/modals';
import { getNewPricingTiersData } from 'src/utils/pricingSettings';

import {
  TicketingOptionCreateBasicsStep,
  TicketingOptionCreatePeopleStep,
  TicketingOptionPricingStep,
} from './components';
import { composeTicketingOptionCreateInput } from './utils';
import { TICKETING_OPTION_CREATE_FORM_INITIAL } from './constants';

const FORM_STEPS: FormStep[] = [
  {
    render: () => <TicketingOptionCreateBasicsStep />,
    validate: validateTicketingOptionCreateBasicsStep,
  },

  {
    render: () => <TicketingOptionCreatePeopleStep />,
    validate: validateTicketingOptionCreatePeopleStep,
  },
  {
    render: () => <TicketingOptionPricingStep />,
    validate: validateTicketingOptionCreatePricingForm,
  },
];

export const TicketingOptionCreateForm: React.FC = () => {
  const notification = useNotification();

  const { closeModal, args } = useModal(MODALS.TICKETING_OPTION_CREATE_MODAL);
  const { isLast, isFirst, next, prev, render, validate } = useFormStepper(FORM_STEPS);

  const { createTicketingOption, creating } = useTicketingOptions();

  const { createNewPricingTiers, creatingPricingTiers } = useTicketingOptionsPricingTiers();

  const onCreateTicketingOption = async (formData: Record<string, any>) => {
    if (args?.experienceId) {
      try {
        const ticketingOptionCreateInput = composeTicketingOptionCreateInput(
          args?.experienceId,
          formData,
        );
        const newTicketingOption = await createTicketingOption(ticketingOptionCreateInput);

        const newPricingTiersData = getNewPricingTiersData(
          formData,
          newTicketingOption?.pricingSettings?.id,
        );

        if (newPricingTiersData.length > 0) {
          await createNewPricingTiers(newPricingTiersData);
        }

        notification.success(t('ticketing_option_create_success'));
        closeModal();
      } catch {
        notification.error(t('ticketing_option_create_error'));
      }
    }
  };

  return (
    <Form
      initialValues={TICKETING_OPTION_CREATE_FORM_INITIAL}
      onSubmit={onCreateTicketingOption}
      validate={validate}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({ handleSubmit }) => {
        return (
          <form>
            {render()}
            <FormSpy subscription={{ valid: true }}>
              {({ valid, submitting }) => {
                return (
                  <FormStepperController
                    onContinue={next}
                    onCancel={closeModal}
                    onBack={prev}
                    isFirst={isFirst}
                    valid={valid}
                    isLast={isLast}
                    submitting={submitting || creating || creatingPricingTiers}
                    onSubmit={handleSubmit}
                  />
                );
              }}
            </FormSpy>
          </form>
        );
      }}
    </Form>
  );
};
