import _ from 'lodash';
import { DateTime } from 'luxon';

import { concatDateWithTime } from '@vizsla/utils';
import {
  Maybe,
  RegistrationOptionFragmentFragment,
  RegistrationPricingTier,
} from '@vizsla/graphql';

import { INITIAL_PRICING_TIER } from 'src/constants/pricingSettings';
import { PricingTierFormAction, PricingType } from 'src/types/pricingSettings';
import { getPricingSettingsUpdateData } from 'src/utils/pricingSettings';

export const getDataToSubmit = (data: any) => {
  const {
    ageRequirmentEnabled,
    ageRequirement,
    ageRequirementAsOfDate,
    registrationLimitsEnabled,
    attendeesPerRegistration,
    maximumRegistrationPerOrder,
    groupRegistrationEnabled,
    groupRegistrationMinimum,
    groupRegistrationMaximum,
    attendeesCapEnabled,
    maximumAttendees,
    pricingSettings,
    registrationAvailabilityEnabled,
    registrationStartDate,
    registrationStartTime,
    registrationEndDate,
    registrationEndTime,
    ...rest
  } = data;

  return {
    ...rest,
    ageRequirement: ageRequirmentEnabled ? ageRequirement : null,
    ageRequirementAsOfDate: ageRequirmentEnabled ? ageRequirementAsOfDate : null,
    attendeesPerRegistration: registrationLimitsEnabled ? attendeesPerRegistration : null,
    maximumRegistrationPerOrder: registrationLimitsEnabled ? maximumRegistrationPerOrder : null,
    groupRegistrationMinimum: groupRegistrationEnabled ? groupRegistrationMinimum : null,
    groupRegistrationMaximum: groupRegistrationEnabled ? groupRegistrationMaximum : null,
    maximumAttendees: attendeesCapEnabled ? maximumAttendees : null,
    registrationStartDate: registrationAvailabilityEnabled
      ? concatDateWithTime(registrationStartDate, registrationStartTime)
      : null,
    registrationEndDate: registrationAvailabilityEnabled
      ? concatDateWithTime(registrationEndDate, registrationEndTime)
      : null,
    pricingSettings: getPricingSettingsUpdateData(pricingSettings),
  };
};

export const getFormInitialValues = (
  registrationOption?: Maybe<RegistrationOptionFragmentFragment>,
) => {
  const initialPricingTiers = registrationOption?.pricingSettings?.pricingTiers?.items.map(
    (item: RegistrationPricingTier) => ({
      ..._.pick(item, [
        'id',
        'price',
        'taxDeductibleType',
        'taxDeductiblePercentsAmount',
        'taxDeductibleCurrencyAmount',
        'startDate',
        'endDate',
      ]),
      startTime: item.startDate,
      endTime: item.endDate,
      formAction: PricingTierFormAction.toUpdate,
    }),
  );

  const date = new Date();
  date.setHours(0, 0, 0, 0);
  const pricingTiers =
    !_.isNil(initialPricingTiers) && initialPricingTiers.length > 0
      ? initialPricingTiers
      : [INITIAL_PRICING_TIER];

  return {
    name: registrationOption?.name,
    distance: registrationOption?.distance,
    unitOfMeasure: registrationOption?.unitOfMeasure,
    startWaveOrder: registrationOption?.startWaveOrder,
    description: registrationOption?.description,

    ageRequirmentEnabled:
      !_.isNil(registrationOption?.ageRequirement) &&
      !_.isNil(registrationOption?.ageRequirementAsOfDate),
    ageRequirement: registrationOption?.ageRequirement,
    ageRequirementAsOfDate: registrationOption?.ageRequirementAsOfDate,

    registrationLimitsEnabled:
      !_.isNil(registrationOption?.attendeesPerRegistration) &&
      !_.isNil(registrationOption?.maximumRegistrationPerOrder),
    attendeesPerRegistration: registrationOption?.attendeesPerRegistration,
    maximumRegistrationPerOrder: registrationOption?.maximumRegistrationPerOrder,

    groupRegistrationEnabled:
      !_.isNil(registrationOption?.groupRegistrationMinimum) &&
      !_.isNil(registrationOption?.groupRegistrationMaximum),
    groupRegistrationMinimum: registrationOption?.groupRegistrationMinimum,
    groupRegistrationMaximum: registrationOption?.groupRegistrationMaximum,

    attendeesCapEnabled: !_.isNil(registrationOption?.maximumAttendees),
    maximumAttendees: registrationOption?.maximumAttendees,

    registrationAvailabilityEnabled:
      !_.isNil(registrationOption?.registrationStartDate) &&
      !_.isNil(registrationOption?.registrationEndDate),
    registrationStartDate: registrationOption?.registrationStartDate,
    registrationStartTime: registrationOption?.registrationStartDate || DateTime.fromJSDate(date),
    registrationEndDate: registrationOption?.registrationEndDate,
    registrationEndTime: registrationOption?.registrationEndDate || DateTime.fromJSDate(date),
    pricingSettings: {
      id: registrationOption?.pricingSettings?.id,
      pricingEnabled: registrationOption?.pricingSettings?.pricingEnabled,
      allowCostToBeFundraisingCredit:
        registrationOption?.pricingSettings?.allowCostToBeFundraisingCredit,
      pricingType: registrationOption?.pricingSettings?.pricingType ?? PricingType.Fixed,
      pricingTiers,
    },
  };
};
