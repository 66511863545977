import { gql } from '@apollo/client';

const TICKETING_OPTION_FRAGMENT = gql`
  fragment TicketingOptionFragment on TicketingOption {
    id
    ticketName
    tableNumber
    description
    ageRequirement
    ageRequirementAsOfDate
    maximumAttendees
    attendeesPerTicket
    maximumPerOrder
    minimumGroupTickets
    maximumGroupTickets
    ticketingAvailabilityStartDateTime
    ticketingAvailabilityEndDateTime
    pricingSettings {
      id
      allowCostToBeFundraisingCredit
      pricingEnabled
      pricingType
      pricingTiers {
        items {
          id
          price
          taxDeductibleCurrencyAmount
          taxDeductiblePercentsAmount
          taxDeductibleType
          startDate
          endDate
        }
        count
      }
    }
  }
`;

export const TICKETING_OPTION_QUERY = gql`
  query TicketingOption($id: ID!) {
    ticketingOption(id: $id) {
      ...TicketingOptionFragment
    }
  }
  ${TICKETING_OPTION_FRAGMENT}
`;

export const TICKETING_OPTIONS_LIST_QUERY = gql`
  query TicketingOptionsList($filter: TicketingOptionFilter) {
    ticketingOptionsList(filter: $filter) {
      items {
        ...TicketingOptionFragment
      }
    }
  }
  ${TICKETING_OPTION_FRAGMENT}
`;

export const TICKETING_OPTION_DELETE_MUTATION = gql`
  mutation TicketingOptionDelete($id: ID!) {
    ticketingOptionDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;

export const TICKETING_OPTION_UPDATE_MUTATION = gql`
  mutation TicketingOptionUpdate(
    $data: TicketingOptionUpdateInput!
    $filter: TicketingOptionKeyFilter!
  ) {
    ticketingOptionUpdate(data: $data, filter: $filter) {
      ...TicketingOptionFragment
    }
  }
  ${TICKETING_OPTION_FRAGMENT}
`;

export const TICKETING_OPTION_CREATE_MUTATION = gql`
  mutation TicketingOptionCreate($data: TicketingOptionCreateInput!) {
    ticketingOptionCreate(data: $data) {
      ...TicketingOptionFragment
    }
  }
  ${TICKETING_OPTION_FRAGMENT}
`;
