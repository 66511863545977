import React from 'react';
import { Box, Typography } from '@mui/material';
import { useFormState, useForm } from 'react-final-form';

import { DateFormatPatterns } from '@vizsla/constants';
import {
  DateField,
  Field,
  NumberField,
  RichTextEditorField,
  TextField,
  TimeField,
} from '@vizsla/components';
import { validateHtmlTextLength } from '@vizsla/utils';

import { CampaignWrapper, FlexField, FlexRowRadioGroupField, InLineFields } from './styles';
import { SelectCampaign } from '../../../../../campaign/SelectCampaign';
import { SelectExperience } from '../../../../../experience/SelectExperience';
import { CAMPAIGN, DATE_RANGE, DATE_SINGLE, EXPERIENCE } from '../../../constants';

export const MatchingCommitment: React.FC = () => {
  const { values, errors, submitFailed } = useFormState();

  const { change } = useForm();
  const { inKindType, matchingPeriod } = values;

  const setCampaign = e => {
    change(CAMPAIGN, e);
    change(EXPERIENCE, null);
  };

  const setExperience = e => {
    change(EXPERIENCE, e);
    change(CAMPAIGN, null);
  };
  const nowTime = React.useMemo(() => {
    return `${new Date(Date.now()).toISOString().split('T')[0]}T05:00:00.000Z`;
  }, []);

  return (
    <React.Fragment>
      <InLineFields>
        <FlexField
          component={NumberField}
          name="matchLimit"
          label="Match Limit (USD)"
          numberFormat={{ prefix: '$', thousandSeparator: true }}
          InputProps={{ fullWidth: true, size: 'medium' }}
          required
        />
        <Typography position="absolute" variant="caption" bottom="-1.5rem" left="0">
          Per each attendee donation
        </Typography>
        <FlexField
          component={NumberField}
          name="amount"
          label="Total Amount (USD)"
          numberFormat={{ prefix: '$', thousandSeparator: true }}
          InputProps={{ fullWidth: true, size: 'medium' }}
          required
        />
      </InLineFields>
      <Box marginTop="2rem">
        <Field
          component={RichTextEditorField}
          name="description"
          label="Description"
          validate={value => validateHtmlTextLength(value, 1, 500)}
          maxTextLength={500}
          height={100}
        />
      </Box>

      <Typography fontWeight={600}>Allocation</Typography>
      <Field
        name="inKindType"
        options={[
          { label: 'Campaign', value: CAMPAIGN },
          { label: 'Experiences', value: EXPERIENCE },
        ]}
        component={FlexRowRadioGroupField}
        defaultValue={CAMPAIGN}
        optionContainerProps={{
          display: 'flex',
          flexDirection: 'row',
          direction: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          spacing: 2,
        }}
      />
      {inKindType === CAMPAIGN ? (
        <CampaignWrapper>
          <SelectCampaign name={CAMPAIGN} label="Campaign" onChange={setCampaign} required />
          {submitFailed && (
            <Typography variant="caption" color="red">
              {errors?.campaign}
            </Typography>
          )}
        </CampaignWrapper>
      ) : (
        <CampaignWrapper>
          <SelectExperience
            name={EXPERIENCE}
            label="Experience"
            onChange={setExperience}
            required
          />
          {submitFailed && (
            <Typography variant="caption" color="red">
              {errors?.experience}
            </Typography>
          )}
        </CampaignWrapper>
      )}
      <Field
        name="matchingPeriod"
        options={[
          { label: 'Single Date', value: DATE_SINGLE },
          { label: 'Date Range', value: DATE_RANGE },
        ]}
        component={FlexRowRadioGroupField}
        defaultValue={DATE_SINGLE}
        optionContainerProps={{
          display: 'flex',
          flexDirection: 'row',
          direction: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          spacing: 2,
        }}
      />
      <InLineFields>
        <FlexField
          required
          name="startDate"
          label="Start Date"
          component={DateField}
          inputFormat={DateFormatPatterns.shortMonthDayYear}
          fullWidth
        />
        <FlexField
          required
          name="startTime"
          label="Start Time"
          component={TimeField}
          inputFormat={DateFormatPatterns.meridiemTimePadded}
          defaultValue={nowTime}
          fullWidth
        />
      </InLineFields>
      {matchingPeriod === DATE_RANGE && (
        <InLineFields>
          <FlexField
            name="endDate"
            label="End Date"
            component={DateField}
            inputFormat={DateFormatPatterns.shortMonthDayYear}
            fullWidth
          />
          <FlexField
            name="endTime"
            label="End Time"
            component={TimeField}
            inputFormat={DateFormatPatterns.meridiemTimePadded}
            fullWidth
          />
        </InLineFields>
      )}
      <Field component={TextField} name="notes" label="Other Benefits/Notes" />
    </React.Fragment>
  );
};
