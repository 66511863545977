import React from 'react';
import { Grid, Skeleton, Theme } from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';
import { makeStyles } from '@mui/styles';

import { useModal } from '@vizsla/hooks';
import { CampaignStaff } from '@vizsla/graphql';

import { useCampaignId, useCampaignStaff } from 'src/hooks/campaign';
import { MODALS } from 'src/constants/modals';
import { CampaignAddStaffDialog, CampaignStaffListDialog } from 'src/components/campaign';
import { UserAvatar } from 'src/components/user';

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    width: '224px',
  },
  avatarGroup: {
    cursor: 'pointer',
  },
  avatar: {
    background: '#3B85FF',
  },
}));

export const CampaignStaffAvatarsGroup: React.FC = () => {
  const campaignId = useCampaignId();
  const { campaignStaff, loading } = useCampaignStaff(campaignId);
  const classes = useStyles();
  const { openModal } = useModal();

  const openAddStaffList = () => {
    openModal(MODALS.CAMPAIGN_STAFF_LIST_MODAL);
  };

  const renderContent = () => {
    if (loading) {
      return <Skeleton height={44} />;
    }

    return (
      <AvatarGroup className={classes.avatarGroup} max={6} onClick={openAddStaffList}>
        {campaignStaff.map((staff: CampaignStaff) => (
          <UserAvatar key={staff.id} user={staff.user} />
        ))}
      </AvatarGroup>
    );
  };
  return (
    <Grid className={classes.wrap}>
      {renderContent()}
      <CampaignStaffListDialog />
      <CampaignAddStaffDialog />
    </Grid>
  );
};
