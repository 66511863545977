import React from 'react';

import { PeopleCRMTabType } from 'src/types/peopleCRM';

import { AttendeesTable } from './AttendeesTable';
import { TeamsTable } from './TeamsTable';
import { FundraisersTable } from './FundraisersTable';
import { DonorsTable } from './DonorsTable';
import { OthersTable } from './OthersTable';

interface CRMTableProps {
  tabType: PeopleCRMTabType;
}

const MAP_CRM_TABLES_TO_TAB_TYPES: { [key in PeopleCRMTabType]: React.ReactElement } = {
  [PeopleCRMTabType.attendees]: <AttendeesTable />,
  [PeopleCRMTabType.donors]: <DonorsTable />,
  [PeopleCRMTabType.fundraisers]: <FundraisersTable />,
  [PeopleCRMTabType.teams]: <TeamsTable />,
  [PeopleCRMTabType.others]: <OthersTable />,
};

const PeopleCRMTable: React.FC<CRMTableProps> = ({ tabType }) => {
  return MAP_CRM_TABLES_TO_TAB_TYPES[tabType];
};

export { PeopleCRMTable };
