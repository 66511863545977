import React from 'react';
import { CircularProgressProps, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { PaletteColor, Shade } from 'src/theme';

import { DeterminateProgress } from './DeterminateProgress';

const useStyles = makeStyles<Theme, { color: string }>(theme => ({
  outerProgressContent: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100%',
    position: 'relative',
  },
  innerProgressContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    '&>.MuiTypography-root': {
      fontWeight: theme.typography.fontWeightBold,
      marginLeft: '2px',
      lineHeight: 0,
      color: ({ color }) => color,
    },
    '&>span': {
      fontSize: 10,
      letterSpacing: '-0.14px',
      lineHeight: 0,
      color: PaletteColor.GrayText,
    },
  },
}));

const calculateColor = (value: number) => {
  if (value <= 0) {
    return Shade.Gray[200];
  }
  if (value < 20) {
    return PaletteColor.ErrorMain;
  }

  if (value < 60) {
    return PaletteColor.WarningProgress;
  }
  return PaletteColor.SuccessFaded;
};

interface DeterminateProgressDashboardProps extends CircularProgressProps {
  showCaption?: boolean;
}

export const DeterminateProgressDashboard: React.FC<DeterminateProgressDashboardProps> = ({
  value = 0,
  showCaption = true,
  className,
  ...props
}) => {
  const color = calculateColor(value);
  const classes = useStyles({ color });

  return (
    <div className={clsx(classes.outerProgressContent, className)}>
      <DeterminateProgress value={value} {...props} calculateColor={calculateColor} />
      <div className={clsx(classes.innerProgressContent, className)}>
        <Typography>{value}%</Typography>
        {showCaption ? <span>of Goal</span> : null}
      </div>
    </div>
  );
};
