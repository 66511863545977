import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import {
  AttendeeFilter,
  AttendeesListQuery,
  AttendeesListQueryVariables,
  Attendee,
} from '@vizsla/graphql';
import { usePaginationQueryVariables, usePagination, useSearchQueryVariables } from '@vizsla/hooks';

import { ATTENDEES_LIST_QUERY } from 'src/graphql';

interface Options {
  campaignId: string;

  first?: number;
  skip?: number;

  filter?: AttendeeFilter;
}

export function useAttendees(options: Options) {
  const [data, setData] = useState<Attendee[]>([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const pagination = usePagination();
  const paginationVariables = usePaginationQueryVariables();

  const searchVariables = useSearchQueryVariables<AttendeeFilter>(searchValue => {
    return {
      user: {
        OR: [
          {
            firstName: {
              contains: searchValue,
            },
          },
          {
            lastName: {
              contains: searchValue,
            },
          },
        ],
      },
    };
  });

  const variables: AttendeesListQueryVariables = useMemo(() => {
    const filterByCampaign: AttendeeFilter = {
      experience: {
        campaign: {
          id: {
            equals: options.campaignId,
          },
        },
      },
    };

    return {
      first: options.first ?? paginationVariables.first,
      skip: options.skip ?? paginationVariables.skip,

      filter: {
        ...filterByCampaign,
        ...searchVariables,
        ...options.filter,
      },
    };
  }, [options.campaignId, options.filter, paginationVariables, searchVariables]);

  const [executeQuery] = useLazyQuery<AttendeesListQuery, AttendeesListQueryVariables>(
    ATTENDEES_LIST_QUERY,
  );

  async function refetch() {
    if (!options.campaignId) return;

    setLoading(true);

    const response = await executeQuery({ variables });

    const count = response.data?.attendees?.count ?? 0;
    const data = response.data?.attendees?.items ?? [];

    setData(data as Attendee[]);
    setCount(count);
    setLoading(false);
  }

  useEffect(() => {
    pagination.onCountChange(count);
  }, [count]);

  useEffect(() => {
    refetch();
  }, [variables]);

  return {
    data,
    count,
    loading,

    refetch,
  };
}
