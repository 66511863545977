import create from 'zustand';
import { useParams } from 'react-router-dom';
import { useTeamFundraisingManagementQuery } from '@vizsla/graphql';
export const useSelectedTeamFundraiserStore = create(set => ({
    loading: true,
    data: undefined,
    setLoading: (loading) => set({ loading }),
    setData: (data) => set({ data, loading: false }),
    clean: () => set({ data: undefined, loading: false }),
}));
export function useSelectedTeamFundraiser() {
    const { teamId } = useParams();
    const { data, setData } = useSelectedTeamFundraiserStore();
    const { loading } = useTeamFundraisingManagementQuery({
        skip: !teamId,
        variables: { id: teamId },
        onCompleted: ({ teamFundraising }) => {
            setData(teamFundraising);
        },
    });
    return { data, loading };
}
