import styled from '@emotion/styled';

export const ModalContent = styled.div`
  padding: 0.5rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
