import React from 'react';
import { Form } from 'react-final-form';
import { Grid, Skeleton, SvgIcon, Typography } from '@mui/material';
import { VolunteerActivismOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import {
  Button,
  TextField,
  NumberField,
  CheckboxField,
  SelectField,
  Field,
} from '@vizsla/components';
import { validateWithSchema } from '@vizsla/utils';

import { SwitchCardField } from 'src/components/forms';
import { PaperBlock } from 'src/components/shared';
import { CoveredFeesTypes } from 'src/types/shared';
import { PERCENT_FORMAT } from 'src/constants/formats';
import { useCampaignFundraisingSettings, useCampaignId } from 'src/hooks/campaign';
import { CampaignDonationSettingsSchema } from 'src/constants/validationSchemas/campaign';
import { CampaignFundraisingSettingsTypes } from 'src/types/campaign';
import { FontSize, PaletteColor } from 'src/theme';
import { RoundUpIcon } from 'src/assets';

const useStyles = makeStyles(() => ({
  cardText: {
    color: PaletteColor.BlackText,
  },
  checkboxField: {
    fontSize: FontSize.Default,
    color: PaletteColor.BlackText,
  },
  checkboxStyle: {
    paddingBottom: '4px',
    paddingTop: '4px',
  },
}));

const COVERED_FEES_OPTIONS = [
  {
    label: 'Fixed',
    value: CoveredFeesTypes.fixed,
  },
];

const FEES_MESSAGE =
  'I would like to cover the fees associated with my donation so more of my donation goes to organization.';

const ROUND_UP_MESSAGE =
  'I would like to round my total to the nearest dollar to further support the organization mission.';

type CampaignDonationSettingsProps = {
  experienceId?: string;
  useFundraisingSettings?: (value: string) => any;
};

export const CampaignDonationSettings: React.FC<CampaignDonationSettingsProps> = ({
  experienceId,
  useFundraisingSettings = useCampaignFundraisingSettings,
}) => {
  const classes = useStyles();

  const campaignId = useCampaignId();

  const entityId = experienceId ?? campaignId;
  const { donationSettings, setSettings, loading } = useFundraisingSettings(entityId);

  const INITIAL_VALUES = {
    isEnabled: donationSettings?.isEnabled ?? true,
    allowAnonymousDonations: donationSettings?.allowAnonymousDonations ?? true,
    allowSelfDonations: donationSettings?.allowSelfDonations ?? true,
    allowDonorsToOptIn: donationSettings?.allowDonorsToOptIn ?? true,
    allowDonorsToDedicateDonations: donationSettings?.allowDonorsToDedicateDonations ?? true,
    feesEnabled: donationSettings?.feesEnabled ?? true,
    feesMessage: donationSettings?.feesMessage ?? FEES_MESSAGE,
    roundupMessage: donationSettings?.roundupMessage ?? ROUND_UP_MESSAGE,
    roundupEnabled: donationSettings?.roundupEnabled ?? true,
    coveredFees: donationSettings?.coveredFees ?? CoveredFeesTypes.fixed,
    percentageAmount: donationSettings?.percentageAmount ?? 100,
  };

  const onSubmit = async (data: any) => {
    await setSettings({
      settingsType: CampaignFundraisingSettingsTypes.donationSettings,
      data,
    });
  };

  const renderDonorCoverCard = (checked: boolean) => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.cardText}>
            Encourage donors to cover the transaction fees at checkout so that your organization
            receives full donation amount.
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="coveredFees"
            label="Covered Fees"
            disabled={!checked}
            options={COVERED_FEES_OPTIONS}
            component={SelectField}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="percentageAmount"
            label="Percentage Amount (%)"
            disabled
            component={NumberField}
            numberFormat={PERCENT_FORMAT}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="feesMessage"
            label="Message"
            disabled={!checked}
            rows={3}
            maxRows={4}
            component={TextField}
            required
            multiline
            fullWidth
          />
        </Grid>
      </Grid>
    );
  };

  const renderMissionRoundUpCard = (checked: boolean) => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.cardText}>
            Encourage donors to round up to the nearest dollar amount at checkout. The round up
            amount goes directly to your organization.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="roundupMessage"
            label="Message"
            disabled={!checked}
            maxRows={4}
            rows={3}
            component={TextField}
            helperText="e.g. $25.65 rounds to $26.00 with additional $.35 directly to mission."
            required
            multiline
            fullWidth
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <PaperBlock title="Donation Settings" collapsing defaultOpened>
      <Form
        initialValues={INITIAL_VALUES}
        onSubmit={onSubmit}
        validate={values => validateWithSchema(CampaignDonationSettingsSchema, values)}
      >
        {({ submitting, pristine, handleSubmit, form }) => {
          return (
            <form>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  {loading ? (
                    <Skeleton height={610} variant="rectangular" />
                  ) : (
                    <Grid container direction="column" spacing={4}>
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <Field
                              name="allowAnonymousDonations"
                              label="Allow anonymous donations."
                              component={CheckboxField}
                              labelClassName={classes.checkboxField}
                              className={classes.checkboxStyle}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="stretch" spacing={3}>
                          <Grid item xs={12} xl={6}>
                            <Field
                              name="feesEnabled"
                              title="Donor covered transaction fees"
                              image={<VolunteerActivismOutlined />}
                              component={SwitchCardField}
                              renderContent={renderDonorCoverCard}
                              style={{ height: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} xl={6}>
                            <Field
                              name="roundupEnabled"
                              title="Mission Round-up"
                              image={<SvgIcon component={RoundUpIcon} />}
                              component={SwitchCardField}
                              renderContent={renderMissionRoundUpCard}
                              style={{ height: '100%' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Button
                        disabled={submitting || pristine}
                        variant="text"
                        onClick={() => form.reset()}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button loading={submitting} disabled={pristine} onClick={handleSubmit}>
                        {pristine ? 'Saved' : 'Save'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Form>
    </PaperBlock>
  );
};
