import React from 'react';
import { Form as FinalForm, FormSpy, FormSpyRenderProps } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { validateWithSchema } from '@vizsla/utils';
import { useFormStepper, FormStep, useCurrentUser } from '@vizsla/hooks';

import { useCampaignRegistration } from 'src/hooks/campaign';
import {
  CampaignRegistrationStepperController,
  CampaignImpactGivingFormOne,
  CampaignUploadLogoForm,
  CampaignGoalsForm,
  CampaignRegistrationFinalScreen,
  CampaignRegistrationStepperTitle,
} from 'src/components/campaign';
import {
  CAMPAIGN_IMPACT_GIVING_INITIAL_FIRST_FORM_FIELDS,
  CAMPAIGN_IMPACT_GIVING_INITIAL_SECOND_FORM_FIELDS,
  CAMPAIGN_IMPACT_GIVING_INITIAL_THREE_FORM_FIELDS,
} from 'src/constants/campaign';
import {
  CampaignImpactGivingFormOneSchema,
  CampaignImpactGivingGoalsFormSchema,
} from 'src/constants/validationSchemas/campaign';
import { buildCampaignImpactGivingFormData } from 'src/utils/campaign';

const FORM_STEPS: Array<FormStep> = [
  {
    render: () => {
      return <CampaignImpactGivingFormOne />;
    },
    validate: async (formValues: any) => {
      const formErrors = await validateWithSchema(CampaignImpactGivingFormOneSchema, formValues);

      return formErrors;
    },
  },
  {
    render: () => {
      return <CampaignUploadLogoForm />;
    },
  },
  {
    render: () => {
      return <CampaignGoalsForm />;
    },
    validate: async (formValues: any) => {
      const formErrors = await validateWithSchema(CampaignImpactGivingGoalsFormSchema, formValues);

      return formErrors;
    },
  },
];

const CAMPAIGN_INFO_FORM_INDEX = 0;
const CAMPAIGN_IMAGE_FORM_INDEX = 1;
const CAMPAIGN_GOALS_FORM_INDEX = 2;

interface CampaignImpactGivingStepsProps {
  onCloseModal: () => void;
}

export const CampaignImpactGivingSteps: React.FC<CampaignImpactGivingStepsProps> = ({
  onCloseModal,
}) => {
  const { step, isLast, isFirst, prev, next, validate, render } = useFormStepper(FORM_STEPS);

  const { campaignType, createCampaign, isCreatingCampaign, createdCampaign } =
    useCampaignRegistration();

  const { firstName } = useCurrentUser();

  const initialValues = {
    ...CAMPAIGN_IMPACT_GIVING_INITIAL_FIRST_FORM_FIELDS,
    ...CAMPAIGN_IMPACT_GIVING_INITIAL_SECOND_FORM_FIELDS,
    ...CAMPAIGN_IMPACT_GIVING_INITIAL_THREE_FORM_FIELDS,
  };

  const getTitle = (values: any): string => {
    switch (step) {
      case CAMPAIGN_INFO_FORM_INDEX: {
        return `Okay ${firstName}, here we go!`;
      }
      case CAMPAIGN_IMAGE_FORM_INDEX: {
        return values.name;
      }
      case CAMPAIGN_GOALS_FORM_INDEX: {
        return values.name;
      }
      default:
        return `Okay ${firstName}, here we go!`;
    }
  };

  const getSubtitle = (): string => {
    switch (step) {
      case CAMPAIGN_IMAGE_FORM_INDEX: {
        return "Now let's add your logo and a money shot! You can always skip or edit later.";
      }
      case CAMPAIGN_GOALS_FORM_INDEX: {
        return `Nice, ${firstName}. You're doing great.`;
      }
      default:
        return "Let's kick this campaign off!";
    }
  };

  const onCreateCampaign = async (formValues: any) => {
    const campaignData = buildCampaignImpactGivingFormData({ ...formValues, campaignType });

    try {
      await createCampaign(campaignData);
    } catch (error) {
      console.error({ error });
    }
  };

  const shouldShowSkipButton = step === CAMPAIGN_IMAGE_FORM_INDEX;
  const shouldShowBackButton = !isFirst;
  const shouldShowFinalScreen = createdCampaign !== null;

  if (shouldShowFinalScreen) {
    return <CampaignRegistrationFinalScreen onManage={onCloseModal} />;
  }

  return (
    <FinalForm
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      validate={validate}
      onSubmit={onCreateCampaign}
      subscription={{ submitting: true }}
      render={({ handleSubmit }) => {
        return (
          <React.Fragment>
            <FormSpy subscription={{ values: true }}>
              {(spyRenderProps: FormSpyRenderProps) => {
                return (
                  <CampaignRegistrationStepperTitle
                    title={getTitle(spyRenderProps.values)}
                    subtitle={getSubtitle()}
                  />
                );
              }}
            </FormSpy>
            {render()}
            <FormSpy subscription={{ valid: true, validating: true }}>
              {(spyRenderProps: FormSpyRenderProps) => {
                return (
                  <CampaignRegistrationStepperController
                    shouldShowSkipButton={shouldShowSkipButton}
                    shouldShowBackButton={shouldShowBackButton}
                    isLastStep={isLast}
                    onCancel={onCloseModal}
                    onContinue={next}
                    onCreate={handleSubmit}
                    isCreating={isCreatingCampaign}
                    canContinue={spyRenderProps.valid && !spyRenderProps.validating}
                    onBack={prev}
                    onSkip={next}
                  />
                );
              }}
            </FormSpy>
          </React.Fragment>
        );
      }}
    />
  );
};
