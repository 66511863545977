import React from 'react';
import { CircularProgress, Typography } from '@mui/material';

import { useModal } from '@vizsla/hooks';
import { MODALS } from '@vizsla/constants';

import { CreatePartnershipContactModal } from './CreatePartnershipContactModal';
import { ContactsWrapper, Content, HyperlinkButton } from './styles';
import { PartnershipContactTable } from './PartnershipContactTable';

export const OrganizationPartnershipContact: React.FC<{ partnershipId?: string }> = ({
  partnershipId,
}) => {
  const { openModal } = useModal(MODALS.PARTNERSHIP_CONTACT_CREATE);

  return (
    <ContactsWrapper>
      {!partnershipId ? (
        <CircularProgress />
      ) : (
        <Content>
          <CreatePartnershipContactModal />
          <Typography style={{ marginBottom: '1rem' }} variant="h3">
            Main Contact
          </Typography>

          <PartnershipContactTable partnershipId={partnershipId} />

          <HyperlinkButton
            onClick={() => {
              openModal(MODALS.PARTNERSHIP_CONTACT_CREATE, {
                partnershipId,
              });
            }}
            type="button"
            variant="hyperlink"
          >
            + ADD CONTACT
          </HyperlinkButton>
        </Content>
      )}
    </ContactsWrapper>
  );
};
