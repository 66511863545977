export enum ExcludedBibValidationErrors {
  isNAN = 'experience_excluded_bib_number_create_error',
  isDuplicateAnotherExcludedBib = 'experience_excluded_bib_number_create_duplicate_error',
  isDuplicateAnotherIndividualBib = 'experience_excluded_bib_number_create_duplicate_individual_bib_error',
}

export enum BibAssignmentMethod {
  Auto = 'Auto',
  Batch = 'Batch',
}

export enum BibAssignmentType {
  Pool = 'Pool',
  Individual = 'Individual',
  Excluded = 'Excluded',
  Reserve = 'Reserve',
}

export enum BibBatchAssignSort {
  RegistrationDateTime = 'RegistrationDateTime',
  LastNameFirstNameAddress = 'LastNameFirstNameAddress',
  FirstNameLastNameAddress = 'FirstNameLastNameAddress',
  AgeLastNameFirstName = 'AgeLastNameFirstName',
  FundraisingAmount = 'FundraisingAmount',
}

export enum BibSortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum BibAutoAssignmentStatus {
  Inactive = 'Inactive',
  Scheduled = 'Scheduled',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

export type BibAutoAssignDates = {
  start?: string | null;
  end?: string | null;
};

export type BibAutoAssignDatesFormData = {
  autoAssignStartDate: string;
  autoAssignStartTime: string;
  autoAssignEndDate: string;
  autoAssignEndTime: string;
};
