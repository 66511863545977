import { useQuery } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';

import {
  useCurrentOrganization,
  useCustomFiltersQueryVariables,
  usePagination,
  usePaginationQueryVariables,
  useSearchQueryVariables,
} from '@vizsla/hooks';

import { ORDERS_ORGANIZATION_QUERY } from 'src/graphql/transactions';

import { TransactionsTableView } from '../TransactionsTableView';

export function TransactionsListContent() {
  const pagination = usePagination();
  const searchQueryVariables = useSearchQueryVariables([
    'Ordersubtype',
    'OrderStatus',
    'CampaignName',
    'ExperienceName',
    'OrderItemTransType',
  ]);
  const customFiltersQueryVariables = useCustomFiltersQueryVariables();
  const paginationQueryVariables = usePaginationQueryVariables();
  const { organizationId } = useCurrentOrganization();
  const { data, loading } = useQuery(ORDERS_ORGANIZATION_QUERY, {
    variables: {
      filter: {
        ...customFiltersQueryVariables,
        ...searchQueryVariables,
        VizslaOrganizationID: { equals: organizationId },
      },
      ...paginationQueryVariables,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const transactions = useMemo(() => {
    return data?.transactionVizslaViewExpsList.items ?? [];
  }, [data]);
  const transactionsCount = useMemo(() => data?.transactionVizslaViewExpsList.count ?? [], [data]);
  useEffect(() => {
    if (transactionsCount > 0 && pagination.count !== transactionsCount) {
      pagination.onCountChange(transactionsCount);
    }

    if (transactionsCount < pagination.page * pagination.rowsPerPage) {
      pagination.onPageChange(null, 1);
    }
  }, [transactionsCount]);

  return (
    <React.Fragment>
      <TransactionsTableView
        transactions={transactions}
        pagination={pagination}
        loading={loading}
      />
    </React.Fragment>
  );
}
