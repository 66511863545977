import React from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import { Box, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import _, { filter } from 'lodash';

import {
  usePagination,
  useCurrentOrganization,
  usePaginationQueryVariables,
  useSearchQueryVariables,
  useCustomFiltersQueryVariables,
} from '@vizsla/hooks';
import { Campaign, CampaignsPageQuery, CampaignsPageQueryVariables } from '@vizsla/graphql';
import { TablePagination } from '@vizsla/components';

import { CampaignsListViewsContext } from 'src/providers/campaign';
import { CampaignLayoutView, CampaignView } from 'src/types/campaignsList';
import { CAMPAIGNS_PAGE_QUERY, PROGRAMS_LIST_QUERY } from 'src/graphql';
import { CampaignsListItem } from 'src/types/campaign';

import CampaignsListTileView from '../CampaignsListTilesView';
import { CampaignsListTableView } from '../CampaignsListTableView';
import { rangeFilter } from './utils/campaignFilters';
import CampaignsProgramsListContent from '../CampaignsProgramsListContent';
import CampaignsProgramsListTable from '../CampaignsProgramsListTable';

interface StylesParams {
  layoutView: CampaignLayoutView;
}

const useStyles = makeStyles<Theme, StylesParams>(theme => ({
  pagination: {
    display: 'flex',
    justifyContent: ({ layoutView }) =>
      layoutView === CampaignLayoutView.Tile ? 'center' : 'flex-end',
    width: '100%',
    marginBottom: 0,
    marginTop: 'auto',
  },
  root: {
    minHeight: 'calc(100vh - 152px)',
    display: 'flex',
    flexDirection: 'column',
  },
  emptyMessage: {
    textAlign: 'center',
    paddingTop: theme.spacing(10),
  },
}));

export const CampaignsListContent: React.FC = () => {
  const { organizationId } = useCurrentOrganization();

  const { campaignLayoutView, campaignView } = React.useContext(CampaignsListViewsContext);

  const classes = useStyles({ layoutView: campaignLayoutView });

  const pagination = usePagination();

  const paginationQueryVariables = usePaginationQueryVariables();
  const searchQueryVariables = useSearchQueryVariables('name');
  const {
    teams: teamsRange,
    attendees: attendeesRange,
    ...customFiltersQueryVariables
  } = useCustomFiltersQueryVariables() as any;

  const { data, loading } = useQuery<CampaignsPageQuery, CampaignsPageQueryVariables>(
    CAMPAIGNS_PAGE_QUERY,
    {
      variables: {
        filter: {
          vizslaOrganization: {
            id: {
              equals: organizationId,
            },
          },
          ...customFiltersQueryVariables,
          ...searchQueryVariables,
        },
        ...paginationQueryVariables,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const {
    data: programData,
    loading: loadingPrograms,
    refetch,
  } = useQuery(PROGRAMS_LIST_QUERY, {
    variables: {
      filter: { organization: { id: { equals: organizationId } } },
      CampaignFilter: { ...customFiltersQueryVariables, ...searchQueryVariables },
    },
  });

  const programs = programData?.programsList?.items || [];

  const campaigns = ((data?.campaignsList?.items || []) as Campaign[])
    .filter(campaign => {
      return rangeFilter(campaign.teams?.count ?? 0, teamsRange?.from, teamsRange?.count);
    })
    .filter((campaign: CampaignsListItem) => {
      return rangeFilter(
        campaign.attendees?.groups?.[0]?.count ?? 0,
        attendeesRange?.from,
        attendeesRange?.to,
      );
    });

  const campaignTotalCount = data?.campaignsList?.count || 0;

  React.useEffect(() => {
    if (campaignTotalCount) {
      pagination.onCountChange(campaignTotalCount);
    }
  }, [campaignTotalCount, pagination]);

  React.useEffect(() => {
    if (campaignView === CampaignView.Programs) {
      refetch();
    }
  }, [campaignView]);

  const renderView = () => {
    if (campaignLayoutView === CampaignLayoutView.Tile && campaignView === CampaignView.All) {
      return (
        <React.Fragment>
          <CampaignsListTileView loading={loading} campaigns={campaigns} />
          <Box className={classes.pagination}>
            <TablePagination labelRowsPerPage="Campaigns per page" {...pagination} />
          </Box>
        </React.Fragment>
      );
    }
    if (campaignLayoutView === CampaignLayoutView.Grid && campaignView === CampaignView.All) {
      return <CampaignsListTableView loading={loading} campaigns={campaigns} />;
    }
    if (campaignLayoutView === CampaignLayoutView.Tile && campaignView === CampaignView.Programs) {
      return <CampaignsProgramsListContent programs={programs} loading={loadingPrograms} />;
    }

    return <CampaignsProgramsListTable programs={programs} loading={loadingPrograms} />;
  };

  if (_.isEmpty(campaigns) && !loading) {
    const message = _.isEmpty(searchQueryVariables)
      ? 'There`s no campaigns created yet'
      : `We can't find campaigns`;

    return (
      <Box className={clsx(classes.root, classes.emptyMessage)}>
        <Typography variant="display1">{message}</Typography>
      </Box>
    );
  }

  return <Box className={classes.root}>{renderView()}</Box>;
};
