import React from 'react';
import { Box, Grid, List, ListItemText, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Display, JustifyContent, PaletteColor, Shade } from '@vizsla/theme';
import { ListNavLinkItem } from '@vizsla/components';

import * as appRoutes from 'src/constants/routes';

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    borderRadius: 6,
    marginBottom: theme.spacing(1),
    '&.Mui-selected': {
      backgroundColor: Shade.Blue['25'],
      '& .MuiSvgIcon-root': {
        color: PaletteColor.PrimaryMain,
      },
      '& .MuiTypography-root': {
        color: PaletteColor.PrimaryMain,
      },
    },
  },
  sidebar: {
    padding: theme.spacing(0, 3, 0, 0),
    '&>*': {
      margin: theme.spacing(1, 0),
    },
    '&>*:first-child': {
      margin: theme.spacing(0, 0, 1, 0),
    },
  },
  addExperienceBtn: {
    borderRadius: '50%',
  },
}));

type AdminSettingsMenuItem = {
  to: string;
  text: string;
};

const ADMIN_SETTINGS_MENU_ITEMS: Array<AdminSettingsMenuItem> = [
  {
    to: 'payments',
    text: 'Payments',
  },
];

const AdminSettingsLayout: React.FC = ({ children: pageContent }) => {
  const classes = useStyles();

  const renderNavItem = ({ to, text }: AdminSettingsMenuItem) => {
    const adminSettingsRoute = `${appRoutes.adminSettings}/${to}`;

    return (
      <ListNavLinkItem className={classes.menuItem} key={text} to={adminSettingsRoute}>
        <ListItemText primary={text} />
      </ListNavLinkItem>
    );
  };

  return (
    <Grid container>
      <Grid container direction="row" spacing={4}>
        <Grid item xs={4}>
          <Box
            className={classes.sidebar}
            display={Display.Flex}
            justifyContent={JustifyContent.Center}
            flexDirection="column"
          >
            <List>{ADMIN_SETTINGS_MENU_ITEMS.map(menuItem => renderNavItem({ ...menuItem }))}</List>
          </Box>
        </Grid>
        <Grid item xs={8}>
          {pageContent}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminSettingsLayout;
