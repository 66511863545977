import React from 'react';

import { TemplateStructure } from '@vizsla/graphql';

import {
  GeneralLandingActions,
  INITIAL_STATE,
  INITIAL_THEME,
  LandingAction,
  LandingState,
} from './ThankYouLanding.types';
import { LandingStateContext } from './ThankYouLandingContext';
import { ThankYouPageContext } from './ThankYouContext';

interface LandingStateProviderProps {
  landingReducer: (state: LandingState, action: LandingAction) => LandingState;
  children: React.ReactNode;
}

function convertTemplateStructureToLandingState(
  templateStructure: TemplateStructure | null | undefined,
) {
  if (!templateStructure) {
    return INITIAL_STATE;
  }
  return {
    theme: templateStructure?.structure?.appSchema.theme || INITIAL_THEME,
    nodes: templateStructure?.structure?.appSchema.nodes,
    templateTitle: templateStructure?.templateType?.title as string,
    templateStructureId: templateStructure?.id as string,
  };
}

const ThankYouLandingProvider: React.FC<LandingStateProviderProps> = ({
  children,
  landingReducer,
}) => {
  const { templateStructure } = React.useContext(ThankYouPageContext);

  const [state, dispatch] = React.useReducer(landingReducer, INITIAL_STATE);

  React.useEffect(() => {
    dispatch({
      type: GeneralLandingActions.set,
      payload: convertTemplateStructureToLandingState(templateStructure),
    });
  }, [templateStructure]);

  const reset = () => {
    dispatch({
      type: GeneralLandingActions.set,
      payload: convertTemplateStructureToLandingState(templateStructure),
    });
  };

  const value = { state, dispatch, reset };
  return <LandingStateContext.Provider value={value}>{children}</LandingStateContext.Provider>;
};

export { ThankYouLandingProvider };
