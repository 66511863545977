import React from 'react';

import {
  StoreHistoryEvent,
  StoreHistoryEventsListQuery,
  StoreHistoryEventsListDocument,
  StoreHistoryEventsListQueryVariables,
  useStoreHistoryEventsListQuery,
  useStoreHistoryEventCreateWithRecalcMutation,
  StoreHistoryEventCreateWithRecalcMutationVariables,
} from '@vizsla/graphql';
import { useApolloCacheQueryManager } from '@vizsla/hooks';
import { mutableList } from '@vizsla/utils';
import { STORE_ALL_VALUE } from '@vizsla/constants';

import { useStoreAsset } from './useStoreAsset';
import { useStoreEventFilters } from './useStoreEventFilters';

enum StoreHistoryTypeName {
  storeHistoryEventList = 'StoreHistoryEventListResponse',
  storeHistoryEvent = 'StoreHistoryEvent',
}

type UserStoreHistoryHook = {
  storeHistoryEvents: Array<StoreHistoryEvent>;
  storeHistoryEventsCount: number;
  refetchHistory: () => void;
  loading: boolean;
  creating: boolean;
  createStoreHistoryEvent: (
    storeHistoryData: StoreHistoryEventCreateWithRecalcMutationVariables['data'],
  ) => Promise<void>;
};

export const useStoreHistory = (
  storeAssetId: string,
  queryVariables = {},
): UserStoreHistoryHook => {
  const { sizeFilter, styleFilter } = useStoreEventFilters();

  const sizeAttribute = undefined;
  const styleAttribute = undefined;

  const storeHistoryEventsListQueryVariables = {
    filter: {
      storeAsset: {
        id: {
          equals: storeAssetId,
        },
      },
      sizeAttribute,
      styleAttribute,
    },
    ...queryVariables,
  };

  const {
    data,
    loading,
    refetch: refetchHistory,
  } = useStoreHistoryEventsListQuery({
    variables: storeHistoryEventsListQueryVariables,
    skip: !storeAssetId,
  });

  const storeHistoryEvents = (data?.storeHistoryEventsList.items || []) as Array<StoreHistoryEvent>;
  const storeHistoryEventsCount = data?.storeHistoryEventsList.count || 0;

  const { refetchAsset } = useStoreAsset();

  const [storeHistoryEventCreateMutation, { loading: creating }] =
    useStoreHistoryEventCreateWithRecalcMutation();

  const { updateQuery: updateStoreHistoryEventQuery } = useApolloCacheQueryManager<
    StoreHistoryEventsListQuery,
    StoreHistoryEventsListQueryVariables
  >({
    query: StoreHistoryEventsListDocument,
    typeName: StoreHistoryTypeName.storeHistoryEventList,
    variables: storeHistoryEventsListQueryVariables,
  });

  const createStoreHistoryEvent = React.useCallback(
    async (storeHistory: StoreHistoryEventCreateWithRecalcMutationVariables['data']) => {
      try {
        const { data } = await storeHistoryEventCreateMutation({
          variables: { data: storeHistory },
        });
        refetchAsset();
      } catch (error) {
        console.error({ error });
        throw error;
      }
    },
    [updateStoreHistoryEventQuery, storeHistoryEventCreateMutation],
  );

  return {
    loading,
    creating,
    storeHistoryEvents,
    storeHistoryEventsCount,
    createStoreHistoryEvent,
    refetchHistory,
  };
};
