import React from 'react';
import { Grid } from '@mui/material';

import { useCampaign, useCampaignId } from 'src/hooks/campaign';
import { CampaignType } from 'src/types/campaign';

import {
  TotalDonorsCard,
  TotalRaisedCard,
  TotalTeamsCard,
  TotalAttendeesCard,
  AverageDonationCard,
  RecurringCard,
} from './components';

export const CampaignPeopleMetrics: React.FC = () => {
  const id = useCampaignId();

  const { data: campaign } = useCampaign(id);

  switch (campaign?.campaignType) {
    case CampaignType.impactGiving: {
      return (
        <Grid container spacing={2}>
          <Grid item flexGrow={1} flexBasis={0}>
            <TotalRaisedCard />
          </Grid>

          <Grid item flexGrow={1} flexBasis={0}>
            <TotalDonorsCard />
          </Grid>

          <Grid item flexGrow={1} flexBasis={0}>
            <AverageDonationCard />
          </Grid>

          <Grid item flexGrow={1} flexBasis={0}>
            <RecurringCard recurringValue={0} loading={false} />
          </Grid>
        </Grid>
      );
    }

    case CampaignType.personalMission: {
      return (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TotalRaisedCard />
          </Grid>

          <Grid item xs={3}>
            <TotalAttendeesCard />
          </Grid>

          <Grid item xs={3}>
            <TotalTeamsCard />
          </Grid>

          <Grid item xs={3}>
            <TotalDonorsCard />
          </Grid>
        </Grid>
      );
    }

    case CampaignType.registrationAndTicketing: {
      return (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TotalRaisedCard />
          </Grid>

          <Grid item xs={3}>
            <TotalAttendeesCard />
          </Grid>

          <Grid item xs={3}>
            <TotalTeamsCard />
          </Grid>

          <Grid item xs={3}>
            <TotalDonorsCard />
          </Grid>
        </Grid>
      );
    }

    default: {
      return null;
    }
  }
};
