import React from 'react';
import { Typography } from '@mui/material';
import { isNil } from 'lodash';

import { usePagination, usePaginationQueryVariables } from '@vizsla/hooks';
import { formatDate, formatNumber, formatMoney } from '@vizsla/utils';
import { PaletteColor } from '@vizsla/theme';
import { DateFormatPatterns } from '@vizsla/constants';
import { TableData, TableColumn } from '@vizsla/components';

import { getUserFullName } from 'src/utils/user';
import { useStoreAssetId, useStoreHistory } from 'src/hooks/storeAssets';
import type { StoreHistoryEvent } from 'src/types/storeAssets';
import { StoreHistoryEventType } from 'src/types/storeAssets';

const InventoryTable: React.FC = () => {
  const storeAssetId = useStoreAssetId();

  const pagination = usePagination();
  const paginationQueryVariables = usePaginationQueryVariables();

  const { storeHistoryEvents, loading } = useStoreHistory(storeAssetId, paginationQueryVariables);

  const TABLE_COLUMNS: Array<TableColumn<StoreHistoryEvent>> = React.useMemo(
    () => [
      {
        key: 'type',
        title: 'Type',
        render: (value, { type }) => {
          return type;
        },
      },
      {
        key: 'quantity',
        title: 'Quantity',
        render: (value, { type, quantity }) => {
          let convertibleQuantity = quantity || 0;
          if (type !== StoreHistoryEventType.Add) {
            convertibleQuantity *= -1;
          }
          const typographyColor = convertibleQuantity < 0 ? PaletteColor.ErrorLight : undefined;
          const formattedQuantity = formatNumber(convertibleQuantity);
          return (
            <Typography variant="body2" color={typographyColor}>
              {formattedQuantity}
            </Typography>
          );
        },
      },
      {
        key: 'size',
        title: 'Size',
        render: (value, { type, variantsValues }) => {
          let sizeLabel = '-';
          if (variantsValues) {
            const size =
              Object.entries(variantsValues)?.filter(entrie => entrie[0] === 'Size')[0] || [];
            if (size?.length) {
              const label = size[1] as string;
              sizeLabel = label === '' ? '-' : label;
            }
          }
          return <Typography variant="body2">{sizeLabel}</Typography>;
        },
      },
      {
        key: 'style',
        title: 'Style',
        render: (value, { type, variantsValues }) => {
          let styleLabel = '-';
          if (variantsValues) {
            const size =
              Object.entries(variantsValues).filter(entrie => entrie[0] === 'Style')[0] || [];
            if (size?.length) {
              const label = size[1] as string;
              styleLabel = label === '' ? '-' : label;
            }
          }
          return <Typography variant="body2">{styleLabel}</Typography>;
        },
      },
      {
        key: 'other',
        title: 'Custom',
        render: (value, { type, variantsValues }) => {
          let styleLabel = '-';
          if (variantsValues) {
            const size =
              Object.entries(variantsValues).filter(
                entrie => entrie[0] !== 'Style' && entrie[0] !== 'Size',
              )[0] || [];
            if (size?.length) {
              const label = size[1] as string;
              styleLabel = label;
            }
          }
          return <Typography variant="body2">{styleLabel}</Typography>;
        },
      },
      {
        key: 'createdAt',
        title: 'Created At',
        render: (value, { createdAt }) => {
          return formatDate(createdAt, DateFormatPatterns.smallDateWithLocalTime);
        },
      },
      {
        key: 'createdBy',
        title: 'Created By',
        render: (value, { createdBy }) => {
          return getUserFullName(createdBy);
        },
      },
      {
        key: 'orderCost',
        title: 'Order Cost',
        render: (value, { orderCost }) => {
          if (isNil(orderCost)) {
            return '-';
          }

          return formatMoney(orderCost);
        },
      },
      {
        key: 'dateReceived',
        title: 'Date Received',
        render: (value, { dateReceived }) => {
          return formatDate(dateReceived, DateFormatPatterns.smallDateWithLocalTime) || '-';
        },
      },
      {
        key: 'notes',
        title: 'Notes',
        align: 'right',
        render: (value, { note }) => {
          return note || '-';
        },
      },
    ],
    [storeHistoryEvents],
  );

  return (
    <TableData
      dataSource={storeHistoryEvents}
      columns={TABLE_COLUMNS}
      loading={loading}
      pagination={pagination}
    />
  );
};

export { InventoryTable };
