import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FontSize, PaletteColor } from 'src/theme';

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 10,
    overflowWrap: 'break-word',
  },
  subtitle: {
    fontSize: FontSize.M,
    color: PaletteColor.GrayText,
  },
}));

interface CampaignRegistrationStepperTitleProps {
  title: string;
  subtitle: string;
}

export const CampaignRegistrationStepperTitle: React.FC<CampaignRegistrationStepperTitleProps> = ({
  title,
  subtitle,
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid className={classes.container} item xs={12}>
        <Typography variant="h1" align="center">
          {title}
        </Typography>
      </Grid>
      <Grid className={classes.container} item xs={12}>
        <Typography className={classes.subtitle} variant="h6" align="center">
          {subtitle}
        </Typography>
      </Grid>
    </React.Fragment>
  );
};
