import React from 'react';
import { useMutation } from '@apollo/client';
import { Grid, LinearProgress, Typography, styled, TextField, DialogActions } from '@mui/material';
import _ from 'lodash';

import { useModal, useNotification } from '@vizsla/hooks';
import { Shade } from '@vizsla/theme';
import { Button } from '@vizsla/components';
import { CrmContactUpdateInput } from '@vizsla/graphql';

import { USER_CONTACT_PROFILE_UPDATE_MUTATION } from 'src/graphql/peopleCRM';
import { MODALS } from 'src/constants/modals';

interface ContactInfoModalContentProps {
  other: any;
}
const MAP_PERSONAL_INFO_BLOCKS = [
  {
    label: 'First Name',
    propSelectorFn: user => user.firstName,
  },
  {
    label: 'Last Name',
    propSelectorFn: user => user.lastName,
  },
  {
    label: 'Middle Name',
    propSelectorFn: user => (user.middleName ? user.middleName : 'N/A'),
  },
  {
    label: 'Email',
    propSelectorFn: user => user.email,
  },
  {
    label: 'Phone 1',
    propSelectorFn: user => `+${user?.phone1?.code}-${user?.phone1?.number}`,
  },
  {
    label: 'Phone 2',
    propSelectorFn: user =>
      user?.phone2?.number ? `+${user?.phone2?.code}-${user?.phone2?.number}` : 'N/A',
  },
];

const getFullAddressString = (user?: any): string => {
  if (!user || !user.address) {
    return 'N/A';
  }
  const { address } = user;
  return `${[
    address.street2 ? address.street2 : null,
    address.street1 ? address.street1 : null,
    address.city ? address.city : null,
    address.state ? address.state : null,
    address.country ? address.country : null,
    address.zip ? address.zip : null,
  ].filter(entry => !_.isNil(entry))}`;
};

const StyledLightTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const StyledEditButton = styled(Button)(() => ({
  margin: 0,
  backgroundColor: Shade.Teal[50],
  color: Shade.Teal[700],
  '&:hover': {
    backgroundColor: Shade.Teal[25],
  },
}));

const ContactInfoModalOthers: React.FC<ContactInfoModalContentProps> = ({ other }) => {
  const [note, setNote] = React.useState(other?.note);

  const notification = useNotification();

  const { isOpen, closeModal } = useModal(MODALS.PEOPLE_CRM_CONTACT_INFO_MODAL);

  const [cRMContactUpdate, { loading }] = useMutation<CrmContactUpdateInput>(
    USER_CONTACT_PROFILE_UPDATE_MUTATION,
    {
      refetchQueries: ['CRMContactsList'],
      awaitRefetchQueries: true,
    },
  );

  const StyledPersonalInfoBlock = styled(Grid)(() => ({
    '& > div': {
      flex: '0 1 200px',
    },
  }));

  const renderPersonalInfoBlock = React.useCallback(
    (key: string, label: string, propSelectorFn: any) => {
      if (!other) {
        return null;
      }

      const infoText = propSelectorFn(other) || 'N/A';

      return (
        <Grid key={key} item>
          <StyledLightTypography variant="caption">{label}</StyledLightTypography>
          <Typography variant="body1">{infoText}</Typography>
        </Grid>
      );
    },
    [other],
  );

  const onSubmit = async () => {
    try {
      await cRMContactUpdate({
        variables: {
          data: {
            id: other?.id,
            note,
          },
        },
      });
      notification.success('User Edit');
      closeModal();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Grid
      container
      // alignContent="center"
      // justifyContent="center"
      // height="100vh"
    >
      <Grid item>
        <Typography variant="h6" mb={2}>
          Personal Info
        </Typography>
        <StyledPersonalInfoBlock container item direction="row" rowSpacing={4} columnSpacing={10}>
          {MAP_PERSONAL_INFO_BLOCKS.map((block, idx) =>
            renderPersonalInfoBlock(`personal-info-block${idx}`, block.label, block.propSelectorFn),
          )}
        </StyledPersonalInfoBlock>
      </Grid>
      <Grid item mt={5}>
        <Typography variant="h6" mb={2}>
          Address
        </Typography>
        <Typography variant="body1">{getFullAddressString(other)}</Typography>
      </Grid>

      <Grid item mt={5} xs={12}>
        <Typography variant="h6" mb={2}>
          Note
        </Typography>
        <TextField
          id="outlined-basic"
          label="Notes"
          variant="outlined"
          value={note}
          onChange={e => setNote(e.target.value)}
        />
      </Grid>
      <Grid item mt={5}>
        <Button loading={loading} onClick={onSubmit}>
          Edit
        </Button>
      </Grid>
    </Grid>
  );
};

export { ContactInfoModalOthers };
