var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { FontSize } from '@vizsla/theme';
const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '20px 7px',
        cursor: 'text',
    },
}));
const StripeInput = React.forwardRef(function StripeInput(_a, ref) {
    var { component: Component, onChange, name } = _a, rest = __rest(_a, ["component", "onChange", "name"]);
    const classes = useStyles();
    const elementRef = useRef();
    React.useImperativeHandle(ref, () => ({
        focus: () => { var _a; return (_a = elementRef === null || elementRef === void 0 ? void 0 : elementRef.current) === null || _a === void 0 ? void 0 : _a.focus; },
    }));
    const handleChange = (event) => {
        const { elementType, value, error, empty, complete } = event;
        if (onChange) {
            onChange({
                target: {
                    name: elementType || '',
                    value,
                    error,
                    empty,
                    complete,
                },
            });
        }
    };
    return (_jsx(Component, Object.assign({ className: classes.root, ref: elementRef, 
        /* eslint-disable-next-line no-return-assign */
        onReady: (element) => (elementRef.current = element), onChange: handleChange, options: {
            placeholder: '',
            style: {
                base: {
                    fontSize: FontSize.M,
                    color: '#000000',
                },
            },
        } }, rest)));
});
export { StripeInput };
