import React from 'react';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CampaignsListTile } from '../CampaignsListTilesView/components';
import { ProgressBar } from '../CampaignsProgramsListTable/componets';

const useStyles = makeStyles({
  container: {
    overflowY: 'scroll',
  },
});

const CampaignsProgramsListContent = ({ programs, loading = false }) => {
  const classes = useStyles();

  const sortData = (x, y) => {
    return x.name.localeCompare(y.name);
  };

  const programsCopy = [...programs];

  const sortPrograms = programsCopy.sort(sortData) || [];

  if (loading) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Grid>
      {sortPrograms.map(program => (
        <Grid key={program.id} mt="40px">
          <Grid container>
            <Typography fontSize={20} fontWeight={800}>
              {program.name}
            </Typography>
            <Typography fontSize={20} color="#848C96" ml="5px">
              ({program?.campaigns.items.length})
            </Typography>
          </Grid>

          {program?.campaigns.items.length > 0 && (
            <ProgressBar campaings={program?.campaigns.items} />
          )}
          <Grid container flexWrap="wrap">
            {program?.campaigns.items.length ? (
              program?.campaigns.items.map(campaign => (
                <Box mr="32px" mb="32px" key={campaign.id}>
                  <CampaignsListTile campaign={campaign} />
                </Box>
              ))
            ) : (
              <Box>
                <Typography>No results to display</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default React.memo(CampaignsProgramsListContent);
