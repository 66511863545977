import React from 'react';
import { Grid } from '@mui/material';

import { CampaignContactForm } from 'src/components/campaign';

import { CampaignDetailsForm } from './CampaignDetailsForm';
import { CampaignQuestions } from './CampaignQuestions';
import { CampaignFAQs } from './CampaignFAQs';

const CampaignBasics: React.FC = () => {
  return (
    <Grid spacing={3} container flexDirection="column">
      <Grid item>
        <CampaignDetailsForm />
      </Grid>
      <Grid item>
        <CampaignContactForm />
      </Grid>
      <Grid item>
        <CampaignQuestions />
      </Grid>
      <Grid item>
        <CampaignFAQs />
      </Grid>
    </Grid>
  );
};

export { CampaignBasics };
