import _ from 'lodash';
import { StripeCardFieldName } from './types';
const STRIPE_FIELD_REQUIRED_ERROR_MESSAGE = {
    [StripeCardFieldName.Number]: 'Card Number is required',
    [StripeCardFieldName.Expiry]: 'Expiration date is required',
    [StripeCardFieldName.Cvc]: 'CVV/CVC is required',
};
export const getStripeFieldError = (field, securedFields) => {
    var _a, _b, _c;
    if (_.isNil(securedFields === null || securedFields === void 0 ? void 0 : securedFields[field]) || ((_a = securedFields === null || securedFields === void 0 ? void 0 : securedFields[field]) === null || _a === void 0 ? void 0 : _a.empty)) {
        return STRIPE_FIELD_REQUIRED_ERROR_MESSAGE[field];
    }
    const error = (_b = securedFields === null || securedFields === void 0 ? void 0 : securedFields[field]) === null || _b === void 0 ? void 0 : _b.error;
    if (!_.isNil(error)) {
        return (_c = error === null || error === void 0 ? void 0 : error.message) !== null && _c !== void 0 ? _c : 'Error';
    }
    return null;
};
