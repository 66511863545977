import React from 'react';
import { Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { UserAvatarFullNameInline } from '@vizsla/components';

import { ORDERS_BY_DONOR } from 'src/graphql/orders';

import {
  Container,
  Content,
  ContentLoading,
  Paragraph,
  Subtitle,
  Title,
  Wrapper,
} from './TransactionsPurchaseInfo.style';
import { TransactionsCardsLayout } from '../../TransactionsCardLayout';

const CIRCULAR_PROGRESS_SIZE = 48;

export function TransactionsPurchaseInfo() {
  const { orderId } = useParams();
  const { data: dataDonor, loading } = useQuery(ORDERS_BY_DONOR, {
    variables: {
      orderId,
    },
  });

  const user = React.useMemo(() => dataDonor?.order?.user, [dataDonor]);

  if (!user) {
    return (
      <TransactionsCardsLayout>
        <Container>
          <ContentLoading>
            <Skeleton variant="text" width={288} height={35} />

            <Wrapper>
              <Skeleton
                variant="circular"
                width={CIRCULAR_PROGRESS_SIZE}
                height={CIRCULAR_PROGRESS_SIZE}
              />
              <Skeleton variant="text" width={80} height={32} />
            </Wrapper>
            <Skeleton variant="rectangular" width={143} height={64} />
          </ContentLoading>
        </Container>
      </TransactionsCardsLayout>
    );
  }

  return (
    <TransactionsCardsLayout active>
      <Container>
        <Wrapper>
          <Title variant="h4">Purchase Information</Title>
        </Wrapper>
        <Wrapper>
          <UserAvatarFullNameInline user={user} showEmail />
        </Wrapper>
        <Content>
          <Wrapper>
            <Paragraph variant="subtitle2">First Name</Paragraph>
            <Subtitle> {user?.firstName}</Subtitle>
          </Wrapper>
          <Wrapper>
            <Paragraph variant="subtitle2">Last Name</Paragraph>
            <Subtitle> {user?.lastName}</Subtitle>
          </Wrapper>
        </Content>
      </Container>
    </TransactionsCardsLayout>
  );
}
