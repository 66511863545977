import React from 'react';
import { Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

export interface TransitionSlideProps extends TransitionProps {
  // eslint-disable-next-line
  direction?: 'up' | 'down' | 'left' | 'right';
  // eslint-disable-next-line
  children: React.ReactElement;
}

export const TransitionSlide = React.forwardRef(function Transition(
  props: TransitionSlideProps,
  ref: React.Ref<unknown>,
) {
  return <Slide direction={props.direction || 'up'} ref={ref} {...props} />;
});
