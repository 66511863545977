import React from 'react';
import { Grid, styled } from '@mui/material';
import { isNil } from 'lodash';

import { Button } from '@vizsla/components';
import { useModal } from '@vizsla/hooks';

import { PaletteColor } from 'src/theme';
import { useStoreAsset } from 'src/hooks/storeAssets';
import { MODALS } from 'src/constants/modals';
import type { StoreAssetUpdateOrDeleteImageModalArgs } from 'src/types/storeAssets';
import { EMPTY_IMAGE } from 'src/assets';

const StyledImage = styled('img')(({ theme }) => ({
  display: 'block',
  maxWidth: '100%',
  maxHeight: 500,
  objectFit: 'contain',
  margin: '0 auto',
}));

const StyledButtonsGrid = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
}));

const AssetImageUpdateBox: React.FC = () => {
  const { openModal } = useModal();

  const { storeAsset } = useStoreAsset();

  const imageUrl = storeAsset.image?.downloadUrl ?? EMPTY_IMAGE;

  const onOpenStoreAssetUpdateModal = React.useCallback(() => {
    const modalArgs: StoreAssetUpdateOrDeleteImageModalArgs = {
      storeAsset,
    };
    openModal(MODALS.STORE_ASSET_UPDATE_IMAGE_MODAL, modalArgs);
  }, [openModal, storeAsset]);

  const onOpenStoreAssetDeleteModal = React.useCallback(() => {
    const modalArgs: StoreAssetUpdateOrDeleteImageModalArgs = {
      storeAsset,
    };
    openModal(MODALS.STORE_ASSET_DELETE_IMAGE_MODAL, modalArgs);
  }, [openModal, storeAsset]);

  const isImageExists = React.useMemo(() => !isNil(storeAsset.image), [storeAsset]);

  const renderEditButtonText = () => {
    return isImageExists ? 'Edit Image' : 'Add Image';
  };

  const renderDeleteButton = () => {
    if (!isImageExists) {
      return null;
    }

    return (
      <Button
        variant="hyperlink"
        onClick={onOpenStoreAssetDeleteModal}
        sx={{ color: PaletteColor.ErrorDark }}
      >
        Delete image
      </Button>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledImage src={imageUrl} alt="store asset" />
      </Grid>

      <StyledButtonsGrid item xs={12} mt={2}>
        <Button variant="hyperlink" onClick={onOpenStoreAssetUpdateModal}>
          {renderEditButtonText()}
        </Button>

        {renderDeleteButton()}
      </StyledButtonsGrid>
    </Grid>
  );
};

export { AssetImageUpdateBox };
