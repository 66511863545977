import { useStripeCreateConnectAccountLinkMutation } from '@vizsla/graphql';

export const useCreateConnectAccountLink = () => {
  const [stripeCreateConnectAccountLinkMutation, { loading }] =
    useStripeCreateConnectAccountLinkMutation();

  const createConnectAccountLink = async (
    refreshUrl: string,
    returnUrl: string,
    organizationId: string,
  ) => {
    const { data } = await stripeCreateConnectAccountLinkMutation({
      variables: {
        refreshUrl,
        returnUrl,
        organizationId,
      },
    });

    return data?.stripeCreateConnectAccountLink?.accountLink;
  };

  return {
    createConnectAccountLink,
    loading,
  };
};
