import { useExperienceQuery } from '@vizsla/graphql';

export const useExperienceById = (id: string) => {
  const { data, loading, error } = useExperienceQuery({
    variables: {
      id,
    },
    skip: !id,
  });

  const experience = data?.experience;

  return {
    experience,
    loading,
    error,
  };
};
