import React from 'react';
import { Grid, styled } from '@mui/material';
import { Form } from 'react-final-form';
import { FormApi } from 'final-form';

import { useNotification } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import {
  useStoreAssetUpdateMutation,
  useStoreGhostItemUpdateByFilterMutation,
} from '@vizsla/graphql';
import { Button } from '@vizsla/components';

import { useStoreAsset } from 'src/hooks/storeAssets';
import { StoreAssetCreateOrUpdateFormValues } from 'src/types/storeAssets';
import { StoreAssetCreateOrUpdateFormSchema } from 'src/constants/validationSchemas/storeAssets';
import { resetFieldMutators } from 'src/utils/form';
import { t } from 'src/utils/template';

import { getInfoUpdateFormValues, getInfoUpdateMutationInput } from '../utils/formUtils';
import {
  StoreAssetFormAdditionalInformationSection,
  StoreAssetFormAvailabilitySection,
  StoreAssetFormStockControlSection,
  StoreAssetFormAttributesSection,
} from '../../sharedFormSections';
import { FormCommonInfoSection } from './sections';

const StyledFormNode = styled('form')(({ theme }) => ({
  display: 'contents',
}));

const StyledActionButton = styled(Button)(({ theme }) => ({
  margin: 0,
  '&:not(:first-child)': {
    marginLeft: theme.spacing(1),
  },
}));

const AssetInfoUpdateForm: React.FC = () => {
  const notification = useNotification();

  const { storeAsset, refetchAsset } = useStoreAsset();

  const [variantsPossible, setVariantsPossible] = React.useState([]);
  const [variantPossiblePrevState, setVariantPossiblePrevState] = React.useState([]);

  const [isDisable, setIsDisable] = React.useState(true);

  const [storeAssetUpdateMutation, { loading }] = useStoreAssetUpdateMutation();

  const [storeInventoryItemUpdateByFilter] = useStoreGhostItemUpdateByFilterMutation();
  // if we don't save ref, form when submitting will be
  // re-rendered with the previous values
  const initialValues = React.useRef(getInfoUpdateFormValues(storeAsset));

  React.useEffect(() => setIsDisable(false), [variantsPossible]);

  React.useEffect(() => {
    if (storeAsset?.variantsPossible) {
      const mapVariants = Object.entries(storeAsset?.variantsPossible).map(entry => {
        return { id: Date.now() + Math.random() * 10000, [entry[0]]: entry[1] };
      });
      setVariantsPossible(mapVariants as []);
      setVariantPossiblePrevState(mapVariants as []);
    }
  }, [storeAsset]);

  const onSubmit = React.useCallback(
    async (formValues: StoreAssetCreateOrUpdateFormValues, form: FormApi<any>) => {
      const filterVAriantPossible = variantsPossible.reduce((accum, variant) => {
        const key = Object.keys(variant)[1];
        return { ...accum, [key]: variant[key] };
      }, {} as any);
      const inputData = {
        ...getInfoUpdateMutationInput(formValues),
        variantsPossible: filterVAriantPossible,
      };

      try {
        const { data } = await storeAssetUpdateMutation({
          variables: {
            data: inputData,
            id: storeAsset.id ?? '',
          },
        });

        const newStoreAssetData = data?.storeAssetUpdate;

        await form.restart(getInfoUpdateFormValues(newStoreAssetData));

        await storeInventoryItemUpdateByFilter({
          variables: {
            data: {
              itemToSellWhenOutOfStock: [{ set: inputData?.continueSalesWhenOutOfStock }],
            },

            filter: {
              storeAsset: {
                id: { equals: storeAsset.id },
              },
              itemToSellWhenOutOfStock: {
                is_not_empty: true,
              },
            },
          },
        });

        setVariantPossiblePrevState(variantsPossible);
        refetchAsset();
        setIsDisable(true);
        notification.success(t('store_asset_update_success'));
      } catch (err) {
        notification.error(t('store_asset_update_error'));
        console.error(err);
      }
    },
    [storeAssetUpdateMutation, storeAsset, notification],
  );

  // Force to reset variants possible
  React.useEffect(() => {
    if (variantsPossible.length === 0) {
      setVariantsPossible([...variantPossiblePrevState]);
    }
  }, [variantsPossible]);

  // Force to reset variants possible
  const onResetForm = () => {
    setVariantsPossible([]);
  };

  const validate = React.useCallback(async (formValues: StoreAssetCreateOrUpdateFormValues) => {
    return validateWithSchema(StoreAssetCreateOrUpdateFormSchema, formValues);
  }, []);

  return (
    <Form
      initialValues={initialValues.current}
      mutators={{ ...resetFieldMutators() }}
      onSubmit={onSubmit}
      validate={validate}
      render={({ form, handleSubmit, submitting, pristine, validating, invalid }) => {
        const isButtonsLoading = submitting || validating;
        const isPristine = pristine && isDisable;
        const isButtonsDisabled = invalid || isPristine;

        return (
          <StyledFormNode onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <FormCommonInfoSection />

              <StoreAssetFormAttributesSection
                setVariantsPossible={setVariantsPossible}
                variantsPossible={variantsPossible}
              />

              <StoreAssetFormAvailabilitySection />

              <StoreAssetFormStockControlSection />

              <StoreAssetFormAdditionalInformationSection />

              <Grid item xs={12} mt={2}>
                <StyledActionButton
                  variant="outlined"
                  onClick={() => {
                    form.reset();
                    onResetForm();
                  }}
                  disabled={isButtonsDisabled}
                >
                  Reset
                </StyledActionButton>

                <StyledActionButton
                  onClick={handleSubmit}
                  loading={isButtonsLoading}
                  disabled={isButtonsDisabled}
                >
                  Save
                </StyledActionButton>
              </Grid>
            </Grid>
          </StyledFormNode>
        );
      }}
    />
  );
};

export { AssetInfoUpdateForm };
