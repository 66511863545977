import React from 'react';
import { Typography } from '@mui/material';

const renderRowText = (text: string) => <Typography>{text}</Typography>;

export const columns = [
  {
    title: 'first name',
    key: 'firstName',
    dataPath: 'firstName',
    render: renderRowText,
  },
  {
    title: 'last Name',
    key: 'lastName',
    dataPath: 'lastName',
    render: renderRowText,
  },
  {
    title: 'title',
    key: 'title',
    dataPath: 'title',
    render: renderRowText,
  },
  {
    title: 'email',
    key: 'email',
    dataPath: 'email',
    render: renderRowText,
  },
  {
    title: 'phone number',
    key: 'phone',
    dataPath: 'phone',
    render: renderRowText,
  },
  {
    title: 'notes',
    key: 'notes',
    dataPath: 'notes',
    render: renderRowText,
  },
];
