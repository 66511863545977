import React from 'react';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';

import { Shade } from 'src/theme';

const useStyles = makeStyles(() => ({
  root: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: Shade.Gray['25'],
    },
  },
}));

interface CloseModalButtonProps {
  onClick: () => void;
}

export const CloseModalButton: React.FC<CloseModalButtonProps> = ({ onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Close style={{ fontSize: 40 }} onClick={onClick} />
    </div>
  );
};
