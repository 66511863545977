import {
  RegistrationOptionsListQuery,
  RegistrationOptionsListQueryVariables,
  RegistrationOption,
  RegistrationOptionCreateInput,
  useRegistrationOptionCreateMutation,
  useRegistrationOptionDeleteMutation,
  useRegistrationOptionsListQuery,
  useRegistrationOptionUpdateMutation,
} from '@vizsla/graphql';
import { useApolloCacheQueryManager } from '@vizsla/hooks';
import { mutableList } from '@vizsla/utils';

import { REGISTRATION_OPTIONS_LIST_QUERY } from 'src/graphql';

type UseRegistrationOptionsResult = {
  loading: boolean;
  creating: boolean;
  updating: boolean;
  deleting: boolean;
  registrationOptions: Array<RegistrationOption>;
  registrationOptionsCount: number;
  createRegistrationOption: (
    registrationOptionData: RegistrationOptionCreateInput,
  ) => Promise<RegistrationOption>;
  updateRegistrationOption: (data: any, registrationOptionId: string) => Promise<void>;
  deleteRegistrationOption: (registrationOptionId: string) => Promise<void>;
};

export const useRegistrationOptions = (experienceId: string): UseRegistrationOptionsResult => {
  const registrationOptionsListQueryVariables = {
    variables: {
      filter: {
        experience: {
          id: {
            equals: experienceId,
          },
        },
      },
    },
  };

  const { data, loading } = useRegistrationOptionsListQuery({
    ...registrationOptionsListQueryVariables,
    skip: !experienceId,
  });

  const { updateQuery: updateRegistrationOptionsQuery } = useApolloCacheQueryManager<
    RegistrationOptionsListQuery,
    RegistrationOptionsListQueryVariables
  >({
    query: REGISTRATION_OPTIONS_LIST_QUERY,
    typeName: 'RegistrationOptionListResponse',
    ...registrationOptionsListQueryVariables,
  });

  const registrationOptions = (data?.registrationOptionsList?.items ||
    []) as Array<RegistrationOption>;

  const registrationOptionsCount = data?.registrationOptionsList.count || 0;

  const [createRegistrationOptionMutation, { loading: creating }] =
    useRegistrationOptionCreateMutation();

  const [deleteRegistrationOptionMutation, { loading: deleting }] =
    useRegistrationOptionDeleteMutation();

  const [updateRegistrationOptionMutation, { loading: updating }] =
    useRegistrationOptionUpdateMutation();

  const createRegistrationOption = async (
    registrationOptionData?: RegistrationOptionCreateInput,
  ) => {
    try {
      const { data } = await createRegistrationOptionMutation({
        variables: {
          data: {
            experience: { connect: { id: experienceId } },
            earlyBirdRegistrationOption: {
              create: {
                experience: {
                  connect: {
                    id: experienceId,
                  },
                },
              },
            },
            ...registrationOptionData,
          },
        },
        fetchPolicy: 'network-only',
      });
      const registrationOption = data?.registrationOptionCreate as RegistrationOption;

      updateRegistrationOptionsQuery(query => {
        mutableList(query.registrationOptionsList.items).add(registrationOption);
        query.registrationOptionsList.count += 1;
      });

      return registrationOption;
    } catch (error) {
      console.error({ error });
      throw error;
    }
  };

  const deleteRegistrationOption = async (registrationOptionId: string) => {
    try {
      await deleteRegistrationOptionMutation({ variables: { id: registrationOptionId } });

      updateRegistrationOptionsQuery(query => {
        mutableList(query.registrationOptionsList.items).removeById(registrationOptionId);
        query.registrationOptionsList.count -= 1;
      });
    } catch (error) {
      console.error({ error });
      throw error;
    }
  };

  const updateRegistrationOption = async (data: any, registrationOptionId: string) => {
    await updateRegistrationOptionMutation({
      variables: {
        data: {
          ...data,
          id: registrationOptionId,
        },
      },
    });
  };

  return {
    loading,
    deleting,
    updating,
    creating,
    registrationOptions,
    registrationOptionsCount,
    createRegistrationOption,
    updateRegistrationOption,
    deleteRegistrationOption,
  };
};
