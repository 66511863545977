import _ from 'lodash';
import type { ValidationErrors } from 'final-form';

import { StoreAssetFormFields } from 'src/types/storeAssets';

export const getStoreAvailabilityErrorText = (errors: ValidationErrors) => {
  let errorText: string | undefined;

  if (!_.isNil(errors)) {
    errorText =
      errors[StoreAssetFormFields.STORE_AVAILABILITY] ||
      errors[StoreAssetFormFields.SWAGBAG_AVAILABILITY];
  }

  return errorText;
};
