import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { LinearProgress } from '@mui/material';

import { experienceLandingReducer } from '@vizsla/components';
import { ExperienceLandingProvider, LandingStateProvider } from '@vizsla/providers';
import { TemplateType } from '@vizsla/types';

import { CampaignTemplateEditor } from 'src/components/campaignTemplate';
import { useCampaign, useCampaignId } from 'src/hooks/campaign';

import { TeamFundraiserLadingWrapper } from './TeamFundraiserLadingWrapper';

const useStyles = makeStyles({
  layout: {
    display: 'flex',
    alignItems: 'flex-start',
  },

  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    overflowWrap: 'normal',
  },
});

export const TeamFundraiserTemplate: React.FC = () => {
  const classes = useStyles();

  const campaignId = useCampaignId();
  const { data: campaignData } = useCampaign(campaignId);

  const experienceId = campaignData?.experiences?.items[0].id;

  if (_.isNil(experienceId)) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <div className={classes.layout}>
      <ExperienceLandingProvider
        campaignId={campaignId}
        experienceId={experienceId}
        templateType={TemplateType.TEAM_FUNDRAISER}
      >
        <LandingStateProvider landingReducer={experienceLandingReducer}>
          <TeamFundraiserLadingWrapper />
          <CampaignTemplateEditor />
        </LandingStateProvider>
      </ExperienceLandingProvider>
    </div>
  );
};
