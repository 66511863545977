import React, { Fragment } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import {
  Grid,
  Box,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Theme,
  Button,
  Link,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import TodayIcon from '@mui/icons-material/Today';

import { Campaign, CampaignListQuery, Experience } from '@vizsla/graphql';
import { useCurrentOrganization } from '@vizsla/hooks';

import { useFullPublicWebLink } from 'src/hooks/publicPages';

import { CAMPAIGN_LIST_WITH_EXPERIENCE } from '../../graphql/campaign';

export const CampaignSelect: React.FC = () => {
  const { organizationId } = useCurrentOrganization();
  const { data, loading } = useQuery<CampaignListQuery>(CAMPAIGN_LIST_WITH_EXPERIENCE, {
    variables: { filter: { vizslaOrganization: { id: { equals: organizationId } } } },
  });
  const [selectValue, setSelectValue] = React.useState<string>('');
  const [selectValueExperience, setSelectValueEnxperience] = React.useState<string>('');
  const [experiences, setExperiences] = React.useState<any[]>([]);
  const [experienceName, setExperienceName] = React.useState<string>('');
  const [experienceDates, setExperienceDates] = React.useState<string>('');
  const [showExperience, setShowExperiences] = React.useState<boolean>(false);
  const [image, setImage] = React.useState<string>('');
  const [logo, setLogo] = React.useState<string>('');
  const [slug, setSlug] = React.useState<string>('');
  const [campaignLink, setCampaignLink] = React.useState<string>('');
  const { fullPublicWebLink } = useFullPublicWebLink(slug);
  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      width: '100%',
      height: '100%',
    },
    containerBackgroud: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(${image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
    },
    container2: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderStyle: 'groove',
      borderRadius: '10px',
      width: '70%',
      height: '70%',
      background: '#fff',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    },
    containerRegistered: {
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'right',
      marginTop: '20px',
    },
    containerRegisterMe: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'left',
      marginTop: '20px',
    },
    containerLogo: {
      display: 'flex',
      width: '200px',
      height: '100px',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(${logo})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    },
    campaign: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    experience: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    experienceName: {
      fontWeight: 'bold',
      fontSize: '28px',
    },
    experienceDate: {
      fontWeight: 'bold',
      fontSize: '18px',
    },
    registerFont: {
      fontWeight: 'bold',
      fontSize: '18px',
      color: '#fff',
    },
    backFont: {
      fontSize: '14px',
      color: '#009FB0',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    registered: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      width: '285px',
      height: '156px',
      background: '#1B3C84',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#BBCCF7',
      },
    },
    registerMe: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      width: '285px',
      height: '156px',
      background: '#009FB0',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#BBEEF7',
      },
    },
  }));
  React.useEffect(() => {}, [image]);
  const classes = useStyles();
  const handleSelect = (e: any) => {
    setSelectValue(e.target.value);
    const campaign: Campaign | undefined = data?.campaignsList.items.filter(
      item => item.id === e.target.value,
    )[0];
    if (campaign) {
      setExperiences(campaign?.experiences?.items || []);
      setSlug(campaign?.dnsRecord?.slug || '');
    }
  };
  const handleExperience = (e: any) => {
    setShowExperiences(true);
    const experience: Experience = experiences.filter(item => item.id === e.target.value)[0];
    if (experience) {
      setExperienceName(experience?.name ?? '');
      setImage(experience?.experienceHeaderImage?.downloadUrl || '');
      setLogo(experience?.experienceLogoImage?.downloadUrl || '');
      setCampaignLink(`${fullPublicWebLink}/experience/${experience?.id}`);
      const starDate =
        moment(experience?.startDate).format('MMM DD') === 'Invalid date'
          ? ''
          : moment(experience?.startDate).format('MMM DD');
      const endDate =
        moment(experience?.endDate).format('MMM DD') === 'Invalid date'
          ? ''
          : moment(experience?.endDate).format('MMM DD');
      const year = moment(experience?.startDate).format('YYYY');
      setExperienceDates(`${starDate} - ${endDate}, ${year}` ?? '');
    }
  };
  const onClickRegisterMe = () => {
    window.open(campaignLink);
  };
  const onClickRegistered = () => {
    window.open(`${process.env.REACT_APP_CHECKIN_APP_URL}`);
  };
  const onClickBack = () => {
    setShowExperiences(false);
  };
  return (
    <React.Fragment>
      {loading ? (
        <Box className={classes.container}>
          <CircularProgress size={100} color="secondary" />
        </Box>
      ) : (
        <React.Fragment>
          {!showExperience ? (
            <Grid container xs={12} columnSpacing={2} className={classes.container}>
              <Grid item xs={6} className={classes.campaign}>
                <FormControl fullWidth>
                  <InputLabel id="select-campaign">Select Campaign</InputLabel>
                  <Select
                    labelId="select-campaign"
                    value={selectValue}
                    onChange={handleSelect}
                    className={classes.campaign}
                  >
                    {data?.campaignsList.items.map(item => (
                      <MenuItem key={item?.id} value={item?.id || 'none'}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} className={classes.experience}>
                <FormControl fullWidth>
                  <InputLabel id="select-experience">Select Experience</InputLabel>
                  <Select
                    labelId="select-experience"
                    value={selectValueExperience}
                    onChange={handleExperience}
                  >
                    {experiences.map(item => (
                      <MenuItem key={item?.id} value={item?.id || 'none'}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            <Box className={classes.containerBackgroud}>
              <Box className={classes.container2}>
                <Grid container xs={12} columnSpacing={3} rowSpacing={2}>
                  <Grid item xs={12} className={classes.container}>
                    <Box className={classes.containerLogo} />
                  </Grid>
                  <Grid item xs={12} textAlign="center">
                    <Typography className={classes.experienceName}>{experienceName}</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.container}>
                    <TodayIcon sx={{ color: 'black', fontSize: 30 }} />
                    <Typography className={classes.experienceDate}>{experienceDates}</Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.containerRegistered}>
                    <Button className={classes.registered} onClick={onClickRegistered}>
                      <HowToRegIcon sx={{ color: '#fff', fontSize: 50 }} />
                      <Typography className={classes.registerFont}>{`I'm Registeredd`}</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={6} className={classes.containerRegisterMe}>
                    <Button className={classes.registerMe} onClick={onClickRegisterMe}>
                      <PersonAddAltIcon sx={{ color: '#fff', fontSize: 50 }} />
                      <Typography className={classes.registerFont}>Register Me</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12} textAlign="center">
                    <Link className={classes.backFont} onClick={onClickBack}>
                      View All Campaigns
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
