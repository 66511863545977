import styled from '@emotion/styled';

export const PartnershipContentWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
`;

export const PartnershipLayout = styled.div`
  padding: 2rem;
`;
