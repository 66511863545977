import React from 'react';

import { TableData } from '@vizsla/components';
import { MODALS } from '@vizsla/constants';
import { useListPartnershipContactsQuery } from '@vizsla/graphql';
import { useModal, useNotification } from '@vizsla/hooks';

import { columns } from './columns';
import { useRowActions } from './actions';

export const PartnershipContactTable: React.FC<{ partnershipId: string }> = ({ partnershipId }) => {
  const { data, loading: queryLoading } = useListPartnershipContactsQuery({
    variables: { partnershipOrganizationId: partnershipId as string },
    fetchPolicy: 'network-only',
  });

  const [loading, setLoading] = React.useState(false);

  const { actions } = useRowActions(partnershipId, setLoading);

  return (
    <TableData
      tableProps={{ stickyHeader: true }}
      dataSource={data?.partnershipContactsList?.items || []}
      columns={columns}
      tableContainerProps={{ style: { width: '100%' } }}
      rowActions={actions}
      loading={queryLoading || loading}
    />
  );
};
