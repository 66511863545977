import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import { Form } from 'react-final-form';
import { useMutation } from '@apollo/client';
import { FormApi } from 'final-form';

import { Button, Field, SelectField } from '@vizsla/components';
import { useCurrentUser, useNotification, useOrganizationById } from '@vizsla/hooks';
import { VizslaOrganizationUpdateInput } from '@vizsla/graphql';
import { validateWithSchema } from '@vizsla/utils';
import {
  CURRENCY,
  LANGUAGE_OPTIONS,
  PREFERRED_DATE_TIME_FORMAT,
  TIME_ZONE,
} from '@vizsla/constants';

import { VIZSLA_ORGANIZATION_UPDATE_MUTATION } from 'src/graphql';
import { VizslaOrganizationLocationSchema } from 'src/constants/validationSchemas/organization';

import { Container } from './LocationInfo.style';

export function LocationInfo() {
  const { user } = useCurrentUser();

  const organizationId = user?.vizslaOrganizationStaff?.items[0].vizslaOrganization?.id ?? '';

  const { organization, organizationIsLoading, refetch } = useOrganizationById(organizationId);
  const notification = useNotification();

  const INITIAL_VALUES = {
    ...organization,
  };

  const [vizslaOrganizationUpdate] = useMutation<VizslaOrganizationUpdateInput>(
    VIZSLA_ORGANIZATION_UPDATE_MUTATION,
    {
      refetchQueries: ['OrganizationsList', 'VizslaOrganizationQuery'],
      awaitRefetchQueries: true,
    },
  );
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      await vizslaOrganizationUpdate({
        variables: {
          data: {
            id: organizationId,
            currency: data?.currency,
            timeZone: data?.timeZone,
            language: data?.language,
            preferredDateFormat: data?.preferredDateFormat,
          },
        },
      });
      notification.success('Organization update success');
      refetch();
      setLoading(false);
    } catch (e) {
      notification.error('Error updating organization');
      console.error(e);
      setLoading(false);
    }
  };

  const onCancel = (form: FormApi) => {
    form.reset();
  };

  if (organizationIsLoading) {
    return (
      <Container>
        <Grid item xs={3}>
          <Skeleton variant="text" height={40} />
          <Skeleton variant="text" height={40} />
        </Grid>

        <Grid item xs={3}>
          <Skeleton variant="text" height={40} />
          <Skeleton variant="text" height={40} />
        </Grid>
      </Container>
    );
  }
  return (
    <Grid container spacing={6}>
      <Grid item xs={9}>
        <Form
          onSubmit={onSubmit}
          initialValues={INITIAL_VALUES}
          validate={values => validateWithSchema(VizslaOrganizationLocationSchema, values)}
        >
          {({ form, handleSubmit, submitting, pristine, hasValidationErrors }) => {
            const isSubmitDisabled =
              organizationIsLoading || submitting || pristine || hasValidationErrors;

            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Grid container spacing={5}>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="timeZone"
                          label="Organization TimeZone"
                          requierd
                          component={SelectField}
                          options={TIME_ZONE}
                          disabled={loading}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="currency"
                          label="Currency"
                          requierd
                          component={SelectField}
                          options={CURRENCY}
                          disabled={loading}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="language"
                          requierd
                          label="Language"
                          component={SelectField}
                          options={LANGUAGE_OPTIONS}
                          disabled={loading}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="preferredDateFormat"
                          label="Preferred Date/time Format"
                          component={SelectField}
                          options={PREFERRED_DATE_TIME_FORMAT}
                          disabled={loading}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Button onClick={handleSubmit} loading={loading} disabled={isSubmitDisabled}>
                      Save
                    </Button>
                    <Button
                      onClick={() => onCancel(form)}
                      disabled={isSubmitDisabled}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </Grid>
    </Grid>
  );
}
