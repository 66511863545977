import React from 'react';
import { Box, CircularProgress } from '@mui/material';

import { CampaignQuestionUserGroup } from '@vizsla/types';
import {
  filterCustomQuestionsByUserGroup,
  filterDefaultQuestionsByUserGroup,
  sortQuestionsByOrder,
} from '@vizsla/utils';
import { CustomCampaignQuestion, DefaultCampaignQuestion } from '@vizsla/graphql';

import { useCampaignId } from 'src/hooks/campaign';
import { useCampaignQuestions } from 'src/hooks/campaignQuestion';
import { PaperBlock } from 'src/components/shared';

import { UserGroupsTabs } from './UserGroupsTabs';
import { ManageCampaignQuestions } from './ManageCampaignQuestions';
import { CampaignDefaultQuestionsTable } from './CampaignDefaultQuestionsTable';
import { CampaignCustomQuestionsTable } from './CampaignCustomQuestionsTable';

export const CampaignQuestions: React.FC = () => {
  const campaignId = useCampaignId();
  const {
    data: { defaultQuestions, customQuestions },
    loading,
    settingDefaultQuestions,
    campaignHasNoDefaultQuestions,
    handleSetDefaultQuestions,
  } = useCampaignQuestions(campaignId);

  React.useEffect(() => {
    if (!loading && campaignHasNoDefaultQuestions) {
      handleSetDefaultQuestions(campaignId);
    }
  }, [loading, campaignHasNoDefaultQuestions, handleSetDefaultQuestions, campaignId]);

  const [userGroup, setUserGroup] = React.useState<CampaignQuestionUserGroup>(
    CampaignQuestionUserGroup.attendee,
  );

  const [isManagingQuestions, setIsManagingQuestions] = React.useState<boolean>(false);

  const handleSetIsManagingQuestions = (isManagingQuestions: boolean) => {
    setIsManagingQuestions(isManagingQuestions);
  };

  const onChangeUserGroupTab = (_: React.SyntheticEvent, userGroup: CampaignQuestionUserGroup) => {
    setUserGroup(userGroup);
  };

  const displayedDefaultQuestions = React.useMemo(() => {
    const filtered = filterDefaultQuestionsByUserGroup(defaultQuestions, userGroup);

    return sortQuestionsByOrder<DefaultCampaignQuestion>(filtered);
  }, [defaultQuestions, userGroup]);

  const displayedCustomQuestions = React.useMemo(() => {
    const filtered = filterCustomQuestionsByUserGroup(customQuestions, userGroup);

    return sortQuestionsByOrder<CustomCampaignQuestion>(filtered);
  }, [customQuestions, userGroup]);

  if (isManagingQuestions) {
    return <ManageCampaignQuestions handleSetIsManagingQuestions={handleSetIsManagingQuestions} />;
  }

  return (
    <PaperBlock title="Questions" collapsing defaultOpened>
      <Box display="flex" justifyContent="center" marginBottom={3}>
        <UserGroupsTabs userGroup={userGroup} onChangeUserGroupTab={onChangeUserGroupTab} />
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height={200}>
          <CircularProgress size={32} />
        </Box>
      ) : (
        <React.Fragment>
          <Box marginBottom={4}>
            <CampaignDefaultQuestionsTable
              campaignQuestions={displayedDefaultQuestions}
              settingQuestions={settingDefaultQuestions}
            />
          </Box>
          <Box marginBottom={4}>
            <CampaignCustomQuestionsTable
              campaignQuestions={displayedCustomQuestions}
              handleSetIsManagingQuestions={handleSetIsManagingQuestions}
            />
          </Box>
        </React.Fragment>
      )}
    </PaperBlock>
  );
};
