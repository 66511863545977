import * as yup from 'yup';

import { concatDateWithTime, isDateGreaterThan, isDateGreaterOrEquals } from '@vizsla/utils';
import { Experience } from '@vizsla/graphql';

const name = yup.string().nullable().required('Registration Name is required');

const description = yup.string().nullable();

const distance = yup.number().nullable().required('Distance is required');

const unitOfMeasure = yup.string().nullable().required();

const startWaveOrder = yup.number().nullable().notRequired();

const AgeRequirement = yup
  .number()
  .nullable()
  .when('ageRequirmentEnabled', {
    is: true,
    then: yup
      .number()
      .nullable()
      .required('Age Requirement is required')
      .min(0, 'Should be greater or equals 0'),
    otherwise: yup.number().nullable().notRequired(),
  });

const AgeRequirementAsOfDate = yup
  .string()
  .nullable()
  .when('ageRequirmentEnabled', {
    is: true,
    then: yup.string().nullable().required('As of Date is required'),
    otherwise: yup.string().nullable().notRequired(),
  });

const AttendeesPerRegistration = yup
  .number()
  .nullable()
  .when('registrationLimitsEnabled', {
    is: true,
    then: yup
      .number()
      .nullable()
      .required('Attendees per Registration is required')
      .min(0, 'Should be greater or equals 0'),
    otherwise: yup.number().nullable().notRequired(),
  });

const MaximumRegistrationPerOrder = yup
  .number()
  .nullable()
  .when('registrationLimitsEnabled', {
    is: true,
    then: yup
      .number()
      .nullable()
      .required('Maximum Registration per Order is required')
      .min(0, 'Should be greater or equals 0'),
    otherwise: yup.number().nullable().notRequired(),
  });

const GroupRegistrationMinimum = yup
  .number()
  .nullable()
  .when('groupRegistrationEnabled', {
    is: true,
    then: yup
      .number()
      .nullable()
      .required('Minimum is required')
      .min(0, 'Should be greater or equals 0'),
    otherwise: yup.number().nullable().notRequired(),
  });

const GroupRegistrationMaximum = yup
  .number()
  .nullable()
  .when('groupRegistrationEnabled', {
    is: true,
    then: yup
      .number()
      .nullable()
      .required('Maximum is required')
      .min(0, 'Should be greater or equals 0'),
    otherwise: yup.number().nullable().notRequired(),
  });

const RegistrationStartDate = yup
  .string()
  .nullable()
  .when('registrationAvailabilityEnabled', {
    is: true,
    then: yup.string().nullable().required('Start Date is required'),
    otherwise: yup.string().nullable().notRequired(),
  });

const RegistrationStartTime = yup
  .string()
  .nullable()
  .when('registrationAvailabilityEnabled', {
    is: true,
    then: yup.string().nullable().required('Start Time is required'),
    otherwise: yup.string().nullable().notRequired(),
  });

const RegistrationEndDate = yup
  .string()
  .nullable()
  .when('registrationAvailabilityEnabled', {
    is: true,
    then: yup.string().nullable().required('End Date is required'),
    otherwise: yup.string().nullable().notRequired(),
  });

const RegistrationEndTime = yup
  .string()
  .nullable()
  .when('registrationAvailabilityEnabled', {
    is: true,
    then: yup
      .string()
      .nullable()
      .required('End Time is required')
      .test(
        'end-time-should-be-greater-than-the-start-time',
        'End time should be greater than the start time',
        (registrationEndTime: string | null | undefined, { parent }) => {
          const { registrationStartDate, registrationStartTime, registrationEndDate } = parent;

          const registrationFullStartDate = concatDateWithTime(
            registrationStartDate,
            registrationStartTime,
          );

          const registrationFullEndDate = concatDateWithTime(
            registrationEndDate,
            registrationEndTime,
          );

          return isDateGreaterThan(registrationFullEndDate, registrationFullStartDate, 'minute');
        },
      ),
    otherwise: yup.string().nullable().notRequired(),
  });

const MaximumAttendees = yup
  .number()
  .nullable()
  .when('attendeesCapEnabled', {
    is: true,
    then: yup
      .number()
      .nullable()
      .required('Maximum Attendees is required')
      .min(0, 'Should be greater or equals 0'),
    otherwise: yup.number().nullable().notRequired(),
  });

const FundraisingMinimum = yup
  .number()
  .nullable()
  .when('fundraisingMinimumEnabled', {
    is: true,
    then: yup
      .number()
      .nullable()
      .required('Minimum Fundraising is required')
      .min(0, 'Should be greater or equals 0'),
    otherwise: yup.number().nullable().notRequired(),
  });

const CheckpointAmount = yup
  .number()
  .nullable()
  .when('checkpointEnabled', {
    is: true,
    then: yup
      .number()
      .nullable()
      .required('Checkpoint Amount is required')
      .min(0, 'Should be greater or equals 0'),
    otherwise: yup.number().nullable().notRequired(),
  });

const CheckpointDate = yup
  .string()
  .nullable()
  .when('checkpointEnabled', {
    is: true,
    then: yup.string().nullable().required('Checkpoint Date is required'),
    otherwise: yup.string().nullable().notRequired(),
  });

export const RegistrationOptionCreateFormStepOneSchema = yup.object({
  name,
  description,
  distance,
  unitOfMeasure,
  startWaveOrder,
});

export const RegistrationOptionCreateFormStepTwoSchema = yup.object({
  ageRequirmentEnabled: yup.bool().required(),
  ageRequirement: AgeRequirement,
  registrationLimitsEnabled: yup.bool().required(),
  attendeesPerRegistration: AttendeesPerRegistration,
  maximumRegistrationPerOrder: MaximumRegistrationPerOrder,
  groupRegistrationEnabled: yup.bool().required(),
  groupRegistrationMinimum: GroupRegistrationMinimum,
  groupRegistrationMaximum: GroupRegistrationMaximum,
});

export const RegistrationOptionCreateFormStepThreeSchema = yup.object({
  attendeesCapEnabled: yup.bool().required(),
  maximumAttendees: MaximumAttendees,
  // pricingSettings: PricingSettingsSchema,
});

export const RegistrationOptionCreateFormStepFourSchema = yup.object({
  fundraisingMinimumEnabled: yup.bool().required(),
  fundraisingMinimum: FundraisingMinimum,
  checkpointEnabled: yup.bool().required(),
  checkpointAmount: CheckpointAmount,
  checkpointDate: CheckpointDate,
  registrationAvailabilityEnabled: yup.bool().required(),
  registrationStartDate: RegistrationStartDate,
  registrationStartTime: RegistrationStartTime,
  registrationEndDate: RegistrationEndDate,
  registrationEndTime: RegistrationEndTime,
});

export const RegistrationOptionEditBasicsFormSchema = yup.object({
  name,
  description,
  distance,
  unitOfMeasure,
  startWaveOrder,
  ageRequirmentEnabled: yup.bool().required(),
  ageRequirement: AgeRequirement,
  ageRequirementAsOfDate: AgeRequirementAsOfDate,
  registrationLimitsEnabled: yup.bool().required(),
  attendeesPerRegistration: AttendeesPerRegistration,
  maximumRegistrationPerOrder: MaximumRegistrationPerOrder,
  groupRegistrationEnabled: yup.bool().required(),
  groupRegistrationMinimum: GroupRegistrationMinimum,
  groupRegistrationMaximum: GroupRegistrationMaximum,
  attendeesCapEnabled: yup.bool().required(),
  maximumAttendees: MaximumAttendees,
  registrationAvailabilityEnabled: yup.bool().required(),
  registrationStartDate: RegistrationStartDate,
  registrationStartTime: RegistrationStartTime,
  registrationEndDate: RegistrationEndDate,
  registrationEndTime: RegistrationEndTime,
  // pricingSettings: PricingSettingsSchema,
});

export const RegistrationOptionEditFundraisingFormSchema = yup.object({
  fundraisingMinimumEnabled: yup.bool().required(),
  fundraisingMinimum: FundraisingMinimum,
  checkpointEnabled: yup.bool().required(),
  checkpointAmount: CheckpointAmount,
  checkpointDate: CheckpointDate,
});
