import type { RouteProps } from '@vizsla/types';

import * as appRoutes from 'src/constants/routes';
import {
  StoreAssetManagementDetailsTab,
  StoreAssetManagementInventoryTab,
} from 'src/components/storeAssets';

import { StoreAssetManagementIndex } from './StoreAssetManagementIndex';

export const storeAssetManagementRoutes: RouteProps[] = [
  {
    index: true,
    element: StoreAssetManagementIndex,
  },
  {
    path: appRoutes.storeAssetsAssetDetails,
    element: StoreAssetManagementDetailsTab,
  },
  {
    path: appRoutes.storeAssetsAssetInventory,
    element: StoreAssetManagementInventoryTab,
  },
];
