import { gql } from '@apollo/client';

import { CAMPAIGNS_PAGE_CAMPAIGN_FRAGMENT } from './campaign';

export const PROGRAM_FRAGMENT = gql`
  fragment ProgramFragment on Program {
    id
    name
  }
`;

export const PROGRAMS_QUERY = gql`
  query ProgramsList($filter: ProgramFilter) {
    programsList(filter: $filter) {
      items {
        ...ProgramFragment
      }
    }
  }
  ${PROGRAM_FRAGMENT}
`;

export const PROGRAMS_LIST_QUERY = gql`
  query ProgramsListQuery($filter: ProgramFilter, $CampaignFilter: CampaignFilter) {
    programsList(filter: $filter) {
      items {
        ...ProgramFragment
        campaigns(filter: $CampaignFilter) {
          items {
            ...CampaignsPageCampaignFragment
          }
        }
      }
    }
  }
  ${PROGRAM_FRAGMENT}
  ${CAMPAIGNS_PAGE_CAMPAIGN_FRAGMENT}
`;

export const PROGRAM_CREATE_MUTATION = gql`
  mutation ProgramCreate($data: ProgramCreateInput!) {
    programCreate(data: $data) {
      ...ProgramFragment
    }
  }
  ${PROGRAM_FRAGMENT}
`;
