import _ from 'lodash';

import { concatDateWithTime, isDateGreaterOrEquals, getNowDateTime } from '@vizsla/utils';
import { Maybe, RegistrationPricingSetting } from '@vizsla/graphql';

import { PricingTierFormAction, PricingType } from 'src/types/pricingSettings';

export const getNewPricingTiersData = (data: any, pricingSettingsId?: Maybe<string>) => {
  const { pricingSettings } = data;
  const { pricingTiers, id, pricingEnabled } = pricingSettings;

  const idToConnect = id ?? pricingSettingsId;

  if (!pricingEnabled || _.isNil(idToConnect)) {
    return [];
  }

  return pricingTiers
    .filter((item: any) => item.formAction === PricingTierFormAction.toCreate)
    .map(({ formAction, startDate, startTime, endDate, endTime, ...restPricingTier }: any) => {
      return {
        ...restPricingTier,
        startDate: concatDateWithTime(startDate, startTime),
        endDate: concatDateWithTime(endDate, endTime),
        pricingSettings: { connect: { id: idToConnect } },
      };
    });
};

export const getPricingSettingsUpdateData = (pricingSettings: any) => {
  const {
    pricingTiers,
    id: pricingSettingsId,
    pricingEnabled,
    ...restPricingSettings
  } = pricingSettings;

  if (!pricingEnabled) {
    return {
      update: {
        pricingEnabled,
      },
    };
  }

  return {
    update: {
      pricingEnabled,
      ...restPricingSettings,
      pricingTiers: {
        update: pricingTiers
          .filter((item: any) => item.formAction === PricingTierFormAction.toUpdate)
          .map(
            ({
              id,
              formAction,
              startDate,
              startTime,
              endDate,
              endTime,
              ...restPricingTier
            }: any) => {
              return {
                data: {
                  ...restPricingTier,
                  startDate: concatDateWithTime(startDate, startTime),
                  endDate: concatDateWithTime(endDate, endTime),
                },
                filter: {
                  id,
                },
              };
            },
          ),
      },
    },
  };
};

export const getPricingTiersToDeleteIds = (data: any) => {
  const { pricingSettings } = data;
  const { pricingTiers, pricingEnabled } = pricingSettings;

  if (!pricingEnabled) {
    return [];
  }

  return pricingTiers
    .filter((item: any) => item.formAction === PricingTierFormAction.toDelete)
    .map(({ id }: any) => id);
};

export const getNewPricingSettingsData = (pricingSettings: any) => {
  const { pricingTiers, pricingEnabled, ...restPricingSettings } = pricingSettings;

  if (!pricingEnabled) {
    return {
      create: {
        pricingEnabled,
      },
    };
  }

  return {
    create: {
      pricingEnabled,
      ...restPricingSettings,
    },
  };
};

export const resolveCurrentPrice = (settings: RegistrationPricingSetting | null | undefined) => {
  if (!settings?.pricingEnabled) {
    return 0;
  }

  const tiers = _.sortBy(_.get(settings, ['pricingTiers', 'items'], []), ['endDate']);

  switch (settings?.pricingType) {
    case PricingType.Fixed: {
      return tiers[0]?.price ?? 0;
    }

    case PricingType.Scaled: {
      return tiers.find(tier => isDateGreaterOrEquals(tier?.endDate, getNowDateTime()))?.price ?? 0;
    }

    default: {
      return 0;
    }
  }
};
