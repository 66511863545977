import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useSelectedTransactionRow } from 'src/hooks/transactions';

import { BillingInformation } from '../BillingInformation';
import { CampaignDetails } from '../CampaignDetails';
import { PaymentDetails } from '../PaymentDetails';
import {
  TransactionDonationCard,
  TransactionsDonorCard,
} from '../TransactionsCards/TransactionDonationCard';
import { TransactionsCampaignCard } from '../TransactionsCards/TransactionsCampaignCard';
import { TransactionsDetails } from '../TransactionsDetails';
import {
  CardsContent,
  LinkTransactions,
  Title,
  WrapperPaperBlocks,
  WrapperTitle,
} from './TransactionsDonations.style';

export function TransactionsDonationsPage() {
  const navigate = useNavigate();
  const { data: dataTransaction } = useSelectedTransactionRow();
  return (
    <React.Fragment>
      <WrapperTitle>
        <Title>Transaction ID: {dataTransaction?.id?.toUpperCase()}</Title>
        <LinkTransactions onClick={() => navigate(-1)} underline="hover" variant="subtitle2">
          View All Transactions
        </LinkTransactions>
      </WrapperTitle>
      <CardsContent>
        <TransactionDonationCard />
        <TransactionsDonorCard />
        <TransactionsCampaignCard />
      </CardsContent>
      <WrapperPaperBlocks>
        <TransactionsDetails />
        {dataTransaction?.Ordersubtype === 'Donation' ? <CampaignDetails /> : null}
        <PaymentDetails />
        <BillingInformation />
      </WrapperPaperBlocks>
    </React.Fragment>
  );
}
