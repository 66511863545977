import type { RouteProps } from '@vizsla/types';
import { Application, SystemRoles, OrganizationRoles } from '@vizsla/types';
import { useCurrentUser, useUserCurrentApp } from '@vizsla/hooks';
import { getUserRoles } from '@vizsla/utils';

import * as appRoutes from 'src/constants/routes';
import { STATIC_ROUTES } from 'src/routes';

interface AllowedRoutes {
  allowedRoutes: RouteProps[];
  startRoutePath: string;
}

interface AllowedRoutesHookResult extends AllowedRoutes {
  loading: boolean;
}

const emptyRoutes: AllowedRoutes = {
  allowedRoutes: [],
  startRoutePath: '/',
};

export const useAllowedRoutes = (): AllowedRoutesHookResult => {
  const { user, loading: userLoading } = useCurrentUser();
  const { currentApp, loading: appLoading } = useUserCurrentApp();

  const loading = userLoading || appLoading;

  if (loading) {
    return {
      loading,
      ...emptyRoutes,
    };
  }

  const userRoles = getUserRoles(user!);

  if (
    (currentApp === Application.VizslaAdminClient || currentApp === Application.Unknown) &&
    userRoles.system.includes(SystemRoles.VizslaAdministrator)
  ) {
    return {
      loading,
      allowedRoutes: STATIC_ROUTES.AdminRoutes,
      startRoutePath: appRoutes.adminOrganizations,
    };
  }

  if (
    (currentApp === Application.OrganizationAdminClient || currentApp === Application.Unknown) &&
    userRoles.system.includes(SystemRoles.OrganizationAdministrator)
  ) {
    return {
      loading,
      allowedRoutes: STATIC_ROUTES.OrganizationRoutes,
      startRoutePath: appRoutes.home,
    };
  }

  return {
    loading,
    ...emptyRoutes,
  };
};
