import React from 'react';
import { Grid } from '@mui/material';

import { FiltersLayout } from '@vizsla/components';
import { useModal } from '@vizsla/hooks';

import { MODALS } from 'src/constants/modals';
import { OrganizationCreateModal } from 'src/modals';

import { AdminOrganizationsInfoCards } from './components/AdminOrganizationsInfoCards';
import { AdminOrganizationsTable } from './components/AdminOrganizationsTable';

export const AdminOrganizationsPage: React.FC = () => {
  const { openModal } = useModal();

  const onOpenOrganizationCreateDialog = () => openModal(MODALS.ORGANIZATION_CREATE_MODAL);

  return (
    <FiltersLayout
      withSearch
      withPagination
      rightButtonProps={{
        content: 'New Organization',
        onClick: onOpenOrganizationCreateDialog,
      }}
    >
      <React.Fragment>
        <Grid item mb={2}>
          <AdminOrganizationsInfoCards />
        </Grid>
        <AdminOrganizationsTable />
        <OrganizationCreateModal />
      </React.Fragment>
    </FiltersLayout>
  );
};
