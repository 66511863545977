import React from 'react';
import Container from '@mui/material/Container';

import { Inbox } from '@vizsla/components';
import { Experience, UserFilter } from '@vizsla/graphql';
import { useCurrentOrganization } from '@vizsla/hooks';
import { InboxFilterOption, InboxMessageFilterOption, InboxTypeFilterOption } from '@vizsla/types';

import { InboxPageHeader } from './components';
import { useInboxRecipientsFromLocationState } from '../../../hooks/inbox';

const buildUserInMyNetworkFilter = (experienceId?: string | null): UserFilter => {
  if (!experienceId) return {};
  return {
    OR: [
      {
        donors: {
          some: {
            campaign: { experiences: { some: { id: { equals: experienceId } } } },
          },
        },
      },
      {
        campaignStaff: {
          some: {
            campaign: {
              experiences: { some: { id: { equals: experienceId } } },
            },
          },
        },
      },
      {
        attendees: {
          some: {
            experience: {
              id: { equals: experienceId },
            },
          },
        },
      },
    ],
  };
};
const ALLOWED_FILTER_OPTIONS: InboxFilterOption[] = [
  InboxTypeFilterOption.DirectMessage,
  InboxMessageFilterOption.All,
  InboxMessageFilterOption.Read,
  InboxMessageFilterOption.Unread,
  InboxMessageFilterOption.Sent,
];
export const InboxPage: React.FC = () => {
  const { organizationId, organizationIsLoading } = useCurrentOrganization();
  const [selectedExperience, setSelectedExperience] = React.useState<Experience | undefined>();
  const newInboxRecipients = useInboxRecipientsFromLocationState();
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <InboxPageHeader
          organizationId={organizationId}
          loading={organizationIsLoading}
          experience={selectedExperience}
          onChange={setSelectedExperience}
        />
        <Inbox
          userFilter={buildUserInMyNetworkFilter(selectedExperience?.id)}
          allowedFilterOptions={ALLOWED_FILTER_OPTIONS}
          selectedInbox={newInboxRecipients}
        />
      </Container>
    </React.Fragment>
  );
};
