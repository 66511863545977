import React from 'react';
import { useMutation } from '@apollo/client';
import { Grid, LinearProgress, Typography, styled, TextField } from '@mui/material';
import _ from 'lodash';

import { Maybe, User, useUserContactProfileInfoQuery } from '@vizsla/graphql';
import { formatDate } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';
import { Shade } from '@vizsla/theme';
import { Button } from '@vizsla/components';
import { CrmContact } from '@vizsla/graphql';
import { CrmContactUpdateInput } from '@vizsla/graphql';

import { ContactUserIdContext } from 'src/providers/peopleCRM';
import { USER_CONTACT_PROFILE_UPDATE_MUTATION } from 'src/graphql/peopleCRM';

type PropSelectorFn = (user: User) => Maybe<string> | undefined;

const MAP_PERSONAL_INFO_BLOCKS: { label: string; propSelectorFn: PropSelectorFn }[] = [
  {
    label: 'First Name',
    propSelectorFn: user => user.firstName,
  },
  {
    label: 'Last Name',
    propSelectorFn: user => user.lastName,
  },
  {
    label: 'Gender',
    propSelectorFn: () => 'N/A',
  },
  {
    label: 'Email',
    propSelectorFn: user => user.email,
  },
  {
    label: 'Phone Number',
    propSelectorFn: user => {
      if (!user.phone || !user.phone.code || !user.phone.number) {
        return 'N/A';
      }

      return `${user.phone.code} ${user.phone.number}`;
    },
  },
  {
    label: 'Birthday',
    propSelectorFn: user => formatDate(user?.birthDate, DateFormatPatterns.shortDateWithSlash),
  },
];

const StyledPersonalInfoBlock = styled(Grid)(() => ({
  '& > div': {
    flex: '0 1 200px',
  },
}));

const StyledLightTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const StyledEditButton = styled(Button)(() => ({
  margin: 0,
  backgroundColor: Shade.Teal[50],
  color: Shade.Teal[700],
  '&:hover': {
    backgroundColor: Shade.Teal[25],
  },
}));

const getFullAddressString = (user?: Maybe<User>): string => {
  if (!user || !user.address) {
    return 'N/A';
  }

  const { address } = user;
  return `${[address.street2, address.street1, address.city, address.state, address.country]
    .filter(entry => !_.isNil(entry))
    .join(', ')} ${address.zip}`;
};

const ContactProfile: React.FC = () => {
  const { userId, tabType } = React.useContext(ContactUserIdContext);
  const { data, loading } = useUserContactProfileInfoQuery({ variables: { userId } });
  const user = data?.user;

  const [note, setNote] = React.useState('');

  const [cRMContactUpdate] = useMutation<CrmContactUpdateInput>(
    USER_CONTACT_PROFILE_UPDATE_MUTATION,
    // {
    //   refetchQueries: ['OrganizationsList'],
    // },
  );

  const renderPersonalInfoBlock = React.useCallback(
    (key: string, label: string, propSelectorFn: PropSelectorFn) => {
      if (!user) {
        return null;
      }

      const infoText = propSelectorFn(user) || 'N/A';

      return (
        <Grid key={key} item>
          <StyledLightTypography variant="caption">{label}</StyledLightTypography>
          <Typography variant="body1">{infoText}</Typography>
        </Grid>
      );
    },
    [user],
  );

  const onSubmit = async () => {
    try {
      await cRMContactUpdate({
        variables: {
          data: {
            id: user?.id,
            address: user?.address,
            firstName: user?.firstName,
            lastName: user?.lastName,
            phone1: user?.phone,
            note,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  if (loading) {
    return (
      <Grid item>
        <LinearProgress color="secondary" />
      </Grid>
    );
  }

  // TODO: Replace hardcoded data with real values whenever is possible
  return (
    <React.Fragment>
      <Grid item>
        <Typography variant="h6">Personal Info</Typography>
        <StyledPersonalInfoBlock container item direction="row" rowSpacing={4} columnSpacing={10}>
          {MAP_PERSONAL_INFO_BLOCKS.map((block, idx) =>
            renderPersonalInfoBlock(`personal-info-block${idx}`, block.label, block.propSelectorFn),
          )}
        </StyledPersonalInfoBlock>
      </Grid>
      {/* <Grid item xs={6}>
        <TextField id="outlined-basic" label="Notes" variant="outlined" />
      </Grid> */}
      <Grid item>
        <Typography variant="h6">Address</Typography>
        <Typography variant="body1">{getFullAddressString(user)}</Typography>
      </Grid>
      {/* <Grid item>
        <Typography variant="h6">Notes</Typography>
        <StyledLightTypography variant="body1">No notes entered</StyledLightTypography>
      </Grid> */}
      {tabType === 'others' && (
        <React.Fragment>
          <Grid item>
            <TextField
              id="outlined-basic"
              label="Notes"
              variant="outlined"
              onChange={e => setNote(e.target.value)}
            />
          </Grid>
          <Grid item>
            <StyledEditButton onClick={onSubmit}>Edit</StyledEditButton>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export { ContactProfile };
