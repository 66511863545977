import React from 'react';
import { Grid } from '@mui/material';

import { useCampaign, useCampaignId } from 'src/hooks/campaign';
import { CampaignType } from 'src/types/campaign';

import {
  CampaignDashboardDateRange,
  RegistrationAndTicketingCampaignDashboard,
  PersonalMissionCampaignDashboard,
  ImpactGivingCampaignDashboard,
} from './components';

const CampaignDashboard: React.FC = () => {
  const campaignId = useCampaignId();
  const { data: campaign } = useCampaign(campaignId);

  const startDate = campaign?.startDate;
  const endDate = campaign?.endDate;

  const renderDashboardByCampaignType = () => {
    switch (campaign?.campaignType) {
      case CampaignType.registrationAndTicketing: {
        return <RegistrationAndTicketingCampaignDashboard campaign={campaign} />;
      }
      case CampaignType.impactGiving: {
        return <ImpactGivingCampaignDashboard campaign={campaign} />;
      }
      case CampaignType.personalMission: {
        return <PersonalMissionCampaignDashboard campaign={campaign} />;
      }
    }
    return null;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CampaignDashboardDateRange startDate={startDate} endDate={endDate} />
      </Grid>
      {renderDashboardByCampaignType()}
    </Grid>
  );
};

export { CampaignDashboard };
