import React from 'react';
import { Box, Grid } from '@mui/material';
import { Field, Form } from 'react-final-form';

import { useModal } from '@vizsla/hooks';
import { Button, RadioGroupField, Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import {
  EarlyBirdRegistrationInvitationFormValues,
  EarlyBirdRegistrationInvitationType,
} from 'src/types/earlyBirdRegistration';
import { ExperienceAttendeeOrTeamSelect } from 'src/components/experience';

const INVITATION_TYPE_OPTIONS = [
  {
    label: 'Attendee',
    value: EarlyBirdRegistrationInvitationType.attendee,
  },
  {
    label: 'Team',
    value: EarlyBirdRegistrationInvitationType.team,
  },
];

const EarlyBirdRegistrationInvitationCreateModal: React.FC = () => {
  const {
    isOpen,
    closeModal,
    args = { onSubmit: () => Promise.resolve(null) },
  } = useModal(MODALS.EARLY_BIRD_REGISTRATION_CREATE_MODAL);

  const initialValues: EarlyBirdRegistrationInvitationFormValues = {
    attendeeOrTeam: null,
    invitationType: EarlyBirdRegistrationInvitationType.attendee,
  };

  const handleCloseModal = (reset: () => void) => () => {
    closeModal();
    reset();
  };

  const onSubmit = async (formValues: EarlyBirdRegistrationInvitationFormValues) => {
    const { attendeeOrTeam, invitationType } = formValues;
    await args.onSubmit({ id: attendeeOrTeam?.id, invitationType });
    closeModal();
  };

  return (
    <Form<EarlyBirdRegistrationInvitationFormValues>
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{ submitting: true, values: true, pristine: true }}
      render={({ handleSubmit, form, submitting, pristine, values }) => {
        const { invitationType } = values;
        return (
          <form onSubmit={handleSubmit}>
            <Modal fullWidth isOpen={isOpen} onClose={closeModal}>
              <Modal.Title>Invite Attendee or Team</Modal.Title>
              <Modal.Content>
                <Box mt={2}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Field
                        name="invitationType"
                        label="Invitation Type"
                        component={RadioGroupField}
                        options={INVITATION_TYPE_OPTIONS}
                        parse={value => {
                          form.change('attendeeOrTeam', null);
                          return value;
                        }}
                        row
                      />
                    </Grid>
                    <Grid item>
                      <ExperienceAttendeeOrTeamSelect name="attendeeOrTeam" type={invitationType} />
                    </Grid>
                  </Grid>
                </Box>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  color="info"
                  variant="text"
                  disabled={pristine}
                  onClick={handleCloseModal(form.reset)}
                >
                  Cancel
                </Button>
                <Button loading={submitting} disabled={pristine} onClick={form.submit}>
                  Invite
                </Button>
              </Modal.Actions>
            </Modal>
          </form>
        );
      }}
    />
  );
};

export default EarlyBirdRegistrationInvitationCreateModal;
