import * as yup from 'yup';
import _ from 'lodash';

import { concatDateWithTime, isDateGreaterOrEquals, isDateLessOrEquals } from '@vizsla/utils';

import { PricingType, TaxDeductibleType } from 'src/types/pricingSettings';

export type PricingTierContextSchema = {
  minDateTimeValue: string | null | undefined;
  maxDateTimeValue: string | null | undefined;
};

const PriceSchema = yup
  .number()
  .nullable()
  .required('Price is required')
  .min(0, 'Should be greater or equals 0');

const TaxDeductibleTypeSchema = yup.string().nullable().required('Tax Deductible Type is required');

const TaxDeductiblePercentsAmountSchema = yup
  .number()
  .nullable()
  .when('taxDeductibleType', {
    is: TaxDeductibleType.Percents,
    then: yup
      .number()
      .nullable()
      .required('Tax Deductible Amount is required')
      .min(0, 'Should be greater or equals 0')
      .max(100, 'Should be less or equals 100'),
    otherwise: yup.number().nullable().notRequired(),
  });

const TaxDeductibleCurrencyAmountSchema = yup
  .number()
  .nullable()
  .when('taxDeductibleType', {
    is: TaxDeductibleType.Currency,
    then: yup
      .number()
      .nullable()
      .required('Tax Deductible Amount is required')
      .min(0, 'Should be greater or equals 0'),
    otherwise: yup.number().nullable().notRequired(),
  });

export const ScaledPricingTierSchema = yup.object().shape({
  startDate: yup.string().nullable().required('Start Date is required'),
  startTime: yup
    .string()
    .nullable()
    .required('Start Time is required')
    .test(
      'start-date-should-greater-or-equal-end-date-of-previos-pricing-tier',
      'Start Date/Time must be less than current Pricing Tier End Date/Time, and greater than previous Pricing Tier Start Date/Time',
      (startTime: string | null | undefined, { options, parent }): boolean => {
        const { context } = options;

        const { startDate, endDate, endTime } = parent;
        const { minDateTimeValue, maxDateTimeValue } = context as PricingTierContextSchema;

        if (startDate && startTime && endDate && endTime) {
          const resultStartDateTime = concatDateWithTime(startDate, startTime);
          const resultEndDateTime = concatDateWithTime(endDate, endTime);

          const isEndGreaterThanStart = isDateGreaterOrEquals(
            resultEndDateTime,
            resultStartDateTime,
            'minute',
          );

          const isStartGreaterThanPreviousTierEnd =
            _.isNil(minDateTimeValue) ||
            isDateLessOrEquals(minDateTimeValue, resultStartDateTime, 'minute');

          const isStartLessThanNextTierStart =
            _.isNil(maxDateTimeValue) ||
            isDateGreaterOrEquals(maxDateTimeValue, resultStartDateTime, 'minute');

          return (
            isEndGreaterThanStart &&
            isStartGreaterThanPreviousTierEnd &&
            isStartLessThanNextTierStart
          );
        }
        return true;
      },
    ),
  endDate: yup.string().nullable().required('End Date is required'),
  endTime: yup
    .string()
    .nullable()
    .required('End Time is required')
    .test(
      'start-date-should-greater-or-equal-end-date-of-previos-pricing-tier',
      'End Date/Time must be greater than current Pricing Tier Start Date/Time, and less than next Pricing Tier Start Date/Time',
      (endTime: string | null | undefined, { options, parent }): boolean => {
        const { context } = options;

        const { startTime, startDate, endDate } = parent;
        const { minDateTimeValue, maxDateTimeValue } = context as PricingTierContextSchema;

        if (startDate && startTime && endDate && endTime) {
          const resultStartDateTime = concatDateWithTime(startDate, startTime);
          const resultEndDateTime = concatDateWithTime(endDate, endTime);

          const isEndGreaterThanStart = isDateGreaterOrEquals(
            resultEndDateTime,
            resultStartDateTime,
            'minute',
          );

          const isEndGreaterThanPreviousTierEnd =
            _.isNil(minDateTimeValue) ||
            isDateLessOrEquals(minDateTimeValue, resultEndDateTime, 'minute');

          const isEndLessThanNextTierStart =
            _.isNil(maxDateTimeValue) ||
            isDateGreaterOrEquals(maxDateTimeValue, resultEndDateTime, 'minute');

          return (
            isEndGreaterThanStart && isEndGreaterThanPreviousTierEnd && isEndLessThanNextTierStart
          );
        }
        return true;
      },
    ),
  price: PriceSchema,
  taxDeductibleType: TaxDeductibleTypeSchema,
  taxDeductiblePercentsAmount: TaxDeductiblePercentsAmountSchema,
  taxDeductibleCurrencyAmount: TaxDeductibleCurrencyAmountSchema,
});

export const ScaledPricingTierFieldSchema = yup.array().of(ScaledPricingTierSchema);

export const FixedPricingTierFieldSchema = yup.array().of(
  yup.object().shape({
    price: PriceSchema,
    taxDeductibleType: TaxDeductibleTypeSchema,
    taxDeductiblePercentsAmount: TaxDeductiblePercentsAmountSchema,
    taxDeductibleCurrencyAmount: TaxDeductibleCurrencyAmountSchema,
  }),
);

export const PricingSettingsSchema = yup.object({
  pricingTiers: yup.array().when(['pricingEnabled', 'pricingType'], (...args: any) => {
    // destructing args here to avoid TS error
    const [pricingEnabled, pricingType, schema] = args;

    if (!pricingEnabled) {
      return schema.array().nullable().notRequired();
    }

    if (pricingType === PricingType.Fixed) {
      return FixedPricingTierFieldSchema;
    }

    return ScaledPricingTierFieldSchema;
  }),
});
