import React from 'react';
import { useNavigate, useMatch, Outlet } from 'react-router-dom';

import { buildUrl } from '@vizsla/utils';

import { CampaignLayout } from 'src/layouts';
import { FullscreenLoader } from 'src/components/shared';
import * as appRoutes from 'src/constants/routes';
import * as entitiesId from 'src/constants/entities-id';
import { useCampaign, useCampaignId } from 'src/hooks/campaign';
import { useExperiences } from 'src/hooks/experiences';

const CampaignPageWrapper: React.FC = () => {
  const navigate = useNavigate();
  const campaignId = useCampaignId();

  const { data: campaign, loading: campaignLoading } = useCampaign(campaignId);
  const { loading: experiencesLoading } = useExperiences(campaignId);

  const match = useMatch({
    path: appRoutes.campaign,
  });

  const isRootPath = Boolean(match) && !match?.params.id;

  React.useEffect(() => {
    if (isRootPath) {
      const dashboardUrl = buildUrl(appRoutes.campaignDashboard, {
        [entitiesId.campaign]: campaignId,
      });
      navigate(dashboardUrl);
    }
  }, [navigate, campaignId, isRootPath]);

  if (campaignLoading || !campaign || experiencesLoading) {
    return <FullscreenLoader variant="light" />;
  }

  return (
    <CampaignLayout campaign={campaign}>
      <Outlet />
    </CampaignLayout>
  );
};

export { CampaignPageWrapper };
