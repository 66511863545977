import React from 'react';
import { Form, Field } from 'react-final-form';
import { Grid, Skeleton, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { FormApi } from 'final-form';
import _ from 'lodash';

import { validateWithSchema, getUserFullName } from '@vizsla/utils';
import { CampaignStaff, Maybe, User, UsersListQuery, VizslaOrganization } from '@vizsla/graphql';
import { useCurrentOrganization } from '@vizsla/hooks';
import { PaletteColor } from '@vizsla/theme';
import {
  TextField,
  NumberField,
  DataBaseMultiSelectField,
  Button,
  useDataBaseSelectField,
  UserChip,
} from '@vizsla/components';

import { CAMPAIGN_STAFF_LIST_QUERY } from 'src/graphql';
import { useCampaign, useCampaignId, useCampaignStaff } from 'src/hooks/campaign';
import { CampaignContactsFormSchema } from 'src/constants/validationSchemas/campaign';
import { PaperBlock } from 'src/components/shared';
import { CampaignDetailItem } from 'src/types/campaign';

const getInitialValues = (
  organization?: Maybe<VizslaOrganization>,
  campaign?: Maybe<CampaignDetailItem>,
  campaignStaff?: Maybe<Array<CampaignStaff>>,
) => {
  if (_.isNil(organization) || _.isNil(campaign) || _.isNil(campaignStaff)) {
    return {};
  }

  return {
    contactEmail: campaign.contactEmail || organization.contactEmail,
    contactPhone: campaign.contactPhone?.number?.length
      ? campaign.contactPhone
      : organization.contactPhone,
    emailForwardingList: campaignStaff,
  };
};

const getCampaignFieldsData = ({ contactEmail, contactPhone }: any) => ({
  contactEmail,
  contactPhone: {
    code: contactPhone?.code,
    number: contactPhone?.number,
  },
});

const getCampaignStaffNotifiedIds = (
  { emailForwardingList: newNotifiedIds }: any,
  currentNotifiedCampaignStaff: CampaignStaff[],
) => {
  const currentNotifiedIds = currentNotifiedCampaignStaff.map(
    (staff: CampaignStaff) => staff.id as string,
  );

  return {
    idsToEnable: _.difference(newNotifiedIds, currentNotifiedIds),
    idsToDisable: _.difference(currentNotifiedIds, newNotifiedIds),
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  submitMessage: {
    transition: theme.transitions.create(['opacity']),
    color: PaletteColor.GrayText,
    '&.hidden': {
      opacity: 0,
    },
  },
}));

const getStaffQueryVariables = (campaignId: string) => () => ({
  filter: {
    campaign: {
      id: {
        equals: campaignId,
      },
    },
  },
});

export const CampaignContactForm: React.FC = () => {
  const classes = useStyles();

  const campaignId = useCampaignId();

  const { organization } = useCurrentOrganization();

  const {
    data: campaign,
    loading: campaignLoading,
    updateCampaign,
    updating,
  } = useCampaign(campaignId);

  const {
    campaignStaff: currentNotifiedCampaignStaff,
    loading: campaignStaffLoading,
    updateCampaignStaffByIds,
  } = useCampaignStaff(campaignId, {
    campaign: {
      id: {
        equals: campaignId,
      },
    },
    emailForwarding: {
      equals: true,
    },
  });

  const updateNotification = React.useCallback(
    async (idsToUpdate: string[], isNotificationEnable: boolean) => {
      try {
        await updateCampaignStaffByIds({
          data: {
            emailForwarding: [
              {
                set: isNotificationEnable,
              },
            ],
          },
          idsToUpdate,
          shouldAddResultToCache: isNotificationEnable,
          shouldRemoveIdsFromCache: !isNotificationEnable,
        });
      } catch (error) {
        console.error({ error });
      }
    },
    [updateCampaignStaffByIds],
  );

  const INITIAL_VALUES = React.useMemo(
    () => getInitialValues(organization, campaign, currentNotifiedCampaignStaff),
    [organization, campaign, currentNotifiedCampaignStaff],
  );

  const [showSubmitMsg, setShowSubmitMsg] = React.useState(false);

  React.useEffect(() => {
    if (showSubmitMsg) {
      const timer = setTimeout(() => {
        setShowSubmitMsg(false);
      }, 2000);
      return () => clearTimeout(timer);
    }

    return () => {};
  }, [showSubmitMsg]);

  const multiSelectFieldProps = useDataBaseSelectField<UsersListQuery, CampaignStaff>(
    {
      query: CAMPAIGN_STAFF_LIST_QUERY,
      getQueryItems: 'campaignStaffsList.items',
      getCustomQueryVariables: getStaffQueryVariables(campaignId),
    },
    {
      getOptionLabel: (staff: CampaignStaff) => {
        const userFullName = getUserFullName(staff.user as User);
        const staffEmail = staff?.user?.email as string;
        return `${userFullName} (${staffEmail})`;
      },
      renderTag: (staff: CampaignStaff) => {
        const { user } = staff;
        if (_.isNil(user)) {
          return null;
        }
        return <UserChip key={user?.id as string} user={user} sx={{ m: '2px' }} />;
      },
      mapValues: campaignStaffList => campaignStaffList.map(staff => staff.id),
    },
  );

  const [hideMultiSelect, setHideMultiSelect] = React.useState(false);

  React.useEffect(() => {
    if (hideMultiSelect) {
      const timer = setTimeout(() => {
        setHideMultiSelect(false);
      }, 200);
      return () => clearTimeout(timer);
    }

    return () => {};
  }, [hideMultiSelect]);

  if (
    campaignLoading ||
    campaignStaffLoading ||
    _.isNil(campaign) ||
    _.isNil(currentNotifiedCampaignStaff)
  ) {
    return <PaperBlock title="Contact" collapsing loading />;
  }

  const onSubmit = async (data: any, form: FormApi) => {
    const campaignData = getCampaignFieldsData(data);
    const { idsToEnable, idsToDisable } = getCampaignStaffNotifiedIds(
      data,
      currentNotifiedCampaignStaff,
    );

    try {
      await updateCampaign(campaignData);
      await updateNotification(idsToEnable, true);
      await updateNotification(idsToDisable, false);
      form.reset();
      setShowSubmitMsg(true);
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = (form: FormApi) => {
    form.reset();
    setHideMultiSelect(true);
  };

  const renderMultiSelectField = () => {
    if (hideMultiSelect) {
      return <Skeleton width="100%" />;
    }

    return (
      <Field
        name="emailForwardingList"
        label="Staff Members"
        component={DataBaseMultiSelectField}
        disabled={updating}
        fullWidth
        {...multiSelectFieldProps}
      />
    );
  };

  return (
    <PaperBlock title="Contact" collapsing defaultOpened>
      <Form
        onSubmit={onSubmit}
        initialValues={INITIAL_VALUES}
        validate={values => validateWithSchema(CampaignContactsFormSchema, values)}
      >
        {({ form, handleSubmit, submitting, pristine, hasValidationErrors, dirty }) => {
          const isSubmitDisabled = updating || submitting || hasValidationErrors || pristine;
          const isCancelDisabled = !dirty || updating || submitting;

          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} marginBottom={4}>
                <Grid item xs={12} marginBottom={2}>
                  <Typography variant="body2">
                    Contact information for supporters who have questions about your campaign. We
                    use the organization email by default, but you are welcome to change it.
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Field fullWidth name="contactEmail" label="Email *" component={TextField} />
                </Grid>
                <Grid item xs={6} marginBottom={4}>
                  <Field
                    fullWidth
                    name="contactPhone.number"
                    label="Phone Number *"
                    formatMask="+1 (###) ###-####"
                    isNumericString
                    component={NumberField}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <Typography variant="subtitle2">Email Forwarding</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">Notify the following staff members.</Typography>
                </Grid>
                <Grid item xs={12} marginBottom={4}>
                  renderMultiSelectField{()}
                </Grid> */}
                <Grid item xs={12} container justifyContent="center" spacing={1} marginBottom={4}>
                  <Typography
                    variant="body2"
                    className={clsx({
                      [classes.submitMessage]: true,
                      hidden: !showSubmitMsg,
                    })}
                  >
                    Saved!
                  </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="center" spacing={1} marginBottom={2}>
                  <Button onClick={handleSubmit} disabled={isSubmitDisabled} loading={updating}>
                    Save
                  </Button>
                  <Button variant="text" onClick={() => onCancel(form)} disabled={isCancelDisabled}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Form>
    </PaperBlock>
  );
};
