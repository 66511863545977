import React from 'react';
import { Grid } from '@mui/material';
import {
  Send as SendIcon,
  ListOutlined as ListIcon,
  DateRangeOutlined as DateIcon,
  MonetizationOnOutlined as MoneyIcon,
  PeopleAltOutlined as TeamIcon,
  FaceOutlined as AttendeesIcon,
} from '@mui/icons-material';

import { NumberFieldProps, FiltersLayout } from '@vizsla/components';
import {
  MONEY_FORMAT,
  BASE_NUMBER_FORMAT,
  CAMPAIGN_STATUS_OPTIONS,
  CAMPAIGN_TYPE_OPTIONS,
} from '@vizsla/constants';
import { useModal } from '@vizsla/hooks';
import {
  DateFilterSettings,
  CheckboxFilterSettings,
  RangeNumberFilterSettings,
} from '@vizsla/providers';

import { CampaignCreateModal, CampaignDeleteModal, ProgramCreateModal } from 'src/modals';
import { MODALS } from 'src/constants/modals';
import { CampaignsListViewsProvider } from 'src/providers/campaign';
import { CampaignLayoutView, CampaignView } from 'src/types/campaignsList';

import { CampaignsListLayoutSwitcher, CampaignsListLayoutViewSwitcher } from './components';

type NumberFieldInputProps = Pick<NumberFieldProps, 'className' | 'numberFormat' | 'formatMask'>;

type CampaignCustomFilters = [
  CheckboxFilterSettings,
  CheckboxFilterSettings,
  DateFilterSettings,
  RangeNumberFilterSettings<NumberFieldInputProps>,
  RangeNumberFilterSettings<NumberFieldInputProps>,
  RangeNumberFilterSettings<NumberFieldInputProps>,
];

const CAMPAIGN_CUSTOM_FILTERS: CampaignCustomFilters = [
  {
    type: 'checkbox',
    filterName: 'Status',
    filterValueName: 'campaignStatus',
    options: CAMPAIGN_STATUS_OPTIONS,
    icon: <SendIcon />,
  },
  {
    type: 'checkbox',
    filterName: 'Type',
    filterValueName: 'campaignType',
    options: CAMPAIGN_TYPE_OPTIONS,
    icon: <ListIcon />,
  },
  {
    type: 'date',
    filterName: 'Date',
    dateFilterKey: 'campaignDateFilter',
    fromToFields: ['startDate', 'endDate'],
    icon: <DateIcon />,
  },
  {
    type: 'range-count',
    filterName: 'Raised',
    filterValueName: 'totalRaised',
    inputProps: {
      numberFormat: MONEY_FORMAT,
    },
    icon: <MoneyIcon />,
  },
  {
    type: 'range-count',
    filterName: 'Attendees',
    filterValueName: 'attendees',
    customQuery(from, to) {
      return {
        key: 'attendees',
        queryVariables: {
          from,
          to,
        },
      };
    },
    inputProps: {
      numberFormat: BASE_NUMBER_FORMAT,
    },
    icon: <AttendeesIcon />,
  },
  {
    type: 'range-count',
    filterName: 'Teams',
    filterValueName: 'teams',
    customQuery(from, to) {
      return {
        key: 'teams',
        queryVariables: {
          from,
          to,
        },
      };
    },
    inputProps: {
      numberFormat: BASE_NUMBER_FORMAT,
    },
    icon: <TeamIcon />,
  },
];

export const CampaignsListLayout: React.FC = ({ children }) => {
  const { openModal } = useModal();

  const [campaignLayoutView, setCampaignLayoutView] = React.useState<CampaignLayoutView>(
    CampaignLayoutView.Tile,
  );

  const [campaignView, setCampaignView] = React.useState<CampaignView>(CampaignView.All);
  const isCampaignView = campaignView === CampaignView.All;

  const onChangeCampaignLayoutView = (_: React.ChangeEvent, layoutView: CampaignLayoutView) => {
    setCampaignLayoutView(layoutView);
  };

  const onChangeCampaignView = (_: React.ChangeEvent, view: CampaignView) => {
    setCampaignView(view);
  };

  const onOpenCampaignCreateModal = () => {
    openModal(MODALS.CAMPAIGN_CREATE_MODAL);
  };

  const onOpenProgramCreateModal = () => {
    openModal(MODALS.PROGRAM_CREATE_MODAL);
  };

  const createButtonText = isCampaignView ? 'New Campaign' : 'New Program';
  const onOpenModal = isCampaignView ? onOpenCampaignCreateModal : onOpenProgramCreateModal;

  return (
    <FiltersLayout
      withSearch
      withPagination
      rightButtonProps={{
        content: createButtonText,
        onClick: onOpenModal,
      }}
      customFilters={CAMPAIGN_CUSTOM_FILTERS}
      rightComponent={
        <React.Fragment>
          <Grid item>
            <CampaignsListLayoutSwitcher
              campaignLayoutView={campaignLayoutView}
              onChangeLayout={onChangeCampaignLayoutView}
            />
          </Grid>
          <Grid item>
            <CampaignsListLayoutViewSwitcher
              campaignView={campaignView}
              handleButtonToggleFlag={onChangeCampaignView}
            />
          </Grid>
        </React.Fragment>
      }
    >
      <React.Fragment>
        <Grid item>
          <CampaignsListViewsProvider context={{ campaignLayoutView, campaignView }}>
            {children}
          </CampaignsListViewsProvider>
        </Grid>
        <CampaignCreateModal />
        <CampaignDeleteModal />
        <ProgramCreateModal />
      </React.Fragment>
    </FiltersLayout>
  );
};
