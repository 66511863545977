import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Form, Field } from 'react-final-form';
import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';

import { useModal, useNotification } from '@vizsla/hooks';
import { TextField, CheckboxField, SelectField, Button, Modal } from '@vizsla/components';
import { CampaignQuestionType, CustomCampaignQuestionUpdateFormData } from '@vizsla/types';
import { USER_GROUP_OPTIONS } from '@vizsla/constants';
import { prepareCustomQuestionUpdateInput } from '@vizsla/utils';
import { CustomCampaignQuestion, Experience } from '@vizsla/graphql';

import { useCampaignId } from 'src/hooks/campaign';
import { useExperiences } from 'src/hooks/experiences';
import { useCampaignQuestions } from 'src/hooks/campaignQuestion';
import { CampaignCustomQuestionTypeField } from 'src/components/forms';
import { t } from 'src/utils/template';
import { MODALS } from 'src/constants/modals';

const CampaignQuestionEditModal: React.FC = () => {
  const notification = useNotification();

  const { isOpen, closeModal, args } = useModal(MODALS.CAMPAIGN_QUESTION_EDIT_MODAL);

  const campaignId = useCampaignId();
  const { updateCampaignQuestion } = useCampaignQuestions(campaignId);

  const { data: experiences } = useExperiences(campaignId);

  const ALLOCATION_OPTIONS = experiences.map((experience: Experience) => ({
    label: experience.name,
    value: experience.id,
  }));

  const initialCampaignQuestion: CustomCampaignQuestion = React.useMemo(
    () => args?.campaignQuestion,
    [args?.campaignQuestion],
  );
  const initialExperiences = React.useMemo(
    () => initialCampaignQuestion?.experience?.items.map(experience => experience?.id),
    [initialCampaignQuestion?.experience?.items],
  );

  const INITIAL_VALUES = React.useMemo(
    () => ({
      title: initialCampaignQuestion?.title,
      experience: initialExperiences,
      isRequired: initialCampaignQuestion?.isRequired,
      isEnabled: initialCampaignQuestion?.isEnabled,
      userGroup: initialCampaignQuestion?.userGroup,
      type: initialCampaignQuestion?.type,
      options: initialCampaignQuestion?.options,
      format: initialCampaignQuestion?.format,
    }),
    [initialCampaignQuestion, initialExperiences],
  );

  const onSubmit = async (
    campaignQuestionData: CustomCampaignQuestionUpdateFormData,
    form: FormApi,
  ) => {
    try {
      const questionUpdateInput = prepareCustomQuestionUpdateInput(campaignQuestionData);

      await updateCampaignQuestion(
        questionUpdateInput,
        initialCampaignQuestion.id as string,
        CampaignQuestionType.Custom,
      );

      notification.success(t('campaign_custom_question_update_success'));
      closeModal();
    } catch (error) {
      notification.error(t('campaign_custom_question_update_error'));
      console.error({ error });
    }
  };

  const onCloseModal = () => {
    closeModal();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <Modal.Title>
        <Typography variant="h3" align="center">
          Edit Custom Question
        </Typography>
      </Modal.Title>

      <Modal.Content>
        <Form
          onSubmit={onSubmit as any}
          initialValues={INITIAL_VALUES}
          mutators={{
            ...arrayMutators,
          }}
        >
          {({ handleSubmit, submitting, form: { mutators }, pristine }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="title"
                      label="Question"
                      component={TextField}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="isRequired"
                      label="This is required question"
                      component={CheckboxField}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="experience"
                      label="Allocation"
                      multiple
                      component={SelectField}
                      options={ALLOCATION_OPTIONS}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="userGroup"
                      label="User Groups"
                      component={SelectField}
                      options={USER_GROUP_OPTIONS}
                      disabled={submitting}
                      required
                      multiple
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="type"
                      label="Type"
                      component={CampaignCustomQuestionTypeField}
                      mutators={mutators}
                      disabled={submitting}
                    />
                  </Grid>
                </Grid>
                <Modal.Actions>
                  <Button
                    variant="text"
                    size="large"
                    color="info"
                    style={{ width: 100 }}
                    onClick={onCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={{ width: 100 }}
                    size="large"
                    color="primary"
                    onClick={handleSubmit}
                    loading={submitting}
                    disabled={submitting || pristine}
                  >
                    Save
                  </Button>
                </Modal.Actions>
              </form>
            );
          }}
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default CampaignQuestionEditModal;
