import React, { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import { Button, Grid, TextField as TField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useNotification, useModal } from '@vizsla/hooks';
import {
  useCurrentUserConsumerQuery,
  useUser_Consumer_Info_UpdateMutation,
  UserUpdateInput,
} from '@vizsla/graphql';
import { NumberField, SelectField, TextField } from '@vizsla/components';
import { GENDER_OPTIONS, LANGUAGE_OPTIONS } from '@vizsla/constants';
import { USA_STATES_OPTIONS } from '@vizsla/constants';
import { validateWithSchema } from '@vizsla/utils';
import { MODALS } from '@vizsla/constants';

import { editUsersInfoSchema } from 'src/constants/validationSchemas/editUserSchema';
import { SelectProfilePictureModal } from 'src/modals/SelectProfilePictureModal';

import {
  AvatarPicture,
  ChanceAvataButton,
  Container,
  FormContainer,
  FormLabel,
  Icon,
  TinyTitle,
  Title,
} from './ProfileInfo.style';

const ProfileInfo: React.FC = () => {
  const notification = useNotification();

  const { openModal, closeModal } = useModal();

  const {
    data: dataCurrentuser,
    refetch,
    loading: loadingCurrentuser,
  } = useCurrentUserConsumerQuery();
  const idUser = dataCurrentuser?.user?.id;
  const birthDateUser = useMemo(
    () => moment(dataCurrentuser?.user?.birthDate).format('YYYY-MM-DD'),
    [dataCurrentuser],
  );
  const initialValues = React.useMemo(
    () => ({
      firstName: dataCurrentuser?.user?.firstName,
      lastName: dataCurrentuser?.user?.lastName,
      email: dataCurrentuser?.user?.email,
      gender: dataCurrentuser?.user?.gender,
      phone: {
        number: dataCurrentuser?.user?.phone?.number,
        code: dataCurrentuser?.user?.phone?.code,
      },
      birthDate: birthDateUser,
      address: {
        street1: dataCurrentuser?.user?.address?.street1,
        city: dataCurrentuser?.user?.address?.city,
        state: dataCurrentuser?.user?.address?.state,
        country: dataCurrentuser?.user?.address?.country,
        zip: dataCurrentuser?.user?.address?.zip,
      },
      companyName: dataCurrentuser?.user?.companyName,
      language: 'English',
    }),
    [dataCurrentuser, birthDateUser],
  );

  const [userUpdate, { loading }] = useUser_Consumer_Info_UpdateMutation();

  const onSubmit = async (data: UserUpdateInput) => {
    try {
      await userUpdate({
        variables: {
          userid: idUser,
          data: {
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            gender: data?.gender,
            phone: {
              number: data?.phone?.number?.toString(),
              code: data?.phone?.code,
            },
            birthDate: moment(data?.birthDate).format('YYYY-MM-DD'),
            address: {
              street1: data?.address?.street1,
              city: data?.address?.city,
              state: data?.address?.state,
              country: data?.address?.country,
              zip: data?.address?.zip,
            },
            companyName: data?.companyName,
            language: data?.language,
          },
        },
      });
      refetch();
      notification.success('User update success');
    } catch (error) {
      notification.error('Error updating user');
      console.error(error);
    }
  };

  const onPictureUpdate = async ({ picture }) => {
    const avatar = {
      create: {
        fileId: picture?.fileId,
        filename: picture?.filename,
        meta: picture?.meta,
      },
    };
    try {
      await userUpdate({
        variables: {
          userid: idUser,
          data: {
            avatar,
          },
        },
      });
      refetch();
      notification.success('User update success');
    } catch (error) {
      notification.error('Error updating user');
      console.error(error);
    }
    closeModal(MODALS.PROFILE_PICTURE_UPDATE_MODAL);
  };

  const openPictureUpdateModal = () => {
    openModal(MODALS.PROFILE_PICTURE_UPDATE_MODAL);
  };

  return (
    <Container>
      <AvatarPicture src={dataCurrentuser?.user?.avatar?.downloadUrl || ''} />
      <SelectProfilePictureModal onSubmit={onPictureUpdate} />
      <ChanceAvataButton
        size="small"
        variant="text"
        color="secondary"
        onClick={openPictureUpdateModal}
      >
        Change Avatar
      </ChanceAvataButton>
      <Title>
        {dataCurrentuser?.user?.firstName} {dataCurrentuser?.user?.lastName}
      </Title>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={values => validateWithSchema(editUsersInfoSchema, values)}
      >
        {({ handleSubmit, submitting, pristine, validating, form, hasValidationErrors }) => {
          const isDisabled = submitting || pristine || hasValidationErrors;
          return (
            <FormContainer container spacing={2}>
              <Grid item xs={12} display="flex" flexDirection="row">
                <Icon htmlColor="#000" />
                <TinyTitle>Personal Information</TinyTitle>
              </Grid>
              <Grid item xs={6}>
                <FormLabel>Name</FormLabel>
                <Field
                  fullWidth
                  name="firstName"
                  label="First Name"
                  component={TextField}
                  required
                  disabled={loadingCurrentuser}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel>Last Name</FormLabel>
                <Field
                  fullWidth
                  name="lastName"
                  label="last Name"
                  component={TextField}
                  required
                  disabled={loadingCurrentuser}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Email Address</FormLabel>
                <Field fullWidth name="email" label="email" component={TextField} disabled />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Phone Number</FormLabel>
                <Field
                  fullWidth
                  name="phone.number"
                  formatMask="+1 (###) ###-####"
                  label="Phone"
                  isNumericString
                  component={NumberField}
                  required
                  disabled={loadingCurrentuser}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel>Gender</FormLabel>
                <Field
                  fullWidth
                  name="gender"
                  label="Gender"
                  required
                  component={SelectField}
                  options={GENDER_OPTIONS}
                  disabled={loadingCurrentuser}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel>Date of Birth</FormLabel>
                <Field name="birthDate" label="BirthDate" fullWidth required>
                  {props => (
                    <div>
                      <TField
                        name={props.input.name}
                        onChange={props.input.onChange}
                        value={props.input.value}
                        type="date"
                        fullWidth
                        disabled={loadingCurrentuser}
                        required
                      />
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Address</FormLabel>
                <Field
                  fullWidth
                  name="address.street1"
                  component={TextField}
                  required
                  disabled={loadingCurrentuser}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  name="address.city"
                  label="City"
                  component={TextField}
                  required
                  disabled={loadingCurrentuser}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  name="address.state"
                  label="State/Province"
                  component={SelectField}
                  options={USA_STATES_OPTIONS}
                  required
                  disabled={loadingCurrentuser}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  name="address.country"
                  label="Country"
                  component={TextField}
                  required
                  disabled={loadingCurrentuser}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  name="address.zip"
                  label="Zip Code"
                  isNumericString
                  formatMask="#####"
                  component={NumberField}
                  disabled={loadingCurrentuser}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Company Name</FormLabel>
                <Field
                  fullWidth
                  name="companyName"
                  label="Company name"
                  component={TextField}
                  disabled={loadingCurrentuser}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel>Preferred Language</FormLabel>
                <Field
                  fullWidth
                  name="language"
                  component={SelectField}
                  options={LANGUAGE_OPTIONS}
                  disabled={loadingCurrentuser}
                />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton onClick={handleSubmit} disabled={isDisabled} loading={submitting}>
                  Save
                </LoadingButton>

                <Button
                  type="reset"
                  variant="outlined"
                  disabled={submitting || pristine}
                  onClick={() => form.reset()}
                >
                  Cancel
                </Button>
              </Grid>
            </FormContainer>
          );
        }}
      </Form>
    </Container>
  );
};

export { ProfileInfo };
