import React from 'react';
import clsx from 'clsx';
import { Box, BoxProps, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { HelpOutline } from '@mui/icons-material';

import { FontSize, PaletteColor } from 'src/theme';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipText: {
    fontSize: FontSize.S,
  },
  tooltipContainer: {
    display: 'flex',
    marginLeft: 6,
  },
  textContainer: {
    textAlign: 'center',
  },
  whiteText: {
    color: PaletteColor.White,
  },
  helpOutlineQuestionStyle: {
    fontSize: FontSize.M,
    color: PaletteColor.DefaultLight,
  },
}));

interface TooltipTextProps extends BoxProps {
  children: React.ReactNode;
  text: string;
  type?: 'question' | 'attention';
}

export const TooltipTextWrapper: React.FC<TooltipTextProps> = ({
  text,
  children,
  type = 'question',
  className,
  ...props
}) => {
  const classes = useStyles();

  const renderIconByType = () => {
    if (type === 'question') {
      return <HelpOutline className={clsx(classes.helpOutlineQuestionStyle, className)} />;
    }

    return <HelpOutline fontSize="small" />;
  };

  const tooltip = (
    <Tooltip
      placement="top"
      arrow
      title={
        <Typography variant="body2" className={clsx(classes.tooltipText, classes.whiteText)}>
          {text}
        </Typography>
      }
    >
      {renderIconByType()}
    </Tooltip>
  );

  return (
    <Box className={classes.root} {...props}>
      <Box {...props}>{children}</Box>
      <div className={classes.tooltipContainer}>{tooltip}</div>
    </Box>
  );
};
