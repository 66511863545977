import * as yup from 'yup';
import _ from 'lodash';
import { StripeCardFieldName } from './types';
import { getStripeFieldError } from './utils';
export const CreditCardValidationSchema = yup
    .object({
    [StripeCardFieldName.Number]: yup
        .string()
        .test('card-number-is-required', function validate(_value, { options }) {
        const { context } = options;
        const errorMessage = getStripeFieldError(StripeCardFieldName.Number, context === null || context === void 0 ? void 0 : context.securedFields);
        if (!_.isNil(errorMessage)) {
            return this.createError({
                message: errorMessage,
            });
        }
        return true;
    }),
    [StripeCardFieldName.Expiry]: yup
        .string()
        .test('expiry-number-is-required', function validate(_value, { options }) {
        const { context } = options;
        const errorMessage = getStripeFieldError(StripeCardFieldName.Expiry, context === null || context === void 0 ? void 0 : context.securedFields);
        if (!_.isNil(errorMessage)) {
            return this.createError({
                message: errorMessage,
            });
        }
        return true;
    }),
    [StripeCardFieldName.Cvc]: yup
        .string()
        .test('cvc-number-is-required', function validate(_value, { options }) {
        const { context } = options;
        const errorMessage = getStripeFieldError(StripeCardFieldName.Cvc, context === null || context === void 0 ? void 0 : context.securedFields);
        if (!_.isNil(errorMessage)) {
            return this.createError({
                message: errorMessage,
            });
        }
        return true;
    }),
})
    .required();
