import * as yup from 'yup';

import { AddressSchema, PhoneSchema } from '@vizsla/constants';

export const editUsersInfoSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last name is required'),
  gender: yup.string().nullable().required('Gender is required'),
  birthDate: yup.string().nullable().required('Birthdate is required'),
  email: yup.string().email('Invalid Email').required('Email is required'),
  phone: PhoneSchema,
  address: AddressSchema,
  companyName: yup.string().nullable().optional(),
});
