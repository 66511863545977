import React from 'react';
import _ from 'lodash';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { BibAutoAssignmentStatus } from 'src/types/bibAssignment';
import { BIB_AUTO_STATUS_LABELS } from 'src/constants/bibAssignment';
import { FontWeight, PaletteColor, Shade } from 'src/theme';

interface BibAutoAssignmentStatusChipProps {
  status: BibAutoAssignmentStatus;
}

const useStyles = makeStyles(() => ({
  chip: {
    color: PaletteColor.White,
    fontWeight: FontWeight.Regular,
  },
  [BibAutoAssignmentStatus.Inactive]: {
    background: PaletteColor.WarningMain,
  },
  [BibAutoAssignmentStatus.Scheduled]: {
    background: PaletteColor.InfoMain,
  },
  [BibAutoAssignmentStatus.InProgress]: {
    background: PaletteColor.SuccessMain,
  },
  [BibAutoAssignmentStatus.Completed]: {
    background: Shade.Gray[300],
  },
}));

export const BibAutoAssignmentStatusChip: React.FC<BibAutoAssignmentStatusChipProps> = ({
  status,
}) => {
  const classes = useStyles();

  if (_.isNil(status)) {
    return null;
  }

  return (
    <Chip
      size="small"
      label={BIB_AUTO_STATUS_LABELS[status]}
      className={clsx(classes.chip, classes[status])}
    />
  );
};
