import React, { useCallback } from 'react';
import { CircularProgress, Grid, IconButton, Theme, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Field, Form } from 'react-final-form';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import { useModal, useNotification } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import {
  User,
  Attendee,
  AttendeesListQuery,
  useTeamRtCreateMutation,
  Experience,
  ExperienceListQuery,
  TEAM_FUNDRAISING_MANAGEMENT_BY_FILTER,
} from '@vizsla/graphql';
import {
  Modal,
  TextField,
  NumberField,
  CheckboxField,
  DataBaseSelectField,
  useDataBaseSelectField,
  DEFAULT_NUMBER_OF_SEARCH_OPTIONS,
  SelectField,
  DebouncedField,
} from '@vizsla/components';

import {
  ATTENDEES_LIST_QUERY,
  EXPERIENCE_LIST_PER_CAMPAIGN,
  TEAMS_NAME_PER_EXPERIENCE,
} from 'src/graphql';
import { useCampaignId } from 'src/hooks/campaign';
import { MODALS } from 'src/constants/modals';
import { MONEY_FORMAT } from 'src/constants/formats';
import { getUserFullName } from 'src/utils/user';
import { CampaignTeamAddSchema } from 'src/constants/validationSchemas/campaign';
import { TeamRole } from 'src/types/team';
import { getAttendeeSearchFilter } from 'src/utils/attendee';
import { useExperiences } from 'src/hooks/experiences';

const useStyles = makeStyles((theme: Theme) => ({
  addTeamButton: {
    padding: theme.spacing(2),
    width: '150px',
  },
  closeIconButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
type Form = Record<string, any>;

type TeamRTCreateFormValues = {
  teamName: string;
  teamFundraisingGoal: number | null;
  teamCaptain: Attendee | null;
  webLink: string;
  experience: string | null;
};

const TeamRTCreateModal: React.FC = () => {
  const classes = useStyles();

  const { isOpen, closeModal } = useModal(MODALS.TEAM_RT_CREATE_MODAL);

  const notification = useNotification();
  const campaignId = useCampaignId();
  const { data: experiences } = useExperiences(campaignId);
  const [findByFilter] = useLazyQuery(TEAM_FUNDRAISING_MANAGEMENT_BY_FILTER);
  const [findTeamByfilter] = useLazyQuery(TEAMS_NAME_PER_EXPERIENCE);

  async function existsByWebLink(webLink?: string) {
    if (!webLink || !campaignId) return false;

    const result = await findByFilter({
      variables: {
        filter: {
          AND: [{ webLink: { equals: webLink } }, { campaign: { id: { equals: campaignId } } }],
        },
      },
    });
    const entries = result.data?.teamFundraisingsList?.count ?? 0;
    return entries > 0;
  }

  const validateSchema = useCallback((form: Form) => {
    return validateWithSchema(CampaignTeamAddSchema, form);
  }, []);

  const [teamRtCreateMutation, { loading }] = useTeamRtCreateMutation();
  const EXPERIENCE_OPTIONS = experiences.map((experience: Experience) => ({
    label: experience.name || '',
    value: experience.id || '',
  }));

  const teamRTCreateFormInitialValues: TeamRTCreateFormValues = {
    teamName: '',
    teamFundraisingGoal: null,
    teamCaptain: null,
    webLink: '',
    experience: null,
  };

  const onCancel = (reset: () => void) => {
    closeModal();
    reset();
  };

  function getTeamRtCreateFieldsData(values: TeamRTCreateFormValues) {
    const {
      teamName: name,
      teamFundraisingGoal: fundraiserGoal,
      teamCaptain,
      webLink,
      experience,
    } = values;

    const membersCreateData = [
      {
        role: TeamRole.TeamCaptain,
        attendee: { connect: { id: teamCaptain?.id } },
      },
    ];

    return {
      variables: {
        createData: {
          name,
          fundraiserGoal: fundraiserGoal || 0,
          webLink,
          experience: { connect: { id: experience } },
          campaign: { connect: { id: campaignId } },
          members: {
            create: membersCreateData,
          },
        },
      },
    };
  }

  const validateTeamName = async (values: TeamRTCreateFormValues, form: Form) => {
    if (!form.teamName) return undefined;

    const { data: responseData } = await findTeamByfilter({
      variables: {
        experienceId: form.experience,
        teamName: form.teamName,
      },
    });

    const entries = responseData?.experience?.teams?.count ?? 0;
    const isEntries = entries > 0;

    if (isEntries) {
      return { teamName: `Already exists a team "${form.teamName}".` };
    }
    return undefined;
  };

  const validateWebLink = async (webLink: string) => {
    if (!webLink) return undefined;

    if (await existsByWebLink(webLink)) {
      return { webLink: `Already exists a link "${webLink}".` };
    }

    return undefined;
  };

  const onSubmit = async (values: TeamRTCreateFormValues, form: Form) => {
    try {
      await teamRtCreateMutation(getTeamRtCreateFieldsData(values));
      notification.success(`Team ${values.teamName} was created succesfully`);
      onCancel(form.reset);
      return {};
    } catch {
      notification.error(`Couldn't create a team`);
      return {};
    }
  };

  const selectFieldProps = useDataBaseSelectField<AttendeesListQuery, Attendee>(
    {
      query: ATTENDEES_LIST_QUERY,
      getQueryItems: data =>
        data?.attendeesList?.items.filter((attendee: Attendee) =>
          _.isNull(attendee.teamMembership),
        ),
      getCustomQueryVariables: searchText => ({
        filter: getAttendeeSearchFilter(searchText, campaignId),
        first: DEFAULT_NUMBER_OF_SEARCH_OPTIONS,
      }),
    },
    {
      getOptionLabel: (attendee: Attendee) => getUserFullName(attendee.user as User),
    },
  );

  return (
    <Form<TeamRTCreateFormValues>
      onSubmit={onSubmit}
      initialValues={teamRTCreateFormInitialValues}
      validate={validateSchema}
      subscription={{
        submitting: true,
        pristine: true,
        values: true,
        validating: true,
        invalid: true,
      }}
      render={({ form, handleSubmit, submitting, pristine, values, validating, invalid }) => {
        const isLoading = submitting || loading;
        const isDisabled = invalid || validating;

        return (
          <form onSubmit={handleSubmit}>
            <Modal isOpen={isOpen} onClose={() => onCancel(form.reset)}>
              <Modal.Content>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item>
                    <IconButton
                      aria-label="close"
                      onClick={() => onCancel(form.reset)}
                      className={classes.closeIconButton}
                      size="large"
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid item marginBottom={2}>
                    <Typography variant="h2">Add Team</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Field
                      label="Experience"
                      name="experience"
                      component={SelectField}
                      options={EXPERIENCE_OPTIONS}
                      disabled={submitting}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <DebouncedField
                      label="Team Name"
                      name="teamName"
                      component={TextField}
                      fullWidth
                      debounce={600}
                      validate={validateTeamName}
                    />
                    {validating && values.teamName && (
                      <CircularProgress size={13} color="primary" />
                    )}
                  </Grid>

                  <Grid item xs={10}>
                    <Field
                      name="teamCaptain"
                      label="Team Captain"
                      component={DataBaseSelectField}
                      fullWidth
                      {...selectFieldProps}
                    />
                  </Grid>

                  {/* !!!!! This will be added after we add the partnership !!!!! */}
                  {/* <Grid item xs={10}>
                    <Field
                      label="Related to Partner or Company"
                      name="relatedToPartnerOrCompany"
                      component={CheckboxField}
                      fullWidth
                    />
                  </Grid>
                  {values.relatedToPartnerOrCompany && (
                    <Grid item xs={10}>
                      <Field
                        name="parnterOrCompany"
                        label="Partner or Company"
                        component={DataBaseMultiSelectField}
                        // disabled={updating}
                        fullWidth
                        // {...multiSelectFieldProps}
                      />
                    </Grid>
                  )} */}
                  <Grid item xs={10}>
                    <Field
                      label="Team Fundraising Goal (USD)"
                      name="teamFundraisingGoal"
                      component={NumberField}
                      numberFormat={MONEY_FORMAT}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <DebouncedField
                      label="Team Web Link"
                      name="webLink"
                      component={TextField}
                      required
                      fullWidth
                      debounce={600}
                      validate={validateWebLink}
                    />
                    {values.webLink && validating && <CircularProgress size={13} color="primary" />}
                  </Grid>
                </Grid>
              </Modal.Content>
              <Modal.Actions>
                <Modal.Action
                  type="primary"
                  content="Add Team"
                  onAction={handleSubmit}
                  disabled={isDisabled}
                  loading={isLoading}
                  className={classes.addTeamButton}
                />
              </Modal.Actions>
            </Modal>
          </form>
        );
      }}
    />
  );
};

export default TeamRTCreateModal;
