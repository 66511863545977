import React from 'react';

import { STORE_ALL_VALUE } from '@vizsla/constants';

type StoreAssetEventFilterProviderData = {
  sizeFilter: string;
  styleFilter: string;
  setSizeFilter: (size: string) => void;
  setStyleFilter: (style: string) => void;
};

export const StoreAssetEventFilterContext = React.createContext<StoreAssetEventFilterProviderData>({
  sizeFilter: STORE_ALL_VALUE,
  styleFilter: STORE_ALL_VALUE,
  setSizeFilter: () => {},
  setStyleFilter: () => {},
});

const StoreAssetEventFilterProvider: React.FC<{ children: any }> = ({ children }) => {
  const [sizeFilter, setSizeFilter] = React.useState<string>(STORE_ALL_VALUE);
  const [styleFilter, setStyleFilter] = React.useState<string>(STORE_ALL_VALUE);

  return (
    <StoreAssetEventFilterContext.Provider
      value={{ sizeFilter, styleFilter, setSizeFilter, setStyleFilter }}
    >
      {children}
    </StoreAssetEventFilterContext.Provider>
  );
};

export { StoreAssetEventFilterProvider };
