import React from 'react';
import { Grid, Typography } from '@mui/material';
import _ from 'lodash';

import { formatDate, formatMoney } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';
import {
  RegistrationPricingSetting,
  RegistrationPricingTier,
  TicketingPricingSetting,
  TicketingPricingTier,
} from '@vizsla/graphql';

import { EllipsisTypography } from 'src/components/shared';
import { PricingType } from 'src/types/pricingSettings';

type PricingTier = RegistrationPricingTier | TicketingPricingTier;

interface PricingTiersListProps {
  pricingSettings?: RegistrationPricingSetting | TicketingPricingSetting | null;
}

const formatPrice = (price?: number | null) =>
  !_.isNil(price) && price > 0 ? formatMoney(price) : 'FREE';

export const PricngTiersList: React.FC<PricingTiersListProps> = ({ pricingSettings }) => {
  if (_.isNil(pricingSettings)) {
    return <EllipsisTypography>N/A</EllipsisTypography>;
  }

  if (!pricingSettings.pricingEnabled || _.isEmpty(pricingSettings.pricingTiers?.items)) {
    return <EllipsisTypography>FREE</EllipsisTypography>;
  }

  if (pricingSettings.pricingType === PricingType.Fixed) {
    const price = pricingSettings?.pricingTiers?.items[0]?.price;
    const formattedPrice = formatPrice(price);

    return <EllipsisTypography>{formattedPrice}</EllipsisTypography>;
  }

  const renderPricingTier = (pricingTier: PricingTier) => {
    const { startDate, endDate, price } = pricingTier;

    const formattedStartDate = `${formatDate(
      startDate,
      DateFormatPatterns.smallDateWithLocalTime,
    )}`;
    const formattedEndDate = `${formatDate(endDate, DateFormatPatterns.smallDateWithLocalTime)}`;
    const formattedDateRange = `${formattedStartDate} / ${formattedEndDate}`;
    const formattedPrice = formatPrice(price);
    const formattedDateRangeWithPrice = `${formattedDateRange}: ${formattedPrice}`;

    return (
      <Grid>
        <Typography>{formattedDateRangeWithPrice}</Typography>
      </Grid>
    );
  };

  return <Grid>{pricingSettings.pricingTiers?.items.map(renderPricingTier)}</Grid>;
};
