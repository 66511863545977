import React from 'react';
import { FormSpy } from 'react-final-form';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Field, CheckboxField, NumberField } from '@vizsla/components';

import { BASE_NUMBER_FORMAT } from 'src/constants/formats';
import { FontSize, PaletteColor } from 'src/theme';

const useStyles = makeStyles(() => ({
  zeroFieldTopPadding: {
    '&.MuiGrid-item': {
      paddingTop: 0,
    },
  },
  checkboxField: {
    fontSize: FontSize.Default,
    color: PaletteColor.BlackText,
  },
  totalUsesDescription: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
}));

export const CampaignDiscountFormUsageSection: React.FC = () => {
  const classes = useStyles();

  return (
    <FormSpy subscription={{ submitting: true, values: true }}>
      {({ submitting }) => {
        return (
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Usage</Typography>
              </Grid>
              <Grid item xs={12} className={classes.zeroFieldTopPadding}>
                <Field
                  name="limitToOneUsePerAttendee"
                  label="Limit to one use per Attendee"
                  labelClassName={classes.checkboxField}
                  component={CheckboxField}
                  disabled={submitting}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      name="totalUses"
                      label="Total Uses"
                      component={NumberField}
                      disabled={submitting}
                      numberFormat={BASE_NUMBER_FORMAT}
                      helperText="Leave blank for Unlimited"
                      placeholder="Unlimited"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} position="relative">
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.totalUsesDescription}
                    >
                      Limit the number of total uses.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </FormSpy>
  );
};
