import React from 'react';
import { Grid, Box, Typography, styled } from '@mui/material';
import { isNil } from 'lodash';

import { useModal, useNotification } from '@vizsla/hooks';
import { useStoreAssetUpdateMutation } from '@vizsla/graphql';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import { t } from 'src/utils/template';
import type { StoreAssetUpdateOrDeleteImageModalArgs } from 'src/types/storeAssets';
import { useStoreAsset } from 'src/hooks/storeAssets';

const StyledModalContentBox = styled(Box)(({ theme }) => ({
  display: 'contents',
  '& > *': {
    paddingBottom: theme.spacing(1),
  },
}));

const StoreAssetDeleteImageModal: React.FC = () => {
  const { isOpen, closeModal, args } = useModal(MODALS.STORE_ASSET_DELETE_IMAGE_MODAL);

  const notification = useNotification();

  const [storeAssetUpdateMutation, { loading }] = useStoreAssetUpdateMutation();

  const { refetchAsset } = useStoreAsset();

  const modalArgs: StoreAssetUpdateOrDeleteImageModalArgs | undefined = args;

  const storeAsset = modalArgs?.storeAsset;

  const onDeleteStoreAsset = React.useCallback(async () => {
    if (isNil(storeAsset) || isNil(storeAsset.image)) {
      return;
    }

    const storeAssetImage = storeAsset.image;

    try {
      await storeAssetUpdateMutation({
        variables: {
          id: storeAsset.id ?? '',
          data: {
            image: {
              disconnect: {
                id: storeAssetImage.id,
                fileId: storeAssetImage.fileId,
              },
            },
          },
        },
      });

      notification.success(t('store_asset_update_success'));
      refetchAsset();
      closeModal();
    } catch (err) {
      notification.error(t('store_asset_update_error'));
      console.error(err);
    }
  }, [closeModal, notification, storeAsset, storeAssetUpdateMutation]);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth>
      <Modal.Title>Delete Image</Modal.Title>

      <StyledModalContentBox>
        <Modal.Content>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography textAlign="center">
                Are you sure you want to delete the asset image?
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Modal.Actions>
                <Modal.Action
                  type="secondary"
                  content="Cancel"
                  disabled={loading}
                  onAction={closeModal}
                />

                <Modal.Action
                  type="primary"
                  content="Delete"
                  loading={loading}
                  onAction={onDeleteStoreAsset}
                />
              </Modal.Actions>
            </Grid>
          </Grid>
        </Modal.Content>
      </StyledModalContentBox>
    </Modal>
  );
};

export { StoreAssetDeleteImageModal };
