import { gql } from '@apollo/client';

export const ORDERS_BY_DONOR = gql`
  query ordersbydonors($orderId: ID) {
    order(id: $orderId) {
      donations {
        items {
          amount

          donor {
            createdAt
            firstName
            lastName
            email
            gender
            companyName
          }

          donationAllocations {
            items {
              teamFundraising {
                name
              }

              individualFundraising {
                attendee {
                  user {
                    firstName
                    lastName
                    createdAt
                  }
                }
              }
            }
          }
        }
      }
      registrationOptionFulfillment {
        items {
          attendee {
            id
            createdAt
            user {
              id
              firstName
              lastName
              gender
              companyName
              email
              createdAt
            }
          }
        }
      }
      user {
        firstName
        lastName
        email
        createdAt
      }
    }
  }
`;

export const GET_ASSETS_BY_ORDER = gql`
  query getAssetsByOrder($id: ID) {
    order(id: $id) {
      assetsFulfillment {
        items {
          storeInventoryItem {
            storeAsset {
              name
              image {
                downloadUrl
              }
            }
            itemToSellWhenOutOfStock
            variantsValues
          }
        }
      }
    }
  }
`;
