import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Flag as FlagIcon } from '@mui/icons-material';

import { useCampaignsPageStyles } from 'src/hooks/campaign';
import { CampaignView } from 'src/types/campaignsList';

interface CampaignsListLayoutViewSwitcherProps {
  campaignView: CampaignView;
  handleButtonToggleFlag: (event: React.ChangeEvent<any>, newButton: CampaignView) => void;
}

export const CampaignsListLayoutViewSwitcher: React.FC<CampaignsListLayoutViewSwitcherProps> = ({
  campaignView,
  handleButtonToggleFlag,
}) => {
  const classes = useCampaignsPageStyles();
  return (
    <ToggleButtonGroup
      exclusive
      value={campaignView}
      onChange={handleButtonToggleFlag}
      className={classes.toggle}
    >
      <ToggleButton value={CampaignView.All} disabled={campaignView === CampaignView.All}>
        <FlagIcon fontSize="small" />
      </ToggleButton>
      <ToggleButton value={CampaignView.Programs} disabled={campaignView === CampaignView.Programs}>
        PGRM
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
