import React from 'react';
import { FormControl, InputLabel, MenuItem, Select as SelectMaterial } from '@mui/material';

import { useExperienceListQuery } from '@vizsla/graphql';
import { useCurrentOrganization } from '@vizsla/hooks';

export const SelectExperience: React.FC<{
  name: string;
  label: string;
  required?: boolean;
  onChange?: (e) => void;
}> = ({ name, label, required = false, onChange }) => {
  const { organizationId } = useCurrentOrganization();
  const { data: expList, loading: expLoading } = useExperienceListQuery({
    variables: {
      filter: { campaign: { vizslaOrganization: { id: { equals: organizationId || '' } } } },
    },
    skip: !organizationId,
  });

  return (
    <React.Fragment>
      <FormControl>
        <InputLabel id="campaign-select-id" required={required}>
          {label}
        </InputLabel>
        <SelectMaterial
          name={name}
          label={label}
          labelId="campaign-select-id"
          required={required}
          onChange={e => onChange?.(e.target.value)}
          error
        >
          {expList?.experiencesList.items.map(item => (
            <MenuItem key={item?.id} value={item?.id || ''}>
              {item.name}
            </MenuItem>
          ))}
        </SelectMaterial>
      </FormControl>
    </React.Fragment>
  );
};
