import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

import { ImageSelected } from './ImageSelected';
import { ImageFile, ImageSelectorButton } from './ImageSelectorButton';

export interface MultipleImageSelectorProps {
  onChange?: (files: ImageFile[]) => void;
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
});

export function MultipleImageSelector(props: MultipleImageSelectorProps) {
  const [images, setImages] = useState<ImageFile[]>([]);

  const styles = useStyles();

  useEffect(() => {
    props.onChange?.(images);
  }, [images]);

  const removeImage = (index: number) => {
    // TODO: Remove image from CDN

    setImages(prev => prev.filter((i, key) => key !== index));
  };

  return (
    <div className={styles.container}>
      {images.map((item, key) => (
        <ImageSelected
          key={item.id || key}
          name={item.filename ?? undefined}
          url={item.downloadUrl ?? undefined}
          onRemove={() => removeImage(key)}
        />
      ))}

      <ImageSelectorButton label="Add Image" onChange={setImages} />
    </div>
  );
}
