import React from 'react';
import { Tab, Tabs } from '@mui/material';

import { CampaignQuestionUserGroup } from '@vizsla/types';

interface UserGroupsTabsProps {
  userGroup: CampaignQuestionUserGroup;
  onChangeUserGroupTab: (event: React.SyntheticEvent, userGroup: CampaignQuestionUserGroup) => void;
}

export const UserGroupsTabs: React.FC<UserGroupsTabsProps> = ({
  userGroup,
  onChangeUserGroupTab,
}) => {
  return (
    <Tabs value={userGroup} onChange={onChangeUserGroupTab} centered>
      <Tab label="ATTENDEE" value={CampaignQuestionUserGroup.attendee} />
      <Tab label="DONORS" value={CampaignQuestionUserGroup.donor} />
      <Tab label="TEAM" value={CampaignQuestionUserGroup.team} />
    </Tabs>
  );
};
