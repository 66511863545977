import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

import { getUserFullName } from '@vizsla/utils';
import {
  usePagination,
  useSearchQueryVariables,
  usePaginationQueryVariables,
  useCurrentOrganization,
  useModal,
  useNotification,
} from '@vizsla/hooks';
import {
  TableData,
  UserAvatar,
  TableColumn,
  TableRowAction,
  TablePagination,
} from '@vizsla/components';
import { useOrganizationMembersQuery, useResendTemporaryPasswordMutation } from '@vizsla/graphql';

import { MODALS } from 'src/constants/modals';
import { t } from 'src/utils/template';

import { CampaignLayoutView } from '../OrganizationSettingsTeamMembers';
import { CampaignsListInfoCards } from './CampaignsListInfoCards';
import { TeammatesCard } from './TeammatesCard';
import { OrganizationMemberAddModal } from './OrganizationMemberAddModal';
import { OrganizationMemberEditModal } from './OrganizationMemberEditModal';

interface RenderViewsProps {
  layoutView: CampaignLayoutView;
}

export const RenderViews: React.FC<RenderViewsProps> = ({ layoutView }) => {
  const { openModal } = useModal();
  const notification = useNotification();
  const { organizationId, organization } = useCurrentOrganization();
  const [resendTemporaryPasswordMutation] = useResendTemporaryPasswordMutation();

  const searchQueryVariables = useSearchQueryVariables(searchValue => {
    return {
      user: {
        OR: [
          {
            firstName: {
              contains: searchValue,
            },
          },
          {
            lastName: {
              contains: searchValue,
            },
          },
          {
            email: {
              contains: searchValue,
            },
          },
        ],
      },
    };
  });

  const pagination = usePagination();
  const paginationQueryVariables = usePaginationQueryVariables();

  const filterByOrganizationId = { vizslaOrganization: { id: { equals: organizationId } } };
  const filter = {
    ...filterByOrganizationId,
    ...searchQueryVariables,
  };

  const { data, loading, error, refetch } = useOrganizationMembersQuery({
    variables: {
      filter,
      ...paginationQueryVariables,
    },
    skip: !organizationId,
  });
  const handleResendTemporaryPassword = async OrganizationTeamMember => {
    try {
      await resendTemporaryPasswordMutation({
        variables: {
          data: {
            firstName: OrganizationTeamMember.user.firstName,
            lastName: OrganizationTeamMember.user.lastName,
            email: OrganizationTeamMember.user.email,
            organizationName: organization?.name,
          },
        },
      });
      notification.success(t('resend_invite_success'));
    } catch (error) {
      notification.error(t('resend_invite_error'));
      console.error({ error });
    }
  };
  const users = React.useMemo(() => data?.vizslaOrganizationStaffsList?.items || [], [data]);
  const count = React.useMemo(() => data?.vizslaOrganizationStaffsList?.count || 0, [data]);

  React.useEffect(() => {
    pagination.onCountChange(count);
  }, [pagination, count]);

  const columns: Array<TableColumn> = [
    {
      key: 'name',
      title: 'Name',
      render: (_v, OrganizationTeamMember) => {
        return (
          <Grid container alignItems="center" gap={1}>
            <Grid item>
              <UserAvatar user={OrganizationTeamMember.user} size="48px" />
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {getUserFullName(OrganizationTeamMember.user)}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      key: 'email',
      title: 'Email',
      render: (_v, OrganizationTeamMember) => {
        return OrganizationTeamMember.user?.email || 'N/A';
      },
    },
    {
      key: 'phone',
      title: 'Phone',
      render: (_v, OrganizationTeamMember) => {
        const phone = OrganizationTeamMember.user?.phone || null;
        const phoneFormatted = phone ? `+${phone?.code} ${phone?.number}` : 'N/A';
        return phoneFormatted;
      },
    },
    {
      title: 'role',
      key: 'Role',
      dataPath: 'campaignStatus',
      render: (_v, OrganizationTeamMember) => {
        const role =
          OrganizationTeamMember?.user?.status === 'inactive'
            ? 'Pending'
            : OrganizationTeamMember.roles[0];
        return role || 'N/A';
      },
    },
    {
      key: 'lastAccess',
      title: 'Last Access',
      render: (_v, OrganizationTeamMember) => {
        return OrganizationTeamMember.user?.updatedAt || 'N/A';
      },
    },
  ];

  const rowActions: Array<TableRowAction> = [
    {
      actionName: 'Edit',
      onAction: OrganizationTeamMember => {
        openModal(MODALS.ORGANIZATION_MEMBER_EDIT_MODAL, {
          organizationMember: OrganizationTeamMember,
        });
      },
    },
    {
      actionName: 'Resend Invitation',
      onAction: OrganizationTeamMember => {
        handleResendTemporaryPassword(OrganizationTeamMember);
      },
    },
  ];

  return (
    <Grid direction="column" spacing={2} container>
      <Grid item>
        <CampaignsListInfoCards />
      </Grid>

      <Grid item>
        {layoutView === 0 && (
          <TableData
            dataSource={users}
            columns={columns}
            rowActions={rowActions}
            pagination={pagination}
            loading={loading}
          />
        )}
      </Grid>

      {layoutView === 1 && (
        <Grid item>
          <Grid
            container
            display="flex"
            flexDirection="row"
            wrap="wrap"
            justifyContent="flex-start"
          >
            {users.map(member => (
              <TeammatesCard
                key={member.id}
                member={member}
                resendInviteFunction={handleResendTemporaryPassword}
              />
            ))}
          </Grid>
          <Box>
            <TablePagination labelRowsPerPage="Members per page" {...pagination} />
          </Box>
        </Grid>
      )}
      <OrganizationMemberAddModal refetchUsers={refetch} />
      <OrganizationMemberEditModal refetchUsers={refetch} />
    </Grid>
  );
};
