import { get } from 'lodash';
export const fillRegistrationOptionAttendee = (user) => ({
    firstName: get(user, 'firstName', ''),
    lastName: get(user, 'lastName', ''),
    email: '',
    phone: get(user, 'phone.number', ''),
    birthday: get(user, 'birthDate', ''),
    state: get(user, ['address', 'state'], ''),
    address: get(user, ['address', 'street1'], ''),
    zip: get(user, ['address', 'zip'], ''),
    city: get(user, ['address', 'city'], ''),
    reminders: false,
});
export const fillRegistrationOption = (input) => ({
    type: 'registration',
    option: input.option,
    price: input.price,
    attendee: fillRegistrationOptionAttendee(input.user),
});
