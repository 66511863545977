import { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import moment from 'moment';

const QUERY = gql`
  query CampaignDonationsMetrics(
    $campaignId: ID!
    $todayDate: DateTime!
    $lastSevenDaysDate: DateTime!
  ) {
    campaign(id: $campaignId) {
      totalGoal: fundraisingGoal

      all: allocatedDonations(
        groupBy: { query: { amount: { as: "total", fn: { aggregate: SUM } } } }
      ) {
        groups {
          total: Float
        }
      }

      average: allocatedDonations(
        groupBy: { query: { amount: { as: "total", fn: { aggregate: AVG } } } }
      ) {
        groups {
          total: Float
        }
      }

      lastSevenDays: allocatedDonations(
        filter: { createdAt: { gte: $lastSevenDaysDate } }

        groupBy: { query: { amount: { as: "total", fn: { aggregate: SUM } } } }
      ) {
        groups {
          total: Float
        }
      }

      today: allocatedDonations(
        filter: { createdAt: { gte: $todayDate } }

        groupBy: { query: { amount: { as: "total", fn: { aggregate: SUM } } } }
      ) {
        groups {
          total: Float
        }
      }
    }
  }
`;

interface Response {
  campaign: {
    totalGoal: number;

    all: {
      groups: [
        {
          total: number;
        },
      ];
    };

    average: {
      groups: [
        {
          total: number;
        },
      ];
    };

    lastSevenDays: {
      groups: [
        {
          total: number;
        },
      ];
    };

    today: {
      groups: [
        {
          total: number;
        },
      ];
    };
  };
}

interface Variables {
  campaignId: string;

  todayDate: string;
  lastSevenDaysDate: string;
}

interface CampaignDonationsMetrics {
  totalGoal: number;
  totalAverage: number;

  totalRaised: number;
  totalRaisedLastSevenDays: number;
  totalRaisedToday: number;
}

export function useCampaignDonationsMetrics(campaignId?: string) {
  const [data, setData] = useState<CampaignDonationsMetrics>();
  const [loading, setLoading] = useState(false);

  const [executeQuery] = useLazyQuery<Response, Variables>(QUERY, { fetchPolicy: 'cache-first' });

  async function refetch(campaignId: string) {
    setLoading(true);

    const todayDate = moment().startOf('day').toISOString();
    const lastSevenDaysDate = moment().startOf('day').subtract(7, 'days').toISOString();

    const response = await executeQuery({
      variables: {
        campaignId,
        todayDate,
        lastSevenDaysDate,
      },
    });

    const campaign = response.data?.campaign;

    const [all] = campaign?.all.groups ?? [];
    const [average] = campaign?.average.groups ?? [];
    const [lastSevenDays] = campaign?.lastSevenDays.groups ?? [];
    const [today] = campaign?.today.groups ?? [];

    const metrics: CampaignDonationsMetrics = {
      totalGoal: campaign?.totalGoal ?? 0,
      totalAverage: average?.total ?? 0,
      totalRaised: all?.total ?? 0,
      totalRaisedToday: today?.total ?? 0,
      totalRaisedLastSevenDays: lastSevenDays?.total ?? 0,
    };

    setData(metrics);
    setLoading(false);

    return metrics;
  }

  useEffect(() => {
    if (campaignId) {
      refetch(campaignId);
    }
  }, [campaignId]);

  return {
    data,
    loading,

    refetch,
  };
}
