import { INITIAL_PRICING_TIER } from 'src/constants/pricingSettings';
import { PricingType } from 'src/types/pricingSettings';

const TICKETING_OPTION_FIRST_FORM_INITIAL = {
  ticketName: '',
  tableNumber: null,
  description: '',

  ticketingAvailabilityEnabled: false,
  ticketingAvailabilityStartDate: null,
  ticketingAvailabilityStartTime: null,
  ticketingAvailabilityEndDate: null,
  ticketingAvailabilityEndTime: null,
};

const TICKETING_OPTION_SECOND_FORM_INITIAL = {
  ageRequirementEnabled: false,
  ageRequirement: null,
  ageRequirementAsOfDate: null,

  ticketingLimitsEnabled: false,
  attendeesPerTicket: null,
  maximumPerOrder: null,

  attendeeCapEnabled: false,
  maximumAttendees: null,

  groupTicketsEnabled: false,
  minimumGroupTickets: null,
  maximumGroupTickets: null,
};

const TICKECTING_OPTION_PRICING_STEP_INITIAL = {
  pricingSettings: {
    pricingEnabled: false,
    allowCostToBeFundraisingCredit: false,
    pricingType: PricingType.Fixed,
    pricingTiers: [INITIAL_PRICING_TIER],
  },
};

export const TICKETING_OPTION_CREATE_FORM_INITIAL = {
  ...TICKETING_OPTION_FIRST_FORM_INITIAL,
  ...TICKETING_OPTION_SECOND_FORM_INITIAL,
  ...TICKECTING_OPTION_PRICING_STEP_INITIAL,
};
