import { gql } from '@apollo/client';
export const SEARCH_CODE_QUERY = gql `
  query SearchDiscountCodeByCampaign($name: String!, $experienceId: ID!) {
    discount: searchDiscountCode(filter: { name: $name, experienceId: $experienceId }) {
      id
      name
      category
      amount
      amountType
      limitToOneUsePerAttendee
    }
  }
`;
