import React from 'react';
import { Grid, Typography } from '@mui/material';
import { FormSpy } from 'react-final-form';

import { DateFormatPatterns } from '@vizsla/constants';
import { isDateEquals, toDateTimeFromISO, trimTime } from '@vizsla/utils';
import { PaletteColor } from '@vizsla/theme';
import {
  Field,
  TextField,
  NumberField,
  DateField,
  TimeField,
  GridDefaultPadding,
} from '@vizsla/components';

import { TicketingOptionFormState } from 'src/constants/validationSchemas/ticketingOption';
import { RichEditorField, SwitchSectionField } from 'src/components/forms';

const TICKETING_AVAILABILITY_SECTION_FIELDS = [
  'ticketingAvailabilityStartDate',
  'ticketingAvailabilityStartTime',
  'ticketingAvailabilityEndDate',
  'ticketingAvailabilityEndTime',
];

export const TicketingOptionCreateBasicsStep: React.FC = () => {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        const ticketingAvailabilityMinEndDate = toDateTimeFromISO(
          trimTime(values?.ticketingAvailabilityStartDate),
        );
        const ticketingAvailabilityMaxStartDate = toDateTimeFromISO(
          trimTime(values?.ticketingAvailabilityEndDate),
        );

        const isStartDateEqualsEndDate = isDateEquals(
          trimTime(values?.ticketingAvailabilityStartDate),
          trimTime(values?.ticketingAvailabilityEndDate),
        );
        const ticketingAvailabilityMaxStartTime = isStartDateEqualsEndDate
          ? toDateTimeFromISO(values?.ticketingAvailabilityEndTime)
          : null;
        const ticketingAvailabilityMinEndTime = isStartDateEqualsEndDate
          ? toDateTimeFromISO(values?.ticketingAvailabilityStartTime)
          : null;

        return (
          <Grid container mb={2}>
            <GridDefaultPadding container item xs={12}>
              <Grid item xs={12}>
                <Typography variant="h1" align="center">
                  New Ticket Option
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" align="center" color={PaletteColor.GrayText}>
                  Let&apos;s set up a new ticket option
                </Typography>
              </Grid>
            </GridDefaultPadding>

            <GridDefaultPadding item xs={12}>
              <Typography variant="subtitle2">Fill in the basic information:</Typography>
            </GridDefaultPadding>

            <GridDefaultPadding item xs={6}>
              <Field
                required
                name="ticketName"
                label="Ticket Name"
                component={TextField}
                fullWidth
              />
            </GridDefaultPadding>

            <GridDefaultPadding item xs={6}>
              <Field name="tableNumber" label="Table Number" component={NumberField} fullWidth />
            </GridDefaultPadding>

            <GridDefaultPadding item xs={12}>
              <Field
                name="description"
                placeholder="Description (Recommended)"
                component={RichEditorField}
                note="This description will appear in the registration option the attendee views."
              />
            </GridDefaultPadding>

            <GridDefaultPadding item xs={12}>
              <SwitchSectionField
                name={TicketingOptionFormState.TicketingAvailabilityEnabled}
                heading="Ticketing Availability"
                fieldsToResetOnUncheck={TICKETING_AVAILABILITY_SECTION_FIELDS}
              >
                <Grid container xs={12} spacing={2}>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        required
                        name="ticketingAvailabilityStartDate"
                        label="Start Date"
                        disabled={!values.ticketingAvailabilityEnabled}
                        component={DateField}
                        inputFormat={DateFormatPatterns.shortMonthDayYear}
                        maxDate={ticketingAvailabilityMaxStartDate}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Field
                        required
                        name="ticketingAvailabilityStartTime"
                        label="Start Time"
                        disabled={!values.ticketingAvailabilityEnabled}
                        component={TimeField}
                        maxTime={ticketingAvailabilityMaxStartTime}
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        required
                        name="ticketingAvailabilityEndDate"
                        label="End Date"
                        disabled={!values.ticketingAvailabilityEnabled}
                        component={DateField}
                        inputFormat={DateFormatPatterns.shortMonthDayYear}
                        minDate={ticketingAvailabilityMinEndDate}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Field
                        required
                        name="ticketingAvailabilityEndTime"
                        label="End Time"
                        disabled={!values.ticketingAvailabilityEnabled}
                        component={TimeField}
                        minTime={ticketingAvailabilityMinEndTime}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </SwitchSectionField>
            </GridDefaultPadding>
          </Grid>
        );
      }}
    </FormSpy>
  );
};
