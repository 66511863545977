import React from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import { Form } from 'react-final-form';
import _ from 'lodash';

import { useModal, useCurrentOrganization } from '@vizsla/hooks';
import { CampaignStaff, User, UsersListQuery, VizslaOrganizationStaff } from '@vizsla/graphql';
import { getUserFullName } from '@vizsla/utils';
import {
  Button,
  DataBaseMultiSelectField,
  useDataBaseSelectField,
  Field,
} from '@vizsla/components';

import { VIZSLA_ORGANIZATION_STAFF_QUERY } from 'src/graphql';
import { MODALS } from 'src/constants/modals';
import { useCampaignId, useCampaignStaff } from 'src/hooks/campaign';
import { UserChip } from 'src/components/user';

const useStyles = makeStyles((theme: Theme) => ({
  wrapperContent: {
    padding: theme.spacing(2),
    width: '100%',
  },
}));

export const CampaignAddStaffDialog: React.FC = () => {
  const classes = useStyles();
  const { isOpen, closeModal, args } = useModal(MODALS.CAMPAIGN_ADD_STAFF_MODAL);
  const { organizationId } = useCurrentOrganization();
  const campaignId = useCampaignId();

  const { campaignStaff, createCampaignStaff, deleteCampaignStaff, loading, creating, deleting } =
    useCampaignStaff(campaignId);

  const updating = loading || creating || deleting;

  const currentStaffUsers = campaignStaff?.map((item: CampaignStaff) => item.user as User) ?? [];

  const initialValues = {
    staffUsers: currentStaffUsers,
  };

  const onCloseModal = () => {
    closeModal();
  };

  const onCancel = React.useCallback(() => {
    closeModal();

    if (typeof args?.onCancel === 'function') {
      args.onCancel();
    }
  }, [closeModal, args]);

  const onSubmit = async (data: any) => {
    const newUserIds = data.staffUsers.map((user: User) => user.id) as string[];
    const currentUserIds = currentStaffUsers.map((user: User) => user.id) as string[];

    const usersIdsToRemove = _.difference(currentUserIds, newUserIds);
    const usersIdsToAdd = _.difference(newUserIds, currentUserIds);

    const campaignStaffIdsToDelete = campaignStaff
      .filter((campaignStaffItem: CampaignStaff) =>
        usersIdsToRemove.includes(campaignStaffItem.user?.id as string),
      )
      .map((campaignStaffItem: CampaignStaff) => campaignStaffItem.id as string);

    try {
      await createCampaignStaff(usersIdsToAdd);
      await deleteCampaignStaff(campaignStaffIdsToDelete);

      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const multiSelectFieldProps = useDataBaseSelectField<UsersListQuery, User>(
    {
      query: VIZSLA_ORGANIZATION_STAFF_QUERY,
      getQueryItems: data => {
        const vizslaOgranizationsStaff = _.get(
          data,
          ['vizslaOrganization', 'vizslaOrganizationStaff', 'items'],
          [],
        );

        return vizslaOgranizationsStaff.map((staff: VizslaOrganizationStaff) => staff.user);
      },
      getCustomQueryVariables: () => ({
        id: organizationId,
      }),
    },
    {
      getOptionLabel: (user: User) => `${getUserFullName(user as User)} (${user?.email as string})`,
      renderTag: (user: User) => {
        return <UserChip key={user?.id as string} user={user} sx={{ m: '2px' }} />;
      },
    },
  );

  return (
    <Dialog open={isOpen} onClose={onCloseModal} fullWidth>
      <div className={classes.wrapperContent}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
            size="large"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid>
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              initialValuesEqual={() => true}
              render={({ form, handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid item xs={12} marginBottom={4} marginTop={1}>
                      <Field
                        name="staffUsers"
                        label="Add staff"
                        component={DataBaseMultiSelectField}
                        disabled={updating}
                        fullWidth
                        {...multiSelectFieldProps}
                      />
                    </Grid>
                    <Grid item xs={12} container justifyContent="center" spacing={1}>
                      <Button
                        color="info"
                        variant="contained"
                        onClick={onCancel}
                        disabled={updating}
                      >
                        Cancel
                      </Button>
                      <Button onClick={handleSubmit} loading={updating}>
                        Save
                      </Button>
                    </Grid>
                  </form>
                );
              }}
            />
          </Grid>
        </DialogContent>
      </div>
    </Dialog>
  );
};
