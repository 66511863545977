import React from 'react';
import { Tooltip, Typography, TypographyProps } from '@mui/material';

interface EllipsisTypographyProps extends Omit<TypographyProps, 'display' | 'noWrap'> {
  children: React.ReactChild;
}

export const EllipsisTypography: React.FC<EllipsisTypographyProps> = ({
  children,
  maxWidth = '100%',
  ...rest
}) => {
  return (
    <Tooltip title={children}>
      <Typography maxWidth={maxWidth} display="inline-block" noWrap {...rest}>
        {children}
      </Typography>
    </Tooltip>
  );
};
