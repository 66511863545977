import { useMemo } from 'react';
import { useUserQuery } from '@vizsla/graphql';
import { getUserFullName } from '@vizsla/utils';
export function useCurrentUser() {
    const { data: response, loading, error, } = useUserQuery({
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
    });
    const data = useMemo(() => {
        var _a, _b, _c;
        const user = ((_a = response === null || response === void 0 ? void 0 : response.user) !== null && _a !== void 0 ? _a : null);
        return {
            user,
            firstName: (_b = user === null || user === void 0 ? void 0 : user.firstName) !== null && _b !== void 0 ? _b : null,
            lastName: (_c = user === null || user === void 0 ? void 0 : user.lastName) !== null && _c !== void 0 ? _c : null,
            fullName: getUserFullName(user),
        };
    }, [response]);
    return Object.assign(Object.assign({}, data), { loading, error });
}
