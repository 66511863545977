import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';

import { useModal, usePagination } from '@vizsla/hooks';
import { TableData, TableColumn, TableRowAction } from '@vizsla/components';
import { Campaign } from '@vizsla/graphql';
import { buildUrl, calcPercentage, formatMoney, getStringifiedDateRange } from '@vizsla/utils';

import { MODALS } from 'src/constants/modals';
import { CampaignStatus } from 'src/types/campaign';
import { TextWithImageCell } from 'src/components/shared';
import * as appRoutes from 'src/constants/routes';
import * as entitiesId from 'src/constants/entities-id';
import { getAttendeesCountFromCampaign, isRegistrationAndTicketing } from 'src/utils/campaign';

import { CampaignsListInfoCards, ValueGoal } from './components';
import { CampaignStatusChip } from '../CampaignStatusChip';

interface CampaignsListTableViewProps {
  campaigns?: Campaign[];
  loading: boolean;
}

const TableView: React.FC<CampaignsListTableViewProps> = ({ campaigns = [], loading = false }) => {
  const { openModal } = useModal();

  const pagination = usePagination();

  const columns: Array<TableColumn<Campaign>> = [
    {
      title: 'name',
      key: 'name',
      render: (_v, campaign) => {
        const campaignUrl = buildUrl(appRoutes.campaignDashboard, {
          [entitiesId.campaign]: campaign.id as string,
        });
        const dateRange = getStringifiedDateRange(campaign.startDate, campaign.endDate);

        return (
          <Link to={campaignUrl} style={{ textDecoration: 'none' }}>
            <TextWithImageCell
              text={campaign.name || ''}
              image={campaign?.image?.downloadUrl}
              extra={dateRange}
            />
          </Link>
        );
      },
    },
    {
      title: 'status',
      key: 'status',
      dataPath: 'campaignStatus',
      render: (campaignStatus: CampaignStatus | undefined) => {
        return <CampaignStatusChip status={campaignStatus || CampaignStatus.draft} />;
      },
    },
    {
      title: 'type',
      key: 'type',
      dataPath: 'campaignType',
    },
    {
      title: 'experiences',
      key: 'experiences',
      render: (_v, campaign) => {
        const experiencesCount = isRegistrationAndTicketing(campaign)
          ? campaign.experiences?.count
          : 0;

        return experiencesCount;
      },
    },
    {
      title: 'raised',
      key: 'raised',
      render: (_v, campaign) => {
        const percentage = calcPercentage(
          campaign?.totalRaised || 0,
          campaign?.fundraisingGoal || 0,
        );
        const formattedTotalRaised = formatMoney(Number(campaign?.totalRaised));

        return <ValueGoal value={formattedTotalRaised} percentage={percentage} />;
      },
    },
    {
      title: 'attendees',
      key: 'attendees',
      render: (_v, campaign) => {
        const attendeesCount = getAttendeesCountFromCampaign(campaign);
        const percentage = calcPercentage(
          Number(attendeesCount),
          Number(campaign.attendanceFundraisersCountGoal),
        );

        return <ValueGoal value={attendeesCount} percentage={percentage} />;
      },
    },
    {
      title: 'teams',
      key: 'teams',
      render: (_v, campaign) => {
        const percentage = calcPercentage(campaign?.teams?.count, campaign?.teamGoal || 0);
        const teamsCount = Number(campaign?.teams?.count);

        return <ValueGoal percentage={percentage} value={teamsCount} />;
      },
    },
  ];

  const rowActions: Array<TableRowAction<Campaign>> = [
    {
      actionName: 'delete',
      onAction: campaign => openModal(MODALS.CAMPAIGN_DELETE_MODAL, { campaign }),
      disabled: campaign => campaign?.campaignStatus !== CampaignStatus.draft,
    },
  ];

  return (
    <Grid direction="column" spacing={2} container>
      <Grid item>
        <CampaignsListInfoCards />
      </Grid>
      <Grid item>
        <TableData
          dataSource={campaigns}
          columns={columns}
          rowActions={rowActions}
          pagination={pagination}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

const CampaignsListTableView = React.memo(TableView);
export { CampaignsListTableView };
