import { gql } from '@apollo/client';
export const INBOX_USER_FRAGMENT = gql `
  fragment InboxUserFragment on User {
    id
    firstName
    lastName
    fullName
    email
    avatar {
      downloadUrl
    }
  }
`;
export const INBOX_MESSAGE_FRAGMENT = gql `
  fragment InboxMessageFragment on InboxMessage {
    id
    createdAt
    createdBy {
      ...InboxUserFragment
    }
    body
    readBy {
      items {
        id
      }
    }
  }
  ${INBOX_USER_FRAGMENT}
`;
export const INBOX_FRAGMENT = gql `
  fragment InboxFragment on Inbox {
    id
    type
    subject
    createdAt
    recipients {
      items {
        ...InboxUserFragment
      }
    }
  }
  ${INBOX_USER_FRAGMENT}
`;
export const INBOX_FRAGMENT_WITH_LAST_MESSAGE = gql `
  fragment InboxFragmentWithLastMessage on Inbox {
    ...InboxFragment
    inboxMessages(first: 1, sort: [{ createdAt: DESC }]) {
      items {
        ...InboxMessageFragment
      }
    }
  }
  ${INBOX_FRAGMENT}
  ${INBOX_MESSAGE_FRAGMENT}
`;
