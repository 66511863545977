import React from 'react';
import { MonetizationOnOutlined as Icon } from '@mui/icons-material';

import { formatMoney, formatNumber } from '@vizsla/utils';

import { MetricCard, MetricCardData } from 'src/components/shared';
import { useCampaignDonationsMetrics, useCampaignId } from 'src/hooks/campaign';

interface Props {
  raised?: number;

  lastToday?: number;
  lastSevenDays?: number;

  loading?: boolean;
}

export function CampaignMetricsRaisedCard(props: Props) {
  const campaignId = useCampaignId();

  const { data, loading } = useCampaignDonationsMetrics(campaignId);

  const goal = React.useMemo(() => data?.totalGoal ?? 0, [data]);
  const raised = React.useMemo(() => data?.totalRaised ?? 0, [data]);

  const progress = React.useMemo(() => {
    return Math.min(Math.round((raised / goal) * 100), 100);
  }, [raised, goal]);

  const formattedRaised = React.useMemo(() => formatMoney(raised), [goal]);
  const formattedGoal = React.useMemo(() => formatMoney(goal), [goal]);

  const formattedLastSevenDaysRaised = React.useMemo(() => {
    return formatMoney(data?.totalRaisedLastSevenDays ?? 0);
  }, [data]);

  const formattedTodayRaised = React.useMemo(() => {
    return formatMoney(data?.totalRaisedToday ?? 0);
  }, [data]);

  const metrics = React.useMemo(() => {
    const items: MetricCardData[] = [
      {
        label: 'Goal',
        value: formattedGoal,
      },
      {
        label: 'Today',
        description: 'All the donations made today',
        value: formattedTodayRaised,
      },
      {
        label: 'Last 7 days',
        description: 'All the donations made in the last 7 days',
        value: formattedLastSevenDaysRaised,
      },
    ];

    return items;
  }, [formattedGoal, formattedLastSevenDaysRaised, formattedTodayRaised]);

  if (loading) {
    return <MetricCard loading />;
  }

  return (
    <MetricCard
      label="Raised"
      value={formattedRaised}
      icon={<Icon />}
      percentage={progress}
      metrics={metrics}
    />
  );
}
