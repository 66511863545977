import { useExperienceId as sharedUseExperienceId } from '@vizsla/hooks';

/** @deprecated use hook from &#64;vizsla/hooks package */
export const useExperienceId = sharedUseExperienceId;

export { useExperienceFormCollection } from './useExperienceFormCollection';
export { useExperiences } from './useExperiences';
export { useExperienceFundraisingSettings } from './useExperienceFundraisingSettings';
export { useExperienceById } from './useExperienceById';
export { useExperienceBibAssignment } from './useExperienceBibAssignment';
