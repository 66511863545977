import { TeamFundraisingFilter, useOrganizationTeamsListQuery } from '@vizsla/graphql';
import { PaginationQueryVariables, useCurrentOrganization } from '@vizsla/hooks';

interface UseTeamsListProps {
  searchQueryVariables?: TeamFundraisingFilter;
  paginationQueryVariables?: PaginationQueryVariables;
}

const useTeamsList = ({ searchQueryVariables, paginationQueryVariables }: UseTeamsListProps) => {
  const { organizationId, organizationIsLoading } = useCurrentOrganization();

  const teamsListVariables = {
    filter: {
      campaign: { vizslaOrganization: { id: { equals: organizationId } } },
      ...searchQueryVariables,
    },
    ...paginationQueryVariables,
  };

  const { data: teams, loading: isTeamsListLoading } = useOrganizationTeamsListQuery({
    variables: teamsListVariables,
  });

  const loading = organizationIsLoading || isTeamsListLoading;

  return { teams, loading };
};

export { useTeamsList };
