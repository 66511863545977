import React from 'react';

import { CampaignLayoutView, CampaignView } from 'src/types/campaignsList';

export type CampaignsListViewsContextType = {
  campaignLayoutView: CampaignLayoutView;
  campaignView: CampaignView;
};

export const CampaignsListViewsContext = React.createContext<CampaignsListViewsContextType>(
  {} as CampaignsListViewsContextType,
);

type CampaignsListViewsProviderProps = {
  context: CampaignsListViewsContextType;
};

export const CampaignsListViewsProvider: React.FC<CampaignsListViewsProviderProps> = ({
  context,
  children,
}) => {
  return (
    <CampaignsListViewsContext.Provider value={context}>
      {children}
    </CampaignsListViewsContext.Provider>
  );
};
