import React from 'react';
import { Form, Field } from 'react-final-form';
import { Grid } from '@mui/material';

import { RichEditorField } from 'src/components/forms';

import { HtmlRenderer } from './HtmlRenderer';

export const RichTextExample = () => {
  // eslint-disable-next-line no-console
  const onSubmit = (values: any) => console.log({ values });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Field name="text" component={RichEditorField} />
                </Grid>
                <Grid item>
                  <pre>{JSON.stringify(values)}</pre>
                </Grid>
                <Grid item>
                  <HtmlRenderer html={values.text} />
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Grid>
    </Grid>
  );
};
