import React from 'react';
import { useForm } from 'react-final-form';
import _ from 'lodash';

import { TeamFundraising, TeamRTsNamesListQuery } from '@vizsla/graphql';
import { Field, useDataBaseSelectField, DataBaseSelectField } from '@vizsla/components';

import { TEAMS_RT_NAMES_LIST_QUERY } from 'src/graphql';

export const AddOfflineDonationTeamSelectField: React.FC = () => {
  const { getFieldState, change } = useForm();
  const fundraiserFieldState = getFieldState('campaignInfo.fundraiser');
  const campaignFieldState = getFieldState('campaignInfo.campaign');

  const [isDisabled, setIsDisabled] = React.useState(false);

  const { onSearch, ...selectTeamFieldProps } = useDataBaseSelectField<
    TeamRTsNamesListQuery,
    TeamFundraising
  >(
    {
      query: TEAMS_RT_NAMES_LIST_QUERY,
      getQueryItems: 'teamRTSList.items',
      getCustomQueryVariables: searchText => ({
        filter: {
          name: {
            contains: searchText,
          },
          campaign: {
            id: {
              equals: campaignFieldState?.value,
            },
          },
        },
        skip: !campaignFieldState?.value,
      }),
    },
    {
      getOptionLabel: (team: TeamFundraising) => {
        return String(team.name);
      },
    },
  );

  React.useEffect(() => {
    if (!_.isNil(fundraiserFieldState?.value)) {
      change('campaignInfo.fundraisingTeam', undefined);
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [change, fundraiserFieldState?.value]);

  return (
    <Field
      fullWidth
      name="campaignInfo.fundraisingTeam"
      label="Fundraising Team"
      component={DataBaseSelectField}
      onSearch={onSearch}
      disabled={isDisabled}
      {...selectTeamFieldProps}
    />
  );
};
