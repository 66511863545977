import React from 'react';
import {
  Select as MUISelect,
  SelectProps as MUISelectProps,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
} from '@mui/material';

import { SelectFieldOption } from '@vizsla/types';

export interface SelectFieldProps extends MUISelectProps {
  options: Array<SelectFieldOption>;
  error?: boolean;
  helperText?: string;
  label?: React.ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
  multiple?: boolean;
}

export const Select: React.FC<SelectFieldProps> = ({
  options,
  name,
  value: valueProp,
  helperText,
  onBlur,
  onChange,
  label,
  multiple,
  disabled = false,
  error = false,
  fullWidth = false,
  required = false,
  ...rest
}) => {
  const id = `select-${name}`;
  const value = multiple ? valueProp ?? [] : valueProp ?? '';

  return (
    <FormControl variant="outlined" error={error} required={required} fullWidth={fullWidth}>
      <InputLabel id={id} disabled={disabled}>
        {label}
      </InputLabel>
      <MUISelect
        labelId={id}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        multiple={multiple}
        disabled={disabled}
        {...rest}
      >
        {options?.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MUISelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
