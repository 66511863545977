import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import _ from 'lodash';

import { useModal } from '@vizsla/hooks';
import { CampaignStaff, User } from '@vizsla/graphql';
import { Button, TableData, TableColumn } from '@vizsla/components';

import { getUserFullName } from 'src/utils/user';
import { useCampaignId, useCampaignStaff } from 'src/hooks/campaign';
import { MODALS } from 'src/constants/modals';
import { SearchInput } from 'src/components/shared';
import { UserAvatar } from 'src/components/user';

const useStyles = makeStyles((theme: Theme) => ({
  wrapperContent: {
    padding: theme.spacing(2),
    width: '100%',
  },
  staffContainer: {
    height: 'calc(100vh - 300px)',
    overflowY: 'scroll',
  },
}));

type UserWithSearchableField = User & { searchableField: string };

const COLUMNS: TableColumn<UserWithSearchableField>[] = [
  {
    title: 'title',
    key: 'user',
    dataPath: 'id',
    render: (id: string, user: User) => {
      const { email } = user;
      return (
        <Grid key={id} container alignItems="center">
          <Grid item marginRight={2}>
            <UserAvatar user={user} />
          </Grid>
          <Grid item>
            <Typography variant="h6">{getUserFullName(user)}</Typography>
            <Typography variant="caption">{email}</Typography>
          </Grid>
        </Grid>
      );
    },
  },
];

const getFilteredUsers = (searchValue: string, users: UserWithSearchableField[]) =>
  users.filter(user => user.searchableField.includes(searchValue));

export const CampaignStaffListDialog: React.FC = () => {
  const classes = useStyles();

  const campaignId = useCampaignId();
  const { campaignStaff, loading } = useCampaignStaff(campaignId);

  const { isOpen: isOpenStaffListDialog, closeModal: closeStaffListDialog } = useModal(
    MODALS.CAMPAIGN_STAFF_LIST_MODAL,
  );
  const { openModal: openAddCampaignStaffDialog } = useModal(MODALS.CAMPAIGN_ADD_STAFF_MODAL);

  const onAddButtonClick = () => {
    closeStaffListDialog();
    openAddCampaignStaffDialog();
  };

  const [searchValue, setSearchValue] = React.useState<string | undefined>(undefined);

  const onSearchChange = (value?: string) => setSearchValue(value?.toLowerCase());

  const currentStaffUsers = React.useMemo(
    () =>
      campaignStaff?.map(
        (item: CampaignStaff) =>
          ({
            ...item.user,
            searchableField: [item.user?.firstName, item.user?.lastName, item.user?.email]
              .filter(str => !_.isNil(str))
              .join(' ')
              .toLowerCase(),
          } as UserWithSearchableField),
      ) ?? [],
    [campaignStaff],
  );

  const [users, setUsers] = React.useState(currentStaffUsers);

  const onClose = () => {
    closeStaffListDialog();
  };

  React.useEffect(() => {
    if (_.isNil(searchValue) || searchValue.length === 0) {
      setUsers(currentStaffUsers);
      return;
    }
    const filtered = getFilteredUsers(searchValue, currentStaffUsers);
    setUsers(filtered);
  }, [searchValue, currentStaffUsers]);

  return (
    <Dialog open={isOpenStaffListDialog} onClose={onClose} fullWidth>
      <div className={classes.wrapperContent}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClose}
            size="large"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid>
            <Grid marginBottom={1}>
              <Typography variant="h3" textAlign="center">
                Campaign Staff
              </Typography>
            </Grid>
            <Grid marginBottom={1}>
              <Typography variant="body2" textAlign="center">
                {campaignStaff.length} Members
              </Typography>
            </Grid>
            <Grid marginBottom={2}>
              <SearchInput value={searchValue} onChange={onSearchChange} />
            </Grid>
          </Grid>
          <Grid className={classes.staffContainer}>
            <TableData dataSource={users} columns={COLUMNS} loading={loading} hideTitle />
          </Grid>
          <Grid item xs={12} container justifyContent="center" spacing={1}>
            <Button color="info" variant="contained" onClick={onAddButtonClick}>
              Edit Staff Members
            </Button>
          </Grid>
        </DialogContent>
      </div>
    </Dialog>
  );
};
