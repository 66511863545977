import React from 'react';
import { Grid, Typography, TypographyProps } from '@mui/material';

import type { User } from '@vizsla/graphql';
import { UserAvatar } from '@vizsla/components';

interface UserAvatarFullNameProps {
  user?: User | null | undefined;
  typographyVariant?: TypographyProps['variant'];
  additionalCaption?: React.ReactNode | string;
  fullName?: React.ReactNode | string;

  showEmail?: boolean;
}

export function UserAvatarFullName(props: UserAvatarFullNameProps) {
  const fullname = React.useMemo(() => props.user?.fullName, [props.user?.fullName]);
  const email = React.useMemo(() => props.user?.email ?? null, [props.user]);

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <UserAvatar user={props.user} />
      </Grid>

      <Grid item>
        <Grid>
          <Typography variant={props.typographyVariant}>{props.fullName}</Typography>

          {props.showEmail && email && <Typography variant="caption">{email}</Typography>}
        </Grid>

        <Grid>{props.additionalCaption}</Grid>
      </Grid>
    </Grid>
  );
}
