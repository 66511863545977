import React from 'react';
import { Today as TodayIcon } from '@mui/icons-material';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { DateTime } from 'luxon';

import { getStringifiedDateRange, isValidDate } from '@vizsla/utils';

const useStyles = makeStyles((theme: Theme) => ({
  calendar: {
    display: 'flex',
    '& .MuiSvgIcon-root': {
      marginRight: '8px',
    },
  },
  daysLeft: {
    fontWeight: theme.typography.fontWeightRegular,
    marginLeft: '10px',
  },
}));

const getDateRangeStr = (startDate: string, endDate?: string) => {
  if (!isValidDate(startDate) && !isValidDate(endDate)) {
    return '-';
  }

  return getStringifiedDateRange(startDate, endDate);
};

interface DataRangeProps {
  startDate: string;
  endDate?: string;
  className?: string;
}

export const CampaignDashboardDateRange: React.FC<DataRangeProps> = ({
  startDate,
  endDate,
  className,
}) => {
  const classes = useStyles();

  const renderDaysToGo = () => {
    const betweenDaysInterval = isValidDate(startDate)
      ? DateTime.now().until(DateTime.fromISO(startDate))
      : null;

    if (!betweenDaysInterval?.isValid) {
      return null;
    }

    const countInterval = betweenDaysInterval.count('days') - 1;

    return (
      <span className={classes.daysLeft}>
        {countInterval > 1 ? `${countInterval} Days` : `${countInterval} Day`} To Go
      </span>
    );
  };

  return (
    <Typography variant="h5" className={clsx(classes.calendar, className)}>
      <TodayIcon fontSize="medium" />
      <span>{getDateRangeStr(startDate, endDate)}</span>
      {renderDaysToGo()}
    </Typography>
  );
};
