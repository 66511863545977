import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tabs, Tab, Grid } from '@mui/material';

import { usePagination } from '@vizsla/hooks';

import { PeopleCRMTable } from 'src/components/peopleCRM';
import { PeopleCRMTabType } from 'src/types/peopleCRM';
import { ContactInfoModal, ContactAddModal } from 'src/modals';

type TabData = {
  key: PeopleCRMTabType;
  label: string;
};

const TAB_SEARCH_PARAM_KEY = 'tab';

const TABS: TabData[] = [
  {
    key: PeopleCRMTabType.attendees,
    label: 'ATTENDEES',
  },
  {
    key: PeopleCRMTabType.donors,
    label: 'DONORS',
  },
  {
    key: PeopleCRMTabType.fundraisers,
    label: 'FUNDRAISERS',
  },
  {
    key: PeopleCRMTabType.teams,
    label: 'TEAMS',
  },
  {
    key: PeopleCRMTabType.others,
    label: 'OTHERS',
  },
];

const defaultTab = TABS[0];

const PeopleCRMTabs: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pagination = usePagination();

  const [currentTab, setCurrentTab] = React.useState(
    TABS.find(tab => tab.key === searchParams.get(TAB_SEARCH_PARAM_KEY))?.key || defaultTab.key,
  );

  React.useEffect(() => {
    setCurrentTab(
      TABS.find(tab => tab.key === searchParams.get(TAB_SEARCH_PARAM_KEY))?.key || defaultTab.key,
    );
  }, [searchParams]);

  const handleTabChange = React.useCallback(
    (_, tabKey: PeopleCRMTabType) => {
      pagination.onPageChange(null, 0);
      setSearchParams({ [TAB_SEARCH_PARAM_KEY]: tabKey }, { replace: false });
    },
    [setSearchParams, pagination],
  );
  return (
    <React.Fragment>
      <ContactInfoModal tabType={currentTab} />
      <ContactAddModal />

      <Grid item>
        <Tabs value={currentTab} onChange={handleTabChange} centered>
          {TABS.map(tabData => (
            <Tab key={tabData.key} value={tabData.key} label={tabData.label} />
          ))}
        </Tabs>
      </Grid>

      <Grid item mt={4}>
        <PeopleCRMTable tabType={currentTab} />
      </Grid>
    </React.Fragment>
  );
};

export { PeopleCRMTabs };
