import React, { useMemo, useState, useCallback } from 'react';
import { Grid, LinearProgress, Tooltip, TextField as TField } from '@mui/material';
import { Field, Form, FormSpy, FormSpyRenderProps } from 'react-final-form';
import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import _ from 'lodash';
import moment from 'moment';
import { useQuery } from '@apollo/client';

import {
  Button,
  TextField,
  NumberField,
  SelectField,
  TimeField,
  CheckboxField,
  DateField,
  RadioGroupField,
  FormState,
} from '@vizsla/components';
import { DateFormatPatterns } from '@vizsla/constants';
import { toDateTimeFromISO, validateWithSchema } from '@vizsla/utils';
import { useNotification } from '@vizsla/hooks';
import { ExperienceGeneralSettingsListQuery } from '@vizsla/graphql';

import {
  useRegistrationOptionById,
  useRegistrationOptions,
  useRegistrationOptionPricingTiers,
} from 'src/hooks/registrationOption';
import { RichEditorField, SectionExpanderField } from 'src/components/forms';
import { UNITS_OF_MEASURE_OPTIONS } from 'src/constants/registrationOption';
import { useExperienceId, useExperienceById } from 'src/hooks/experiences';
import { RegistrationOptionEditBasicsFormSchema } from 'src/constants/validationSchemas/registrationOption';
import { PricingTiersField } from 'src/components/experiencePricing';
import { t } from 'src/utils/template';
import { getNewPricingTiersData, getPricingTiersToDeleteIds } from 'src/utils/pricingSettings';
import { PRICING_TYPE_SELECT_FIELD_OPTIONS } from 'src/constants/pricingSettings';

import { getDataToSubmit, getFormInitialValues } from './utils';

interface RegistrationOptionBasicsEditFormProps {
  registrationOptionId: string;
}

export const RegistrationOptionBasicsEditForm: React.FC<RegistrationOptionBasicsEditFormProps> = ({
  registrationOptionId,
}) => {
  const notification = useNotification();

  const experienceId = useExperienceId();
  const { experience, loading: experienceLoading } = useExperienceById(experienceId);

  const { registrationOptionsCount, updateRegistrationOption, updating } =
    useRegistrationOptions(experienceId);

  const { createNewPricingTiers, deletePricingTiers, creatingPricingTiers, deletingPricingTiers } =
    useRegistrationOptionPricingTiers();

  const waveOptions = React.useMemo(() => {
    if (_.isNil(registrationOptionsCount)) {
      return [];
    }
    return _.range(1, registrationOptionsCount + 1).map(number => ({
      label: number,
      value: number,
    }));
  }, [registrationOptionsCount]);

  const { registrationOption, loading } = useRegistrationOptionById(registrationOptionId);

  const initialValues = React.useMemo(() => {
    return getFormInitialValues({
      ...registrationOption,
      ageRequirementAsOfDate: experience?.startDate,
    });
  }, [experience?.startDate, registrationOption]);

  const [form, setForm] = useState<Record<string, any>>({});

  const ageRequirementAsOfDate = useMemo(() => {
    const age = parseInt(form?.ageRequirement || 0, 10);

    return moment(experience?.startDate || null)
      .subtract(age, 'years')
      .format('yyyy-MM-DD');
  }, [form]);

  const handleChanges = useCallback(
    (state: FormState) => {
      setForm(state.values);
    },
    // eslint-disable-next-line
    [experience],
  );

  const onSubmit = async (data: any, form: FormApi<any, any>) => {
    try {
      data.ageRequirementAsOfDate = moment(experience?.startDate || null).subtract(
        data?.ageRequirement,
        'years',
      );
      // .format('yyyy-mm-dd hh:mm');

      const formattedData = getDataToSubmit(data);

      const newPricingTiersData = getNewPricingTiersData(
        data,
        registrationOption?.pricingSettings?.id,
      );

      const pricingTiersToDeleteIds = getPricingTiersToDeleteIds(data);

      if (newPricingTiersData.length > 0) {
        await createNewPricingTiers(newPricingTiersData);
      }

      if (pricingTiersToDeleteIds.length > 0) {
        await deletePricingTiers(pricingTiersToDeleteIds);
      }

      await updateRegistrationOption(formattedData, registrationOptionId);

      form.reset();

      notification.success(t('registration_option_update_success'));
    } catch (error) {
      notification.error(t('registration_option_update_error'));
    }
  };

  const onCancel = (form: FormApi) => {
    form.reset();
  };

  if (loading || experienceLoading) {
    return <LinearProgress />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={formValues =>
        validateWithSchema(RegistrationOptionEditBasicsFormSchema, formValues, {
          context: { experience },
        })
      }
      mutators={{
        ...arrayMutators,
      }}
      render={({ form, handleSubmit, submitting, values, dirty, valid }) => {
        const isSubmitting = submitting || updating || creatingPricingTiers || deletingPricingTiers;
        const isCancelDisabled = !dirty;
        const isSubmittingDisabled = !valid;

        return (
          <form onSubmit={handleSubmit}>
            <FormSpy subscription={{ values: true }} onChange={handleChanges} />

            <Grid container spacing={2}>
              <Grid item xs={12} marginBottom={1} marginTop={1}>
                <Field
                  name="name"
                  label="Registration Name"
                  component={TextField}
                  fullWidth
                  disabled={isSubmitting}
                  required
                />
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={6}>
                  <Field name="distance" label="Distance" component={NumberField} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="unitOfMeasure"
                    label="Units"
                    component={SelectField}
                    options={UNITS_OF_MEASURE_OPTIONS}
                    disabled={isSubmitting}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="description"
                  label="Description"
                  component={RichEditorField}
                  placeholder="Description (Recommended)"
                  height={300}
                  disabled={isSubmitting}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <SectionExpanderField
                  isActive={values.ageRequirmentEnabled}
                  name="ageRequirmentEnabled"
                  heading="Age Requirement"
                  labelCollapsed="All ages allowed"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        name="ageRequirement"
                        label="Age Requirement"
                        component={NumberField}
                        min={0}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field name="ageRequirementAsOfDate" fullWidth>
                        {props => (
                          <div>
                            <TField
                              name={props.input.name}
                              value={ageRequirementAsOfDate}
                              onChange={props.input.onChange}
                              type="date"
                              fullWidth
                              required
                              disabled
                              label="As of a Date"
                            />
                          </div>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </SectionExpanderField>
              </Grid>

              <Grid item xs={12}>
                <SectionExpanderField
                  isActive={values.registrationLimitsEnabled}
                  name="registrationLimitsEnabled"
                  heading="Group Limitations"
                  tooltip="Registration Limits"
                  labelCollapsed="Unlimited"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        required
                        name="attendeesPerRegistration"
                        label="Attendees per Registration"
                        component={NumberField}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Field
                        required
                        name="maximumRegistrationPerOrder"
                        label="Maximum Registration per Order"
                        component={NumberField}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </SectionExpanderField>
              </Grid>

              {/* According to this issue this element must be removed and "registration limits" was renamed to "Group Limitations"
              https://8base-dev.atlassian.net/browse/VSL-1558
               <Grid item xs={12}>
                <SectionExpanderField
                  isActive={values.groupRegistrationEnabled}
                  name="groupRegistrationEnabled"
                  heading="Group Registration"
                  tooltip="Group Registration"
                  labelCollapsed="Not allowed"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        required
                        name="groupRegistrationMinimum"
                        label="Minimum"
                        component={NumberField}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Field
                        required
                        name="groupRegistrationMaximum"
                        label="Maximum"
                        component={NumberField}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </SectionExpanderField>
              </Grid> */}

              <Grid item xs={12}>
                <SectionExpanderField
                  isActive={values.registrationAvailabilityEnabled}
                  name="registrationAvailabilityEnabled"
                  heading="Registration Availability"
                  tooltip="Registration Availability"
                  labelCollapsed="All time available"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        required
                        name="registrationStartDate"
                        label="Start Date"
                        component={DateField}
                        inputFormat={DateFormatPatterns.shortMonthDayYear}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Field
                        name="registrationStartTime"
                        label="Start Time"
                        component={TimeField}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} marginTop={1}>
                    <Grid item xs={4}>
                      <FormSpy subscription={{ values: true }}>
                        {(spyRenderProps: FormSpyRenderProps) => {
                          const { registrationStartDate } = spyRenderProps.values;
                          const minEndDate = toDateTimeFromISO(registrationStartDate);

                          return (
                            <Field
                              name="registrationEndDate"
                              label="End Date"
                              component={DateField}
                              inputFormat={DateFormatPatterns.shortMonthDayYear}
                              minDate={minEndDate}
                              required
                              fullWidth
                            />
                          );
                        }}
                      </FormSpy>
                    </Grid>

                    <Grid item xs={4}>
                      <Field
                        name="registrationEndTime"
                        label="End Time"
                        component={TimeField}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </SectionExpanderField>
              </Grid>

              <Grid item xs={12}>
                <SectionExpanderField
                  isActive={values.attendeesCapEnabled}
                  name="attendeesCapEnabled"
                  heading="Attendee Cap"
                  labelCollapsed="Unlimited"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        name="maximumAttendees"
                        label="Maximum Attendees"
                        component={NumberField}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </SectionExpanderField>
              </Grid>

              <Grid item xs={12}>
                <SectionExpanderField
                  isActive={values.pricingSettings.pricingEnabled}
                  name="pricingSettings.pricingEnabled"
                  heading="Pricing"
                  labelCollapsed="Free"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        name="pricingSettings.pricingType"
                        component={RadioGroupField}
                        containerOptions={{ direction: 'row', container: true }}
                        options={PRICING_TYPE_SELECT_FIELD_OPTIONS}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="pricingSettings.pricingTiers"
                        component={PricingTiersField}
                        mutators={form.mutators}
                        variant={values.pricingSettings.pricingType}
                      />
                    </Grid>
                    {/* This section is disabled by current business logic */}
                    {/* <Grid item xs={12}>
                      <Field
                        name="pricingSettings.allowCostToBeFundraisingCredit"
                        label="Allow Registration Cost to be Fundraising Credit."
                        component={CheckboxField}
                      />
                    </Grid> */}
                  </Grid>
                </SectionExpanderField>
              </Grid>

              <Grid item xs={12} container justifyContent="center" spacing={1}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => onCancel(form)}
                  disabled={isCancelDisabled}
                >
                  Cancel
                </Button>
                <Button onClick={form.submit} loading={updating} disabled={isSubmittingDisabled}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};
