import React from 'react';

import * as appRoutes from 'src/constants/routes';

import { DrawerSidebar } from '../../components/layout/drawer';

const TOP_MENU_ITEMS = [
  {
    title: 'Organizations',
    icon: 'Domain',
    path: appRoutes.adminOrganizations,
  },
];

const BOTTOM_MENU_ITEMS = [
  {
    title: 'Settings',
    icon: 'Settings',
    path: appRoutes.adminSettings,
  },
];

export const Sidebar: React.FC = () => {
  return <DrawerSidebar upperItems={TOP_MENU_ITEMS} lowerItems={BOTTOM_MENU_ITEMS} />;
};
