import React from 'react';
import _ from 'lodash';
import { Grid, Typography } from '@mui/material';
import {
  Add,
  PermContactCalendarOutlined as PermContactCalendarOutlinedIcon,
} from '@mui/icons-material';

import { useModal } from '@vizsla/hooks';
import { TableColumn, TableRowAction, TableData } from '@vizsla/components';
import { ExperienceBibPool, RegistrationOption } from '@vizsla/graphql';
import { formatNumber } from '@vizsla/utils';

import { useExperienceId, useExperienceBibAssignment } from 'src/hooks/experiences';
import { useRegistrationOptions } from 'src/hooks/registrationOption';
import { EllipsisTypography } from 'src/components/shared';
import { MODALS } from 'src/constants/modals';
import { getRemainingBibPoolNumbers } from 'src/utils/bibAssignment';

type TableAllocationProps = {
  allocationType: string;
  registrationOption: RegistrationOption;
};

export const CampaignExperienceBibPoolTable: React.FC = () => {
  const experienceId = useExperienceId();

  const { openModal } = useModal();
  const { loading: registrationOptionsLoading } = useRegistrationOptions(experienceId);
  const {
    bibPoolsList,
    deleteBibPool,
    loading,
    deletingPool,
    bibAssignmentsList,
    poolBibAssignments,
  } = useExperienceBibAssignment(experienceId);

  const canEditPools = _.isEmpty(poolBibAssignments);

  const onDeleteBibPool = bibPool => {
    if (bibPool) {
      openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
        deleteFunction: () => deleteBibPool(bibPool.id),
        objectName: bibPool.name,
      });
    }
  };

  const onEditBibPool = bibPool => {
    if (bibPool) {
      openModal(MODALS.BIB_POOL_UPDATE_MODAL, { bibPool });
    }
  };

  const COLUMNS: TableColumn<ExperienceBibPool>[] = React.useMemo(
    () => [
      {
        title: 'Name',
        key: 'name',
        dataPath: 'name',
        render: (bibPoolName: string) => {
          return (
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <PermContactCalendarOutlinedIcon />
              </Grid>
              <Grid item>
                <EllipsisTypography variant="subtitle2" maxWidth={300}>
                  {bibPoolName}
                </EllipsisTypography>
              </Grid>
            </Grid>
          );
        },
      },
      {
        title: 'Starting #',
        key: 'startNumbers',
        dataPath: 'bibRanges',
        render: (bibRanges: Array<string>) => {
          const startNumbers = bibRanges?.map(bibRange => Number(bibRange.split('-')[0]));
          return (
            <Grid container direction="column" spacing={1}>
              {startNumbers?.map(startNumber => (
                <Grid key={startNumber} item>
                  <Typography variant="body2">{formatNumber(startNumber)}</Typography>
                </Grid>
              ))}
            </Grid>
          );
        },
      },
      {
        title: 'Ending #',
        key: 'endNumbers',
        dataPath: 'bibRanges',
        render: (bibRanges: Array<string>) => {
          const endNumbers = bibRanges?.map(bibRange => Number(bibRange.split('-')[1]));
          return (
            <Grid container direction="column" spacing={1}>
              {endNumbers?.map(endNumber => (
                <Grid key={endNumber} item>
                  <Typography variant="body2">{formatNumber(endNumber)}</Typography>
                </Grid>
              ))}
            </Grid>
          );
        },
      },
      {
        title: 'Remaining',
        key: 'remaining',
        dataPath: 'bibRanges',
        render: (bibRanges: Array<string>) => {
          const remainingCounter = getRemainingBibPoolNumbers(bibRanges, bibAssignmentsList);

          return (
            <Grid container direction="column" spacing={1}>
              {bibRanges?.map(bibRange => (
                <Grid key={bibRange} item>
                  <Typography variant="body2">{remainingCounter[bibRange]}</Typography>
                </Grid>
              ))}
            </Grid>
          );
        },
      },
      {
        title: 'Allocation',
        key: 'allocation',
        render: ({ allocationType, registrationOption }: TableAllocationProps) => {
          if (registrationOption) {
            return registrationOption.name;
          }
          return allocationType;
        },
      },
    ],
    [bibAssignmentsList],
  );

  const ROW_ACTIONS: Array<TableRowAction<ExperienceBibPool>> = [
    {
      actionName: 'Edit',
      onAction: bibPool => onEditBibPool(bibPool),
    },
    {
      actionName: 'Delete',
      onAction: bibPool => onDeleteBibPool(bibPool),
    },
  ];

  const BOTTOM_ACTIONS = [
    {
      icon: <Add />,
      actionName: ' Add Bib Pool',
      onAction: () => openModal(MODALS.BIB_POOL_CREATE_MODAL),
    },
  ];

  return (
    <TableData
      loading={loading || registrationOptionsLoading || deletingPool}
      columns={COLUMNS}
      dataSource={bibPoolsList}
      bottomActions={canEditPools ? BOTTOM_ACTIONS : undefined}
      rowActions={canEditPools ? ROW_ACTIONS : undefined}
    />
  );
};
