import React from 'react';
import { makeStyles } from '@mui/styles';

import { DrawerProvider } from 'src/providers/DrawerProdiver';

import { Sidebar } from './Sidebar';
import { Header } from './Header';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    backgroundColor: '#fff',
    display: 'grid',
    gridTemplateAreas: '"sidebar header" "sidebar content"',
    gridTemplateRows: '64px 1fr',
    gridTemplateColumns: 'auto 1fr',
    color: '#34b9ff',
  },
  content: {
    height: 'calc(100vh - 64px)',
    gridArea: 'content',
    overflow: 'auto',
    padding: '16px 16px 0',

    '&::after': {
      display: 'block',
      content: '""',
      height: 10,
      width: '100%',
    },
  },
}));

export const AdminLayout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <DrawerProvider>
      <div className={classes.root}>
        <Sidebar />
        <Header />
        <main className={classes.content}>{children}</main>
      </div>
    </DrawerProvider>
  );
};
