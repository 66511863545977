import { gql } from '@apollo/client';

export const ATTENDEE_USER_FRAGMENT = gql`
  fragment ATTENDEE_USER_FRAGMENT on User {
    id
    firstName
    lastName
    email

    avatar {
      downloadUrl
    }
  }
`;

export const CLIENT_ATTENDEE_FRAGMENT = gql`
  ${ATTENDEE_USER_FRAGMENT}

  fragment CLIENT_ATTENDEE_FRAGMENT on Attendee {
    id

    user {
      ...ATTENDEE_USER_FRAGMENT
    }

    experience {
      id
      name
    }

    registrationOption {
      id
      name
    }

    teamMembership {
      role

      team {
        id
        name
      }
    }

    fundraising {
      id
      goal
      createdAt

      allocatedDonations(groupBy: { query: { amount: { as: "raised", fn: { aggregate: SUM } } } }) {
        groups {
          raised: Float
        }
      }
    }
  }
`;

export const ATTENDEES_LIST_QUERY = gql`
  ${CLIENT_ATTENDEE_FRAGMENT}

  query AttendeesList($filter: AttendeeFilter, $skip: Int, $first: Int) {
    attendees: attendeesList(filter: $filter, skip: $skip, first: $first) {
      count

      items {
        ...CLIENT_ATTENDEE_FRAGMENT
      }
    }
  }
`;

export const INDIVIDUAL_FUNDRAISERS_QUERY = gql`
  query IndividualFundraiser($filter: IndividualFundraisingFilter, $skip: Int, $first: Int) {
    fundraisers: individualFundraisingsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        goal
        createdAt

        allocatedDonations(
          groupBy: { query: { amount: { as: "raised", fn: { aggregate: SUM } } } }
        ) {
          groups {
            raised: Float
          }
        }

        attendee {
          id

          experience {
            name
          }

          registrationOption {
            name
          }

          teamMembership {
            team {
              name
            }
          }

          user {
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

export const ATTENDEES_NAMES_LIST_QUERY = gql`
  query AttendeesNamesList($filter: AttendeeFilter) {
    attendeesList(filter: $filter, first: 10) {
      items {
        id
        user {
          id
          lastName
          firstName
          email
        }
        experience {
          id
        }
      }
    }
  }
`;
