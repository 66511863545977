import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';

import {
  CampaignDonationFundraising,
  CampaignFundraisingSettingListResponse,
  CampaignFundraisingSettingUpdateInput,
  CampaignGeneralFundraising,
  CampaignIndividualFundraising,
  CampaignTeamFundraising,
} from '@vizsla/graphql';

import {
  CAMPAIGN_FUNDRAISING_SETTINGS_CREATE_MUTATION,
  CAMPAIGN_FUNDRAISING_SETTINGS_QUERY,
  CAMPAIGN_FUNDRAISING_SETTINGS_UPDATE_MUTATION,
} from 'src/graphql';
import { CampaignFundraisingSettingsTypes } from 'src/types/campaign';

enum FundraisingSettingsActions {
  create = 'create',
  update = 'update',
}

interface SetSettingsInput {
  settingsType: CampaignFundraisingSettingsTypes;
  data: CampaignFundraisingSettingUpdateInput;
}

type CampaignFundraisingSettingsOutput = {
  donationSettings: CampaignDonationFundraising | null;
  generalSettings: CampaignGeneralFundraising | null;
  teamSettings: CampaignTeamFundraising | null;
  individualSettings: CampaignIndividualFundraising | null;
  setSettings: ({ settingsType, data }: SetSettingsInput) => Promise<{ success: boolean }>;
  loading: boolean;
};

export const useCampaignFundraisingSettings = (
  campaignId: string,
): CampaignFundraisingSettingsOutput => {
  const { data, loading: settingsLoading } = useQuery<{
    campaignFundraisingSettingsList: CampaignFundraisingSettingListResponse;
  }>(CAMPAIGN_FUNDRAISING_SETTINGS_QUERY, {
    variables: {
      filter: {
        campaign: {
          id: {
            equals: campaignId,
          },
        },
      },
    },
    skip: !campaignId,
  });

  const [createSettings, { loading: creatingSettings }] = useMutation(
    CAMPAIGN_FUNDRAISING_SETTINGS_CREATE_MUTATION,
    {
      refetchQueries: ['FundraisingSettingsByCampaignId'],
      awaitRefetchQueries: true,
    },
  );

  const [updateSettings, { loading: updatingSettings }] = useMutation<{
    updateData: CampaignFundraisingSettingUpdateInput;
    settingsId: string;
  }>(CAMPAIGN_FUNDRAISING_SETTINGS_UPDATE_MUTATION, {
    refetchQueries: ['FundraisingSettingsByCampaignId'],
    awaitRefetchQueries: true,
  });

  const fundraisingSettings = data?.campaignFundraisingSettingsList?.items[0] || {};
  const campaignHasSettings = !_.isEmpty(fundraisingSettings);

  const {
    donationSettings = null,
    generalSettings = null,
    teamSettings = null,
    individualSettings = null,
  } = fundraisingSettings;

  const getMutationAction = (
    settingsType: CampaignFundraisingSettingsTypes,
  ): FundraisingSettingsActions => {
    const campaignHasCurrentSettings = fundraisingSettings && fundraisingSettings[settingsType];

    if (campaignHasCurrentSettings) {
      return FundraisingSettingsActions.update;
    }

    return FundraisingSettingsActions.create;
  };

  const setSettings = async ({ settingsType, data }: SetSettingsInput) => {
    if (campaignHasSettings) {
      const action = getMutationAction(settingsType);

      try {
        await updateSettings({
          variables: {
            settingsId: fundraisingSettings?.id,
            updateData: {
              [settingsType]: {
                [action]: {
                  ...data,
                },
              },
            },
          },
        });

        return { success: true };
      } catch (e) {
        console.error({ e });
        return { success: false };
      }
    }

    if (!campaignHasSettings) {
      try {
        await createSettings({
          variables: {
            createData: {
              campaign: {
                connect: {
                  id: campaignId,
                },
              },
              [settingsType]: {
                create: {
                  ...data,
                },
              },
            },
          },
        });

        return { success: true };
      } catch (e) {
        console.error({ e });
        return { success: false };
      }
    }

    return { success: false };
  };

  const loading = settingsLoading || updatingSettings || creatingSettings;

  return {
    donationSettings,
    generalSettings,
    teamSettings,
    individualSettings,
    setSettings,
    loading,
  };
};
