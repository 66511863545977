import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  BillingInformationPaperBlock,
  PaymentDetailsPaperBlock,
  TransactionsDetailsPaperBlock,
} from '@vizsla/components';

import { useSelectedTransactionRow } from 'src/hooks/transactions';

import {
  CardsContent,
  LinkTransactions,
  Title,
  WrapperPaperBlocks,
  WrapperTitle,
} from './TransactionsRegistration.style';
import { TransactionRegistrationCard } from '../TransactionsCards/TransactionRegistrationCard';
import { TransactionsCampaignCard } from '../TransactionsCards/TransactionsCampaignCard';
import { TransactionsAttendeeCard } from '../TransactionsCards/TransactionsAttendee';
import { CampaignDetails } from '../CampaignDetails';

export function TransactionsRegistration() {
  const navigate = useNavigate();
  const { data: dataTransaction } = useSelectedTransactionRow();

  return (
    <React.Fragment>
      <WrapperTitle>
        <Title>Transaction ID: {dataTransaction?.id?.toUpperCase()}</Title>
        <LinkTransactions onClick={() => navigate(-1)} underline="hover" variant="subtitle2">
          View All Transactions
        </LinkTransactions>
      </WrapperTitle>
      <CardsContent>
        <TransactionRegistrationCard />
        <TransactionsAttendeeCard />
        <TransactionsCampaignCard />
      </CardsContent>
      <WrapperPaperBlocks>
        <TransactionsDetailsPaperBlock />
        <CampaignDetails />
        <PaymentDetailsPaperBlock />
        <BillingInformationPaperBlock />
      </WrapperPaperBlocks>
    </React.Fragment>
  );
}
