import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@apollo/client';

import { useCurrentUser, useModal } from '@vizsla/hooks';

import { MODALS } from 'src/constants/modals';
import { STRIPE_PAYMENT_METHODS, STRIPE_PAYMENT_METHODS_INACTIVE } from 'src/graphql/stripe';

export const InactivePaymentMethod = () => {
  const { user } = useCurrentUser();
  const userId = React.useMemo(() => user?.id, [user]);
  const { isOpen, closeModal, args } = useModal(
    MODALS.ORGANIZATION_SETTING_INACTIVE_PAYMENT_METHOD,
  );
  const [loadingButton, setLoadingButton] = React.useState<boolean>(false);
  const paymentMethodId = React.useMemo(() => args?.paymentMethodId || '', [args]);
  const [paymentMethodInactive] = useMutation(STRIPE_PAYMENT_METHODS_INACTIVE);
  const handleCancel = () => {
    closeModal();
  };

  const handlerInactive = async paymentMethodId => {
    try {
      setLoadingButton(true);
      await paymentMethodInactive({
        variables: {
          userId,
          paymentMethodId,
        },
        refetchQueries: [{ query: STRIPE_PAYMENT_METHODS, variables: { userId: String(userId) } }],
        awaitRefetchQueries: true,
      });
      setLoadingButton(false);
      closeModal();
      return paymentMethodId;
    } catch (error) {
      console.error(error);
      closeModal();
      return '';
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={handleCancel}>
      <DialogTitle>Inactive Billing Method</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          This actions is permanent, select &quot;Inactive&quot; to confirm, &quot;Cancel&quot; to
          stop this action.
        </DialogContentText>

        <DialogActions>
          <Button disabled={loadingButton} onClick={handleCancel} color="secondary">
            Cancel
          </Button>

          <LoadingButton
            loading={loadingButton}
            disabled={loadingButton}
            variant="contained"
            onClick={() => {
              handlerInactive(paymentMethodId);
            }}
          >
            Inactive
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
