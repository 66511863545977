import React from 'react';
import { Form } from 'react-final-form';
import { Grid, Skeleton, SvgIcon, Typography } from '@mui/material';
import {
  PeopleAltOutlined as PeopleAltOutlinedIcon,
  GroupAddOutlined as GroupAddOutlinedIcon,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { FormApi } from 'final-form';

import { validateHtmlTextLength, validateWithSchema } from '@vizsla/utils';
import { Field, Button, NumberField } from '@vizsla/components';

import { useCampaignFundraisingSettings, useCampaignId } from 'src/hooks/campaign';
import { PaperBlock } from 'src/components/shared';
import { RichEditorField, SwitchCardField, TileRadioGroupField } from 'src/components/forms';
import { FontSize, PaletteColor } from 'src/theme';
import { BASE_NUMBER_FORMAT } from 'src/constants/formats';
import { CampaignTeamFundraisingSettingsSchema } from 'src/constants/validationSchemas/campaign';
import { CampaignFundraisingSettingsTypes } from 'src/types/campaign';
import { ReactComponent as GroupIcon } from 'src/assets/img/groups_black.svg';
import { TeamCapabilities } from 'src/types/experience';

const useStyles = makeStyles(() => ({
  cardText: {
    color: PaletteColor.BlackText,
  },
  checkboxField: {
    fontSize: FontSize.Default,
    color: PaletteColor.BlackText,
  },
  checkboxStyle: {
    paddingBottom: '4px',
    paddingTop: '4px',
  },
  messageLabelHeader: {
    fontSize: FontSize.Default,
    fontWeight: 900,
    lineHeight: '20px',
  },
  messageLabelBody: {
    fontSize: FontSize.Default,
    lineHeight: '25px',
  },
}));

const INITIAL_APPEAL_MESSAGE = `<p>Family and friends,</p>
<p>Please help me to support organization by making a donation to my fundraising page for campaign-name. Even a small donation will help me achieve my goal of goal-amount and my team, (team page), goal of team-goal-amount.</p>`;

const INITIAL_THANK_YOU_MESSAGE = `<p>Hi first_name,</p>
<p>Thank you for donating to organization through my teams’s fundraing page for campaign-name. Feel free to check back on my fundraising progress as I intent to reach my goal.</p>`;

const RADIO_OPTIONS = [
  {
    label: 'Can Join or Create a Team',
    value: TeamCapabilities.CanJoinOrCreate,
    innerIcon: <PeopleAltOutlinedIcon fontSize="large" />,
  },
  {
    label: 'Require to Join or Create a Team',
    value: TeamCapabilities.RequireJoinOrCreate,
    innerIcon: <PeopleAltOutlinedIcon fontSize="large" />,
  },
];

type CampaignTeamFundraisingSettingsProps = {
  experienceId?: string;
  useFundraisingSettings?: (value: string) => any;
};

export const CampaignTeamFundraisingSettings: React.FC<CampaignTeamFundraisingSettingsProps> = ({
  experienceId,
  useFundraisingSettings = useCampaignFundraisingSettings,
}) => {
  const classes = useStyles();

  const campaignId = useCampaignId();

  const entityId = experienceId ?? campaignId;
  const { teamSettings, setSettings, loading } = useFundraisingSettings(entityId);

  const INITIAL_VALUES = {
    isEnabled: teamSettings?.isEnabled ?? true,
    teamCapabilities: teamSettings?.teamCapabilities ?? TeamCapabilities.CanJoinOrCreate,
    joiningTeamEnabled: teamSettings?.joiningTeamEnabled ?? true,
    allowTeamsMakeTheirTeamInvite: false,
    allowAttendeeRequestAdmissionOntoTeam: false,
    maximumTeams: teamSettings?.maximumTeams,
    maximumTeamMembers: teamSettings?.maximumTeamMembers,
    teamSharingEnabled: teamSettings?.teamSharingEnabled ?? false,
    appealMessage: teamSettings?.appealMessage ?? INITIAL_APPEAL_MESSAGE,
    thankYouMessage: teamSettings?.thankYouMessage ?? INITIAL_THANK_YOU_MESSAGE,
  };

  const onSubmit = async (data: any) => {
    await setSettings({
      settingsType: CampaignFundraisingSettingsTypes.teamSettings,
      data: { maximumTeamMembers: null, maximumTeams: null, ...data },
    });
  };

  const onResetForm = (form: FormApi) => {
    form.reset();
  };

  const renderJoiningTeamCard = (checked: boolean) => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Field
            name="maximumTeams"
            label="Maximum Teams"
            disabled={!checked}
            component={NumberField}
            numberFormat={BASE_NUMBER_FORMAT}
            helperText="Leave blank for Unlimited"
            placeholder="Unlimited"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="maximumTeamMembers"
            label="Maximum Team Members"
            disabled={!checked}
            component={NumberField}
            numberFormat={BASE_NUMBER_FORMAT}
            helperText="Leave blank for Unlimited"
            placeholder="Unlimited"
            fullWidth
          />
        </Grid>
      </Grid>
    );
  };

  const renderTeamSharingCard = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.cardText}>
            Provide your Team Captains ability to allocate funds that have been donated to the team
            account rather than an individual account. Funds can can not be allocated outside of a
            team.
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <PaperBlock title="Team Settings" collapsing defaultOpened>
      <Form
        initialValues={INITIAL_VALUES}
        onSubmit={onSubmit}
        validate={values => validateWithSchema(CampaignTeamFundraisingSettingsSchema, values)}
        onResetForm={onResetForm}
      >
        {({ submitting, pristine, handleSubmit, form }) => {
          return (
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {loading ? (
                    <Skeleton height={710} variant="rectangular" />
                  ) : (
                    <Grid container direction="column" spacing={4}>
                      {/* This section is disabled by current business logic */}
                      {/* <Grid item>
                        <Field
                          name="teamCapabilities"
                          options={RADIO_OPTIONS}
                          component={TileRadioGroupField}
                          gridContainerProps={{
                            container: true,
                            direction: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            spacing: 2,
                          }}
                          height="96px"
                          fullWidth
                        />
                      </Grid> */}
                      <Grid item>
                        <Grid container alignItems="stretch" spacing={2}>
                          <Grid item xs={12} xl={6}>
                            <Field
                              name="joiningTeamEnabled"
                              title="Joining a Team"
                              image={<GroupAddOutlinedIcon />}
                              component={SwitchCardField}
                              renderContent={renderJoiningTeamCard}
                              style={{ height: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} xl={6}>
                            <Field
                              name="teamSharingEnabled"
                              title="Team Sharing"
                              image={<SvgIcon component={GroupIcon} />}
                              component={SwitchCardField}
                              renderContent={renderTeamSharingCard}
                              style={{ height: '100%' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography component="p" className={classes.messageLabelHeader}>
                                  Appeal Message
                                </Typography>
                                <Typography component="p" className={classes.messageLabelBody}>
                                  Create a message for your fundraisers to send to supporters. Your
                                  fundraisers can always edit upon creating a fundraising page.
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  name="appealMessage"
                                  label="Appeal message"
                                  component={RichEditorField}
                                  validate={value => validateHtmlTextLength(value, 1, 500)}
                                  maxTextLength={500}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            {/* This section is disabled by current business logic */}
                            {/* <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography component="p" className={classes.messageLabelHeader}>
                                  Thank You Message
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  name="thankYouMessage"
                                  label="Thank you message"
                                  component={RichEditorField}
                                  validate={value => validateHtmlTextLength(value, 1, 500)}
                                  maxTextLength={500}
                                />
                              </Grid>
                            </Grid> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Button
                        disabled={submitting || pristine}
                        variant="text"
                        onClick={() => onResetForm(form)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button loading={submitting} disabled={pristine} onClick={handleSubmit}>
                        {pristine ? 'Saved' : 'Save'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Form>
    </PaperBlock>
  );
};
