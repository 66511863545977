import React from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FileInput } from '8base-react-sdk';

import { File } from '@vizsla/graphql';

import { ExperienceImageLayout } from './ExperienceImageLayout';

interface ExperienceAddImageProps {
  file?: File;
  fetching: boolean;
  updating: boolean;

  attach: (file: File) => Promise<void>;
}

const DEFAULT_MAX_IMAGE_SIZE = 8 * 1024 * 1024;

const useStyles = makeStyles({
  button: {
    margin: 0,
  },
});

export function ExperienceAddImage(props: ExperienceAddImageProps) {
  const styles = useStyles();

  const attachImage = async (file: File | File[]) => {
    const payload: File | undefined = Array.isArray(file) ? file.at(0) : file;

    if (payload) {
      await props.attach(payload);
    }
  };

  const openExplorer = async (picker: (opts: any) => Promise<void>) => {
    await picker({
      maxSize: DEFAULT_MAX_IMAGE_SIZE,
      accept: ['image/jpeg', 'image/png', 'image/webp'],
      fromSources: ['local_file_system'],
    });
  };

  return (
    <ExperienceImageLayout image={props.file} loading={props.updating}>
      <FileInput onChange={attachImage}>
        {wrapper => (
          <Grid item>
            <Button
              className={styles.button}
              disabled={props.fetching || props.updating}
              startIcon={<AddIcon />}
              onClick={() => openExplorer(wrapper.pick)}
            >
              Add Image
            </Button>
          </Grid>
        )}
      </FileInput>
    </ExperienceImageLayout>
  );
}
