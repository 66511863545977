import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { Form, Field } from 'react-final-form';
import { useMutation } from '@apollo/client';

import { useModal, useApolloCacheQueryManager, useNotification } from '@vizsla/hooks';
import { validateWithSchema, mutableList } from '@vizsla/utils';
import { Button, TextField } from '@vizsla/components';
import {
  ExperienceGeneralSettingsListQuery,
  ExperienceGeneralSettingsListQueryVariables,
} from '@vizsla/graphql';

import { useExperienceId } from 'src/hooks/experiences';
import { MODALS } from 'src/constants/modals';
import { CloseModalButton } from 'src/components/shared';
import {
  EXPERIENCE_GENERAL_SETTINGS_LIST_QUERY,
  EXPERIENCE_VENUE_CREATE_MUTATION,
  EXPERIENCE_VENUE_UPDATE_MUTATION,
} from 'src/graphql';
import { ExperienceVenueSchema } from 'src/constants/validationSchemas/experience';

enum QueryType {
  create,
  update,
}

const ExperienceVenueModal: React.FC = () => {
  const experienceId = useExperienceId();
  const notification = useNotification();
  const { isOpen, closeModal, args } = useModal(MODALS.EXPERIENCE_VENUE_CREATE_MODAL);

  const queryType = args?.initialValues ? QueryType.update : QueryType.create;

  const query =
    queryType === QueryType.update
      ? EXPERIENCE_VENUE_UPDATE_MUTATION
      : EXPERIENCE_VENUE_CREATE_MUTATION;

  const [saveVenue, { loading: uploading }] = useMutation(query);

  const { updateQuery: updateExperienceGeneralSettingsQuery } = useApolloCacheQueryManager<
    ExperienceGeneralSettingsListQuery,
    ExperienceGeneralSettingsListQueryVariables
  >({
    query: EXPERIENCE_GENERAL_SETTINGS_LIST_QUERY,
    typeName: 'Experience',
    variables: {
      id: experienceId,
    },
  });

  const createVenue = async venue => {
    const { data } = await saveVenue({
      variables: {
        data: {
          ...venue,
          experience: {
            connect: {
              id: args?.experienceId,
            },
          },
        },
      },
    });
    const createdVenue = data?.experienceVenueCreate;

    if (createdVenue) {
      updateExperienceGeneralSettingsQuery(query => {
        if (query?.experience?.venues?.items) {
          mutableList(query.experience.venues.items).add(createdVenue);
        }
      });
    }
  };

  const updateVenue = async (venueId: string, venue: unknown) => {
    await saveVenue({
      variables: {
        data: venue,
        filter: {
          id: venueId,
        },
      },
    });
  };

  const onClose = () => {
    closeModal();
  };

  const onCancel = (reset: () => void) => {
    reset();
    closeModal();
  };

  const onSubmit = async ({ id, __typename, ...venue }: any) => {
    try {
      if (queryType === QueryType.create) {
        await createVenue(venue);
        notification.success('Created Venue');
        closeModal();
      } else {
        await updateVenue(id, venue);
        notification.success('Updated Venue');
        closeModal();
      }

      closeModal();
    } catch (error) {
      notification.error('Error trying action');
      console.error(error);
      closeModal();
    }
  };

  return (
    <Dialog fullWidth draggable open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Box my={3} display="flex" justifyContent="center" alignItems="center">
          <Box position="absolute" right={1} mr={3}>
            <CloseModalButton onClick={closeModal} />
          </Box>
          <Typography position="relative" variant="h3">
            {queryType === QueryType.create ? 'Add' : 'Edit'} Venue
          </Typography>
        </Box>
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        initialValues={args?.initialValues}
        validate={values => validateWithSchema(ExperienceVenueSchema, values)}
      >
        {({ handleSubmit, form, pristine }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field component={TextField} name="name" label="Name *" fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <Field component={TextField} name="address" label="Address *" fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <Field component={TextField} name="notes" label="Notes" fullWidth />
                  </Grid>
                  <Grid container item xs={12} justifyContent="center">
                    <Grid item>
                      <Button
                        variant="text"
                        disabled={pristine}
                        onClick={() => onCancel(form.reset)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button loading={uploading} disabled={uploading || pristine} type="submit">
                        {queryType === QueryType.create ? 'Add' : 'Save'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
            </form>
          );
        }}
      </Form>
    </Dialog>
  );
};

export default ExperienceVenueModal;
