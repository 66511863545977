import React from 'react';
import { Typography } from '@mui/material';

import { useModal } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';

import { ExperienceIndividualBibCreateForm } from './components';

const ExperienceIndividualBibCreateModal: React.FC = () => {
  const { isOpen, closeModal } = useModal(MODALS.INDIVIDUAL_BIB_CREATE_MODAL);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth customClasses={[`maxWidth: 'sm'`]}>
      <Modal.Title>
        <Typography variant="h3" align="center">
          Add Individual Bib
        </Typography>
        <Typography variant="body2" align="center" paddingTop={1}>
          Assign a bib to an attendee
        </Typography>
      </Modal.Title>
      <Modal.Content>
        <ExperienceIndividualBibCreateForm />
      </Modal.Content>
    </Modal>
  );
};

export default ExperienceIndividualBibCreateModal;
