import { BibBatchAssignSort, BibSortOrder, BibAutoAssignmentStatus } from 'src/types/bibAssignment';

export const ASSIGNMENT_ORDER_OPTIONS = [
  {
    label: 'By Registration Date, and Time',
    value: BibBatchAssignSort.RegistrationDateTime,
  },
  {
    label: 'By Last Name, First Name, Address',
    value: BibBatchAssignSort.LastNameFirstNameAddress,
  },
  {
    label: 'By First Name, Last Name, Address',
    value: BibBatchAssignSort.FirstNameLastNameAddress,
  },
  {
    label: 'By Age, Last Name, First Name',
    value: BibBatchAssignSort.AgeLastNameFirstName,
  },
  {
    label: 'By Fundraising Amount',
    value: BibBatchAssignSort.FundraisingAmount,
  },
];

export const ASSIGNMENT_DIRECTION_OPTIONS = [
  {
    label: 'Ascending',
    value: BibSortOrder.ASC,
  },
  {
    label: 'Descending',
    value: BibSortOrder.DESC,
  },
];

export const BIB_AUTO_STATUS_LABELS = {
  [BibAutoAssignmentStatus.Inactive]: 'Inactive',
  [BibAutoAssignmentStatus.Scheduled]: 'Scheduled',
  [BibAutoAssignmentStatus.InProgress]: 'In Progress',
  [BibAutoAssignmentStatus.Completed]: 'Completed',
};
