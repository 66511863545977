import React from 'react';
import { Avatar, Typography } from '@mui/material';
import { green, grey, red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

import { TableColumn, TableData } from '@vizsla/components';
import { buildUrl, formatDate, formatMoney } from '@vizsla/utils';
import { DateFormatPatterns, RouterEntitiesId } from '@vizsla/constants';

import { useSelectedTransactionRow } from 'src/hooks/transactions';
import * as appRoutes from 'src/constants/routes';

const defaultLabel = 'N/A';

const columns: Array<TableColumn> = [
  {
    title: 'Date',
    key: 'date',
    dataPath: 'OrderDate',
    render: (date: string) => {
      return formatDate(date, DateFormatPatterns.shortMonthDayYear);
    },
  },
  {
    title: 'Status',
    key: 'status',
    render: (_, row) => {
      const colors = {
        Completed: green[400],
        default: red[400],
        [defaultLabel]: grey[400],
      };
      const status = row.OrderStatus ?? defaultLabel;
      const bgcolor = colors[status] ?? colors.default;
      const label = status !== defaultLabel ? status.substring(0, 1).toUpperCase() : defaultLabel;
      return (
        <Avatar sx={{ bgcolor, width: 24, height: 24 }} alt={status}>
          <Typography fontSize={11} color="white">
            {label}
          </Typography>
        </Avatar>
      );
    },
  },
  {
    title: 'type',
    key: 'type',
    render: (_, row) => row.OrderPaymentMethod,
  },
  {
    title: 'SubType',
    key: 'subType',
    render: (_, row) => row.Ordersubtype,
  },
  {
    title: 'Name',
    key: 'name',
    render: (_, row) => {
      return `${row.UsersFirsName} ${row.UsersLastName}`;
    },
  },
  {
    title: 'Amount',
    key: 'amount',
    dataPath: 'OrderItemsAmount',
    render: (amount: number) => formatMoney(amount, 2),
  },
];

export function TransactionsTableView({ transactions, pagination, loading }) {
  const navigate = useNavigate();
  const { setData: setTransactionData } = useSelectedTransactionRow();

  return (
    <TableData
      dataSource={transactions}
      columns={columns}
      pagination={pagination}
      rowActions={[]}
      loading={loading}
      onRowClick={dataRow => {
        if (dataRow.Ordersubtype === 'Donation') {
          setTransactionData(dataRow);
          navigate(
            buildUrl(appRoutes.transactionsDonations, {
              [RouterEntitiesId.order]: dataRow.id as string,
            }),
          );
        } else if (dataRow.Ordersubtype === 'Asset') {
          setTransactionData(dataRow);
          navigate(
            buildUrl(appRoutes.transactionsAssets, {
              [RouterEntitiesId.order]: dataRow.id as string,
            }),
          );
        } else if (dataRow.Ordersubtype === 'Registration Option') {
          setTransactionData(dataRow);
          navigate(
            buildUrl(appRoutes.transactionsRegistration, {
              [RouterEntitiesId.order]: dataRow.id as string,
            }),
          );
        }
      }}
    />
  );
}
