import React from 'react';
import { Grid, styled } from '@mui/material';

import { Field, TextField } from '@vizsla/components';

import { ImageInputField } from 'src/components/forms';
import { StoreAssetFormFields } from 'src/types/storeAssets';

const StyledImageGrid = styled(Grid)(() => ({
  '& > *': {
    '& > *': {
      margin: '0 auto',
    },
  },
}));

interface FormCommonInfoSectionProps {
  disabled?: boolean;
}

const FormCommonInfoSection: React.FC<FormCommonInfoSectionProps> = ({ disabled }) => {
  return (
    <React.Fragment>
      <StyledImageGrid item xs={12}>
        <Field
          fullWidth
          name={StoreAssetFormFields.IMAGE}
          label="Add Image"
          width={200}
          height={200}
          component={ImageInputField}
          disabled={disabled}
        />
      </StyledImageGrid>

      <Grid item xs={12}>
        <Field
          fullWidth
          name={StoreAssetFormFields.NAME}
          label="Name"
          component={TextField}
          required
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          fullWidth
          name={StoreAssetFormFields.DESCRIPTION}
          label="Description"
          component={TextField}
          disabled={disabled}
        />
      </Grid>
    </React.Fragment>
  );
};

export { FormCommonInfoSection };
