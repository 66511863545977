import React from 'react';
import { IconButton } from '@mui/material';
import { RemoveRedEyeOutlined, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';

import { PaletteColor } from '@vizsla/theme';

interface VisibilityButtonProps {
  isVisible: boolean;
  onClick?: () => void;
}

export const VisibilityButton: React.FC<VisibilityButtonProps> = ({ isVisible, onClick }) => {
  return (
    <IconButton size="medium" onClick={onClick}>
      {isVisible ? (
        <RemoveRedEyeOutlined
          sx={{
            color: PaletteColor.PrimaryGray,
          }}
        />
      ) : (
        <VisibilityOffIcon
          sx={{
            color: PaletteColor.PrimaryGray,
          }}
        />
      )}
    </IconButton>
  );
};
