import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { useModal } from '@vizsla/hooks';
import { Button } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';

const useStyles = makeStyles((theme: Theme) => ({
  wrapperContent: {
    padding: theme.spacing(2),
  },
}));

interface ConfirmationDialogProps {
  submitting?: boolean;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ submitting = false }) => {
  const classes = useStyles();
  const { isOpen, closeModal, args } = useModal(MODALS.CONFIRMATION_MODAL);

  const onCloseModal = () => {
    closeModal();
  };

  const onCancel = React.useCallback(() => {
    closeModal();

    if (typeof args?.onCancel === 'function') {
      args.onCancel();
    }
  }, [closeModal, args]);

  const onConfirm = React.useCallback(async () => {
    if (typeof args?.onConfirm === 'function') {
      await args.onConfirm();
    }
    closeModal();
  }, [closeModal, args]);

  const message = args?.message ?? 'Are you sure?';

  return (
    <Dialog open={isOpen} onClose={onCloseModal}>
      <div className={classes.wrapperContent}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onCloseModal}
            disabled={submitting}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
            size="large"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center">
            <Typography variant="subtitle1">{message}</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center">
            <Button color="info" variant="contained" disabled={submitting} onClick={onCancel}>
              Cancel
            </Button>
            <Button onClick={onConfirm} loading={submitting}>
              Confirm
            </Button>
          </Grid>
        </DialogActions>
      </div>
    </Dialog>
  );
};
