import { useNavigate } from 'react-router-dom';

import * as appRoutes from '../../constants/routes';

export function useNavigateToInboxByRecipients() {
  const navigate = useNavigate();
  return (...recipients: string[]) => {
    navigate(appRoutes.inbox, { state: { recipients } });
  };
}
