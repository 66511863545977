import React from 'react';
import { Box, Button, ButtonProps, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Display, JustifyContent } from 'src/theme';

const useStyles = makeStyles({
  root: {
    borderRadius: '500px',
    width: '150px',
    margin: '0 auto',
  },
});

export const AddExperienceButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Box display={Display.Flex} justifyContent={JustifyContent.Center}>
      <Button size="small" color="secondary" variant="outlined" classes={classes} {...props}>
        <Typography color="secondary" variant="caption">
          {children}
        </Typography>
      </Button>
    </Box>
  );
};
