import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { LinearProgress, Grid } from '@mui/material';

import { useNotification } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import {
  useRegistrationOptionSwagbagCreateMutation,
  useRegistrationOptionSwagbagListInventoryQuery,
} from '@vizsla/graphql';

import { t } from 'src/utils/template';
import type { SwagBagAssetAddOrUpdateFormValues } from 'src/types/registrationOption';
import { ExperienceStoreAssetAddFormSchema } from 'src/constants/validationSchemas/storeAssets';
import { ExperienceStoreDataProvider } from 'src/providers/storeAssets';

import { RegistrationOptionSwagBagAssetsTable } from './SwagBagAssetsTable';
import { FormSearchSection, FormPriceSection } from './sections';
import { RegistrationOptionSwagBagAssetDeleteModal } from './RegistrationOptionSwagBagDeleteModal';

interface RegistrationOptionSwagBagProps {
  experienceId: string;
  registrationOptionId: string;
}

export const RegistrationOptionSwagBag: React.FC<RegistrationOptionSwagBagProps> = ({
  experienceId,
  registrationOptionId,
}) => {
  const [searchValue, setSearchValue] = React.useState<string | null>();
  const notification = useNotification();

  const initialValues = React.useRef<SwagBagAssetAddOrUpdateFormValues>({
    storeAsset: undefined,
  });

  const {
    data: dataAvailableSwagBag,
    loading: loaginAvailability,
    error: errorAvailability,
  } = useRegistrationOptionSwagbagListInventoryQuery({
    variables: {
      filter: { swagbagAvailability: { equals: true } },
    },
  });

  const availableSwagBagItems = useMemo(
    () => dataAvailableSwagBag?.getAllAssetInventoriesList?.items ?? [],
    [dataAvailableSwagBag],
  );

  const [registrationOptionSwagbagCreateMutation, { loading, error }] =
    useRegistrationOptionSwagbagCreateMutation();

  const onSubmit = async (formValues: Record<string, any>) => {
    try {
      const { data: createRegistrationSwagBagAssetData } =
        await registrationOptionSwagbagCreateMutation({
          variables: {
            data: {
              registrationOption: {
                connect: {
                  id: registrationOptionId,
                },
              },
              storeAsset: {
                connect: {
                  id: formValues.storeAsset.id,
                },
              },
            },
          },
        });

      notification.success(t('registration_option_swag_bag_add_success'));
    } catch (error) {
      notification.error(t('registration_option_swag_bag_add_error'));
    }
  };
  const validate = React.useCallback(async (formValues: SwagBagAssetAddOrUpdateFormValues) => {
    return validateWithSchema(ExperienceStoreAssetAddFormSchema, formValues);
  }, []);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <ExperienceStoreDataProvider>
      <Grid container>
        <Form
          initialValues={initialValues.current}
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitting, pristine, validating, invalid, dirty }) => {
            const isSubmitLoading = submitting || validating;
            const isSubmitDisabled = isSubmitLoading || pristine || validating || invalid;

            return (
              <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <FormSearchSection
                    searchValue={searchValue || null}
                    setSearchValue={setSearchValue}
                    registrationOptionId={registrationOptionId}
                    availableSwagBagItems={availableSwagBagItems}
                  />

                  <FormPriceSection handleSubmit={handleSubmit} />
                </Grid>
              </form>
            );
          }}
        />
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <RegistrationOptionSwagBagAssetsTable
            isStoreEnabled
            registrationOptionId={registrationOptionId}
            availableSwagBagItems={availableSwagBagItems}
          />
        </Grid>
      </Grid>
      <RegistrationOptionSwagBagAssetDeleteModal />
    </ExperienceStoreDataProvider>
  );
};
