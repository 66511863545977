import React from 'react';
import { Grid } from '@mui/material';
import _ from 'lodash';

import { Field, NumberField } from '@vizsla/components';

import { CampaignType } from 'src/types/campaign';
import { MONEY_FORMAT } from 'src/constants/formats';

interface CampaignDetailsGoalsInputsProps {
  campaignType: CampaignType;
}

export const CampaignDetailsGoalsInputs: React.FC<CampaignDetailsGoalsInputsProps> = ({
  campaignType,
}) => {
  if (_.isNil(campaignType)) {
    return null;
  }

  const renderFundraisingGoal = () => (
    <Grid item xs={3}>
      <Field
        name="fundraisingGoal"
        label="Fundraising *"
        fullWidth
        component={NumberField}
        numberFormat={MONEY_FORMAT}
      />
    </Grid>
  );

  const renderAttendeesGoal = () => (
    <Grid item xs={3}>
      <Field
        fullWidth
        name="attendanceFundraisersCountGoal"
        label="Attendees"
        component={NumberField}
      />
    </Grid>
  );

  const renderTeamsGoal = () => (
    <Grid item xs={3}>
      <Field fullWidth name="teamGoal" label="Teams" component={NumberField} />
    </Grid>
  );

  const renderDonorsGoal = () => (
    <Grid item xs={3}>
      <Field fullWidth name="donorGoal" label="Donors" component={NumberField} />
    </Grid>
  );

  const renderFundraisersGoal = () => (
    <Grid item xs={3}>
      <Field fullWidth name="fundraiserGoal" label="Fundraisers" component={NumberField} />
    </Grid>
  );

  const renderFields = () => {
    switch (campaignType) {
      case CampaignType.registrationAndTicketing: {
        return (
          <React.Fragment>
            {renderFundraisingGoal()}
            {renderAttendeesGoal()}
            {renderTeamsGoal()}
          </React.Fragment>
        );
      }
      case CampaignType.personalMission: {
        return (
          <React.Fragment>
            {renderFundraisingGoal()}
            {renderFundraisersGoal()}
            {renderTeamsGoal()}
          </React.Fragment>
        );
      }
      case CampaignType.impactGiving: {
        return (
          <React.Fragment>
            {renderFundraisingGoal()}
            {renderDonorsGoal()}
          </React.Fragment>
        );
      }
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={2}>
      {renderFields()}
    </Grid>
  );
};
