import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { usePaginationQueryVariables, usePagination, useSearchQueryVariables } from '@vizsla/hooks';
import {
  IndividualFundraising,
  IndividualFundraisingFilter,
  IndividualFundraisingListResponse,
} from '@vizsla/graphql';

import { INDIVIDUAL_FUNDRAISERS_QUERY } from 'src/graphql';

interface Options {
  campaignId: string;

  skip?: number;
  first?: number;

  filter?: IndividualFundraisingFilter;
}

interface IndividualFundraisersQueryResult {
  fundraisers: IndividualFundraisingListResponse;
}

interface IndividualFundraisersQueryVariables {
  skip?: number;
  first?: number;

  filter: IndividualFundraisingFilter;
}

export function useFundraisers(options: Options) {
  const [data, setData] = useState<IndividualFundraising[]>([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const pagination = usePagination();
  const paginationVariables = usePaginationQueryVariables();

  const searchVariables = useSearchQueryVariables<IndividualFundraisingFilter>(searchValue => {
    return {
      attendee: {
        user: {
          OR: [
            {
              firstName: {
                contains: searchValue,
              },
            },
            {
              lastName: {
                contains: searchValue,
              },
            },
          ],
        },
      },
    };
  });

  const [executeQuery] = useLazyQuery<
    IndividualFundraisersQueryResult,
    IndividualFundraisersQueryVariables
  >(INDIVIDUAL_FUNDRAISERS_QUERY);

  const variables: IndividualFundraisersQueryVariables = useMemo(() => {
    const filterByCampaign: IndividualFundraisingFilter = {
      attendee: {
        experience: {
          campaign: {
            id: { equals: options.campaignId },
          },
        },
      },
    };

    return {
      first: options.first ?? paginationVariables.first,
      skip: options.skip ?? paginationVariables.skip,

      filter: {
        ...filterByCampaign,
        ...searchVariables,
        ...options.filter,
      },
    };
  }, [options.campaignId, options.filter, paginationVariables, searchVariables]);

  async function refetch() {
    if (!options.campaignId) return;

    setLoading(true);

    const response = await executeQuery({ variables });

    const count = response.data?.fundraisers.count ?? 0;
    const data = response.data?.fundraisers.items ?? [];

    setData(data);
    setCount(count);
    setLoading(false);
  }

  useEffect(() => {
    pagination.onCountChange(count);
  }, [count]);

  useEffect(() => {
    refetch();
  }, [variables]);

  return {
    data,
    loading,
  };
}
