import { create } from 'zustand';

import { VizslaOrganization } from '@vizsla/graphql';

interface State {
  loading: boolean;
  data?: VizslaOrganization;
}

interface Store extends State {
  setLoading(loading: boolean): void;
  setData(data: VizslaOrganization | undefined): void;

  clean(): void;
}

export const useSelectedVizslaOrganization = create<Store>(set => {
  const initial: State = {
    loading: true,
    data: undefined,
  };

  function setLoading(loading: boolean) {
    set({ loading });
  }

  function setData(data: VizslaOrganization | undefined) {
    set({ data, loading: false });
  }

  function clean() {
    set(initial);
  }

  return {
    ...initial,

    setData,
    setLoading,
    clean,
  };
});
