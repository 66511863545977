import React from 'react';
import { Grid, IconButton, Popover, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Edit as EditIcon, Close as CloseIcon } from '@mui/icons-material';

import { usePopover } from '@vizsla/hooks';
import { concatDateWithTime } from '@vizsla/utils';

import { PaletteColor } from 'src/theme';
import { PricingTierFormAction, PricingTierFieldValue } from 'src/types/pricingSettings';

import { ScaledPriceTierForm } from './ScaledPriceTierForm';

const useStyles = makeStyles((theme: Theme) => ({
  selectedColumns: {
    maxHeight: 400,
    overflowY: 'auto',
  },
  fieldRow: {
    display: 'flex',
  },
  actions: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  action: {
    cursor: 'pointer',
  },
  contentWrap: {
    width: '418px',
    padding: theme.spacing(2),
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: PaletteColor.PrimaryGray,
  },
}));

interface ScaledPriceEditTierPopoverProps {
  update: (value: PricingTierFieldValue) => void;
  currentTierValue: PricingTierFieldValue;
  nextTierValue?: PricingTierFieldValue;
  prevTierValue?: PricingTierFieldValue;
}

export const ScaledPriceEditTierPopover: React.FC<ScaledPriceEditTierPopoverProps> = ({
  update,
  currentTierValue,
  nextTierValue,
  prevTierValue,
}) => {
  const popover = usePopover();
  const classes = useStyles();

  const minDateTimeValue = concatDateWithTime(prevTierValue?.endDate, prevTierValue?.endTime);

  const maxDateTimeValue = concatDateWithTime(nextTierValue?.startDate, nextTierValue?.startTime);

  const onSubmit = React.useCallback(
    (value: any) => {
      const formAction =
        value.formAction === PricingTierFormAction.toCreate
          ? PricingTierFormAction.toCreate
          : PricingTierFormAction.toUpdate;

      popover.closePopover();
      setTimeout(
        () =>
          update({
            ...value,
            formAction,
          }),
        200,
      );
    },
    [popover, update],
  );

  return (
    <React.Fragment>
      <Grid item>
        <Tooltip title="Edit Price Tier">
          <IconButton aria-label="select-columns" onClick={popover.openPopover} size="large">
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Popover
        anchorEl={popover.el}
        open={popover.isOpen}
        onClose={popover.closePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid className={classes.contentWrap}>
          <Grid marginBottom={4}>
            <Typography variant="subtitle1">Edit Pricing Tier</Typography>
            <IconButton
              aria-label="close"
              size="large"
              onClick={popover.closePopover}
              className={classes.closeIcon}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid>
            <ScaledPriceTierForm
              onSubmit={onSubmit}
              initialValues={currentTierValue}
              minDateTimeValue={minDateTimeValue}
              maxDateTimeValue={maxDateTimeValue}
            />
          </Grid>
        </Grid>
      </Popover>
    </React.Fragment>
  );
};
