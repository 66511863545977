import React from 'react';
import { Grid, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Button } from '@vizsla/components';

const useStyles = makeStyles({
  button: {
    width: 100,
  },
  link: {
    cursor: 'pointer',
  },
});

interface RegistrationOptionCreateStepperControllerProps {
  onBack: () => void;
  onContinue: () => void;
  isFirst: boolean;
  isLast: boolean;
  onCancel: () => void;
  valid: boolean;
  submitting: boolean;
  onSubmit: () => void;
}

export const FormStepperController: React.FC<RegistrationOptionCreateStepperControllerProps> = ({
  onBack,
  onContinue,
  isFirst,
  onCancel,
  valid,
  isLast,
  submitting,
  onSubmit,
}) => {
  const classes = useStyles();

  const renderContinueOrSubmitButton = () => {
    if (isLast) {
      return (
        <Button onClick={onSubmit} disabled={!valid} loading={submitting}>
          Submit
        </Button>
      );
    }

    return (
      <Button disabled={!valid} onClick={onContinue}>
        Continue
      </Button>
    );
  };

  return (
    <Grid container spacing={1} px={2} alignItems="center">
      <Grid item flex={1}>
        <Link className={classes.link} onClick={onCancel}>
          Cancel
        </Link>
      </Grid>
      <Grid item>
        <Button
          disabled={isFirst}
          className={classes.button}
          variant="text"
          color="info"
          onClick={onBack}
        >
          Back
        </Button>
        {renderContinueOrSubmitButton()}
      </Grid>
      <Grid item flex={1} display="flex" />
    </Grid>
  );
};
