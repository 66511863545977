import { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import moment from 'moment';

const QUERY = gql`
  query CampaignDonorsMetrics(
    $campaignId: ID!
    $todayDate: DateTime!
    $lastSevenDaysDate: DateTime!
  ) {
    campaign(id: $campaignId) {
      all: allocatedDonations(
        groupBy: {
          query: { donor: { id: { as: "count", fn: { aggregate: COUNT, distinct: true } } } }
        }
      ) {
        groups {
          count: Int
        }
      }

      lastSevenDays: allocatedDonations(
        filter: { createdAt: { gte: $lastSevenDaysDate } }

        groupBy: {
          query: { donor: { id: { as: "count", fn: { aggregate: COUNT, distinct: true } } } }
        }
      ) {
        groups {
          count: Int
        }
      }

      today: allocatedDonations(
        filter: { createdAt: { gte: $todayDate } }

        groupBy: {
          query: { donor: { id: { as: "count", fn: { aggregate: COUNT, distinct: true } } } }
        }
      ) {
        groups {
          count: Int
        }
      }
    }
  }
`;

interface Response {
  campaign: {
    all: {
      groups: [
        {
          count: number;
        },
      ];
    };

    lastSevenDays: {
      groups: [
        {
          count: number;
        },
      ];
    };

    today: {
      groups: [
        {
          count: number;
        },
      ];
    };
  };
}

interface Variables {
  campaignId: string;

  todayDate: string;
  lastSevenDaysDate: string;
}

interface CampaignDonorsMetrics {
  totalCount: number;
  totalCountLastSevenDays: number;
  totalCountToday: number;
}

export function useCampaignDonorsMetrics(campaignId?: string) {
  const [data, setData] = useState<CampaignDonorsMetrics>();
  const [loading, setLoading] = useState(false);

  const [executeQuery] = useLazyQuery<Response, Variables>(QUERY, { fetchPolicy: 'cache-first' });

  async function refetch(campaignId: string) {
    setLoading(true);

    const todayDate = moment().startOf('day').toISOString();
    const lastSevenDaysDate = moment().startOf('day').subtract(7, 'days').toISOString();

    const response = await executeQuery({
      variables: {
        campaignId,
        todayDate,
        lastSevenDaysDate,
      },
    });

    const campaign = response.data?.campaign;

    const [all] = campaign?.all.groups ?? [];
    const [lastSevenDays] = campaign?.lastSevenDays.groups ?? [];
    const [today] = campaign?.today.groups ?? [];

    const metrics: CampaignDonorsMetrics = {
      totalCount: all?.count ?? 0,
      totalCountToday: today?.count ?? 0,
      totalCountLastSevenDays: lastSevenDays?.count ?? 0,
    };

    setData(metrics);
    setLoading(false);

    return metrics;
  }

  useEffect(() => {
    if (campaignId) {
      refetch(campaignId);
    }
  }, [campaignId]);

  return {
    data,
    loading,

    refetch,
  };
}
