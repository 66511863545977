import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { useMatch } from 'react-router-dom';

import { useCurrentOrganization } from '@vizsla/hooks';
import { FullscreenLoader } from '@vizsla/components';

import { EmptyOrganizationScreen } from 'src/components/organization';
import { DrawerProvider } from 'src/providers/DrawerProdiver';

import { Sidebar } from './Sidebar';
import { Header } from './Header';

type StylesParams = {
  isEditor: boolean;
};

const useStyles = makeStyles<Theme, StylesParams>(theme => ({
  root: {
    height: '100%',
    backgroundColor: '#fff',
    color: '#34b9ff',

    display: ({ isEditor }) => (isEditor ? 'block' : 'grid'),
    gridTemplateAreas: '"sidebar header" "sidebar content"',
    gridTemplateRows: '64px 1fr',
    gridTemplateColumns: 'auto 1fr',
  },

  content: ({ isEditor }) =>
    isEditor
      ? {
          height: '100vh',
          overflow: 'hidden',
        }
      : {
          height: 'calc(100vh - 64px)',
          gridArea: 'content',
          overflow: 'auto',
          padding: '16px 16px 0',

          '&::after': {
            display: 'block',
            content: '""',
            height: 10,
            width: '100%',
          },
        },
}));

const EDITOR_ROUTE_NAME = '/editor/:id/';

export const OrganizationLayout: React.FC = ({ children }) => {
  const isEditor = Boolean(useMatch(EDITOR_ROUTE_NAME));

  const classes = useStyles({ isEditor });

  const { organization, organizationIsLoading } = useCurrentOrganization();

  const renderPageContent = () => {
    if (Boolean(organization) && !organizationIsLoading) {
      return <React.Fragment>{children}</React.Fragment>;
    }

    if (organization === undefined) {
      return <EmptyOrganizationScreen />;
    }

    return <FullscreenLoader variant="light" />;
  };

  return (
    <DrawerProvider>
      <div className={classes.root}>
        {!isEditor && (
          <React.Fragment>
            <Sidebar isLoading={organizationIsLoading} />
            <Header />
          </React.Fragment>
        )}
        <main className={classes.content}>{renderPageContent()}</main>
      </div>
    </DrawerProvider>
  );
};
