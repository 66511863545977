import React from 'react';

import { useDeletePartnershipCommitmentMutation } from '@vizsla/graphql';
import { useNotification } from '@vizsla/hooks';

export const useRowActions = (partnershipId: string, setLoading: (bool) => void) => {
  const notifications = useNotification();
  const [DeleteCommitment] = useDeletePartnershipCommitmentMutation({});

  const actions = React.useMemo(() => {
    return [
      {
        actionName: 'Delete',
        onAction: async commitment => {
          setLoading(true);
          try {
            // IMPORTANT change for commitment
            await DeleteCommitment({
              variables: { data: { id: commitment.id } },
              refetchQueries: ['ListPartnershipCommitments'],
            });
            notifications.success(`Commitment deleted successfully`);
          } catch (error) {
            notifications.error(`Error while deleting the commitment: ${error.message}`);
          }
          setLoading(false);
        },
      },
    ];
  }, [partnershipId, DeleteCommitment, setLoading, notifications]);

  return {
    actions,
  };
};
