import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { useModal, useCurrentOrganization } from '@vizsla/hooks';
import { StripeElementsProvider } from '@vizsla/stripe';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';

import { AddOfflineDonationForm } from './components/AddOfflineDonationForm';

const CampaignAddOfflineDonationModal: React.FC = () => {
  const { isOpen, closeModal } = useModal(MODALS.CAMPAIGN_ADD_OFFLINE_DONATION_MODAL);
  const { organizationId } = useCurrentOrganization();

  const noStripeMessage = (
    <Typography variant="body2">
      <span>Stripe is not connected. Check Stripe status at </span>
      <Link to="/settings/checkout">Checkout Settings</Link>
    </Typography>
  );

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Title>
        <Typography textAlign="center" variant="h2">
          Add Offline Donation
        </Typography>
      </Modal.Title>
      <Modal.Content>
        <StripeElementsProvider
          organizationId={organizationId || ''}
          noStripeMessage={noStripeMessage}
        >
          <AddOfflineDonationForm closeModal={closeModal} />
        </StripeElementsProvider>
      </Modal.Content>
    </Modal>
  );
};

export default CampaignAddOfflineDonationModal;
