import React from 'react';
import {
  Flag as FlagIcon,
  FlagOutlined as FlagOutlinedIcon,
  PaidOutlined as CoinIcon,
  VolunteerActivismOutlined as DonationIcon,
} from '@mui/icons-material';
import { styled } from '@mui/styles';
import { Box } from '@mui/material';

import { User } from '@vizsla/graphql';
import { formatMoney, formatNumber } from '@vizsla/utils';

import { InformationCard } from 'src/components/shared';
import { mapUserStatistics } from 'src/utils/peopleCRM';

interface ContactInfoCardsProps {
  user: User;
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  gap: theme.spacing(2),
  '& > div': {
    flex: '1 1 0px',
  },
}));

const ContactInfoCards: React.FC<ContactInfoCardsProps> = ({ user }) => {
  const statistics = mapUserStatistics(user);

  return (
    <StyledBox>
      <InformationCard
        icon={<FlagIcon color="secondary" fontSize="large" />}
        title="Active Campaigns"
        value={formatNumber(statistics.activeCampaingsCount)}
      />
      <InformationCard
        icon={<FlagOutlinedIcon color="secondary" fontSize="large" />}
        title="Total Campaigns"
        value={formatNumber(statistics.totalCampaignsCount)}
      />
      <InformationCard
        icon={<CoinIcon color="secondary" fontSize="large" />}
        title="Total Raised"
        value={formatMoney(statistics.totalRaised)}
      />
      <InformationCard
        icon={<DonationIcon color="secondary" fontSize="large" />}
        title="Total Donors"
        value={formatNumber(statistics.totalDonorsCount)}
      />
    </StyledBox>
  );
};

export { ContactInfoCards };
