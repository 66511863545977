import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import {
  useCurrentOrganization,
  usePagination,
  usePaginationQueryVariables,
  useSearchQueryVariables,
} from '@vizsla/hooks';
import { IndividualFundraising, IndividualFundraisingFilter } from '@vizsla/graphql';

const FUNDRAISERS_CRM_QUERY = gql`
  query FundraisersCRM($first: Int, $skip: Int, $filter: IndividualFundraisingFilter) {
    fundraisers: individualFundraisingsList(first: $first, skip: $skip, filter: $filter) {
      count
      items {
        id

        attendee {
          user {
            id
            firstName
            lastName

            avatar {
              downloadUrl
            }
          }

          teamMembership {
            team {
              name
            }
          }
        }

        allocatedDonations(first: 1, orderBy: createdAt_DESC) {
          items {
            createdAt
          }
        }

        raised: allocatedDonations(
          groupBy: { query: { amount: { as: "total", fn: { aggregate: SUM } } } }
        ) {
          groups {
            total: Float
          }
        }
      }
    }
  }
`;

export type FundraiserCRMRow = IndividualFundraising & {
  raised: {
    groups: [
      {
        total: number;
      },
    ];
  };
};

interface FundraisersCRMQuery {
  fundraisers: {
    count: number;
    items: FundraiserCRMRow[];
  };
}

interface FundraisersCRMQueryVariables {
  first?: number;
  skip?: number;
  filter?: IndividualFundraisingFilter;
}

export function useFundraisersCRM() {
  const pagination = usePagination();
  const paginationQuery = usePaginationQueryVariables();
  const searchQuery = useSearchQueryVariables(true);

  const { organizationId } = useCurrentOrganization();

  const organizationFilter: IndividualFundraisingFilter = useMemo(() => {
    return {
      attendee: {
        experience: {
          campaign: {
            vizslaOrganization: {
              id: { equals: organizationId },
            },
          },
        },
      },
    };
  }, [organizationId]);

  const { data: response, loading } = useQuery<FundraisersCRMQuery, FundraisersCRMQueryVariables>(
    FUNDRAISERS_CRM_QUERY,
    {
      variables: {
        ...paginationQuery,

        filter: {
          ...searchQuery,
          ...organizationFilter,
        },
      },
    },
  );

  useEffect(() => {
    pagination.onCountChange(response?.fundraisers.count ?? 0);
  }, [response]);

  const data = useMemo(() => response?.fundraisers.items ?? [], [response]);

  return {
    data,
    loading,
  };
}
