import React from 'react';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

import { useModal } from '@vizsla/hooks';
import { TableData, TableColumn, TableRowAction } from '@vizsla/components';
import { buildUrl, calcPercentage, formatMoney, getStringifiedDateRange } from '@vizsla/utils';
import { Campaign } from '@vizsla/graphql';

import { MODALS } from 'src/constants/modals';
import * as appRoutes from 'src/constants/routes';
import * as entitiesId from 'src/constants/entities-id';
import { TextWithImageCell } from 'src/components/shared';
import { CampaignStatus } from 'src/types/campaign';
import { getAttendeesCountFromCampaign, isRegistrationAndTicketing } from 'src/utils/campaign';

import { CampaignStatusChip } from '../CampaignStatusChip';
import { ValueGoal } from '../CampaignsListTableView/components';
import { ProgressBar } from './componets';

const CampaignsProgramsListTable = ({ programs, loading = false }) => {
  const { openModal } = useModal();

  const sortData = (x, y) => {
    return x.name.localeCompare(y.name);
  };

  const programsCopy = [...programs];

  const sortPrograms = programsCopy.sort(sortData) || [];

  const columns: Array<TableColumn<Campaign>> = [
    {
      title: 'name',
      key: 'name',
      render: (_v, campaign) => {
        const campaignUrl = buildUrl(appRoutes.campaignDashboard, {
          [entitiesId.campaign]: campaign.id as string,
        });
        const dateRange = getStringifiedDateRange(campaign.startDate, campaign.endDate);

        return (
          <Link to={campaignUrl} style={{ textDecoration: 'none' }}>
            <TextWithImageCell
              text={campaign.name || ''}
              image={campaign?.image?.downloadUrl}
              extra={dateRange}
            />
          </Link>
        );
      },
    },
    {
      title: 'status',
      key: 'status',
      dataPath: 'campaignStatus',
      render: (campaignStatus: CampaignStatus | undefined) => {
        return <CampaignStatusChip status={campaignStatus || CampaignStatus.draft} />;
      },
    },
    {
      title: 'type',
      key: 'type',
      dataPath: 'campaignType',
    },
    {
      title: 'experiences',
      key: 'experiences',
      render: (_v, campaign) => {
        const experiencesCount = isRegistrationAndTicketing(campaign)
          ? campaign.experiences?.count
          : 0;

        return experiencesCount;
      },
    },
    {
      title: 'raised',
      key: 'raised',
      render: (_v, campaign) => {
        const percentage = calcPercentage(
          campaign?.totalRaised || 0,
          campaign?.fundraisingGoal || 0,
        );
        const formattedTotalRaised = formatMoney(Number(campaign?.totalRaised));

        return <ValueGoal value={formattedTotalRaised} percentage={percentage} />;
      },
    },
    {
      title: 'attendees',
      key: 'attendees',
      render: (_v, campaign) => {
        const attendeesCount = getAttendeesCountFromCampaign(campaign);
        const percentage = calcPercentage(
          Number(attendeesCount),
          Number(campaign.attendanceFundraisersCountGoal),
        );

        return <ValueGoal value={attendeesCount} percentage={percentage} />;
      },
    },
    {
      title: 'teams',
      key: 'teams',
      render: (_v, campaign) => {
        const percentage = calcPercentage(campaign?.teams?.count, campaign?.teamGoal || 0);
        const teamsCount = Number(campaign?.teams?.count);

        return <ValueGoal percentage={percentage} value={teamsCount} />;
      },
    },
  ];

  const rowActions: Array<TableRowAction<Campaign>> = [
    {
      actionName: 'delete',
      onAction: campaign => openModal(MODALS.CAMPAIGN_DELETE_MODAL, { campaign }),
      disabled: campaign => campaign?.campaignStatus !== CampaignStatus.draft,
    },
  ];

  if (loading) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Grid>
      {sortPrograms.map(program => (
        <Grid key={program.id} mt="40px">
          <Grid container>
            <Typography fontSize={20} fontWeight={800}>
              {program.name}
            </Typography>
            <Typography fontSize={20} color="#848C96" ml="5px">
              ({program?.campaigns.items.length})
            </Typography>
          </Grid>

          {program?.campaigns.items.length > 0 && (
            <ProgressBar campaings={program?.campaigns.items} />
          )}
          {program?.campaigns.items.length ? (
            <Grid item>
              <TableData
                dataSource={program?.campaigns.items}
                columns={columns}
                rowActions={rowActions}
                loading={loading}
              />
            </Grid>
          ) : (
            <Typography>No results to display</Typography>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default React.memo(CampaignsProgramsListTable);
