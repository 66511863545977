import React from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { AttachMoney, MoneyOff } from '@mui/icons-material';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';
import _ from 'lodash';

import { DateFormatPatterns } from '@vizsla/constants';
import { validateWithSchema } from '@vizsla/utils';
import { useNotification } from '@vizsla/hooks';
import {
  Field,
  Button,
  NumberField,
  DateField,
  RadioGroupField,
  GridDefaultPadding,
} from '@vizsla/components';

import { useRegistrationOptionById, useRegistrationOptions } from 'src/hooks/registrationOption';
import { useExperienceId, useExperienceById } from 'src/hooks/experiences';
import { MONEY_FORMAT } from 'src/constants/formats';
import { CheckpointNotificationValue } from 'src/types/registrationOption';
import { RegistrationOptionEditFundraisingFormSchema } from 'src/constants/validationSchemas/registrationOption';
import { CHECKPOINT_NOTIFICATION_OPTIONS } from 'src/constants/registrationOption';
import { t } from 'src/utils/template';
import { SectionExpanderField } from 'src/components/forms';

interface RegistrationOptionFundraisingEditFormProps {
  registrationOptionId: string;
}

const getDataToSubmit = (data: any) => {
  const {
    fundraisingMinimumEnabled,
    fundraisingMinimum,
    checkpointEnabled,
    checkpointAmount,
    checkpointDate,
    checkpointNotification,
    ...rest
  } = data;
  return {
    ...rest,
    fundraisingMinimum: fundraisingMinimumEnabled ? fundraisingMinimum : null,
    checkpointAmount: checkpointEnabled ? checkpointAmount : null,
    checkpointDate: checkpointEnabled ? checkpointDate : null,
    checkpointNotification: checkpointEnabled ? checkpointNotification : null,
  };
};

export const RegistrationOptionFundraisingEditForm: React.FC<
  RegistrationOptionFundraisingEditFormProps
> = ({ registrationOptionId }) => {
  const notification = useNotification();

  const experienceId = useExperienceId();
  const { experience, loading: experienceLoading } = useExperienceById(experienceId);

  const { updateRegistrationOption, updating } = useRegistrationOptions(experienceId);

  const { registrationOption, loading } = useRegistrationOptionById(registrationOptionId);

  const initialValues = React.useMemo(
    () => ({
      fundraisingMinimumEnabled: !_.isNil(registrationOption?.fundraisingMinimum),
      fundraisingMinimum: registrationOption?.fundraisingMinimum,
      checkpointEnabled:
        !_.isNil(registrationOption?.checkpointAmount) &&
        !_.isNil(registrationOption?.checkpointDate),
      checkpointAmount: registrationOption?.checkpointAmount,
      checkpointDate: registrationOption?.checkpointDate,
      checkpointNotification:
        registrationOption?.checkpointNotification ?? CheckpointNotificationValue.OnlyAttendee,
    }),
    [registrationOption],
  );

  const onSubmit = async (data: any, form: FormApi<any, any>) => {
    try {
      const formattedData = getDataToSubmit(data);
      await updateRegistrationOption(formattedData, registrationOptionId);

      notification.success(t('registration_option_update_success'));
    } catch (error) {
      notification.error(t('registration_option_update_error'));
    }
    form.reset();
  };

  const onCancel = (form: FormApi) => {
    form.reset();
  };

  if (loading || experienceLoading) {
    return <LinearProgress />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={values =>
        validateWithSchema(RegistrationOptionEditFundraisingFormSchema, values, {
          context: { experience },
        })
      }
      render={({ form, handleSubmit, submitting, dirty, values, valid }) => {
        const arefieldsDisabled = submitting || updating;
        const isCancelDisabled = !dirty;
        const isSubmittingDisabled = !valid;

        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <GridDefaultPadding item xs={12}>
                <SectionExpanderField
                  name="fundraisingMinimumEnabled"
                  isActive={values.fundraisingMinimumEnabled}
                  heading={
                    <React.Fragment>
                      <AttachMoney />
                      Fundraising Minimum
                    </React.Fragment>
                  }
                  labelCollapsed="No fundraising minimum"
                >
                  <Grid item xs={12} marginBottom={2}>
                    <Typography variant="body2">
                      Establish the minimum fundraising required to attend. Once a registration is
                      purchased, the minimum commitment can not be edited.
                    </Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        name="fundraisingMinimum"
                        label="Minimum Fundraising (USD)"
                        component={NumberField}
                        numberFormat={MONEY_FORMAT}
                        disabled={arefieldsDisabled}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </SectionExpanderField>
              </GridDefaultPadding>

              <GridDefaultPadding item xs={12}>
                <SectionExpanderField
                  name="checkpointEnabled"
                  isActive={values.checkpointEnabled}
                  heading={
                    <React.Fragment>
                      <MoneyOff />
                      Checkpoint
                    </React.Fragment>
                  }
                  labelCollapsed="No checkpoint"
                >
                  <Grid item xs={12} marginBottom={2}>
                    <Typography variant="body2">
                      Provide notification if specified amount has not been met by the specified
                      date.
                    </Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        name="checkpointAmount"
                        label="Amount (USD)"
                        component={NumberField}
                        numberFormat={MONEY_FORMAT}
                        disabled={arefieldsDisabled}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        name="checkpointDate"
                        label="Date"
                        component={DateField}
                        inputFormat={DateFormatPatterns.shortMonthDayYear}
                        disabled={arefieldsDisabled}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="checkpointNotification"
                        options={CHECKPOINT_NOTIFICATION_OPTIONS}
                        component={RadioGroupField}
                        disabled={arefieldsDisabled}
                      />
                    </Grid>
                  </Grid>
                </SectionExpanderField>
              </GridDefaultPadding>

              <Grid item xs={12} container justifyContent="center" spacing={1}>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => onCancel(form)}
                  disabled={isCancelDisabled}
                >
                  Cancel
                </Button>
                <Button onClick={form.submit} loading={updating} disabled={isSubmittingDisabled}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};
