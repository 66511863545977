import React from 'react';
import { GroupOutlined as GroupIcon } from '@mui/icons-material';

import { InformationCard } from '@vizsla/components';

import { useCampaignId, useCampaignTeamsMetrics } from 'src/hooks/campaign';

export function TotalTeamsCard() {
  const campaignId = useCampaignId();

  const { data, loading } = useCampaignTeamsMetrics(campaignId);

  const count = React.useMemo(() => data?.totalCount ?? 0, [data]);

  return (
    <InformationCard
      title="Total Teams"
      value={count}
      icon={<GroupIcon color="secondary" fontSize="large" />}
      loading={loading}
    />
  );
}
