import React from 'react';
import { Grid, Typography } from '@mui/material';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';

import {
  TextField,
  NumberField,
  DateField,
  SelectField,
  CheckboxField,
  Field,
} from '@vizsla/components';
import {
  StripeCardElementCommonChangeEvent,
  StripeCardFieldName,
  CardCvcField,
  CardExpiryField,
  CardNumberField,
} from '@vizsla/stripe';
import { USA_STATES_OPTIONS } from '@vizsla/constants';

import { MONEY_FORMAT } from 'src/constants/formats';
import { PaymentMethod } from 'src/types/offlineDonation';

const SELECT_OPTIONS = [
  {
    value: PaymentMethod.cash,
    label: 'Cash',
  },
  {
    value: PaymentMethod.check,
    label: 'Check',
  },
  {
    value: PaymentMethod.card,
    label: 'Credit',
  },
];

interface AddOfflineDonationPaymentStepProps {
  onChangeSecuredField: (
    field: StripeCardFieldName,
  ) => (changedObj: StripeCardElementCommonChangeEvent) => void;
}

export const AddOfflineDonationPaymentStep: React.FC<AddOfflineDonationPaymentStepProps> = ({
  onChangeSecuredField,
}) => {
  const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethod>(PaymentMethod.cash);

  const renderPaymentMethodFields = () => {
    switch (paymentMethod) {
      case PaymentMethod.cash:
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                name="paymentInfo.amount"
                label="Amount (USD)"
                required
                component={NumberField}
                numberFormat={MONEY_FORMAT}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="paymentInfo.date"
                label="Date"
                required
                component={DateField}
                fullWidth
              />
            </Grid>
          </Grid>
        );
      case PaymentMethod.check:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name="paymentInfo.from"
                label="From"
                required
                component={TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="paymentInfo.bank"
                label="Bank"
                required
                component={TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="paymentInfo.number"
                label="Check No."
                required
                component={TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="paymentInfo.amount"
                label="Amount (USD)"
                required
                component={NumberField}
                numberFormat={MONEY_FORMAT}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="paymentInfo.date"
                label="Date"
                required
                component={DateField}
                fullWidth
              />
            </Grid>
          </Grid>
        );
      case PaymentMethod.card:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name="paymentInfo.amount"
                label="Amount (USD)"
                required
                component={NumberField}
                numberFormat={MONEY_FORMAT}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} direction="column">
                <Grid item xs={12}>
                  <Typography variant="body1">Credit Card</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={`paymentInfo.creditCard.${StripeCardFieldName.Number}`}
                    label="Card Number"
                    required
                    component={CardNumberField}
                    onStripeInputChange={onChangeSecuredField(StripeCardFieldName.Number)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} direction="row">
                    <Grid item xs={6}>
                      <Field
                        name={`paymentInfo.creditCard.${StripeCardFieldName.Expiry}`}
                        label="Exp."
                        required
                        component={CardExpiryField}
                        onStripeInputChange={onChangeSecuredField(StripeCardFieldName.Expiry)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name={`paymentInfo.creditCard.${StripeCardFieldName.Cvc}`}
                        label="CVC"
                        required
                        component={CardCvcField}
                        onStripeInputChange={onChangeSecuredField(StripeCardFieldName.Cvc)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1">Billing Address</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Field
                    fullWidth
                    name="paymentInfo.billingAddress.street1"
                    label="Address"
                    required
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    label="State"
                    name="paymentInfo.billingAddress.state"
                    component={SelectField}
                    options={USA_STATES_OPTIONS}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={8}>
                  <Field
                    fullWidth
                    name="paymentInfo.billingAddress.city"
                    label="City"
                    required
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    fullWidth
                    name="paymentInfo.billingAddress.zip"
                    label="Zip Code"
                    required
                    component={TextField}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <FormSpy subscription={{ values: true }}>
      {(spyRenderProps: FormSpyRenderProps) => {
        const { paymentInfo } = spyRenderProps.values;

        const method = paymentInfo?.method;

        if (method) {
          setPaymentMethod(method);
        }

        return (
          <Grid container spacing={2} direction="column">
            <Grid item xs={12}>
              <Field
                name="paymentInfo.method"
                label="Payment Method"
                required
                initialValue={SELECT_OPTIONS[0].value}
                component={SelectField}
                options={SELECT_OPTIONS}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              {renderPaymentMethodFields()}
            </Grid>
            <Grid item xs={12}>
              <Field
                name="paymentInfo.comment"
                label="Comment"
                rows={3}
                maxRows={4}
                component={TextField}
                multiline
                fullWidth
              />
            </Grid>
            {method === PaymentMethod.card && (
              <Grid item xs={12}>
                <Field
                  name="paymentInfo.sendReceipt"
                  label="Send receipt to email address provided"
                  component={CheckboxField}
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
        );
      }}
    </FormSpy>
  );
};
