import {
  ProgramCreateInput,
  useProgramCreateMutation,
  useProgramsListQuery,
} from '@vizsla/graphql';

export const usePrograms = (organizationId: string) => {
  const { data, loading } = useProgramsListQuery({
    variables: {
      filter: {
        organization: {
          id: {
            equals: organizationId,
          },
        },
      },
    },
    skip: !organizationId,
  });

  const [programCreateMutation, { loading: creating }] = useProgramCreateMutation({
    refetchQueries: ['ProgramsList'],
    awaitRefetchQueries: true,
  });

  const createProgram = async (data: ProgramCreateInput) => {
    await programCreateMutation({
      variables: {
        data: {
          ...data,
          organization: {
            connect: {
              id: organizationId,
            },
          },
        },
      },
    });
  };

  const programsList = data?.programsList?.items || [];

  return {
    programsList,
    createProgram,
    creating,
    loading,
  };
};
