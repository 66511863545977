import React from 'react';
import { Grid } from '@mui/material';

import { useModal } from '@vizsla/hooks';

import { useCampaignDiscounts, useCampaignId } from 'src/hooks/campaign';
import { MODALS } from 'src/constants/modals';

import { DiscountCard, AddDiscountButton } from './components';

export const CampaignDiscountsList: React.FC = () => {
  const campaignId = useCampaignId();

  const { discountsSettings, loading } = useCampaignDiscounts(campaignId);

  const { openModal } = useModal();

  const handleAddDiscount = () => {
    openModal(MODALS.CAMPAIGN_DISCOUNTS_CREATE_MODAL);
  };

  return (
    <Grid container spacing={3} wrap="wrap">
      {discountsSettings?.map(discount => (
        <Grid item key={discount.id} xl={4} lg={6} sm={12}>
          <DiscountCard discountSettings={discount} />
        </Grid>
      ))}
      <Grid item xl={4} lg={6} sm={12}>
        <AddDiscountButton onClick={handleAddDiscount} loading={loading} />
      </Grid>
    </Grid>
  );
};
