import React from 'react';

import { usePartnershipOrganizationsListQuery } from '@vizsla/graphql';
import {
  useCurrentOrganization,
  useCustomFiltersQueryVariables,
  usePagination,
  usePaginationQueryVariables,
  useSearchQueryVariables,
} from '@vizsla/hooks';

export const usePartnershipQuery = () => {
  const pagination = usePagination();

  const searchQueryVariables = useSearchQueryVariables(['name', 'type']);

  const customFiltersQueryVariables = useCustomFiltersQueryVariables();

  const paginationQueryVariables = usePaginationQueryVariables();

  const { organizationId } = useCurrentOrganization();

  const { data, loading } = usePartnershipOrganizationsListQuery({
    variables: {
      filter: {
        ...customFiltersQueryVariables,
        ...searchQueryVariables,
        organization: { id: { equals: organizationId } },
      },
      ...paginationQueryVariables,
    },
    fetchPolicy: 'cache-and-network',
  });

  const partnerships = React.useMemo(() => {
    return (
      data?.partnershipOrganizationsList?.items?.map(p => ({
        ...p,
        fullNameAvatar: { avatar: p?.avatar, name: p?.name },
      })) || []
    );
  }, [data?.partnershipOrganizationsList]);

  React.useEffect(() => {
    if (partnerships?.length > 0 && pagination.count !== partnerships?.length) {
      pagination.onCountChange(partnerships?.length);
    }
  }, [partnerships?.length]);

  return { partnerships, loading, pagination };
};
