import React from 'react';

import { FiltersLayout } from '@vizsla/components';
import { useModal } from '@vizsla/hooks';

import { MODALS } from 'src/constants/modals';
import { CampaignDiscountsCreateModal } from 'src/modals';
import { CampaignDiscountsList } from 'src/components/campaign/campaignDiscountsList';

const CampaignDiscounts: React.FC = () => {
  const { openModal } = useModal();

  const onOpenCampaignDiscountsCreateDialog = () =>
    openModal(MODALS.CAMPAIGN_DISCOUNTS_CREATE_MODAL);

  return (
    <FiltersLayout
      withSearch
      rightButtonProps={{
        content: 'Add Discount',
        onClick: onOpenCampaignDiscountsCreateDialog,
      }}
    >
      <React.Fragment>
        <CampaignDiscountsCreateModal />
        <CampaignDiscountsList />
      </React.Fragment>
    </FiltersLayout>
  );
};

export { CampaignDiscounts };
