import React, { useContext } from 'react';
import { Grid, List, ListItem, SxProps, Typography } from '@mui/material';

import { VisibilityButton } from 'src/components/shared';
import { ExperienceLandingActions } from 'src/providers/campaign/ThankYouLanding.types';
import { LandingStateContext } from 'src/providers/campaign/ThankYouLandingContext';

export const ManageDonationSection = () => {
  const { state, dispatch } = useContext(LandingStateContext);

  const isManageDonationTitleVisible = state.theme.isManageDonationTitleVisible ?? true;
  const isManageDonationDescriptionVisible = state.theme.isManageDonationDescriptionVisible ?? true;

  return (
    <List component="div">
      <ListItem disablePadding sx={theme => ({ paddingBottom: theme.spacing(2) })}>
        <Grid container display="flex" columnSpacing={5}>
          <Grid item xs={1}>
            <VisibilityButton
              isVisible={isManageDonationTitleVisible}
              onClick={() =>
                dispatch({
                  type: ExperienceLandingActions.updateisManageDonationTitleVisible,
                  payload: !isManageDonationTitleVisible,
                })
              }
            />
          </Grid>
          <Grid item xs={10} display="flex" justifyContent="left" alignItems="center">
            <Typography fontSize="14px">Title</Typography>
          </Grid>
        </Grid>
      </ListItem>
      <ListItem disablePadding sx={theme => ({ paddingBottom: theme.spacing(3) })}>
        <Grid container columnSpacing={5}>
          <Grid item xs={1}>
            <VisibilityButton
              isVisible={isManageDonationDescriptionVisible}
              onClick={() =>
                dispatch({
                  type: ExperienceLandingActions.updateisManageDonationDescriptionVisible,
                  payload: !isManageDonationDescriptionVisible,
                })
              }
            />
          </Grid>
          <Grid item xs={10} display="flex" justifyContent="left" alignItems="center">
            <Typography fontSize="14px">Description</Typography>
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );
};
