import React from 'react';
import { Box, styled, Typography } from '@mui/material';

import { useModal } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import { ExperienceImagesModalType } from 'src/types/experience';

import { ExperienceHeaderImageCreateForm, ExperienceHeaderImageEditForm } from './components';

const StyledModalContentBox = styled(Box)(({ theme }) => ({
  margin: 0,
  '& > *': {
    paddingBottom: theme.spacing(1),
  },
}));

/** @deprecated Changed by `VSL-1129`. */
export const ExperienceHeaderImagesCreateEditModal: React.FC = () => {
  const { isOpen, closeModal, args } = useModal(MODALS.EXPERIENCE_HEADER_IMAGES_UPDATE_MODAL);

  const experienceHeaderImage = args?.experienceHeaderImage;
  const modalType = args?.modalType;
  const isCreateType = modalType === ExperienceImagesModalType.Create;

  const onCloseModal = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  const renderModalContent = () => {
    if (isCreateType) {
      return <ExperienceHeaderImageCreateForm closeModal={onCloseModal} />;
    }

    return (
      <ExperienceHeaderImageEditForm
        closeModal={onCloseModal}
        experienceHeaderImage={experienceHeaderImage}
      />
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} fullWidth>
      <Modal.Title>
        <Typography variant="h3" align="center" paddingBottom={2}>
          {isCreateType ? 'Add Images' : 'Edit Image'}
        </Typography>
      </Modal.Title>

      <StyledModalContentBox>
        <Modal.Content>{renderModalContent()}</Modal.Content>
      </StyledModalContentBox>
    </Modal>
  );
};
