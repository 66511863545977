import React, { useMemo } from 'react';
import { Grid, LinearProgress } from '@mui/material';

import { MODALS } from '@vizsla/constants';
import {
  useCustomFiltersQueryVariables,
  useModal,
  usePagination,
  usePaginationQueryVariables,
  useSearchQueryVariables,
} from '@vizsla/hooks';
import { useGetHistoryMessagesQuery } from '@vizsla/graphql';
import { PaginationProvider } from '@vizsla/providers';

import { MessageTableView } from './MessageTableView';

export function MessagePageContent() {
  const { args } = useModal(MODALS.PEOPLE_CRM_CONTACT_INFO_MODAL);

  const userId = args?.userId || '';

  const pagination = usePagination();

  const searchQueryVariables = useSearchQueryVariables(['createdBy']);

  const customFiltersQueryVariables = useCustomFiltersQueryVariables();

  const paginationQueryVariables = usePaginationQueryVariables();

  const { data: dataMessages, loading: loadingMessages } = useGetHistoryMessagesQuery({
    variables: {
      ...customFiltersQueryVariables,
      ...searchQueryVariables,
      userId,
      ...paginationQueryVariables,
    },
    fetchPolicy: 'network-only',
  });

  const Messages = useMemo(() => {
    return dataMessages?.inboxMessagesList.items ?? [];
  }, [dataMessages]);

  if (loadingMessages) {
    return (
      <Grid item>
        <LinearProgress color="secondary" />
      </Grid>
    );
  }

  return (
    <PaginationProvider>
      <MessageTableView messages={Messages} pagination={pagination} loading={loadingMessages} />
    </PaginationProvider>
  );
}
