import React from 'react';
import { Popover, List, ListItem, Checkbox, ListItemButton, Grid } from '@mui/material';

import { Button } from '@vizsla/components';

export type MultiSelectListItem = {
  key: React.Key;
  text: string;
  checked: boolean;
  disabled?: boolean;
  [key: string]: any;
};

interface MultiSelectPopoverProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  items: Array<MultiSelectListItem>;
  onSubmit: (items: Array<MultiSelectListItem>) => void;
  submitting?: boolean;
}

export const MultiSelectPopover: React.FC<MultiSelectPopoverProps> = ({
  isOpen,
  onClose,
  anchorEl,
  items: itemsProp,
  onSubmit: onSubmitProp,
  submitting = false,
}) => {
  const [items, setItems] = React.useState<Array<MultiSelectListItem>>([]);

  React.useEffect(() => {
    setItems(itemsProp);
  }, [itemsProp]);

  const onSelect = (event: React.ChangeEvent<HTMLInputElement>, selectedIndex: number) => {
    const { checked } = event.target;

    setItems(state => {
      return state.map((item, index) => {
        if (selectedIndex === index) {
          return {
            ...item,
            checked,
          };
        }

        return item;
      });
    });
  };

  const renderItem = (item: MultiSelectListItem, itemIndex: number) => {
    return (
      <ListItem
        key={item.key}
        secondaryAction={
          <Checkbox checked={item.checked} onChange={event => onSelect(event, itemIndex)} />
        }
      >
        <ListItemButton>{item.text}</ListItemButton>
      </ListItem>
    );
  };

  const onSubmit = () => {
    onSubmitProp(items);
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <List dense>{items.map(renderItem)}</List>
      <Grid container justifyContent="center" alignItems="center" p={1}>
        <Grid item>
          <Button size="small" color="info" variant="text" onClick={onClose}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button size="small" loading={submitting} disabled={submitting} onClick={onSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};
