import React, { useEffect, useMemo, useState } from 'react';
import { IconButton, Tooltip, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { PaymentMethod } from '@vizsla/graphql';

import {
  Actions,
  Card,
  FooterCardActions,
} from '../../components/organization/organizationSettingBillingComponents/PaymentMethods.styles';

interface Props {
  data: PaymentMethod;
  children: React.ReactNode;

  /** @default true */
  showRemove?: boolean;

  /** @default false */
  active?: boolean;

  onStar?: any;
  loading?: boolean;
  onRemove(): void;
}

export const PaymentMethodCardLayout = (props: Props) => {
  const handleRemove = () => {
    props.onRemove();
  };

  const handleStar = () => {
    props.onStar();
  };
  if (props.active) {
    return (
      <Card>
        {props.children}
        <FooterCardActions>
          <Actions>
            <Button
              sx={{
                backgroundColor: '#00a152',
                ':before': {
                  content: '"Active"',
                },
                ':hover': {
                  backgroundColor: 'red',
                },
                ':hover:before': {
                  content: '"Inactive"',
                },
              }}
              onClick={() => handleRemove()}
            />
          </Actions>
        </FooterCardActions>
      </Card>
    );
  }

  return (
    <Card>
      {props.children}
      <FooterCardActions>
        <Actions>
          <Tooltip title="Mark as your primary payment method">
            <LoadingButton
              onClick={handleStar}
              style={{ backgroundColor: '#e8f4fd', color: 'gray' }}
              loading={props.loading}
              disabled={props.loading}
            >
              Select
            </LoadingButton>
          </Tooltip>
        </Actions>
      </FooterCardActions>
    </Card>
  );
};
