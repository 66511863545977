import React from 'react';
import _ from 'lodash';
import { Chip, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { CAMPAIGN_STATUS_PALETTE } from '@vizsla/constants';

import { FontWeight, PaletteColor } from 'src/theme';
import { CampaignStatus } from 'src/types/campaign';

type Size = 'small' | 'medium';

interface CampaignStatusChipProps {
  status: CampaignStatus;
  icon?: React.ComponentType<any>;
  handleClick?: (...args: any) => void;
  size?: Size;
}

interface StylesParams {
  size: Size;
}

const useStyles = makeStyles<Theme, StylesParams>(() => ({
  chip: {
    color: PaletteColor.White,
    fontWeight: ({ size }) => Number(size === 'medium' ? FontWeight.Bold : FontWeight.Regular),
  },
  Draft: {
    background: CAMPAIGN_STATUS_PALETTE[CampaignStatus.draft],
  },
  Paused: {
    background: CAMPAIGN_STATUS_PALETTE[CampaignStatus.paused],
  },
  Live: {
    background: CAMPAIGN_STATUS_PALETTE[CampaignStatus.live],
  },
  Completed: {
    background: CAMPAIGN_STATUS_PALETTE[CampaignStatus.completed],
  },
}));

export const CampaignStatusChip: React.FC<CampaignStatusChipProps> = ({
  status,
  icon,
  handleClick,
  size = 'small',
}) => {
  const classes = useStyles({ size });

  if (_.isNil(status)) {
    return null;
  }

  const renderLabel = () => {
    if (_.isNil(icon)) {
      return status;
    }
    return (
      <Grid container alignItems="center">
        {status}
        {React.createElement(icon, {
          sx: {
            marginLeft: '2px',
            marginRight: '-6px',
          },
        })}
      </Grid>
    );
  };

  return (
    <Chip
      size={size}
      label={renderLabel()}
      className={clsx(classes.chip, classes[status])}
      onClick={handleClick}
    />
  );
};
