import React from 'react';
import {
  AttachMoneyOutlined,
  SwitchAccountOutlined,
  PaymentsOutlined,
  AutoAwesomeOutlined,
  LocalAtmOutlined,
} from '@mui/icons-material';
import { Box } from '@mui/material';

import { InfoCardGroup } from '@vizsla/components';
import { useOrganizationPartnershipsDashboardDataQuery } from '@vizsla/graphql';
import { useCurrentOrganization } from '@vizsla/hooks';

export const PartnershipInfoCards: React.FC = () => {
  const { organizationId } = useCurrentOrganization();
  const { loading, data: stats } = useOrganizationPartnershipsDashboardDataQuery({
    variables: { id: organizationId || '' },
    skip: !organizationId,
  });

  return (
    <Box width="100%">
      <InfoCardGroup
        data={[
          {
            loading,
            icon: <SwitchAccountOutlined color="secondary" fontSize="large" />,
            title: 'Total Partnerships',
            value: stats?.partnershipsCount?.count || 0,
          },
          {
            loading,
            icon: <AttachMoneyOutlined color="secondary" fontSize="large" />,
            title: 'Total Amount',
            value: stats?.total?.groups?.at(0)?.sum || 0,
          },
          {
            loading,
            icon: <PaymentsOutlined color="secondary" fontSize="large" />,
            title: 'Cash',
            value: stats?.cash?.groups?.at(0)?.sum || 0,
          },
          {
            loading,
            icon: <AutoAwesomeOutlined color="secondary" fontSize="large" />,
            title: 'In-Kind',
            value: stats?.inKind?.groups?.at(0)?.sum || 0,
          },
          {
            loading,
            icon: <LocalAtmOutlined color="secondary" fontSize="large" />,
            title: 'Matching',
            value: stats?.matching?.groups?.at(0)?.sum || 0,
          },
        ]}
      />
    </Box>
  );
};
