import React from 'react';
import clsx from 'clsx';
import { Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { BorderRadius, PaletteColor, Shade } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 216,
    height: 250,
    borderRadius: BorderRadius.M,
    transition: `all 0.2s ${theme.transitions.easing.easeOut}`,

    padding: theme.spacing(4, 3, 0),
    '&.empty': {
      backgroundColor: Shade.Gray[25],
      boxShadow: 'none',
    },
    '& .MuiTextField-root': {
      backgroundColor: PaletteColor.White,
    },
  },
  iconContainer: {
    '& .MuiSvgIcon-root': {
      width: 48,
      height: 48,
      fill: PaletteColor.PrimaryGray,
    },
    marginBottom: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(4),
    color: PaletteColor.PrimaryGray,
  },
}));

interface CardInputProps {
  title: string;
  icon: React.ComponentType<any>;
  input: JSX.Element;
}

export const CardInput: React.FC<CardInputProps> = ({ title, icon, input }) => {
  const classes = useStyles();

  return (
    <Paper
      variant="elevation"
      elevation={4}
      className={clsx({
        [classes.card]: true,
        empty: !input.props?.value,
      })}
    >
      <div className={classes.iconContainer}>{React.createElement(icon)}</div>
      <Typography variant="subtitle2" className={classes.title}>
        {title}
      </Typography>
      <React.Fragment>{input}</React.Fragment>
    </Paper>
  );
};
