import React from 'react';

export const DrawerContext = React.createContext<{
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  toggleOpen: () => void;
}>({
  isOpen: false,
  setIsOpen: (val: boolean) => {},
  toggleOpen: () => {},
});

export function DrawerProvider(props: React.PropsWithChildren<unknown>) {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleOpen = () => {
    setIsOpen(state => !state);
  };

  return (
    <DrawerContext.Provider value={{ isOpen, setIsOpen, toggleOpen }}>
      {props.children}
    </DrawerContext.Provider>
  );
}

export const useDrawerContext = () => React.useContext(DrawerContext);
