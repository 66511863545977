export enum PeopleCRMTabType {
  attendees = 'atendees',
  donors = 'donors',
  fundraisers = 'fundraisers',
  teams = 'teams',
  others = 'others',
}

export enum ContactInfoTabType {
  profile = 'profile',
  activity = 'activity',
  impact = 'impact',
  donations = 'donations',
  teams = 'teams',
}
