import React from 'react';
import { Grid, Typography } from '@mui/material';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { TableData } from '@vizsla/components';
import type { TableColumn } from '@vizsla/components';
import { DateFormatPatterns } from '@vizsla/constants';
import { formatDate, formatMoney, formatPercentage } from '@vizsla/utils';

import {
  PricingTierFormAction,
  PricingTierFieldValue,
  TaxDeductibleType,
} from 'src/types/pricingSettings';

import { ScaledPriceEditTierPopover } from './ScaledPriceEditTierPopover';
import { ScaledPriceDeleteTierPopover } from './ScaledPriceDeleteTierPopover';
import { ScaledPriceAddTierPopover } from './ScaledPriceAddTierPopover';
import { ScaledPricingTierFieldItem } from '../types';

const getColumns = (deleteDisabled: boolean): Array<TableColumn<ScaledPricingTierFieldItem>> => [
  {
    title: '#',
    key: 'number',
    render: (
      _field: ScaledPricingTierFieldItem,
      _record: ScaledPricingTierFieldItem,
      idx: number,
    ) => <Typography variant="subtitle2">{idx + 1}</Typography>,
  },
  {
    title: 'Start Date',
    key: 'startDate',
    dataPath: 'value.startDate',
    render: (startDate: string, _record: ScaledPricingTierFieldItem) => {
      return formatDate(startDate, DateFormatPatterns.shortMonthDayYear);
    },
  },
  {
    title: 'End Date',
    key: 'endDate',
    dataPath: 'value.endDate',
    render: (endDate: string) => {
      return formatDate(endDate, DateFormatPatterns.shortMonthDayYear);
    },
  },
  {
    title: 'Price',
    key: 'price',
    dataPath: 'value.price',
    render: (price: number) => {
      if (price === 0) {
        return 'Free';
      }
      return formatMoney(price);
    },
  },
  {
    title: 'Tax Deductible',
    key: 'taxDeductible',
    dataPath: 'value',
    render: (value: PricingTierFieldValue) => {
      const { taxDeductibleType, taxDeductiblePercentsAmount, taxDeductibleCurrencyAmount } = value;
      if (taxDeductibleType === TaxDeductibleType.Percents) {
        return formatPercentage(taxDeductiblePercentsAmount);
      }
      return formatMoney(taxDeductibleCurrencyAmount);
    },
  },
  {
    title: '',
    key: 'edit',
    render: (
      field: ScaledPricingTierFieldItem,
      _record: ScaledPricingTierFieldItem,
      idx: number,
      dataSourceArr: ScaledPricingTierFieldItem[],
    ) => {
      const next = dataSourceArr[idx + 1];
      const prev = dataSourceArr[idx - 1];
      return (
        <ScaledPriceEditTierPopover
          update={field.update}
          currentTierValue={field.value}
          nextTierValue={next?.value}
          prevTierValue={prev?.value}
        />
      );
    },
  },
  {
    title: '',
    key: 'delete',
    render: (field: ScaledPricingTierFieldItem) => {
      return (
        <ScaledPriceDeleteTierPopover
          update={field.update}
          currentValue={field.value}
          disabled={deleteDisabled}
        />
      );
    },
  },
];

interface ScaledPriceTiersFieldProps {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
}

const getDataRowsFromFields = (fields: any): ScaledPricingTierFieldItem[] =>
  fields.value
    .map(
      (fieldValue: PricingTierFieldValue, idx: number) =>
        ({
          update: (newValue: PricingTierFieldValue) => fields.update(idx, newValue),
          value: fieldValue,
        } as ScaledPricingTierFieldItem),
    )
    .filter(
      (item: ScaledPricingTierFieldItem) =>
        item.value.formAction !== PricingTierFormAction.toDelete,
    );

export const ScaledPriceTiersField: React.FC<ScaledPriceTiersFieldProps> = ({ input, meta }) => {
  const { name } = input;

  return (
    <FieldArray name={name}>
      {({ fields }) => {
        const dataRows = getDataRowsFromFields(fields);
        const deleteDisabled = dataRows.length === 1;
        const lastDataRow = dataRows[dataRows.length - 1];
        return (
          <React.Fragment>
            <TableData dataSource={dataRows} columns={getColumns(deleteDisabled)} />
            <Grid marginTop={2}>
              <ScaledPriceAddTierPopover
                addValue={fields.push}
                lastPricingTierValue={lastDataRow.value}
              />
            </Grid>
          </React.Fragment>
        );
      }}
    </FieldArray>
  );
};
