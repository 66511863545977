import React from 'react';
import { Navigate } from 'react-router-dom';

import { buildUrl } from '@vizsla/utils';
import { useOrganizationId } from '@vizsla/hooks';

import * as entitiesId from 'src/constants/entities-id';
import * as appRoutes from 'src/constants/routes';

const AdminOrganizationProfilePageIndex: React.FC = () => {
  const organizationId = useOrganizationId();

  const defaultUrl = buildUrl(appRoutes.adminOrganizationProfileInfo, {
    [entitiesId.organization]: organizationId,
  });

  return <Navigate to={defaultUrl} />;
};

export { AdminOrganizationProfilePageIndex };
