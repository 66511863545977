import React from 'react';
import _ from 'lodash';
import { ControlPoint } from '@mui/icons-material';
import { Typography } from '@mui/material';

import { useModal, usePagination } from '@vizsla/hooks';
import { TableData } from '@vizsla/components';
import type { TableColumn, TableRowAction } from '@vizsla/components';
import { getStringifiedDateRange } from '@vizsla/utils';
import { Experience, RegistrationOption } from '@vizsla/graphql';

import { useCampaignId } from 'src/hooks/campaign';
import { useExperiences } from 'src/hooks/experiences';
import { PaperBlock } from 'src/components/shared';
import { ExperienceEditModal } from 'src/modals';
import { MODALS } from 'src/constants/modals';

const EXPERIENCES_TABLE_COLUMNS: Array<TableColumn<Experience>> = [
  {
    title: 'name',
    key: 'name',
    dataPath: 'name',
    render: (experienceName: string) => {
      return <Typography variant="subtitle2">{experienceName}</Typography>;
    },
  },
  {
    title: 'type',
    key: 'experienceType',
    dataPath: 'experienceType',
  },
  {
    title: 'date',
    key: 'startDate',
    render: (valueByDataPath: undefined, experience: Experience) => {
      return getStringifiedDateRange(experience.startDate, experience.endDate);
    },
  },
  {
    title: 'attendees',
    key: 'attendees',
    dataPath: 'registrationOptions.items',
    render: (registrationOptions: RegistrationOption[] = []) => {
      const attendees = _.flatten(
        registrationOptions.map(option => option?.attendees?.items ?? []),
      );
      return attendees.length;
    },
  },
];

export const ExperiencesTable: React.FC = () => {
  const campaignId = useCampaignId();

  const pagination = usePagination();

  const {
    data: experiences,
    loading,
    deleting,
    updating,
    deleteExperience,
  } = useExperiences(campaignId);

  const { openModal } = useModal();

  const onEditExperience = React.useCallback(
    (experience: Experience) => {
      openModal(MODALS.EXPERIENCE_EDIT_MODAL, { experience });
    },
    [openModal],
  );

  const rowActions: Array<TableRowAction<Experience>> = [
    {
      actionName: 'Edit',
      onAction: onEditExperience,
    },
    {
      actionName: 'Delete',
      onAction: (experience: Experience) => {
        openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
          deleteFunction: () => deleteExperience(experience?.id as string),
          objectName: experience?.name,
          objectType: 'experience',
        });
      },
    },
  ];

  const bottomActions = [
    {
      actionName: 'add experience',
      icon: <ControlPoint />,
      onAction: () => openModal(MODALS.EXPERIENCE_CREATE_MODAL),
    },
  ];

  return (
    <React.Fragment>
      <PaperBlock title="Experiences">
        <TableData
          dataSource={experiences}
          columns={EXPERIENCES_TABLE_COLUMNS}
          rowActions={rowActions}
          bottomActions={bottomActions}
          pagination={pagination}
          loading={loading || deleting || updating}
        />
      </PaperBlock>
      <ExperienceEditModal />
    </React.Fragment>
  );
};
