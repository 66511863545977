import React from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { nanoid } from 'nanoid';

import { EXPERIENCE_INITIAL_FORM_FIELDS } from 'src/constants/campaign';

type ExperienceFormCollectionHook = {
  fields: any;
  selectedForm: number;
  selectForm: (formIndex: number) => void;
  canRemoveForm: boolean;
  canAddForm: boolean;
  addForm: () => void;
  removeForm: () => void;
};

const MAX_COUNT_FORMS = 5;

export function useExperienceFormCollection(): ExperienceFormCollectionHook {
  const { fields } = useFieldArray('experiences');

  const [selectedForm, setSelectedForm] = React.useState<number>(0);

  const formCollection = [...fields.value];

  function selectForm(formIndex: number) {
    setSelectedForm(formIndex);
  }

  function addForm() {
    fields.push({ ...EXPERIENCE_INITIAL_FORM_FIELDS, uid: nanoid() });

    setSelectedForm(fields.length as number);
  }

  function removeForm() {
    fields.remove(selectedForm);
    const lastFormIndex = formCollection.length - 1;

    setSelectedForm((selectedForm: number) => {
      const isLastSelectedFormIndex = selectedForm === lastFormIndex;

      if (isLastSelectedFormIndex) {
        setSelectedForm(lastFormIndex - 1);
      }

      return selectedForm;
    });
  }

  const canRemoveForm = (fields.length as number) > 1;
  const canAddForm = (fields.length as number) < MAX_COUNT_FORMS;

  return { fields, selectedForm, selectForm, canAddForm, addForm, removeForm, canRemoveForm };
}
