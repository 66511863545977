export const CAMPAIGN = 'campaign';
export const EXPERIENCE = 'experience';
export const AGREEMENT_FILE = 'agreement';
export const DATE_SINGLE = 'single';
export const DATE_RANGE = 'range';

export enum CashCommitmentStatusE {
  pending = 'pending',
  paid = 'paid',
}
export const cashCommitmentStatusDict = {
  pending: 'Pending',
  paid: 'Paid',
};
