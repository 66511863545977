import React from 'react';

import { PartnershipPageLayout } from './PartnershipPageLayout';
import { PartnershipsListContent } from './PartnershipListContent';
import { PartnershipModal } from '../../modals/Partnerships';

export const PartnershipDashboard = () => {
  return (
    <React.Fragment>
      <PartnershipPageLayout>
        <PartnershipsListContent />
        <PartnershipModal />
      </PartnershipPageLayout>
    </React.Fragment>
  );
};
