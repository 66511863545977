import React from 'react';
import { Box, Grid, Link, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import { PaperBlock, ShowInvoices } from '@vizsla/components';
import { PaletteColor } from '@vizsla/theme';
import { useCurrentUser, useCurrentOrganization } from '@vizsla/hooks';
import {
  StripePlansResponse,
  VizslaStripePlanListResponse,
  useUsersStripePlansQuery,
  useStripePlansQuery,
} from '@vizsla/graphql';

import { PaymentMehod, ShowPlans } from './organizationSettingBillingComponents';

type ActivePlan = 'monthly' | 'yearly';

export const OrganizationSettingsBilling: React.FC = () => {
  const { data } = useStripePlansQuery();
  const [activePlans, setActivePlans] = React.useState<ActivePlan>('monthly');
  const [showPlans, setShowPlans] = React.useState<boolean>(true);
  const { user } = useCurrentUser();
  const { organizationId } = useCurrentOrganization();
  const userId = React.useMemo(() => user?.id, [user]);
  const { data: userStripePlan } = useUsersStripePlansQuery({
    variables: {
      filter: { organizationId: { equals: organizationId } },
    },
  });

  const products = React.useMemo(() => {
    if (data?.stripePlans?.length) {
      const products: any = [];
      for (const item of data.stripePlans) {
        const newProduct: any = JSON.parse(JSON.stringify(item));
        const prices = item!.prices ?? [];
        const monthly = prices.filter(element => element?.recurring?.interval === 'month') || [];
        const yearly = prices.filter(element => element?.recurring?.interval === 'year') || [];
        newProduct.pricesInterval = {
          monthly,
          yearly,
        };
        products.push(newProduct);
      }
      return products;
    }
    return [];
  }, [data]);

  const vizlaUserStripePlan = React.useMemo(() => {
    return userStripePlan?.vizslaStripePlansList?.items || [];
  }, [userStripePlan]);

  const customerId = React.useMemo(() => {
    if (vizlaUserStripePlan.length > 0) {
      return userStripePlan?.vizslaStripePlansList?.items[0].customerId;
    }
    return '';
  }, [vizlaUserStripePlan]);

  const handleButtonToggle = (_: React.ChangeEvent<any>, newButton: ActivePlan) => {
    setActivePlans(newButton);
  };

  const handleHideAllPlan = () => {
    setShowPlans(!showPlans);
  };
  return (
    <Grid>
      <Typography variant="h3" marginBottom={1}>
        Billing
      </Typography>
      <Typography variant="body2" marginBottom={4}>
        Review your Vizsla plan and billing information
      </Typography>
      <PaperBlock
        titleContent={
          <Box>
            <Typography variant="h5" color={PaletteColor.BlueText}>
              Plans
            </Typography>
            <Typography variant="body2" marginBottom={4}>
              Choose the plan the works best for you and your team.
            </Typography>
          </Box>
        }
        key="StipePlans"
        switchProps={{
          checked: showPlans,
          onSwitch: handleHideAllPlan,
        }}
        defaultOpened
        defaultChecked={showPlans}
        collapsing
        collapsed={showPlans}
      >
        <Grid item marginBottom={10}>
          <ToggleButtonGroup
            exclusive
            value={activePlans}
            onChange={handleButtonToggle}
            size="medium"
          >
            <ToggleButton value="monthly" disabled={activePlans === 'monthly'}>
              Monthly
            </ToggleButton>
            <ToggleButton value="yearly" disabled={activePlans === 'yearly'}>
              Yearly
            </ToggleButton>
          </ToggleButtonGroup>
          <Box>
            {activePlans === 'monthly' && (
              <ShowPlans
                activePlans={activePlans}
                products={products}
                vizlaUserStripePlan={vizlaUserStripePlan}
              />
            )}
            {activePlans === 'yearly' && (
              <ShowPlans
                activePlans={activePlans}
                products={products}
                vizlaUserStripePlan={vizlaUserStripePlan}
              />
            )}
          </Box>
          <Link component="button" variant="body2" onClick={handleHideAllPlan}>
            Hide All Plans
          </Link>
        </Grid>
      </PaperBlock>
      <PaperBlock
        titleContent={
          <Box>
            <Typography variant="h5" color={PaletteColor.BlueText}>
              Payment Method
            </Typography>
            <Typography variant="body2" marginBottom={4}>
              Description goes here
            </Typography>
          </Box>
        }
        key="StipePlansInvoices"
        collapsing
        defaultOpened={false}
      >
        <ShowInvoices customerId={customerId || ''} />
      </PaperBlock>
      <PaperBlock
        titleContent={
          <Box>
            <Typography variant="h5" color={PaletteColor.BlueText}>
              Payment Method
            </Typography>
            <Typography variant="body2" marginBottom={4}>
              Description goes here
            </Typography>
          </Box>
        }
        key="StipePaymentMethod"
        collapsing
        defaultOpened={false}
      >
        <Typography variant="body2" marginBottom={4}>
          Your credit card will be charged with the plan of your choice and auto charge{' '}
          {activePlans} based in your usage.
        </Typography>
        <PaymentMehod />
      </PaperBlock>
    </Grid>
  );
};
