import {
  ActionButtonBackgroundType,
  HeroMediaType,
  PageBackgroundType,
  RegistrationsSectionDisplayStyleType,
  HeaderLogoAlignType,
} from '@vizsla/constants';
import { Experience, File } from '@vizsla/graphql';

import templateSchema from './templateSchema.json';

type Nodes = {
  [NodeId: string]: {
    id: string;
    name: string;
    parentID: string | null;
    props: {
      children: {
        nodes: Array<string>;
      };
      [prop: string]: any;
    };
    alias: string;
  };
};

export type Theme = {
  headerBackground: string;
  headerLogoAlign: HeaderLogoAlignType;
  accentColor: string;
  textLinksColor: string;
  progressBarBegin: string;
  progressBarEnd: string;
  footerBackground: string;
  footerTextColor: string;
  pageBackgroundFirstColor: string;
  pageBackgroundSecondColor: string;
  pageBackgroundType: PageBackgroundType;
  heroMediaType: HeroMediaType;
  optionCardTextColor: string;
  optionCardBackgroundColor: string;
  colorLibrary: Array<string>;
  isProgressBarRaisedAmountVisible: boolean;
  isProgressBarGoalAmountVisible: boolean;
  isOurStoryTitleVisible: boolean;
  isThankYouMessageVisible: boolean;
  isDonationInformationTitleVisible: boolean;
  isDonationInformationDescriptionVisible: boolean;
  isManageDonationTitleVisible: boolean;
  isManageDonationDescriptionVisible: boolean;
  isHelpTitleVisible: boolean;
  isHelpDescriptionVisible: boolean;
  isLeaderboardVisible: boolean;
  isActivityVisible: boolean;
  registrationButtonLabel: string;
  registrationButtonBackgrondType: ActionButtonBackgroundType;
  registrationButtonIsVisible: boolean;
  donationButtonLabel: string;
  donationButtonBackgrondType: ActionButtonBackgroundType;
  donationButtonIsVisible: boolean;
  shareButtonLabel: string;
  shareButtonBackgrondType: ActionButtonBackgroundType;
  shareButtonIsVisible: boolean;
  registrationsSectionTitle: string;
  registrationsSectionDescription: string;
  registrationsSectionDisplayStyle: RegistrationsSectionDisplayStyleType;
  ourStorySectionTitle: string;
  thankYouMessage: string;

  gallerySectionTitle: string;
  gallerySectionDescription: string;
  gallerySectionImagesPerRows: number;
  gallerySectionRows: number;
  gallerySectionImages: unknown[]; // TODO: Add structured type to images
};

const INITIAL_COLOR_LIBRARY = [
  '#202B38',
  '#fff',
  '#E7E9EB',
  '#A0A7AF',
  '#EC3350',
  '#F64D73',
  '#BC42AA',
  '#6739B6',
  '#2A9AFF',
  '#6EC6EE',
  '#28C2D5',
  '#00D687',
  '#FFC109',
  '#F97309',
];

export const INITIAL_THEME: Theme = {
  headerBackground: '#fff',
  textLinksColor: '#fff',
  progressBarBegin: '#fff',
  progressBarEnd: '#00d687',
  footerBackground: '#fff',
  footerTextColor: '#fff',
  accentColor: '#202B38',
  headerLogoAlign: HeaderLogoAlignType.left,
  pageBackgroundFirstColor: '#fff',
  pageBackgroundSecondColor: '#fff',
  pageBackgroundType: PageBackgroundType.white,
  heroMediaType: HeroMediaType.single,
  optionCardTextColor: '#fff',
  optionCardBackgroundColor: '#fff',
  colorLibrary: INITIAL_COLOR_LIBRARY,
  isProgressBarRaisedAmountVisible: true,
  isProgressBarGoalAmountVisible: true,
  isOurStoryTitleVisible: true,
  isThankYouMessageVisible: true,
  isDonationInformationTitleVisible: true,
  isDonationInformationDescriptionVisible: true,
  isManageDonationTitleVisible: true,
  isManageDonationDescriptionVisible: true,
  isHelpTitleVisible: true,
  isHelpDescriptionVisible: true,
  isLeaderboardVisible: true,
  isActivityVisible: true,
  registrationButtonLabel: 'Register Now',
  registrationButtonBackgrondType: ActionButtonBackgroundType.filled,
  registrationButtonIsVisible: true,
  donationButtonLabel: 'Make a Donation',
  donationButtonBackgrondType: ActionButtonBackgroundType.filled,
  donationButtonIsVisible: true,
  shareButtonLabel: 'Share',
  shareButtonBackgrondType: ActionButtonBackgroundType.filled,
  shareButtonIsVisible: true,
  registrationsSectionTitle: 'Make a difference',
  registrationsSectionDescription:
    'You can help fundraise for the cause once you register, or just make a donation.',
  registrationsSectionDisplayStyle: RegistrationsSectionDisplayStyleType.cards,
  ourStorySectionTitle: 'Our Story',
  thankYouMessage: 'Thank you message for the donor',

  gallerySectionTitle: 'Make a difference',
  gallerySectionDescription: '',
  gallerySectionImagesPerRows: 4,
  gallerySectionRows: 1,
  gallerySectionImages: [],
};

export type LandingState = {
  theme: Theme;
  nodes: Nodes;
  templateTitle: string;
  templateStructureId: string;
};

export const INITIAL_STATE: LandingState = {
  theme: INITIAL_THEME,
  nodes: templateSchema.appSchema.nodes,
  templateTitle: 'Template Title',
  templateStructureId: '',
};

export interface LandingAction<Type = any, Payload = any> {
  type: Type;
  payload: Payload;
}

export enum GeneralLandingActions {
  set = 'set',
}

export enum ExperienceLandingActions {
  updateAccentColor = 'updateAccentColor',
  updateColorLibrary = 'updateColorLibrary',
  updateProgressBarColor = 'updateProgressBarColor',
  updatePageBackgroundType = 'updatePageBackgroundType',
  updatePageBackgroundFirstColor = 'updatePageBackgroundFirstColor',
  updatePageBackgroundSecondColor = 'updatePageBackgroundSecondColor',
  updateRegistrationButtonLabel = 'updateRegistrationButtonLabel',
  updateDonationButtonLabel = 'updateDonationButtonLabel',
  updateShareButtonLabel = 'updateShareButtonLabel',
  updateRegistrationButtonBackgrondType = 'updateRegistrationButtonBackgrondType',
  updateDonationButtonBackgrondType = 'updateDonationButtonBackgrondType',
  updateShareButtonBackgrondType = 'updateShareButtonBackgrondType',
  updateIsRegistrationButtonVisible = 'updateIsRegistrationButtonVisible',
  updateIsDonationButtonVisible = 'updateIsDonationButtonVisible',
  updateIsShareButtonVisible = 'updateIsShareButtonVisible',
  updateIsProgressBarRaisedAmountVisible = 'updateIsProgressBarRaisedAmountVisible',
  updateIsProgressBarGoalAmountVisible = 'updateIsProgressBarGoalAmountVisible',
  updateisOurStoryTitleVisible = 'updateisOurStoryTitleVisible',
  updateIsLeaderboardVisible = 'updateisLeaderboardVisible',
  updateIsActivityVisible = 'updateIsActivityVisible',
  updateisThankYouMessageVisible = 'updateisThankYouMessageVisible',
  updateHeroMediaType = 'updateHeroMediaType',
  updateHeaderLogoAlign = 'updateHeaderLogoAlign',
  updateRegistrationsSectionTitle = 'updateRegistrationsSectionTitle',
  updateRegistrationsSectionDescription = 'updateRegistrationsSectionDescription',
  updateRegistrationsSectionDisplayStyle = 'updateRegistrationsSectionDisplayStyle',
  resetThemeColors = 'resetThemeColors',
  updateOurStorySectionTitle = 'updateOurStorySectionTitle',
  updateThankYouMessage = 'updateThankYouMessage',
  updateManageDonationSectionTitle = 'updateManageDonationSectionTitle',
  updateManageDonationSectionDescription = 'updateManageDonationSectionDescription',
  updateDonationInformationSectionTitle = 'updateDonationInformationSectionTitle',
  updateDonationInformationSectionDescription = 'updateDonationInformationSectionDescription',
  updateHelpSectionTitle = 'updateHelpSectionTitle',
  updateHelpSectionDescription = 'updateHelpSectionDescription',
  updateisManageDonationTitleVisible = 'updateisManageDonationTitleVisible',
  updateisManageDonationDescriptionVisible = 'updateisManageDonationDescriptionVisible',
  updateisDonationInformationTitleVisible = 'updateisDonationInformationTitleVisible',
  updateisDonationInformationDescriptionVisible = 'updateisDonationInformationDescriptionVisible',
  updateisHelpTitleVisible = 'updateisHelpTitleVisible',
  updateisHelpDescriptionVisible = 'updateisHelpDescriptionVisible',

  // Gallery

  /** @deprecated The [VSL-212](https://8base-dev.atlassian.net/browse/VSL-212) was redesign by [comment](https://8base-dev.atlassian.net/browse/VSL-212?focusedCommentId=26224). */
  UPDATE_GALLERY_TITLE = 'UPDATE_LANDING_GALLERY_TITLE',
  /** @deprecated The [VSL-212](https://8base-dev.atlassian.net/browse/VSL-212) was redesign by [comment](https://8base-dev.atlassian.net/browse/VSL-212?focusedCommentId=26224). */
  UPDATE_GALLERY_DESCRIPTION = 'UPDATE_LANDING_GALLERY_DESCRIPTION',
  /** @deprecated The [VSL-212](https://8base-dev.atlassian.net/browse/VSL-212) was redesign by [comment](https://8base-dev.atlassian.net/browse/VSL-212?focusedCommentId=26224). */
  UPDATE_GALLERY_ROWS = 'UPDATE_LANDING_GALLERY_ROWS',
  /** @deprecated The [VSL-212](https://8base-dev.atlassian.net/browse/VSL-212) was redesign by [comment](https://8base-dev.atlassian.net/browse/VSL-212?focusedCommentId=26224). */
  UPDATE_GALLERY_IMAGES_PER_ROWS = 'UPDATE_LANDING_GALLERY_IMAGES_PER_ROWS',
  /** @deprecated The [VSL-212](https://8base-dev.atlassian.net/browse/VSL-212) was redesign by [comment](https://8base-dev.atlassian.net/browse/VSL-212?focusedCommentId=26224). */
  UPDATE_GALLERY_IMAGES = 'UPDATE_LANDING_GALLERY_IMAGES',
}

export type ExperienceLandingElementProps = {
  experience: Experience;
  theme: Theme;
};

type ExperienceLandingNodeItemType = {
  element: React.ElementType<ExperienceLandingElementProps>;
  divider: 'none' | 'after' | 'before';
};

export type ExperienceLandingNodesType = Record<string, ExperienceLandingNodeItemType>;

export interface StyledExperienceDescriptionProps {
  hasBackgroundColorEffect: boolean;
  theme: Theme;
}

export interface ExperienceLandingProps {
  experience: Experience | null | undefined;
  loading: boolean;
  experienceLandingState: LandingState;
  headerImages: Array<File> | null;
  editorMode?: boolean;
}
