import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TemplateType } from '@vizsla/types';
import { Button, ExternalLink } from '@vizsla/components';
import { LandingRoutes, RouterEntitiesId } from '@vizsla/constants';
import { Campaign, Experience } from '@vizsla/graphql';
import { usePopover } from '@vizsla/hooks';
import { buildUrl } from '@vizsla/utils';

import * as router from 'src/constants/routes';
import { useExperiences } from 'src/hooks/experiences';
import { useFullPublicWebLink } from 'src/hooks/publicPages';
import { EditorCampaignPagesRoutes, CampaignPagesRoutesPerTemplate } from 'src/types/campaignPages';

const useStyles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    gap: theme.spacing(1),
  },

  button: {
    margin: 0,
  },
}));

export interface CardOptionsExperienceProps {
  campaign: Campaign;
  type: TemplateType;
}

export function CardOptionsExperience(props: CardOptionsExperienceProps) {
  const styles = useStyles();
  const popover = usePopover();
  const navigate = useNavigate();

  const { data: experiences, loading } = useExperiences(props.campaign.id ?? '');
  const { fullPublicWebLink: publicURL } = useFullPublicWebLink(
    props.campaign.dnsRecord?.slug ?? undefined,
  );

  function generateExperiencePublicURL(experience: Experience): string | undefined {
    if (!experience?.id) return undefined;

    const route = CampaignPagesRoutesPerTemplate[props.type];
    const path = LandingRoutes[route];

    const params = {
      [RouterEntitiesId.experience]: experience.id,
    };

    const URL = buildUrl(path, params);

    return `${publicURL}${URL}`;
  }

  const editorURL = useMemo(() => {
    if (!props.campaign.id) return '';

    const routes = router as Record<string, string>;
    const path = routes[EditorCampaignPagesRoutes[props.type]];

    const params = {
      [RouterEntitiesId.campaign]: props.campaign.id,
    };

    return buildUrl(path, params);
  }, []);

  const landings = useMemo(() => {
    if (Array.isArray(experiences)) {
      return experiences.map(ex => {
        const url = generateExperiencePublicURL(ex);

        return {
          id: ex.id,
          label: ex.name,
          url,
        };
      });
    }

    return [];
  }, [experiences]);

  const openEditor = () => {
    navigate(editorURL);
  };

  return (
    <div>
      <Grid container className={styles.buttons}>
        <Button
          variant="text"
          size="small"
          color="primary"
          className={styles.button}
          disabled={!editorURL}
          onClick={openEditor}
        >
          Edit
        </Button>

        <div>
          <Button
            variant="text"
            size="small"
            color="secondary"
            className={styles.button}
            disabled={loading}
            loading={loading}
            onClick={popover.openPopover}
          >
            View
          </Button>

          <Menu anchorEl={popover.el} open={popover.isOpen} onClose={popover.closePopover}>
            {landings.map(d => (
              <ExternalLink key={d.id} underline="none" href={d.url}>
                <MenuItem>{d.label}</MenuItem>
              </ExternalLink>
            ))}
          </Menu>
        </div>
      </Grid>
    </div>
  );
}
