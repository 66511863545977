import React from 'react';
import _ from 'lodash';

import { isFunction } from '@vizsla/utils';

export interface RadioGroupProps {
  name?: string;
  defaultValue?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  defaultValue,
  onChange: onChangeProp,
  children,
}) => {
  const initialValue = defaultValue ?? undefined;
  const [value, setValue] = React.useState<any>(initialValue);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);

    if (onChangeProp && isFunction(onChangeProp)) {
      const modifiedEvent = name ? _.set(event, 'target.name', name) : event;

      onChangeProp(modifiedEvent);
    }
  };

  const childrenArray = React.Children.toArray(children);

  const renderChildWithRadioProps = (child: any) => {
    return React.cloneElement(child, {
      checked: _.isEqual(child.props?.value, value),
      onChange,
    });
  };

  return (
    <div style={{ display: 'inline-block' }}>
      {childrenArray.map(child => renderChildWithRadioProps(child))}
    </div>
  );
};
