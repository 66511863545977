import {
  TicketingOptionFragmentFragment as TicketingOption,
  TicketingOptionsListQuery,
  TicketingOptionUpdateInput,
  TicketingOptionCreateInput,
  TicketingOptionsListDocument,
  TicketingOptionsListQueryVariables,
  useTicketingOptionCreateMutation,
  useTicketingOptionDeleteMutation,
  useTicketingOptionsListQuery,
  useTicketingOptionUpdateMutation,
} from '@vizsla/graphql';
import { useApolloCacheQueryManager, useExperienceId } from '@vizsla/hooks';
import { mutableList } from '@vizsla/utils';

type TicketingOptionsOutput = {
  ticketingOptions: Array<TicketingOption>;
  createTicketingOption: (data: TicketingOptionCreateInput) => Promise<TicketingOption>;
  updateTicketingOption: (id: string, data: TicketingOptionUpdateInput) => Promise<void>;
  deleteTicketingOption: (id: string) => Promise<void>;
  loading: boolean;
  creating: boolean;
  updating: boolean;
  deleting: boolean;
};

export const useTicketingOptions = (): TicketingOptionsOutput => {
  const experienceId = useExperienceId();

  const ticketingOptionsVariables = {
    filter: {
      experience: {
        id: {
          equals: experienceId,
        },
      },
    },
  };

  const { data, loading } = useTicketingOptionsListQuery({
    variables: ticketingOptionsVariables,
    skip: !experienceId,
  });
  const { updateQuery: updateTicketingOptionsListQuery } = useApolloCacheQueryManager<
    TicketingOptionsListQuery,
    TicketingOptionsListQueryVariables
  >({
    query: TicketingOptionsListDocument,
    typeName: 'TicketingOptionListResponse',
    variables: ticketingOptionsVariables,
  });

  const ticketingOptions = data?.ticketingOptionsList?.items || [];

  const [ticketingOptionCreateMutation, { loading: creating }] = useTicketingOptionCreateMutation();
  const [ticketingOptionUpdateMutation, { loading: updating }] = useTicketingOptionUpdateMutation();
  const [ticketingOptionDeleteMutation, { loading: deleting }] = useTicketingOptionDeleteMutation();

  const createTicketingOption = async (data: TicketingOptionCreateInput) => {
    const res = await ticketingOptionCreateMutation({
      variables: {
        data,
      },
      update(_, { data }) {
        const createdTicketingOption = data?.ticketingOptionCreate;
        if (createdTicketingOption) {
          updateTicketingOptionsListQuery(query => {
            mutableList(query.ticketingOptionsList.items).add(createdTicketingOption);
          });
        }
      },
    });

    return res?.data?.ticketingOptionCreate as TicketingOption;
  };

  const updateTicketingOption = async (id: string, data: TicketingOptionUpdateInput) => {
    await ticketingOptionUpdateMutation({
      variables: {
        filter: {
          id,
        },
        data,
      },
    });
  };

  const deleteTicketingOption = async (id: string) => {
    await ticketingOptionDeleteMutation({
      variables: {
        id,
      },
      update() {
        updateTicketingOptionsListQuery(query => {
          mutableList(query.ticketingOptionsList.items).removeById(id);
        });
        return Promise.resolve();
      },
    });
  };

  return {
    ticketingOptions,
    createTicketingOption,
    updateTicketingOption,
    deleteTicketingOption,
    loading,
    creating,
    updating,
    deleting,
  };
};
