import React from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useModal } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import { PeopleCRMTabType } from 'src/types/peopleCRM';

import { ContactInfoModalContent } from './components/ContactInfoModalContent';
import { ContactInfoModalOthers } from './components/ContactInfoModalOthers';

interface CRMTableProps {
  tabType: PeopleCRMTabType;
}

const useStyles = makeStyles((theme: Theme) => ({
  modalWrapper: {
    '& .MuiDialog-container': {
      justifyContent: 'flex-end',
    },
    '& .MuiDialog-paper': {
      width: '80%',
      maxWidth: 'unset',
      height: '100%',
      maxHeight: 'unset',
      margin: 0,
    },
  },
}));

const ContactInfoModal: React.FC<CRMTableProps> = ({ tabType }) => {
  const classes = useStyles();

  const { isOpen, closeModal, args } = useModal(MODALS.PEOPLE_CRM_CONTACT_INFO_MODAL);

  const userId = args?.userId;

  const other = args?.other;

  const handleClose = () => {
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      slideDirection="left"
      customClasses={[classes.modalWrapper]}
      onClose={handleClose}
    >
      <Modal.Title />

      <Modal.Content>
        {!other ? (
          <ContactInfoModalContent userId={userId} tabType={tabType} />
        ) : (
          <ContactInfoModalOthers other={other} />
        )}
      </Modal.Content>
    </Modal>
  );
};

export { ContactInfoModal };
