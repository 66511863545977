import React, { useEffect, useState } from 'react';
import { CircularProgress, Dialog } from '@mui/material';
import { Stripe, StripeElements } from '@stripe/stripe-js';

import { useCurrentUser, useModal } from '@vizsla/hooks';

import { MODALS } from 'src/constants/modals';
import { usePaymentMethodSetup } from 'src/hooks/stripe';
import { StripeProvider } from 'src/providers/stripe/StripeProvider';

import { PaymentMethodForm, Values } from './PaymentMethodForm';
import { Container, LoaderContainer, Title } from './CreatePaymentMethod.styles';

// interface Props {
//   open(): void;

//   onClose(): void;
// }

export const CreatePaymentMethod = () => {
  const [fetching, setFetching] = useState(false);
  const { isOpen, closeModal, args } = useModal(MODALS.ORGANIZATION_SETTING_CREATE_PAYMENT_METHOD);

  const [stripeSecret, setStripeSecret] = useState<string>();

  const { user } = useCurrentUser();
  const userId = React.useMemo(() => user?.id, [user]);
  const { execute: createSetup } = usePaymentMethodSetup();

  const initialize = async () => {
    if (!userId || Boolean(stripeSecret)) return;

    setFetching(true);

    try {
      const setup = await createSetup({ userId });
      setStripeSecret(setup.secret);
      setFetching(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isOpen && !stripeSecret) {
      initialize();
    }

    return () => {
      setStripeSecret(undefined);
    };
  }, [isOpen]);

  const handleCancel = () => {
    closeModal();
  };

  const handleSubmit = async (values: Values, stripe: Stripe, elements: StripeElements) => {
    const response = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (response.error) {
      console.error(response.error.message);
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={handleCancel}>
      {fetching || !stripeSecret ? (
        <React.Fragment>
          <Title>New Billing Method</Title>

          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Title>New Billing Method</Title>
          <Container>
            <StripeProvider clientSecret={stripeSecret}>
              <PaymentMethodForm
                labelSubmit="Add new billing method"
                onSubmit={handleSubmit}
                onCancel={handleCancel}
              />
            </StripeProvider>
          </Container>
        </React.Fragment>
      )}
    </Dialog>
  );
};
