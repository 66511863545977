import React from 'react';
import { Grid, styled } from '@mui/material';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';

import { useNotification, usePaginationQueryVariables } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { Modal } from '@vizsla/components';

import { useStoreHistory, useStoreAssetId, useStoreAsset } from 'src/hooks/storeAssets';
import type { StoreAssetInventoryAddOrAdjustFormValues } from 'src/types/storeAssets';
import { StoreHistoryEventType } from 'src/types/storeAssets';
import { t } from 'src/utils/template';
import { StoreAsseInventoryAddOrAdjustFormSchema } from 'src/constants/validationSchemas/storeAssets';

import { getStoreHistoryEventCreateDataInput } from '../../utils';
import {
  FormCommonInfoSection,
  FormAttributesSection,
  FormOrderInfoSection,
  FormAdditionalInfoSection,
} from './sections';

const StyledFormNode = styled('form')(({ theme }) => ({
  display: 'contents',
}));

interface StoreAssetUpdateImageFormProps {
  closeModal: () => void;
}

const StoreAssetInventoryAddForm: React.FC<StoreAssetUpdateImageFormProps> = ({ closeModal }) => {
  const notification = useNotification();
  const { storeAsset } = useStoreAsset();
  const storeAssetId = useStoreAssetId();
  const paginationQueryVariables = usePaginationQueryVariables();

  const {
    createStoreHistoryEvent,
    creating: loading,
    refetchHistory,
  } = useStoreHistory(storeAssetId, paginationQueryVariables);

  const onSubmit = React.useCallback(
    async (formValues: StoreAssetInventoryAddOrAdjustFormValues, form: FormApi<any>) => {
      try {
        const inputObject = getStoreHistoryEventCreateDataInput(
          formValues,
          storeAssetId,
          StoreHistoryEventType.Add,
        );

        if (!inputObject.variantsValues) {
          const mapVariants = Object.keys(storeAsset.variantsPossible).reduce(
            (acumm, key) => ({ ...acumm, [key]: '' }),
            {},
          );

          inputObject.variantsValues = mapVariants;
        }

        await createStoreHistoryEvent(inputObject);

        notification.success(t('store_asset_update_success'));
        refetchHistory();
        closeModal();
      } catch (err) {
        notification.error(t('store_asset_update_error'));
        console.error(err);
      }
    },
    [storeAssetId, createStoreHistoryEvent, notification, closeModal],
  );

  const validate = React.useCallback(
    async (formValues: StoreAssetInventoryAddOrAdjustFormValues) => {
      return validateWithSchema(StoreAsseInventoryAddOrAdjustFormSchema, formValues);
    },
    [],
  );

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting, pristine, validating, invalid }) => {
        const isSubmitLoading = submitting || validating || loading;
        const isSubmitDisabled = isSubmitLoading || pristine || invalid;

        return (
          <StyledFormNode onSubmit={handleSubmit}>
            <Grid container spacing={2.5}>
              <FormCommonInfoSection />

              <FormAttributesSection />

              <FormOrderInfoSection />

              <FormAdditionalInfoSection />

              <Grid item xs={12}>
                <Modal.Actions>
                  <Modal.Action
                    type="secondary"
                    content="Cancel"
                    onAction={closeModal}
                    disabled={isSubmitDisabled}
                  />

                  <Modal.Action
                    type="primary"
                    content="Add"
                    onAction={handleSubmit}
                    disabled={isSubmitDisabled}
                    loading={isSubmitLoading}
                  />
                </Modal.Actions>
              </Grid>
            </Grid>
          </StyledFormNode>
        );
      }}
    />
  );
};

export { StoreAssetInventoryAddForm };
