import React from 'react';
import { Grid } from '@mui/material';

import { ExperienceType } from '@vizsla/types';
import { useExperienceId } from '@vizsla/hooks';
import { Field, TextField, SelectField, QrAndCopyWebLink } from '@vizsla/components';

import {
  EXPERIENCE_REGISTRATION_CATEGORY_OPTIONS,
  EXPERIENCE_TICKETING_CATEGORY_OPTIONS,
} from 'src/constants/experience';
import { useFullPublicWebLink } from 'src/hooks/publicPages';
import { useCurrentCampaign } from 'src/hooks/campaign';

interface GeneralBlockProps {
  spacing: number;
  experienceType?: string | null;
}

export const GeneralBlock: React.FC<GeneralBlockProps> = ({ spacing, experienceType }) => {
  const { data: campaign } = useCurrentCampaign();
  const { fullPublicWebLink: campaignLink } = useFullPublicWebLink(
    campaign?.dnsRecord?.slug as string,
  );

  const experienceId = useExperienceId();

  const experienceCategoryOptions =
    experienceType === ExperienceType.registration
      ? EXPERIENCE_REGISTRATION_CATEGORY_OPTIONS
      : EXPERIENCE_TICKETING_CATEGORY_OPTIONS;

  const formattedWebLink = `${campaignLink}/experience/${experienceId}`;

  return (
    <Grid container item direction="row" spacing={spacing}>
      <Grid item flexBasis={0} flexGrow={1}>
        <Field label="Experience Name *" name="name" fullWidth component={TextField} />
      </Grid>
      <Grid item flexBasis={0} flexGrow={1}>
        <Field
          label="Experience Category"
          name="experienceCategory"
          options={experienceCategoryOptions}
          component={SelectField}
          fullWidth
          required
        />
      </Grid>
      <QrAndCopyWebLink webLink={formattedWebLink} />
    </Grid>
  );
};
