import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  PersonalMissionRecentActivityQuery,
  PersonalMissionRecentActivityQueryVariables,
} from '@vizsla/graphql';
import { useTabs } from '@vizsla/components';

import { PaperBlock } from 'src/components/shared';
import {
  CampaignPMRecentFundraisersTable,
  CampaignPMRecentTeamsTable,
  CampaignPMRecentDonationsTable,
} from 'src/components/campaign';
import { PERSONAL_MISSION_RECENT_ACTIVITY_QUERY } from 'src/graphql';
import {
  PersonalMissionRecentActivityTabKey,
  CampaignDetailItem,
  PeopleTablesTab,
} from 'src/types/campaign';
import { FontSize, PaletteColor } from 'src/theme';

const useStyles = makeStyles(() => ({
  showSummary: {
    color: PaletteColor.GrayText,
  },
  viewAllLink: {
    fontSize: FontSize.Default,
  },
}));

interface PersonalMissionRecentActivityProps {
  campaign: CampaignDetailItem;
}

const PEOPLE_TABLES_MAP = {
  [PersonalMissionRecentActivityTabKey.Fundraisers]: PeopleTablesTab.Fundraisers,
  [PersonalMissionRecentActivityTabKey.Teams]: PeopleTablesTab.Teams,
  [PersonalMissionRecentActivityTabKey.Donations]: PeopleTablesTab.Donors,
};

export const PersonalMissionRecentActivity: React.FC<PersonalMissionRecentActivityProps> = ({
  campaign,
}) => {
  const classes = useStyles();

  const { data: recentActivity, loading } = useQuery<
    PersonalMissionRecentActivityQuery,
    PersonalMissionRecentActivityQueryVariables
  >(PERSONAL_MISSION_RECENT_ACTIVITY_QUERY, {
    variables: {
      id: campaign?.id as string,
    },
    skip: !campaign?.id,
  });

  const recentDonations = React.useMemo(
    () => recentActivity?.campaign?.donations?.items ?? [],
    [recentActivity],
  );
  const donationsCount = recentDonations.length;
  const totalDonations = recentActivity?.campaign?.donations?.count ?? 0;

  const tableData = React.useMemo(() => {
    const tableDataMap = {
      [PersonalMissionRecentActivityTabKey.Fundraisers]: {
        dataSource: [],
        showing: 0,
        total: 0,
        rowActions: [],
      },

      [PersonalMissionRecentActivityTabKey.Teams]: {
        dataSource: [],
        showing: 0,
        total: 0,
        rowActions: [],
      },

      [PersonalMissionRecentActivityTabKey.Donations]: {
        dataSource: recentDonations,
        showing: donationsCount,
        total: totalDonations,
        rowActions: [
          {
            actionName: 'View/Edit',
            onAction: () => {},
          },
          {
            actionName: 'Send Text Message',
            onAction: () => {},
          },
          {
            actionName: 'Call a Team Member',
            onAction: () => {},
          },
          {
            actionName: 'Compose an Email',
            onAction: () => {},
          },
          {
            actionName: 'Add a Note',
            onAction: () => {},
          },
        ],
      },
    };

    return tableDataMap;
  }, [donationsCount, recentDonations, totalDonations]);

  const {
    tabs,
    selectedTab: selectedRecentActivityTab,
    selectedTabKey,
  } = useTabs(
    [
      {
        key: PersonalMissionRecentActivityTabKey.Fundraisers,
        label: PersonalMissionRecentActivityTabKey.Fundraisers,
        render: () => (
          <CampaignPMRecentFundraisersTable
            loading={loading}
            dataSource={tableData[PersonalMissionRecentActivityTabKey.Fundraisers].dataSource}
            rowActions={tableData[PersonalMissionRecentActivityTabKey.Fundraisers].rowActions}
          />
        ),
      },
      {
        key: PersonalMissionRecentActivityTabKey.Teams,
        label: PersonalMissionRecentActivityTabKey.Teams,
        render: () => (
          <CampaignPMRecentTeamsTable
            loading={loading}
            dataSource={tableData[PersonalMissionRecentActivityTabKey.Teams].dataSource}
            rowActions={tableData[PersonalMissionRecentActivityTabKey.Teams].rowActions}
          />
        ),
      },
      {
        key: PersonalMissionRecentActivityTabKey.Donations,
        label: PersonalMissionRecentActivityTabKey.Donations,
        render: () => (
          <CampaignPMRecentDonationsTable
            loading={loading}
            dataSource={tableData[PersonalMissionRecentActivityTabKey.Donations].dataSource}
            rowActions={tableData[PersonalMissionRecentActivityTabKey.Donations].rowActions}
          />
        ),
      },
    ],
    { initialTabKey: PersonalMissionRecentActivityTabKey.Donations, tabsProps: { centered: true } },
  );

  return (
    <React.Fragment>
      <PaperBlock title="Recent Activity">
        {tabs}

        {selectedRecentActivityTab}

        {!loading && (
          <React.Fragment>
            <Box display="flex" justifyContent="center">
              <Link
                component={RouterLink}
                to={`/campaigns/${campaign?.id}/people?tab=${PEOPLE_TABLES_MAP[selectedTabKey]}`}
                className={classes.viewAllLink}
              >
                View All
              </Link>
            </Box>
          </React.Fragment>
        )}
      </PaperBlock>
    </React.Fragment>
  );
};
