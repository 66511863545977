import React from 'react';

import { FiltersLayout } from '@vizsla/components';

export function ActivityPageLayout({ children }) {
  return (
    <FiltersLayout withSearch withPagination searchPlaceholder="Search by registration or Donation">
      {children}
    </FiltersLayout>
  );
}
