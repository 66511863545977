import React from 'react';
import _ from 'lodash';
import { Box, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { usePopover, useNotification } from '@vizsla/hooks';
import { TableColumn, TableData } from '@vizsla/components';
import { isEnabledAllCampaignQuestions } from '@vizsla/utils';
import { CampaignQuestion, CampaignQuestionCategories, CampaignQuestionType } from '@vizsla/types';
import { Experience } from '@vizsla/graphql';

import { useCampaignQuestions } from 'src/hooks/campaignQuestion';
import { useCampaignId } from 'src/hooks/campaign';
import { useExperiences } from 'src/hooks/experiences';
import {
  CampaignQuestionEnabledSwitch,
  CampaignQuestionRequiredCheckbox,
} from 'src/components/campaign';
import {
  MultiSelectPopover,
  MultiSelectListItem,
  DropDownTextWrapper,
} from 'src/components/shared';
import { getExperienceById } from 'src/utils/experience';
import { t } from 'src/utils/template';
import { FontSize, FontWeight } from 'src/theme';

const useStyles = makeStyles(() => ({
  tableCells: {
    '& .MuiTableCell-root:nth-child(1)': {
      width: '35%',
      minWidth: '35%',
      maxWidth: 300,
    },
    '& .MuiTableCell-root:nth-child(2)': {
      width: '10%',
      minWidth: '10%',
    },
    '& .MuiTableCell-root:nth-child(3)': {
      width: '35%',
      minWidth: '35%',
      maxWidth: 300,
    },
    '& .MuiTableCell-root:nth-child(4)': {
      width: '10%',
      minWidth: '10%',
    },
    '& .MuiTableCell-root:nth-child(5)': {
      width: '10%',
      minWidth: '10%',
    },
  },

  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  enableAll: {
    cursor: 'pointer',
    fontSize: FontSize.Default,
  },
}));

interface CampaignManageQuestionTableProps {
  category: CampaignQuestionCategories;
  questions: Array<CampaignQuestion>;
}

export const CampaignManageQuestionTable: React.FC<CampaignManageQuestionTableProps> = ({
  questions = [],
  category,
}) => {
  const classes = useStyles();
  const notification = useNotification();

  const isMandatory = category === CampaignQuestionCategories.Mandatory;

  const campaignId = useCampaignId();

  const {
    toggleEnableAll,
    toggleIsEnabled,
    toggleIsRequired,
    connectCampaignQuestionToExperiences,
  } = useCampaignQuestions(campaignId);

  const { data: campaignExperiences } = useExperiences(campaignId);

  const [isConnectingExperiences, setIsConnectingExperiences] = React.useState<boolean>(false);
  const [isTogglingAllEnabled, setTogglingAllEnabled] = React.useState<boolean>(false);

  const {
    isOpen,
    el,
    closePopover,
    openPopover,
    state: selectedQuestion,
  } = usePopover<CampaignQuestion>();

  const isEnabledAll = React.useMemo(() => isEnabledAllCampaignQuestions(questions), [questions]);

  const onEnableAll = async () => {
    try {
      setTogglingAllEnabled(true);
      await toggleEnableAll(!isEnabledAll, category);

      notification.success(
        t('campaign_question_category_toggle_enabled_success', {
          category,
          action: isEnabledAll ? 'disabled' : 'enabled',
        }),
      );
    } catch (error) {
      notification.error(
        t('campaign_question_category_toggle_enabled_error', {
          category,
          action: isEnabledAll ? 'disable' : 'enable',
        }),
      );

      console.error({ error });
    } finally {
      setTogglingAllEnabled(false);
    }
  };

  const onOpenMultiSelect = React.useCallback(
    (event: React.MouseEvent<any, MouseEvent>, question: CampaignQuestion) => {
      openPopover(event, question);
    },
    [openPopover],
  );

  const experienceSelectItems = React.useMemo<Array<MultiSelectListItem>>(() => {
    const selectedQuestionExperiences = selectedQuestion?.experience?.items || [];

    const experienceItems = campaignExperiences.map(experience => {
      const hasExperience =
        getExperienceById(selectedQuestionExperiences, experience.id as string) !== null;

      return {
        key: experience.id as React.Key,
        text: experience?.name as string,
        checked: hasExperience,
        ...experience,
      };
    });

    return experienceItems;
  }, [campaignExperiences, selectedQuestion]);

  const onSubmit = React.useCallback(
    async (experiences: Array<any>) => {
      const selectedExperiences = experiences.filter(experience => experience.checked);
      const selectedExperienceIds = selectedExperiences.map(experience => experience.id);
      const questionId = selectedQuestion?.id as string;
      const questionType = !_.isNil(selectedQuestion?.question)
        ? CampaignQuestionType.Default
        : CampaignQuestionType.Custom;

      try {
        setIsConnectingExperiences(true);
        await connectCampaignQuestionToExperiences(questionId, selectedExperienceIds, questionType);

        notification.success(t('campaign_question_link_experience_success'));
        closePopover();
      } catch (error) {
        notification.error(t('campaign_question_link_experience_error'));
        console.error({ error });
      }

      setIsConnectingExperiences(false);
    },
    [
      closePopover,
      connectCampaignQuestionToExperiences,
      notification,
      selectedQuestion?.id,
      selectedQuestion?.question,
    ],
  );

  const TABLE_COLUMNS: TableColumn<CampaignQuestion>[] = React.useMemo(
    () => [
      {
        title: 'Question',
        key: 'title',
        render: (valueByDataPath: any, campaignQuestion: CampaignQuestion) => {
          const isDefault = !_.isNil(campaignQuestion?.question);
          const title = isDefault ? campaignQuestion?.question?.title : campaignQuestion?.title;

          return (
            <Typography
              variant="body2"
              fontWeight={isDefault ? FontWeight.Bold : FontWeight.Regular}
              className={classes.title}
            >
              {title}
            </Typography>
          );
        },
      },
      {
        title: 'Type',
        key: 'type',
        render: (valueByDataPath: any, campaignQuestion: CampaignQuestion) => {
          return campaignQuestion?.type ?? campaignQuestion?.question?.type;
        },
      },
      {
        title: 'Allocation',
        key: 'allocation',
        dataPath: 'experience.items',
        render: (experiences: Experience[] = [], campaignQuestion: CampaignQuestion) => {
          const isEmpty = _.isEmpty(experiences);

          if (isMandatory && isEmpty) {
            return 'All Experiences';
          }
          const experiencesTitles = experiences?.map(exp => exp?.name).join(', ');

          return (
            <Box onClick={event => onOpenMultiSelect(event, campaignQuestion)}>
              <DropDownTextWrapper>
                {isEmpty ? 'All Experiences' : experiencesTitles}
              </DropDownTextWrapper>
            </Box>
          );
        },
      },
      {
        title: 'required',
        key: 'isRequired',
        dataPath: 'isRequired',
        align: 'center',
        render: (isRequired: boolean, question: CampaignQuestion) => {
          return (
            <CampaignQuestionRequiredCheckbox
              isRequired={isRequired}
              isMandatory={isMandatory}
              question={question}
              onCheck={toggleIsRequired}
            />
          );
        },
      },
      {
        title: 'enabled',
        key: 'isEnabled',
        dataPath: 'isEnabled',
        align: 'right',
        render: (isEnabled: boolean, question: CampaignQuestion) => {
          return (
            <CampaignQuestionEnabledSwitch
              isMandatory={isMandatory}
              isEnabled={isMandatory || isEnabled}
              onToggle={toggleIsEnabled}
              question={question}
            />
          );
        },
      },
    ],
    [classes.title, isMandatory, onOpenMultiSelect, toggleIsEnabled, toggleIsRequired],
  );

  return (
    <Grid className={classes.tableCells}>
      {!isMandatory && (
        <Grid container item justifyContent="space-between" mt={2} mb={1}>
          <Grid item xs={8}>
            <Typography variant="h6">{category}</Typography>
          </Grid>
          <Grid container item justifyContent="flex-end" px={1} xs={4}>
            <Grid item>
              <Button
                disabled={isTogglingAllEnabled}
                variant="hyperlink"
                onClick={onEnableAll}
                className={classes.enableAll}
                disableRipple
              >
                {isEnabledAll ? 'Disable All' : 'Enable All'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      <TableData
        dataSource={questions}
        columns={TABLE_COLUMNS}
        hideTitle={!isMandatory}
        loading={isTogglingAllEnabled}
      />
      <MultiSelectPopover
        isOpen={isOpen}
        anchorEl={el}
        onClose={closePopover}
        items={experienceSelectItems}
        onSubmit={onSubmit}
        submitting={isConnectingExperiences}
      />
    </Grid>
  );
};
