import React from 'react';
import { Grid, IconButton, Popover, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';

import { BottomActionButton } from '@vizsla/components';
import { usePopover } from '@vizsla/hooks';
import { concatDateWithTime } from '@vizsla/utils';

import { PaletteColor } from 'src/theme';
import { PricingTierFormAction, PricingTierFieldValue } from 'src/types/pricingSettings';

import { ScaledPriceTierForm } from './ScaledPriceTierForm';

const useStyles = makeStyles((theme: Theme) => ({
  selectedColumns: {
    maxHeight: 400,
    overflowY: 'auto',
  },
  fieldRow: {
    display: 'flex',
  },
  actions: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  action: {
    cursor: 'pointer',
  },
  contentWrap: {
    width: '418px',
    padding: theme.spacing(2),
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: PaletteColor.PrimaryGray,
  },
}));

interface ScaledPriceAddTierPopoverProps {
  addValue: (values: PricingTierFieldValue) => void;
  lastPricingTierValue: PricingTierFieldValue;
}

export const ScaledPriceAddTierPopover: React.FC<ScaledPriceAddTierPopoverProps> = ({
  addValue,
  lastPricingTierValue,
}) => {
  const popover = usePopover();
  const classes = useStyles();

  const minDateTimeValue = concatDateWithTime(
    lastPricingTierValue?.endDate,
    lastPricingTierValue?.endTime,
  );

  const onSubmit = React.useCallback(
    (value: PricingTierFieldValue) => {
      popover.closePopover();
      setTimeout(
        () =>
          addValue({
            ...value,
            formAction: PricingTierFormAction.toCreate,
          }),
        200,
      );
    },
    [popover, addValue],
  );

  return (
    <React.Fragment>
      <Grid item>
        <BottomActionButton icon={<AddIcon />} onClick={popover.openPopover}>
          Add Pricing Tier
        </BottomActionButton>
      </Grid>
      <Popover
        anchorEl={popover.el}
        open={popover.isOpen}
        onClose={popover.closePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid className={classes.contentWrap}>
          <Grid marginBottom={4}>
            <Typography variant="subtitle1">Add Pricing Tier</Typography>
            <IconButton
              aria-label="close"
              onClick={popover.closePopover}
              className={classes.closeIcon}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid>
            <ScaledPriceTierForm onSubmit={onSubmit} minDateTimeValue={minDateTimeValue} />
          </Grid>
        </Grid>
      </Popover>
    </React.Fragment>
  );
};
