import React from 'react';
import { Grid, SxProps, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { theme } from '@vizsla/theme';

import { ActivityFeed } from './ActivityFeed';
import { MessageHistory } from '../MessageHistory';

enum ActivitySections {
  activityFeed = 'Activity Feed',
  messageHistory = 'Message History',
}

export function ActivityPage() {
  const [currentSection, setCurrentSection] = React.useState<ActivitySections>(
    ActivitySections.activityFeed,
  );

  const handleSwitchSection = (_: React.ChangeEvent<any>, ActivitySections: ActivitySections) => {
    setCurrentSection(ActivitySections);
  };

  const toggleButtonGroupSxProp: SxProps = {
    '&>.MuiButtonBase-root': {
      padding: theme.spacing(0.55, 4),
      margin: 0,
    },
  };

  return (
    <React.Fragment>
      <Grid container display="flex" justifyContent="center" columnSpacing={2}>
        <ToggleButtonGroup
          size="medium"
          exclusive
          value={currentSection}
          onChange={handleSwitchSection}
          sx={toggleButtonGroupSxProp}
        >
          <ToggleButton value={ActivitySections.activityFeed}>
            {ActivitySections.activityFeed}
          </ToggleButton>
          <ToggleButton value={ActivitySections.messageHistory}>
            {ActivitySections.messageHistory}
          </ToggleButton>
        </ToggleButtonGroup>
        {currentSection === ActivitySections.activityFeed && <ActivityFeed />}
        {currentSection === ActivitySections.messageHistory && <MessageHistory />}
      </Grid>
    </React.Fragment>
  );
}
