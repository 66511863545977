import React from 'react';
import { Grid, Typography } from '@mui/material';

import { Field, TextField, NumberField, SelectField } from '@vizsla/components';

import { RichEditorField } from 'src/components/forms';
import { UNITS_OF_MEASURE_OPTIONS } from 'src/constants/registrationOption';

export const RegistrationOptionCreateFormStepOne: React.FC = () => {
  return (
    <React.Fragment>
      <Typography variant="h4" paddingBottom={3}>
        Basic Information
      </Typography>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={6}>
          <Field fullWidth label="Registration Name" name="name" component={TextField} />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={8}>
              <Field name="distance" label="Distance" component={NumberField} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="unitOfMeasure"
                label="Units"
                component={SelectField}
                options={UNITS_OF_MEASURE_OPTIONS}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="description"
            label="Description"
            component={RichEditorField}
            placeholder="Description (Recommended)"
            height={300}
            required
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
