import React from 'react';
import { makeStyles } from '@mui/styles';
import { Toolbar, Theme, Paper, IconButton } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';

import { HeaderLogoAlignType } from '@vizsla/constants';

import { LandingHeaderLogo } from './LandingHeaderLogo';

interface LandingHeaderProps {
  logoImageUrl?: string;
  headerLogoAlign: HeaderLogoAlignType;
  headerBackground: string;
  editorMode?: boolean;
}

interface StylesParams {
  headerBackground: string;
  headerLogoAlign: HeaderLogoAlignType;
}

const useStyles = makeStyles<Theme, StylesParams>(theme => ({
  root: {
    gridArea: 'header',
    zIndex: 2,
  },

  toolbar: {
    background: ({ headerBackground }) => headerBackground,
    justifyContent: 'center',
  },

  logo: {
    width: 100,
    height: 48,
    marginLeft: props => (props.headerLogoAlign === HeaderLogoAlignType.left ? 0 : 'auto'),
    marginRight: '-10px',
    backgroundColor: 'red',
  },

  preview: {
    width: 100,
    height: 48,
    marginLeft: props => (props.headerLogoAlign === HeaderLogoAlignType.left ? 0 : 'auto'),
    marginRight: '-10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'red',
  },

  personalButton: {
    marginLeft: 'auto',
    marginRight: '0',
  },
}));

export function LandingHeader({
  logoImageUrl,
  headerLogoAlign,
  headerBackground,
  editorMode,
}: LandingHeaderProps) {
  const styles = useStyles({ headerBackground, headerLogoAlign });

  return (
    <Paper className={styles.root} square elevation={1}>
      <Toolbar className={styles.toolbar}>
        <LandingHeaderLogo url={logoImageUrl} align={headerLogoAlign} editorMode={editorMode} />

        <div className={styles.personalButton}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => {}}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </div>
      </Toolbar>
    </Paper>
  );
}
