import React from 'react';

import { PartnershipOrganization, PartnershipOrganizationsListQuery } from '@vizsla/graphql';
import { Field, useDataBaseSelectField, DataBaseSelectField } from '@vizsla/components';
import { useCurrentOrganization } from '@vizsla/hooks';

import { PARTNERSHIP_ORGANIZATIONS_LIST } from 'src/graphql/partnershipOrganization';
import { getPartnershipOrganizationSearchFilter } from 'src/utils/partnershipOrganization';

export const AddOfflineDonationOrganizationSelectField: React.FC = () => {
  const { organizationId } = useCurrentOrganization();

  const selectFundraiserFieldProps = useDataBaseSelectField<
    PartnershipOrganizationsListQuery,
    PartnershipOrganization
  >(
    {
      query: PARTNERSHIP_ORGANIZATIONS_LIST,
      getQueryItems: 'partnershipOrganizationsList.items',
      getCustomQueryVariables: searchText => ({
        filter: getPartnershipOrganizationSearchFilter(searchText, organizationId ?? ''),
        skip: !organizationId,
      }),
    },
    {
      getOptionLabel: (partnershipOrganization: PartnershipOrganization) => {
        return partnershipOrganization.name as string;
      },
    },
  );

  return (
    <Field
      fullWidth
      name="donorInfo.donorPartnershipOrganization"
      label="Partnership *"
      component={DataBaseSelectField}
      {...selectFundraiserFieldProps}
    />
  );
};
