import React from 'react';
import { Link, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CopyToClipboard from 'react-copy-to-clipboard';

import { useNotification } from '@vizsla/hooks';
import { FontSize } from '@vizsla/theme';

import { t } from 'src/utils/template';

const useStyles = makeStyles({
  link: {
    cursor: 'pointer',
    width: 80,
    fontSize: FontSize.M,
  },

  notchedOutline: {
    borderStyle: 'dashed !important',
  },
});

interface EarlyBirdRegistrationURLProps {
  campaignWebLink?: string | null;
  code?: string | null;
}

const EarlyBirdRegistrationURL: React.FC<EarlyBirdRegistrationURLProps> = ({
  campaignWebLink,
  code,
}) => {
  const classes = useStyles();

  const notification = useNotification();

  const [earlyBirdURL, setEarlyBirdURL] = React.useState('');

  const URL = campaignWebLink
    ? `https://${campaignWebLink}.vizsla.com/earlybirdregistration`
    : 'Not Set';

  React.useEffect(() => {
    if (code === '') {
      setEarlyBirdURL(URL);
    } else if (campaignWebLink && code) {
      setEarlyBirdURL(`${URL}/${code}`);
    }
  }, [campaignWebLink, code]);

  const handleCopy = () => {
    notification.success(t('copy_success', { textName: 'Link' }));
  };

  return (
    <TextField
      label="Early Bird URL"
      variant="outlined"
      InputProps={{
        readOnly: true,
        classes: {
          notchedOutline: classes.notchedOutline,
        },
        endAdornment: (
          <CopyToClipboard onCopy={handleCopy} text={earlyBirdURL}>
            <Link className={classes.link}>Copy Link</Link>
          </CopyToClipboard>
        ),
      }}
      focused
      fullWidth
      value={earlyBirdURL}
    />
  );
};

export default EarlyBirdRegistrationURL;
