import React from 'react';
import { Grid, Theme } from '@mui/material';
import { Form } from 'react-final-form';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

import { validateWithSchema } from '@vizsla/utils';
import { useAppAuth } from '@vizsla/hooks';
import { ConfirmEmailSchema } from '@vizsla/constants';
import { Button, Field, AuthTextField } from '@vizsla/components';

import * as appRoutes from 'src/constants/routes';
import { OrganizationAuthLayout } from 'src/layouts';

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    margin: theme.spacing(2.5, 0, 0, 0),
  },
}));

const AuthConfirmationEmailPage: React.FC = () => {
  const { isAuthenticated, confirmEmail } = useAppAuth();
  const navigate = useNavigate();
  const classes = useStyles();

  if (isAuthenticated) {
    navigate(appRoutes.home);
  }

  const onSubmit = React.useCallback(
    async data => {
      await confirmEmail(data);

      navigate(appRoutes.authLogin);
    },
    [confirmEmail, navigate],
  );

  return (
    <OrganizationAuthLayout
      title="Confirm your Email"
      caption="We sent you an account verification code"
    >
      <Form onSubmit={onSubmit} validate={values => validateWithSchema(ConfirmEmailSchema, values)}>
        {({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
          const isDisabled = submitting || pristine || hasValidationErrors;

          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Field name="email" label="Email" component={AuthTextField} />
                </Grid>
                <Grid item xs={12}>
                  <Field name="code" label="Code" component={AuthTextField} />
                </Grid>
              </Grid>
              <Grid container justifyContent="left">
                <Grid item>
                  <Button
                    className={classes.submitButton}
                    color="secondary"
                    type="submit"
                    loading={submitting}
                    disabled={isDisabled}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Form>
    </OrganizationAuthLayout>
  );
};

export { AuthConfirmationEmailPage };
