import React from 'react';
import Box from '@mui/material/Box';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { AuthTextField } from '@vizsla/components';
import { validateWithSchema } from '@vizsla/utils';
import { useAppAuth } from '@vizsla/hooks';
import { ForgotPasswordValidationSchema } from '@vizsla/constants';

import { authForgotPasswordVerifyCode } from 'src/constants/routes';
import { OrganizationAuthLayout } from 'src/layouts';

import { StyledButton } from '../components/styled-components';

export function SendCodeByEmail() {
  const navigate = useNavigate();
  const { forgotPassword } = useAppAuth();
  const handleSendCode = React.useCallback(
    async ({ email }) => {
      await forgotPassword(email);
      navigate(authForgotPasswordVerifyCode, { state: { email } });
    },
    [forgotPassword, navigate],
  );
  const validate = React.useCallback(
    values => validateWithSchema(ForgotPasswordValidationSchema, values),
    [],
  );
  return (
    <OrganizationAuthLayout
      title="Forgot password"
      caption="Enter your email address to reset your password"
    >
      <Form onSubmit={handleSendCode} validate={validate}>
        {({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
          const isDisabled = submitting || pristine || hasValidationErrors;
          return (
            <form onSubmit={handleSubmit}>
              <Box>
                <Field name="email" label="Email address" component={AuthTextField} />
              </Box>
              <StyledButton loading={submitting} disabled={isDisabled}>
                Send code
              </StyledButton>
            </form>
          );
        }}
      </Form>
    </OrganizationAuthLayout>
  );
}
