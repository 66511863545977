import React from 'react';
import { Grid } from '@mui/material';
import { Form } from 'react-final-form';
import type { FormApi } from 'final-form';

import { useNotification } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { Modal } from '@vizsla/components';

import { useExperienceId } from 'src/hooks/experiences';
import { useExperienceStoreAssets } from 'src/hooks/storeAssets';
import type { ExperienceStoreAssetAddOrUpdateFormValues } from 'src/types/storeAssets';
import { ExperienceStoreAssetAddFormSchema } from 'src/constants/validationSchemas/storeAssets';
import { t } from 'src/utils/template';

import { getExperienceStoreAssetDataInput } from '../../utils';
import { FormSearchSection, FormPriceSection } from './sections';

interface ExperienceStoreAssetAddFormProps {
  closeModal: () => void;
}

const ExperienceStoreAssetAddForm: React.FC<ExperienceStoreAssetAddFormProps> = ({
  closeModal,
}) => {
  const notification = useNotification();

  const experienceId = useExperienceId();

  const initialValues = React.useRef<ExperienceStoreAssetAddOrUpdateFormValues>({
    storeAsset: undefined,
  });

  const { createExperienceStoreAsset, creating } = useExperienceStoreAssets();

  const onSubmit = React.useCallback(
    async (formValues: ExperienceStoreAssetAddOrUpdateFormValues, form: FormApi<any>) => {
      try {
        const inputData = getExperienceStoreAssetDataInput(formValues, experienceId);

        await createExperienceStoreAsset(inputData);

        notification.success(t('experience_store_item_add_success'));

        closeModal();
      } catch (error) {
        notification.error(t('experience_store_item_add_error'));
        console.error(error);
      }
    },
    [closeModal, createExperienceStoreAsset, experienceId, notification],
  );

  const validate = React.useCallback(
    async (formValues: ExperienceStoreAssetAddOrUpdateFormValues) => {
      return validateWithSchema(ExperienceStoreAssetAddFormSchema, formValues);
    },
    [],
  );

  return (
    <Form
      initialValues={initialValues.current}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting, pristine, validating, invalid }) => {
        const isSubmitLoading = submitting || validating || creating;
        const isSubmitDisabled = isSubmitLoading || pristine || validating || invalid;

        return (
          <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <FormSearchSection />

              <FormPriceSection />

              <Grid item xs={12}>
                <Modal.Actions>
                  <Modal.Action
                    type="secondary"
                    content="Cancel"
                    onAction={closeModal}
                    disabled={isSubmitDisabled}
                  />

                  <Modal.Action
                    type="primary"
                    content="Add"
                    onAction={handleSubmit}
                    disabled={isSubmitDisabled}
                    loading={isSubmitLoading}
                  />
                </Modal.Actions>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export { ExperienceStoreAssetAddForm };
