import { useMemo } from 'react';
import {
  loadStripe,
  StripeElementsOptions,
  Appearance as StripeAppearance,
} from '@stripe/stripe-js';
import { useTheme } from '@emotion/react';

const STRIPE_PUBLIC_TOKEN = process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? '';

const client = loadStripe(STRIPE_PUBLIC_TOKEN);

/** Create a new instance of the Stripe SDK. */
export const useStripePromiseClient = () => {
  if (STRIPE_PUBLIC_TOKEN === '') {
    throw new Error('Stripe token is not setup correctly');
  }

  return client;
};

export function useStripeOptions(clientSecret?: string): StripeElementsOptions {
  const theme = useTheme();

  const appearance = useMemo(() => {
    const customization: StripeAppearance = {
      theme: 'none',
      variables: {
        fontFamily: theme.typography.fontFamily,
        fontSizeBase: theme.typography.body1.fontSize?.toString(),
        spacingUnit: theme.spacing(1),
        borderRadius: theme.spacing(2),
        colorText: '#001153',
        colorPrimary: theme.palette.primary.main,
      },

      rules: {
        '.Tab': {
          border: 'solid 0.15rem rgba(0, 0, 0, 0.1)',
          outline: 'none',
          padding: theme.spacing(2),
        },

        '.Tab--selected': {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          boxShadow: '0 5px 20px 0 rgba(0, 0, 0, 0.1)',
        },

        '.TabIcon--selected': {
          fill: theme.palette.primary.main,
        },

        '.TabLabel--selected': {
          color: theme.palette.primary.main,
        },

        '.Input': {
          border: 'solid 0.15rem rgba(0, 0, 0, 0.1)',
          padding: theme.spacing(2, 3),
          outline: 'none',
        },

        '.PickerItem': {
          border: 'solid 0.15rem rgba(0, 0, 0, 0.1)',
          outline: 'none',
          padding: theme.spacing(2),
        },

        '.PickerItem--selected': {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          boxShadow: '0 5px 20px 0 rgba(0, 0, 0, 0.1)',
        },
      },
    };

    return customization;
  }, [theme]);

  return {
    clientSecret,

    appearance,
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;700;900&display=swap',
      },
    ],
  };
}
