import React from 'react';
import { Grid } from '@mui/material';

import { Modal, Field, TextField } from '@vizsla/components';

import { StoreAssetFormFields } from 'src/types/storeAssets';

interface StoreAssetFormAdditionalInformationSectionProps {
  disabled?: boolean;
}

const StoreAssetFormAdditionalInformationSection: React.FC<
  StoreAssetFormAdditionalInformationSectionProps
> = ({ disabled = false }) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Modal.ContentText>Additional Information</Modal.ContentText>
      </Grid>

      <Grid item xs={12}>
        <Field
          fullWidth
          name={StoreAssetFormFields.NOTES}
          label="Notes"
          component={TextField}
          disabled={disabled}
        />
      </Grid>
    </React.Fragment>
  );
};

export { StoreAssetFormAdditionalInformationSection };
