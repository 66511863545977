import React from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useFormState, useForm } from 'react-final-form';
import { FileInput } from '8base-react-sdk';
import { PictureAsPdf, Close } from '@mui/icons-material';

import { DateField, Field, NumberField, RichTextEditorField, TextField } from '@vizsla/components';
import { DateFormatPatterns } from '@vizsla/constants';
import { validateHtmlTextLength } from '@vizsla/utils';

import {
  CampaignWrapper,
  FlexField,
  FlexRowRadioGroupField,
  InLineFields,
  LinkText,
  PdfWrapper,
} from './styles';
import { SelectCampaign } from '../../../../../campaign/SelectCampaign';
import { SelectExperience } from '../../../../../experience/SelectExperience';
import { AGREEMENT_FILE, CAMPAIGN, EXPERIENCE } from '../../../constants';

export const CashCommitment: React.FC = () => {
  const { values, errors, submitFailed } = useFormState();

  const { change } = useForm();
  const { inKindType } = values;

  const setCampaign = e => {
    change(EXPERIENCE, null);
    change(CAMPAIGN, e);
  };

  const setExperience = e => {
    change(CAMPAIGN, null);
    change(EXPERIENCE, e);
  };

  const clearCampaignExperience = () => {
    change(CAMPAIGN, '');
    change(EXPERIENCE, '');
  };
  React.useEffect(() => {
    clearCampaignExperience();
  }, [inKindType]);
  return (
    <React.Fragment>
      <InLineFields>
        <FlexField
          component={NumberField}
          name="amount"
          label="Amount (USD)"
          numberFormat={{ prefix: '$', thousandSeparator: true }}
          InputProps={{ fullWidth: true, size: 'medium' }}
          required
          afterSubmit={() => {}}
        />

        <FlexField
          required
          name="decisionDate"
          label="Partner Decision Date"
          component={DateField}
          inputFormat={DateFormatPatterns.shortMonthDayYear}
        />
      </InLineFields>
      <Box marginTop="2rem">
        <Field
          component={RichTextEditorField}
          name="description"
          label="Description"
          validate={value => validateHtmlTextLength(value, 1, 500)}
          maxTextLength={500}
          height={100}
        />
      </Box>

      <Typography fontWeight={600}>Allocation</Typography>
      <Field
        name="inKindType"
        options={[
          { label: 'Campaign', value: CAMPAIGN },
          { label: 'Experiences', value: EXPERIENCE },
        ]}
        component={FlexRowRadioGroupField}
        defaultValue={CAMPAIGN}
        optionContainerProps={{
          display: 'flex',
          flexDirection: 'row',
          direction: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          spacing: 2,
        }}
      />
      {inKindType === CAMPAIGN ? (
        <CampaignWrapper>
          <SelectCampaign name={CAMPAIGN} label="Campaign" onChange={setCampaign} required />
          {submitFailed && (
            <Typography variant="caption" color="red">
              {errors?.campaign}
            </Typography>
          )}
        </CampaignWrapper>
      ) : (
        <CampaignWrapper>
          <SelectExperience
            name={EXPERIENCE}
            label="Experience"
            onChange={setExperience}
            required
          />
          {submitFailed && (
            <Typography variant="caption" color="red">
              {errors?.experience}
            </Typography>
          )}
        </CampaignWrapper>
      )}

      <Field component={TextField} name="notes" label="Other Benefits/Notes" />
      {values[AGREEMENT_FILE] ? (
        <PdfWrapper>
          {values[AGREEMENT_FILE]?.filename?.replace('.pdf', '')}
          <PictureAsPdf />
          <IconButton
            onClick={() => {
              change(AGREEMENT_FILE, null);
            }}
          >
            <Close />
          </IconButton>
        </PdfWrapper>
      ) : (
        <FileInput
          onChange={e => {
            const file = e as any;
            change(AGREEMENT_FILE, {
              ...file,
            });
          }}
        >
          {wrapper => (
            <Grid item>
              <LinkText
                onClick={async () => {
                  const res = await wrapper.pick({
                    maxSize: 1000000,
                    accept: ['application/pdf', 'application/x-pdf'],
                    fromSources: ['local_file_system'],
                  });
                  return res;
                }}
              >
                Upload Agreement
              </LinkText>
            </Grid>
          )}
        </FileInput>
      )}
    </React.Fragment>
  );
};
