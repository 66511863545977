import React from 'react';
import { OutlinedInputProps, TextField } from '@mui/material';
import _ from 'lodash';

import { EditorTextInputFieldSizeType } from '@vizsla/constants';

interface EditorTextInputFieldProps {
  onChange: (fieldValue: string) => void;
  fieldValue: string;
  fieldLabel: string;
  inputProps?: Partial<OutlinedInputProps>;
  fieldSize?: EditorTextInputFieldSizeType;
  multiline?: boolean;
  rows?: number;
  maxRows?: number;
}

export const EditorTextInputField: React.FC<EditorTextInputFieldProps> = ({
  fieldLabel,
  onChange,
  fieldValue,
  fieldSize,
  inputProps,
  rows,
  maxRows,
  multiline = false,
}) => {
  const [currentValue, setCurrentValue] = React.useState(fieldValue);

  const debouncedOnChange = React.useCallback(_.debounce(onChange, 300), []);

  const onChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const fieldValue = event?.target?.value || '';
    setCurrentValue(fieldValue);

    debouncedOnChange(fieldValue);
  };

  return (
    <TextField
      label={fieldLabel}
      variant="outlined"
      size={fieldSize}
      InputProps={inputProps}
      onChange={onChangeInput}
      value={currentValue}
      multiline={multiline}
      rows={rows}
      maxRows={maxRows}
      fullWidth
    />
  );
};
