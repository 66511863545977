import { Add } from '@mui/icons-material';
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';

import { PaperBlock, TableColumn, TableData, TableRowAction } from '@vizsla/components';
import { useCurrentUser, useModal } from '@vizsla/hooks';
import {
  SOCIAL_MEDIA_DELETE_MUTATION,
  SOCIAL_MEDIA_QUERY,
  SocialMedia,
  SocialMediaDeleteInput,
  SocialMediaQuery,
  SocialMediaQueryVariables,
} from '@vizsla/graphql';
import { MODALS } from '@vizsla/constants';

import { EllipsisTypography } from 'src/components/shared';

import { OrganizationSocialMediaIcon } from '../../OrganizationSocialMedia';

export function SocialMediaOrganization() {
  const { user } = useCurrentUser();

  const organizationId = user?.vizslaOrganizationStaff?.items[0].vizslaOrganization?.id ?? '';

  const { data: response } = useQuery<SocialMediaQuery, SocialMediaQueryVariables>(
    SOCIAL_MEDIA_QUERY,
    {
      variables: {
        filter: { organization: { id: { equals: organizationId } } },
      },
    },
  );

  const { openModal } = useModal();

  const [deleteSocialMedia, { loading: deleting }] = useMutation<SocialMediaDeleteInput>(
    SOCIAL_MEDIA_DELETE_MUTATION,
    {
      refetchQueries: ['SocialMedia'],
      awaitRefetchQueries: true,
    },
  );
  const openSocialMediaDialog = () => {
    openModal(MODALS.SOCIAL_MEDIA_CREATE_MODAL);
  };

  const [loading] = React.useState(false);

  const COLUMNS: TableColumn<SocialMedia>[] = [
    {
      title: 'Social Media',
      key: 'socialMedia',
      render(_, media) {
        return (
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <OrganizationSocialMediaIcon type={(media?.socialMedia as any) ?? undefined} />
            </Grid>

            <Grid item>
              <EllipsisTypography variant="h6" maxWidth={300}>
                {media?.socialMedia ?? ''}
              </EllipsisTypography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      title: 'URL',
      key: 'uRL',

      render: (_, media) => {
        return <Typography variant="body2">{media?.uRL ?? ''}</Typography>;
      },
    },
  ];

  const ROW_ACTIONS: TableRowAction<SocialMedia>[] = [
    {
      actionName: 'Delete',

      onAction: (SocialMedia: SocialMedia) => {
        openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
          deleteFunction: () => deleteSocialMedia({ variables: { id: SocialMedia?.id } }),
          objectName: SocialMedia?.socialMedia,
          objectType: 'Social Media',
        });
      },
    },
  ];

  const BOTTOM_ACTIONS = [
    {
      icon: <Add />,
      actionName: 'Add Social Media',
      onAction: () => openSocialMediaDialog(),
    },
  ];

  const data = React.useMemo(() => response?.socialMedias?.items ?? [], [response]);

  return (
    <PaperBlock title="Social Media" collapsing defaultOpened>
      <Grid item>
        <TableData
          loading={loading || deleting}
          columns={COLUMNS}
          dataSource={data}
          hideTitle={isEmpty(data)}
          bottomActions={BOTTOM_ACTIONS}
          rowActions={ROW_ACTIONS}
        />
      </Grid>
    </PaperBlock>
  );
}
