import React from 'react';
import { Box, Theme } from '@mui/material';
import { FlagOutlined, MonetizationOnOutlined, PersonOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@apollo/client';

import { formatMoney } from '@vizsla/utils';
import { CampaignsStatisticsQuery } from '@vizsla/graphql';
import { useCurrentOrganization } from '@vizsla/hooks';

import { InformationCard } from 'src/components/shared';
import { CAMPAIGNS_STATISTICS_QUERY } from 'src/graphql';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    '&>*:not(:first-child):not(:last-child)': {
      margin: theme.spacing(0, 1),
    },
    '&>*:first-child': {
      marginRight: theme.spacing(1),
    },
    '&>*:last-child': {
      marginLeft: theme.spacing(1),
    },
  },
}));

export const CampaignsListInfoCards: React.FC = () => {
  const classes = useStyles();

  const { organizationId } = useCurrentOrganization();
  const { data, loading } = useQuery<CampaignsStatisticsQuery>(CAMPAIGNS_STATISTICS_QUERY, {
    variables: {
      organizationId,
    },
  });

  const totalCount = data?.totalCount?.count;
  const totalAttendees = data?.totalAttendees?.campaigns?.groups[0]?.count;
  const totalRaised = formatMoney(data?.totalRaised.groups[0].totalRaised);
  const totalCompleted = data?.totalCompleted.count;
  const totalActive = data?.totalActive.count;

  return (
    <Box className={classes.root}>
      <InformationCard
        icon={<FlagOutlined color="secondary" fontSize="large" />}
        title="Total Campaigns"
        value={totalCount}
        loading={loading}
      />
      <InformationCard
        icon={<FlagOutlined color="success" fontSize="large" />}
        title="Active Campaigns"
        value={totalActive}
        loading={loading}
      />
      <InformationCard
        icon={<FlagOutlined color="disabled" fontSize="large" />}
        title="Completed Campaigns"
        value={totalCompleted}
        loading={loading}
      />
      <InformationCard
        icon={<PersonOutlined color="secondary" fontSize="large" />}
        title="Total Attendees"
        value={totalAttendees}
        loading={loading}
      />
      <InformationCard
        icon={<MonetizationOnOutlined color="success" fontSize="large" />}
        title="Total Raised"
        value={totalRaised}
        loading={loading}
      />
    </Box>
  );
};
