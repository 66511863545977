import React from 'react';
import { useApolloClient } from '@apollo/client';

import {
  Experience,
  ExperienceUpdateInput,
  useEarlyBirdRegistrationSettingsQuery,
  useEarlyBirdRegistrationSettingsUpdateMutation,
} from '@vizsla/graphql';

import { EARLY_BIRD_REGISTRATION_SETTINGS_QUERY } from 'src/graphql/earlyBirdRegistration';

const EXPERIENCE_TYPENAME = 'Experience';

const useEarlyBirdRegistration = (experienceId: string) => {
  const apolloClient = useApolloClient();

  const { data: earlyBirdRegistrationSettingsQueryResult, loading } =
    useEarlyBirdRegistrationSettingsQuery({
      variables: { id: experienceId },
    });

  const [earlyBirdRegistrationSettingsUpdateMutation, { loading: updating }] =
    useEarlyBirdRegistrationSettingsUpdateMutation();

  const earlyBirdRegistrationSettings =
    earlyBirdRegistrationSettingsQueryResult?.experience as Experience;

  const updateEarlyBirdRegistrationSettingsInCache = React.useCallback(
    (experience: Experience) => {
      apolloClient.writeQuery({
        query: EARLY_BIRD_REGISTRATION_SETTINGS_QUERY,
        variables: {
          id: experienceId,
        },
        data: {
          experience,
          __typename: EXPERIENCE_TYPENAME,
        },
      });
    },
    [apolloClient, experienceId],
  );

  const updateEarlyBirdRegistrationSettings = async (
    experienceUpdateInput: ExperienceUpdateInput,
  ) => {
    const { data } = await earlyBirdRegistrationSettingsUpdateMutation({
      variables: {
        data: experienceUpdateInput,
        filter: {
          id: experienceId,
        },
      },
      refetchQueries: ['EarlyBirdRegistrationSettings'],
      awaitRefetchQueries: true,
    });
    updateEarlyBirdRegistrationSettingsInCache((data?.experienceUpdate || {}) as Experience);
  };

  return {
    earlyBirdRegistrationSettings,
    updateEarlyBirdRegistrationSettings,
    loading,
    updating,
  };
};

export default useEarlyBirdRegistration;
