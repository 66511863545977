import React from 'react';
import { FormSpy, useForm } from 'react-final-form';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Field, NumberField, SelectField } from '@vizsla/components';

import { AMOUNT_OPTIONS } from '../constants/campaignDiscountFormOptions';
import { getAmountFormatType } from '../utils/campaignDiscountFormUtils';

const useStyles = makeStyles(() => ({
  amountField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  amountType: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderLeftColor: 'transparent',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}));

export const CampaignDiscountFormAmountSection: React.FC = () => {
  const classes = useStyles();

  const { getState } = useForm();

  return (
    <FormSpy subscription={{ submitting: true, values: true }}>
      {({ submitting }) => {
        const { values } = getState();
        const { amountType } = values;

        const amountFormat = getAmountFormatType(amountType);

        return (
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={9}>
                <Field
                  name="amount"
                  label="Amount"
                  component={NumberField}
                  disabled={submitting}
                  numberFormat={amountFormat}
                  className={classes.amountField}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="amountType"
                  options={AMOUNT_OPTIONS}
                  component={SelectField}
                  disabled={submitting}
                  className={classes.amountType}
                  sx={{
                    '&> .MuiSelect-select': {
                      overflow: 'visible',
                    },
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </FormSpy>
  );
};
