import React from 'react';
import { Paper, Collapse, Box, Typography, Tooltip, Theme } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Field } from 'react-final-form';

import { SwitchField } from '@vizsla/components';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  paper: {
    padding: theme.spacing(2, 3),
  },
  heading: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    '& .MuiSvgIcon-root': {
      marginTop: '-4px',
      width: 32,
      height: 32,
    },
  },
  content: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  },
}));

interface SectionExpanderFieldProps {
  heading: string | React.ReactNode;
  name: string;
  isActive: boolean;
  labelExpanded?: string;
  labelCollapsed?: string;
  tooltip?: string;
}

export const SectionExpanderField: React.FC<SectionExpanderFieldProps> = ({
  name,
  isActive,
  children,
  heading,
  labelExpanded = '',
  labelCollapsed = '',
  tooltip,
}) => {
  const classes = useStyles();

  const paperVariant = isActive ? 'outlined' : 'gray';

  return (
    <Box className={classes.wrapper}>
      <Paper variant={paperVariant} className={classes.paper}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h4" className={classes.heading}>
              {heading}
            </Typography>
            {tooltip && (
              <Tooltip title={tooltip}>
                <HelpOutline color="disabled" />
              </Tooltip>
            )}
          </Box>

          <Field
            name={name}
            label={isActive ? labelExpanded : labelCollapsed}
            formControlLabelProps={{
              labelPlacement: 'start',
              componentsProps: {
                typography: {
                  variant: 'body2',
                },
              },
            }}
            component={SwitchField}
          />
        </Box>

        <Collapse in={isActive}>
          <Box className={classes.content}>{children}</Box>
        </Collapse>
      </Paper>
    </Box>
  );
};
