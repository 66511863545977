export enum OfflineDonorType {
  individual = 'individual',
  organization = 'organization',
}

export enum PaymentMethod {
  cash = 'cash',
  check = 'check',
  card = 'card',
}
