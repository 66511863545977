import React from 'react';
import { Form } from 'react-final-form';
import { Grid, Typography, styled } from '@mui/material';

import { useModal, useCurrentOrganization } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { Button, Field, Modal, TextField } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import { ProgramCreateSchema } from 'src/constants/validationSchemas/program';
import { usePrograms } from 'src/hooks/program';

const StyledButton = styled(Button)(() => ({
  margin: 0,
  width: 120,
}));

export const ProgramCreateModal: React.FC = () => {
  const { isOpen, closeModal } = useModal(MODALS.PROGRAM_CREATE_MODAL);

  const { organizationId } = useCurrentOrganization();

  const { createProgram } = usePrograms(organizationId ?? '');

  const handleCloseModal = () => {
    closeModal();
  };

  const onSubmit = async (values: any) => {
    await createProgram(values);
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Title>
        <Typography textAlign="center" variant="h2">
          New Program
        </Typography>
      </Modal.Title>

      <Modal.Content>
        <Typography textAlign="center" mb={5}>
          A program is like a folder. Create and move any campaign you wish!
        </Typography>
        <Form
          onSubmit={onSubmit}
          validate={values => validateWithSchema(ProgramCreateSchema, values)}
        >
          {({ handleSubmit, form, valid, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={4} width={560}>
                  <Grid item>
                    <Field name="name" required label="Name" fullWidth component={TextField} />
                  </Grid>
                  <Grid item>
                    <Field name="description" label="Description" fullWidth component={TextField} />
                  </Grid>
                  <Grid item>
                    <Grid container item xs={12} justifyContent="center" spacing={1}>
                      <Grid item>
                        <StyledButton onClick={handleCloseModal} color="inherit">
                          Cancel
                        </StyledButton>
                      </Grid>
                      <Grid item>
                        <StyledButton
                          loading={submitting}
                          disabled={!valid || submitting}
                          onClick={form.submit}
                        >
                          Create
                        </StyledButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </Modal.Content>
    </Modal>
  );
};
