import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Tab,
  Theme,
  Typography,
} from '@mui/material';
import { Close, ReceiptOutlined } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import { useModal, useExperienceId } from '@vizsla/hooks';

import {
  RegistrationOptionBasicsEditForm,
  RegistrationOptionFundraisingEditForm,
  RegistrationOptionSwagBag,
} from 'src/components/registrationOption';
import { TransitionSlide } from 'src/components/shared';
import { MODALS } from 'src/constants/modals';
import { useRegistrationOptionById } from 'src/hooks/registrationOption';

const useStyles = makeStyles((theme: Theme) => ({
  dialogWrapper: {
    '& .MuiDialog-container': {
      justifyContent: 'flex-end',
    },
    '& .MuiDialog-paper': {
      width: '50%',
      right: '0',
    },
  },
  dialogTitle: {
    padding: 0,
  },
  dialogTitleGridTextItem: {
    overflowX: 'hidden',
  },
  dialogTitleGridTextItemHeading: {
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  wrapperContent: {
    padding: theme.spacing(5, 6),
  },
  content: {
    maxWidth: 700,
  },
  marginBottom: {
    marginBottom: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  tabPanel: {
    padding: 0,
  },
}));

enum RegistrationOptionEditFormTab {
  Basics = 'Basics',
  Fundraising = 'Fundraising',
  SwagBag = 'Swag Bag',
}

const RegistrationOptionEditModal: React.FC = () => {
  const classes = useStyles();
  const experienceId = useExperienceId();

  const { isOpen, closeModal, args } = useModal(MODALS.REGISTRATION_OPTION_EDIT_MODAL);

  const [tabValue, setTabValue] = React.useState<RegistrationOptionEditFormTab>(
    RegistrationOptionEditFormTab.Basics,
  );

  const onCloseModal = () => {
    closeModal();
  };

  const { registrationOption, loading } = useRegistrationOptionById(args?.registrationOptionId);

  const handleTabSwitch = (_: React.ChangeEvent<any>, newValue: RegistrationOptionEditFormTab) => {
    setTabValue(newValue);
  };

  const renderTitle = () => {
    if (loading) {
      return <Skeleton variant="text" width="70%" />;
    }

    if (_.isNil(registrationOption)) {
      return null;
    }

    return (
      <Grid container wrap="nowrap">
        <Grid item alignItems="center" sx={{ marginTop: '1px' }} marginRight={1}>
          <ReceiptOutlined />
        </Grid>
        <Grid item className={classes.dialogTitleGridTextItem}>
          <Typography variant="h3" className={classes.dialogTitleGridTextItemHeading}>
            {registrationOption.name}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onCloseModal}
      TransitionComponent={TransitionSlide}
      className={classes.dialogWrapper}
    >
      <div className={classes.wrapperContent}>
        <DialogTitle className={classes.dialogTitle}>
          <Grid>
            <IconButton
              aria-label="close"
              onClick={onCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 32,
                color: theme => theme.palette.grey[500],
              }}
              size="large"
            >
              <Close />
            </IconButton>
          </Grid>
          {renderTitle()}
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <TabContext value={tabValue}>
            <Grid container justifyContent="center" marginBottom={3}>
              <TabList onChange={handleTabSwitch}>
                <Tab
                  label={RegistrationOptionEditFormTab.Basics}
                  value={RegistrationOptionEditFormTab.Basics}
                />
                <Tab
                  label={RegistrationOptionEditFormTab.Fundraising}
                  value={RegistrationOptionEditFormTab.Fundraising}
                />
                <Tab
                  label={RegistrationOptionEditFormTab.SwagBag}
                  value={RegistrationOptionEditFormTab.SwagBag}
                />
              </TabList>
            </Grid>
            <TabPanel value={RegistrationOptionEditFormTab.Basics} className={classes.tabPanel}>
              <RegistrationOptionBasicsEditForm registrationOptionId={args?.registrationOptionId} />
            </TabPanel>
            <TabPanel
              value={RegistrationOptionEditFormTab.Fundraising}
              className={classes.tabPanel}
            >
              <RegistrationOptionFundraisingEditForm
                registrationOptionId={args?.registrationOptionId}
              />
            </TabPanel>
            <TabPanel value={RegistrationOptionEditFormTab.SwagBag} className={classes.tabPanel}>
              <RegistrationOptionSwagBag
                registrationOptionId={args?.registrationOptionId}
                experienceId={experienceId}
              />
            </TabPanel>
          </TabContext>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default RegistrationOptionEditModal;
