import React from 'react';
import _ from 'lodash';
import { Grid, Skeleton, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

import { useModal } from '@vizsla/hooks';
import { BottomActionButton } from '@vizsla/components';

import { PaperBlock } from 'src/components/shared';
import { MODALS } from 'src/constants/modals';
import {
  ExperienceBibPoolCreateModal,
  ExperienceBibPoolEditModal,
  ExperienceResetBibAssignmentModal,
} from 'src/modals';
import { useExperienceId, useExperienceBibAssignment } from 'src/hooks/experiences';
import { PaletteColor } from 'src/theme';

import { CampaignExperienceBibAssignmentMethodSection } from './CampaignExperienceBibAssignmentMethodSection';
import { CampaignExperienceIndividualBibAssignmentSection } from './CampaignExperienceIndividualBibAssignmentSection';
import { CampaignExperienceExcludeBibsSection } from './CampaignExperienceExcludeBibsSection';
import { CampaignExperienceBibPoolTable } from './CampaignExperienceBibPoolTable';

export const CampaignExperienceBibAssignment: React.FC = () => {
  const experienceId = useExperienceId();
  const { openModal } = useModal();

  const { loading, isEmptyBibPoolsList, poolBibAssignments } =
    useExperienceBibAssignment(experienceId);

  const canEditPools = _.isEmpty(poolBibAssignments);

  const handleCreateBibPool = () => {
    openModal(MODALS.BIB_POOL_CREATE_MODAL);
  };

  const renderContent = () => {
    if (loading) {
      return <Skeleton height={400} variant="rectangular" />;
    }
    if (isEmptyBibPoolsList) {
      return (
        <BottomActionButton
          icon={<Add />}
          onClick={() => handleCreateBibPool()}
          disabled={!canEditPools}
        >
          Add Bib Pool
        </BottomActionButton>
      );
    }
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <CampaignExperienceBibPoolTable />
        </Grid>
        <Grid item>
          <CampaignExperienceExcludeBibsSection />
          <CampaignExperienceIndividualBibAssignmentSection />
        </Grid>
        <Grid item>
          <CampaignExperienceBibAssignmentMethodSection />
        </Grid>
      </Grid>
    );
  };

  return (
    <PaperBlock title="Bib Assignment" collapsing defaultOpened>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h6">Bib Pools</Typography>
          <Typography variant="body2" color={PaletteColor.BlackText}>
            Create multiple bib pools to define how you want to assign the bibs numbers.
          </Typography>

          {!canEditPools && (
            <Typography color="error" variant="caption" py={2}>
              Reset your current bib assignment so that you can change bib pools settings.
            </Typography>
          )}
        </Grid>
        <Grid item>
          {renderContent()}
          <ExperienceBibPoolCreateModal />
          <ExperienceBibPoolEditModal />
          <ExperienceResetBibAssignmentModal />
        </Grid>
      </Grid>
    </PaperBlock>
  );
};
