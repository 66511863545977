import React from 'react';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';
import { makeStyles } from '@mui/styles';
import { Button, Grid, Theme, Typography, IconButton } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';

import { GridDefaultPadding } from '@vizsla/components';

import { TooltipTextWrapper } from 'src/components/shared';

const useStyles = makeStyles<Theme>(theme => ({
  rowButtons: {
    marginBottom: theme.spacing(2),
  },
}));

interface ExperienceFormCollectionProps {
  children: JSX.Element;
  formValues: Array<any>;
  canAddForm?: boolean;
  canRemoveForm?: boolean;
  addForm: () => void;
  removeForm: () => void;
  selectedForm: number;
  selectForm: (formIndex: number) => void;
}

export const ExperienceFormCollectionController: React.FC<ExperienceFormCollectionProps> = ({
  children: selectedFormComponent,
  formValues,
  addForm,
  removeForm,
  canAddForm = true,
  canRemoveForm = true,
  selectedForm,
  selectForm,
}) => {
  const classes = useStyles();

  function renderFormIndexButton(formIndex: number) {
    const variantProperty = selectedForm === formIndex ? 'contained' : 'text';

    return (
      <Button
        variant={variantProperty}
        size="large"
        color="secondary"
        onClick={() => selectForm(formIndex)}
      >
        {formIndex + 1}
      </Button>
    );
  }

  return (
    <React.Fragment>
      <Grid container direction="row">
        <GridDefaultPadding item xs={6}>
          <TooltipTextWrapper
            text="Experiences are the registration and/or ticketed events that comprise a campaign."
            type="question"
          >
            <Typography variant="subtitle2">Experiences</Typography>
          </TooltipTextWrapper>
        </GridDefaultPadding>
      </Grid>
      <Grid
        className={classes.rowButtons}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          {formValues.map((_: any, formIndex: number) => renderFormIndexButton(formIndex))}
          <FormSpy subscription={{ valid: true }}>
            {(formSpyProps: FormSpyRenderProps) => {
              return (
                <Button
                  variant="text"
                  size="large"
                  color="secondary"
                  onClick={addForm}
                  disabled={!canAddForm || !formSpyProps.valid}
                >
                  +
                </Button>
              );
            }}
          </FormSpy>
        </Grid>
        {canRemoveForm && (
          <Grid item>
            <IconButton onClick={removeForm} size="large">
              <DeleteOutline fontSize="medium" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      {selectedFormComponent}
    </React.Fragment>
  );
};
