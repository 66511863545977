import { gql } from '@apollo/client';

export const TEAM_RT_FRAGMENT = gql`
  fragment TeamRTFragment on TeamFundraising {
    id
    name
    createdAt
    avatar {
      id
      downloadUrl
    }
    teamCaptain: members(filter: { role: { equals: "Team Captain" } }) {
      items {
        id
        role
        attendee {
          id
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
    members {
      count
      items {
        id
      }
    }
    fundraiserGoal
    donations {
      items {
        id
        amount
      }
    }
  }
`;

export const TEAMS_RT_LIST_QUERY = gql`
  query TeamRTsList($filter: TeamFundraisingFilter, $skip: Int, $first: Int) {
    teamFundraisingsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        ...TeamRTFragment
      }
    }
  }
  ${TEAM_RT_FRAGMENT}
`;

export const TEAM_RT_CREATE_MUTATION = gql`
  mutation TeamRTCreate($createData: TeamFundraisingCreateInput!) {
    teamFundraisingCreate(data: $createData) {
      id
      name
    }
  }
`;

export const TEAM_WEB_LINK_COUNTER_BY_NAME_QUERY = gql`
  query TeamWebLinkCounterByName($campaignId: ID!, $webLink: String!) {
    teamFundraisingsList(
      filter: { campaign: { id: { equals: $campaignId } }, webLink: { equals: $webLink } }
    ) {
      count
    }
  }
`;

export const TEAMS_RT_NAMES_LIST_QUERY = gql`
  query TeamRTsNamesList($filter: TeamFundraisingFilter) {
    teamFundraisingsList(filter: $filter, first: 10) {
      items {
        id
        name
        campaign {
          id
        }
      }
    }
  }
`;
