import React from 'react';

import { useCurrentOrganization, useModal } from '@vizsla/hooks';
import { MODALS } from '@vizsla/constants';
import { FiltersLayout } from '@vizsla/components';

import { PartnershipLayout } from './styles';

export const PartnershipPageLayout = ({ children: PartnershipContent }) => {
  const { openModal } = useModal();
  const { organizationIsLoading } = useCurrentOrganization();

  const openCreatePartnership = () => {
    openModal(MODALS.PARTNERSHIP_CREATE_MODAL);
  };

  const isButtonsDisabled = organizationIsLoading;

  return (
    <PartnershipLayout>
      <FiltersLayout
        withSearch
        withPagination
        rightButtonProps={{
          content: 'Add Partnership',
          onClick: openCreatePartnership,
          disabled: isButtonsDisabled,
        }}
      >
        {PartnershipContent}
      </FiltersLayout>
    </PartnershipLayout>
  );
};
