import * as yup from 'yup';

import { ExperienceBibAssignment, Attendee } from '@vizsla/graphql';
import { concatDateWithTime, isDateGreaterThan } from '@vizsla/utils';

import { AllocationType } from 'src/modals/experienceBibPoolCreateEditModals/constants/experienceBibPoolFormConstants';

const DEFAULT_MAX_STRING_LENGTH = 100;
const DEFAULT_MAX_STRING_LENGTH_ERROR_MESSAGE = `Must be maximum ${DEFAULT_MAX_STRING_LENGTH} symbols`;

export const ExperienceIndividualBibCreateFormSchema = yup.object({
  bibNumber: yup
    .number()
    .nullable()
    .required('Bib Number is required')
    .positive('Must be positive')
    .test(
      'this-bib-number-already-belongs-to-another-attendee',
      'This Bib Number already belongs to another attendee',
      (newIndividualBibNumber: number | null | undefined, { options }): boolean => {
        const { context } = options;
        const { individualBibAssignments } = context as {
          individualBibAssignments: Array<ExperienceBibAssignment>;
        };

        const individualBibNumbers = individualBibAssignments.map(
          (individualBibAssignment: ExperienceBibAssignment) => individualBibAssignment?.bibNumber,
        );

        return !individualBibNumbers.includes(newIndividualBibNumber);
      },
    )
    .test(
      'this-bib-number-is-excluded',
      'This Bib Number is excluded',
      (newIndividualBibNumber: number | null | undefined, { options }): boolean => {
        const { context } = options;
        const { excludedBibNumbers } = context as {
          excludedBibNumbers: Array<number>;
        };

        if (newIndividualBibNumber) {
          return !excludedBibNumbers.includes(newIndividualBibNumber);
        }
        return true;
      },
    ),
  attendee: yup
    .object()
    .nullable()
    .required('Attendee is required')
    .test(
      'this-attendee-already-has-an-individual-bib-number',
      'This attendee already has an individual Bib Number',
      (attendee: Attendee | null | undefined, { options }): boolean => {
        const { context } = options;
        const { individualBibAssignments } = context as {
          individualBibAssignments: Array<ExperienceBibAssignment>;
        };

        const assignedAttendees = individualBibAssignments.map(
          (individualBibAssignment: ExperienceBibAssignment) =>
            individualBibAssignment?.attendee?.id,
        );
        return !assignedAttendees.includes(attendee?.id);
      },
    ),
});

export const ExperienceBibPoolFormSchema = yup.object({
  name: yup
    .string()
    .nullable()
    .max(DEFAULT_MAX_STRING_LENGTH, DEFAULT_MAX_STRING_LENGTH_ERROR_MESSAGE)
    .required('Bib Pool Name is required'),
  allocationType: yup.string().nullable().required('Allocation Type is required'),
  teamAllocation: yup.array().when('allocationType', {
    is: AllocationType.SpecificTeams,
    then: schema => schema.ensure().min(1, 'At least one team must be selected'),
    otherwise: schema => schema.nullable(),
  }),
});

export const ExperienceBibAutoDatesFormSchema = yup.object({
  autoAssignStartDate: yup
    .string()
    .nullable()
    .required('Start Date is required')
    .test(
      'start-date-does-not-exceed-end-date',
      'Start Date must not exceed End Date',
      (startDate: string | null | undefined, { parent }) => {
        const {
          autoAssignStartTime: startTime,
          autoAssignEndDate: endDate,
          autoAssignEndTime: endTime,
        } = parent;

        const startDateTime = concatDateWithTime(startDate, startTime);
        const endDateTime = concatDateWithTime(endDate, endTime);

        return isDateGreaterThan(endDateTime, startDateTime, 'minute');
      },
    ),
  autoAssignStartTime: yup.string().nullable().required('Start Time is required'),
  autoAssignEndDate: yup.string().nullable().required('End Date is required'),
  autoAssignEndTime: yup.string().nullable().required('End Time is required'),
});
