import React from 'react';

import { useExperienceHeaderImage } from '@vizsla/hooks';

import { ExperienceImageLoader } from './ExperienceImageLoader';
import { ExperienceHasImage } from './ExperienceHasImage';
import { ExperienceAddImage } from './ExperienceAddImage';

export function ExperienceImage() {
  const { data, hasData, updating, fetching, attach, detach, replace } = useExperienceHeaderImage();

  if (fetching) {
    return <ExperienceImageLoader />;
  }

  if (hasData) {
    return (
      <ExperienceHasImage
        file={data}
        updating={updating}
        fetching={fetching}
        detach={detach}
        replace={replace}
      />
    );
  }

  return <ExperienceAddImage file={data} updating={updating} fetching={fetching} attach={attach} />;
}
