import React from 'react';
import { Outlet } from 'react-router-dom';

import { StoreAssetManagementDataProvider } from 'src/providers/storeAssets';
import { StoreAssetManagementLayout } from 'src/layouts';

const StoreAssetManagementWrapper: React.FC = () => {
  return (
    <StoreAssetManagementDataProvider>
      <StoreAssetManagementLayout>
        <Outlet />
      </StoreAssetManagementLayout>
    </StoreAssetManagementDataProvider>
  );
};

export { StoreAssetManagementWrapper };
