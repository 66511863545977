import React from 'react';
import { styled } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { useExperienceImages, useModal } from '@vizsla/hooks';
import { TableColumn, TableData } from '@vizsla/components';
import { File } from '@vizsla/graphql';

import { useExperienceId } from 'src/hooks/experiences';
import { MODALS } from 'src/constants/modals';
import { EMPTY_IMAGE } from 'src/assets';
import { ExperienceImagesModalType } from 'src/types/experience';

const StyledImageBlock = styled('div')(({ theme }) => ({
  width: '100px',
  height: '75px',
  '& > img': {
    display: 'block',
    width: '100%',
    height: '100%',
    maxHeight: '100px',
    objectFit: 'contain',
  },
}));

/** @deprecated Changed by `VSL-1129`, use `useExperienceHeaderImage` instead. */
export const CampaignExperienceHeaderImagesTable: React.FC = () => {
  const experienceId = useExperienceId();

  const { openModal } = useModal();

  const {
    deleteExperienceHeaderImage,
    experienceHeaderImages,
    updatingHeaderImages,
    loadingHeaderImages,
  } = useExperienceImages(experienceId);

  const handleAddExperienceHeaderImages = () => {
    openModal(MODALS.EXPERIENCE_HEADER_IMAGES_UPDATE_MODAL, {
      modalType: ExperienceImagesModalType.Create,
    });
  };

  const COLUMNS: TableColumn<File>[] = [
    {
      title: 'Image',
      key: 'image',
      render: (experienceHeaderImage: File) => {
        return (
          <StyledImageBlock>
            <img src={experienceHeaderImage?.downloadUrl ?? EMPTY_IMAGE} alt="HeaderImage" />
          </StyledImageBlock>
        );
      },
    },
    {
      title: 'Name',
      key: 'name',
      render: (experienceHeaderImage: File) => {
        return experienceHeaderImage?.filename ?? '-';
      },
    },
  ];

  const ROW_ACTIONS = [
    {
      actionName: 'Edit',
      onAction: (experienceHeaderImage: File) =>
        openModal(MODALS.EXPERIENCE_HEADER_IMAGES_UPDATE_MODAL, {
          modalType: ExperienceImagesModalType.Edit,
          experienceHeaderImage,
        }),
    },
    {
      actionName: 'Delete',
      onAction: (experienceHeaderImage: File) =>
        openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
          deleteFunction: () => deleteExperienceHeaderImage(experienceHeaderImage.id || ''),
          objectName: experienceHeaderImage.filename,
        }),
    },
  ];

  const BOTTOM_ACTIONS = [
    {
      icon: <AddIcon />,
      actionName: ' Add Images',
      onAction: () => handleAddExperienceHeaderImages(),
    },
  ];

  return (
    <TableData
      loading={loadingHeaderImages || updatingHeaderImages}
      columns={COLUMNS}
      dataSource={experienceHeaderImages}
      bottomActions={BOTTOM_ACTIONS}
      rowActions={ROW_ACTIONS}
    />
  );
};
