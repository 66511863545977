import { DateTime } from 'luxon';
import * as yup from 'yup';

import {
  concatDateWithTime,
  isDateLessOrEquals,
  isDateGreaterOrEquals,
  isHtmlTextLengthValid,
} from '@vizsla/utils';

const DEFAULT_MAX_STRING_LENGTH = 100;
const DEFAULT_MAX_STRING_LENGTH_ERROR_MESSAGE = `Must be maximum ${DEFAULT_MAX_STRING_LENGTH} symbols`;
export const MAX_LENGTH_WAIVER = 10_000;

const imageSchema = yup.object().nullable().notRequired();

export type ExperienceContextSchema = {
  campaignStartDate: string | null | undefined;
  campaignEndDate: string | null | undefined;
};

export const RegistrationExperienceBaseSchema = yup.object({
  name: yup
    .string()
    .nullable()
    .required('Experience Name is required')
    .max(DEFAULT_MAX_STRING_LENGTH, DEFAULT_MAX_STRING_LENGTH_ERROR_MESSAGE),
  experienceCategory: yup.string().nullable().required('Experience Category is required'),
});

export const RegistrationExperienceWithStartDateSchema = yup.object({
  name: yup
    .string()
    .nullable()
    .required('Experience Name is required')
    .max(DEFAULT_MAX_STRING_LENGTH, DEFAULT_MAX_STRING_LENGTH_ERROR_MESSAGE),
  experienceCategory: yup.string().nullable().required('Experience Category is required'),
  startDate: yup
    .string()
    .nullable()
    .required('Start Date is required')
    .test(
      'start-date-should-greater-or-equal-start-date-campaign',
      'Start Date should be greater or equal than start of campaign',
      (experienceStartDate: string | null | undefined, { options, parent }): boolean => {
        const { context } = options;

        const { startTime: experienceStartTime } = parent;
        const { campaignStartDate } = context as ExperienceContextSchema;

        const experienceFullStartDate = concatDateWithTime(
          experienceStartDate,
          experienceStartTime,
        );

        return isDateGreaterOrEquals(experienceFullStartDate, campaignStartDate, 'day');
      },
    )
    .test(
      'start-date-should-less-or-equal-end-date-campaign',
      'Start Date should be less or equal than End campaign',
      (experienceStartDate: string | null | undefined, { options, parent }): boolean => {
        const { context } = options;

        const { startTime: experienceStartTime } = parent;
        const { campaignEndDate } = context as ExperienceContextSchema;

        const experienceFullStartDate = concatDateWithTime(
          experienceStartDate,
          experienceStartTime,
        );

        return isDateLessOrEquals(experienceFullStartDate, campaignEndDate, 'day');
      },
    ),
  startTime: yup
    .string()
    .nullable()
    .required('Start Time is required')
    .test(
      'start-time-should-greater-or-equal-start-time-campaign',
      'Start Time should be greater or equal Start of campaign',
      (experienceStartTime: string | null | undefined, { options, parent }): boolean => {
        const { context } = options;

        const { campaignStartDate } = context as ExperienceContextSchema;
        const { startDate: experienceStartDate } = parent;

        const experienceFullStartDate = concatDateWithTime(
          experienceStartDate,
          experienceStartTime,
        );

        return isDateGreaterOrEquals(experienceFullStartDate, campaignStartDate, 'hour');
      },
    ),
});

export const RegistrationExperienceSchemaEndDateTime = yup.object({
  name: yup
    .string()
    .nullable()
    .required('Campaign name is required')
    .max(DEFAULT_MAX_STRING_LENGTH, DEFAULT_MAX_STRING_LENGTH_ERROR_MESSAGE),
  experienceCategory: yup.string().nullable().required('Experience Category is required'),
  startDate: yup.string().nullable().required('Start Date is required'),
  startTime: yup.string().nullable().required('Start Time is required'),
  endDate: yup
    .string()
    .nullable()
    .required('End Date is required')
    .test('is-after-start', 'End Date must be after Start Date', function (value) {
      const { startDate } = this.parent;
      if (!startDate || !value) {
        return true;
      }
      return DateTime.fromISO(value) >= DateTime.fromISO(startDate);
    }),

  endTime: yup.string().nullable().required('End Time is required'),
});

export const ExperienceGeneralSettingsUpdateSchema = yup.object({
  name: yup.string().required('Name is required'),
  experienceCategory: yup.string().required('Experience Category is required'),
  startDate: yup.string().nullable().required('Start Date is required'),
  startTime: yup.string().nullable().required('Start Time is required'),
  endDate: yup
    .string()
    .nullable()
    .required('End Date is required')
    .test('is-after-start', 'End Date must be after Start Date', function (value) {
      const { startDate } = this.parent;
      if (!startDate || !value) {
        return true;
      }
      return DateTime.fromISO(value) >= DateTime.fromISO(startDate);
    }),
});

export const ExperienceVenueSchema = yup.object({
  name: yup.string().required('Name is required'),
  address: yup.string().required('Address is required'),
  notes: yup.string().nullable(),
});

export const ExperienceWaiverSchema = yup.object({
  waiverTextBody: yup
    .string()
    .required('Waiver Text is required')
    .test(
      `waiver-text-must-contain-more-than-1-character-and-less-than-${MAX_LENGTH_WAIVER}-characters`,
      `Waiver text must contain more than 1 character and less than ${MAX_LENGTH_WAIVER} characters.`,
      (value: string | undefined) => isHtmlTextLengthValid(value, 1, MAX_LENGTH_WAIVER),
    ),
  waiverAllowMultipleAttendeeConsent: yup.bool().nullable(),
});

export const ExperienceHeaderImagesCreateSchema = yup.object({
  experienceHeaderImages: yup
    .array()
    .of(imageSchema)
    .min(1, 'At least one image must be selected')
    .nullable(),
});

export const ExperienceHeaderImageUpdateSchema = yup.object({
  experienceHeaderImage: imageSchema,
});

export const ExperienceHeaderLogoImageUpdateSchema = yup.object({
  experienceLogoImage: imageSchema,
});
