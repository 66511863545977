import { gql } from '@apollo/client';

export const EXPERIENCE_FRAGMENT = gql`
  fragment ExperienceFragment on Experience {
    id
    name
    experienceType
    experienceCategory
    startDate
    earlyBirdUrlCode
    endDate
    waiverAllowMultipleAttendeeConsent
    waiverTextBody

    campaign {
      name
    }
    attendees {
      items {
        id
      }
    }
    registrationOptions {
      items {
        id
        attendees {
          items {
            id
          }
        }
      }
    }
  }
`;

export const EXPERIENCE_LIST_PER_CAMPAIGN = gql`
  query ExperienceperCampaign($campaignId: ID!) {
    experiencesList(filter: { campaign: { id: { equals: $campaignId } } }) {
      count
      items {
        ...ExperienceFragment
      }
    }
  }
`;

export const TEAMS_NAME_PER_EXPERIENCE = gql`
  query TeamsPerExperience($experienceId: ID!, $teamName: String) {
    experience(id: $experienceId) {
      teams(filter: { name: { equals: $teamName } }) {
        count
        items {
          name
        }
      }
    }
  }
`;

export const EXPERIENCE_LIST_QUERY = gql`
  query ExperienceList($filter: ExperienceFilter, $skip: Int, $first: Int) {
    experiencesList(filter: $filter, skip: $skip, first: $first) {
      items {
        ...ExperienceFragment
      }
    }
  }
  ${EXPERIENCE_FRAGMENT}
`;

export const EXPERIENCE_QUERY = gql`
  query Experience($id: ID!) {
    experience(id: $id) {
      ...ExperienceFragment
    }
  }
  ${EXPERIENCE_FRAGMENT}
`;

export const EXPERIENCE_CREATE_MANY_MUTATION = gql`
  mutation ExperienceCreateMany($data: [ExperienceCreateManyInput]!) {
    experienceCreateMany(data: $data) {
      count
    }
  }
`;

export const EXPERIENCE_CREATE_MUTATION = gql`
  mutation ExperienceCreate($data: ExperienceCreateInput!) {
    experienceCreate(data: $data) {
      ...ExperienceFragment
    }
  }
  ${EXPERIENCE_FRAGMENT}
`;

export const EXPERIENCE_UPDATE_MUTATION = gql`
  mutation ExperienceUpdate($data: ExperienceUpdateInput!, $filter: ExperienceKeyFilter!) {
    experienceUpdate(data: $data, filter: $filter) {
      ...ExperienceFragment
    }
  }
  ${EXPERIENCE_FRAGMENT}
`;

export const EXPERIENCE_DELETE_MUTATION = gql`
  mutation ExperienceDelete($id: ID!) {
    experienceDelete(filter: { id: $id }) {
      success
    }
  }
`;

export const EXPERIENCE_VENUE_FRAGMENT = gql`
  fragment ExperienceVenueFragment on ExperienceVenue {
    id
    address
    name
    notes
  }
`;

const EXPERIENCE_GENERAL_SETTINGS_FRAGMENT = gql`
  fragment ExperienceGeneralFragment on Experience {
    hideFromPublic
    timeZone
    privacy
    whiteListTicketingExperience {
      items {
        id
        name
      }
    }
    whiteListRegistrationExperience {
      id
      name
    }
    dateRangeType
    venues {
      items {
        ...ExperienceVenueFragment
      }
    }
  }
  ${EXPERIENCE_VENUE_FRAGMENT}
`;

export const EXPERIENCE_GENERAL_SETTINGS_LIST_QUERY = gql`
  query ExperienceGeneralSettingsList($id: ID!) {
    experience(id: $id) {
      ...ExperienceFragment
      ...ExperienceGeneralFragment
    }
  }
  ${EXPERIENCE_FRAGMENT}
  ${EXPERIENCE_GENERAL_SETTINGS_FRAGMENT}
`;

export const EXPERIENCE_GENERAL_UPDATE_MUTATION = gql`
  mutation ExperienceGeneralUpdateMutation(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter!
  ) {
    experienceUpdate(data: $data, filter: $filter) {
      ...ExperienceFragment
      ...ExperienceGeneralFragment
    }
  }
  ${EXPERIENCE_FRAGMENT}
  ${EXPERIENCE_GENERAL_SETTINGS_FRAGMENT}
`;

export const EXPERIENCE_VENUE_CREATE_MUTATION = gql`
  mutation ExperienceVenueCreateMutation($data: ExperienceVenueCreateInput!) {
    experienceVenueCreate(data: $data) {
      ...ExperienceVenueFragment
    }
  }
  ${EXPERIENCE_VENUE_FRAGMENT}
`;

export const EXPERIENCE_VENUE_UPDATE_MUTATION = gql`
  mutation ExperienceVenueUpdateMutation(
    $data: ExperienceVenueUpdateInput!
    $filter: ExperienceVenueKeyFilter!
  ) {
    experienceVenueUpdate(data: $data, filter: $filter) {
      ...ExperienceVenueFragment
    }
  }
  ${EXPERIENCE_VENUE_FRAGMENT}
`;

export const EXPERIENCE_VENUE_DELETE_MUTATION = gql`
  mutation ExperienceVenueDeleteMutation($id: ID!) {
    experienceVenueDelete(filter: { id: $id }) {
      success
    }
  }
`;

export const EXPERIENCE_TRANSACTION_FEES_QUERY = gql`
  query ExperienceTransactionFees($id: ID) {
    experience(id: $id) {
      id
      transactionFees
    }
  }
`;
