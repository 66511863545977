import React from 'react';
import _ from 'lodash';
import { Box, Checkbox, CircularProgress } from '@mui/material';
import { Lock as LockIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { useNotification } from '@vizsla/hooks';
import { CampaignQuestion, CampaignQuestionType } from '@vizsla/types';

import { t } from 'src/utils/template';
import { Shade } from 'src/theme';

const useStyles = makeStyles(() => ({
  lockIcon: {
    color: Shade.Gray[400],
  },
}));

enum QuestionType {
  Required = 'required',
  Optional = 'optional',
}

interface CampaignQuestionRequiredCheckboxProps {
  isRequired: boolean;
  isMandatory: boolean;
  question: CampaignQuestion;
  onCheck: (
    questionId: string | null | undefined,
    isEnabled: boolean,
    type: CampaignQuestionType,
  ) => Promise<CampaignQuestion | null>;
}

export const CampaignQuestionRequiredCheckbox: React.FC<CampaignQuestionRequiredCheckboxProps> = ({
  isRequired,
  isMandatory,
  question: campaignQuestion,
  onCheck,
}) => {
  const classes = useStyles();
  const notification = useNotification();

  const [isUpdating, setUpdating] = React.useState<boolean>(false);

  const onToggleIsRequired = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isMandatory) {
      return;
    }

    const { checked: isRequired } = event.target;
    const questionType = !_.isNil(campaignQuestion?.question)
      ? CampaignQuestionType.Default
      : CampaignQuestionType.Custom;

    try {
      setUpdating(true);
      await onCheck(campaignQuestion?.id, isRequired, questionType);

      notification.success(
        t('campaign_question_toggle_required_success', {
          type: isRequired ? QuestionType.Required : QuestionType.Optional,
        }),
      );
    } catch (e) {
      notification.error(
        t('campaign_question_toggle_required_error', {
          type: isRequired ? QuestionType.Required : QuestionType.Optional,
        }),
      );

      console.error(e);
    } finally {
      setUpdating(false);
    }
  };

  if (isUpdating) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={20} />
      </Box>
    );
  }

  return isMandatory ? (
    <LockIcon className={classes.lockIcon} />
  ) : (
    <Checkbox checked={isRequired} onChange={onToggleIsRequired as any} />
  );
};
