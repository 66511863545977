import React from 'react';
import { Grid } from '@mui/material';

import { PaginationProvider, SearchProvider } from '@vizsla/providers';

import { CampaignMetricsCards } from 'src/components/campaign';
import { CampaignDetailItem } from 'src/types/campaign';

import { ExperiencesTable } from './ExperiencesTable';
import { RegistrationAndTicketingRecentActivity } from './RegistrationAndTicketingRecentActivity';

interface DashboardProps {
  campaign: CampaignDetailItem;
}

export const RegistrationAndTicketingCampaignDashboard: React.FC<DashboardProps> = ({
  campaign,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container alignItems="flex-start" spacing={4}>
          <CampaignMetricsCards />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <PaginationProvider>
          <ExperiencesTable />
        </PaginationProvider>
      </Grid>

      <Grid item xs={12}>
        <SearchProvider>
          <RegistrationAndTicketingRecentActivity campaign={campaign} />
        </SearchProvider>
      </Grid>
    </React.Fragment>
  );
};
