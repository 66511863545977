import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { PaletteColor } from '@vizsla/theme';
import { Button } from '@vizsla/components';

export const StyledLink = styled(Link)`
  color: ${PaletteColor.TealText};
  text-decoration: none;
  margin-top: ${({ theme }) => theme.spacing(1)};
`;
export const StyledButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing(2.5, 0, 0)};
  display: block;
`;
StyledButton.defaultProps = {
  type: 'submit',
  color: 'secondary',
};

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;
