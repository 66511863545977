import React from 'react';
import { GroupOutlined as GroupIcon } from '@mui/icons-material';

import { InformationCard } from '@vizsla/components';
import { formatMoney } from '@vizsla/utils';

import { useCampaignDonationsMetrics, useCampaignId } from 'src/hooks/campaign';

export function AverageDonationCard() {
  const campaignId = useCampaignId();

  const { data, loading } = useCampaignDonationsMetrics(campaignId);

  const average = React.useMemo(() => data?.totalRaised ?? 0, [data]);
  const averageFormatted = React.useMemo(() => formatMoney(average), [average]);

  return (
    <InformationCard
      title="Average Donation"
      value={averageFormatted}
      icon={<GroupIcon color="secondary" fontSize="large" />}
      loading={loading}
    />
  );
}
