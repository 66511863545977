import React from 'react';
import { Grid } from '@mui/material';

import { Modal, Field, SelectField } from '@vizsla/components';
import type { SelectFieldOption } from '@vizsla/types';

import { useStoreAsset } from 'src/hooks/storeAssets';
import { StoreAsseInventoryFormFields } from 'src/types/storeAssets';

const FormAttributesSection: React.FC = () => {
  const { storeAsset } = useStoreAsset();

  const variantsMap = React.useMemo(() => {
    const mapVariants = Object.entries(storeAsset?.variantsPossible).map(entry => {
      return { id: Date.now() + Math.random() * 10000, [entry[0]]: entry[1] };
    });
    return mapVariants;
  }, [storeAsset?.variantsPossible]);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Modal.ContentText>Attributes</Modal.ContentText>
      </Grid>

      {variantsMap.map(option => {
        const [name, data] = Object.entries(option)[1];

        const formartData = data as string[];

        const OPTIONS: SelectFieldOption[] = formartData?.map(option => ({
          value: option,
          label: option,
        }));

        return (
          <Grid item xs={6} key={option.id}>
            <Field
              label={name}
              name={`variantsValues.${name}`}
              options={OPTIONS}
              component={SelectField}
              fullWidth
            />
          </Grid>
        );
      })}
    </React.Fragment>
  );
};

export { FormAttributesSection };
