import styled from '@emotion/styled';
import { DialogContent, DialogTitle } from '@mui/material';

/// Title

export const Title = styled(DialogTitle)``;

/// Container

export const Container = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

/// Container

export const LoaderContainer = styled(Container)`
  height: 10rem;
  justify-content: center;
  align-items: center;
`;

Container.defaultProps = {
  dividers: true,
};
