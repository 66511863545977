import React from 'react';

import { FiltersLayout } from '@vizsla/components';

import { CampaignPeopleMetrics, CampaignPeopleTables } from 'src/components/campaign';

const CampaignPeople: React.FC = () => {
  return (
    <FiltersLayout withSearch withPagination>
      <React.Fragment>
        <CampaignPeopleMetrics />
        <CampaignPeopleTables />
      </React.Fragment>
    </FiltersLayout>
  );
};

export { CampaignPeople };
