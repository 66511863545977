type NotificationMessages = {
  [key: string]: string;
};

// format notification message key: domain_action_type

export default {
  campaign_create_success: 'Campaign successfully created',
  campaign_create_error: "We couldn't create campaign",
  entity_create_success: '{{objectName}}" has been successfully created',
  entity_create_error: 'Failed to create "{{objectName}}"! Error message: {{errorMessage}}',
  entity_update_success: '"{{objectName}}" has been successfully updated',
  entity_update_error: 'Failed to update "{{objectName}}"! Error message: {{errorMessage}}',
  entity_delete_success: '"{{objectName}}" successfully deleted',
  entity_delete_error: 'Failed to delete! Error message: {{errorMessage}}',
  experience_create_success: 'Experience successfully created',
  experience_create_error: "We couldn't create experience",
  experience_update_success: 'Experience successfully updated',
  experience_update_error: "We couldn't update experience",
  experience_delete_success: 'Experience successfully deleted',
  experience_delete_error: "We couldn't delete experience",
  experience_general_settings_update_success: 'Experience settings successfully updated',
  experience_general_settings_update_error: "We couldn't update experience settings",
  registration_option_update_success: 'Registration Option successfully updated',
  registration_option_update_error: "We couldn't update Registration Option",
  registration_option_create_success: 'Registration Option successfully created',
  registration_option_create_error: "We couldn't create Registration Option",
  registration_option_delete_success: 'Registration Option successfully deleted',
  registration_option_delete_error: "We couldn't delete Registration Option",
  registration_option_swag_bag_add_success: 'Swag bag asset successfully added',
  registration_option_swag_bag_add_error: "We couldn't add this swag bag asset",
  registration_option_swag_bag_delete_success: 'Swag bag asset successfully deleted',
  registration_option_swag_bag_delete_error: "We couldn't delete this swag bag asset",
  ticketing_option_create_success: 'Ticketing Option successfully created',
  ticketing_option_create_error: "We couldn't create Ticketing Option",
  ticketing_option_update_success: 'Ticketing Option successfully updated',
  ticketing_option_update_error: "We couldn't update Ticketing Option",
  ticketing_option_delete_success: 'Ticketing Option successfully deleted',
  ticketing_option_delete_error: "We couldn't delete Ticketing Option",
  experience_waiver_update_success: 'Waiver successfully updated',
  experience_waiver_update_error: "We couldn't update Waiver",
  discount_create_success: 'Discount code {{discountName}} successfully created',
  discount_create_error: "We couldn't create Discount code {{discountName}}",
  discount_delete_success: 'Discount code {{discountName}} successfully deleted',
  discount_delete_error: "We couldn't delete Discount code {{discountName}}",
  discount_update_success: 'Discount code {{discountName}} successfully updated',
  discount_update_error: "We couldn't update Discount code {{discountName}}",
  donation_success: 'Donation successfully added',
  donation_error: "We couldn't add donation",
  copy_success: '{{textName}} has been copied to clipboard',
  experience_bib_pool_create_success: 'Bib Pool successfully created',
  experience_bib_pool_create_error: "We couldn't create Bib Pool",
  experience_bib_pool_update_success: 'Bib Pool successfully updated',
  experience_bib_pool_update_error: "We couldn't update Bib Pool",
  experience_excluded_bib_number_create_success: 'Bib number {{bibNumber}} successfully excluded',
  experience_excluded_bib_number_create_error: "We couldn't exclude Bib number {{bibNumber}}",
  experience_excluded_bib_number_create_duplicate_error:
    "We couldn't exclude Bib number {{bibNumber}} because it was already excluded",
  experience_excluded_bib_number_create_duplicate_individual_bib_error:
    "We couldn't exclude Bib number {{bibNumber}} because it was already assigned individually",
  experience_individual_bib_number_create_success: 'Individual Bib number successfully created',
  experience_individual_bib_number_create_error: "We couldn't create individual Bib number",
  experience_assignment_reset_success: 'Bib assignment was successfully reset',
  experience_assignment_reset_error: "We couldn't reset bib assignment",
  experience_batch_assignment_success: 'Bib numbers were successfully assigned',
  experience_batch_assignment_error: "We couldn't assign bib numbers",
  experience_auto_assignment_dates_change_success: 'Auto assignment dates successfully set',
  experience_auto_assignment_dates_change_error: "We couldn't change auto assignment dates",
  discount_update_usage_error:
    "We couldn't update Discount code {{discountName}}. It has already been used",
  discount_search_error: 'Discount code fetching error',
  campaign_delete_success: 'Campaign successfully deleted',
  campaign_delete_error: "We couldn't delete campaign",
  campaign_status_error: "Campaign status isn't a 'Draft', you can't do this.",
  team_RT_create_success: 'Team {{teamName}} successfully created',
  team_RT_create_error: "We couldn't create Team",
  create_stripe_connection_link_error: "We couldn't creact connection link",
  dns_record_create_error: "We couldn't create public page url",
  campaign_custom_question_create_success: 'New custom question successfully added',
  campaign_custom_question_create_error: "We couldn't add new custom question",
  campaign_custom_question_update_success: 'Custom question successfully updated',
  campaign_custom_question_update_error: "We couldn't update custom question",
  campaign_question_toggle_enable_success: 'Question has been successfully {{action}}',
  campaign_question_toggle_enable_error: "We couldn't {{action}} question",
  campaign_question_toggle_required_success: 'Question has been successfully set to {{type}}',
  campaign_question_toggle_required_error: "We couldn't set question to {{type}}",
  campaign_question_category_toggle_enabled_success:
    '{{category}} questions have been successfully {{action}}',
  campaign_question_category_toggle_enabled_error: "We couldn't {{action}} {{category}} questions",
  campaign_question_link_experience_success: 'Experience settings successfully updated',
  campaign_question_link_experience_error: "We couldn't update experience settings",
  campaign_faq_create_success: 'Campaign FAQ successfully created',
  campaign_faq_create_error: "We couldn't create campaign FAQ",
  campaign_faq_edit_success: 'Campaign FAQ successfully Updated',
  campaign_faq_edit_error: "We couldn't update campaign FAQ",
  store_asset_create_success: 'Store asset created successfully',
  store_asset_create_error: 'Error while creating store asset!',
  store_asset_update_success: 'Store asset information has been updated successfully!',
  store_asset_update_error: 'Error while updating store asset!',
  store_asset_inventory_item_create_success: 'Store asset inventory item created successfully',
  store_asset_inventory_item_create_fail: 'Error while creating store asset inventory item!',
  store_asset_delete_success: 'Store asset deleted successfully',
  store_asset_delete_error: 'Error while deleting store asset!',
  experience_store_item_add_success: 'Store item successfully added',
  experience_store_item_add_error: 'Error while adding store item!',
  experience_store_item_update_success: 'Store item successfully updated',
  experience_store_item_update_error: 'Error while updating store item!',
  experience_store_item_delete_success: 'Store item successfully deleted',
  experience_store_item_delete_error: 'Error while deleting store item!',
  experience_store_enable_error: 'Error while enabling the store!',
  store_asset_not_found: 'The requested store asset does not exist',
  inventory_item_not_found: 'The requested inventory item does not exist',
  experience_swagbag_item_add_success: 'Swagbag item successfully added',
  experience_swagbag_item_add_error: 'Error while adding swagbag item!',
  organization_create_error: 'Could not create an organization',
  experience_header_images_add_success: 'Header Images successfully added',
  experience_header_images_add_error: "We couldn't add Header Images",
  experience_header_images_update_success: 'Header Image successfully updated',
  experience_header_images_update_error: "We couldn't update Header Image",
  experience_logo_image_add_success: 'Logo Image successfully added',
  experience_logo_image_add_error: "We couldn't add Logo Image",
  experience_logo_image_update_success: 'Logo Image successfully updated',
  experience_logo_image_update_error: "We couldn't update Logo Image",
  organization_add_member_success: 'Member successfully added',
  organization_add_member_error: "We couldn't add member",
  organization_update_member_success: 'Member successfully updated',
  organization_update_member_error: "We couldn't update member",
  resend_invite_success: 'Invite successfully resent',
  resend_invite_error: "We couldn't resend invite",
  partnership_create_success: 'Partnership successfully created',
  partnership_create_error: "We couldn't create partnership",
} as NotificationMessages;
