import React from 'react';
import { Grid } from '@mui/material';
import { Form } from 'react-final-form';
import type { FormApi } from 'final-form';

import { useNotification } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { Modal } from '@vizsla/components';

import { useExperienceStoreAssets } from 'src/hooks/storeAssets';
import type {
  ExperienceStoreAssetAddOrUpdateFormValues,
  ExperienceStoreAsset,
} from 'src/types/storeAssets';
import { ExperienceStoreAssetAddFormSchema } from 'src/constants/validationSchemas/storeAssets';
import { t } from 'src/utils/template';

import { getExperienceStoreAssetUpdateDataInput } from '../../utils';
import { FormSearchSection, FormPriceSection } from './sections';

interface ExperienceStoreAssetAddFormProps {
  experienceStoreAsset: ExperienceStoreAsset;
  closeModal: () => void;
}

const ExperienceStoreAssetUpdateForm: React.FC<ExperienceStoreAssetAddFormProps> = ({
  experienceStoreAsset,
  closeModal,
}) => {
  const notification = useNotification();

  const initialValues = React.useRef<ExperienceStoreAssetAddOrUpdateFormValues>({
    storeAsset: experienceStoreAsset.storeAsset as any,
    price: experienceStoreAsset.price || undefined,
  });

  const { updateExperienceStoreAsset, updating } = useExperienceStoreAssets();

  const onSubmit = React.useCallback(
    async (formValues: ExperienceStoreAssetAddOrUpdateFormValues, form: FormApi<any>) => {
      try {
        const inputData = getExperienceStoreAssetUpdateDataInput(
          formValues,
          experienceStoreAsset.id || '',
        );

        await updateExperienceStoreAsset(inputData);

        notification.success(t('experience_store_item_update_success'));

        closeModal();
      } catch (error) {
        notification.error(t('experience_store_item_update_error'));
        console.error(error);
      }
    },
    [experienceStoreAsset.id, updateExperienceStoreAsset, notification, closeModal],
  );

  const validate = React.useCallback(
    async (formValues: ExperienceStoreAssetAddOrUpdateFormValues) => {
      return validateWithSchema(ExperienceStoreAssetAddFormSchema, formValues);
    },
    [],
  );

  return (
    <Form
      initialValues={initialValues.current}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting, pristine, validating, invalid }) => {
        const isSubmitLoading = submitting || validating || updating;
        const isSubmitDisabled = isSubmitLoading || pristine || validating || invalid;

        return (
          <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
            <Grid container>
              <FormSearchSection />

              <FormPriceSection />

              <Grid item xs={12} mt={2}>
                <Modal.Actions>
                  <Modal.Action
                    type="secondary"
                    content="Cancel"
                    onAction={closeModal}
                    disabled={isSubmitDisabled}
                  />

                  <Modal.Action
                    type="primary"
                    content="Edit"
                    onAction={handleSubmit}
                    disabled={isSubmitDisabled}
                    loading={isSubmitLoading}
                  />
                </Modal.Actions>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export { ExperienceStoreAssetUpdateForm };
