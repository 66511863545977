import { concatDateWithTime } from '@vizsla/utils';
import { RegistrationOptionCreateInput } from '@vizsla/graphql';

import { getNewPricingSettingsData } from 'src/utils/pricingSettings';

export const prepareValues = (formValues: Record<string, any>) => {
  const {
    ageRequirmentEnabled,
    registrationLimitsEnabled,
    groupRegistrationEnabled,
    attendeesCapEnabled,
    fundraisingMinimumEnabled,
    checkpointEnabled,
    registrationAvailabilityEnabled,
    registrationStartDate,
    registrationStartTime,
    registrationEndDate,
    registrationEndTime,
    pricingSettings,
    ...restValues
  } = formValues;

  return {
    ...restValues,
    registrationStartDate: registrationAvailabilityEnabled
      ? concatDateWithTime(registrationStartDate, registrationStartTime)
      : null,
    registrationEndDate: registrationAvailabilityEnabled
      ? concatDateWithTime(registrationEndDate, registrationEndTime)
      : null,
    pricingSettings: getNewPricingSettingsData(pricingSettings),
  } as RegistrationOptionCreateInput;
};
