import React from 'react';
import { Grid, styled } from '@mui/material';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';

import { useNotification, usePaginationQueryVariables } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { Modal } from '@vizsla/components';

import { useStoreHistory, useStoreAssetId } from 'src/hooks/storeAssets';
import type { StoreAssetInventoryAddOrAdjustFormValues } from 'src/types/storeAssets';
import { StoreHistoryEventType } from 'src/types/storeAssets';
import { t } from 'src/utils/template';
import { StoreAsseInventoryAddOrAdjustFormSchema } from 'src/constants/validationSchemas/storeAssets';

import { getStoreHistoryEventCreateDataInput } from '../../utils';
import {
  FormCommonInfoSection,
  FormAttributesSection,
  FormAdditionalInfoSection,
} from './sections';

const StyledFormNode = styled('form')(() => ({
  display: 'contents',
}));

interface StoreAssetUpdateImageFormProps {
  closeModal: () => void;
}

const StoreAssetInventoryAdjustForm: React.FC<StoreAssetUpdateImageFormProps> = ({
  closeModal,
}) => {
  const notification = useNotification();

  const storeAssetId = useStoreAssetId();
  const paginationQueryVariables = usePaginationQueryVariables();

  const { createStoreHistoryEvent, creating, refetchHistory } = useStoreHistory(
    storeAssetId,
    paginationQueryVariables,
  );

  const onSubmit = React.useCallback(
    async (formValues: StoreAssetInventoryAddOrAdjustFormValues, form: FormApi<any>) => {
      try {
        const inputObject = getStoreHistoryEventCreateDataInput(
          formValues,
          storeAssetId,
          StoreHistoryEventType.Adjust,
        );

        await createStoreHistoryEvent(inputObject);
        refetchHistory();
        notification.success(t('store_asset_update_success'));

        closeModal();
      } catch (err) {
        notification.error(t('store_asset_update_error'));
        console.error(err);
      }
    },
    [storeAssetId, createStoreHistoryEvent, notification, closeModal],
  );

  const validate = React.useCallback(
    async (formValues: StoreAssetInventoryAddOrAdjustFormValues) => {
      return validateWithSchema(StoreAsseInventoryAddOrAdjustFormSchema, formValues);
    },
    [],
  );

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting, pristine, validating, invalid, errors }) => {
        const isSubmitLoading = submitting || validating || creating;
        const isSubmitDisabled = isSubmitLoading || pristine || invalid;

        return (
          <StyledFormNode onSubmit={handleSubmit}>
            <Grid container spacing={2.5}>
              <FormCommonInfoSection />

              <FormAttributesSection />

              <FormAdditionalInfoSection />

              <Grid item xs={12}>
                <Modal.Actions>
                  <Modal.Action
                    type="secondary"
                    content="Cancel"
                    onAction={closeModal}
                    disabled={isSubmitDisabled}
                  />

                  <Modal.Action
                    type="primary"
                    content="Add"
                    onAction={handleSubmit}
                    disabled={isSubmitDisabled}
                    loading={isSubmitLoading}
                  />
                </Modal.Actions>
              </Grid>
            </Grid>
          </StyledFormNode>
        );
      }}
    />
  );
};

export { StoreAssetInventoryAdjustForm };
