import React from 'react';
import {
  Grid,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
} from '@mui/material';
import { Form, FormRenderProps } from 'react-final-form';
import { makeStyles } from '@mui/styles';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardNumberElement } from '@stripe/stripe-js';
import { FormApi } from 'final-form';

import { useNotification, FormStep, useFormStepper, useCurrentOrganization } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { AddDonationInput, AddDonationPaymentInfo, useAddDonationMutation } from '@vizsla/graphql';
import { useStripeCardElementsState, useCreatePaymentIntentForItem } from '@vizsla/stripe';
import { DonationType, DonorType } from '@vizsla/types';
import { Button } from '@vizsla/components';

import {
  OfflineDonationCampaignInfoSchema,
  OfflineDonationDonorInfoSchema,
  OfflineDonationPaymentInfo,
} from 'src/constants/validationSchemas/offlineDonation';
import { t } from 'src/utils/template';
import { PaymentMethod } from 'src/types/offlineDonation';

import { AddOfflineDonationCampaignInfoStep } from './AddOfflineDonationCampaignInfoStep';
import { AddOfflineDonationDonorInfoStep } from './AddOfflineDonationDonorInfoStep';
import { AddOfflineDonationPaymentStep } from './AddOfflineDonationPaymentStep';

const OfflineDonationConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 16,
    left: 'calc(-50% + 24px)',
    right: 'calc(50% + 24px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#1B3C84',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#1B3C84',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderStyle: 'dashed',
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderWidth: 1,
    borderRadius: 1,
  },
}));

const useStyles = makeStyles(() => ({
  circleIcon: {
    '& .MuiStepLabel-iconContainer': {
      width: 32,
      height: 32,
    },
    '& .MuiStepIcon-root': {
      width: 32,
      height: 32,
    },
    '& .Mui-completed': {
      color: '#08BE94',
    },
  },
}));

const CAMPAIGN_INFO_INDEX = 0;
const DONOR_INFO_INDEX = 1;
const PAYMENT_INDEX = 2;

interface AddOfflineDonationFormProps {
  closeModal: () => void;
}

export const AddOfflineDonationForm: React.FC<AddOfflineDonationFormProps> = ({ closeModal }) => {
  const { securedFields, onChangeSecuredField } = useStripeCardElementsState();

  const FORM_STEPS: Array<FormStep> = React.useMemo(
    () => [
      {
        render: () => <AddOfflineDonationCampaignInfoStep />,
        validate: async (formValues: any) => {
          const context = {
            campaignId: formValues.campaignInfo?.campaign,
            teamCampaignId: formValues.campaignInfo?.fundraisingTeam?.campaign?.id,
            fundraiserCampaignId: formValues.campaignInfo?.fundraiser?.campaign?.id,
          };

          const formErrors = await validateWithSchema(
            OfflineDonationCampaignInfoSchema,
            formValues,
            {
              context,
            },
          );

          return formErrors;
        },
      },
      {
        render: () => <AddOfflineDonationDonorInfoStep />,
        validate: async (formValues: any) => {
          const formErrors = await validateWithSchema(OfflineDonationDonorInfoSchema, formValues);
          return formErrors;
        },
      },
      {
        render: () => <AddOfflineDonationPaymentStep onChangeSecuredField={onChangeSecuredField} />,
        validate: async (formValues: any) => {
          const context = {
            securedFields,
            paymentMethod: formValues.paymentInfo?.method,
          };
          const formErrors = await validateWithSchema(OfflineDonationPaymentInfo, formValues, {
            context,
          });
          return formErrors;
        },
      },
    ],
    [securedFields, onChangeSecuredField],
  );

  const classes = useStyles();

  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({ [CAMPAIGN_INFO_INDEX]: false, [DONOR_INFO_INDEX]: false, [PAYMENT_INDEX]: false });

  const { prev, next, render, step, validate, isLast, isFirst, setStep } =
    useFormStepper(FORM_STEPS);

  const notification = useNotification();

  const [addDonation, { loading: creatingDonation }] = useAddDonationMutation();

  const stripe = useStripe();
  const elements = useElements();

  const { organization, organizationId } = useCurrentOrganization();

  const { createPaymentIntentForItem, loading: creatingPaymentIntent } =
    useCreatePaymentIntentForItem(String(organization?.stripeIntegration?.stripeAccountID));

  React.useEffect(() => {
    return () => {
      setCompleted({
        [CAMPAIGN_INFO_INDEX]: false,
        [DONOR_INFO_INDEX]: false,
        [PAYMENT_INDEX]: false,
      });
      setStep(0);
    };
  }, [setStep]);

  const confirmCardPayment = async (
    amount: number,
    donorInfo: any,
    billingAddress: any,
    meta = {},
  ): Promise<{ stripeId: string; status: string }> => {
    const { paymentIntent: newIntent } = await createPaymentIntentForItem(
      amount,
      meta,
      donorInfo?.sendReceipt ? donorInfo?.donorEmail : undefined,
    );

    if (!stripe || !elements || !newIntent) {
      throw new Error('Cannot load Stripe');
    }

    const secret = newIntent?.client_secret;

    const card = elements.getElement(CardNumberElement) as StripeCardNumberElement;

    const { paymentIntent, error } = await stripe.confirmCardPayment(secret, {
      payment_method: {
        card,
        billing_details: {
          name: `${donorInfo?.donorFirstName} ${donorInfo?.donorLastName}`,
          email: donorInfo?.sendReceipt ? donorInfo?.donorEmail : undefined,
          phone: `+1${donorInfo.donorPhone.number}`,
          address: {
            country: 'US',
            city: billingAddress?.city,
            line1: billingAddress?.street1,
            postal_code: billingAddress?.zip,
            state: billingAddress?.state,
          },
        },
      },
    });

    const resultStatus = paymentIntent?.status;

    if (error) {
      throw new Error(`Cannot confirm card payment: ${error}`);
    }

    if (resultStatus === 'succeeded') {
      return { stripeId: paymentIntent?.id || '', status: resultStatus };
    }

    throw new Error('Something went wrong');
  };

  const handleSubmit = async (data: any, form: FormApi) => {
    setCompleted({ ...completed, [PAYMENT_INDEX]: true });

    const { campaignInfo, donorInfo, paymentInfo } = data;
    const { method, amount, billingAddress } = paymentInfo;

    const input = {
      amount: paymentInfo.amount,
      allocationInfo: {
        vizslaOrganizationID: String(organizationId),
        campaignId: String(campaignInfo.campaign),
      },
      donorType: donorInfo.donorType,
      donationType: DonationType.oneTime,
      fundraiserInfo: {
        fundraiserAttendeeId: campaignInfo.fundraiser?.id,
        fundraiserTeamId: campaignInfo.fundraisingTeam?.id,
      },
    } as AddDonationInput;

    if (donorInfo.donorType === DonorType.individual) {
      input.donorIndividualInfo = {
        firstName: donorInfo.donorFirstName,
        lastName: donorInfo.donorLastName,
        email: donorInfo.donorEmail,
        phone: {
          code: '+1',
          number: String(donorInfo.donorPhone.number),
        },
      };
    } else if (donorInfo.donorType === DonorType.organization) {
      input.donorPartnershipOrganizationInfo = {
        partnershipOrganizationId: String(donorInfo.donorPartnershipOrganization?.id),
      };
    }

    try {
      let paymentInfoInput = {} as AddDonationPaymentInfo;

      switch (method) {
        case PaymentMethod.card: {
          const { stripeId } = await confirmCardPayment(amount, donorInfo, billingAddress, {});

          paymentInfoInput = {
            method: PaymentMethod.card,
            sendReceipt: donorInfo?.sendReceipt ? donorInfo?.donorEmail : undefined,
            stripeId,
          };

          break;
        }
        case PaymentMethod.cash: {
          paymentInfoInput = {
            method: PaymentMethod.cash,
            date: paymentInfo.date,
            sendReceipt: donorInfo?.sendReceipt ? donorInfo?.donorEmail : undefined,
          };
          break;
        }
        case PaymentMethod.check: {
          paymentInfoInput = {
            method: PaymentMethod.check,
            date: paymentInfo.date,
            bank: paymentInfo.bank,
            from: paymentInfo.from,
            number: paymentInfo.number,
            sendReceipt: donorInfo?.sendReceipt ? donorInfo?.donorEmail : undefined,
          };
          break;
        }
        default:
          break;
      }

      await addDonation({
        variables: {
          input,
          paymentInfo: paymentInfoInput,
        },
      });

      form.reset();
      closeModal();
      notification.success(t('donation_success'));
    } catch (error) {
      notification.error(t('donation_error'));
    }
  };

  const handleCompleteStep = () => {
    setCompleted({ ...completed, [step]: true });
  };

  const handleResetStep = () => {
    setCompleted({ ...completed, [step]: false });
  };

  const handleContinue = () => {
    next();
    handleCompleteStep();
  };

  const handleBack = () => {
    prev();
    handleResetStep();
  };

  const steps = ['Campaign Info', 'Donor Info', 'Payment'];

  return (
    <Grid container spacing={4} p={6}>
      <Grid item xs={12}>
        <Stepper activeStep={step} alternativeLabel connector={<OfflineDonationConnector />}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepLabel className={classes.circleIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={12}>
        <Form onSubmit={handleSubmit} validate={validate} validateOnBlur>
          {({
            form,
            handleSubmit,
            hasValidationErrors,
            submitting,
            validating,
          }: FormRenderProps) => {
            const isLoading = creatingDonation || creatingPaymentIntent || submitting;

            return (
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>{render()}</Grid>
                  <Grid item>
                    <Grid container justifyContent="center" spacing={2}>
                      <Grid item>
                        <Button color="info" onClick={handleBack} disabled={isFirst || isLoading}>
                          Back
                        </Button>
                      </Grid>
                      {isLast && (
                        <Grid item>
                          <Button type="submit" disabled={hasValidationErrors} loading={isLoading}>
                            Add Donation
                          </Button>
                        </Grid>
                      )}
                      {!isLast && (
                        <Grid item>
                          <Button
                            type="button"
                            onClick={handleContinue}
                            disabled={hasValidationErrors || isLoading || validating}
                            loading={validating}
                          >
                            Continue
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </Grid>
    </Grid>
  );
};
