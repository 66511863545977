import React from 'react';
import _ from 'lodash';

import { useUserTeamsInfoQuery } from '@vizsla/graphql';

import { ContactUserIdContext } from '../../../../../providers/peopleCRM';

export const useContactTeams = () => {
  const { userId } = React.useContext(ContactUserIdContext);
  const [search, setSearch] = React.useState('');

  const { data, loading } = useUserTeamsInfoQuery({ variables: { userId, search } });
  const setDebouncedSearch = React.useCallback(_.debounce(setSearch, 300), [search, setSearch]);

  const avatarNameData = React.useMemo(() => {
    return data?.teamFundraisingsList?.items?.map(item => ({
      ...item,
      nameAvatar: { name: item.name, avatar: item.avatar },
    }));
  }, [data?.teamFundraisingsList?.items]);

  return { data: avatarNameData || [], loading, setSearch: setDebouncedSearch };
};
