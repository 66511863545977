import React from 'react';

import { MODALS } from '@vizsla/constants';
import {
  PartnershipCommitmentCreateInput,
  useCreatePartnershipCommitmentMutation,
} from '@vizsla/graphql';
import { useModal, useNotification } from '@vizsla/hooks';

import {
  CashCommitmentValuesT,
  CommitmentTypesE,
  InKindCommitmentValuesT,
  MatchingCommitmentValuesT,
  PartnershipCommitmentDBRowT,
  PartnershipCommitmentT,
  SubmitCommitmentValuesT,
} from '../../types';
import { AGREEMENT_FILE, CashCommitmentStatusE } from '../../constants';

export const useCommitmentsCreate = () => {
  const [loading, setLoading] = React.useState(false);
  const [createCommitment] = useCreatePartnershipCommitmentMutation();
  const { closeModal: close, args } = useModal(MODALS.PARTNERSHIP_COMMITMENT_CREATE);
  const {
    commitment,
    partnershipId,
  }: { commitment?: PartnershipCommitmentDBRowT; partnershipId?: string } = args ?? {};
  const notifications = useNotification();

  const onSubmit = React.useCallback(
    async (form: PartnershipCommitmentT) => {
      const file = form[AGREEMENT_FILE];
      const filePayload: PartnershipCommitmentCreateInput['agreement'] = file?.fileId
        ? {
            create: {
              fileId: file?.fileId,
              filename: file?.filename,
              meta: file?.meta,
              mods: file?.mods,
            },
          }
        : null;
      let payload: SubmitCommitmentValuesT;

      const startTime = form?.startTime?.split('T')?.at(1);
      const startDate = startTime ? form?.startDate?.split('T')?.at(0) : form?.startDate;
      const finalStartDate = startDate ? `${startDate}${startTime ? `T${startTime}` : ''}` : null;

      const endTime = form?.endTime?.split('T')?.at(1);
      const endDate = endTime ? form?.endDate?.split('T')?.at(0) : form?.endDate;

      const finalEndDate = endDate ? `${endDate}${endTime ? `T${endTime}` : ''}` : null;

      switch (form.type) {
        case CommitmentTypesE.cash:
          payload = {
            type: form?.type,
            amount: form.amount,
            description: form?.description,
            campaignId: form?.campaign,
            experienceId: form?.experience,
            notes: form?.notes,
            partnershipOrganizationId: partnershipId,
            decisionDate: form?.decisionDate?.split('T')?.at(0),
            agreement: filePayload,
            status: CashCommitmentStatusE.pending,
          } as CashCommitmentValuesT;

          break;
        case CommitmentTypesE.matching:
          payload = {
            type: form?.type,
            matchLimit: form.matchLimit,
            amount: form.amount,
            description: form?.description,
            campaignId: form?.campaign,
            experienceId: form?.experience,
            startDate: finalStartDate,
            endDate: finalEndDate,
            notes: form?.notes,
            partnershipOrganizationId: partnershipId,
            agreement: filePayload,
          } as MatchingCommitmentValuesT;

          break;
        default:
        case CommitmentTypesE.inKind:
          payload = {
            type: form?.type,
            amount: form.amount,
            description: form?.description,
            campaignId: form?.campaign,
            experienceId: form?.experience,
            notes: form?.notes,
            partnershipOrganizationId: partnershipId,
            serviceProvided: form?.serviceProvided,
            agreement: filePayload,
          } as InKindCommitmentValuesT;
          break;
      }
      setLoading(true);
      try {
        await createCommitment({
          variables: {
            data: payload,
          },
          refetchQueries: [
            'ListPartnershipCommitments',
            'OrganizationPartnershipsDashboardData',
            'PartnershipDashboardData',
          ],
        });
        notifications.success('Commitment created successfully');
      } catch (error) {
        notifications.error(`There was an error while creating a commitment: ${error.message}`);
        close();
      }
      setLoading(false);
      close();
    },
    [createCommitment, notifications, close, partnershipId],
  );

  return { onSubmit, loading };
};
