import React from 'react';
import { Navigate } from 'react-router-dom';

import * as appRoutes from 'src/constants/routes';

const OrganizationSettingsPageIndex: React.FC = () => {
  return <Navigate to={appRoutes.organizationSettingsAccountDetails} />;
};

export { OrganizationSettingsPageIndex };
