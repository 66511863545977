import React from 'react';
import { Grid } from '@mui/material';

import { Field, NumberField } from '@vizsla/components';

import { StoreAsseInventoryFormFields } from 'src/types/storeAssets';

const FormCommonInfoSection: React.FC = () => {
  return (
    <Grid item xs={12}>
      <Field
        fullWidth
        name={StoreAsseInventoryFormFields.QUANTITY}
        label="Quantity"
        required
        component={NumberField}
      />
    </Grid>
  );
};

export { FormCommonInfoSection };
