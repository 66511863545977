import React from 'react';
import { useUsersListQuery } from '@vizsla/graphql';
import { useInboxFilters } from '@vizsla/providers/dist/InboxProvider/hooks';
import { useCurrentUser } from '../user';
const buildMyNetworkUsersFilter = ({ search, userFilter }) => {
    const filters = [mapSearchToFilter(search), { is_self: false }, userFilter].filter(Boolean);
    return { AND: filters };
};
const mapSearchToFilter = (search) => {
    const hasSearch = search && search.length > 0;
    if (!hasSearch)
        return null;
    return { OR: [{ firstName: { contains: search } }, { lastName: { contains: search } }] };
};
export function useNetworkUsersQuery(search) {
    const { userFilter } = useInboxFilters();
    const { user } = useCurrentUser();
    const { data, loading, error } = useUsersListQuery({
        skip: !user,
        variables: {
            filter: buildMyNetworkUsersFilter({
                userFilter,
                search,
            }),
        },
        fetchPolicy: 'cache-first',
    });
    const users = React.useMemo(() => { var _a, _b; return (_b = (_a = data === null || data === void 0 ? void 0 : data.usersList) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : []; }, [data]);
    return { users, loading, error };
}
