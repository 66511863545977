import React from 'react';
import { Grid, Typography } from '@mui/material';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';

import { Field, CheckboxField, RadioGroupField, TimeField, DateField } from '@vizsla/components';
import { DateFormatPatterns } from '@vizsla/constants';
import { getNowDate, toDateTimeFromISO } from '@vizsla/utils';

import { DateType, DATE_RANGE_TYPE_OPTIONS } from 'src/constants/campaign';
import { getExperienceMaxEndDate } from 'src/utils/experience';

import { ExperienceGeneralSettingsFormValues } from './CampaignExperienceGeneralSettings';

interface WhenBlockProps {
  values: ExperienceGeneralSettingsFormValues;
  spacing: number;
}

export const WhenBlock: React.FC<WhenBlockProps> = ({ values, spacing }) => {
  const currentDate = toDateTimeFromISO(getNowDate());

  return (
    <Grid container item direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h6">When</Typography>
      </Grid>
      <Grid item>
        <Field
          name="dateRangeType"
          component={RadioGroupField}
          defaultValue={DateType.Single}
          row
          options={DATE_RANGE_TYPE_OPTIONS}
        />
      </Grid>
      <Grid container item spacing={spacing}>
        <Grid container item spacing={spacing}>
          <Grid item>
            <Field
              name="startDate"
              label="Start Date *"
              component={DateField}
              minDate={currentDate}
              inputFormat={DateFormatPatterns.shortMonthDayYear}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Field name="startTime" label="Start Time *" component={TimeField} />
          </Grid>
        </Grid>
        {values?.dateRangeType === DateType.Range && (
          <Grid container item spacing={spacing}>
            <Grid item>
              <FormSpy subscription={{ values: true }}>
                {(spyRenderProps: FormSpyRenderProps) => {
                  const { startDate } = spyRenderProps.values;
                  const minEndDate = toDateTimeFromISO(startDate || currentDate);

                  return (
                    <Field
                      label="End Date"
                      name="endDate"
                      component={DateField}
                      inputFormat={DateFormatPatterns.shortMonthDayYear}
                      minDate={minEndDate}
                      fullWidth
                      required
                    />
                  );
                }}
              </FormSpy>
            </Grid>
            <Grid item>
              <Field name="endTime" label="End Time" component={TimeField} />
            </Grid>
            <Grid item display="flex" justifyContent="center">
              <Field
                name="hideFromPublic"
                label="Hide End Date from Public"
                component={CheckboxField}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
