import styled from '@emotion/styled';
import { Avatar, Grid, Typography, IconButton, Button } from '@mui/material';
import { style, typography } from '@mui/system';

export const TeammareCard = styled(Grid)`
  position: relative;
  box-sizing: border-box;
  height: 140px;
  width: 316px;
  border: 1px solid #d9dcde;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 8px;
  &:hover {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const UserAvatar = styled(Avatar)`
  height: 48px;
  width: 48px;
`;

export const BigUserAvatar = styled(Avatar)`
  height: 80px;
  width: 80px;
  margin-top: 34px;
`;

export const AvatarImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const HeaderCard = styled(Grid)`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  margin-left: 24px;
  height: 62px;
`;

export const TextContainer = styled(Grid)`
  margin-left: 13px;
  display: flex;
  flex-direction: column;
`;

export const GoalContainer = styled(Grid)`
  margin-left: 24px;
  display: flex;
  flex-direction: column;
`;

export const Text = styled(Typography)`
  &:hover {
    cursor: 'default';
  }
`;

export const TextLigth = styled(Typography)`
  height: 20px;
  color: #676767;
  font-family: Inter;
  font-size: 10px;
  letter-spacing: -0.17px;
  line-height: 20px;
  &:hover {
    cursor: 'default';
  }
`;

export const TextAlerts = styled(Typography)``;

TextAlerts.defaultProps = {
  variant: 'caption',
};
export const Icon = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  margin-top: 20px;
  margin-right: 16px;
`;

export const TeammatesPopoverCard = styled(Grid)`
  height: 452px;
  min-width: 287.43px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerTextInformationPopoverCard = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e7e9eb;
  width: 80%;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const MessageButton = styled(Button)`
  margin-top: 17px;
  height: 36px;
  width: 229px;
  border-radius: 8px;
  background-color: #d9f5f9;
  margin-bottom: 20px;
`;

export const CaptainContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  height: 20px;
  width: 190px;
  border-radius: 12px;
  background-color: #2a394a;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
`;
