import { INITIAL_PRICING_TIER } from 'src/constants/pricingSettings';

export enum PricingType {
  Fixed = 'Fixed',
  Scaled = 'Scaled',
}

export enum TaxDeductibleType {
  Percents = 'Percents',
  Currency = 'Currency',
}

export enum PricingTierFormAction {
  toDelete = 'toDelete',
  toCreate = 'toCreate',
  toUpdate = 'toUpdate',
}

// TODO finish typing
export interface PricingTierFormValue {
  id?: string;
}

export type PricingTierFieldValue = typeof INITIAL_PRICING_TIER;
