import React from 'react';
import { InputAdornment, TextField, Theme } from '@mui/material';
import { Search } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { BorderRadius, Shade } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  searchInput: {
    '& .MuiOutlinedInput-root': {
      background: Shade.Gray[25],
      borderRadius: BorderRadius.M,
      '&.Mui-focused': {
        background: 'transparent',
      },
      '& fieldset': {
        '&.MuiOutlinedInput-notchedOutline': {
          outline: 'none',
          borderColor: 'transparent',
        },
      },
      '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: '#C0C4C9',
      },
      '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: '#C0C4C9',
      },
    },
  },
}));

interface SearchInputProps {
  onChange: (value?: string) => void;
  value?: string;
}

export const SearchInput: React.FC<SearchInputProps> = ({ value, onChange }) => {
  const classes = useStyles();
  return (
    <TextField
      variant="outlined"
      value={value}
      onChange={e => onChange(e?.target?.value)}
      size="small"
      fullWidth
      className={classes.searchInput}
      InputProps={{
        placeholder: 'Search',
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
};
