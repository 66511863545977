import React from 'react';
import _ from 'lodash';
import { useApolloClient } from '@apollo/client';

import {
  Experience,
  ExperienceUpdateInput,
  ExperienceBibPool,
  ExperienceBibPoolCreateInput,
  ExperienceBibPoolUpdateInput,
  ExperienceBibAssignment,
  ExperienceBibAssignmentCreateInput,
  ExperienceBibAssignmentCreateManyInput,
  useExperienceBibPoolDeleteMutation,
  useExperienceBibPoolCreateMutation,
  useExperienceBibPoolUpdateMutation,
  useExperienceBibAssignmentCreateManyMutation,
  useExperienceBibAssignmentCreateMutation,
  useExperienceBibAssignmentDeleteManyMutation,
  useExperienceBibAssignmentDeleteMutation,
  useExperienceBibAssignmentSettingsQuery,
  useExperienceBibAssignmentSettingsUpdateMutation,
  useExperienceBibExcludeNumberMutation,
  useExperienceBibAssignIndividualNumberMutation,
  useExperienceBibAssignBatchMutation,
} from '@vizsla/graphql';

import {
  BibAssignmentType,
  BibAutoAssignDates,
  BibBatchAssignSort,
  BibSortOrder,
} from 'src/types/bibAssignment';
import { EXPERIENCE_BIB_ASSIGNMENT_SETTINGS_QUERY } from 'src/graphql/experienceBibAssignment';

enum BibAssignmentTypename {
  Experience = 'Experience',
  BibPools = 'ExperienceBibPoolListResponse',
  BibAssignments = 'ExperienceBibAssignmentListResponse',
}

interface UpdateBibPoolInput {
  validatedData: ExperienceBibPoolUpdateInput;
  bibPoolId: string;
  prevRegistrationOptionId: string | null;
}

interface UseExperienceBibAssignmentOutput {
  loading: boolean;
  bibPoolsList: ExperienceBibPool[];
  isEmptyBibPoolsList: boolean;

  allowIndividualBibAssignment: boolean;
  allowExcludedBibNumbers: boolean;

  bibAutoAssignEnabled: boolean;
  bibAutoAssignDates: BibAutoAssignDates;

  bibAssignmentsList: ExperienceBibAssignment[];
  poolBibAssignments: ExperienceBibAssignment[];
  individualBibAssignments: ExperienceBibAssignment[];
  excludedBibAssignments: ExperienceBibAssignment[];

  updateBibSettings: (data: ExperienceUpdateInput) => Promise<void>;
  updatingBibSettings: boolean;

  createBibPool: (validatedData: ExperienceBibPoolCreateInput) => Promise<void>;
  creatingPool: boolean;

  updateBibPool: (data: UpdateBibPoolInput) => Promise<void>;
  updatingPool: boolean;

  deleteBibPool: (bibPoolId: string) => Promise<void>;
  deletingPool: boolean;

  excludeAssignment: (bibNumber: number) => Promise<void>;
  excludingAssignment: boolean;

  createIndividualAssignment: (attendeeId: string, bibNumber: number) => Promise<void>;
  creatingIndividualAssignment: boolean;

  createAssignment: (data: ExperienceBibAssignmentCreateInput) => Promise<void>;
  creatingAssignment: boolean;

  createBatchAssignments: (sortBy: BibBatchAssignSort, order: BibSortOrder) => Promise<void>;
  creatingBatchAssignments: boolean;

  createMultipleAssignments: (data: ExperienceBibAssignmentCreateManyInput) => Promise<void>;
  creatingMultipleAssignments: boolean;

  deleteAssignment: (id: string) => Promise<void>;
  deletingAssignment: boolean;

  deleteAssignmentsByType: (types: BibAssignmentType[]) => Promise<void>;
  deletingMultipleAssignments: boolean;
}

export const useExperienceBibAssignment = (
  experienceId: string,
): UseExperienceBibAssignmentOutput => {
  const apolloClient = useApolloClient();

  // Settings Query

  const { data, loading } = useExperienceBibAssignmentSettingsQuery({
    variables: {
      id: experienceId,
    },
    skip: !experienceId,
  });

  const bibAssignmentSettings = data?.experience;

  const allowIndividualBibAssignment = Boolean(bibAssignmentSettings?.allowIndividualBibAssignment);
  const allowExcludedBibNumbers = Boolean(bibAssignmentSettings?.allowExcludedBibNumbers);

  const bibAutoAssignEnabled = Boolean(bibAssignmentSettings?.bibAutoAssignEnabled);
  const bibAutoAssignDates = {
    start: bibAssignmentSettings?.bibAutoAssignStartDate,
    end: bibAssignmentSettings?.bibAutoAssignEndDate,
  };

  const bibAssignmentsList = React.useMemo(
    () => bibAssignmentSettings?.bibAssignments?.items ?? [],
    [bibAssignmentSettings],
  );

  const bibPoolsList = React.useMemo(
    () => (bibAssignmentSettings?.bibPools?.items as Array<ExperienceBibPool>) ?? [],
    [bibAssignmentSettings],
  );
  const isEmptyBibPoolsList = _.isEmpty(bibPoolsList);

  // Caching

  const getBibAssignmentSettingsFromCache = React.useCallback((): Experience => {
    const data = apolloClient.readQuery({
      query: EXPERIENCE_BIB_ASSIGNMENT_SETTINGS_QUERY,
      variables: {
        id: experienceId,
      },
    });

    return data?.experience as Experience;
  }, [apolloClient, experienceId]);

  const setBibAssignmentSettingsInCache = React.useCallback(
    (newSettings: Experience): void => {
      apolloClient.writeQuery({
        query: EXPERIENCE_BIB_ASSIGNMENT_SETTINGS_QUERY,
        variables: {
          id: experienceId,
        },
        data: {
          experience: {
            ...newSettings,
            __typename: BibAssignmentTypename.Experience,
          },
        },
      });
    },
    [apolloClient, experienceId],
  );

  // Settings Update

  const [bibAssignmentSettingsUpdateMutation, { loading: updatingBibSettings }] =
    useExperienceBibAssignmentSettingsUpdateMutation();

  const updateBibSettings = async (data: ExperienceUpdateInput) => {
    const res = await bibAssignmentSettingsUpdateMutation({
      variables: {
        data,
        filter: {
          id: experienceId,
        },
      },
    });

    const updatedSettings = res?.data?.experienceUpdate;
    setBibAssignmentSettingsInCache(updatedSettings as Experience);
  };

  // Bib Pools Management

  const [bibPoolDeleteMutation, { loading: deletingPool }] = useExperienceBibPoolDeleteMutation();
  const [bibPoolCreateMutation, { loading: creatingPool }] = useExperienceBibPoolCreateMutation();
  const [bibPoolUpdateMutation, { loading: updatingPool }] = useExperienceBibPoolUpdateMutation();

  const createBibPool = async (validatedData: ExperienceBibPoolCreateInput) => {
    const res = await bibPoolCreateMutation({
      variables: {
        validatedData,
        additionalData: {
          experienceId,
          registrationOptionId: validatedData.registrationOption,
        },
      },
    });

    const newBibPool = res?.data?.bibPoolCreate?.experienceBibPoolCreate as ExperienceBibPool;
    const settingsFromCache = getBibAssignmentSettingsFromCache();
    const bibPoolsFromCache = settingsFromCache?.bibPools?.items ?? [];

    setBibAssignmentSettingsInCache({
      ...settingsFromCache,
      bibPools: {
        items: [...bibPoolsFromCache, newBibPool],
        __typename: BibAssignmentTypename.BibPools,
      },
    } as Experience);
  };

  const updateBibPool = async ({
    validatedData,
    bibPoolId,
    prevRegistrationOptionId,
  }: UpdateBibPoolInput) => {
    const res = await bibPoolUpdateMutation({
      variables: {
        validatedData,
        additionalData: {
          experienceId,
          prevRegistrationOptionId,
          currentBibPoolId: bibPoolId,
          prevBibPoolName: validatedData.name,
          registrationOptionId: validatedData.registrationOption,
        },
      },
    });

    const updatedBibPool = res?.data?.bibPoolUpdate?.experienceBibPoolUpdate as ExperienceBibPool;
    const settingsFromCache = getBibAssignmentSettingsFromCache();
    const bibPoolsFromCache = settingsFromCache?.bibPools?.items ?? [];

    const updatedBibPools = bibPoolsFromCache.map(pool =>
      pool?.id === updatedBibPool?.id ? updatedBibPool : pool,
    );

    setBibAssignmentSettingsInCache({
      ...settingsFromCache,
      bibPools: {
        items: updatedBibPools,
        __typename: BibAssignmentTypename.BibPools,
      },
    } as Experience);
  };

  const deleteBibPool = async (bibPoolId: string) => {
    await bibPoolDeleteMutation({
      variables: {
        filter: {
          id: bibPoolId,
        },
      },
    });

    const settingsFromCache = getBibAssignmentSettingsFromCache();
    const bibPoolsFromCache = settingsFromCache?.bibPools?.items ?? [];

    const updatedBibPools = bibPoolsFromCache.filter(pool => pool?.id !== bibPoolId);

    setBibAssignmentSettingsInCache({
      ...settingsFromCache,
      bibPools: {
        items: updatedBibPools,
        __typename: BibAssignmentTypename.BibPools,
      },
    } as Experience);
  };

  // Bib Assignments Management

  const poolBibAssignments = React.useMemo(
    () =>
      bibAssignmentsList?.filter(
        a => a?.type === BibAssignmentType.Pool || a?.type === BibAssignmentType.Reserve,
      ) ?? [],
    [bibAssignmentsList],
  );

  const individualBibAssignments = React.useMemo(
    () => bibAssignmentsList?.filter(a => a?.type === BibAssignmentType.Individual) ?? [],
    [bibAssignmentsList],
  );

  const excludedBibAssignments = React.useMemo(
    () => bibAssignmentsList?.filter(a => a?.type === BibAssignmentType.Excluded) ?? [],
    [bibAssignmentsList],
  );

  const [excludeBibNumberMutation, { loading: excludingAssignment }] =
    useExperienceBibExcludeNumberMutation();

  const [assignIndividualNumberMutation, { loading: creatingIndividualAssignment }] =
    useExperienceBibAssignIndividualNumberMutation();

  const [bibAssignBatchMutation, { loading: creatingBatchAssignments }] =
    useExperienceBibAssignBatchMutation({
      refetchQueries: ['ExperienceBibAssignmentSettings'],
      awaitRefetchQueries: true,
    });

  const [bibAssignmentCreateMutation, { loading: creatingAssignment }] =
    useExperienceBibAssignmentCreateMutation();

  const [bibAssignmentCreateManyMutation, { loading: creatingMultipleAssignments }] =
    useExperienceBibAssignmentCreateManyMutation();

  const [bibAssignmentDeleteMutation, { loading: deletingAssignment }] =
    useExperienceBibAssignmentDeleteMutation();

  const [bibAssignmentDeleteManyMutation, { loading: deletingMultipleAssignments }] =
    useExperienceBibAssignmentDeleteManyMutation();

  const createAssignment = async (data: ExperienceBibAssignmentCreateInput) => {
    const res = await bibAssignmentCreateMutation({
      variables: {
        data: {
          experience: {
            connect: {
              id: experienceId,
            },
          },
          ...data,
        },
      },
    });

    const settingsFromCache = getBibAssignmentSettingsFromCache();

    const existingAssignments = settingsFromCache?.bibAssignments?.items ?? [];
    const newAssignment = res?.data?.experienceBibAssignmentCreate;

    setBibAssignmentSettingsInCache({
      ...settingsFromCache,
      bibAssignments: {
        items: [...existingAssignments, newAssignment],
        __typename: BibAssignmentTypename.BibAssignments,
      },
    } as Experience);
  };

  const excludeAssignment = async (bibNumber: number) => {
    const res = await excludeBibNumberMutation({
      variables: {
        experienceId,
        bibNumber,
      },
    });

    const settingsFromCache = getBibAssignmentSettingsFromCache();

    const existingAssignments = settingsFromCache?.bibAssignments?.items ?? [];

    const overriddenAssignments = res?.data?.bibExcludeNumber?.deleted ?? [];
    const newAssignment = res?.data?.bibExcludeNumber?.bibAssignment;

    setBibAssignmentSettingsInCache({
      ...settingsFromCache,
      bibAssignments: {
        items: [...existingAssignments, newAssignment].filter(
          a => !overriddenAssignments.includes(a?.id as string),
        ),
        __typename: BibAssignmentTypename.BibAssignments,
      },
    } as Experience);
  };

  const createIndividualAssignment = async (attendeeId: string, bibNumber: number) => {
    const res = await assignIndividualNumberMutation({
      variables: {
        experienceId,
        attendeeId,
        bibNumber,
      },
    });

    const settingsFromCache = getBibAssignmentSettingsFromCache();

    const existingAssignments = settingsFromCache?.bibAssignments?.items ?? [];

    const overriddenAssignments = res?.data?.bibAssignIndividualNumber?.deleted ?? [];
    const newAssignment = res?.data?.bibAssignIndividualNumber?.bibAssignment;

    setBibAssignmentSettingsInCache({
      ...settingsFromCache,
      bibAssignments: {
        items: [...existingAssignments, newAssignment].filter(
          a => !overriddenAssignments.includes(a?.id as string),
        ),
        __typename: BibAssignmentTypename.BibAssignments,
      },
    } as Experience);
  };

  const createBatchAssignments = async (sortBy: BibBatchAssignSort, order: BibSortOrder) => {
    await bibAssignBatchMutation({
      variables: {
        experienceId,
        sortBy,
        order,
      },
    });
  };

  const createMultipleAssignments = async (data: ExperienceBibAssignmentCreateManyInput) => {
    const res = await bibAssignmentCreateManyMutation({
      variables: {
        data,
      },
    });

    const settingsFromCache = getBibAssignmentSettingsFromCache();

    const existingAssignments = settingsFromCache?.bibAssignments?.items ?? [];
    const newAssignments = res?.data?.experienceBibAssignmentCreateMany?.items ?? [];

    setBibAssignmentSettingsInCache({
      ...settingsFromCache,
      bibAssignments: {
        items: [...existingAssignments, ...newAssignments],
        __typename: BibAssignmentTypename.BibAssignments,
      },
    } as Experience);
  };

  const deleteAssignment = async (id: string) => {
    await bibAssignmentDeleteMutation({
      variables: {
        filter: {
          id,
        },
      },
    });

    const settingsFromCache = getBibAssignmentSettingsFromCache();

    const existingAssignments = settingsFromCache?.bibAssignments?.items ?? [];
    const updatedAssignments = existingAssignments.filter(assignment => assignment?.id !== id);

    setBibAssignmentSettingsInCache({
      ...settingsFromCache,
      bibAssignments: {
        items: updatedAssignments,
        __typename: BibAssignmentTypename.BibAssignments,
      },
    } as Experience);
  };

  const deleteAssignmentsByType = async (types: BibAssignmentType[]) => {
    await bibAssignmentDeleteManyMutation({
      variables: {
        filter: {
          type: {
            in: types,
          },
        },
      },
    });

    const settingsFromCache = getBibAssignmentSettingsFromCache();

    const existingAssignments = settingsFromCache?.bibAssignments?.items ?? [];
    const updatedAssignments = existingAssignments.filter(
      assignment => !types.includes(assignment?.type as BibAssignmentType),
    );

    setBibAssignmentSettingsInCache({
      ...settingsFromCache,
      bibAssignments: {
        items: updatedAssignments,
        __typename: BibAssignmentTypename.BibAssignments,
      },
    } as Experience);
  };

  return {
    loading,
    bibPoolsList,
    isEmptyBibPoolsList,

    allowIndividualBibAssignment,
    allowExcludedBibNumbers,

    bibAutoAssignEnabled,
    bibAutoAssignDates,

    bibAssignmentsList,
    poolBibAssignments,
    individualBibAssignments,
    excludedBibAssignments,

    updateBibSettings,
    updatingBibSettings,

    createBibPool,
    creatingPool,

    updateBibPool,
    updatingPool,

    deleteBibPool,
    deletingPool,

    createAssignment,
    creatingAssignment,

    createIndividualAssignment,
    creatingIndividualAssignment,

    excludeAssignment,
    excludingAssignment,

    createBatchAssignments,
    creatingBatchAssignments,

    createMultipleAssignments,
    creatingMultipleAssignments,

    deleteAssignment,
    deletingAssignment,

    deleteAssignmentsByType,
    deletingMultipleAssignments,
  };
};
