import React from 'react';
import { Typography, Select, InputLabel, FormControl, MenuItem } from '@mui/material';
import { useQuery } from '@apollo/client';

import { useModal } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';
import { ExperienceListQuery, Experience } from '@vizsla/graphql';

import { TicketingOptionCreateModal } from 'src/modals';
import { MODALS } from 'src/constants/modals';
import { useFullPublicWebLink } from 'src/hooks/publicPages';

import { EXPERIENCE_LIST_QUERY } from '../../graphql/experience';

export const SelectExperience = () => {
  const { isOpen, closeModal, args } = useModal(MODALS.SELECT_EXPERIENCE);
  const { openModal } = useModal();

  const { data, loading } = useQuery<ExperienceListQuery>(EXPERIENCE_LIST_QUERY, {
    variables: { filter: { campaign: { id: { equals: args?.campaignId } } } },
  });

  const [experienceId, setExperienceId] = React.useState<string>('');
  const [link, setLink] = React.useState<string>('');
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const { fullPublicWebLink } = useFullPublicWebLink(link);

  const handleSelect = (e: any) => {
    setExperienceId(e.target.value);
  };
  const experience = React.useMemo(() => {
    setLink(args?.dns);
    if (args?.section === 'Ticket') {
      return data?.experiencesList.items.filter(item => item.experienceType === 'Ticketing') ?? [];
    }
    return data?.experiencesList?.items ?? [];
  }, [data, loading, args?.section]);

  React.useEffect(() => {
    if (experienceId !== '') {
      setDisabled(false);
    }
  }, [experienceId]);
  const onClick = () => {
    if (args?.section === 'Ticket') {
      openModal(MODALS.TICKETING_OPTION_CREATE_MODAL, {
        experienceId,
      });
    }
    if (args?.section === 'Registration') {
      window.open(`${fullPublicWebLink}/experience/${experienceId}`);
    }
    if (args?.section === 'Pledge') {
      window.open(`${fullPublicWebLink}/experience/${experienceId}`);
    }
    setDisabled(true);
    closeModal();
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onClose={closeModal} fullWidth>
        <Modal.Title>
          <Typography textAlign="center" variant="h2">
            Select Experience
          </Typography>
        </Modal.Title>
        <Modal.Content>
          <FormControl fullWidth>
            <InputLabel id="select-experience">Select Experience</InputLabel>
            <Select labelId="select-experience" value={experienceId} onChange={handleSelect}>
              {experience.map(item => (
                <MenuItem key={item?.id} value={item?.id || 'none'}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <Modal.Actions>
              <Modal.Action type="secondary" content="Cancel" onAction={closeModal} />
              <Modal.Action
                type="primary"
                content="Continue"
                onAction={onClick}
                loading={loading}
                disabled={disabled}
              />
            </Modal.Actions>
          </FormControl>
        </Modal.Content>
      </Modal>
      <TicketingOptionCreateModal />
    </React.Fragment>
  );
};
