import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Theme } from '@mui/material';

import { useCurrentUser } from '@vizsla/hooks';
import { Button } from '@vizsla/components';

import { useCampaignPublicPages, useCampaignRegistration } from 'src/hooks/campaign';
import { CampaignCard } from 'src/components/campaign';
import { CampaignType } from 'src/types/campaign';
import { FontSize, PaletteColor } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(1),
  },
  subtitleContainer: {
    marginBottom: theme.spacing(4),
  },
  bottomTexContainer: {
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    fontSize: FontSize.M,
    color: PaletteColor.GrayText,
  },
  cardWrapper: {
    marginBottom: theme.spacing(4),
  },
}));

interface CampaignRegistrationFinalScreenProps {
  onManage: () => void;
}

export const CampaignRegistrationFinalScreen: React.FC<CampaignRegistrationFinalScreenProps> = ({
  onManage,
}) => {
  const classes = useStyles();
  const { fullName } = useCurrentUser();
  const { createdCampaign } = useCampaignRegistration();
  const id = createdCampaign?.id as string;

  const onConnectCampaign = async () => {
    try {
      connectTemplatesToCampaign();
    } catch (e) {
      console.error(e);
    }
  };

  const { connectTemplatesToCampaign, updating, hasCampaignTemplates, campaignPublicTemplates } =
    useCampaignPublicPages(id);

  React.useEffect(() => {
    if (!hasCampaignTemplates && !updating && id) {
      onConnectCampaign();
    }
  }, [hasCampaignTemplates, updating]);

  const name = createdCampaign?.name as string;
  const campaignType = createdCampaign?.campaignType as CampaignType;
  const imageUrl = createdCampaign?.image?.downloadUrl as string | undefined;
  const startDate = createdCampaign?.startDate as string;
  const endDate = createdCampaign?.endDate as string;
  const fundraisingGoal = createdCampaign?.fundraisingGoal as number;
  const experiencesCount = createdCampaign?.experiences?.count as number | undefined;
  const fundraiserGoal = createdCampaign?.fundraiserGoal as number | undefined;
  const attendeesGoal = createdCampaign?.attendanceFundraisersCountGoal as number | undefined;
  const teams = createdCampaign?.teamGoal as number | undefined;
  const totalRaised = createdCampaign?.totalRaised as number | undefined;
  const donorGoal = createdCampaign?.donorGoal as number | undefined;

  return (
    <Grid container flexDirection="column" alignItems="center">
      <Grid className={classes.container} item xs={12}>
        <Typography variant="h1" align="center">
          Great Job, {fullName}!
        </Typography>
      </Grid>
      <Grid className={classes.subtitleContainer} item xs={12}>
        <Typography className={classes.subtitle} variant="h6" align="center">
          Your campaign has been created.
        </Typography>
      </Grid>
      <Grid item className={classes.cardWrapper}>
        <CampaignCard
          name={name}
          attendeesGoal={attendeesGoal}
          campaignType={campaignType}
          startDate={startDate}
          endDate={endDate}
          teams={teams}
          imageUrl={imageUrl}
          experiencesCount={experiencesCount}
          fundraisingGoal={fundraisingGoal}
          fundraiserGoal={fundraiserGoal}
          totalRaised={totalRaised}
          donorGoal={donorGoal}
        />
      </Grid>
      <Grid className={classes.bottomTexContainer} item xs={12}>
        <Typography className={classes.subtitle} variant="body2" align="center">
          Now, let&apos;s go to your Command Center to build and beatify your campaign!
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={onManage}
          loading={updating}
        >
          Manage Campaign
        </Button>
      </Grid>
    </Grid>
  );
};
