import { Grid, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import { Form } from 'react-final-form';
import { FormApi } from 'final-form';
import { useMutation } from '@apollo/client';

import { Button, Field, NumberField, PaperBlock, TextField } from '@vizsla/components';
import { useCurrentUser, useModal, useNotification, useOrganizationById } from '@vizsla/hooks';
import { File, VizslaOrganizationUpdateInput } from '@vizsla/graphql';
import { validateWithSchema } from '@vizsla/utils';
import { MODALS } from '@vizsla/constants';

import { VizslaOrganizationUpdateSchema } from 'src/constants/validationSchemas/organization';
import { VIZSLA_ORGANIZATION_UPDATE_MUTATION } from 'src/graphql';
import { ImageSelector } from 'src/components/ImageSelector';
import { ConfirmationDeleteDialog } from 'src/components/shared';
import { SocialMediaModal } from 'src/modals';
import {
  useSelectedOrganizationLogo,
  useSelectedOrganizationNegativeLogo,
  useSelectedVizslaOrganization,
} from 'src/hooks/organization';

import {
  BrandingTitle,
  Container,
  MainLogo,
  NegativeLogo,
  Title,
  Wrapper,
  WrapperTitles,
} from './OrganizationSettingsAccountDetails.style';
import { LocationInfo } from './LocationInfo';
import { SocialMediaOrganization } from './SocialMediaOrganization';

export const OrganizationSettingsAccountDetails: React.FC = () => {
  const notification = useNotification();

  const [loading, setLoading] = React.useState(false);

  const { user } = useCurrentUser();

  const organizationId = user?.vizslaOrganizationStaff?.items[0].vizslaOrganization?.id ?? '';

  const { organization, organizationIsLoading, refetch } = useOrganizationById(organizationId);
  const { data, loading: fetching } = useSelectedVizslaOrganization();
  const { setData } = useSelectedVizslaOrganization();

  React.useEffect(() => setData(organization), [organization, setData]);

  const {
    uri: logoURI,
    fetching: fetchingLogo,
    updating: updatingLogo,
    attach: attachLogo,
    detach: detachLogo,
  } = useSelectedOrganizationLogo();

  const {
    uri: negativeLogoURI,
    fetching: fetchingNegativeLogo,
    updating: updatingNegativeLogo,
    attach: attachNegativeLogo,
    detach: detachNegativeLogo,
  } = useSelectedOrganizationNegativeLogo();

  const { openModal } = useModal();

  const handleChangeLogo = async (file?: File) => {
    if (file) {
      await attachLogo(file);
      return;
    }

    openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
      deleteFunction: () => detachLogo(),
      objectName: 'Organization Logo',
    });
  };

  const handleChangeNegativeLogo = async (file?: File) => {
    if (file) {
      await attachNegativeLogo(file);
      return;
    }

    openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
      deleteFunction: () => detachNegativeLogo(),
      objectName: 'Organization Negative Logo',
    });
  };

  const disabled = React.useMemo(() => fetching || !data, [data, fetching]);

  const INITIAL_VALUES = {
    ...organization,
  };

  const [vizslaOrganizationUpdate] = useMutation<VizslaOrganizationUpdateInput>(
    VIZSLA_ORGANIZATION_UPDATE_MUTATION,
    {
      refetchQueries: ['OrganizationsList', 'VizslaOrganizationQuery'],
      awaitRefetchQueries: true,
    },
  );
  React.useEffect(() => {
    refetch();
  }, [refetch]);

  if (organizationIsLoading) {
    return <LinearProgress color="secondary" />;
  }

  const onCancel = (form: FormApi) => {
    form.reset();
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      await vizslaOrganizationUpdate({
        variables: {
          data: {
            id: organizationId,
            name: data?.name,
            ein: String(data?.ein),
            contactEmail: data?.contactEmail,
            contactAddress: {
              country: data?.contactAddress?.country,
              city: data?.contactAddress?.city,
              state: data?.contactAddress?.state,
              zip: data?.contactAddress?.zip,
              street1: data?.contactAddress?.street1,
              street2: data?.contactAddress?.street2,
            },
            contactPhone: {
              code: data?.contactPhone?.code || '+1',
              number: String(data?.contactPhone?.number),
            },
          },
        },
      });
      notification.success('Organization update success');
      refetch();
      setLoading(false);
    } catch (e) {
      notification.error('Error updating organization');
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Title>Account Details</Title>
      <PaperBlock title="Organization Details" collapsing defaultOpened>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Form
              onSubmit={onSubmit}
              initialValues={INITIAL_VALUES}
              validate={values => validateWithSchema(VizslaOrganizationUpdateSchema, values)}
            >
              {({ form, handleSubmit, submitting, pristine, hasValidationErrors }) => {
                const isSubmitDisabled =
                  organizationIsLoading || submitting || pristine || hasValidationErrors;

                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">General</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              fullWidth
                              name="name"
                              label="Organization Name"
                              requierd
                              component={TextField}
                              disabled={loading}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              fullWidth
                              name="ein"
                              label="EIN Number"
                              requierd
                              formatMask="##-#######"
                              component={NumberField}
                              disabled={loading}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Contact Information</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              fullWidth
                              name="contactEmail"
                              requierd
                              label="Email"
                              component={TextField}
                              disabled={loading}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              fullWidth
                              name="contactPhone.number"
                              label="Phone Number *"
                              formatMask="+1 (###) ###-####"
                              component={NumberField}
                              disabled={loading}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Address</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              fullWidth
                              name="contactAddress.street1"
                              label="Address"
                              requierd
                              component={TextField}
                              disabled={loading}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              fullWidth
                              name="contactAddress.city"
                              label="City"
                              requierd
                              component={TextField}
                              disabled={loading}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              fullWidth
                              name="contactAddress.state"
                              label="State/Province"
                              requierd
                              component={TextField}
                              disabled={loading}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              fullWidth
                              name="contactAddress.zip"
                              label="Zip Code"
                              required
                              isNumericString
                              formatMask="#####"
                              component={NumberField}
                              disabled={loading}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              fullWidth
                              name="contactAddress.country"
                              label="Country"
                              requierd
                              component={TextField}
                              disabled={loading}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={handleSubmit}
                          loading={loading}
                          disabled={isSubmitDisabled}
                        >
                          Save
                        </Button>
                        <Button
                          onClick={() => onCancel(form)}
                          disabled={isSubmitDisabled}
                          variant="outlined"
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Form>
          </Grid>
        </Grid>
      </PaperBlock>
      <PaperBlock title="Branding" collapsing defaultOpened>
        <BrandingTitle variant="subtitle2">Organization Logo</BrandingTitle>
        <WrapperTitles>
          <MainLogo>Main Logo Version (over white backgrond)</MainLogo>
          <NegativeLogo>Negative Logo Version (over black backgrond)</NegativeLogo>
        </WrapperTitles>
        <Wrapper>
          <ImageSelector
            uri={logoURI}
            disabled={disabled}
            loading={fetchingLogo || updatingLogo}
            onChange={handleChangeLogo}
          />

          <ImageSelector
            uri={negativeLogoURI}
            disabled={disabled}
            loading={fetchingNegativeLogo || updatingNegativeLogo}
            onChange={handleChangeNegativeLogo}
          />
        </Wrapper>

        <ConfirmationDeleteDialog />
      </PaperBlock>
      <PaperBlock title="Location" collapsing defaultOpened>
        <LocationInfo />
      </PaperBlock>

      <SocialMediaOrganization />
      <SocialMediaModal />
    </Container>
  );
};
