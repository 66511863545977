var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from 'lodash';
import { parseHtmlText } from './helpers';
export function trimSpaces(value) {
    return value !== undefined && value !== null ? value.trim().length !== 0 : true;
}
export function validateWithSchema(schema, values, validationOptions) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        try {
            yield schema.validate(values, Object.assign({ abortEarly: false }, validationOptions));
            return {};
        }
        catch (error) {
            return (_a = error === null || error === void 0 ? void 0 : error.inner) === null || _a === void 0 ? void 0 : _a.reduce((accum, error) => {
                return _.set(accum, error.path, error.errors[0]);
            }, {});
        }
    });
}
export function getRequiredFieldsFromSchema(schema) {
    if (_.isNil(schema)) {
        return [];
    }
    const currentFormFields = schema.describe().fields;
    const fieldsMap = _.pickBy(_.mapValues(currentFormFields, (field) => {
        return Boolean(_.find(field.tests, { name: 'required' }));
    }), Boolean);
    return _.keys(fieldsMap);
}
export const composeValidators = (...validators) => (value, allValues, meta) => validators.reduce((error, validator) => error || validator(value, allValues, meta), undefined);
export function validateHtmlTextLength(html, minValue, maxValue, required) {
    if (html) {
        const parsedText = parseHtmlText(html);
        const textLength = parsedText ? parsedText.length - 1 : 0;
        if (required && textLength < minValue) {
            return 'required';
        }
        if (textLength > maxValue) {
            return `Must be maximum ${maxValue} symbols`;
        }
    }
    return undefined;
}
export function isHtmlTextLengthValid(html, minValue, maxValue) {
    if (!html) {
        return false;
    }
    const parsedText = parseHtmlText(html);
    const textLength = parsedText ? parsedText.length - 1 : 0;
    if (textLength < minValue || textLength > maxValue) {
        return false;
    }
    return true;
}
