import React from 'react';
import { Box, Checkbox, CircularProgress, FormControlLabel, Typography } from '@mui/material';
import _ from 'lodash';
import { Formik } from 'formik';

import { PaletteColor } from '@vizsla/theme';
import { PaperBlock, Button } from '@vizsla/components';

import { useGetNotificationSettingsData } from './hooks';
import {
  ButtonSectionWrapper,
  ContentWrapper,
  Form,
  GridContainer,
  NotifGroupGrid,
  SelectAllButton,
} from './styles';

export const OrganizationSettingsNotifications: React.FC = () => {
  const { initialValues, onSubmit, notificationTypesGrouped, loading, editSection } =
    useGetNotificationSettingsData();

  return (
    <ContentWrapper>
      <Typography variant="h3" marginBottom={1}>
        Notifications
      </Typography>
      <Typography variant="body2" marginBottom={4}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore.
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, submitForm, handleChange, setValues }) => (
            <Form>
              {notificationTypesGrouped &&
                Object.entries(notificationTypesGrouped).map(([key, group]) => {
                  let sectionActive =
                    values[key] && !!Object?.values(values[key]).find(val => val?.enabled);

                  const groupTitle = `${key.substring(0, 1).toUpperCase()}${key.substring(
                    1,
                    key.length,
                  )} Notifications`;

                  return (
                    <PaperBlock
                      titleContent={
                        <Box>
                          <Typography variant="h5" color={PaletteColor.BlueText}>
                            {groupTitle}
                          </Typography>
                          <Typography variant="body2" marginBottom={0.5}>
                            Description goes here.
                          </Typography>
                        </Box>
                      }
                      collapsing
                      key={key}
                      defaultOpened
                      switchProps={{
                        checked: sectionActive,
                        onSwitch: e => {
                          if (e.valueOf()) {
                            const checkedGroupData = editSection(group, true);
                            setValues({ ...values, [key]: checkedGroupData });
                            sectionActive = true;
                          } else {
                            const checkedGroupData = editSection(group, false);

                            setValues({ ...values, [key]: checkedGroupData });
                            sectionActive = false;
                          }
                        },
                      }}
                    >
                      <GridContainer>
                        <SelectAllButton
                          onClick={() => {
                            const checkedGroupData = editSection(group, true);

                            setValues({ ...values, [key]: checkedGroupData });
                            sectionActive = true;
                          }}
                          fullWidth={false}
                          size="small"
                          variant="text"
                        >
                          Select All
                        </SelectAllButton>
                        <NotifGroupGrid>
                          {group.map(notif => (
                            <FormControlLabel
                              key={notif.key}
                              control={
                                <Checkbox
                                  id="terms"
                                  name={`${key}.${notif.key}.enabled`}
                                  color="primary"
                                  defaultChecked={values[key][notif?.key as string]?.enabled}
                                  required
                                  checked={values[key][notif?.key as string]?.enabled}
                                />
                              }
                              id={notif.key || ''}
                              name={`${key}.${notif.key}.enabled`}
                              label={<Typography variant="body2">{notif.label}</Typography>}
                              onChange={handleChange}
                            />
                          ))}
                        </NotifGroupGrid>
                      </GridContainer>
                    </PaperBlock>
                  );
                })}
              <ButtonSectionWrapper>
                <Button onClick={submitForm} size="large">
                  Save
                </Button>
              </ButtonSectionWrapper>
            </Form>
          )}
        </Formik>
      )}
    </ContentWrapper>
  );
};
