import * as yup from 'yup';
import _ from 'lodash';

import { isDateEquals, isDateGreaterOrEquals, trimTime, validateWithSchema } from '@vizsla/utils';

import { PricingSettingsSchema } from './pricingSettings';

export enum TicketingOptionFormState {
  AgeRequirementEnabled = 'ageRequirementEnabled',
  TicketingLimitsEnabled = 'ticketingLimitsEnabled',
  GroupTicketsEnabled = 'groupTicketsEnabled',
  AttendeeCapEnabled = 'attendeeCapEnabled',
  TicketingAvailabilityEnabled = 'ticketingAvailabilityEnabled',
  PricingEnabled = 'pricingEnabled',
}

export const validateTicketingOptionCreatePeopleStep = async (formData: Record<string, any>) => {
  const context = {
    groupMin: formData.minimumGroupTickets,
    groupMax: formData.maximumGroupTickets,
  };

  const formErrors = await validateWithSchema(TicketingOptionCreatePeopleFormSchema, formData, {
    context,
  });
  return formErrors;
};

export const validateTicketingOptionCreateBasicsStep = async (formData: Record<string, any>) => {
  const context = {
    startDate: formData.ticketingAvailabilityStartDate,
    startTime: formData.ticketingAvailabilityStartTime,
    endDate: formData.ticketingAvailabilityEndDate,
    endTime: formData.ticketingAvailabilityEndTime,
  };

  const formErrors = await validateWithSchema(TicketingOptionCreateBasicsFormSchema, formData, {
    context,
  });
  return formErrors;
};

export const validateTicketingOptionCreatePricingForm = async (formData: Record<string, any>) => {
  const formErrors = await validateWithSchema(TicketingOptionCreatePricingFormSchema, formData);

  return formErrors;
};

export const onValidateTicketingOptionEditForm = async (formData: Record<string, any>) => {
  const context = {
    startDate: formData.ticketingAvailabilityStartDate,
    startTime: formData.ticketingAvailabilityStartTime,
    endDate: formData.ticketingAvailabilityEndDate,
    endTime: formData.ticketingAvailabilityEndTime,
    groupMin: formData.minimumGroupTickets,
    groupMax: formData.maximumGroupTickets,
  };

  const formErrors = await validateWithSchema(TicketingOptionEditFormSchema, formData, {
    context,
  });
  return formErrors;
};

const TicketNameSchema = yup.string().required('Ticket Name is required');
const TableNumberSchema = yup.number().nullable().min(1, 'Table Number must be positive');
const SectionEnabledSchema = yup.boolean();
const AgeRequirementSchema = yup.number().when(TicketingOptionFormState.AgeRequirementEnabled, {
  is: true,
  then: yup
    .number()
    .nullable()
    .required('Age Requirement must be provided')
    .min(1, 'Required Age must be positive number'),
  otherwise: yup.number().nullable(),
});
const AgeRequirementAsOfDateSchema = yup
  .string()
  .when(TicketingOptionFormState.AgeRequirementEnabled, {
    is: true,
    then: yup.string().nullable().required('Date for Age Requirement must be specified'),
    otherwise: yup.string().nullable(),
  });
const AttendeesPerTicketSchema = yup
  .number()
  .when(TicketingOptionFormState.TicketingLimitsEnabled, {
    is: true,
    then: yup
      .number()
      .nullable()
      .required('Attendees per Ticket must be specified')
      .min(1, 'Attendees number must be positive'),
    otherwise: yup.number().nullable(),
  });

const MaximumPerOrderSchema = yup.number().when(TicketingOptionFormState.TicketingLimitsEnabled, {
  is: true,
  then: yup
    .number()
    .nullable()
    .required('Maximum restriction per order is required')
    .min(1, 'Maximum per Order must be positive'),
  otherwise: yup.number().nullable(),
});

const MinimumGroupTicketsSchema = yup.number().when(TicketingOptionFormState.GroupTicketsEnabled, {
  is: true,
  then: yup
    .number()
    .nullable()
    .required('Group Tickets Minimum value is required')
    .min(0, 'Minimum must be positive')
    .test('min-less-than-max', 'Group Minimum cannot exceed Maximum', (value, { options }) => {
      const groupMax = options.context?.groupMax;

      return !_.isNil(value) || !_.isNil(groupMax) ? (value as number) <= groupMax : false;
    }),
  otherwise: yup.number().nullable(),
});

const MaximumGroupTicketsSchema = yup.number().when(TicketingOptionFormState.GroupTicketsEnabled, {
  is: true,
  then: yup
    .number()
    .nullable()
    .required('Group Tickets Maximum value is required')
    .min(0, 'Maximum must be positive')
    .test(
      'max-greater-than-min',
      'Group Maximum cannot be less than Minimum',
      (value, { options }) => {
        const groupMin = options.context?.groupMin;

        return !_.isNil(value) || !_.isNil(groupMin) ? (value as number) >= groupMin : false;
      },
    ),
  otherwise: yup.number().nullable(),
});

const TicketingAvailabilityStartDateSchema = yup
  .string()
  .when(TicketingOptionFormState.TicketingAvailabilityEnabled, {
    is: true,
    then: yup
      .string()
      .nullable()
      .required('Start Date is required')
      .test(
        'start-date-less-than-end-date',
        'Start Date must not exceed End Date',
        (startDate, { options }) => {
          const { context } = options;

          return isDateGreaterOrEquals(context?.endDate, startDate);
        },
      ),
    otherwise: yup.string().nullable(),
  });

const TicketingAvailabilityStartTimeSchema = yup
  .string()
  .when(TicketingOptionFormState.TicketingAvailabilityEnabled, {
    is: true,
    then: yup
      .string()
      .nullable()
      .required('Start Time is required')
      .test(
        'start-datetime-less-than-end-datetime',
        'Start Time must not exceed End Time',
        (startTime, { options }) => {
          const { context } = options;

          const startDate = trimTime(context?.startDate);
          const endDate = trimTime(context?.endDate);

          if (isDateEquals(startDate, endDate)) {
            return isDateGreaterOrEquals(context?.endTime, startTime, 'minute');
          }
          return true;
        },
      ),
    otherwise: yup.string().nullable(),
  });

const TicketingAvailabilityEndDateSchema = yup
  .string()
  .when(TicketingOptionFormState.TicketingAvailabilityEnabled, {
    is: true,
    then: yup
      .string()
      .nullable()
      .required('End Date is required')
      .test(
        'end-date-greater-than-start-date',
        'End Date must not preceed Start Date',
        (endDate, { options }) => {
          const { context } = options;

          return isDateGreaterOrEquals(endDate, context?.startDate);
        },
      ),
    otherwise: yup.string().nullable(),
  });

const TicketingAvailabilityEndTime = yup
  .string()
  .when(TicketingOptionFormState.TicketingAvailabilityEnabled, {
    is: true,
    then: yup
      .string()
      .nullable()
      .required('End Time is required')
      .test(
        'end-datetime-greater-than-start-datetime',
        'End Time must not preceed Start Time',
        (endTime, { options }) => {
          const { context } = options;

          const startDate = trimTime(context?.startDate);
          const endDate = trimTime(context?.endDate);

          if (isDateEquals(startDate, endDate)) {
            return isDateGreaterOrEquals(endTime, context?.startTime, 'minute');
          }
          return true;
        },
      ),
    otherwise: yup.string().nullable(),
  });

const MaximumAttendeesSchema = yup
  .number()
  .nullable()
  .when(TicketingOptionFormState.AttendeeCapEnabled, {
    is: true,
    then: yup
      .number()
      .nullable()
      .required('Maximum attendees is required')
      .min(1, 'Attendees Maximum must be positive'),
    otherwise: yup.number().nullable(),
  });

const TicketingOptionCreatePeopleFormSchema = yup.object().shape({
  [TicketingOptionFormState.AgeRequirementEnabled]: SectionEnabledSchema,
  ageRequirement: AgeRequirementSchema,
  ageRequirementAsOfDate: AgeRequirementAsOfDateSchema,
  [TicketingOptionFormState.TicketingLimitsEnabled]: SectionEnabledSchema,
  attendeesPerTicket: AttendeesPerTicketSchema,
  maximumPerOrder: MaximumPerOrderSchema,

  [TicketingOptionFormState.GroupTicketsEnabled]: SectionEnabledSchema,
  minimumGroupTickets: MinimumGroupTicketsSchema,
  maximumGroupTickets: MaximumGroupTicketsSchema,

  [TicketingOptionFormState.AttendeeCapEnabled]: SectionEnabledSchema,
  maximumAttendees: MaximumAttendeesSchema,
});

const TicketingOptionCreateBasicsFormSchema = yup.object().shape({
  ticketName: TicketNameSchema,
  tableNumber: TableNumberSchema,

  [TicketingOptionFormState.TicketingAvailabilityEnabled]: SectionEnabledSchema,
  ticketingAvailabilityStartDate: TicketingAvailabilityStartDateSchema,
  ticketingAvailabilityStartTime: TicketingAvailabilityStartTimeSchema,
  ticketingAvailabilityEndDate: TicketingAvailabilityEndDateSchema,
  ticketingAvailabilityEndTime: TicketingAvailabilityEndTime,
});

const TicketingOptionCreatePricingFormSchema = yup.object({
  pricingSettings: PricingSettingsSchema,
});

const TicketingOptionEditFormSchema = yup.object().shape({
  ticketName: TicketNameSchema,
  tableNumber: TableNumberSchema,

  [TicketingOptionFormState.AgeRequirementEnabled]: SectionEnabledSchema,
  ageRequirement: AgeRequirementSchema,
  ageRequirementAsOfDate: AgeRequirementAsOfDateSchema,
  [TicketingOptionFormState.TicketingLimitsEnabled]: SectionEnabledSchema,
  attendeesPerTicket: AttendeesPerTicketSchema,
  maximumPerOrder: MaximumPerOrderSchema,

  [TicketingOptionFormState.GroupTicketsEnabled]: SectionEnabledSchema,
  minimumGroupTickets: MinimumGroupTicketsSchema,
  maximumGroupTickets: MaximumGroupTicketsSchema,

  [TicketingOptionFormState.TicketingAvailabilityEnabled]: SectionEnabledSchema,
  ticketingAvailabilityStartDate: TicketingAvailabilityStartDateSchema,
  ticketingAvailabilityStartTime: TicketingAvailabilityStartTimeSchema,
  ticketingAvailabilityEndDate: TicketingAvailabilityEndDateSchema,
  ticketingAvailabilityEndTime: TicketingAvailabilityEndTime,

  [TicketingOptionFormState.AttendeeCapEnabled]: SectionEnabledSchema,
  maximumAttendees: MaximumAttendeesSchema,
  pricingSettings: PricingSettingsSchema,
});
