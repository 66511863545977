export type PartnershipCommitmentT = any;
export type PartnershipCommitmentDBRowT = PartnershipCommitmentT & {
  id?: string;
};

export enum CommitmentTypesE {
  cash = 'cash',
  inKind = 'inKind',
  matching = 'matching',
}

export const CommitmentTypesLabelsDisct = {
  [CommitmentTypesE.cash]: 'Cash',
  [CommitmentTypesE.inKind]: 'In-Kind',
  [CommitmentTypesE.matching]: 'Matching',
};

export type CashCommitmentValuesT = {
  amount: number;
  type: CommitmentTypesE.inKind;
  description: string;
  campaignId?: string;
  experienceId?: string;
  notes?: string;
  partnershipOrganizationId: string;
  decisionDate: string;
  agreement?: any;
  status: 'pending' | 'paid';
};
export type InKindCommitmentValuesT = {
  amount: number;
  type: CommitmentTypesE.inKind;
  description: string;
  campaignId?: string;
  experienceId?: string;
  notes?: string;
  partnershipOrganizationId: string;
  serviceProvided: string;
  agreement?: any;
};
export type MatchingCommitmentValuesT = {
  type: CommitmentTypesE.matching;
  matchLimit: number;
  amount: number;
  description: string;
  campaignId: string;
  experienceId: string;
  startDate: string;
  startTime: string;
  endDate?: string;
  endTime?: string;
  notes?: string;
  partnershipOrganizationId: string;
  agreement: any;
};

export type SubmitCommitmentValuesT =
  | InKindCommitmentValuesT
  | MatchingCommitmentValuesT
  | CashCommitmentValuesT;
