import React, { memo, useMemo } from 'react';
import { Box } from '@mui/material';

import {
  CardPaymentMethodBrand,
  PaymentMethod,
  BankPaymentMethod,
  CardPaymentMethod,
} from '@vizsla/graphql';

import { formantCardPaymentMethodBrand } from 'src/utils/cards';

import {
  LOGO_AMEX,
  LOGO_DISCOVER,
  LOGO_MASTERCARD,
  LOGO_VISA,
} from '../../../assets/paymentMethodsLogos';
import {
  BankIcon,
  CardBrandImage,
  Details,
  IconContainer,
  Name,
  Reference,
  HeaderCard,
  FooterCardLogo,
} from './PaymentMethods.styles';
import { PaymentMethodCardLayout } from '../../../layouts/paymentMethodCardLayout/paymentMethodCardLayout';

interface Props {
  data: any;

  /** @default true */
  showRemove?: boolean;

  /** @default false */
  active?: boolean;

  onStar?: any;
  loading?: boolean;
  onRemove?(method: PaymentMethod): void;
}

const CARD_IMAGES: Partial<Record<CardPaymentMethodBrand, string>> = {
  AMEX: LOGO_AMEX,
  MASTERDCARD: LOGO_MASTERCARD,
  VISA: LOGO_VISA,
  DISCOVER: LOGO_DISCOVER,
};

export function PaymentMethodCard(props: Props) {
  const method = useMemo(() => props.data, [props.data]);

  const handleRemove = () => {
    props.onRemove?.(method);
  };

  const handleStar = () => {
    props.onStar();
  };

  const Layout = memo(inner => (
    <PaymentMethodCardLayout
      data={method}
      active={props.active}
      showRemove={props.showRemove}
      onRemove={handleRemove}
      onStar={handleStar}
      loading={props.loading}
    >
      {inner.children}
    </PaymentMethodCardLayout>
  ));

  // eslint-disable-next-line no-underscore-dangle
  if (method.__typename === 'CardPaymentMethod') {
    const brand = formantCardPaymentMethodBrand(method.brand);

    return (
      <Layout>
        <HeaderCard>
          <Name>{brand}</Name>
          <Reference>xxxx xxxx xxxx {method.reference}</Reference>
        </HeaderCard>
        <FooterCardLogo>
          <IconContainer>
            <CardBrandImage src={CARD_IMAGES[method.brand]} />
          </IconContainer>
        </FooterCardLogo>
      </Layout>
    );
  }

  // eslint-disable-next-line no-underscore-dangle
  if (method.__typename === 'BankPaymentMethod') {
    return (
      <Layout>
        <HeaderCard>
          <Name>{method.name}</Name>
          <Reference>xxxx xxxx xxxx {method.reference}</Reference>
        </HeaderCard>
        <FooterCardLogo>
          <IconContainer>
            <BankIcon />
          </IconContainer>
        </FooterCardLogo>
      </Layout>
    );
  }

  return (
    <Layout>
      <Details>
        <Name>Unknown Method</Name>
      </Details>
    </Layout>
  );
}
