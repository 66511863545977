import React from 'react';
import _ from 'lodash';
import {
  CircularProgress,
  TextField,
  Autocomplete,
  AutocompleteRenderGetTagProps,
} from '@mui/material';

import { isFunction } from '@vizsla/utils';
import { RenderTag } from '@vizsla/components';

export interface TagInputProps {
  value: any;
  label?: React.ReactNode;
  placeholder?: string;
  renderTag?: RenderTag;
  onAdd?: (chip: any) => any;
  readOnly?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  loading?: boolean;
}

export const TagInput: React.FC<TagInputProps> = ({
  value,
  label,
  renderTag,
  onAdd,
  placeholder = 'Type to Add',
  readOnly = false,
  fullWidth = false,
  disabled = false,
  required = false,
  loading = false,
  ...props
}) => {
  const [inputValue, setInputValue] = React.useState<string>('');

  const renderTags = (options: Array<any>, getTagProps: AutocompleteRenderGetTagProps) => {
    if (!isFunction(renderTag)) {
      return undefined;
    }

    const renderTagFn = renderTag as RenderTag;
    return options.map((option: any, index: number) => {
      const { key, onDelete, ...tagProps } = getTagProps({ index });
      const tagComponent = renderTagFn(option, index) as any;
      if (_.isNil(tagComponent)) {
        return null;
      }
      const tagComponentProps = tagComponent?.props;

      return React.cloneElement(tagComponent, {
        ...tagComponentProps,
        ...tagProps,
      });
    });
  };

  const onInputChange = (event: any, inputValue: string) => {
    if (!_.isNull(event)) {
      if (event?.key === 'Enter' && event?.target?.value) {
        onAdd?.(event.target.value);
        setInputValue('');
      } else {
        setInputValue(inputValue);
      }
    }
  };

  return (
    <Autocomplete
      value={[...value]}
      inputValue={inputValue}
      onInputChange={onInputChange}
      renderTags={renderTags}
      options={[]}
      open={false}
      disabled={disabled}
      clearIcon={null}
      freeSolo
      multiple
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            readOnly,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="secondary" size={20} /> : null}
              </React.Fragment>
            ),
          }}
        />
      )}
      {...props}
    />
  );
};
