import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import {
  useCurrentOrganization,
  usePagination,
  usePaginationQueryVariables,
  useSearchQueryVariables,
} from '@vizsla/hooks';
import { User, UserFilter } from '@vizsla/graphql';

const DONORS_CRM_QUERY = gql`
  query DonorsCRM($first: Int, $skip: Int, $filter: UserFilter) {
    users: usersList(first: $first, skip: $skip, filter: $filter) {
      count
      items {
        id
        firstName
        lastName
        email

        avatar {
          downloadUrl
        }

        donated: allocatedDonations(
          groupBy: { query: { amount: { as: "total", fn: { aggregate: SUM } } } }
        ) {
          groups {
            total: Float
          }
        }

        allocatedDonations(first: 1, orderBy: createdAt_DESC) {
          items {
            id
            createdAt
          }
        }

        campaigns: attendees(
          groupBy: {
            query: { experience: { campaign: { id: { as: "count", fn: { aggregate: COUNT } } } } }
          }
        ) {
          groups {
            count: Int
          }
        }
      }
    }
  }
`;

export type DonorCRMRow = User & {
  campaigns: {
    groups: [
      {
        count: number;
      },
    ];
  };

  donated: {
    groups: [
      {
        total: number;
      },
    ];
  };
};

interface AttendeesCRMQuery {
  users: {
    count: number;
    items: DonorCRMRow[];
  };
}

interface AttendeesCRMQueryVariables {
  first?: number;
  skip?: number;
  filter?: UserFilter;
}

export function useDonorsCRM() {
  const pagination = usePagination();
  const paginationQuery = usePaginationQueryVariables();
  const searchQuery = useSearchQueryVariables(true);

  const { organizationId } = useCurrentOrganization();

  const organizationFilter: UserFilter = useMemo(() => {
    return {
      allocatedDonations: {
        some: {
          experience: {
            campaign: {
              vizslaOrganization: { id: { equals: organizationId } },
            },
          },
        },
      },
    };
  }, [organizationId]);

  const { data: response, loading } = useQuery<AttendeesCRMQuery, AttendeesCRMQueryVariables>(
    DONORS_CRM_QUERY,
    {
      variables: {
        ...paginationQuery,

        filter: {
          ...searchQuery,
          ...organizationFilter,
        },
      },
    },
  );

  useEffect(() => {
    pagination.onCountChange(response?.users.count ?? 0);
  }, [response]);

  const data = useMemo(() => response?.users.items ?? [], [response]);

  return {
    data,
    loading,
  };
}
