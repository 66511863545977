import React from 'react';
import _ from 'lodash';
import { Box, Typography, Tooltip, Theme, Paper } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useForm, Field } from 'react-final-form';

import { SwitchField } from '@vizsla/components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 3),
  },

  checkbox: {
    color: theme.palette.secondary.main,

    '&.Mui-checked': {
      color: theme.palette.secondary.light,
    },
  },
}));

interface SwitchSectionFieldProps {
  heading: string;
  name: string;
  tooltip?: string;
  label?: string;
  fieldsToResetOnUncheck?: string[];
}

export const SwitchSectionField: React.FC<SwitchSectionFieldProps> = ({
  children,
  name,
  heading,
  tooltip,
  label = '',
  fieldsToResetOnUncheck = [],
}) => {
  const classes = useStyles();

  const { batch, change, getFieldState, resetFieldState } = useForm();
  const checkbox = getFieldState(name);
  const sectionActive = checkbox?.value;

  React.useEffect(() => {
    if (!_.isNil(sectionActive) && !sectionActive) {
      batch(() => {
        fieldsToResetOnUncheck.forEach(fieldName => {
          change(fieldName, null);
          resetFieldState(fieldName);
        });
      });
    }
  }, [batch, change, resetFieldState, sectionActive, fieldsToResetOnUncheck]);

  return (
    <Paper variant="outlined" className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" height={40} alignItems="center">
          <Typography variant="h4" display="inline-block" marginRight={1}>
            {heading}
          </Typography>
          {tooltip && (
            <Tooltip title={tooltip}>
              <HelpOutline color="disabled" />
            </Tooltip>
          )}
        </Box>

        <Field
          name={name}
          label={label}
          color="secondary"
          formControlLabelProps={{
            labelPlacement: 'start',
            componentsProps: {
              typography: {
                variant: 'body2',
              },
            },
          }}
          component={SwitchField}
          className={classes.checkbox}
        />
      </Box>

      <Box paddingY={2.5}>{children}</Box>
    </Paper>
  );
};
