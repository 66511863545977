import React from 'react';
import { Grid, Typography } from '@mui/material';
import { FormSpy } from 'react-final-form';

import { PaletteColor } from '@vizsla/theme';
import { Field, RadioGroupField, CheckboxField, GridDefaultPadding } from '@vizsla/components';

import { PRICING_TYPE_SELECT_FIELD_OPTIONS } from 'src/constants/pricingSettings';
import { SwitchSectionField } from 'src/components/forms';
import { PricingTiersField } from 'src/components/experiencePricing';

export const TicketingOptionPricingStep: React.FC = () => {
  return (
    <FormSpy subscription={{ values: true, errors: true }}>
      {({ form, values }) => {
        return (
          <Grid container mb={2} spacing={2}>
            <GridDefaultPadding container item xs={12}>
              <Grid item xs={12}>
                <Typography variant="h1" align="center">
                  Pricing Settings
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" align="center" color={PaletteColor.GrayText}>
                  Manage pricing settings
                </Typography>
              </Grid>
            </GridDefaultPadding>

            <GridDefaultPadding item xs={12}>
              <SwitchSectionField name="pricingSettings.pricingEnabled" heading="Pricing Settings">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="pricingSettings.pricingType"
                      component={RadioGroupField}
                      containerOptions={{ direction: 'row', container: true }}
                      options={PRICING_TYPE_SELECT_FIELD_OPTIONS}
                      disabled={!values.pricingSettings.pricingEnabled}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="pricingSettings.pricingTiers"
                      component={PricingTiersField}
                      mutators={form.mutators}
                      variant={values.pricingSettings.pricingType}
                      disabled={!values.pricingSettings.pricingEnabled}
                    />
                  </Grid>
                  {/* This section is disabled by current business logic */}
                  {/* <Grid item xs={12}>
                    <Field
                      name="pricingSettings.allowCostToBeFundraisingCredit"
                      label="Allow Registration Cost to be Fundraising Credit."
                      component={CheckboxField}
                      disabled={!values.pricingSettings.pricingEnabled}
                    />
                  </Grid> */}
                </Grid>
              </SwitchSectionField>
            </GridDefaultPadding>
          </Grid>
        );
      }}
    </FormSpy>
  );
};
