import React from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { Grid, Popover, Theme, Typography } from '@mui/material';

import { usePopover } from '@vizsla/hooks';
import { FontSize, FontWeight, PaletteColor } from '@vizsla/theme';

import { pickTextColorBasedOnBgColor } from 'src/utils/colorPicker';

const useStyles = makeStyles<Theme, { bgColor: string }>(() => ({
  swatch: {
    border: `1px solid #C0C4C9`,
    borderRadius: '8px',
    width: '170px',
    textAlign: 'center',
    padding: '11px 0',
    outline: '3.5px solid #fff',
    outlineOffset: '-4.5px',
    cursor: 'pointer',
    backgroundColor: ({ bgColor }) => bgColor,
    '&:focus': {
      borderColor: PaletteColor.PrimaryGray,
    },
  },
  innerLabel: {
    fontWeight: FontWeight.Regular,
    fontSize: FontSize.Default,
    color: ({ bgColor }) =>
      pickTextColorBasedOnBgColor(bgColor, PaletteColor.ContrastText, PaletteColor.PrimaryGray),
  },
  popover: {
    marginTop: '5px',
  },
  contentWrap: {
    width: '252px',
    padding: '16px 24px',
  },
  paletteItem: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    border: `1px solid #C0C4C9`,
    borderRadius: '3px',
    outline: '2px solid #fff',
    outlineOffset: '-3px',
    marginRight: '6px',
    '&:nth-child(7n)': {
      marginRight: 0,
    },
  },
  colorInput: {
    display: 'block',
    boxSizing: 'border-box',
    border: '1px solid #C0C4C9',
    font: 'inherit',
    fontWeight: FontWeight.Regular,
    fontSize: FontSize.Default,
    color: PaletteColor.DefaultText,
    borderRadius: '4px',
    outline: 'none',
    textAlign: 'left',
    width: '108px',
    padding: '11px 0 11px 26px',
    height: '40px',
  },
  hexSign: {
    fontSize: FontSize.Default,
    color: PaletteColor.DefaultMain,
    position: 'absolute',
    left: '12px',
    top: '11px',
  },
  paletteEditor: {
    position: 'relative',
  },
  colorPicker: {
    '&.react-colorful': {
      height: '150px',
      width: '204px',
    },

    '&>.react-colorful__saturation': {
      borderRadius: '8px',
      borderBottom: 'none',
      marginBottom: '16px',
    },

    '&>.react-colorful__saturation .react-colorful__saturation-pointer': {
      width: '16px',
      height: '16px',
    },

    '&>.react-colorful__hue': {
      height: '10px',
      borderRadius: '5px',
    },

    '&>.react-colorful__hue .react-colorful__hue-pointer': {
      width: '15px',
      height: '15px',
    },
  },
}));

interface ColorLibraryFieldProps {
  color: string;
  colorLibrary: Array<string>;
  onChange: (color: string) => void;
  onChangeLibrary: (colorLibrary: Array<string>) => void;
  label: string;
}

export const ColorLibraryField: React.FC<ColorLibraryFieldProps> = ({
  color,
  colorLibrary,
  onChange,
  onChangeLibrary,
  label,
}) => {
  const classes = useStyles({ bgColor: color });
  const popover = usePopover();

  const [isOpenedEditor, setIsOpenedEditor] = React.useState<boolean>(false);
  const [currentColorIndex, setCurrentColorIndex] = React.useState<number | null>();

  const listener = React.useCallback(
    (event: any) => {
      if (
        !popover.isOpen ||
        event?.target?.value ||
        event?.target?.className?.includes('react-colorful')
      ) {
        return;
      }
      setCurrentColorIndex(null);
      setIsOpenedEditor(false);
    },
    [popover.isOpen],
  );

  React.useEffect(() => {
    if (isOpenedEditor) {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
    }
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [isOpenedEditor, listener]);

  const handleClickOnPaletteItem = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const [index, value] = event?.currentTarget?.value.split('-');
    setIsOpenedEditor(true);
    setCurrentColorIndex(Number(index));
    onChange(value);
  };

  const handleOnChangePaletteItem = (color: string) => {
    const newColorLibrary = colorLibrary.map((libraryItem: string, index: number) =>
      index === currentColorIndex ? color : libraryItem,
    );
    onChange(color);
    onChangeLibrary(newColorLibrary);
  };

  const debouncedOnChange = _.debounce(handleOnChangePaletteItem, 100);

  return (
    <React.Fragment>
      <button
        className={classes.swatch}
        onClick={popover.openPopover}
        type="button"
        style={{
          borderColor: popover.isOpen ? PaletteColor.PrimaryGray : '#C0C4C9',
        }}
      >
        <span className={classes.innerLabel}>{label}</span>
      </button>
      <Popover
        anchorEl={popover.el}
        open={popover.isOpen}
        onClose={popover.closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.popover}
      >
        <Grid className={classes.contentWrap} container>
          <Grid item>
            <Typography variant="caption" fontWeight="bold">
              Color Library
            </Typography>
            <Grid item marginTop={2}>
              {colorLibrary?.map((paletteItem: string, index: number) => (
                <button
                  key={paletteItem}
                  aria-label={paletteItem}
                  value={`${index}-${paletteItem}`}
                  className={classes.paletteItem}
                  type="button"
                  style={{
                    backgroundColor: paletteItem,
                    borderColor: index === currentColorIndex ? PaletteColor.PrimaryGray : '#C0C4C9',
                  }}
                  onClick={handleClickOnPaletteItem}
                />
              ))}
            </Grid>
            {isOpenedEditor && (
              <React.Fragment>
                <Grid item className={classes.paletteEditor} marginTop={2}>
                  <span className={classes.hexSign}>#</span>
                  <HexColorInput
                    color={color}
                    onChange={handleOnChangePaletteItem}
                    className={classes.colorInput}
                  />
                </Grid>
                <Grid item marginTop={1}>
                  <HexColorPicker
                    className={classes.colorPicker}
                    color={color}
                    onChange={debouncedOnChange}
                  />
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Popover>
    </React.Fragment>
  );
};
