import React from 'react';
import { Button } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';

import { useCurrentUser, useModal } from '@vizsla/hooks';
import { StripePaymentMethodsQuery, StripePaymentMethodsQueryVariables } from '@vizsla/graphql';

import { STRIPE_PAYMENT_METHODS, STRIPE_PAYMENT_METHODS_DEFAULT } from 'src/graphql/stripe';
import { MODALS } from 'src/constants/modals';

import { PaymentMethodCard } from './PaymentMethodCard';
import { CreatePaymentMethod } from './CreatePaymentMethod/CreatePaymentMethod';
import { InactivePaymentMethod } from './InactivePaymentMethod/InactivePaymentMethod';
import { Actions, CardSkeleton, Container } from './PaymentMethods.styles';

export const PaymentMehod = () => {
  const { user } = useCurrentUser();
  const userId = React.useMemo(() => user?.id, [user]);
  const { openModal } = useModal();
  const [loadingButton, setLoadingButton] = React.useState<boolean>(false);

  const [paymentMethodDefault] = useMutation(STRIPE_PAYMENT_METHODS_DEFAULT);
  const { data, loading } = useQuery<StripePaymentMethodsQuery, StripePaymentMethodsQueryVariables>(
    STRIPE_PAYMENT_METHODS,
    {
      variables: {
        userId: String(userId),
      },
    },
  );
  const paymentMethods = React.useMemo(() => {
    return data?.stripePaymentMethods?.items ?? [];
  }, [data]);
  if (loading) {
    return (
      <Container>
        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
      </Container>
    );
  }
  const openCreatePayment = () => {
    openModal(MODALS.ORGANIZATION_SETTING_CREATE_PAYMENT_METHOD);
  };
  const openInactivePayment = paymentMethodId => {
    openModal(MODALS.ORGANIZATION_SETTING_INACTIVE_PAYMENT_METHOD, { paymentMethodId });
  };

  const handlerDefault = async paymentMethodId => {
    try {
      setLoadingButton(true);
      await paymentMethodDefault({
        variables: {
          userId,
          paymentMethodId,
        },
        refetchQueries: [{ query: STRIPE_PAYMENT_METHODS, variables: { userId: String(userId) } }],
        awaitRefetchQueries: true,
      });
      setLoadingButton(false);
      return paymentMethodId;
    } catch (error) {
      console.error(error);
      return '';
    }
  };
  return (
    <React.Fragment>
      <Container>
        <CreatePaymentMethod />
        <InactivePaymentMethod />
        {!loading && paymentMethods
          ? paymentMethods.map(method => (
              <PaymentMethodCard
                key={method?.id}
                data={method}
                active={method?.primary}
                showRemove={false}
                onRemove={() => {
                  openInactivePayment(method?.id);
                }}
                loading={loadingButton}
                onStar={() => {
                  handlerDefault(method?.id);
                }}
              />
            ))
          : ''}
      </Container>
      <Actions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            openCreatePayment();
          }}
        >
          + ADD CREDIT CARD / BANK ACCOUNT
        </Button>
      </Actions>
    </React.Fragment>
  );
};
