import React from 'react';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Field, Form } from 'react-final-form';

import { Modal } from '@vizsla/components';
import { useModal, useNotification } from '@vizsla/hooks';
import { MODALS } from '@vizsla/constants';

import { ImageInputField } from 'src/components/forms';

export const SelectProfilePictureModal = ({ onSubmit }) => {
  const { isOpen, closeModal, args } = useModal(MODALS.PROFILE_PICTURE_UPDATE_MODAL);
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Grid container padding="20px" minHeight="300px" alignItems="center" flexDirection="column">
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, pristine, submitting }) => (
            <React.Fragment>
              <Field
                fullWidth
                name="picture"
                label="Add Image"
                width={250}
                height={250}
                component={ImageInputField}
                disabled={submitting}
                imageDim={[200, 200]}
              />
              <Grid mt="4px">
                <LoadingButton onClick={handleSubmit} disabled={pristine} loading={submitting}>
                  Save
                </LoadingButton>
              </Grid>
            </React.Fragment>
          )}
        </Form>
      </Grid>
    </Modal>
  );
};
