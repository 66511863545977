import React from 'react';
import { FileInput, FileInputProps } from '8base-react-sdk';

/*
  We will need this wrap for styling when new version of file-input will be available
  Right now we have to use default filestack picker and we cant change styles
  API will remain the same
*/

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const FileInputWrap: React.FC<FileInputProps> = ({ children, ...rest }) => {
  return <FileInput {...rest}>{children}</FileInput>;
};
