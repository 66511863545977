import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TemplateType } from '@vizsla/types';
import { Button } from '@vizsla/components';
import { LandingRoutes, RouterEntitiesId } from '@vizsla/constants';
import { buildUrl } from '@vizsla/utils';

import * as router from 'src/constants/routes';
import { EditorCampaignPagesRoutes } from 'src/types/campaignPages';

const useStyles = makeStyles(theme => ({
  buttons: {},

  button: {
    margin: 0,
  },
}));

export interface CardOptionsIndividualFundraiserProps {
  campaignID: string;
  type: TemplateType;
}

export function CardOptionsIndividualFundraiser(props: CardOptionsIndividualFundraiserProps) {
  const styles = useStyles();
  const navigate = useNavigate();

  const editorURL = React.useMemo(() => {
    if (!props.campaignID) return '';

    const routes = router as Record<string, string>;
    const path = routes[EditorCampaignPagesRoutes[props.type]];
    const params = {
      [RouterEntitiesId.campaign]: props.campaignID,
    };

    return buildUrl(path, params);
  }, []);
  const openEditor = () => {
    navigate(editorURL);
  };
  return (
    <div>
      <Grid container className={styles.button}>
        <Button
          variant="text"
          size="small"
          color="primary"
          className={styles.button}
          onClick={openEditor}
        >
          Edit
        </Button>
      </Grid>
    </div>
  );
}
