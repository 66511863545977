import React from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// import { ExperienceLandingElementProps } from '../ExperienceLanding.types';
import { ExperienceLandingElementProps } from 'src/providers/campaign/ThankYouLanding.types';

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontSize: '18px',
  },
  title: {
    fontSize: '26px',
  },
}));

export const DonationInformation: React.FC<ExperienceLandingElementProps> = ({
  experience,
  theme,
}) => {
  const { accentColor } = theme;
  const isDonationInformationTitleVisible = theme?.isDonationInformationTitleVisible ?? true;
  const isDonationInformationDescriptionVisible =
    theme?.isDonationInformationDescriptionVisible ?? true;

  const classes = useStyles({ accentColor });

  return (
    <Grid>
      {isDonationInformationTitleVisible && (
        <Typography variant="h4" textAlign="center" marginBottom={1} className={classes.title}>
          Donation Information
        </Typography>
      )}
      {isDonationInformationDescriptionVisible && (
        <Grid container>
          <Grid item xs={4}>
            <Typography>Amount</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{`${
              experience?.donations?.items && experience?.donations?.items.length > 0
                ? `$ ${experience?.donations?.items[0].amount}`
                : 'Amount Donation'
            }`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>Confirmation Number</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{`${
              experience?.donations?.items && experience?.donations?.items.length > 0
                ? experience?.donations?.items[0].order?.number ?? 'Number Donation'
                : 'Number Donation'
            }`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="Bold">
              A copy of your receipt will be sent to your email shortly
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
