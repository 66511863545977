import React from 'react';
import { Field, Form } from 'react-final-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { AuthTextField } from '@vizsla/components';
import { validateWithSchema } from '@vizsla/utils';
import { useAppAuth } from '@vizsla/hooks';
import { BorderRadius } from '@vizsla/theme';
import { ForgotPasswordCodeVerificationValidationSchema } from '@vizsla/constants';

import { OrganizationAuthLayout } from 'src/layouts';

import { StyledButton, StyledForm, StyledLink } from '../components/styled-components';
import * as appRoutes from '../../../constants/routes';
import { authForgotPassword, authLogin } from '../../../constants/routes';

type LocationState = {
  email: string;
};

const StyledEmail = styled(Typography)`
  --color: ${({ theme }) => theme.palette.info.light};
  margin: ${({ theme }) => theme.spacing(2, 0)};
  color: var(--color);
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: ${BorderRadius.M};
  border: 1px solid var(--color);
  width: 100%;
  max-width: 330px;
`;

export function CodeVerificationAndChangePassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const { forgotPasswordCodeVerification, forgotPassword } = useAppAuth();

  const email = React.useMemo(() => {
    if (!location.state) return '';
    const { email } = location.state as LocationState;
    return email;
  }, [location]);

  const handleVerifyCode = React.useCallback(
    async ({ password, code }) => {
      await forgotPasswordCodeVerification({ email, code, password });
      navigate(appRoutes.authLogin, { replace: true });
    },
    [email, forgotPasswordCodeVerification, navigate],
  );
  const resendCode = React.useCallback(() => forgotPassword(email), [email, forgotPassword]);
  const validate = React.useCallback(
    values => validateWithSchema(ForgotPasswordCodeVerificationValidationSchema, values),
    [],
  );
  return (
    <OrganizationAuthLayout
      title="Forgot password"
      caption="We have sent you an email with a code to reset your password. Please check your inbox."
    >
      <Form onSubmit={handleVerifyCode} initialValues={{ email }} validate={validate}>
        {({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
          const isDisabled = submitting || pristine || hasValidationErrors;
          return (
            <StyledForm onSubmit={handleSubmit}>
              <StyledEmail color="whitesmoke">{email}</StyledEmail>
              <Field name="code" label="Enter code" component={AuthTextField} />
              <Field
                type="password"
                name="password"
                label="Enter new password"
                readonly
                component={AuthTextField}
              />
              <Box gap={1} display="flex">
                <StyledButton loading={submitting} disabled={isDisabled}>
                  Change password
                </StyledButton>
                <StyledButton variant="outlined" color="info" type="button" onClick={resendCode}>
                  Resend code
                </StyledButton>
              </Box>
              <StyledLink to={authForgotPassword} replace>
                Change email
              </StyledLink>
              <StyledLink to={authLogin} replace>
                Back to login
              </StyledLink>
            </StyledForm>
          );
        }}
      </Form>
    </OrganizationAuthLayout>
  );
}
