import React from 'react';
import { Grid, Tab, Theme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { useQueryParams } from '@vizsla/hooks';
import { buildUrl } from '@vizsla/utils';

import * as appRoutes from 'src/constants/routes';
import { useCampaign, useCampaignId } from 'src/hooks/campaign';
import { CampaignType, PeopleTablesTab } from 'src/types/campaign';
import { CAMPAIGN_TYPE_PEOPLE_TABS_MAP } from 'src/constants/campaign';
import {
  CampaignAttendeesTab,
  CampaignFundraisersTab,
  CampaignPeopleTeamsTab,
  CampaignPeopleDonorsTab,
} from 'src/components/campaign';

const useStyles = makeStyles((theme: Theme) => ({
  tabPanel: {
    padding: 0,
  },
}));

export const CampaignPeopleTables: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [{ tab }] = useQueryParams();

  const campaignId = useCampaignId();

  const { data, loading } = useCampaign(campaignId);

  const tabs = CAMPAIGN_TYPE_PEOPLE_TABS_MAP[data?.campaignType as CampaignType];

  React.useEffect(() => {
    if (!_.isNil(tab) || _.isNil(data) || loading) {
      return;
    }

    navigate(
      buildUrl(
        appRoutes.campaignPeople,
        {
          campaignId,
        },
        {
          tab: tabs[0],
        },
      ),
    );
  }, [tab, campaignId, navigate, data, loading, tabs]);

  const onTabSwitch = (_: React.ChangeEvent<any>, newValue: PeopleTablesTab) => {
    navigate(
      buildUrl(
        appRoutes.campaignPeople,
        {
          campaignId,
        },
        {
          tab: newValue,
        },
      ),
    );
  };

  const renderTabs = () => {
    if (_.isNil(data?.campaignType) || loading) {
      return null;
    }
    return (
      <TabList onChange={onTabSwitch}>
        {tabs.map((tab: PeopleTablesTab) => (
          <Tab key={tab} label={tab} value={tab} />
        ))}
      </TabList>
    );
  };

  if (_.isNil(data) || loading) {
    return null;
  }

  return (
    <Grid paddingTop={3}>
      <TabContext value={tab}>
        <Grid container justifyContent="center" marginBottom={3}>
          {renderTabs()}
        </Grid>
        <TabPanel value={PeopleTablesTab.Attendees} className={classes.tabPanel}>
          <CampaignAttendeesTab showNavigation />
        </TabPanel>
        <TabPanel value={PeopleTablesTab.Fundraisers} className={classes.tabPanel}>
          <CampaignFundraisersTab showNavigation />
        </TabPanel>
        <TabPanel value={PeopleTablesTab.Teams} className={classes.tabPanel}>
          <CampaignPeopleTeamsTab />
        </TabPanel>
        <TabPanel value={PeopleTablesTab.Donors} className={classes.tabPanel}>
          <CampaignPeopleDonorsTab />
        </TabPanel>
      </TabContext>
    </Grid>
  );
};
