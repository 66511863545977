import React from 'react';
import _ from 'lodash';
import { Box, Grid } from '@mui/material';

import { Button } from '@vizsla/components';
import {
  CampaignQuestionUserGroup,
  CampaignQuestionCategories,
  CampaignQuestionGroup,
} from '@vizsla/types';
import {
  filterDefaultQuestionsByUserGroup,
  filterQuestionsByUserGroup,
  getManagedQuestionGroups,
} from '@vizsla/utils';
import { DefaultCampaignQuestion } from '@vizsla/graphql';

import { PaperBlock } from 'src/components/shared';
import { useCampaignId } from 'src/hooks/campaign';
import { useCampaignQuestions } from 'src/hooks/campaignQuestion';

import { CampaignManageQuestionTable } from './CampaignManageQuestionTable';
import { UserGroupsTabs } from './UserGroupsTabs';

interface ManageCampaignQuestionsProps {
  handleSetIsManagingQuestions: (isManagingQuestions: boolean) => void;
}

export const ManageCampaignQuestions: React.FC<ManageCampaignQuestionsProps> = ({
  handleSetIsManagingQuestions,
}) => {
  const campaignId = useCampaignId();
  const {
    data: { defaultQuestions, customQuestions },
  } = useCampaignQuestions(campaignId);

  const [userGroup, setUserGroup] = React.useState<CampaignQuestionUserGroup>(
    CampaignQuestionUserGroup.attendee,
  );

  const managedQuestionGroups = React.useMemo(
    () => getManagedQuestionGroups(defaultQuestions, customQuestions),
    [defaultQuestions, customQuestions],
  );

  const mandatoryQuestionsByUserGroup = React.useMemo(() => {
    const defaultMandatoryQuestions = (managedQuestionGroups[0].questions ??
      []) as Array<DefaultCampaignQuestion>;

    return filterDefaultQuestionsByUserGroup(defaultMandatoryQuestions, userGroup);
  }, [managedQuestionGroups, userGroup]);

  const optionalQuestionGroups = React.useMemo(
    () => managedQuestionGroups.slice(1),
    [managedQuestionGroups],
  );

  const onChangeUserGroupTab = (_: React.SyntheticEvent, userGroup: CampaignQuestionUserGroup) => {
    setUserGroup(userGroup);
  };

  return (
    <PaperBlock title="Questions" collapsing defaultOpened>
      <Box display="flex" justifyContent="center" marginBottom={4}>
        <UserGroupsTabs userGroup={userGroup} onChangeUserGroupTab={onChangeUserGroupTab} />
      </Box>
      <Grid container direction="column">
        <Grid item xs={12}>
          <CampaignManageQuestionTable
            category={CampaignQuestionCategories.Mandatory}
            questions={mandatoryQuestionsByUserGroup}
          />
        </Grid>
        {optionalQuestionGroups.map(({ category, questions }: CampaignQuestionGroup) => {
          const filteredQuestionsByUserGroup = filterQuestionsByUserGroup(questions, userGroup);

          if (_.isEmpty(filteredQuestionsByUserGroup)) {
            return null;
          }

          return (
            <Grid item key={category} pt={2}>
              <CampaignManageQuestionTable
                category={category}
                questions={filteredQuestionsByUserGroup}
              />
            </Grid>
          );
        })}
        <Grid item container alignItems="center" justifyContent="center" mb={2} mt={4}>
          <Grid item>
            <Button onClick={() => handleSetIsManagingQuestions(false)}>Go Back</Button>
          </Grid>
        </Grid>
      </Grid>
    </PaperBlock>
  );
};
