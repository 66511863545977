import React from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useExperienceQuery } from '@vizsla/graphql';
import { ExperienceType } from '@vizsla/types';

import { useExperienceId, useExperienceFundraisingSettings } from 'src/hooks/experiences';
import {
  CampaignExperienceBibAssignment,
  CampaignExperienceGeneralSettings,
} from 'src/components/campaign/campaignExperience';
import { CampaignExperienceImages } from 'src/components/campaign/campaignExperienceImages';
import {
  CampaignIndividualFundraisingSettings,
  CampaignTeamFundraisingSettings,
} from 'src/components/campaign';
import {
  ExperienceWaiver,
  ExperienceEarlyBirdRegistration,
  ExperienceDonationOptions,
} from 'src/components/experience';
import { RegistrationOptionsSettings } from 'src/components/registrationOption';
import { TicketingOptionsTable } from 'src/components/ticketingOption';
import { ExperienceStoreSettings } from 'src/components/store';

import { CampaignDonationSettings } from '../CampaignFundraising/CampaignDonationSettings';
import { FundraisingSettings } from '../CampaignFundraising/FundraisingSettings';

const useStyles = makeStyles({
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    overflowWrap: 'normal',
  },
});

const CampaignExperience: React.FC = () => {
  const classes = useStyles();
  const experienceId = useExperienceId();

  const { data, loading } = useExperienceQuery({
    variables: {
      id: experienceId,
    },
  });

  const experienceType = data?.experience?.experienceType;
  const experienceName = data?.experience?.name || '-';
  const waiverAllowMultipleAttendeeConsent = data?.experience?.waiverAllowMultipleAttendeeConsent;
  const waiverTextBody = data?.experience?.waiverTextBody;
  if (loading) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Typography component="div" width={400} className={classes.ellipsis} variant="h3">
          {experienceName}
        </Typography>
      </Grid>

      <Grid item>
        <CampaignExperienceImages />
      </Grid>

      <Grid item>
        <CampaignExperienceGeneralSettings />
      </Grid>

      {experienceType === ExperienceType.registration ? (
        <React.Fragment>
          <Grid item>
            <RegistrationOptionsSettings />
          </Grid>
          <Grid item>
            <ExperienceEarlyBirdRegistration />
          </Grid>
          <Grid item>
            <ExperienceWaiver
              experienceId={experienceId}
              waiverAllowMultipleAttendeeConsent={waiverAllowMultipleAttendeeConsent}
              waiverTextBody={waiverTextBody}
            />
          </Grid>
          <Grid item>
            <CampaignDonationSettings
              experienceId={experienceId}
              useFundraisingSettings={useExperienceFundraisingSettings}
            />
          </Grid>
          <Grid item>
            <ExperienceDonationOptions />
          </Grid>
          <Grid item>
            <FundraisingSettings
              experienceId={experienceId}
              useFundraisingSettings={useExperienceFundraisingSettings}
            />
          </Grid>
          <Grid item>
            <ExperienceStoreSettings experienceId={experienceId} />
          </Grid>
          <Grid item>
            <CampaignIndividualFundraisingSettings
              experienceId={experienceId}
              useFundraisingSettings={useExperienceFundraisingSettings}
            />
          </Grid>
          <Grid item>
            <CampaignTeamFundraisingSettings
              experienceId={experienceId}
              useFundraisingSettings={useExperienceFundraisingSettings}
            />
          </Grid>
          <Grid item>
            <CampaignExperienceBibAssignment />
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid item>
            <CampaignDonationSettings
              experienceId={experienceId}
              useFundraisingSettings={useExperienceFundraisingSettings}
            />
          </Grid>
          <Grid item>
            <TicketingOptionsTable />
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

export { CampaignExperience };
