import React from 'react';

import { TransactionsListContent } from 'src/components/transactions';
import { TransactionListLayout } from 'src/layouts';

export function TransactionsPage() {
  return (
    <TransactionListLayout>
      <TransactionsListContent />
    </TransactionListLayout>
  );
}
