import React from 'react';
import { Button, Grid, SwitchProps, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  enrollButton: {
    margin: theme.spacing('auto', 0, 0, 0),
    width: 100,
  },
}));

interface SocialNetworkCardProps extends SwitchProps {
  socialNetworkName: string;
  enabled?: boolean;
}

export const SocialNetworkCard: React.FC<SocialNetworkCardProps> = ({
  socialNetworkName,
  enabled,
}) => {
  const classes = useStyles();

  return (
    <Grid height="100%" container direction="row" spacing={2}>
      <Grid item>
        <Typography variant="subtitle2">
          Allow attendees to fundraise on {socialNetworkName}.
        </Typography>
        <Typography variant="body2">
          Not enrolled with {socialNetworkName} Giving? Click below to enroll. Applications take 2-3
          weeks to be reviewed.
        </Typography>
      </Grid>
      <Grid item>
        <Button
          className={classes.enrollButton}
          disabled={!enabled}
          variant="text"
          color="secondary"
          size="small"
        >
          Enroll Now
        </Button>
      </Grid>
    </Grid>
  );
};
