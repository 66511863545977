import { useApolloClient } from '@apollo/client';
import debounce from 'debounce-promise';

import {
  CampaignDiscountCounterByNameQuery,
  CampaignDiscountCounterByNameQueryVariables,
} from '@vizsla/graphql';

import { CAMPAIGN_DISCOUNT_COUNTER_BY_NAME } from 'src/graphql';
import { useCampaignId } from 'src/hooks/campaign';

const ERROR_TEXT = 'Discount code with such Name already exists';

export const useCheckCampaignDiscountName = () => {
  const campaignId = useCampaignId();
  const client = useApolloClient();

  const nameValidator = async (discountName: string, initialDiscountName?: string) => {
    if (discountName && discountName !== initialDiscountName) {
      const { data } = await client.query<
        CampaignDiscountCounterByNameQuery,
        CampaignDiscountCounterByNameQueryVariables
      >({
        query: CAMPAIGN_DISCOUNT_COUNTER_BY_NAME,
        variables: {
          campaignId,
          discountName,
        },
      });

      const count = data?.campaign?.discountCodeSettings?.count || 0;
      if (count) {
        return ERROR_TEXT;
      }
    }

    return undefined;
  };

  const debouncedNameValidator = debounce(nameValidator, 250);

  return { debouncedNameValidator };
};
