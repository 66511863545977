import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useModal } from '@vizsla/hooks';
import { buildUrl } from '@vizsla/utils';
import { useRegistrationOptionSwagbagListQuery, RegistrationOptionSwagbag } from '@vizsla/graphql';
import { TableColumn, TableData, TableRowAction } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import { SwagBagAssetActionUpdateOrDeleteModalArgs } from 'src/types/registrationOption';
import * as appRoutes from 'src/constants/routes';
import { storeAsset as storeAssetEntityId } from 'src/constants/entities-id';
import { TableNameCellContent } from 'src/components/storeAssets/StoreAssetsDashboardTable/components';

interface ExperienceStoreAssetsTableProps {
  isStoreEnabled: boolean;
  registrationOptionId: string;
  availableSwagBagItems: any;
}

const RegistrationOptionSwagBagAssetsTable: React.FC<ExperienceStoreAssetsTableProps> = ({
  isStoreEnabled,
  registrationOptionId,
  availableSwagBagItems,
}) => {
  const navigate = useNavigate();

  const { openModal } = useModal();
  const {
    data: dataTableSwagBag,
    loading,
    error,
    refetch,
  } = useRegistrationOptionSwagbagListQuery({
    variables: {
      filter: {
        registrationOption: {
          id: {
            equals: registrationOptionId,
          },
        },
      },
    },
  });

  refetch();
  const columns: Array<TableColumn<RegistrationOptionSwagbag>> = [
    {
      key: 'item',
      title: 'Asset',
      render: (experienceStoreAsset: RegistrationOptionSwagbag) => {
        const { storeAsset } = experienceStoreAsset;
        return (
          <TableNameCellContent
            assetName={storeAsset?.name || ''}
            assetImageUrl={storeAsset?.image?.downloadUrl || null}
          />
        );
      },
    },
    {
      key: 'available',
      title: 'Quantity Available',
      render: (experienceStoreAsset: RegistrationOptionSwagbag) => {
        const availableItem = availableSwagBagItems.find(
          item => item.id === experienceStoreAsset.storeAsset?.id,
        );
        return availableItem?.aviable || '-';
      },
    },
  ];

  const rowActions: Array<TableRowAction<RegistrationOptionSwagbag>> = [
    {
      actionName: 'View in Assets',
      onAction: experienceStoreAsset => {
        const assetUrl = buildUrl(appRoutes.storeAssetsAssetPage, {
          [storeAssetEntityId]: experienceStoreAsset.storeAsset?.id || '',
        });
        navigate(assetUrl);
      },
    },
    {
      actionName: 'Delete',
      onAction: storeAsset => {
        const modalArgs: SwagBagAssetActionUpdateOrDeleteModalArgs = {
          storeAsset,
        };
        openModal(MODALS.REGISTRATION_OPTION_SWAG_BAG_ASSET_DELETE_MODAL, modalArgs);
      },
    },
  ];

  const isTableLoading = loading;
  const isStoreDisabled = !isStoreEnabled;

  const dataSource = useMemo(
    () => dataTableSwagBag?.registrationOptionSwagbagsList?.items ?? [],
    [dataTableSwagBag],
  );

  return (
    <TableData
      loading={isTableLoading}
      disabled={isStoreDisabled}
      dataSource={dataSource}
      columns={columns}
      rowActions={rowActions}
    />
  );
};

export { RegistrationOptionSwagBagAssetsTable };
