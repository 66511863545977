import React from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';

import {
  CampaignFaq,
  CampaignFaqCreateMutation,
  CampaignFaqCreateMutationVariables,
  CampaignFaqDeleteMutation,
  CampaignFaqDeleteMutationVariables,
  CampaignFaqFilter,
  CampaignFaQsListQuery,
  CampaignFaQsListQueryVariables,
  CampaignFaqUpdateMutation,
  CampaignFaqUpdateMutationVariables,
} from '@vizsla/graphql';
import { useApolloCacheQueryManager } from '@vizsla/hooks';
import { mutableList } from '@vizsla/utils';
import { CampaignFaqAllocation } from '@vizsla/types';

import {
  CAMPAIGN_FAQS_LIST_QUERY,
  CAMPAIGN_FAQ_CREATE_MUTATION,
  CAMPAIGN_FAQ_DELETE_MUTATION,
  CAMPAIGN_FAQ_FRAGMENT,
  CAMPAIGN_FAQ_UPDATE_MUTATION,
} from 'src/graphql';

interface SplittedCampaignFaqsList {
  [CampaignFaqAllocation.Attendee]: Array<CampaignFaq>;
  [CampaignFaqAllocation.Donor]: Array<CampaignFaq>;
}

enum CampaignFaqTypeName {
  campaignFAQSList = 'CampaignFAQListResponse',
  campaignFaq = 'CampaignFAQ',
}

export const useCampaignFAQs = (campaignId: string, customFilter?: CampaignFaqFilter) => {
  const apolloClient = useApolloClient();

  // TO DO const filterByCampaignId = {
  //   campaign: {
  //     id: {
  //       equals: campaignId,
  //     },
  //   },
  // };

  // const filter = customFilter ?? filterByCampaignId;

  const filter = customFilter;

  const { data, loading } = useQuery<CampaignFaQsListQuery, CampaignFaQsListQueryVariables>(
    CAMPAIGN_FAQS_LIST_QUERY,
    {
      variables: {
        filter,
      },
      skip: !campaignId,
    },
  );

  const { updateQuery: updateCampaignFaqsListQuery } = useApolloCacheQueryManager<
    CampaignFaQsListQuery,
    CampaignFaQsListQueryVariables
  >({
    query: CAMPAIGN_FAQS_LIST_QUERY,
    typeName: 'CampaignFAQListResponse',
    variables: {
      filter,
    },
  });

  const campaignFaqs = data?.campaignFAQSList?.items || [];

  const [campaignFaqCreateMutation, { loading: creating }] = useMutation<
    CampaignFaqCreateMutation,
    CampaignFaqCreateMutationVariables
  >(CAMPAIGN_FAQ_CREATE_MUTATION);

  const [campaignFaqUpdateMutation, { loading: updating }] = useMutation<
    CampaignFaqUpdateMutation,
    CampaignFaqUpdateMutationVariables
  >(CAMPAIGN_FAQ_UPDATE_MUTATION);

  const [campaignFaqDeleteMutation, { loading: deleting }] = useMutation<
    CampaignFaqDeleteMutation,
    CampaignFaqDeleteMutationVariables
  >(CAMPAIGN_FAQ_DELETE_MUTATION);

  const getCampaignFaqFromCacheById = React.useCallback(
    (faqId: string): CampaignFaq | null => {
      try {
        return apolloClient.readFragment<CampaignFaq>({
          id: `${CampaignFaqTypeName.campaignFaq}:${faqId}`,
          fragment: CAMPAIGN_FAQ_FRAGMENT,
        });
      } catch (error) {
        console.error({ error });
      }

      return null;
    },
    [apolloClient],
  );

  const createCampaignFaq = async (payload: any) => {
    try {
      const result = await campaignFaqCreateMutation(payload);
      const campaignFaq = result?.data?.campaignFAQCreate;

      if (!_.isNil(campaignFaq)) {
        updateCampaignFaqsListQuery(query => {
          mutableList(query.campaignFAQSList.items).add(campaignFaq as CampaignFaq);
        });
      }
    } catch (error) {
      console.error({ error });
      throw error;
    }
  };

  const updateCampaignFaq = async (payload: any) => {
    try {
      await campaignFaqUpdateMutation(payload);
    } catch ({ error }) {
      console.error({ error });
      throw error;
    }
  };

  const deleteCampaignFaq = React.useCallback(
    async (faqId: string) => {
      try {
        await campaignFaqDeleteMutation({
          variables: {
            id: faqId,
          },
        });

        updateCampaignFaqsListQuery(query => {
          mutableList(query.campaignFAQSList.items).removeById(faqId);
        });
      } catch (error) {
        console.error({ error });
        throw error;
      }
    },
    [campaignFaqDeleteMutation, updateCampaignFaqsListQuery],
  );

  const splittedFaqsList: SplittedCampaignFaqsList = React.useMemo(() => {
    const initialValue = {
      [CampaignFaqAllocation.Attendee]: [],
      [CampaignFaqAllocation.Donor]: [],
    } as SplittedCampaignFaqsList;

    if (_.isNil(campaignFaqs)) {
      return initialValue;
    }

    return campaignFaqs.reduce((acc: SplittedCampaignFaqsList, faqItem: any) => {
      const allocationArr = (faqItem?.userGroup ?? [
        CampaignFaqAllocation.Attendee,
      ]) as CampaignFaqAllocation[];
      allocationArr.forEach((allocation: CampaignFaqAllocation) => {
        acc[allocation].push(faqItem);
      });
      return acc;
    }, initialValue);
  }, [campaignFaqs]);

  return {
    campaignFaqs,
    splittedFaqsList,
    getCampaignFaqFromCacheById,
    createCampaignFaq,
    updateCampaignFaq,
    deleteCampaignFaq,
    loading,
    creating,
    updating,
    deleting,
  };
};
