import React from 'react';

import { useCampaign, useCampaignId } from 'src/hooks/campaign';
import { CampaignType } from 'src/types/campaign';

import { CampaignPeoplePersonalMissionTeamsTable } from './CampaignPeoplePersonalMissionTeamsTable';
import { CampaignPeopleRegistrationAndTicketingTeamsTable } from './CampaignPeopleRegistrationAndTicketingTeamsTable';

export const CampaignPeopleTeamsTab: React.FC = () => {
  const campaignId = useCampaignId();

  const { data: campaign } = useCampaign(campaignId);

  switch (campaign?.campaignType as CampaignType) {
    case CampaignType.registrationAndTicketing:
      return <CampaignPeopleRegistrationAndTicketingTeamsTable />;
    case CampaignType.personalMission:
      return <CampaignPeoplePersonalMissionTeamsTable />;
    default:
      return null;
  }
};
