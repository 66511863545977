import React from 'react';
import { Typography } from '@mui/material';

import { CompanyAvatarFullNameInline } from '@vizsla/components';
import { formatDate, formatMoney } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';

const renderRowText = (text?: string) => <Typography>{text || 'N/A'}</Typography>;

export const columns = [
  {
    title: 'NAME',
    key: 'fullNameAvatar',
    dataPath: 'nameAvatar',
    render: row => {
      return !row ? (
        renderRowText('')
      ) : (
        <CompanyAvatarFullNameInline name={row?.name} avatar={row?.avatar} />
      );
    },
  },

  {
    title: 'MEMBER SINCE',
    key: 'selfMember',
    dataPath: 'selfMember',
    render: selfMember => {
      return renderRowText(
        formatDate(selfMember?.items?.at(0)?.createdAt, DateFormatPatterns.shortDateWithSlash) ||
          '',
      );
    },
  },

  {
    title: 'CAPTAIN',
    key: 'captain',
    dataPath: 'captain',
    render: captain => renderRowText(captain?.groups?.at(0)?.fullName),
  },
  {
    title: 'TEAMMATES',
    key: 'members',
    dataPath: 'members',
    render: members => renderRowText(members?.count),
  },
  {
    title: 'FUNDRAISING GOAL',
    key: 'fundraiserGoal',
    dataPath: 'fundraiserGoal',
    render: row => renderRowText(formatMoney(row)),
  },
  {
    title: 'CAMPAIGN',
    key: 'campaign',
    dataPath: 'campaign',
    render: campaign => renderRowText(campaign?.name),
  },
  {
    title: 'EXPERIENCE',
    key: 'experience',
    dataPath: 'experience',
    render: experience => renderRowText(experience?.name),
  },
];
