import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const Title = styled(Typography)`
  font-family: Inter;
  font-size: ${p => p.theme.typography.h2.fontSize};
  padding: ${p => p.theme.spacing(2)};
  font-weight: ${p => p.theme.typography.fontWeightBold};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(3)};
`;

export const BrandingTitle = styled(Typography)`
  font-family: Inter;
  margin-bottom: ${p => p.theme.spacing(2)};
  font-weight: ${p => p.theme.typography.fontWeightBold};
`;

export const MainLogo = styled.p`
  font-family: Inter;
  letter-spacing: -0.2px;
  line-height: ${p => p.theme.spacing(1)};
  padding-bottom: ${p => p.theme.spacing(2)};
`;

export const NegativeLogo = styled.p`
  font-family: Inter;
  letter-spacing: -0.2px;
  line-height: ${p => p.theme.spacing(1)};
  padding-bottom: ${p => p.theme.spacing(2)};
  margin-left: ${p => p.theme.spacing(22)};
`;
export const WrapperTitles = styled.div`
  display: flex;
`;
