import React from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useForm } from 'react-final-form';
import { isNil, debounce } from 'lodash';

import { formatNumber } from '@vizsla/utils';
import {
  useGetAllAssetsByOrganizationListQuery,
  useGetAllFileListQuery,
  GetAllAssetInventoryFilter,
} from '@vizsla/graphql';
import { useCurrentOrganization } from '@vizsla/hooks';
import { DEFAULT_NUMBER_OF_SEARCH_OPTIONS } from '@vizsla/components';

import { useExperienceStoreAssets } from 'src/hooks/storeAssets';
import { SearchInput } from 'src/components/shared';
import {
  ExperienceStoreAssetFormFields,
  ExperienceStoreAssetAddOrUpdateFormValues,
} from 'src/types/storeAssets';
import { EMPTY_IMAGE } from 'src/assets';

interface GetAllAssetsVariables {
  filter: GetAllAssetInventoryFilter;
  first?: number;
  skip?: number;
}

const FormSearchSection: React.FC = () => {
  const { organizationId } = useCurrentOrganization();

  let data: any = [];

  const { assetsIds } = useExperienceStoreAssets();

  const { change, getState, batch } = useForm<ExperienceStoreAssetAddOrUpdateFormValues>();
  const { values } = getState();

  const [searchValue, setSearchValue] = React.useState<string>();

  const storeAllAssetsVariables = React.useMemo(() => {
    const variables: GetAllAssetsVariables = {
      filter: {
        organizationId: { equals: organizationId },
        name: { contains: searchValue },
      },
      first: DEFAULT_NUMBER_OF_SEARCH_OPTIONS,
    };
    if (assetsIds.length > 0) {
      variables.filter.id = { not_in: assetsIds };
    }
    return variables;
  }, [searchValue, assetsIds, organizationId]);

  const {
    data: assets,
    loading: loadAssets,
    refetch,
  } = useGetAllAssetsByOrganizationListQuery({
    variables: storeAllAssetsVariables,
    skip: !organizationId,
  });

  const searchIds =
    assets?.getAllAssetInventoriesList?.items?.map(({ picture }) => picture as any) || [];

  const { data: assetsPictures, loading: loadAssetsPictures } = useGetAllFileListQuery({
    variables: { filter: { id: { in: searchIds } } },
  });

  if (assetsPictures?.filesList?.items.length && assets?.getAllAssetInventoriesList?.items.length) {
    data = assets.getAllAssetInventoriesList.items.map((asset: any) => {
      const value = asset;
      const image = assetsPictures.filesList.items.filter(itF => itF.id === asset.picture);
      return { ...value, imageUrl: image[0]?.downloadUrl } as any;
    });
  }

  const storeAssets = data || [];

  const debouncedSetSearchValue = debounce(setSearchValue, 500);

  const isLoading = loadAssets || loadAssetsPictures;

  const renderItemsList = () => {
    if (isNil(searchValue)) {
      return null;
    }

    if (isLoading) {
      return (
        <Typography textAlign="center" padding={2}>
          <CircularProgress />
        </Typography>
      );
    }

    if (storeAssets.length === 0) {
      return (
        <Typography variant="body1" textAlign="center" padding={2}>
          Not found
        </Typography>
      );
    }

    return (
      <List>
        {storeAssets.map(storeAsset => (
          <ListItem key={storeAsset?.id} disablePadding>
            <ListItemButton
              onClick={() => {
                batch(() => {
                  change(ExperienceStoreAssetFormFields.STORE_ASSET, storeAsset);
                  change(ExperienceStoreAssetFormFields.PRICE, storeAsset?.price || 0);
                });
              }}
            >
              <ListItemAvatar>
                <Avatar
                  src={storeAsset?.imageUrl || EMPTY_IMAGE}
                  alt={storeAsset?.name || 'Asset'}
                />
              </ListItemAvatar>

              <ListItemText>{storeAsset.name}</ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };

  const selectedStoreAsset = values[ExperienceStoreAssetFormFields.STORE_ASSET] as any;

  if (!selectedStoreAsset) {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <SearchInput onChange={debouncedSetSearchValue} />
        </Grid>

        <Grid item xs={12}>
          {renderItemsList()}
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Avatar
          variant="rounded"
          sx={{ width: 150, height: 150, margin: '0 auto' }}
          src={
            selectedStoreAsset?.imageUrl || selectedStoreAsset?.image?.downloadUrl || EMPTY_IMAGE
          }
          alt={selectedStoreAsset?.name || 'Asset'}
        />
      </Grid>

      <Grid item xs={12} mt={2}>
        <Typography variant="h4" textAlign="center">
          {selectedStoreAsset.name}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={0.5}>
        <Typography variant="body2" textAlign="center">
          Quantity Available:{' '}
          <strong>
            {formatNumber(selectedStoreAsset.aviable || selectedStoreAsset.available) || 0}
          </strong>
        </Typography>
      </Grid>
    </React.Fragment>
  );
};

export { FormSearchSection };
