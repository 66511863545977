import React from 'react';
import { Form as FinalForm, FormSpy, FormSpyRenderProps } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { validateWithSchema, concatDateWithTime } from '@vizsla/utils';
import { useCurrentUser, useFormStepper, FormStep } from '@vizsla/hooks';

import { useCampaignRegistration } from 'src/hooks/campaign';
import {
  CampaignRegistrationStepperController,
  CampaignExperiencesFormCollection,
  CampaignRegistrationStepperTitle,
  CampaignRegistrationFinalScreen,
  CampaignRegistrationFormOne,
  CampaignUploadLogoForm,
  CampaignGoalsForm,
} from 'src/components/campaign';
import {
  CampaignRegistrationFormOneSchema,
  CampaignRegistrationFormThreeSchema,
} from 'src/constants/validationSchemas/campaign';
import type { ExperienceContextSchema } from 'src/constants/validationSchemas/experience';
import {
  CAMPAIGN_REGISTRATION_INITIAL_FIRST_FORM_FIELDS,
  CAMPAIGN_REGISTRATION_INITIAL_SECOND_FORM_FIELDS,
  CAMPAIGN_REGISTRATION_INITIAL_THREE_FORM_FIELDS,
  CAMPAIGN_REGISTRATION_INITIAL_FOURTH_FORM_FIELDS,
  EXPERIENCE_INITIAL_FORM_FIELDS,
} from 'src/constants/campaign';
import { buildCampaignRegistrationFormData, buildExperiencesFormData } from 'src/utils/campaign';
import { getExperienceFormSchema } from 'src/utils/experience';

const FORM_STEPS: Array<FormStep> = [
  {
    render: () => {
      return <CampaignRegistrationFormOne />;
    },
    validate: async (formData: any) => {
      const formErrors = await validateWithSchema(CampaignRegistrationFormOneSchema, formData);

      return formErrors;
    },
  },
  {
    render: () => {
      return <CampaignUploadLogoForm />;
    },
  },
  {
    render: () => {
      return <CampaignGoalsForm />;
    },
    validate: async (formData: any) => {
      const formErrors = await validateWithSchema(CampaignRegistrationFormThreeSchema, formData);

      return formErrors;
    },
  },
  {
    render: () => {
      return <CampaignExperiencesFormCollection />;
    },
    validate: async (formData: any): Promise<any> => {
      const { experiences } = formData;

      const context = {
        campaignStartDate: concatDateWithTime(formData.startDate, formData.startTime),
        campaignEndDate: concatDateWithTime(formData.endDate, formData.endTime),
      } as ExperienceContextSchema;

      const formErrors = await Promise.all(
        experiences.map(async (experienceForm: typeof EXPERIENCE_INITIAL_FORM_FIELDS) => {
          const validationSchema = getExperienceFormSchema(experienceForm);
          const errors = await validateWithSchema<ExperienceContextSchema>(
            validationSchema,
            experienceForm,
            { context },
          );

          return errors;
        }),
      );

      return formErrors;
    },
  },
];

const CAMPAIGN_INFO_FORM_INDEX = 0;
const CAMPAIGN_IMAGE_FORM_INDEX = 1;
const CAMPAIGN_GOALS_FORM_INDEX = 2;
const CAMPAIGN_EXPERIENCES_FORM_INDEX = 3;

interface CampaignRegistrationStepsProps {
  onCloseModal: () => void;
}

export const CampaignRegistrationAndTicketingSteps: React.FC<CampaignRegistrationStepsProps> = ({
  onCloseModal,
}) => {
  const { step, isLast, isFirst, prev, next, validate, render } = useFormStepper(FORM_STEPS);

  const { campaignType, createCampaign, isCreatingCampaign, createdCampaign } =
    useCampaignRegistration();

  const { firstName } = useCurrentUser();

  const initialValues = {
    ...CAMPAIGN_REGISTRATION_INITIAL_FIRST_FORM_FIELDS,
    ...CAMPAIGN_REGISTRATION_INITIAL_SECOND_FORM_FIELDS,
    ...CAMPAIGN_REGISTRATION_INITIAL_THREE_FORM_FIELDS,
    ...CAMPAIGN_REGISTRATION_INITIAL_FOURTH_FORM_FIELDS,
  };

  const getTitle = (values: any): string => {
    switch (step) {
      case CAMPAIGN_INFO_FORM_INDEX: {
        return `Okay ${firstName}, here we go!`;
      }
      default:
        return `${values.name}`;
    }
  };

  const getSubtitle = (): string => {
    switch (step) {
      case CAMPAIGN_IMAGE_FORM_INDEX: {
        return "Now let's add your logo and a money shot! You can always skip or edit later.";
      }
      case CAMPAIGN_GOALS_FORM_INDEX: {
        return `Nice, ${firstName}. You're doing great.`;
      }
      case CAMPAIGN_EXPERIENCES_FORM_INDEX: {
        return `Right on ${firstName}! Now let's add your campaign experiences.`;
      }
      default:
        return "Let's kick this campaign off!";
    }
  };

  const onCreateCampaign = async (formValues: any) => {
    const campaignData = buildCampaignRegistrationFormData({ ...formValues, campaignType });
    const { experiences } = formValues;

    const experiencesData = buildExperiencesFormData(experiences, campaignData);

    try {
      await createCampaign(campaignData, experiencesData);
    } catch (error) {
      console.error({ error });
    }
  };

  const shouldShowSkipButton = step === CAMPAIGN_IMAGE_FORM_INDEX;
  const shouldShowBackButton = !isFirst;
  const shouldShowFinalScreen = createdCampaign !== null;

  if (shouldShowFinalScreen) {
    return <CampaignRegistrationFinalScreen onManage={onCloseModal} />;
  }

  return (
    <FinalForm
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      validate={validate}
      onSubmit={onCreateCampaign}
      subscription={{ submitting: true }}
      render={({ handleSubmit }) => {
        return (
          <React.Fragment>
            <FormSpy subscription={{ values: true }}>
              {(spyRenderProps: FormSpyRenderProps) => {
                return (
                  <CampaignRegistrationStepperTitle
                    title={getTitle(spyRenderProps.values)}
                    subtitle={getSubtitle()}
                  />
                );
              }}
            </FormSpy>
            {render()}
            <FormSpy subscription={{ valid: true, validating: true }}>
              {(spyRenderProps: FormSpyRenderProps) => {
                return (
                  <CampaignRegistrationStepperController
                    shouldShowSkipButton={shouldShowSkipButton}
                    shouldShowBackButton={shouldShowBackButton}
                    isLastStep={isLast}
                    onCancel={onCloseModal}
                    onContinue={next}
                    onCreate={handleSubmit}
                    isCreating={isCreatingCampaign}
                    canContinue={spyRenderProps.valid && !spyRenderProps.validating}
                    onBack={prev}
                    onSkip={next}
                  />
                );
              }}
            </FormSpy>
          </React.Fragment>
        );
      }}
    />
  );
};
