import React from 'react';
import { Grid, Typography } from '@mui/material';

import { ExperienceVenue } from '@vizsla/graphql';

import { VenuesTable } from './VenuesTable';

interface WhereBlockProps {
  venues: ExperienceVenue[];
}

export const WhereBlock: React.FC<WhereBlockProps> = ({ venues }) => {
  return (
    <Grid container item direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6">Where</Typography>
      </Grid>
      <Grid item flexBasis={0} flexGrow={1}>
        <VenuesTable venues={venues} />
      </Grid>
    </Grid>
  );
};
