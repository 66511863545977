import React from 'react';
import { Box, Paper, Switch, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { PaletteColor } from 'src/theme';

const useStyles = makeStyles<Theme>(theme => ({
  integration: {
    padding: theme.spacing(4),
    transition: '500ms',
  },
  whiteBackground: {
    background: PaletteColor.White,
  },
  greyBackground: {
    background: theme.palette.grey['50'],
  },
}));

interface CampaignAdministrationIntegrationProps {
  title: string;
  extra: string;
  image: React.ReactNode;
}

export const CampaignAdministrationIntegration: React.FC<
  CampaignAdministrationIntegrationProps
> = ({ title, extra, image }) => {
  const classes = useStyles();

  const [isEnabled, setIsEnabled] = React.useState(false);

  const handleChange = () => {
    setIsEnabled(!isEnabled);
  };

  return (
    <Paper
      className={clsx(classes.integration, {
        [classes.whiteBackground]: isEnabled,
        [classes.greyBackground]: !isEnabled,
      })}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Box marginRight={4}>{image}</Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body2">{extra}</Typography>
          </Box>
        </Box>
        <Switch value={isEnabled} onChange={handleChange} />
      </Box>
    </Paper>
  );
};
