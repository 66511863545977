import { DateTime } from 'luxon';

import { toDateTimeFromISO } from '@vizsla/utils';
import { Experience } from '@vizsla/graphql';

import { DateType, EXPERIENCE_INITIAL_FORM_FIELDS } from 'src/constants/campaign';
import {
  RegistrationExperienceBaseSchema,
  RegistrationExperienceSchemaEndDateTime,
  RegistrationExperienceWithStartDateSchema,
} from 'src/constants/validationSchemas/experience';

export function getExperienceById(
  experiences: Array<Experience>,
  experienceId: string,
): Experience | null {
  return experiences.find(experience => experience.id === experienceId) || null;
}

type ExperienceFormData = Omit<typeof EXPERIENCE_INITIAL_FORM_FIELDS, 'uid'>;

export function getExperienceFormSchema(formData: ExperienceFormData) {
  if (formData.sameAsCampaignDates) {
    return RegistrationExperienceBaseSchema;
  }

  const isSingleDateType = formData.dateType === DateType.Single;

  if (isSingleDateType) {
    return RegistrationExperienceWithStartDateSchema;
  }

  return RegistrationExperienceSchemaEndDateTime;
}

export function getExperienceMaxStartDate(
  experienceEndDate: string | null | undefined,
  campaignEndDate: string | null | undefined,
): DateTime | null {
  return toDateTimeFromISO(experienceEndDate || campaignEndDate);
}

export function getExperienceMinStartDate(
  campaignStartDate: string | null | undefined,
): DateTime | null {
  return toDateTimeFromISO(campaignStartDate);
}

export function getExperienceMinEndDate(
  experienceStartDate: string | null | undefined,
  campaignStartDate: string | null | undefined,
): DateTime | null {
  return toDateTimeFromISO(experienceStartDate || campaignStartDate);
}

export function getExperienceMaxEndDate(
  campaignEndDate: string | null | undefined,
): DateTime | null {
  return toDateTimeFromISO(campaignEndDate);
}
