import React from 'react';

import {
  TableRowAction,
  TableData,
  TableColumn,
  UserAvatarFullNameInline,
} from '@vizsla/components';
import { DonationAllocation, IndividualFundraising, TeamFundraising } from '@vizsla/graphql';
import { formatDate, formatMoney } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';

interface CampaignDonationsTableProps {
  loading?: boolean;
  dataSource: DonationAllocation[];

  rowActions: TableRowAction[];
}

const COLUMNS: TableColumn<DonationAllocation>[] = [
  {
    title: 'donor',
    key: 'donor',

    render(root, donation) {
      return <UserAvatarFullNameInline user={donation.donor} />;
    },
  },
  {
    title: 'date',
    key: 'date',
    dataPath: 'createdAt',

    render(date: string) {
      return formatDate(date, DateFormatPatterns.shortMonthDayYear);
    },
  },
  {
    title: 'fundraiser',
    key: 'fundraiser',
    dataPath: 'individualFundraising',

    render(fundraiser: IndividualFundraising | null) {
      if (!fundraiser) return '-';
      return <UserAvatarFullNameInline user={fundraiser.attendee?.user} />;
    },
  },
  {
    title: 'team',
    key: 'team',
    dataPath: 'teamFundraising',

    render(team: TeamFundraising | null) {
      return team?.name ?? '-';
    },
  },
  {
    title: 'amount',
    key: 'amount',
    dataPath: 'amount',

    render(amount: number) {
      const formatted = formatMoney(amount, 2);

      return formatted;
    },
  },
];

export function CampaignDonationsTable(props: CampaignDonationsTableProps) {
  return <TableData columns={COLUMNS} dataSource={props.dataSource} />;
}
