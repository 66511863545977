import { create } from 'zustand';

import { TransactionVizslaViewExp } from '@vizsla/graphql';

interface State {
  data?: TransactionVizslaViewExp;
  loading: boolean;
}

interface Store extends State {
  setLoading(Loading: boolean): void;
  setData(data: TransactionVizslaViewExp): void;

  clean(): void;
}

export const useSelectedTransactionRow = create<Store>(set => {
  const initial: State = {
    data: undefined,
    loading: false,
  };

  function setLoading(loading: boolean) {
    set({ loading });
  }

  function setData(data: TransactionVizslaViewExp) {
    set({ data, loading: false });
  }

  function clean() {
    set({ ...initial });
  }

  return {
    ...initial,

    setLoading,
    setData,

    clean,
  };
});
