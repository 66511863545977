import React from 'react';
import { Collapse, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { HtmlRenderer } from '@vizsla/components';
import { CampaignFaq } from '@vizsla/graphql';

import { PaletteColor, Shade } from 'src/theme';

const ANIMATION_DURATION = 200;

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: theme.spacing(1),
  },
  collapseBody: {
    width: '8px',
    height: '8px',
    transition: `all ${ANIMATION_DURATION}ms ease-in-out`,
    borderColor: Shade.Blue[500],
    borderStyle: 'solid',
    borderWidth: '2px 0 0 2px',
    transformOrigin: 'center',
    transform: 'translateX(-1px) rotate(135deg)',
    background: 'transparent',
    '&.opened': {
      transform: 'translateY(-1px) rotate(225deg)',
    },
  },
  switcherWrapBtn: {
    outline: 'none',
    cursor: 'pointer',
    width: '32px',
    height: '32px',
    border: '0px solid transparent',
    borderRadius: '50%',
    background: 'transparent',
    transition: `all ${ANIMATION_DURATION}ms ease-in-out`,
    '&:hover': {
      background: PaletteColor.PrimaryTeal,
      '&>*': {
        borderColor: '#FFFFFF',
      },
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  answerWrap: {
    padding: theme.spacing(0, 1, 1, 6),
  },
  question: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface CampaignFaqTableRecordProps {
  campaignFaq: CampaignFaq;
}

export const CampaignFaqTableRecord: React.FC<CampaignFaqTableRecordProps> = ({ campaignFaq }) => {
  const classes = useStyles();

  const [opened, setOpened] = React.useState<boolean>(false);

  const renderCollapseSwitcher = () => {
    return (
      <button
        type="button"
        className={classes.switcherWrapBtn}
        onClick={() => setOpened(isOpen => !isOpen)}
      >
        <div className={clsx({ [classes.collapseBody]: true, opened })} />
      </button>
    );
  };

  return (
    <Grid>
      <Grid container justifyContent="flex-start" alignItems="center" className={classes.header}>
        <Grid item marginRight={2}>
          {renderCollapseSwitcher()}
        </Grid>
        <Grid item className={classes.question}>
          {campaignFaq?.question}
        </Grid>
      </Grid>
      <Collapse in={opened} collapsedSize={0} timeout={ANIMATION_DURATION}>
        <Grid className={classes.answerWrap}>
          <HtmlRenderer html={campaignFaq?.answer as string} />
        </Grid>
      </Collapse>
    </Grid>
  );
};
