import React from 'react';
import {
  DashboardOutlined as DashboardOutlinedIcon,
  SubjectOutlined as SubjectOutlinedIcon,
  Category as CategoryIcon,
  Wallpaper as WallpaperIcon,
  FaceOutlined,
  LocalOfferOutlined,
  SupervisedUserCircleOutlined,
} from '@mui/icons-material';

import { useModal } from '@vizsla/hooks';
import { buildUrl } from '@vizsla/utils';
import { Experience } from '@vizsla/graphql';

import { useExperiences } from 'src/hooks/experiences';
import { AddExperienceButton } from 'src/layouts';
import { CampaignMenu, CampaignMenuItemHeader } from 'src/components/campaign';
import * as entitiesId from 'src/constants/entities-id';
import * as appRoutes from 'src/constants/routes';
import { MODALS } from 'src/constants/modals';
import { useCampaignId } from 'src/hooks/campaign';

export const RegistrationAndTicketingCampaignMenu: React.FC = () => {
  const campaignId = useCampaignId();
  const { data: experiences } = useExperiences(campaignId);
  const { openModal } = useModal();

  const onAddExperience = () => {
    openModal(MODALS.EXPERIENCE_CREATE_MODAL);
  };

  const CAMPAIGN_MENU_ITEMS = [
    {
      to: buildUrl(appRoutes.campaignDashboard, { [entitiesId.campaign]: campaignId }),
      text: 'Dashboard',
      icon: <DashboardOutlinedIcon />,
    },
    {
      to: buildUrl(appRoutes.campaignBasics, { [entitiesId.campaign]: campaignId }),
      text: 'Basics',
      icon: <SubjectOutlinedIcon />,
    },
  ];

  const EXPERIENCE_MENU_ITEMS = experiences.map((experience: Experience) => {
    const campaignExperiencesUrl = buildUrl(appRoutes.campaignExperience, {
      [entitiesId.campaign]: campaignId,
      [entitiesId.experience]: experience.id as string,
    });

    return {
      to: campaignExperiencesUrl,
      text: `${experience.name}`,
      icon: <CategoryIcon />,
    };
  });

  const DESIGN_CAMPAIGN_MENU = [
    {
      to: buildUrl(appRoutes.campaignPages, { [entitiesId.campaign]: campaignId }),
      text: 'Campaign Pages',
      icon: <WallpaperIcon />,
    },
  ];

  const MANAGE_CAMPAIGN_MENU_ITEMS = [
    {
      to: buildUrl(appRoutes.campaignPeople, { [entitiesId.campaign]: campaignId }),
      text: 'People',
      icon: <FaceOutlined />,
    },
    {
      to: buildUrl(appRoutes.campaignDiscounts, { [entitiesId.campaign]: campaignId }),
      text: 'Discounts',
      icon: <LocalOfferOutlined />,
    },
    {
      to: buildUrl(appRoutes.campaignAdministration, { [entitiesId.campaign]: campaignId }),
      text: 'Administration',
      icon: <SupervisedUserCircleOutlined />,
    },
  ];

  return (
    <React.Fragment>
      <CampaignMenuItemHeader>CAMPAIGN</CampaignMenuItemHeader>
      <CampaignMenu items={CAMPAIGN_MENU_ITEMS} />
      <React.Fragment>
        <CampaignMenuItemHeader>EXPERIENCES</CampaignMenuItemHeader>
        <CampaignMenu items={EXPERIENCE_MENU_ITEMS} />
        <AddExperienceButton onClick={onAddExperience}>Add experience</AddExperienceButton>
      </React.Fragment>
      <React.Fragment>
        <CampaignMenuItemHeader>DESIGN</CampaignMenuItemHeader>
        <CampaignMenu items={DESIGN_CAMPAIGN_MENU} />
      </React.Fragment>
      <React.Fragment>
        <CampaignMenuItemHeader>MANAGE</CampaignMenuItemHeader>
        <CampaignMenu items={MANAGE_CAMPAIGN_MENU_ITEMS} />
      </React.Fragment>
    </React.Fragment>
  );
};
