import React from 'react';
import { Grid } from '@mui/material';

import { Modal, Field, TextField } from '@vizsla/components';

import { StoreAsseInventoryFormFields } from 'src/types/storeAssets';

const FormAdditionalInfoSection: React.FC = () => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Modal.ContentText>Additional Information</Modal.ContentText>
      </Grid>

      <Grid item xs={12}>
        <Field
          fullWidth
          name={StoreAsseInventoryFormFields.NOTES}
          label="Notes"
          component={TextField}
        />
      </Grid>
    </React.Fragment>
  );
};

export { FormAdditionalInfoSection };
