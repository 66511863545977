import React from 'react';
import { Box, styled } from '@mui/material';
import {
  PersonOutlined as PersonIcon,
  GroupsOutlined as GroupsIcon,
  PaymentOutlined as PaymentIcon,
  MonetizationOnOutlined as MonetizationIcon,
} from '@mui/icons-material';

import { formatNumber } from '@vizsla/utils';

import { InformationCard } from 'src/components/shared';
import { useOrganizationStats } from 'src/hooks/peopleCRM';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  gap: theme.spacing(2),
  '& > div': {
    flex: '1 1 0px',
  },
}));

const PeopleCRMInfoCards: React.FC = () => {
  const { loading, data: stats } = useOrganizationStats();

  return (
    <StyledBox>
      <InformationCard
        loading={loading}
        icon={<PersonIcon color="secondary" fontSize="large" />}
        title="Total Attendees"
        value={formatNumber(stats?.attendeesCount.count || 0)}
      />

      <InformationCard
        loading={loading}
        icon={<PaymentIcon color="secondary" fontSize="large" />}
        title="Total Donors"
        value={formatNumber(stats?.donorsCount.count || 0)}
      />

      <InformationCard
        loading={loading}
        icon={<MonetizationIcon color="secondary" fontSize="large" />}
        title="Total Fundraisers"
        value={formatNumber(stats?.fundraisersCount.count || 0)}
      />

      <InformationCard
        loading={loading}
        icon={<GroupsIcon color="secondary" fontSize="large" />}
        title="Total Teams"
        value={formatNumber(stats?.teamsCount.count || 0)}
      />
    </StyledBox>
  );
};

export { PeopleCRMInfoCards };
