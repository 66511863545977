import React from 'react';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormApi } from 'final-form';
import { Grid } from '@mui/material';

import { useModal, useNotification } from '@vizsla/hooks';
import { TextField, Button } from '@vizsla/components';
import { ExperienceBibPool } from '@vizsla/graphql';
import { validateWithSchema } from '@vizsla/utils';

import { useExperienceBibAssignment, useExperienceId } from 'src/hooks/experiences';
import { useRegistrationOptions } from 'src/hooks/registrationOption';
import { ExperienceBibPoolFormSchema } from 'src/constants/validationSchemas/bibAssignment';
import { MODALS } from 'src/constants/modals';
import { t } from 'src/utils/template';

import { getBibPoolInitialValues, getPreparedData } from '../utils/experienceBibPoolFormUtils';
import { ExperienceBibPoolFormAllocationSection } from './ExperienceBibPoolFormAllocationSection';
import { ExperienceBibPoolFormRangesSection } from './ExperienceBibPoolFormRangesSection';

export const ExperienceBibPoolEditForm: React.FC = () => {
  const experienceId = useExperienceId();
  const notification = useNotification();
  const { closeModal, args } = useModal(MODALS.BIB_POOL_UPDATE_MODAL);

  const { registrationOptions: registrationOptionsList, loading: registrationOptionsLoading } =
    useRegistrationOptions(experienceId);
  const { updateBibPool } = useExperienceBibAssignment(experienceId);

  const currentBibPool = args?.bibPool as ExperienceBibPool;
  const initialValues = getBibPoolInitialValues(currentBibPool);

  const onSubmit = async (formData: Record<string, any>, form: FormApi) => {
    const preparedData = getPreparedData(formData, registrationOptionsList);
    const bibPoolId = currentBibPool?.id;
    const prevRegistrationOptionId = currentBibPool?.registrationOption?.id || null;
    if (bibPoolId) {
      try {
        await updateBibPool({
          validatedData: {
            ...preparedData,
          },
          bibPoolId,
          prevRegistrationOptionId,
        });

        closeModal();
        form.reset();
        notification.success(t('experience_bib_pool_update_success'));
      } catch (error) {
        notification.error(t('experience_bib_pool_update_error'));
      }
    } else {
      notification.error(t('experience_bib_pool_update_error'));
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{ submitting: true, initialValues: true }}
      validate={values => validateWithSchema(ExperienceBibPoolFormSchema, values)}
      mutators={{
        ...arrayMutators,
      }}
      keepDirtyOnReinitialize
    >
      {({ submitting, pristine, handleSubmit, form }) => {
        const onCloseModal = () => {
          closeModal();
          form.reset();
        };
        return (
          <form>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Field
                  name="name"
                  label="Bib Pool Name"
                  component={TextField}
                  disabled={submitting}
                  variant="outlined"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <ExperienceBibPoolFormRangesSection />
              </Grid>
              <Grid item xs={12}>
                <ExperienceBibPoolFormAllocationSection />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button disabled={submitting} variant="text" onClick={onCloseModal}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      loading={submitting}
                      disabled={pristine || registrationOptionsLoading}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
};
