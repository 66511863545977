import React from 'react';
import { Grid, List, ListItem, SxProps } from '@mui/material';

import { FontSize } from '@vizsla/theme';
import { ExperienceLandingActions, useLandingState } from '@vizsla/providers';
import { EditorTextInputFieldSizeType } from '@vizsla/constants';

import { VisibilityButton } from 'src/components/shared';

import { EditorTextInputField } from '../EditorTextInputField';

export const OurStorySection = () => {
  const { state, dispatch } = useLandingState();

  const isOurStoryTitleVisible = state.theme.isOurStoryTitleVisible ?? true;
  const isOurStoryDescriptionVisible = state.theme.isOurStoryDescriptionVisible ?? true;
  const { ourStorySectionTitle, ourStoryDescription } = state.theme;

  const ourStorySectionTitleSxProp: SxProps = {
    width: '336px',
    height: '40px',
    padding: '4px',
    fontSize: FontSize.Default,
  };

  const ourStorySectionDescriptionSxProp: SxProps = {
    width: '336px',
    fontSize: FontSize.Default,
  };

  const handleTitleInputFieldChange = (ourStorySection: string) =>
    dispatch({
      type: ExperienceLandingActions.updateOurStorySectionTitle,
      payload: ourStorySection,
    });

  const handleDescriptionInputFieldChange = (ourStorySectionDescription: string) =>
    dispatch({
      type: ExperienceLandingActions.updateOurStorySectionDescription,
      payload: ourStorySectionDescription,
    });

  return (
    <List component="div">
      <ListItem disablePadding sx={theme => ({ paddingBottom: theme.spacing(2) })}>
        <Grid container spacing={1}>
          <Grid item>
            <EditorTextInputField
              onChange={handleTitleInputFieldChange}
              fieldSize={EditorTextInputFieldSizeType.small}
              fieldValue={ourStorySectionTitle}
              fieldLabel="Title"
              inputProps={{
                sx: ourStorySectionTitleSxProp,
              }}
            />
          </Grid>
          <Grid item>
            <VisibilityButton
              isVisible={isOurStoryTitleVisible}
              onClick={() =>
                dispatch({
                  type: ExperienceLandingActions.updateisOurStoryTitleVisible,
                  payload: !isOurStoryTitleVisible,
                })
              }
            />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem disablePadding sx={theme => ({ paddingBottom: theme.spacing(3) })}>
        <Grid container spacing={1}>
          <Grid item>
            <EditorTextInputField
              onChange={handleDescriptionInputFieldChange}
              fieldSize={EditorTextInputFieldSizeType.small}
              fieldValue={ourStoryDescription}
              fieldLabel="Description"
              inputProps={{
                sx: ourStorySectionDescriptionSxProp,
              }}
              rows={3}
              maxRows={4}
              multiline
            />
          </Grid>
          <Grid item>
            <VisibilityButton
              isVisible={isOurStoryDescriptionVisible}
              onClick={() =>
                dispatch({
                  type: ExperienceLandingActions.updateisOurStoryDescriptionVisible,
                  payload: !isOurStoryDescriptionVisible,
                })
              }
            />
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );
};
