import React from 'react';
import { Form } from 'react-final-form';
import { useMutation } from '@apollo/client';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  styled,
} from '@mui/material';

import { useModal, useNotification } from '@vizsla/hooks';
import { Button, TextField, NumberField, SelectField, Field } from '@vizsla/components';
import { validateWithSchema } from '@vizsla/utils';
import { USA_STATES_OPTIONS } from '@vizsla/constants';

import { t } from 'src/utils/template';
import { MODALS } from 'src/constants/modals';
import { VizslaOrganizationCreateSchema } from 'src/constants/validationSchemas/organization';
import { VIZSLA_ORGANIZATION_CREATE_MUTATION } from 'src/graphql';
import { ImageInputField } from 'src/components/forms';

const StyledImageGrid = styled(Grid)(() => ({
  '& > *': {
    '& > *': {
      margin: '0 auto',
    },
  },
}));

const OrganizationCreateModal: React.FC = () => {
  const notification = useNotification();

  const [isLoading, setisLoading] = React.useState(false);
  const { isOpen, closeModal } = useModal(MODALS.ORGANIZATION_CREATE_MODAL);

  const [inviteOrganization, { loading }] = useMutation(VIZSLA_ORGANIZATION_CREATE_MUTATION, {
    refetchQueries: ['OrganizationsList'],
    awaitRefetchQueries: true,
  });

  const INITIAL_VALUES = {
    contactNumber: {
      code: '1',
    },
    contactAddress: {
      country: 'United States',
    },
  };

  React.useEffect(() => setisLoading(false), [isOpen]);

  const onCloseModal = () => {
    closeModal();
  };

  const onSubmit = async (formData: any) => {
    setisLoading(true);

    const logo = {
      create: {
        fileId: formData.logo.fileId,
        filename: formData.logo.filename,
        meta: formData.logo.meta,
      },
    };

    const data = {
      ...formData,
      logo,
    };

    try {
      await inviteOrganization({
        variables: {
          data,
        },
      });
      notification.success('organization create success');
      setisLoading(false);
      closeModal();
    } catch (err) {
      if (err instanceof Error) {
        notification.warning(err.message);
      }
      setisLoading(false);
      notification.error(t('organization_create_error'));
      console.error(err);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onCloseModal}>
      <DialogTitle>Create Organization</DialogTitle>
      <Form
        onSubmit={onSubmit}
        initialValues={INITIAL_VALUES}
        validate={values => validateWithSchema(VizslaOrganizationCreateSchema, values)}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
          const isSubmitDisabled = loading || submitting || pristine || hasValidationErrors;

          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  <StyledImageGrid item xs={12}>
                    <Field
                      fullWidth
                      name="logo"
                      label="Add Image"
                      width={250}
                      height={250}
                      component={ImageInputField}
                      disabled={submitting}
                      imageDim={[200, 200]}
                    />
                  </StyledImageGrid>
                  <Grid item xs={12}>
                    <DialogContentText variant="subtitle2">General</DialogContentText>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="name"
                      label="Organization Name"
                      required
                      component={TextField}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="ein"
                      label="EIN Number"
                      required
                      isNumericString
                      formatMask="##-#######"
                      component={NumberField}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DialogContentText variant="subtitle2">Contact Information</DialogContentText>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="firstName"
                      label="First Name"
                      required
                      component={TextField}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="lastName"
                      label="Last Name"
                      required
                      component={TextField}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field fullWidth name="email" label="Email" required component={TextField} />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="contactPhone.number"
                      label="Phone Number"
                      required
                      formatMask="+1 (###) ###-####"
                      isNumericString
                      component={NumberField}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DialogContentText variant="subtitle2">Address</DialogContentText>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name="contactAddress.street1"
                      label="Address"
                      required
                      component={TextField}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="contactAddress.city"
                      label="City"
                      required
                      component={TextField}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="contactAddress.state"
                      label="State/Province"
                      required
                      component={SelectField}
                      options={USA_STATES_OPTIONS}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="contactAddress.zip"
                      label="Zip Code"
                      required
                      isNumericString
                      formatMask="#####"
                      component={NumberField}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="contactAddress.country"
                      label="Country"
                      required
                      disabled
                      component={TextField}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button variant="text" onClick={onCloseModal}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit} disabled={isSubmitDisabled} loading={loading}>
                  Create Organization
                </Button>
              </DialogActions>
            </form>
          );
        }}
      </Form>
    </Dialog>
  );
};

export default OrganizationCreateModal;
