import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useFormState, useForm } from 'react-final-form';
import _ from 'lodash';

import { formatMoney } from '@vizsla/utils';
import { Field, NumberField, Button } from '@vizsla/components';

import { MONEY_FORMAT } from 'src/constants/formats';
import {
  ExperienceStoreAssetFormFields,
  ExperienceStoreAssetAddOrUpdateFormValues,
} from 'src/types/storeAssets';
import type { SwagBagAssetAddOrUpdateFormValues } from 'src/types/registrationOption';

interface FormSearchSectionSwagBagProps {
  handleSubmit: (event) => void;
}

const FormPriceSection: React.FC<FormSearchSectionSwagBagProps> = ({ handleSubmit }) => {
  const { values } = useFormState<ExperienceStoreAssetAddOrUpdateFormValues>();
  const { reset } = useForm<SwagBagAssetAddOrUpdateFormValues>();

  const selectedStoreAsset = values[ExperienceStoreAssetFormFields.STORE_ASSET];

  if (_.isNil(selectedStoreAsset)) {
    return null;
  }

  const submitAndReset = event => {
    handleSubmit(event);
    reset();
  };

  return (
    <React.Fragment>
      <Grid item xs={12} mt={2}>
        <Field
          fullWidth
          name={ExperienceStoreAssetFormFields.PRICE}
          label="Price ($)"
          required
          component={NumberField}
          numberFormat={MONEY_FORMAT}
        />
      </Grid>

      <Grid item xs={12} mt={0.5} textAlign="right">
        <Typography variant="body2">
          Recommended price: {formatMoney(selectedStoreAsset.price, 2)}
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="center" spacing={1}>
        <Button color="info" variant="contained" onClick={() => reset()}>
          Cancel
        </Button>
        <Button onClick={submitAndReset}>Save</Button>
      </Grid>
    </React.Fragment>
  );
};

export { FormPriceSection };
