import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Avatar,
  Button,
  Link,
  Dialog,
  Menu,
  MenuItem,
  Chip,
  Tab,
  Tabs,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Typography,
  Radio,
  Switch,
  Select,
  RadioGroup,
  Checkbox,
  Divider,
  TextField,
  FormGroup,
  FormControl,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
  FormControlLabel,
  Paper,
  Tooltip,
} from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Form } from 'react-final-form';

import { useNotification, useMultiSelectField } from '@vizsla/hooks';
import {
  USERS_LIST_QUERY,
  UsersListQuery,
  CampaignListResponse,
  Campaign,
  User,
} from '@vizsla/graphql';
import {
  Field,
  NumberField,
  CheckboxField,
  DateField,
  DateTimeField,
  RadioGroupField,
  SelectField,
  DataBaseMultiSelectField,
  useDataBaseSelectField,
} from '@vizsla/components';

import { RichTextExample } from 'src/components/shared';
import { UserChip } from 'src/components/user';
import { SectionExpanderField } from 'src/components/forms';
import { CAMPAIGN_LIST_QUERY } from 'src/graphql';
import { getUserFullName } from 'src/utils/user';
import { t } from 'src/utils/template';

const useStyles = makeStyles(() => ({
  column: {
    padding: '1rem 0.5rem',
  },
}));

export const SampleMuiComponent = () => {
  const classes = useStyles();

  const notification = useNotification();

  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [selectValue, setSelectValue] = React.useState<string>('');
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [activeToggle, setActiveToggle] = React.useState<string>('one');

  const { data } = useQuery<{ campaignsList: CampaignListResponse }>(CAMPAIGN_LIST_QUERY);
  const campaigns = data?.campaignsList?.items || [];

  const dataBaseMultiSelectFieldProps = useDataBaseSelectField<UsersListQuery, User>(
    {
      query: USERS_LIST_QUERY,
      getQueryItems: 'usersList.items',
    },
    {
      getOptionLabel: (user: User) => getUserFullName(user),
      renderTag: (user: User) => {
        return <UserChip key={user?.id as string} user={user} sx={{ m: '2px' }} />;
      },
      mapValues: (users: Array<User>) => users.map(user => user.id),
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ...multiSelectFieldProps } = useMultiSelectField<Campaign>({
    options: campaigns,
    getOptionLabel: (campaign: Campaign) => campaign.name as string,
    mapValues: campaigns => campaigns.map(campaign => campaign.id),
  });

  const anchorEl = React.useRef(null);

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSelect = (e: any) => {
    setSelectValue(e.target.value);
  };

  const handleTabSwitch = (_: React.ChangeEvent<any>, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleButtonToggle = (_: React.ChangeEvent<any>, newButton: string) => {
    setActiveToggle(newButton);
  };

  enum ToastTypes {
    info,
    success,
    error,
    warn,
  }

  const handleShowToast = (type: ToastTypes) => {
    switch (type) {
      case ToastTypes.info:
        notification.info(t('campaign_create_success'));
        break;
      case ToastTypes.success:
        notification.success(t('campaign_create_success'));
        break;
      case ToastTypes.error:
        notification.error(t('campaign_create_error'));
        break;
    }
  };

  return (
    <Grid container>
      <Grid item container xs={12}>
        <Grid item container xs={8} direction="column" className={classes.column}>
          <Grid item>
            <Typography variant="h1">Heading 1</Typography>
            <Typography variant="h2">Heading 2</Typography>
            <Typography variant="h3">Heading 3</Typography>
            <Typography variant="h4">Heading 4</Typography>
            <Typography variant="h5">Heading 5</Typography>
            <Typography variant="h6">Heading 6</Typography>
            <Typography variant="display1">Display 1</Typography>
            <Typography variant="display2">Display 2</Typography>
            <Typography variant="stats">Stats</Typography>
            <Typography variant="body1">
              Body1: Lorem ipsum, dolor sit amet consectetur adipisicing elit. Asperiores dolore
              architecto at esse eius explicabo incidunt in tenetur impedit repellat.
            </Typography>
            <Typography variant="body2">
              Body2: Lorem ipsum dolor sit amet consectetur, adipisicing elit. Temporibus vero vel
              debitis, commodi tenetur esse libero labore provident optio ad!
            </Typography>
            <Typography variant="subtitle1">Subtitle 1</Typography>
            <Typography variant="subtitle2">Subtitle 2</Typography>
            <Typography variant="tabs">Tabs</Typography>
            <br />
            <Typography variant="tablehead">Table Head</Typography>
            <br />
            <Typography variant="caption">Caption</Typography>
            <br />
            <Typography variant="button">Button</Typography>
            <br />
            <Typography variant="hyperlink">Link Typography</Typography>
            <br />
            <Link href="#">Hyperlink</Link>
          </Grid>

          <br />
          <Divider />
          <Typography variant="h4">Buttons</Typography>
          <br />

          <Grid item>
            <Button variant="text" size="small" color="primary">
              Text Small Primary
            </Button>
            <Button variant="text" size="large" color="primary">
              Text Large Primary
            </Button>
            <Button variant="contained" size="small" color="primary">
              Contained Small Primary
            </Button>
            <Button variant="contained" size="large" color="primary">
              Contained Large Primary
            </Button>
            <Button variant="contained" size="small" color="primary" disabled>
              Contained Small Disabled
            </Button>
            <Button variant="contained" size="large" color="primary" disabled>
              Contained Large Disabled
            </Button>
          </Grid>

          <br />
          <Divider />
          <br />

          <Grid item>
            <Button variant="text" size="small" color="secondary">
              Text Small Secondary
            </Button>
            <Button variant="text" size="large" color="secondary">
              Text Large Secondary
            </Button>
            <Button variant="contained" size="small" color="secondary">
              Contained Small Secondary
            </Button>
            <Button variant="contained" size="large" color="secondary">
              Contained Large Secondary
            </Button>
            <Button variant="contained" size="small" color="secondary" disabled>
              Contained Small Disabled
            </Button>
            <Button variant="contained" size="large" color="secondary" disabled>
              Contained Large Disabled
            </Button>
          </Grid>

          <br />
          <Divider />
          <br />

          <Grid item>
            <Button variant="text" size="small" color="error">
              Text Small Error
            </Button>
            <Button variant="text" size="large" color="error">
              Text Large Error
            </Button>
            <Button variant="contained" size="small" color="error">
              Contained Small Error
            </Button>
            <Button variant="contained" size="large" color="error">
              Contained Large Error
            </Button>
            <Button variant="contained" size="small" color="error" disabled>
              Contained Small Disabled
            </Button>
            <Button variant="contained" size="large" color="error" disabled>
              Contained Large Disabled
            </Button>
          </Grid>

          <br />
          <Divider />
          <br />

          <Grid item>
            <Button variant="text" size="small" color="info">
              Text Small Info
            </Button>
            <Button variant="text" size="large" color="info">
              Text Large Info
            </Button>
            <Button variant="contained" size="small" color="info">
              Contained Small Info
            </Button>
            <Button variant="contained" size="large" color="info">
              Contained Large Info
            </Button>
            <Button variant="text" size="small" color="info" disabled>
              Text Small Disabled
            </Button>
            <Button variant="text" size="large" color="info" disabled>
              Text Large Disabled
            </Button>
            <Button variant="contained" size="small" color="info" disabled>
              Contained Small Disabled
            </Button>
            <Button variant="contained" size="large" color="info" disabled>
              Contained Large Disabled
            </Button>
          </Grid>

          <br />
          <Divider />
          <br />

          <Grid item>
            <Typography variant="h2">Toast Messages</Typography>
            <Button onClick={() => handleShowToast(ToastTypes.info)}>Info</Button>
            <Button onClick={() => handleShowToast(ToastTypes.success)}>Success</Button>
            <Button onClick={() => handleShowToast(ToastTypes.error)}>Error</Button>
            <Button onClick={() => handleShowToast(ToastTypes.warn)}>Warn</Button>
          </Grid>

          <br />
          <Divider />
          <br />

          <Grid item>
            <ToggleButtonGroup exclusive value={activeToggle} onChange={handleButtonToggle}>
              <ToggleButton value="one" disabled={activeToggle === 'one'}>
                Option One
              </ToggleButton>
              <ToggleButton value="two" disabled={activeToggle === 'two'}>
                Option Two
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          <br />
          <Divider />
          <Typography variant="h4">Popups</Typography>
          <br />

          <Grid item>
            <Button variant="text" size="small" onClick={handleMenuOpen} ref={anchorEl}>
              Open Popup
            </Button>

            <Menu open={menuOpen} onClose={handleMenuClose} anchorEl={anchorEl.current}>
              <MenuItem>
                <AccessTime />
                First Menu Item
              </MenuItem>
              <MenuItem>Second Menu Item</MenuItem>
              <Divider />
              <MenuItem disabled>Third Menu Item</MenuItem>
              <MenuItem>
                <Avatar src="" alt="Nikita" />
                Menu Item With Avatar
              </MenuItem>
            </Menu>
          </Grid>

          <br />

          <Grid item>
            <Button variant="text" size="small" onClick={handleDialogOpen}>
              Open Dialog
            </Button>

            <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="md">
              <Grid container direction="column" style={{ padding: 20 }}>
                <Grid item xs={12} style={{ marginBottom: 16 }}>
                  <Typography variant="h3" align="center">
                    Sample Dialog!
                  </Typography>
                </Grid>

                <Grid item container xs={12}>
                  <Button variant="text" size="large" onClick={handleDialogClose}>
                    Close Dialog
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={handleDialogClose}
                  >
                    Close but different color
                  </Button>
                </Grid>
              </Grid>
            </Dialog>
          </Grid>

          <br />

          <Grid item>
            <Tooltip title="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, voluptatum?">
              <Button variant="text" size="small">
                Hover to Show Tooltip
              </Button>
            </Tooltip>
          </Grid>

          <br />
          <Typography variant="h4">Tabs</Typography>
          <br />

          <Tabs value={activeTab} onChange={handleTabSwitch}>
            <Tab label="First" />
            <Tab label="Second" />
            <Tab label="Third" />
          </Tabs>

          <br />
          <Typography variant="h4">Papers</Typography>
          <br />

          <Grid item>
            <Paper variant="elevation" elevation={1}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis quaerat veritatis,
              cumque adipisci optio unde maxime cum in rem repellat!
            </Paper>
            <br />
            <Paper variant="elevation" elevation={2}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis quaerat veritatis,
              cumque adipisci optio unde maxime cum in rem repellat!
            </Paper>
            <br />
            <Paper variant="elevation" elevation={3}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis quaerat veritatis,
              cumque adipisci optio unde maxime cum in rem repellat!
            </Paper>
            <br />
            <Paper variant="elevation" elevation={4}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis quaerat veritatis,
              cumque adipisci optio unde maxime cum in rem repellat!
            </Paper>
            <br />

            <Paper variant="dashed">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis quaerat veritatis,
              cumque adipisci optio unde maxime cum in rem repellat!
            </Paper>
            <br />

            <Paper variant="lightgray">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis quaerat veritatis,
              cumque adipisci optio unde maxime cum in rem repellat!
            </Paper>
            <br />

            <Paper variant="outlined">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis quaerat veritatis,
              cumque adipisci optio unde maxime cum in rem repellat!
            </Paper>

            <br />
          </Grid>
        </Grid>

        <Grid item xs={4} container direction="column" className={classes.column}>
          <Typography variant="h4">Text Fields</Typography>
          <br />
          <TextField variant="outlined" helperText="Some helper text" />
          <br />
          <TextField variant="outlined" label="Label" />
          <br />
          <TextField error variant="outlined" label="Error Text" helperText="Some Error" />
          <br />
          <TextField label="Disabled Text" disabled value="Some Value" />
          <br />

          <FormControl>
            <InputLabel id="select-label">Select Label</InputLabel>
            <Select labelId="select-label" value={selectValue} onChange={handleSelect}>
              <MenuItem value="somebody">Somebody</MenuItem>
              <MenuItem value="once">Once</MenuItem>
              <MenuItem value="told">Told</MenuItem>
              <MenuItem value="me">Me</MenuItem>
            </Select>
          </FormControl>

          <br />
          <Divider />
          <Typography variant="h4">Radio</Typography>
          <br />

          <RadioGroup>
            <FormControlLabel color="primary" label="First Radio" control={<Radio />} value="1" />
            <FormControlLabel
              disabled
              checked
              label="Disabled Radio"
              control={<Radio />}
              value="2"
            />
            <FormControlLabel label="Third Radio" control={<Radio />} value="3" />
          </RadioGroup>

          <br />
          <Divider />
          <Typography variant="h4">Checkbox</Typography>
          <br />

          <RadioGroup>
            <FormControlLabel label="First Check" control={<Checkbox />} value="1" />
            <FormControlLabel disabled label="Disabled Check" control={<Checkbox />} value="2" />
            <FormControlLabel
              label="Partial Check"
              control={<Checkbox indeterminate />}
              value="3"
            />
          </RadioGroup>

          <br />
          <Divider />
          <Typography variant="h4">Switch</Typography>
          <br />

          <FormGroup>
            <FormControlLabel label="Normal" control={<Switch checked onChange={() => {}} />} />
            <FormControlLabel label="Normal" control={<Switch onChange={() => {}} />} />
          </FormGroup>

          <br />
          <Divider />
          <Typography variant="h4">Final Form</Typography>
          <br />

          <Form
            // eslint-disable-next-line no-console
            onSubmit={(values: any) => console.log(JSON.stringify(values, null, 4))}
            render={({ handleSubmit, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid item xs={12}>
                    <Field name="field1" label="Field1" component={TextField} />
                  </Grid>
                  <br />

                  <Grid item xs={12}>
                    <Field
                      name="field1dis"
                      label="Field1Dis"
                      component={TextField}
                      value="Disabled Value"
                      disabled
                    />
                  </Grid>
                  <br />

                  <Grid item xs={12}>
                    <Field name="field2" label="Field2" component={NumberField} />
                  </Grid>
                  <br />

                  <Grid item xs={12}>
                    <Field
                      name="field3"
                      label="Options"
                      options={[{ label: '1', value: 'First' }]}
                      component={SelectField}
                      fullWidth
                    />
                  </Grid>
                  <br />

                  <Grid item xs={12}>
                    <Field
                      name="dataBaseMultiSelect"
                      label="DataBaseMultiSelectField"
                      component={DataBaseMultiSelectField}
                      fullWidth
                      {...dataBaseMultiSelectFieldProps}
                    />
                  </Grid>
                  <br />

                  <Grid item xs={12}>
                    <Field name="field4" label="DatePicker" component={DateField} fullWidth />
                  </Grid>
                  <br />

                  <Grid item xs={12}>
                    <Field
                      name="field5"
                      label="DatetimePicker"
                      component={DateTimeField}
                      fullWidth
                    />
                  </Grid>
                  <br />

                  <Grid item xs={12}>
                    <Field
                      name="field6"
                      label="CheckboxField"
                      component={CheckboxField}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      disabled
                      name="field6dis"
                      label="CheckboxFieldDisabled"
                      component={CheckboxField}
                      fullWidth
                    />
                  </Grid>
                  <br />

                  <Grid item xs={12}>
                    <Field
                      label="Radio Group"
                      name="radiogroup"
                      options={[
                        { label: 'first', value: '1' },
                        { label: 'second disabled', value: '2' },
                      ]}
                      component={RadioGroupField}
                      fullWidth
                    />
                  </Grid>
                  <br />

                  <Grid item xs={12}>
                    <SectionExpanderField
                      name="expanderField"
                      heading="My Section"
                      isActive={values.expanderField}
                      labelExpanded="Hide my text"
                      labelCollapsed="Show my text"
                      tooltip="Wha dkalafl lf lalafa;f lorem20"
                    >
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore fuga sed qui
                      aliquid ut, ratione eligendi facere blanditiis iusto nam?
                    </SectionExpanderField>
                  </Grid>

                  <Button type="submit">Submit</Button>
                </form>
              );
            }}
          />

          <br />
          <Divider />
          <Typography variant="h4">Chips</Typography>
          <br />

          <Chip label="Chip 1" />
          <Chip color="primary" label="Chip Primary" />
          <Chip color="secondary" label="Chip Secondary" />
          <Chip label="Chip Removable" variant="outlined" onDelete={() => {}} />
          <Chip
            avatar={<Avatar src="" alt="Nikita" />}
            label="Chip Removable With Avatar"
            variant="outlined"
            onDelete={() => {}}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} padding={2}>
        <Typography variant="h1">Rich Text Editor</Typography>
        <RichTextExample />
      </Grid>
      <Grid item xs={12} style={{ padding: 16 }}>
        <Typography variant="h3">Normal Table</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title 1</TableCell>
              <TableCell>Title 2</TableCell>
              <TableCell>Title 3</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow hover>
              <TableCell>Value 1</TableCell>
              <TableCell>Value 2</TableCell>
              <TableCell>Value 3</TableCell>
            </TableRow>

            <TableRow hover>
              <TableCell>Value 1</TableCell>
              <TableCell>Value 2</TableCell>
              <TableCell>Value 3</TableCell>
            </TableRow>

            <TableRow hover selected>
              <TableCell>Value 1</TableCell>
              <TableCell>Value 2</TableCell>
              <TableCell>Value 3</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
