import * as yup from 'yup';
import { isNil } from 'lodash';

import {
  StoreAssetFormFields,
  StoreAsseInventoryFormFields,
  ExperienceStoreAssetFormFields,
} from 'src/types/storeAssets';
import type { StoreAssetCreateOrUpdateFormValues } from 'src/types/storeAssets';

const nameSchema = yup.string().nullable().required('Asset name is required');

const imageSchema = yup.object().nullable().notRequired();

const descriptionSchema = yup.string().nullable().notRequired();

const sizeAttributeSchema = yup.string().required('Size attribute is required');

const styleAttributeSchema = yup.string().required('Style attribute is required');

const styleAttributesSchema = yup
  .array()
  .of(styleAttributeSchema)
  .min(1, 'At least one attribute must be selected')
  .nullable()
  .required('Style is required');

const storeAvailabilitySchema = yup
  .boolean()
  .nullable()
  .required()
  .test(
    'one-of-the-selling-methods-must-be-chosen',
    'One of the selling methods must be chosen',
    (storeAvailability: boolean | null | undefined, { parent }) => {
      const { swagbagAvailability } = parent as StoreAssetCreateOrUpdateFormValues;

      if (isNil(storeAvailability) || isNil(swagbagAvailability)) {
        return false;
      }

      return storeAvailability || swagbagAvailability;
    },
  );

const swagbagAvailabilitySchema = yup.boolean().nullable().required();
// .test(
//   'one-of-the-selling-methods-must-be-chosen',
//   'One of the selling methods must be chosen',
//   (swagbagAvailability: boolean | null | undefined, { parent }) => {
//     const { storeAvailability } = parent as StoreAssetCreateOrUpdateFormValues;

//     if (isNil(storeAvailability) || isNil(swagbagAvailability)) {
//       return false;
//     }

//     return storeAvailability || swagbagAvailability;
//   },
// );

const simplePriceSchema = yup
  .number()
  .nullable()
  .positive('Must be positive')
  .required('Price is required');

const priceSchema = yup
  .number()
  .nullable()
  .when([StoreAssetFormFields.STORE_AVAILABILITY, StoreAssetFormFields.SWAGBAG_AVAILABILITY], {
    is: (...booleans: boolean[]) => booleans.includes(true),
    then: yup
      .number()
      .nullable()
      .positive('Must be positive')
      .required('Price is required')
      .test(
        'must-be-greater-or-equal-than-asset-unit-cost',
        'Must be greater or equal than asset unit cost',
        (price: number | null | undefined, { parent }) => {
          const { unitCost } = parent as StoreAssetCreateOrUpdateFormValues;

          if (isNil(unitCost) || isNil(price)) {
            return false;
          }

          return price >= unitCost;
        },
      ),

    otherwise: yup.number().nullable().notRequired(),
  });

const unitCostSchema = yup
  .number()
  .nullable()
  .when([StoreAssetFormFields.STORE_AVAILABILITY, StoreAssetFormFields.SWAGBAG_AVAILABILITY], {
    is: (...booleans: boolean[]) => booleans.includes(true),
    then: yup
      .number()
      .nullable()
      .positive('Must be positive')
      .required('Required')
      .test(
        'must-be-less-or-equal-than-asset-recommended-price',
        'Must be less or equal than asset recommended price',
        (unitCost: number | null | undefined, { parent }) => {
          const { price } = parent as StoreAssetCreateOrUpdateFormValues;

          if (isNil(unitCost) || isNil(price)) {
            return false;
          }

          return price >= unitCost;
        },
      ),
    otherwise: yup.number().nullable().notRequired(),
  });

const quantitySchema = yup
  .number()
  .nullable()
  .positive('Must be positive')
  .required('Quantity is required');

const orderCostSchema = yup.number().nullable().positive('Must be positive').notRequired();

const continueSalesWhenOutOfStockSchema = yup.boolean().nullable().required();

const notifyWhenStockMinimumIsAchievedSchema = yup.boolean().nullable().required();

const stockMinimumBoundarySchema = yup
  .number()
  .nullable()
  .when(StoreAssetFormFields.NOTIFY_WHEN_STOCK_MINIMUM_IS_ACHIEVED, {
    is: true,
    then: yup
      .number()
      .nullable()
      .positive('Must be positive')
      .required('Stock minimum is required'),
    otherwise: yup.number().nullable().notRequired(),
  });

const vizslaUserSchema = yup.object();

const vizslaUsersSchema = yup.array().of(vizslaUserSchema).nullable().notRequired();

const dateSchema = yup.string().nullable().notRequired();

const noteSchema = yup.string().nullable().notRequired();

const storeAssetSchema = yup.object().required();

export const StoreAssetCreateOrUpdateFormSchema = yup.object({
  [StoreAssetFormFields.NAME]: nameSchema,
  [StoreAssetFormFields.IMAGE]: imageSchema,
  [StoreAssetFormFields.DESCRIPTION]: descriptionSchema,
  [StoreAssetFormFields.STORE_AVAILABILITY]: storeAvailabilitySchema,
  [StoreAssetFormFields.SWAGBAG_AVAILABILITY]: swagbagAvailabilitySchema,
  [StoreAssetFormFields.RECOMMENDED_PRICE]: priceSchema,
  [StoreAssetFormFields.UNIT_COST]: unitCostSchema,
  [StoreAssetFormFields.CONTINUE_SALES_wHEN_OUT_OF_STOCK]: continueSalesWhenOutOfStockSchema,
  [StoreAssetFormFields.NOTIFY_WHEN_STOCK_MINIMUM_IS_ACHIEVED]:
    notifyWhenStockMinimumIsAchievedSchema,
  [StoreAssetFormFields.STOCK_MINIMUM_BOUNDARY]: stockMinimumBoundarySchema,
  // TO DO [StoreAssetFormFields.STOCK_MINIMUM_RECIPIENTS]: vizslaUsersSchema,
  [StoreAssetFormFields.NOTES]: noteSchema,
});

export const StoreAssetUpdateImageFormSchema = yup.object({
  [StoreAssetFormFields.IMAGE]: imageSchema,
});

export const StoreAsseInventoryAddOrAdjustFormSchema = yup.object({
  [StoreAsseInventoryFormFields.QUANTITY]: quantitySchema,
  [StoreAsseInventoryFormFields.DATE_RECEIVED]: dateSchema,
  [StoreAsseInventoryFormFields.RECIPIENTS]: vizslaUsersSchema,
  [StoreAsseInventoryFormFields.ORDER_COST]: orderCostSchema,
  [StoreAsseInventoryFormFields.NOTES]: noteSchema,
});

export const ExperienceStoreAssetAddFormSchema = yup.object({
  [ExperienceStoreAssetFormFields.STORE_ASSET]: storeAssetSchema,
  [ExperienceStoreAssetFormFields.PRICE]: simplePriceSchema,
});
