import React from 'react';
import { Grid, styled } from '@mui/material';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';

import { useNotification } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { useStoreAssetUpdateMutation } from '@vizsla/graphql';
import { Modal, Field } from '@vizsla/components';

import { StoreAssetFormFields } from 'src/types/storeAssets';
import type { StoreAsset, StoreAssetUpdateImageFormValues } from 'src/types/storeAssets';
import { t } from 'src/utils/template';
import { StoreAssetUpdateImageFormSchema } from 'src/constants/validationSchemas/storeAssets';
import { ImageInputField } from 'src/components/forms';
import { useStoreAsset } from 'src/hooks/storeAssets';

import { getStoreAssetUpdateImageDataInput } from '../../utils';

const StyledFormNode = styled('form')(({ theme }) => ({
  display: 'contents',
}));

const StyledImageGridItem = styled(Grid)(({ theme }) => ({
  '& > *': {
    '& > *': {
      margin: '0 auto',
    },
  },
}));

interface StoreAssetUpdateImageFormProps {
  storeAsset: StoreAsset;
  closeModal: () => void;
}

const StoreAssetUpdateImageForm: React.FC<StoreAssetUpdateImageFormProps> = ({
  storeAsset,
  closeModal,
}) => {
  const { refetchAsset } = useStoreAsset();

  const notification = useNotification();

  const [storeAssetUpdateMutation, { loading }] = useStoreAssetUpdateMutation();

  const onSubmit = React.useCallback(
    async (formValues: StoreAssetUpdateImageFormValues, form: FormApi<any>) => {
      try {
        await storeAssetUpdateMutation({
          variables: {
            data: getStoreAssetUpdateImageDataInput(formValues),
            id: storeAsset.id ?? '',
          },
        });

        notification.success(t('store_asset_update_success'));
        refetchAsset();
        closeModal();
      } catch (err) {
        notification.error(t('store_asset_update_error'));
        console.error(err);
      }
    },
    [closeModal, storeAssetUpdateMutation, storeAsset, notification],
  );

  const validate = React.useCallback(async (formValues: StoreAssetUpdateImageFormValues) => {
    return validateWithSchema(StoreAssetUpdateImageFormSchema, formValues);
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting, pristine, validating, invalid }) => {
        const isSubmitLoading = submitting || validating || loading;
        const isSubmitDisabled = isSubmitLoading || pristine || invalid;

        return (
          <StyledFormNode onSubmit={handleSubmit}>
            <Grid container>
              <StyledImageGridItem item xs={12}>
                <Field
                  fullWidth
                  name={StoreAssetFormFields.IMAGE}
                  label="New Image"
                  width={200}
                  height={200}
                  component={ImageInputField}
                />
              </StyledImageGridItem>

              <Grid item xs={12} mt={2}>
                <Modal.Actions>
                  <Modal.Action
                    type="secondary"
                    content="Cancel"
                    onAction={closeModal}
                    disabled={isSubmitDisabled}
                  />

                  <Modal.Action
                    type="primary"
                    content="Save"
                    onAction={handleSubmit}
                    disabled={isSubmitDisabled}
                    loading={isSubmitLoading}
                  />
                </Modal.Actions>
              </Grid>
            </Grid>
          </StyledFormNode>
        );
      }}
    />
  );
};

export { StoreAssetUpdateImageForm };
