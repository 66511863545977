import React from 'react';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

type SocialNetworkName = 'Facebook' | 'Instagram' | 'Twitter' | 'Youtube' | 'LinkedIn';

interface OrganizationSocialMediaProps {
  type?: SocialNetworkName;
}

const SOCIAL_NETWORK_LOGOS: Record<SocialNetworkName, any> = {
  Facebook: <FacebookOutlinedIcon />,
  Instagram: <InstagramIcon />,
  Twitter: <TwitterIcon />,
  Youtube: <YouTubeIcon />,
  LinkedIn: <LinkedInIcon />,
};

export function OrganizationSocialMediaIcon(props: OrganizationSocialMediaProps) {
  const icon = React.useMemo(() => SOCIAL_NETWORK_LOGOS[props.type ?? 'Facebook'], [props.type]);

  return <React.Fragment>{icon}</React.Fragment>;
}
