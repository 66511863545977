import { DateTime } from 'luxon';
import _ from 'lodash';

import {
  concatDateWithTime,
  getNowDateTime,
  isDateGreaterOrEquals,
  toDateTimeFromISO,
  formatMoney,
  formatPercentage,
} from '@vizsla/utils';

import {
  DiscountAmountTypes,
  DiscountDatePickerTypes,
  DiscountStatusTypes,
} from 'src/types/shared';

export function getStatus(
  remaining: number | string,
  startDate: string,
  startTime: string,
  endDate?: string,
  endTime?: string,
): string {
  const concatenatedStartDate = concatDateWithTime(startDate, startTime);
  const concatenatedEndDate = concatDateWithTime(endDate, endTime);

  const formattedStartDate = toDateTimeFromISO(concatenatedStartDate);
  if (formattedStartDate?.isValid && DateTime.now().until(formattedStartDate).isValid) {
    return DiscountStatusTypes.scheduled;
  }

  const formattedEndDate = toDateTimeFromISO(concatenatedEndDate);
  if (formattedEndDate?.isValid && !DateTime.now().until(formattedEndDate).isValid) {
    return DiscountStatusTypes.expired;
  }

  if (remaining > 0 || remaining === 'Unlimited') {
    return DiscountStatusTypes.live;
  }

  return DiscountStatusTypes.completed;
}

export function getRemaining(
  totalUses: number | null | undefined,
  usesCount: number,
): number | string {
  if (_.isNil(totalUses)) {
    return 'Unlimited';
  }

  return totalUses - usesCount;
}

export function getFormattedAmount(
  amount: number | undefined,
  amountType: string | null | undefined,
): null | string {
  if (amountType === DiscountAmountTypes.dollar) {
    return formatMoney(amount);
  }

  return formatPercentage(amount);
}

export function getMaxStartDate(datePickerType: string, pickerEndDate: string): string | null {
  if (datePickerType === DiscountDatePickerTypes.dateRange && pickerEndDate) {
    return pickerEndDate;
  }

  return null;
}

export function getMinEndDate(datePickerType: string, pickerStartDate: string): string | null {
  if (datePickerType === DiscountDatePickerTypes.dateRange && pickerStartDate) {
    const isCurrentDateGreaterStartDate = isDateGreaterOrEquals(
      getNowDateTime(),
      pickerStartDate,
      'day',
    );
    if (!isCurrentDateGreaterStartDate) {
      return pickerStartDate;
    }
  }

  return getNowDateTime();
}

type ExpirationOutput = {
  isTimeEnded?: boolean;
  additionalMessage?: string;
  valueCount?: number;
  units?: string;
};

export function getExpiration(date?: string | null): ExpirationOutput {
  const formattedDate = toDateTimeFromISO(date);
  if (!formattedDate?.isValid) {
    return {
      additionalMessage: 'Not Set',
    };
  }

  const durationFromNow = formattedDate.diffNow(['days', 'hours', 'minutes']);

  if (!DateTime.now().until(formattedDate).isValid) {
    return {
      isTimeEnded: true,
      additionalMessage: '',
    };
  }

  if (durationFromNow.days > 0) {
    const daysFromNow = Math.floor(durationFromNow.days) + 1;
    return {
      isTimeEnded: false,
      valueCount: daysFromNow,
      units: 'days',
    };
  }
  if (durationFromNow.hours > 0) {
    const hoursFromNow = Math.floor(durationFromNow.hours) + 1;
    return {
      isTimeEnded: false,
      valueCount: hoursFromNow,
      units: 'hours',
    };
  }
  const minutesFromNow = Math.floor(durationFromNow.minutes) + 1;

  return {
    isTimeEnded: false,
    valueCount: minutesFromNow,
    units: minutesFromNow === 1 ? 'minute' : 'minutes',
  };
}
