import React from 'react';
import { Grid, Typography } from '@mui/material';

import { ExperienceLandingElementProps } from 'src/providers/campaign/ThankYouLanding.types';

export const Headline: React.FC<ExperienceLandingElementProps> = ({ experience }) => {
  return (
    <Grid item xs={12}>
      <Typography
        variant="h1"
        sx={{
          fontWeight: 900,
          textAlign: 'center',
        }}
      >
        THANK YOU!
      </Typography>
    </Grid>
  );
};
