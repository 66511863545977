import React from 'react';
import { Grid, Typography } from '@mui/material';

import { PaperBlock } from '@vizsla/components';

import { ExperienceImage, ExperienceLogoImage } from './ExperienceImage';
import { CampaignExperienceGallery } from './CampaignExperienceGallery';

export const CampaignExperienceImages: React.FC = () => {
  return (
    <PaperBlock title="Experience Images" collapsing defaultOpened>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6">Experience Header Image</Typography>
            </Grid>

            <Grid item>
              {/* NOTE: Renamed by VSL-1305 */}
              <ExperienceLogoImage />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6">Experience Image</Typography>
            </Grid>

            <Grid item>
              {/* NOTE: Renamed by VSL-1305 */}
              <ExperienceImage />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6">Experience Gallery Images</Typography>
            </Grid>

            <Grid item>
              <CampaignExperienceGallery />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PaperBlock>
  );
};
