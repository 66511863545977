import React, { useEffect, useMemo } from 'react';
import { Grid, LinearProgress } from '@mui/material';

import { usePagination } from '@vizsla/hooks';
import { useUserActivityLogQuery } from '@vizsla/graphql';
import { MODALS } from '@vizsla/constants';
import { useModal } from '@vizsla/hooks';
import { usePaginationQueryVariables } from '@vizsla/hooks';
import { useCustomFiltersQueryVariables } from '@vizsla/hooks';
import { useSearchQueryVariables } from '@vizsla/hooks';
import { PaginationProvider } from '@vizsla/providers';

import { ActivityTableview } from './ActivityTableView';

export function ActivityPageContent() {
  const { args } = useModal(MODALS.PEOPLE_CRM_CONTACT_INFO_MODAL);

  const userId = args?.userId || '';

  const pagination = usePagination();

  const searchQueryVariables = useSearchQueryVariables(['description']);

  const customFiltersQueryVariables = useCustomFiltersQueryVariables();
  const paginationQueryVariables = usePaginationQueryVariables();

  const { data: dataUserActivityLog, loading: loadingUserActivityLog } = useUserActivityLogQuery({
    variables: {
      ...customFiltersQueryVariables,
      ...searchQueryVariables,
      userId,
      ...paginationQueryVariables,
    },
    fetchPolicy: 'network-only',
  });

  const userActivityLog = useMemo(() => {
    return dataUserActivityLog?.activityLogsList.items ?? [];
  }, [dataUserActivityLog]);

  if (loadingUserActivityLog) {
    return (
      <Grid item>
        <LinearProgress color="secondary" />
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <PaginationProvider>
        <ActivityTableview
          activity={userActivityLog}
          pagination={pagination}
          loading={loadingUserActivityLog}
        />
      </PaginationProvider>
    </React.Fragment>
  );
}
