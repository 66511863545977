import { produce } from 'immer';
import { isObject } from './helpers';
/**
 * Deep merge two objects.
 * @param target
 * @param source
 */
export const mergeDeep = (target, source) => {
    return produce(target, draft => {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!(key in target)) {
                    draft[key] = source[key];
                }
                else {
                    draft[key] = mergeDeep(target[key], source[key]);
                }
            }
            else {
                draft[key] = source[key];
            }
        });
    });
};
