import { concatDateWithTime } from '@vizsla/utils';
import { TicketingOption, TicketingOptionUpdateInput } from '@vizsla/graphql';

import { getPricingSettingsUpdateData } from './pricingSettings';

type TicketingOptionWithFormState = TicketingOption & Record<string, any>;

export const composeTicketingOptionUpdateInput = (
  formData: TicketingOptionWithFormState,
): TicketingOptionUpdateInput => {
  return {
    ticketName: formData.ticketName,
    tableNumber: formData.tableNumber,
    description: formData.description,

    ageRequirement: formData.ageRequirementEnabled ? formData.ageRequirement : null,
    ageRequirementAsOfDate: formData.ageRequirementEnabled ? formData.ageRequirementAsOfDate : null,

    attendeesPerTicket: formData.ticketingLimitsEnabled ? formData.attendeesPerTicket : null,
    maximumPerOrder: formData.ticketingLimitsEnabled ? formData.maximumPerOrder : null,

    minimumGroupTickets: formData.groupTicketsEnabled ? formData.minimumGroupTickets : null,
    maximumGroupTickets: formData.groupTicketsEnabled ? formData.maximumGroupTickets : null,

    maximumAttendees: formData.attendeeCapEnabled ? formData.maximumAttendees : null,

    ticketingAvailabilityStartDateTime: formData.ticketingAvailabilityEnabled
      ? concatDateWithTime(
          formData?.ticketingAvailabilityStartDate,
          formData?.ticketingAvailabilityStartTime,
        )
      : null,

    ticketingAvailabilityEndDateTime: formData.ticketingAvailabilityEnabled
      ? concatDateWithTime(
          formData?.ticketingAvailabilityEndDate,
          formData?.ticketingAvailabilityEndTime,
        )
      : null,
    pricingSettings: getPricingSettingsUpdateData(formData.pricingSettings),
  };
};
