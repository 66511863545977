import React from 'react';
import { useMatch } from 'react-router-dom';

import { Application, SystemRoles } from '@vizsla/types';
import { useCurrentUser, useUserCurrentApp } from '@vizsla/hooks';
import { getUserSystemRoles } from '@vizsla/utils';

import * as appRoutes from 'src/constants/routes';
import { FullscreenLoader } from 'src/components/shared';

import { AdminLayout } from './AdminLayout/AdminLayout';
import { OrganizationLayout } from './OrganizationLayout/OrganizationLayout';

export const LayoutResolver: React.FC = ({ children }) => {
  const { currentApp, loading: appLoading } = useUserCurrentApp();
  const { user, loading: userLoading } = useCurrentUser();
  const isEditorExperiencePage = Boolean(useMatch(appRoutes.editorExperienceTemplate));

  const systemRoles = getUserSystemRoles(user!);

  if (appLoading || userLoading || !user) {
    return <FullscreenLoader />;
  }

  if (
    systemRoles.includes(SystemRoles.VizslaAdministrator) &&
    currentApp === Application.VizslaAdminClient
  ) {
    return <AdminLayout>{children}</AdminLayout>;
  }

  if (isEditorExperiencePage) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return <OrganizationLayout>{children}</OrganizationLayout>;
};
