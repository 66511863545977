import { OrganizationStatisticsQuery, useOrganizationStatisticsQuery } from '@vizsla/graphql';
import { useCurrentOrganization } from '@vizsla/hooks';

type UseOrganizationStatsData = {
  loading: boolean;
  data?: OrganizationStatisticsQuery;
};

const useOrganizationStats = (): UseOrganizationStatsData => {
  const { organizationId, organizationIsLoading } = useCurrentOrganization();

  const { data, loading: isOrganizationStatisticsLoading } = useOrganizationStatisticsQuery({
    variables: {
      organizationId: organizationId || '',
    },
    skip: !organizationId,
  });

  const loading = organizationIsLoading || isOrganizationStatisticsLoading;

  return {
    loading,
    data,
  };
};

export { useOrganizationStats };
