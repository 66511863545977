import React from 'react';

import { ExperienceLandingActions, useExperienceLanding, useLandingState } from '@vizsla/providers';

import { ThemeColorsSection } from './ThemeColorsSection';
import { CollapsingList } from './CollapsingList';
import { PageBackgroundSection, HeroImageSection, HeaderLogoSection } from './sections';

export const DesignSection = () => {
  const { loading } = useExperienceLanding();
  const { dispatch } = useLandingState();

  return (
    <React.Fragment>
      <CollapsingList
        loading={loading}
        title="Theme Colors"
        hasResetIcon
        onReset={() => dispatch({ type: ExperienceLandingActions.resetThemeColors, payload: null })}
        resetBtnLabel="Reset to Default"
      >
        <ThemeColorsSection />
      </CollapsingList>

      <CollapsingList loading={loading} title="Page Background">
        <PageBackgroundSection />
      </CollapsingList>

      <CollapsingList loading={loading} title="Header Logo">
        <HeaderLogoSection />
      </CollapsingList>

      <CollapsingList loading={loading} title="Hero Image">
        <HeroImageSection />
      </CollapsingList>
    </React.Fragment>
  );
};
