import React from 'react';
import { useQuery } from '@apollo/client';

import {
  TeamRTsListQuery,
  TeamRTsListQueryVariables,
  TeamRtFragmentFragment,
} from '@vizsla/graphql';

import { TEAMS_RT_LIST_QUERY } from 'src/graphql';

interface UseAttendeesProps {
  campaignId: string;
  customFilter?: Record<string, unknown>;
  queryVariables?: TeamRTsListQueryVariables;
}

export type TeamRTItem = TeamRtFragmentFragment;

export const useTeamsRT = ({
  campaignId,
  customFilter,
  queryVariables = {},
}: UseAttendeesProps) => {
  const filterByCampaignId = {
    campaign: {
      id: {
        equals: campaignId,
      },
    },
  };

  const filter = customFilter ?? filterByCampaignId;

  const { data, loading } = useQuery<TeamRTsListQuery>(TEAMS_RT_LIST_QUERY, {
    variables: {
      filter,
      ...queryVariables,
    },
    skip: !campaignId,
  });

  const teams = React.useMemo(
    () => (data?.teamFundraisingsList?.items as TeamRTItem[]) || [],
    [data],
  );

  const count = React.useMemo(() => data?.teamFundraisingsList?.count as number, [data]);

  return {
    teams,
    count,
    loading,
  };
};
