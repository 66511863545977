var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import debounce from 'debounce-promise';
import { generateSlug } from '@vizsla/utils';
import { SPECIAL_SYMBOLS_REG_EXP } from '@vizsla/constants';
import { useDnsRecord } from './useDnsRecord';
const CAMPAIGN_NAME_IS_EXISTED_ERROR_MESSAGE = 'Campaign with this public url is existed';
const CAMPAIGN_NAME_IS_INVALID_ERROR_MESSAGE = 'Campaign name is invalid format';
function useDnsRecordValidator() {
    const { getDnsRecordBySlug } = useDnsRecord();
    const validateCampaignName = React.useCallback((campaignName) => __awaiter(this, void 0, void 0, function* () {
        if (campaignName) {
            campaignName = campaignName.replace(/ñ/g, 'n').replace(/[^\w\s]|_/g, '');
            const isValidCampaignName = !SPECIAL_SYMBOLS_REG_EXP.test(campaignName);
            if (!isValidCampaignName) {
                return CAMPAIGN_NAME_IS_INVALID_ERROR_MESSAGE;
            }
            const slug = generateSlug(campaignName);
            const dnsRecord = yield getDnsRecordBySlug(slug);
            if (dnsRecord) {
                return CAMPAIGN_NAME_IS_EXISTED_ERROR_MESSAGE;
            }
        }
        return undefined;
    }), [getDnsRecordBySlug]);
    const debouncedValidateByCampaignName = debounce(validateCampaignName, 250);
    return { validateCampaignName: debouncedValidateByCampaignName };
}
export { useDnsRecordValidator };
