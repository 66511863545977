import React from 'react';

import { useAttendees, useCampaignId } from 'src/hooks/campaign';
import { CampaignAttendeesTable } from 'src/components/campaign';

interface PropsWithSkip {
  first?: number;
  skip?: number;
}

interface PropsWithNavigation {
  showNavigation: true;
}

type Props = PropsWithSkip | PropsWithNavigation;

export function CampaignAttendeesTab(props: Props) {
  const campaignId = useCampaignId();

  const { data, loading } = useAttendees({ campaignId, ...props });

  const showNavigation = React.useMemo(() => {
    return 'showNavigation' in props && props.showNavigation;
  }, [props]);

  return (
    <CampaignAttendeesTable dataSource={data} loading={loading} showNavigation={showNavigation} />
  );
}
