import React from 'react';
import { FaceOutlined as Icon } from '@mui/icons-material';

import { formatMoney, formatNumber } from '@vizsla/utils';

import { MetricCard, MetricCardData } from 'src/components/shared';
import { useCampaignId, useCampaignAttendeesMetrics } from 'src/hooks/campaign';

export function CampaignMetricsAttendeesCard() {
  const campaignId = useCampaignId();

  const { data, loading } = useCampaignAttendeesMetrics(campaignId);

  const goal = React.useMemo(() => data?.totalGoal ?? 0, [data]);
  const count = React.useMemo(() => data?.totalCount ?? 0, [data]);

  const progress = React.useMemo(() => {
    return Math.min(Math.round((count / goal) * 100), 100);
  }, [count, goal]);

  const formattedCount = React.useMemo(() => formatNumber(data?.totalCount ?? 0), [goal]);
  const formattedGoal = React.useMemo(() => formatNumber(goal), [goal]);

  const formattedLastSevenDaysRaised = React.useMemo(() => {
    return formatNumber(data?.totalCountLastSevenDays ?? 0);
  }, [data]);

  const formattedTodayRaised = React.useMemo(() => {
    return formatNumber(data?.totalCountToday ?? 0);
  }, [data]);

  const metrics = React.useMemo(() => {
    const items: MetricCardData[] = [
      {
        label: 'Goal',
        value: formattedGoal,
      },
      {
        label: 'Today',
        description: 'All the new attendees today',
        value: formattedTodayRaised,
      },
      {
        label: 'Last 7 days',
        description: 'All the new attendees in the last 7 days',
        value: formattedLastSevenDaysRaised,
      },
    ];

    return items;
  }, [formattedGoal, formattedLastSevenDaysRaised, formattedTodayRaised]);

  if (loading) {
    return <MetricCard loading />;
  }

  return (
    <MetricCard
      label="Attendees"
      value={formattedCount}
      percentage={progress}
      metrics={metrics}
      icon={<Icon />}
    />
  );
}
