import React from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import { Shade, BorderRadius } from '@vizsla/theme';
import { useNotification, useCurrentOrganization } from '@vizsla/hooks';
import { StripeIntegrationStatus } from '@vizsla/stripe';
import { ExternalLink, Button, FullscreenLoader, PaperBlock } from '@vizsla/components';
import { DateFormatPatterns } from '@vizsla/constants';
import { formatDate } from '@vizsla/utils';

import { OrganizationStripeConnectionStatusChip } from 'src/components/organization';
import { useCreateConnectAccountLink } from 'src/hooks/stripe';
import { t } from 'src/utils/template';
import { StripeIcon } from 'src/assets';

import { Paragraph } from './OrganizationSettingsCheckout.style';

const useStyles = makeStyles((theme: Theme) => ({
  innerBlock: {
    border: `1px solid ${Shade.Gray[100]}`,
    borderRadius: BorderRadius.M,
    padding: theme.spacing(3, 4),
  },
  stripeLogoContainer: {
    width: 135,
    height: 65,
  },
}));

export const OrganizationSettingsCheckout: React.FC = () => {
  const classes = useStyles();

  const { organization, organizationId, organizationIsLoading } = useCurrentOrganization();

  const notification = useNotification();

  const { createConnectAccountLink, loading: connectionLinkLoading } =
    useCreateConnectAccountLink();

  const [connectAccountLink, setConnectAccountLink] = React.useState<string | undefined>(undefined);

  const refreshUrl = `${window.location.href}?refreshConnectLink=true`;
  const returnUrl = window.location.href;

  if (organizationIsLoading || _.isNil(organizationId)) {
    return <FullscreenLoader />;
  }

  const onClick = async () => {
    try {
      const accountLink = await createConnectAccountLink(refreshUrl, returnUrl, organizationId);
      setConnectAccountLink(accountLink.url);
    } catch (e) {
      notification.error(t('create_stripe_connection_link_error'));
    }
  };

  const renderConnectControls = () => {
    if (organization?.stripeIntegration?.status === StripeIntegrationStatus.Connected) {
      return null;
    }

    if (!_.isNil(connectAccountLink)) {
      return (
        <Grid item>
          <ExternalLink href={connectAccountLink}>Connect Stripe Account</ExternalLink>
        </Grid>
      );
    }

    return (
      <Grid item>
        <Button onClick={onClick} loading={connectionLinkLoading}>
          Get Stripe Connection Link
        </Button>
      </Grid>
    );
  };

  return (
    <Grid>
      <Typography variant="h3" marginBottom={1}>
        Checkout
      </Typography>
      <Typography variant="body2" marginBottom={4}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore.
      </Typography>
      <PaperBlock title="Payment Provider" collapsing defaultOpened>
        <Grid
          container
          className={classes.innerBlock}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item className={classes.stripeLogoContainer}>
            <StripeIcon />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">Stripe:</Typography>
            <Paragraph>
              Accepted{' '}
              {formatDate(
                organization?.stripeIntegration?.createdAt,
                DateFormatPatterns.shortDateWithDash,
              )}
            </Paragraph>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">Processing Fees:</Typography>
            {/* @sebastiantoro06: ADD REAL Information, OUT of the scope VSL-685 */}
            <Paragraph>Stripe: 2.2% + $30 Platform Fees: 3.25%</Paragraph>
          </Grid>
          <Grid item>
            <OrganizationStripeConnectionStatusChip
              status={organization?.stripeIntegration?.status}
            />
          </Grid>
          {renderConnectControls()}
        </Grid>
      </PaperBlock>
    </Grid>
  );
};
