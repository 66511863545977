import { useTicketingOptionQuery } from '@vizsla/graphql';

export const useTicketingOptionById = (id: string) => {
  const { data, loading, error } = useTicketingOptionQuery({
    variables: {
      id,
    },
    skip: !id,
  });

  const ticketingOption = data?.ticketingOption;

  return {
    ticketingOption,
    loading,
    error,
  };
};
