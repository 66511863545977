import React from 'react';
import { Form, Field } from 'react-final-form';
import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { Grid, Typography } from '@mui/material';

import { USER_GROUP_OPTIONS } from '@vizsla/constants';
import { CampaignQuestionUserGroup, CampaignQuestionInputType } from '@vizsla/types';
import { useModal, useNotification } from '@vizsla/hooks';
import { prepareCustomQuestionCreateInput } from '@vizsla/utils';
import { Experience } from '@vizsla/graphql';
import { TextField, CheckboxField, SelectField, Button, Modal } from '@vizsla/components';

import { useCampaignId } from 'src/hooks/campaign';
import { useExperiences } from 'src/hooks/experiences';
import { useCampaignQuestions } from 'src/hooks/campaignQuestion';
import { CampaignCustomQuestionTypeField } from 'src/components/forms';
import { t } from 'src/utils/template';
import { MODALS } from 'src/constants/modals';

const INITIAL_VALUES = {
  type: CampaignQuestionInputType.text,
  userGroup: [
    CampaignQuestionUserGroup.attendee,
    CampaignQuestionUserGroup.donor,
    CampaignQuestionUserGroup.team,
  ],
};

const CampaignQuestionCreateModal: React.FC = () => {
  const notification = useNotification();

  const { isOpen, closeModal } = useModal(MODALS.CAMPAIGN_QUESTION_CREATE_MODAL);

  const campaignId = useCampaignId();
  const { createCampaignQuestion } = useCampaignQuestions(campaignId);

  const { data: experiences } = useExperiences(campaignId);

  const ALLOCATION_OPTIONS = experiences.map((experience: Experience) => ({
    label: experience.name || undefined,
    value: experience.id || undefined,
  }));

  const onSubmit = async (campaignQuestionData: any, form: FormApi) => {
    if (campaignId) {
      try {
        const customQuestionCreateInput = prepareCustomQuestionCreateInput({
          ...campaignQuestionData,
          campaign: campaignId,
        });

        await createCampaignQuestion(customQuestionCreateInput);

        notification.success(t('campaign_custom_question_create_success'));
        closeModal();
      } catch (error) {
        notification.error(t('campaign_custom_question_create_error'));
        console.error({ error });
      }
    } else {
      notification.error('campaignId is missing');
    }
  };

  const onCloseModal = () => {
    closeModal();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <Modal.Title>
        <Typography variant="h3" align="center">
          Add Custom Question
        </Typography>
        <Typography variant="body2" align="center">
          Let&apos;s set up a new question
        </Typography>
      </Modal.Title>

      <Modal.Content>
        <Form
          onSubmit={onSubmit}
          initialValues={INITIAL_VALUES}
          mutators={{
            ...arrayMutators,
          }}
        >
          {({ handleSubmit, submitting, form: { mutators }, pristine }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="title"
                      label="Question"
                      component={TextField}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="isRequired"
                      label="This is required question"
                      component={CheckboxField}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="experience"
                      label="Allocation"
                      multiple
                      component={SelectField}
                      options={ALLOCATION_OPTIONS}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="userGroup"
                      label="User Groups"
                      component={SelectField}
                      options={USER_GROUP_OPTIONS}
                      disabled={submitting}
                      required
                      multiple
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="type"
                      label="Type"
                      component={CampaignCustomQuestionTypeField}
                      mutators={mutators}
                      disabled={submitting}
                    />
                  </Grid>
                </Grid>
                <Modal.Actions>
                  <Button
                    variant="text"
                    size="large"
                    color="info"
                    style={{ width: 100 }}
                    onClick={onCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={{ width: 100 }}
                    size="large"
                    color="primary"
                    onClick={handleSubmit}
                    loading={submitting}
                    disabled={submitting || pristine}
                  >
                    Add
                  </Button>
                </Modal.Actions>
              </form>
            );
          }}
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default CampaignQuestionCreateModal;
