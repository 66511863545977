import React from 'react';
import { Grid } from '@mui/material';
import { FormSpy, FormSpyRenderProps, useForm } from 'react-final-form';
import _ from 'lodash';

import { PartnershipOrganization } from '@vizsla/graphql';
import { TextField, NumberField, CheckboxField, RadioGroupField, Field } from '@vizsla/components';

import { OfflineDonorType } from 'src/types/offlineDonation';

import { AddOfflineDonationOrganizationSelectField } from './AddOfflineDonationOrganizationSelectField';

const DONATION_TYPE_OPTIONS = [
  {
    label: 'From an Individual',
    value: OfflineDonorType.individual,
  },
  {
    label: 'From an Organization',
    value: OfflineDonorType.organization,
  },
];

export const AddOfflineDonationDonorInfoStep: React.FC = () => {
  const donorTypeDefaultValue = DONATION_TYPE_OPTIONS[0].value;

  const { change: changeFieldValue, getFieldState } = useForm();

  const [donorType, setDonorType] = React.useState(
    getFieldState('donorInfo.donorType')?.value ?? OfflineDonorType.individual,
  );

  const handleChangeDonorType = (donorTypeFieldValue?: OfflineDonorType) => {
    if (donorTypeFieldValue !== OfflineDonorType.organization) {
      changeFieldValue('donorInfo.donorPartnershipOrganization', undefined);
    }

    if (donorTypeFieldValue !== OfflineDonorType.individual) {
      changeFieldValue('donorInfo.donorFirstName', undefined);
      changeFieldValue('donorInfo.donorLastName', undefined);
    }

    if (donorTypeFieldValue !== donorType) {
      setDonorType(donorTypeFieldValue);
    }
  };

  const handlePartnershipOrganizationChange = (
    organization?: PartnershipOrganization,
    currentDonorEmail?: string,
    donorType?: OfflineDonorType,
  ) => {
    if (donorType === OfflineDonorType.organization && organization?.email !== currentDonorEmail) {
      changeFieldValue('donorInfo.donorEmail', organization?.email);
    }
  };

  const renderDonorInputs = () => {
    switch (donorType) {
      case OfflineDonorType.individual: {
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                name="donorInfo.donorFirstName"
                label="First Name"
                required
                component={TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="donorInfo.donorLastName"
                label="Last Name"
                required
                component={TextField}
                fullWidth
              />
            </Grid>
          </Grid>
        );
      }
      case OfflineDonorType.organization: {
        return <AddOfflineDonationOrganizationSelectField />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <FormSpy subscription={{ values: true }}>
      {(spyRenderProps: FormSpyRenderProps) => {
        const { addDedication, donorInfo } = spyRenderProps.values;

        if (!_.isNil(donorInfo)) {
          handleChangeDonorType(donorInfo?.donorType);
          handlePartnershipOrganizationChange(
            donorInfo?.donorPartnershipOrganization,
            donorInfo?.donorEmail,
            donorInfo?.donorType,
          );
        }

        return (
          <Grid container spacing={2} direction="column">
            <Grid item xs={12}>
              <Field
                label="Donation Type"
                name="donorInfo.donorType"
                component={RadioGroupField}
                defaultValue={donorTypeDefaultValue}
                row
                options={DONATION_TYPE_OPTIONS}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              {renderDonorInputs()}
            </Grid>
            <Grid item xs={12}>
              <Field
                name="donorInfo.donorEmail"
                type="email"
                label="Email"
                required
                component={TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="donorInfo.donorPhone.number"
                label="Phone Number"
                required
                formatMask="+1 (###) ###-####"
                isNumericString
                component={NumberField}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="donorInfo.anonymous"
                label="Make this donation Anonymous"
                component={CheckboxField}
                defaultValue
                fullWidth
              />
            </Grid>
            <Grid item xs={12} container>
              <Field
                name="addDedication"
                label="Add Dedication"
                component={CheckboxField}
                defaultValue
                fullWidth
              />
            </Grid>

            {addDedication && (
              <Grid item xs={12}>
                <Field
                  name="donorInfo.dedication"
                  label="Comment"
                  rows={3}
                  maxRows={4}
                  component={TextField}
                  fullWidth
                  multiline
                />
              </Grid>
            )}
          </Grid>
        );
      }}
    </FormSpy>
  );
};
