import React from 'react';
import {
  Box,
  CircularProgressProps,
  CircularProgress as MuiCircularProgress,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Display, PaletteColor, Shade } from 'src/theme';

const useStyles = makeStyles<Theme, { color: string }>(theme => ({
  root: {
    position: 'relative',
    display: Display.Flex,
    alignItems: 'center',
  },
  progress: {
    position: 'absolute',
    left: 0,
  },
  backProgress: {
    color: theme.palette.grey[200],
  },
  mainProgress: {
    color: ({ color }) => color,
  },
  extraProgress: {
    color: PaletteColor.ExtraProgress,
  },
}));

const defaultCalculateColor = (value: number) => {
  if (value <= 0) {
    return Shade.Gray[200];
  }
  if (value < 20) {
    return PaletteColor.ErrorMain;
  }

  if (value < 40) {
    return PaletteColor.WarningProgress;
  }
  return PaletteColor.SuccessMain;
};

interface DeterminateProgressProps extends CircularProgressProps {
  renderAfter?: (textColor: string) => React.ReactElement;
  calculateColor?: (value: number) => string;
}

export const DeterminateProgress: React.FC<DeterminateProgressProps> = ({
  value = 0,
  renderAfter,
  thickness = 10,
  calculateColor = value => defaultCalculateColor(value),
  className,
  ...props
}) => {
  const color = calculateColor(value);
  const classes = useStyles({ color });

  const hasExtraValue = value > 100;

  const textColor = value > 100 ? PaletteColor.ExtraProgress : color;

  const extraValue = value - 100 > 100 ? 100 : value - 100;

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <MuiCircularProgress
          value={100}
          variant="determinate"
          className={clsx(classes.backProgress, className)}
          thickness={thickness}
          {...props}
        />
        <MuiCircularProgress
          variant="determinate"
          disableShrink
          className={clsx(classes.progress, classes.mainProgress, className)}
          thickness={thickness}
          value={hasExtraValue ? 100 : value}
          {...props}
        />
        {hasExtraValue && (
          <MuiCircularProgress
            variant="determinate"
            disableShrink
            className={clsx(classes.progress, classes.extraProgress, className)}
            thickness={5}
            value={extraValue}
            {...props}
          />
        )}
      </Box>
      {renderAfter && renderAfter(textColor)}
    </React.Fragment>
  );
};
