import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { LinearProgress, Typography } from '@mui/material';

// import { experienceLandingReducer } from '@vizsla/components';

import { ThakYouPageProvider } from 'src/providers/campaign/ThankYouProvider';
import { ThankYouLandingProvider } from 'src/providers/campaign/ThankYouLandingProvider';
import { useCampaign, useCampaignId } from 'src/hooks/campaign';
import { experiences } from 'src/constants/routes';
import { ThankYouEditor } from 'src/components/ThankYouTemplateEditor/ThankYouTemplateEditor';
import { thankYouReducer } from 'src/components/ThankYouTemplateEditor/ThankYouReducer';

import { ThakYouPageWrapper } from './ThankYouPageWrapper';

const useStyles = makeStyles({
  layout: {
    display: 'flex',
    alignItems: 'flex-start',
  },

  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    overflowWrap: 'normal',
  },
});

export const ThankYouTemplate: React.FC = () => {
  const classes = useStyles();
  const campaignId = useCampaignId();
  const { data: campaignData } = useCampaign(campaignId);

  const experienceId = campaignData?.experiences?.items[0].id || '';
  return (
    <div className={classes.layout}>
      <ThakYouPageProvider campaignId={campaignId} experienceId={experienceId}>
        <ThankYouLandingProvider landingReducer={thankYouReducer}>
          <ThakYouPageWrapper />
          <ThankYouEditor />
        </ThankYouLandingProvider>
      </ThakYouPageProvider>
    </div>
  );
};
