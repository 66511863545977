import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SxProps } from '@mui/material';

import {
  usePagination,
  usePaginationQueryVariables,
  useSearchQueryVariables,
  useCurrentOrganization,
} from '@vizsla/hooks';
import { buildUrl, formatNumber, formatMoney } from '@vizsla/utils';
import type { TableColumn, TableRowAction } from '@vizsla/components';
import { TableData } from '@vizsla/components';

import { useStoreAssetsDashboardData } from 'src/hooks/storeAssets';
import type { DashboardStoreAsset } from 'src/types/storeAssets';
import * as entitiesId from 'src/constants/entities-id';
import * as appRoutes from 'src/constants/routes';
import { EMPTY_IMAGE } from 'src/assets';

import { TableNameCellContent } from './components';

const tableContainerStyle: SxProps = () => ({
  height: '100%',
});

const StoreAssetsDashboardTable: React.FC = () => {
  const navigate = useNavigate();
  const { organizationId, organizationIsLoading } = useCurrentOrganization();

  const pagination = usePagination();
  const paginationQueryVariables = usePaginationQueryVariables();
  const searchQueryVariables = useSearchQueryVariables('name');

  const { storeAssets, storeAssetsCount, loading } = useStoreAssetsDashboardData(
    organizationId || undefined,
    paginationQueryVariables,
    searchQueryVariables,
  );

  React.useEffect(() => {
    if (storeAssetsCount) {
      pagination.onCountChange(storeAssetsCount);
    }
  }, [pagination, storeAssetsCount]);

  const STORE_ASSETS_TABLE_COLUMNS: Array<TableColumn<DashboardStoreAsset>> = React.useMemo(
    () => [
      {
        key: 'name',
        title: 'Name',
        render: (value, { name, imageUrl }) => {
          const image = imageUrl || EMPTY_IMAGE;
          return <TableNameCellContent assetName={name} assetImageUrl={image} />;
        },
      },
      {
        key: 'total',
        title: 'Total',
        render: (value, { countOfTotal }) => {
          return formatNumber(countOfTotal);
        },
      },
      {
        key: 'available',
        title: 'Available',
        render: (value, { countOfAvailable }) => {
          return formatNumber(countOfAvailable);
        },
      },
      {
        key: 'price',
        title: 'Price',
        render: (value, { price }) => {
          return formatMoney(price, 2);
        },
      },
      {
        key: 'sales',
        title: 'Sales',
        render: (value, { salesAmount }) => {
          return formatMoney(salesAmount, 2);
        },
      },
      {
        key: 'profit',
        title: 'Profit',
        render: (value, { profitAmount }) => {
          return formatMoney(profitAmount, 2);
        },
      },
    ],
    [storeAssets],
  );

  const STORE_ASSETS_TABLE_ROW_ACTIONS: Array<TableRowAction<DashboardStoreAsset>> = React.useMemo(
    () => [
      {
        actionName: 'Management',
        onAction: (storeAsset: DashboardStoreAsset) => {
          const storeAssetPageUrl = buildUrl(appRoutes.storeAssetsAssetPage, {
            [entitiesId.storeAsset]: storeAsset.id as string,
          });

          navigate(storeAssetPageUrl);
        },
      },
    ],
    [storeAssets],
  );
  return (
    <TableData
      tableContainerProps={{ sx: tableContainerStyle }}
      dataSource={storeAssets}
      columns={STORE_ASSETS_TABLE_COLUMNS}
      rowActions={STORE_ASSETS_TABLE_ROW_ACTIONS}
      loading={organizationIsLoading || loading}
      pagination={pagination}
    />
  );
};

export { StoreAssetsDashboardTable };
