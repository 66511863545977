import styled from '@emotion/styled';
import { AccountBalance } from '@mui/icons-material';
import { Skeleton, Typography } from '@mui/material';

const ICON_SIZE = '3rem';

/// Card
export const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 250px;
  height: 150px;
  padding: 1.5rem;
  background-color: white;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
`;

/// HeaderCard
export const HeaderCard = styled.div`
  display: grid;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
`;

export const FooterCardLogo = styled.div`
  display: grid;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 5;
  grid-row-end: 5;
  justify-items: bottom;
`;

export const FooterCardActions = styled.div`
  display: grid;
  grid-column-start: 2;
  grid-column-end: 9;
  grid-row-start: 5;
  grid-row-end: 5;
  justify-items: bottom;
`;
/// Content

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

/// Details

export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

/// Name

export const Name = styled(Typography)`
  font-weight: bold;
`;

/// Reference

export const Reference = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.1rem;
`;

Reference.defaultProps = {
  variant: 'caption',
};

/// Actions

export const Actions = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 0.5rem;
`;

/// IconContainer

export const IconContainer = styled.div`
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

/// CardBrandImage

export const CardBrandImage = styled.img`
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  object-fit: contain;
`;

/// BankIcon

export const BankIcon = styled(AccountBalance)`
  font-size: calc(${ICON_SIZE} / 1.4);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

/// Title

export const Title = styled(Typography)`
  font-weight: bold;
`;

/// Description

export const Description = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
`;

/// AllowedMethods

export const AllowedMethods = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

/// AllowedMethodImage

const ALLOWED_METHOD_IMAGE_WIDTH = '5rem';
const ALLOWED_METHOD_IMAGE_HEIGHT = '3.5rem';

export const AllowedMethodImage = styled.img`
  width: ${ALLOWED_METHOD_IMAGE_WIDTH};
  height: ${ALLOWED_METHOD_IMAGE_HEIGHT};
  object-fit: contain;
  border: solid 0.1rem #eee;
  border-radius: 0.5rem;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
`;

/// CardSkeleton

export const CardSkeleton = styled(Skeleton)`
  min-height: 8rem;
  border-radius: 0.8rem;
`;

CardSkeleton.defaultProps = {
  variant: 'rectangular',
};
