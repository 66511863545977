import { Close } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { FormApi } from 'final-form';
import { Grid, Typography, IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { Field, Modal, SelectField, TextField, Form } from '@vizsla/components';
import { SocialMediaUpdateInput, SOCIAL_MEDIA_CREATE } from '@vizsla/graphql';
import { useCurrentUser, useModal, useNotification } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { MODALS, SOCIAL_MEDIA } from '@vizsla/constants';

import { VizslaOrganizationSocialMediaSchema } from 'src/constants/validationSchemas/organization';

interface SocialMediaValues {
  socialMedia: string | null;
  uRL: string | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  addTeamButton: {
    padding: theme.spacing(2),
    width: '150px',
  },
  closeIconButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export function SocialMediaModal() {
  const [loading, setLoading] = React.useState(false);

  const notification = useNotification();

  const classes = useStyles();

  const { isOpen, closeModal } = useModal(MODALS.SOCIAL_MEDIA_CREATE_MODAL);

  const { user } = useCurrentUser();

  const organizationId = user?.vizslaOrganizationStaff?.items[0].vizslaOrganization?.id ?? '';

  const [vizslaOrganizationUpdate] = useMutation<SocialMediaUpdateInput>(SOCIAL_MEDIA_CREATE, {
    refetchQueries: ['SocialMedia'],
    awaitRefetchQueries: true,
  });

  const onCancel = (form: FormApi<SocialMediaValues, Partial<SocialMediaValues>>) => {
    form.reset();
  };
  const onSubmit = async (
    data: SocialMediaValues,
    form: FormApi<SocialMediaValues, SocialMediaValues>,
  ) => {
    try {
      setLoading(true);
      await vizslaOrganizationUpdate({
        variables: {
          data: {
            organization: { connect: { id: organizationId } },
            socialMedia: data?.socialMedia,
            uRL: data?.uRL,
          },
        },
      });
      notification.success('Organization update success');
      setLoading(false);
      closeModal();
      form.reset();
    } catch (e) {
      notification.error('Error updating organization');
      console.error(e);
      setLoading(false);
    }
  };

  const onCloseModal = () => {
    closeModal();
  };

  const INITIAL_VALUES: SocialMediaValues = {
    socialMedia: '',
    uRL: '',
  };

  return (
    <Grid container spacing={8}>
      <Grid item xs={4}>
        <Form
          onSubmit={onSubmit}
          initialValues={INITIAL_VALUES}
          validate={values => validateWithSchema(VizslaOrganizationSocialMediaSchema, values)}
        >
          {({ form, handleSubmit, submitting, pristine, hasValidationErrors }) => {
            const isSubmitDisabled = submitting || pristine || hasValidationErrors;

            return (
              <form onSubmit={handleSubmit}>
                <Modal isOpen={isOpen} onClose={onCloseModal}>
                  <Modal.Content>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                      <Grid item>
                        <IconButton
                          aria-label="close"
                          onClick={onCloseModal}
                          className={classes.closeIconButton}
                          size="large"
                        >
                          <Close />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Grid item marginBottom={3} marginTop={1}>
                          <Typography variant="h3" marginLeft={7}>
                            Add Social Media
                          </Typography>
                        </Grid>
                        <Grid container spacing={4} marginLeft={1}>
                          <Grid item xs={9}>
                            <Field
                              fullWidth
                              name="socialMedia"
                              label="Social Media"
                              requierd
                              component={SelectField}
                              options={SOCIAL_MEDIA}
                              disabled={loading}
                            />
                          </Grid>
                          <Grid item xs={9}>
                            <Field
                              fullWidth
                              name="uRL"
                              label="URL"
                              requierd
                              component={TextField}
                              disabled={loading}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Modal.Content>
                  <Grid container justifyContent="center" alignItems="center">
                    <Modal.Actions>
                      <Modal.Action
                        type="primary"
                        content="Add"
                        onAction={handleSubmit}
                        disabled={isSubmitDisabled}
                        loading={loading}
                      />
                      <Modal.Action
                        type="secondary"
                        content="Cancel"
                        onAction={() => onCancel(form)}
                      />
                    </Modal.Actions>
                  </Grid>
                </Modal>
              </form>
            );
          }}
        </Form>
      </Grid>
    </Grid>
  );
}
