import React, { useMemo } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

import { TemplateType } from '@vizsla/types';
import { Button } from '@vizsla/components';

import { useCampaignId, useCampaignPublicPages } from 'src/hooks/campaign';
import { CampaignPageCard } from 'src/components/campaignPages/CampaignPageCard';

const loaderBoxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexFlow: 'column',
  gap: '30px',
};

const CampaignPages: React.FC = () => {
  const campaignId = useCampaignId();

  const {
    campaignPublicTemplates,
    connectTemplatesToCampaign,
    updating,
    loading,
    hasCampaignTemplates,
  } = useCampaignPublicPages(campaignId);

  const tiles = useMemo(
    () =>
      campaignPublicTemplates.map(item => {
        const { id, templateType: template } = item;

        return {
          id,
          title: template?.title ?? `Template #${id}`,
          type: template?.type as TemplateType,
          // TODO: Add final description
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        };
      }),
    [campaignPublicTemplates],
  );

  const onConnectTemplates = async () => {
    try {
      await connectTemplatesToCampaign();
    } catch (e) {
      console.error(e);
    }
  };

  if (updating || loading) {
    return (
      <Box sx={loaderBoxStyles}>
        {updating && <Typography variant="h2">Creating Templates...</Typography>}
        <CircularProgress size={100} color="secondary" />
      </Box>
    );
  }

  if (!hasCampaignTemplates) {
    return (
      <Grid item xs={12}>
        <Box sx={loaderBoxStyles}>
          <Typography variant="h2">No Campaign Pages</Typography>
          <Button onClick={onConnectTemplates}>Connect</Button>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="stretch">
      {tiles?.map(item => (
        <CampaignPageCard
          key={item.id}
          title={item.title}
          type={item.type}
          description={item.description}
          campaignID={campaignId}
        />
      ))}
    </Grid>
  );
};

export { CampaignPages };
