import React from 'react';
import { CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { usePartnershipsQueryQuery } from '@vizsla/graphql';
import { CompanyAvatarFullNameInline } from '@vizsla/components';

import { OrganizationPartnershipContact } from 'src/components/organization/OrganizationPartnershipContact';
import { home } from 'src/constants/routes';

import { Wrapper } from './styles';
import { OrganizationPartnershipCommitment } from '../../../components/organization/OrganizationPartnershipCommitments';
import { PartnershipDetailsInfoCards } from './PartnershipsDetailsInfoCards';

export const PartnershipDetailsPage: React.FC = () => {
  const { partnershipId } = useParams();
  const { data: partnerships, loading } = usePartnershipsQueryQuery({
    variables: { filter: { id: { equals: partnershipId } } },
  });
  const navigate = useNavigate();

  const partnership = React.useMemo(() => {
    if (!loading && !partnerships?.partnershipOrganizationsList?.count) {
      navigate(home);
      return null;
    }

    return partnerships?.partnershipOrganizationsList?.items?.at(0);
  }, [partnerships, loading]);

  if (loading) return <CircularProgress />;

  return (
    <Wrapper>
      <CompanyAvatarFullNameInline
        avatar={partnership?.avatar as any}
        name={partnership?.name}
        typographyVariant="h1"
      />
      <PartnershipDetailsInfoCards partnershipId={partnershipId} />
      <OrganizationPartnershipCommitment partnershipId={partnershipId} />
      <OrganizationPartnershipContact partnershipId={partnershipId} />
    </Wrapper>
  );
};
