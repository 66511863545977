import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowDropDown } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  wrapper: {
    cursor: 'pointer',
  },

  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

interface DropdownTextWrapperProps {
  children: React.ReactNode;
}

export const DropDownTextWrapper: React.FC<DropdownTextWrapperProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" className={classes.wrapper}>
      <span className={classes.text}>{children}</span>
      <ArrowDropDown />
    </Box>
  );
};
