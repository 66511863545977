import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Event } from '@mui/icons-material';

import { EMPTY_IMAGE } from 'src/assets';
import { BorderRadius } from 'src/theme';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    width: 350,
  },
  image: {
    height: 64,
    width: 114,
    objectFit: 'cover',
    borderRadius: BorderRadius.M,
    marginRight: theme.spacing(2),
  },
  textBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    justifyContent: 'center',
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[600],
    '&>*:first-child': {
      marginRight: theme.spacing(1),
    },
  },
}));

interface TextWithImageCellProps {
  image?: string | null;
  text: string;
  extra?: string;
}

export const TextWithImageCell: React.FC<TextWithImageCellProps> = props => {
  const classes = useStyles();

  const { image, extra, text } = props;

  return (
    <Box className={classes.root}>
      <img className={classes.image} src={image || EMPTY_IMAGE} />
      <Box className={classes.textBlock}>
        <Typography
          color={theme => theme.palette.primary.main}
          padding={0}
          noWrap
          textOverflow="ellipsis"
          variant="subtitle1"
        >
          {text}
        </Typography>
        {extra && (
          <Typography variant="caption" className={classes.date}>
            <Event fontSize="inherit" />
            {extra}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
