import { gql } from '@apollo/client';

export const PARTNERSHIP_ORGANIZATIONS_LIST = gql`
  query PartnershipOrganizationsList($filter: PartnershipOrganizationFilter) {
    partnershipOrganizationsList(filter: $filter) {
      items {
        id
        name
        email
        type

        avatar {
          id
          downloadUrl
        }
        organization {
          id
        }
        donations {
          items {
            id
            amount
          }
        }
      }
    }
  }
`;

export const PartnershipDashboardData = gql`
  query PartnershipDashboardData($id: ID!) {
    inKind: partnershipCommitmentsList(
      filter: { type: { equals: "inKind" }, partnershipOrganization: { id: { equals: $id } } }

      groupBy: { query: { amount: { as: "sum", fn: { aggregate: SUM } } } }
    ) {
      groups {
        sum: Float
      }
    }
    matching: partnershipCommitmentsList(
      filter: { type: { equals: "matching" }, partnershipOrganization: { id: { equals: $id } } }
      groupBy: { query: { amount: { as: "sum", fn: { aggregate: SUM } } } }
    ) {
      groups {
        sum: Float
      }
    }
    cash: partnershipCommitmentsList(
      filter: { type: { equals: "cash" }, partnershipOrganization: { id: { equals: $id } } }
      groupBy: { query: { amount: { as: "sum", fn: { aggregate: SUM } } } }
    ) {
      groups {
        sum: Float
      }
    }
    total: partnershipCommitmentsList(
      filter: { partnershipOrganization: { id: { equals: $id } } }
      groupBy: { query: { amount: { as: "sum", fn: { aggregate: SUM } } } }
    ) {
      groups {
        sum: Float
      }
      count
    }
  }
`;

export const OrganizationPartnershipsDashboardData = gql`
  query OrganizationPartnershipsDashboardData($id: ID!) {
    inKind: partnershipCommitmentsList(
      filter: {
        type: { equals: "inKind" }
        partnershipOrganization: { organization: { id: { equals: $id } } }
      }
      groupBy: { query: { amount: { as: "sum", fn: { aggregate: SUM } } } }
    ) {
      groups {
        sum: Float
      }
    }
    matching: partnershipCommitmentsList(
      filter: {
        type: { equals: "matching" }
        partnershipOrganization: { organization: { id: { equals: $id } } }
      }
      groupBy: { query: { amount: { as: "sum", fn: { aggregate: SUM } } } }
    ) {
      groups {
        sum: Float
      }
    }
    cash: partnershipCommitmentsList(
      filter: {
        type: { equals: "cash" }
        partnershipOrganization: { organization: { id: { equals: $id } } }
      }
      groupBy: { query: { amount: { as: "sum", fn: { aggregate: SUM } } } }
    ) {
      groups {
        sum: Float
      }
    }
    total: partnershipCommitmentsList(
      filter: { partnershipOrganization: { organization: { id: { equals: $id } } } }
      groupBy: { query: { amount: { as: "sum", fn: { aggregate: SUM } } } }
    ) {
      groups {
        sum: Float
      }
    }

    partnershipsCount: partnershipOrganizationsList(
      filter: { organization: { id: { equals: $id } } }
    ) {
      count
    }
  }
`;
