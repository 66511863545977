import {
  ActionButtonBackgroundType,
  HeroMediaType,
  PageBackgroundType,
  RegistrationsSectionDisplayStyleType,
  HeaderLogoAlignType,
} from '@vizsla/constants';

import { INITIAL_THEME, LandingState } from 'src/providers/campaign/ThankYouLanding.types';

export function updateAccentColor(state: LandingState, color: string) {
  return { ...state, theme: { ...state.theme, accentColor: color } };
}
export function updateColorLibrary(state: LandingState, colorLibrary: Array<string>) {
  return { ...state, theme: { ...state.theme, colorLibrary } };
}
export function updateProgressBarColor(state: LandingState, color: string) {
  return { ...state, theme: { ...state.theme, progressBarEnd: color } };
}
export function updatePageBackgroundFirstColor(state: LandingState, color: string) {
  return { ...state, theme: { ...state.theme, pageBackgroundFirstColor: color } };
}
export function updatePageBackgroundSecondColor(state: LandingState, color: string) {
  return { ...state, theme: { ...state.theme, pageBackgroundSecondColor: color } };
}
export function updateRegistrationButtonLabel(
  state: LandingState,
  registrationButtonLabel: string,
) {
  return { ...state, theme: { ...state.theme, registrationButtonLabel } };
}
export function updateDonationButtonLabel(state: LandingState, donationButtonLabel: string) {
  return { ...state, theme: { ...state.theme, donationButtonLabel } };
}
export function updateShareButtonLabel(state: LandingState, shareButtonLabel: string) {
  return { ...state, theme: { ...state.theme, shareButtonLabel } };
}
export function updateRegistrationButtonBackgrondType(
  state: LandingState,
  registrationButtonBackgrondType: ActionButtonBackgroundType,
) {
  return { ...state, theme: { ...state.theme, registrationButtonBackgrondType } };
}
export function updateDonationButtonBackgrondType(
  state: LandingState,
  donationButtonBackgrondType: ActionButtonBackgroundType,
) {
  return { ...state, theme: { ...state.theme, donationButtonBackgrondType } };
}
export function updateShareButtonBackgrondType(
  state: LandingState,
  shareButtonBackgrondType: ActionButtonBackgroundType,
) {
  return { ...state, theme: { ...state.theme, shareButtonBackgrondType } };
}
export function updateIsRegistrationButtonVisible(
  state: LandingState,
  registrationButtonIsVisible: boolean,
) {
  return { ...state, theme: { ...state.theme, registrationButtonIsVisible } };
}
export function updateIsDonationButtonVisible(
  state: LandingState,
  donationButtonIsVisible: boolean,
) {
  return { ...state, theme: { ...state.theme, donationButtonIsVisible } };
}
export function updateIsShareButtonVisible(state: LandingState, shareButtonIsVisible: boolean) {
  return { ...state, theme: { ...state.theme, shareButtonIsVisible } };
}
export function updateIsProgressBarRaisedAmountVisible(
  state: LandingState,
  isProgressBarRaisedAmountVisible: boolean,
) {
  return { ...state, theme: { ...state.theme, isProgressBarRaisedAmountVisible } };
}

export function updateisOurStoryTitleVisible(state: LandingState, isOurStoryTitleVisible: boolean) {
  return { ...state, theme: { ...state.theme, isOurStoryTitleVisible } };
}

export function updateisThankYouMessageVisible(
  state: LandingState,
  isThankYouMessageVisible: boolean,
) {
  return { ...state, theme: { ...state.theme, isThankYouMessageVisible } };
}
export function updateisDonationInformationTitleVisible(
  state: LandingState,
  isDonationInformationTitleVisible: boolean,
) {
  return { ...state, theme: { ...state.theme, isDonationInformationTitleVisible } };
}

export function updateisDonationInformationDescriptionVisible(
  state: LandingState,
  isDonationInformationDescriptionVisible: boolean,
) {
  return { ...state, theme: { ...state.theme, isDonationInformationDescriptionVisible } };
}
export function updateisManageDonationTitleVisible(
  state: LandingState,
  isManageDonationTitleVisible: boolean,
) {
  return { ...state, theme: { ...state.theme, isManageDonationTitleVisible } };
}

export function updateisManageDonationDescriptionVisible(
  state: LandingState,
  isManageDonationDescriptionVisible: boolean,
) {
  return { ...state, theme: { ...state.theme, isManageDonationDescriptionVisible } };
}
export function updateisHelpTitleVisible(state: LandingState, isHelpTitleVisible: boolean) {
  return { ...state, theme: { ...state.theme, isHelpTitleVisible } };
}

export function updateisHelpDescriptionVisible(
  state: LandingState,
  isHelpDescriptionVisible: boolean,
) {
  return { ...state, theme: { ...state.theme, isHelpDescriptionVisible } };
}

export function updateIsLeaderboardVisible(state: LandingState, isLeaderboardVisible: boolean) {
  return { ...state, theme: { ...state.theme, isLeaderboardVisible } };
}

export function updateIsActivityVisible(state: LandingState, isActivityVisible: boolean) {
  return { ...state, theme: { ...state.theme, isActivityVisible } };
}
export function updateIsProgressBarGoalAmountVisible(
  state: LandingState,
  isProgressBarGoalAmountVisible: boolean,
) {
  return { ...state, theme: { ...state.theme, isProgressBarGoalAmountVisible } };
}
export function updatePageBackgroundType(
  state: LandingState,
  pageBackgroundType: PageBackgroundType,
) {
  return { ...state, theme: { ...state.theme, pageBackgroundType } };
}
export function updateHeroMediaType(state: LandingState, heroMediaType: HeroMediaType) {
  return { ...state, theme: { ...state.theme, heroMediaType } };
}
export function updateHeaderLogoAlign(state: LandingState, headerLogoAlign: HeaderLogoAlignType) {
  return { ...state, theme: { ...state.theme, headerLogoAlign } };
}
export function updateRegistrationsSectionTitle(
  state: LandingState,
  registrationsSectionTitle: string,
) {
  return { ...state, theme: { ...state.theme, registrationsSectionTitle } };
}
export function updateRegistrationsSectionDescription(
  state: LandingState,
  registrationsSectionDescription: string,
) {
  return { ...state, theme: { ...state.theme, registrationsSectionDescription } };
}

export function updateOurStorySectionTitle(state: LandingState, ourStorySectionTitle: string) {
  return { ...state, theme: { ...state.theme, ourStorySectionTitle } };
}

export function updateThankYouMessage(state: LandingState, thankYouMessage: string) {
  return { ...state, theme: { ...state.theme, thankYouMessage } };
}

export function updateRegistrationsSectionDisplayStyle(
  state: LandingState,
  registrationsSectionDisplayStyle: RegistrationsSectionDisplayStyleType,
) {
  return { ...state, theme: { ...state.theme, registrationsSectionDisplayStyle } };
}
export function resetThemeColors(state: LandingState) {
  return {
    ...state,
    theme: {
      ...state.theme,
      accentColor: INITIAL_THEME.accentColor,
      progressBarEnd: INITIAL_THEME.progressBarEnd,
    },
  };
}
export function set(newState: LandingState) {
  return newState;
}

/** @deprecated The [VSL-212](https://8base-dev.atlassian.net/browse/VSL-212) was redesign by [comment](https://8base-dev.atlassian.net/browse/VSL-212?focusedCommentId=26224). */
export function updateGalleryTitle(state: LandingState, title: string): LandingState {
  return {
    ...state,

    theme: {
      ...state.theme,

      gallerySectionTitle: title,
    },
  };
}

/** @deprecated The [VSL-212](https://8base-dev.atlassian.net/browse/VSL-212) was redesign by [comment](https://8base-dev.atlassian.net/browse/VSL-212?focusedCommentId=26224). */
export function updateGalleryDescription(state: LandingState, description: string): LandingState {
  return {
    ...state,

    theme: {
      ...state.theme,

      gallerySectionDescription: description,
    },
  };
}

export function updateGalleryImagesPerRows(
  state: LandingState,
  imagesPerRows: number,
): LandingState {
  return {
    ...state,

    theme: {
      ...state.theme,

      gallerySectionImagesPerRows: imagesPerRows,
    },
  };
}

export function updateGalleryRows(state: LandingState, rows: number): LandingState {
  return {
    ...state,

    theme: {
      ...state.theme,

      gallerySectionRows: rows,
    },
  };
}

/** @deprecated The [VSL-212](https://8base-dev.atlassian.net/browse/VSL-212) was redesign by [comment](https://8base-dev.atlassian.net/browse/VSL-212?focusedCommentId=26224). */
export function updateGalleryImages(state: LandingState, images: unknown[]): LandingState {
  return {
    ...state,

    theme: {
      ...state.theme,

      gallerySectionImages: images,
    },
  };
}
