import React from 'react';
import { makeStyles } from '@mui/styles';
import { Toolbar, Theme, IconButton, Paper } from '@mui/material';
import { FormatIndentDecrease, FormatIndentIncrease } from '@mui/icons-material';

import { ProfilePopover } from 'src/components/shared';
import { useDrawerContext } from 'src/providers/DrawerProdiver';

import { Breadcrumbs } from './Breadcrumbs';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    gridArea: 'header',
    zIndex: 2,
  },

  userMenuToggle: {
    cursor: 'pointer',
  },

  icon: {
    '& .MuiSvgIcon-root': {
      fill: theme.customPalette.typography.color.default,
    },
  },
}));

export const Header: React.FC = () => {
  const classes = useStyles();
  const { isOpen, toggleOpen } = useDrawerContext();

  return (
    <Paper className={classes.root} square elevation={1}>
      <Toolbar>
        <IconButton className={classes.icon} size="large" onClick={toggleOpen}>
          {isOpen ? <FormatIndentDecrease /> : <FormatIndentIncrease />}
        </IconButton>

        <Breadcrumbs />
        <ProfilePopover />
      </Toolbar>
    </Paper>
  );
};
