import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DeterminateProgress } from 'src/components/shared';

const useStyles = makeStyles<Theme>(theme => ({
  progress: {
    marginRight: theme.spacing(1),
  },
}));

interface ValueGoalProps {
  percentage?: number;
  value?: string | number;
}

export const ValueGoal: React.FC<ValueGoalProps> = ({ value, percentage = 0 }) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography>{value === undefined ? 'N/A' : value}</Typography>
      <Box display="flex">
        <DeterminateProgress
          value={percentage}
          className={classes.progress}
          size={15}
          renderAfter={textColor => (
            <Typography color={textColor} variant="body2">
              {percentage}%
            </Typography>
          )}
        />
      </Box>
    </Box>
  );
};
