import { gql } from '@apollo/client';
import { FILE_URL_FRAGMENT } from '../file';
import { USER_FRAGMENT } from '../users';
import { INDIVIDUAL_FUNDRAISING_MANAGEMENT_FRAGMENT } from '../individualFundraising';
import { TEAM_MEMBERSHIP_FRAGMENT, TEAM_FUNDRAISING_FRAGMENT } from '../teamFundraising';
const INDIVIDUAL_FUNDRAISER_TEMPLATE_TYPE = 'INDIVIDUAL_FUNDRAISER';
export const ATTENDEE_FRAGMENT = gql `
  fragment ATTENDEE_FRAGMENT on Attendee {
    id

    user {
      ...UserFragment

      avatar {
        ...FileURLFragment
      }
    }

    registrationOption {
      id
      name
      description
      fundraisingMinimum
    }

    teamMembership {
      ...TEAM_MEMBERSHIP_FRAGMENT
    }

    experience {
      id
      name
      startDate
      endDate

      venues {
        items {
          id
          name
          address
          notes
        }
      }

      experienceLogoImage {
        ...FileURLFragment
      }

      experienceHeaderImage {
        ...FileURLFragment
      }

      campaign {
        id
        name
        contactEmail
        city
        state

        contactPhone {
          code
          number
        }

        logo {
          ...FileURLFragment
        }

        dnsRecord {
          slug
        }

        campaignTemplates {
          templates(filter: { templateType: { type: { equals: "${INDIVIDUAL_FUNDRAISER_TEMPLATE_TYPE}" } } }) {
            items {
              structure
            }
          }
        }
      }
    }
  }
`;
export const ATTENDEE_MANAGEMENT_QUERY = gql `
  ${FILE_URL_FRAGMENT}
  ${USER_FRAGMENT}
  ${TEAM_FUNDRAISING_FRAGMENT}
  ${TEAM_MEMBERSHIP_FRAGMENT}
  ${INDIVIDUAL_FUNDRAISING_MANAGEMENT_FRAGMENT}
  ${ATTENDEE_FRAGMENT}

  query AttendeeManagement($id: ID!) {
    attendee(id: $id) {
      ...ATTENDEE_FRAGMENT

      fundraising {
        ...INDIVIDUAL_FUNDRAISING_MANAGEMENT_FRAGMENT
      }
    }
  }
`;
export const REGISTER_ATTENDEES = gql `
  mutation ShoppingCartRegisterAttendees($data: [ShoppingCartRegisterAttendeesManyInput!]!) {
    attendees: shoppingCartRegisterAttendees(data: $data) {
      items {
        id
        user {
          id
        }
      }
    }
  }
`;
export const ATTENDEES_BY_EXPERIENCE_LANDING = gql `
  query AttendeesByExperienceLanding($id: ID!, $first: Int, $skip: Int) {
    experienceLandingPeopleSectionAttendees(experienceId: $id, first: $first, skip: $skip) {
      items {
        id
        user {
          id
          firstName
          lastName
          email
          avatar {
            downloadUrl
          }
        }
        registrationOption {
          id
          name
        }
      }
    }
  }
`;
