import { gql } from '@apollo/client';

import { TeamRole } from 'src/types/team';

export const ORGANIZATION_STATISTICS_QUERY = gql`
  query OrganizationStatistics($organizationId: ID!) {
    attendeesCount: attendeesList(
      filter: {
        experience: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
      }
    ) {
      count
    }
    donorsCount: donorsList(
      filter: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
    ) {
      count
    }
    fundraisersCount: attendeesList(
      filter: {
        experience: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
        fundraisingPageLink: { is_not_empty: true }
      }
    ) {
      count
    }
    teamsCount: teamFundraisingsList(
      filter: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
    ) {
      count
    }
  }
`;

const BASE_USER_FRAGMENT = gql`
  fragment BaseUserFragment on User {
    id
    firstName
    lastName
    avatar {
      id
      downloadUrl
    }
    attendees {
      items {
        id
        teamMembership {
          team {
            id
            name
          }
        }
        totalRaised
        experience {
          campaign {
            id
          }
        }
      }
    }
  }
`;

export const OGRANIZATION_FILTERED_USERS_LIST_QUERY = gql`
  query OrganizationFilteredUsersList($filter: UserFilter, $skip: Int, $first: Int) {
    usersList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        ...BaseUserFragment
      }
    }
  }
  ${BASE_USER_FRAGMENT}
`;

export const ORGANIZATION_FILTERED_DONORS_LIST_QUERY = gql`
  query OrganizationFilteredDonorsList($filter: UserFilter, $skip: Int, $first: Int) {
    usersList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        firstName
        lastName
        avatar {
          id
          downloadUrl
        }
        attendees {
          items {
            id
            teamMembership {
              team {
                id
                name
              }
            }
            totalRaised
          }
        }
        donors {
          items {
            id
            campaign {
              id
            }
          }
        }
      }
    }
  }
`;

export const OGRANIZATION_TEAMS_LIST_QUERY = gql`
  query OrganizationTeamsList($filter: TeamFundraisingFilter, $skip: Int, $first: Int) {
    teamFundraisingsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        name
        avatar {
          id
          downloadUrl
        }
        captain: members(filter: { role: { equals: "${TeamRole.TeamCaptain}" } }, first: 1) {
          items {
            id
            attendee {
              id
              user {
                id
                firstName
                lastName
              }
            }
          }
          count
        }
        membersCount: members {
          count
        }
        totalRaised
        createdAt
        webLink
      }
    }
  }
`;

export const UserContactInfo = gql`
  query UserContactInfo($userId: ID!) {
    user(id: $userId) {
      id
      firstName
      lastName
      createdAt
      status
      avatar {
        id
        downloadUrl
      }
      campaignStaff {
        items {
          campaign {
            id
            campaignStatus
          }
        }
      }
      donors {
        count
      }
      attendees {
        items {
          id
          totalRaised
          experience {
            campaign {
              id
              campaignStatus
            }
          }
        }
      }
      roles {
        items {
          id
          name
        }
      }
    }
  }
`;

export const UserTeamsInfo = gql`
  query UserTeamsInfo($first: Int, $skip: Int, $search: String, $userId: ID!) {
    teamFundraisingsList(
      filter: {
        OR: [
          { campaign: { name: { contains: $search } } }
          { experience: { name: { contains: $search } } }
          { members: { some: { attendee: { user: { fullName: { contains: $search } } } } } }
          { name: { contains: $search } }
        ]
        members: { some: { attendee: { user: { id: { equals: $userId } } } } }
      }
      first: $first
      skip: $skip
    ) {
      items {
        id
        avatar {
          downloadUrl
          fileId
          __typename
        }
        name
        avatarName: members(
          filter: { attendee: { user: { id: { equals: $userId } } } }
          groupBy: {
            query: {
              team: {
                name: { as: "teamName" }
                avatar: { downloadUrl: { as: "teamAvatarDownloadUrl" } }
              }
            }
          }
        ) {
          groups {
            teamName: String
            teamAvatarDownloadUrl: String
            __typename
          }
        }
        selfMember: members(filter: { attendee: { user: { id: { equals: $userId } } } }) {
          count
          items {
            id
            createdAt
            role
            __typename
          }
          __typename
        }
        members {
          count
          __typename
        }
        captain: members(
          groupBy: { query: { attendee: { user: { fullName: { as: "fullName" } } } } }
          filter: { role: { equals: "Team Captain" } }
        ) {
          groups {
            fullName: String
            __typename
          }
          __typename
        }
        campaign {
          name
          __typename
        }
        experience {
          name
          __typename
        }
        fundraiserGoal
        __typename
      }
      count
      __typename
    }
  }
`;

export const USER_CONTACT_PROFILE_INFO_QUERY = gql`
  query UserContactProfileInfo($userId: ID!) {
    user(id: $userId) {
      id
      firstName
      lastName
      email
      phone {
        code
        number
      }
      birthDate
      address {
        city
        country
        state
        street1
        street2
        zip
      }
    }
  }
`;

export const USER_CONTACT_PROFILE_UPDATE_MUTATION = gql`
  mutation CRMContactUpdate($data: CRMContactUpdateInput!) {
    cRMContactUpdate(data: $data) {
      id
    }
  }
`;

export const CONTACT_CRM_CREATE_MUTATION = gql`
  mutation CRMContactCreate($data: CRMContactCreateInput!) {
    cRMContactCreate(data: $data) {
      createdAt
    }
  }
`;

export const USER_CONTACT_QUERY = gql`
  query CRMContactsList($skip: Int, $first: Int, $filter: CRMContactFilter) {
    cRMContactsList(skip: $skip, first: $first, filter: $filter) {
      items {
        id
        firstName
        lastName
        middleName
        email
        phone1 {
          code
          number
        }
        phone2 {
          code
          number
        }
        note
        address {
          street1
          street2
          city
          state
          country
          zip
        }
      }
      count
    }
  }
`;

export const CONTACTS_CRM_CREATE_MANY_MUTATION = gql`
  mutation CRMContactCreateMany($data: [CRMContactCreateManyInput]!) {
    cRMContactCreateMany(data: $data) {
      items {
        id
      }
    }
  }
`;

export const IMPORT_CRM_CREATE_MANY_MUTATION = gql`
  mutation ImportUsersFromCSV($data: [JSON!]!) {
    importUsersFromCSV(data: $data) {
      success
      data {
        row
        message
      }
    }
  }
`;
