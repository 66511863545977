import React from 'react';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import { PricingType } from 'src/types/pricingSettings';

import { FixedPriceTierField, ScaledPriceTiersField } from './components';

interface PricingTiersFieldProps {
  variant: PricingType;
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  disabled?: boolean;
}

export const PricingTiersField: React.FC<PricingTiersFieldProps> = ({
  input,
  variant,
  disabled,
  ...rest
}) => {
  if (disabled) {
    return null;
  }

  if (variant === PricingType.Fixed) {
    return <FixedPriceTierField input={input} {...rest} />;
  }

  return <ScaledPriceTiersField input={input} {...rest} />;
};
