import React from 'react';
import { Grid } from '@mui/material';

import { Button, FiltersLayout } from '@vizsla/components';
import { useModal, usePopover } from '@vizsla/hooks';

import { Shade } from 'src/theme';
import { PeopleCRMInfoCards, PeopleCRMTabs } from 'src/components/peopleCRM';
import { MODALS } from 'src/constants/modals';
import { ContactImportModal } from 'src/modals/peopleCRM';

const PeopleCRMLayout: React.FC = () => {
  const { openModal } = useModal();
  return (
    <FiltersLayout
      withSearch
      withPagination
      rightComponent={
        <React.Fragment>
          <Grid item>
            <Button
              onClick={() => openModal(MODALS.PEOPLE_CRM_IMPORT_MODAL)}
              color="inherit"
              sx={{ backgroundColor: Shade.Gray[50], color: Shade.Gray[600] }}
            >
              Import file
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => openModal(MODALS.PEOPLE_CRM_ADD_MODAL)}
              sx={{ backgroundColor: Shade.Blue[500] }}
            >
              Add people
            </Button>
          </Grid>
        </React.Fragment>
      }
    >
      <ContactImportModal />
      <Grid container spacing={4} direction="column" width="auto">
        <Grid item>
          <PeopleCRMInfoCards />
        </Grid>

        <Grid item>
          <PeopleCRMTabs />
        </Grid>
      </Grid>
    </FiltersLayout>
  );
};

export { PeopleCRMLayout };
