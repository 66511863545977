import React from 'react';

import * as appRoutes from 'src/constants/routes';

import { DrawerSidebar } from '../../components/layout/drawer';

const MENU_ITEMS = [
  {
    title: 'Home',
    icon: 'Home',
    path: appRoutes.home,
  },
  {
    title: 'Inbox',
    icon: 'Inbox',
    path: appRoutes.inbox,
  },
  {
    title: 'Campaigns',
    icon: 'Flag',
    path: appRoutes.campaigns,
  },
  {
    title: 'Transactions',
    icon: 'MonetizationOn',
    path: appRoutes.transactions,
  },

  {
    title: 'People',
    icon: 'Face',
    path: appRoutes.crm,
  },
  {
    title: 'Registered/RegisterMe',
    icon: 'HowToReg',
    path: appRoutes.checkOrRegister,
  },
  {
    title: 'Assets',
    icon: 'Assignment',
    path: appRoutes.storeAssets,
  },
  {
    title: 'Partnerships',
    icon: 'SwitchAccount',
    path: appRoutes.partnerships,
  },
];

const LOWER_MENU_ITEMS = [
  {
    title: 'Settings',
    icon: 'SettingsOutlined',
    path: appRoutes.organizationSettings,
  },
];

export const Sidebar: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  return (
    <DrawerSidebar isLoading={isLoading} upperItems={MENU_ITEMS} lowerItems={LOWER_MENU_ITEMS} />
  );
};
