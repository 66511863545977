import React from 'react';
import { Chip, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { StripeIntegrationStatus } from '@vizsla/stripe';

import { FontWeight, PaletteColor } from 'src/theme';
import { STRIPE_STATUS_PALETTE } from 'src/constants/stripe';

type Size = 'small' | 'medium';

interface StylesParams {
  size: Size;
}

const useStyles = makeStyles<Theme, StylesParams>(() => ({
  chip: {
    color: PaletteColor.White,
    fontWeight: ({ size }) => Number(size === 'medium' ? FontWeight.Bold : FontWeight.Regular),
  },
  [StripeIntegrationStatus.Connected]: {
    background: STRIPE_STATUS_PALETTE[StripeIntegrationStatus.Connected],
  },
  [StripeIntegrationStatus.Disconnected]: {
    background: STRIPE_STATUS_PALETTE[StripeIntegrationStatus.Disconnected],
  },
}));

interface OrganizationStripeConnectionStatusChipProps {
  status?: string | null;
  size?: Size;
}

export const OrganizationStripeConnectionStatusChip: React.FC<
  OrganizationStripeConnectionStatusChipProps
> = ({ status, size = 'small' }) => {
  const classes = useStyles({ size });

  const renderStatus = () => {
    if (_.isNil(status)) {
      return (
        <Typography variant="subtitle2" textAlign="center">
          N/A
        </Typography>
      );
    }

    return <Chip size={size} label={status} className={clsx(classes.chip, classes[status])} />;
  };

  return (
    <React.Fragment>
      <Typography variant="body2" textAlign="center">
        Status:
      </Typography>
      {renderStatus()}
    </React.Fragment>
  );
};
