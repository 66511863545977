import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { AddSharp as AddIcon } from '@mui/icons-material';

import { useModal } from '@vizsla/hooks';
import { ExperienceBibPool, ExperienceBibAssignment } from '@vizsla/graphql';
import { NumberField, BottomActionButton, GridDefaultPadding } from '@vizsla/components';

import { BASE_NUMBER_FORMAT } from 'src/constants/formats';
import { validateBibRanges } from 'src/modals/experienceBibPoolCreateEditModals/utils/experienceBibPoolFormUtils';
import { useExperienceId, useExperienceBibAssignment } from 'src/hooks/experiences';
import { PaletteColor } from 'src/theme';
import { MODALS } from 'src/constants/modals';
import { FloatingCloseButton } from 'src/components/shared';

const useStyles = makeStyles(() => ({
  floatingButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

export const ExperienceBibPoolFormRangesSection: React.FC = () => {
  const classes = useStyles();
  const experienceId = useExperienceId();
  const { args } = useModal(MODALS.BIB_POOL_UPDATE_MODAL);

  const {
    bibPoolsList,
    excludedBibAssignments,
    individualBibAssignments,
    allowExcludedBibNumbers,
    allowIndividualBibAssignment,
  } = useExperienceBibAssignment(experienceId);

  const currentBibPoolId = args?.bibPool?.id ?? '';
  const bibRanges = bibPoolsList
    .map((bibPool: ExperienceBibPool) =>
      bibPool?.id !== currentBibPoolId ? bibPool?.bibRanges : [],
    )
    .flat();
  const excludedBibNumbers = allowExcludedBibNumbers
    ? excludedBibAssignments.map(
        (excludedBibNumber: ExperienceBibAssignment) => excludedBibNumber?.bibNumber,
      )
    : [];
  const individualBibNumbers = allowIndividualBibAssignment
    ? individualBibAssignments.map(
        (individualBibAssignment: ExperienceBibAssignment) => individualBibAssignment?.bibNumber,
      )
    : [];

  return (
    <FormSpy subscription={{ submitting: true, values: true }}>
      {({
        submitting,
        form: {
          mutators: { push },
        },
      }) => {
        return (
          <Grid container spacing={2}>
            <GridDefaultPadding item xs={12}>
              <Typography variant="h6">Bib Numbers</Typography>
              <Typography variant="body2">Assign bib number ranges to this pool.</Typography>
            </GridDefaultPadding>
            <FieldArray
              name="bibRanges"
              validate={value =>
                validateBibRanges(value, {
                  context: {
                    bibRanges,
                    excludedBibNumbers,
                    individualBibNumbers,
                  },
                })
              }
            >
              {({ fields, meta: { error, pristine } }) =>
                fields.map((name, index) => (
                  <Grid item xs={12} key={name}>
                    <Grid container>
                      <Grid item xs={10}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Field
                              required
                              name={`${name}.startNumber`}
                              label="Start Number"
                              component={NumberField}
                              numberFormat={BASE_NUMBER_FORMAT}
                              disabled={submitting}
                              fullWidth
                            />
                            {!pristine && error?.[index] && (
                              <Typography variant="caption" color={PaletteColor.ErrorMain}>
                                {error[index]?.startNumberMessage}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              required
                              name={`${name}.endNumber`}
                              label="End Number"
                              component={NumberField}
                              numberFormat={BASE_NUMBER_FORMAT}
                              disabled={submitting}
                              fullWidth
                            />
                            {!pristine && error?.[index] && (
                              <Typography variant="caption" color={PaletteColor.ErrorMain}>
                                {error[index]?.endNumberMessage}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      {fields.value.length > 1 ? (
                        <Grid item xs={2} className={classes.floatingButton}>
                          <FloatingCloseButton
                            size="medium"
                            onClick={() => fields.remove(index)}
                            disabled={submitting}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                    <Grid item>
                      {error?.[index] && (
                        <Typography variant="caption" color={PaletteColor.ErrorMain}>
                          {error[index]?.message}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ))
              }
            </FieldArray>
            <Grid item xs={12}>
              <BottomActionButton
                onClick={() => push('bibRanges', { startNumber: undefined, endNumber: undefined })}
                icon={<AddIcon />}
                disabled={submitting}
              >
                Add Bib Number Range
              </BottomActionButton>
            </Grid>
          </Grid>
        );
      }}
    </FormSpy>
  );
};
