import { useParams } from 'react-router-dom';
import { useTopDonationExperienceQuery } from '@vizsla/graphql';
export function useSelectedTeamTopFundraisersDonations() {
    var _a, _b;
    const { experienceId } = useParams();
    const { data: response, loading, refetch, } = useTopDonationExperienceQuery({
        skip: !experienceId,
        variables: { id: experienceId },
    });
    const data = ((_b = (_a = response === null || response === void 0 ? void 0 : response.topDonationByExperience) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : []);
    return {
        data,
        loading,
        refetch,
    };
}
