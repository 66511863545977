import React from 'react';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import { SwitchCard } from 'src/components/shared';

interface SwitchCardFieldProps {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
}

export const SwitchCardField: React.FC<SwitchCardFieldProps> = ({ input, meta, ...rest }) => {
  const { name, value, onBlur, onChange } = input;

  const handleChange = React.useCallback(
    e => {
      onChange(e.target.checked);
    },
    [onChange],
  );

  return (
    <SwitchCard switchProps={{ name, checked: value, onBlur, onChange: handleChange }} {...rest} />
  );
};
