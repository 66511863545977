var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { isNil } from 'lodash';
import { useShoppingCartRegisterAttendeesMutation, } from '@vizsla/graphql';
import { useShoppingCartState } from './useShoppingCart';
import { selectAllRegistrations } from './selectors';
const selectShoppingCartRegistrationState = state => [selectAllRegistrations(state), state.editMany];
export function useShoppingCartRegistrations() {
    const [registrations, editMany] = useShoppingCartState(selectShoppingCartRegistrationState);
    const [executeRegisterAttendees] = useShoppingCartRegisterAttendeesMutation();
    /**
     * Register all the entries into `Attendee` and finally updates the state if `cart` is set as param.
     * @param cart A filtered version of the shopping cart items, if not was passed the function will use the actual shopping cart state.
     */
    const registerAttendees = useCallback((cart) => __awaiter(this, void 0, void 0, function* () {
        const registrationItems = cart !== null && cart !== void 0 ? cart : registrations;
        if (registrationItems.length === 0) {
            throw new TypeError(`The registration option array must have at least one item`);
        }
        const payload = registrationItems
            .filter(i => !isNil(i.attendee))
            .map(item => ({
            registrationOptionId: item.option.id,
            waiverSignature: item.attendee.waiverSignature,
            user: {
                firstName: item.attendee.firstName,
                lastName: item.attendee.lastName,
                email: item.attendee.email,
                phone: { code: '+1', number: item.attendee.phone },
                address: {
                    state: item.attendee.state,
                    city: item.attendee.city,
                    street1: item.attendee.address,
                    zip: item.attendee.zip,
                },
            },
        }));
        const response = yield executeRegisterAttendees({
            variables: { data: payload },
        });
        if (!response.data) {
            throw new Error('Failed Attendee registration');
        }
        editMany(registrationItems);
        return response.data.attendees;
    }), [editMany, executeRegisterAttendees, registrations]);
    return {
        items: registrations,
        registerAttendees,
    };
}
