import React from 'react';

import { PartnershipsTableView } from './PartnershipsTableView';
import { PartnershipInfoCards } from './PartnershipsInfoCards';
import { PartnershipContentWrapper } from './styles';

export function PartnershipsListContent() {
  return (
    <PartnershipContentWrapper>
      <PartnershipInfoCards />
      <PartnershipsTableView />
    </PartnershipContentWrapper>
  );
}
