import React, { useCallback, useMemo } from 'react';
import { FileInput } from '8base-react-sdk';

import { FILE_IMAGE_MIME_TYPES, FILE_MAX_SIZE } from '@vizsla/constants';
import { File } from '@vizsla/graphql';

import { EMPTY_IMAGE } from 'src/assets';

import {
  Button,
  Buttons,
  Container,
  Content,
  ImageLogo,
  Loader,
  ImageEmpty,
} from './ImageSelector.style';

const DEFAULT_IMAGE_SIZE = 160;

interface ImageSelectorProps {
  /**
   * Sets the size of the image in pixels.
   * @default 160
   */
  size?: number;

  /**
   * Sets if image must be rounded.
   * @default false
   */
  rounded?: boolean;

  /** URI of the resource. */
  uri?: string;

  /** Sets if the resource is loading. */
  loading?: boolean;

  /** Sets if the resource cannot be edited. */
  disabled?: boolean;

  onChange?(file?: File): void;
}

export function ImageSelector(props: ImageSelectorProps) {
  const reset = () => {
    props.onChange?.(undefined);
  };

  const disabled = useMemo(() => props.loading || props.disabled, [props.loading, props.disabled]);

  const createOpenLibrary = useCallback(
    (picker: (options: any) => Promise<void>) => async () => {
      if (disabled) return;

      await picker({
        maxSize: FILE_MAX_SIZE,
        accept: FILE_IMAGE_MIME_TYPES,
        fromSources: ['local_file_system'],
      });
    },

    [disabled],
  );

  const changeImage = (file: File | File[]) => {
    const imageFile = Array.isArray(file) ? file[0] : file;
    props.onChange?.(imageFile);
  };

  return (
    <FileInput onChange={changeImage}>
      {wrapper => (
        <Container>
          <Content>
            {props.uri ? (
              <ImageLogo
                src={props.uri ?? EMPTY_IMAGE}
                size={props.size ?? DEFAULT_IMAGE_SIZE}
                rounded={props.rounded}
              />
            ) : (
              <ImageEmpty
                src={props.uri ?? EMPTY_IMAGE}
                size={props.size ?? DEFAULT_IMAGE_SIZE}
                rounded={props.rounded}
              />
            )}

            {props.loading && <Loader />}
          </Content>

          <Buttons>
            <Button disabled={disabled} onClick={createOpenLibrary(wrapper.pick)}>
              {props.uri ? 'Change' : 'Add a image'}
            </Button>

            {props.uri && (
              <Button disabled={disabled} onClick={reset}>
                Reset
              </Button>
            )}
          </Buttons>
        </Container>
      )}
    </FileInput>
  );
}
