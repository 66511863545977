import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close as CloseIcon } from '@mui/icons-material';

const PREVIEW_SIZE = 48;

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'inline-flex',
    flexWrap: 'nowrap',
    gap: '1rem',
    alignItems: 'center',
  },

  preview: {
    width: PREVIEW_SIZE,
    height: PREVIEW_SIZE,
    display: 'block',
    appearance: 'none',
    border: 'none',
    borderRadius: 4,
    background: '#e3e3e3',
  },

  description: {
    width: '100%',
    userSelect: 'none',
    flex: 1,
  },

  name: {
    display: 'inline-block',
    width: '14rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

interface ImageSelectedProps {
  name?: string;
  url?: string;

  onRemove?: () => void;
}

export function ImageSelected(props: ImageSelectedProps) {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <img src={props.url} className={styles.preview} />

      <div className={styles.description}>
        <Typography variant="caption" className={styles.name}>
          {props.name}
        </Typography>
      </div>

      <CloseIcon color="disabled" onClick={props.onRemove} />
    </div>
  );
}
