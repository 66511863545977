import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';

import { buildUrl } from '@vizsla/utils';
import { Campaign } from '@vizsla/graphql';

import * as entitiesId from 'src/constants/entities-id';
import * as appRoutes from 'src/constants/routes';

import { CampaignTileContent } from './CampaignTileContent';

interface CampaignTileProps {
  campaign: Campaign;
  hideMetrics?: boolean;
  activeLink?: boolean;
}

export const CampaignTile: React.FC<CampaignTileProps> = ({
  campaign,
  hideMetrics = false,
  activeLink = false,
}) => {
  const campaignUrl = buildUrl(appRoutes.campaignDashboard, {
    [entitiesId.campaign]: campaign.id as string,
  });

  if (activeLink) {
    return (
      <Link component={RouterLink} to={campaignUrl} style={{ textDecoration: 'none' }}>
        <CampaignTileContent campaign={campaign} hideMetrics={hideMetrics} />
      </Link>
    );
  }

  return <CampaignTileContent campaign={campaign} hideMetrics={hideMetrics} />;
};
