import React, { useContext } from 'react';

import { ThankYouPageContext } from 'src/providers/campaign/ThankYouContext';

import { CollapsingList } from './CollapsingList';
import {
  ThankYouMessage,
  DonationInformationSection,
  ManageDonationSection,
  HelpSection,
} from './sections';

export const ContentSection = () => {
  const { loading } = useContext(ThankYouPageContext);

  return (
    <React.Fragment>
      <CollapsingList loading={loading} title="Thank You Message">
        <ThankYouMessage />
      </CollapsingList>
      <CollapsingList loading={loading} title="Donation Information">
        <DonationInformationSection />
      </CollapsingList>
      <CollapsingList loading={loading} title="Manage your Donation">
        <ManageDonationSection />
      </CollapsingList>
      <CollapsingList loading={loading} title="Help">
        <HelpSection />
      </CollapsingList>
    </React.Fragment>
  );
};
