import React from 'react';
import { Typography, Grid } from '@mui/material';
import { useLocation, matchPath, Link } from 'react-router-dom';

import { getLocationPaths } from '@vizsla/utils';
import {
  useCurrentOrganization,
  useExperienceById,
  useCurrentUser,
  useOrganizationById,
  useOrganizationId,
} from '@vizsla/hooks';

import * as appRoutes from 'src/constants/routes';
import { useCampaign, useCampaignId } from 'src/hooks/campaign';
import { useExperienceId } from 'src/hooks/experiences';

interface BreadcrumbsPathProps {
  path: string;
  index: number;
  campaignName: string | undefined | null;
  experienceName: string | undefined | null;
}

const getMenuItems = (
  campaignName: string | null | undefined,
  experienceName: string | null | undefined,
) => {
  return [
    {
      title: 'Home',
      path: appRoutes.home,
    },
    {
      title: 'Inbox',
      path: appRoutes.inbox,
    },
    {
      title: 'Transactions',
      path: appRoutes.transactions,
    },
    {
      title: 'Campaigns',
      path: appRoutes.campaigns,
    },
    {
      title: 'People',
      path: appRoutes.crm,
    },
    {
      title: 'Registered/RegisterMe',
      path: appRoutes.checkOrRegister,
    },
    {
      title: 'Assets',
      path: appRoutes.storeAssets,
    },
    {
      title: 'Partnerships',
      path: appRoutes.partnerships,
    },
    {
      title: 'Details',
      path: appRoutes.partnershipDetails,
    },
    {
      title: campaignName ?? '...',
      path: appRoutes.campaign,
    },
    {
      title: experienceName ?? '...',
      path: appRoutes.campaignExperience,
    },
  ];
};

const BreadcrumbsPath: React.FC<BreadcrumbsPathProps> = ({
  path,
  index,
  campaignName,
  experienceName,
}) => {
  const menuItems = getMenuItems(campaignName, experienceName);
  const area = menuItems.find(item => Boolean(matchPath(item.path, path)));
  const { user, loading: userIsLoading } = useCurrentUser();
  const organizationId =
    user?.vizslaOrganizationStaff?.items[0]?.vizslaOrganization?.id || undefined;
  const { organization, organizationIsLoading } = useOrganizationById(organizationId);

  if (!area) {
    return null;
  }

  return (
    <React.Fragment>
      {index === 0 && (
        <Grid marginTop={1} marginRight={4} marginLeft={1}>
          <Typography variant="button">{organization?.name}</Typography>
        </Grid>
      )}
      <React.Fragment>
        {index > 0 && (
          <Grid key="/" item>
            <Typography color="textSecondary">&#8594;</Typography>
          </Grid>
        )}

        <Grid key="item" item>
          <Typography component={Link} to={path} color="textSecondary">
            {area.title}
          </Typography>
        </Grid>
      </React.Fragment>
    </React.Fragment>
  );
};

interface BreadcrumbsProps {}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = () => {
  const location = useLocation();

  const paths = getLocationPaths(location.pathname, 1);

  const campaignId = useCampaignId();
  const { data: campaign } = useCampaign(campaignId);
  const campaignName = campaign?.name;

  const experienceId = useExperienceId();
  const { experience } = useExperienceById(experienceId);
  const experienceName = experience?.name;

  return (
    <Grid container spacing={1}>
      {paths.map((path, index) => (
        <BreadcrumbsPath
          key={path}
          path={path}
          index={index}
          campaignName={campaignName}
          experienceName={experienceName}
        />
      ))}
    </Grid>
  );
};
