import { Mutator } from 'final-form';
import { FieldMetaState } from 'react-final-form';
import { isEmpty } from 'lodash';

export const hasFieldError = (meta: FieldMetaState<any> = {}): boolean => {
  const { submitError, dirtySinceLastSubmit, error, touched } = meta;

  const hasAnyError = Boolean(error) && !isEmpty(error);
  const hasSubmitError = Boolean(submitError);

  return (hasAnyError || hasSubmitError) && !!touched && !dirtySinceLastSubmit;
};

export const getFieldError = (meta: FieldMetaState<any> = {}): string | null => {
  if (hasFieldError(meta)) {
    const { submitError, error } = meta;

    const errorToShow = error || submitError;

    if (typeof errorToShow === 'object') {
      const arrayOfErrors: Array<any> = Object.values(errorToShow);
      return arrayOfErrors.join(' ');
    }

    if (Array.isArray(errorToShow)) {
      return errorToShow.join(' ');
    }

    return errorToShow || null;
  }

  return null;
};

/**
 * @example
 * <Form
 *   mutators={{ ...resetFieldMutators() }}
 * />
 * ...
 * const { mutators } = useForm();
 * mutators.resetField('price');
 */

export const resetFieldMutators = () => {
  const resetField: Mutator = ([fieldName], state, { changeValue, resetFieldState }) => {
    changeValue(state, fieldName, () => undefined);
    resetFieldState(fieldName);
  };

  return {
    resetField,
  };
};
