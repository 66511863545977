import React from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { Grid, Popover } from '@mui/material';

import { FontSize, FontWeight, PaletteColor } from '@vizsla/theme';
import { usePopover } from '@vizsla/hooks';

const useStyles = makeStyles(() => ({
  colorInput: {
    display: 'block',
    boxSizing: 'border-box',
    border: '1px solid #C0C4C9',
    font: 'inherit',
    fontWeight: FontWeight.Regular,
    fontSize: FontSize.Default,
    color: PaletteColor.DefaultText,
    borderRadius: '4px',
    outline: 'none',
    textAlign: 'left',
    width: '108px',
    padding: '11px 0 11px 26px',
    height: '40px',
    '&:focus': {
      borderColor: PaletteColor.PrimaryGray,
    },
  },
  hexSign: {
    fontSize: FontSize.Default,
    color: PaletteColor.DefaultMain,
    position: 'absolute',
    left: '12px',
    top: '12px',
  },
  root: {
    position: 'relative',
  },
  popover: {
    marginTop: '5px',
  },
  contentWrap: {
    width: '100%',
  },
  colorPicker: {
    '&.react-colorful': {
      padding: '12px',
      height: '150px',
      width: '216px',
    },

    '&>.react-colorful__saturation': {
      borderRadius: '8px',
      borderBottom: 'none',
      marginBottom: '16px',
    },

    '&>.react-colorful__saturation .react-colorful__saturation-pointer': {
      width: '16px',
      height: '16px',
    },

    '&>.react-colorful__hue': {
      height: '10px',
      borderRadius: '5px',
    },

    '&>.react-colorful__hue .react-colorful__hue-pointer': {
      width: '15px',
      height: '15px',
    },
  },
}));

interface ColorInputFieldProps {
  color: string;
  onChange: (color: string) => void;
}

export const ColorInputField: React.FC<ColorInputFieldProps> = ({ color, onChange }) => {
  const classes = useStyles();
  const popover = usePopover();

  const handleFocusOnColorInput = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (!popover.isOpen) {
      popover.openPopover(event as any);
    }
  };

  const debouncedOnChange = _.debounce(onChange, 100);

  return (
    <div className={classes.root}>
      <span className={classes.hexSign}>#</span>
      <HexColorInput
        color={color}
        onChange={onChange}
        className={classes.colorInput}
        onClick={popover.openPopover}
        onFocus={handleFocusOnColorInput}
      />
      <Popover
        anchorEl={popover.el}
        open={popover.isOpen}
        onClose={popover.closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.popover}
        disableAutoFocus
        disableEnforceFocus
      >
        <Grid className={classes.contentWrap} container>
          <Grid item>
            <HexColorPicker
              className={classes.colorPicker}
              color={color}
              onChange={debouncedOnChange}
            />
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
};
