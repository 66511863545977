import React from 'react';
import { Grid, Tab, Theme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { Add } from '@mui/icons-material';

import { useModal } from '@vizsla/hooks';
import { TableData } from '@vizsla/components';
import type { TableBottomAction, TableColumn, TableRowAction } from '@vizsla/components';
import { CampaignFaq } from '@vizsla/graphql';
import { CampaignFaqAllocation } from '@vizsla/types';

import { PaperBlock } from 'src/components/shared';
import {
  CampaignFaqAddDialog,
  CampaignFaqEditDialog,
  CampaignFaqTableRecord,
} from 'src/components/campaign';
import { useCampaignFAQs, useCampaignId } from 'src/hooks/campaign';
import { MODALS } from 'src/constants/modals';

const useStyles = makeStyles((theme: Theme) => ({
  tabPanel: {
    padding: 0,
  },
}));

const TABLE_COLUMNS: Array<TableColumn<CampaignFaq>> = [
  {
    title: 'Question',
    key: 'title',
    render: (_: any, campaignFaq: CampaignFaq) => (
      <CampaignFaqTableRecord campaignFaq={campaignFaq} />
    ),
  },
];

export const CampaignFAQs: React.FC = () => {
  const classes = useStyles();

  const campaignId = useCampaignId();
  const { deleteCampaignFaq } = useCampaignFAQs(campaignId);
  const { splittedFaqsList, loading } = useCampaignFAQs(campaignId);

  const { openModal } = useModal();

  const [activeTab, setActiveTab] = React.useState<CampaignFaqAllocation>(
    CampaignFaqAllocation.Attendee,
  );

  const rowActions: Array<TableRowAction<CampaignFaq>> = [
    {
      actionName: 'Edit',
      onAction: (campaignFaq: CampaignFaq) => {
        openModal(MODALS.CAMPAIGN_FAQ_EDIT_MODAL, { campaignFaq });
      },
    },
    {
      actionName: 'Delete',
      onAction: (campaignFaq: CampaignFaq) => {
        openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
          deleteFunction: () => deleteCampaignFaq(campaignFaq?.id as string),
          objectName: campaignFaq?.question,
          objectType: 'FAQ',
        });
      },
    },
  ];

  const bottomActions: Array<TableBottomAction> = [
    {
      actionName: 'Add FAQ',
      icon: <Add />,
      onAction: () => openModal(MODALS.CAMPAIGN_FAQ_ADD_MODAL),
    },
  ];

  const handleTabSwitch = (_: React.ChangeEvent<any>, newValue: CampaignFaqAllocation) => {
    setActiveTab(newValue);
  };

  return (
    <PaperBlock title="FAQs" collapsing defaultOpened>
      <TabContext value={activeTab}>
        <Grid container justifyContent="center" marginBottom={3}>
          <TabList onChange={handleTabSwitch}>
            <Tab label={CampaignFaqAllocation.Attendee} value={CampaignFaqAllocation.Attendee} />
            <Tab label={CampaignFaqAllocation.Donor} value={CampaignFaqAllocation.Donor} />
          </TabList>
        </Grid>
        <TabPanel value={CampaignFaqAllocation.Attendee} className={classes.tabPanel}>
          <TableData
            dataSource={splittedFaqsList[CampaignFaqAllocation.Attendee]}
            columns={TABLE_COLUMNS}
            loading={loading}
            rowActions={rowActions}
            bottomActions={bottomActions}
          />
        </TabPanel>
        <TabPanel value={CampaignFaqAllocation.Donor} className={classes.tabPanel}>
          <TableData
            dataSource={splittedFaqsList[CampaignFaqAllocation.Donor]}
            columns={TABLE_COLUMNS}
            loading={loading}
            rowActions={rowActions}
            bottomActions={bottomActions}
          />
        </TabPanel>
      </TabContext>
      <CampaignFaqAddDialog />
      <CampaignFaqEditDialog />
    </PaperBlock>
  );
};
