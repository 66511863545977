import React from 'react';
import { Grid } from '@mui/material';

import { PaperBlock } from 'src/components/shared';
import { useExperienceId } from 'src/hooks/experiences';
import { useEarlyBirdRegistrationSettings } from 'src/hooks/earlyBirdRegistration';
import { EarlyBirdRegistrationsMode } from 'src/types/earlyBirdRegistration';

import { EarlyBirdRegistrationInvitations, EarlyBirdRegistrationOptions } from './components';
import { FORM_SPACING } from './constants';

const ExperienceEarlyBirdRegistration: React.FC = () => {
  const experienceId = useExperienceId();

  const { earlyBirdRegistrationSettings, updateEarlyBirdRegistrationSettings, loading, updating } =
    useEarlyBirdRegistrationSettings(experienceId);

  const earlyBirdRegistrationAvailable = Boolean(
    earlyBirdRegistrationSettings?.earlyBirdRegistrationAvailable,
  );

  const earlyBirdRegistrationsMode =
    earlyBirdRegistrationSettings?.earlyBirdRegistrationsMode as EarlyBirdRegistrationsMode;

  const handleSwitchRegistrationAvailable = async (checked: boolean) => {
    await updateEarlyBirdRegistrationSettings({
      earlyBirdRegistrationAvailable: checked,
    });
  };

  // To ensure that the correct value of earlyBirdRegistrationAvailable is passed for the
  // defaultOpened property, here we first render an empty PaperBlock with a "paperblock_loading" key,
  // and then, when the settings data is received, we render PaperBlock with a "paperblock_ready" key

  if (loading) {
    return (
      <PaperBlock
        key="paperblock_loading"
        loading
        title="Early Bird Registration"
        switchProps={{
          checked: false,
          onSwitch: () => undefined,
        }}
        defaultOpened={false}
        collapsing
      />
    );
  }

  return (
    <PaperBlock
      key="paperblock_ready"
      loading={updating}
      title="Early Bird Registration"
      switchProps={{
        checked: earlyBirdRegistrationAvailable,
        onSwitch: handleSwitchRegistrationAvailable,
      }}
      collapsing
      defaultChecked={earlyBirdRegistrationAvailable}
    >
      <Grid container direction="column" spacing={FORM_SPACING}>
        <Grid item>
          <EarlyBirdRegistrationOptions
            earlyBirdRegistrationAvailable={earlyBirdRegistrationAvailable}
          />
        </Grid>
        {earlyBirdRegistrationsMode === EarlyBirdRegistrationsMode.specificAttendeesAndTeams && (
          <Grid item>
            <EarlyBirdRegistrationInvitations />
          </Grid>
        )}
      </Grid>
    </PaperBlock>
  );
};

export default ExperienceEarlyBirdRegistration;
