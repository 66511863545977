import React from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { usePagination, usePaginationQueryVariables, useSearchQueryVariables } from '@vizsla/hooks';
import {
  Attendee,
  Donor,
  Experience,
  User,
  VizslaOrganization,
  VizslaOrganizationListResponse,
} from '@vizsla/graphql';
import { DateFormatPatterns } from '@vizsla/constants';
import { TableColumn, TableData } from '@vizsla/components';
import { buildUrl, formatDate, getUserFullName } from '@vizsla/utils';

import { ORGANIZATIONS_QUERY } from 'src/graphql/admin';
import * as appRoutes from 'src/constants/routes';
import * as entitiesId from 'src/constants/entities-id';
import { CampaignDetailItem } from 'src/types/campaign';

const getItemId = (person: Donor | Attendee) => person?.user?.id;

function getUniqOrganizationRelatedUsersIds(organization: VizslaOrganization) {
  const campaignsConsumersIds = _.flatten(
    organization.campaigns?.items?.map(getAllCampaignConsumersIds),
  );

  return _.uniq(campaignsConsumersIds);
}

const getAllExperienceAttendeesIds = (experience: Experience) =>
  experience.attendees?.items?.map(getItemId) ?? [];

const getAllCampaignConsumersIds = (campaign: CampaignDetailItem) => {
  const donorsIds = campaign.donors?.items.map(getItemId) ?? [];

  const attendeesIds = _.flatten(campaign.experiences?.items?.map(getAllExperienceAttendeesIds));
  return [...donorsIds, ...attendeesIds].filter(id => !_.isNil(id));
};

const columns: Array<TableColumn<VizslaOrganization>> = [
  {
    title: 'name',
    key: 'name',
    dataPath: 'name',
    render: (name: string | undefined, organization) => {
      if (!name) {
        return 'N/A';
      }

      const adminOrganizationProfileUrl = buildUrl(appRoutes.adminOrganizationProfileInfo, {
        [entitiesId.organization]: organization.id as string,
      });

      return (
        <Link
          component={RouterLink}
          to={adminOrganizationProfileUrl}
          style={{ textDecoration: 'none' }}
        >
          <Typography variant="subtitle2">{name}</Typography>
        </Link>
      );
    },
  },
  {
    title: 'users',
    key: 'users',
    dataPath: 'vizslaOrganizationStaff.count',
    render: vizslaOrganizationStaffCount => Number(vizslaOrganizationStaffCount),
  },
  {
    title: 'campaigns',
    key: 'campaigns',
    dataPath: 'campaigns.count',
    render: campaignsCount => Number(campaignsCount),
  },
  {
    title: 'experiences',
    key: 'experiences',
    render: (_v, organization) => {
      const campaigns = organization?.campaigns?.items || [];
      return _.sum(campaigns.map(campaign => campaign?.experiences?.count)) ?? 0;
    },
  },
  {
    title: 'people',
    key: 'people',
    render: (_v, organization) => {
      return getUniqOrganizationRelatedUsersIds(organization).length;
    },
  },
  {
    title: 'created at',
    key: 'createdAt',
    dataPath: 'createdAt',
    render: (createdAt: string) => {
      return formatDate(createdAt, DateFormatPatterns.shortDateWithSlash);
    },
  },
  {
    title: 'created by',
    key: 'createdBy',
    dataPath: 'createdBy',
    render: (createdBy: User | undefined) => {
      if (!createdBy) {
        return 'N/A';
      }

      return getUserFullName(createdBy);
    },
  },
  {
    title: 'contact',
    key: 'contact',
    dataPath: 'user',
    render: (user: User | undefined) => {
      if (!user) {
        return 'N/A';
      }

      return getUserFullName(user);
    },
  },
];

export const AdminOrganizationsTable: React.FC = () => {
  const pagination = usePagination();
  const paginationQueryVariables = usePaginationQueryVariables();
  const searchQueryVariables = useSearchQueryVariables('name');

  const { data, loading } = useQuery<{
    vizslaOrganizationsList: VizslaOrganizationListResponse;
  }>(ORGANIZATIONS_QUERY, {
    onCompleted: queryResult => {
      const totalCount = queryResult?.vizslaOrganizationsList?.count || 0;

      if (totalCount) {
        pagination.onCountChange(totalCount);
      }
    },
    variables: {
      filter: {
        ...searchQueryVariables,
      },
      ...paginationQueryVariables,
    },
    fetchPolicy: 'cache-and-network',
  });

  const organizations = (data?.vizslaOrganizationsList?.items ?? []) as VizslaOrganization[];

  return (
    <TableData
      dataSource={organizations}
      columns={columns}
      loading={loading}
      pagination={pagination}
    />
  );
};
