import React from 'react';

import { ExperienceDropDown } from '@vizsla/components';
import { Experience } from '@vizsla/graphql';

import { InboxHeaderActions, InboxHeaderContainer, InboxHeaderTitle } from './InboxHeader.styles';

interface InboxPageHeaderProps {
  organizationId?: string | null;
  loading?: boolean;
  experience?: Experience;
  onChange: (value?: Experience) => void;
}

export function InboxPageHeader({
  organizationId,
  loading,
  experience,
  onChange,
}: InboxPageHeaderProps) {
  return (
    <InboxHeaderContainer>
      <InboxHeaderTitle>Messages</InboxHeaderTitle>
      <InboxHeaderActions>
        <ExperienceDropDown
          loading={loading}
          organizationId={organizationId as string}
          value={experience}
          onChange={onChange}
        />
      </InboxHeaderActions>
    </InboxHeaderContainer>
  );
}
