import React from 'react';

import { TableData } from '@vizsla/components';
import { useListPartnershipCommitmentsQuery } from '@vizsla/graphql';

import { columns } from './columns';
import { useRowActions } from './actions';

export const PartnershipCommitmentTable: React.FC<{ partnershipId: string }> = ({
  partnershipId,
}) => {
  const { data, loading: queryLoading } = useListPartnershipCommitmentsQuery({
    variables: { partnershipOrganizationId: partnershipId as string },
  });

  const organizedData = React.useMemo(
    () =>
      data?.partnershipCommitmentsList?.items?.map(item => ({
        ...item,
        allocation: { campaign: item?.campaign, experience: item?.experience },
      })),
    [data?.partnershipCommitmentsList?.items],
  );
  const [loading, setLoading] = React.useState(false);

  const { actions } = useRowActions(partnershipId, setLoading);

  return (
    <TableData
      tableProps={{ stickyHeader: true }}
      dataSource={organizedData || []}
      columns={columns}
      tableContainerProps={{ style: { width: '100%' } }}
      rowActions={actions}
      loading={queryLoading || loading}
    />
  );
};
