import React from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import authBg from 'src/assets/img/auth-bg.jpg';
import vizslaLogo from 'src/assets/img/vizsla-log-white-small.png';
import { FontSize, PaletteColor } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '800px',
    height: '100vh',
    backgroundPosition: 'left center',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
  },
  bgImage: {
    height: '100%',
    background: (props: { file: string }) =>
      `linear-gradient(90deg, rgba(42,58,74,0.1) 0%, rgb(42,58,74) 90%), no-repeat center/cover url(${
        props?.file ?? ''
      })`,
  },
  logo: {
    width: 150,
    height: 150,
  },
  formContainer: {
    backgroundColor: '#2a3a4a',
  },
  textContainer: {
    width: 440,
  },
  title: {
    fontSize: FontSize.XL,
    fontWeight: 400,
    textAlign: 'left',
    lineHeight: '1.2',
    color: PaletteColor.White,
    margin: '5px 0',
  },
  subtitle: {
    fontSize: FontSize.Default,
    fontWeight: 400,
    textAlign: 'left',
    lineHeight: '1.2',
    color: PaletteColor.White,
  },
}));

interface OrganizationAuthLayoutProps {
  title: string;
  caption: string;
  children: React.ReactNode;
}

export const OrganizationAuthLayout: React.FC<OrganizationAuthLayoutProps> = ({
  title,
  caption,
  children,
}) => {
  const classes = useStyles({ file: authBg });

  return (
    <Grid className={classes.root} container direction="row">
      <Grid className={classes.bgImage} item xs={8} />
      <Grid
        className={classes.formContainer}
        item
        xs={4}
        spacing={2}
        container
        justifyContent="center"
        alignItems="left"
        direction="column"
      >
        <Grid item>
          <img src={vizslaLogo} className={classes.logo} alt="Vizsla logo" />
        </Grid>
        <Grid item className={classes.textContainer}>
          <Typography className={classes.title} variant="h1">
            {title}
          </Typography>
          <Typography className={classes.subtitle} variant="h2">
            {caption}
          </Typography>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </Grid>
  );
};
