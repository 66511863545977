import React from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Button } from '@vizsla/components';

// import { ExperienceLandingElementProps } from '../ExperienceLanding.types';
import { ExperienceLandingElementProps } from 'src/providers/campaign/ThankYouLanding.types';

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontSize: '18px',
  },
  title: {
    fontSize: '26px',
  },
}));

export const ManageYourDonation: React.FC<ExperienceLandingElementProps> = ({
  experience,
  theme,
}) => {
  const { accentColor } = theme;
  const isManageDonationTitleVisible = theme?.isManageDonationTitleVisible ?? true;
  const isManageDonationDescriptionVisible = theme?.isManageDonationDescriptionVisible ?? true;

  const classes = useStyles({ accentColor });
  const onClick = () => {
    window.open(`https://consumer-${process.env.REACT_APP_WORKSPACE_ENV}.vizsla.com`);
  };
  return (
    <Grid>
      {isManageDonationTitleVisible && (
        <Typography variant="h4" textAlign="center" marginBottom={1} className={classes.title}>
          Manage Your Donation
        </Typography>
      )}
      {isManageDonationDescriptionVisible && (
        <Grid container>
          <Grid item xs={12}>
            <Typography>
              To manage your donation at any time, please log in by clicking the &quot;Log in&quot;
              link below.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button variant="outlined" onClick={onClick}>
              {' '}
              Log in{' '}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
