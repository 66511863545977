import React from 'react';
import { CircularProgress, Typography } from '@mui/material';

import { useModal } from '@vizsla/hooks';
import { MODALS } from '@vizsla/constants';

import { CreatePartnershipCommitmentModal } from './CreatePartnershipCommitmentsModal';
import { ContactsWrapper, Content, HyperlinkButton } from './styles';
import { PartnershipCommitmentTable } from './PartnershipCommitmentsTable';

export const OrganizationPartnershipCommitment: React.FC<{ partnershipId?: string }> = ({
  partnershipId,
}) => {
  const { openModal } = useModal(MODALS.PARTNERSHIP_COMMITMENT_CREATE);

  return (
    <ContactsWrapper>
      {!partnershipId ? (
        <CircularProgress />
      ) : (
        <Content>
          <CreatePartnershipCommitmentModal />
          <Typography style={{ marginBottom: '1rem' }} variant="h3">
            Commitments
          </Typography>

          <PartnershipCommitmentTable partnershipId={partnershipId} />

          <HyperlinkButton
            onClick={() => {
              openModal(MODALS.PARTNERSHIP_COMMITMENT_CREATE, {
                partnershipId,
              });
            }}
            type="button"
            variant="hyperlink"
          >
            + ADD COMMITMENT
          </HyperlinkButton>
        </Content>
      )}
    </ContactsWrapper>
  );
};
