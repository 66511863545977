import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Menu, MenuItem, ListItemText } from '@mui/material';
import { DeleteOutline as DeleteIcon } from '@mui/icons-material';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';
import { makeStyles } from '@mui/styles';

import { usePopover, useModal } from '@vizsla/hooks';
import { buildUrl } from '@vizsla/utils';
import { Campaign } from '@vizsla/graphql';

import { BorderRadius, PaletteColor } from 'src/theme';
import { TileBar } from 'src/components/campaign';
import { MODALS } from 'src/constants/modals';
import { CampaignStatus } from 'src/types/campaign';
import * as entitiesId from 'src/constants/entities-id';
import * as appRoutes from 'src/constants/routes';
import { AddToProgram } from 'src/modals/campaign/AddtoProgram';
import { ReassingProgram } from 'src/modals/campaign/ReassingProgram';

import { CampaignTile } from './CampaignTile';

const CARD_HEIGHT = 400;

const useStyles = makeStyles(() => ({
  card: {
    width: 298,
    height: CARD_HEIGHT,
    borderRadius: BorderRadius.M,
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

type CampaignPopoverAction = {
  actionName: string;
  onAction: (record: Campaign, rowIndex?: number) => void;
  icon?: React.ReactElement;
  disabled?: boolean;
  actionColor?: string;
};

interface CampaignTileProps {
  campaign: Campaign;
}

const CampaignsListTile: React.FC<CampaignTileProps> = ({ campaign }) => {
  const classes = useStyles();
  const { state: campaignState, ...campaignActionsPopover } = usePopover<Campaign>();

  const { openModal } = useModal();

  const navigate = useNavigate();

  const [visible, setVisible] = React.useState(false);

  const onOpenCampaignActionMenu = (event: React.MouseEvent<any>) => {
    campaignActionsPopover.openPopover(event, campaign);
  };

  const onViewCampaignPage = () => {
    navigate(buildUrl(appRoutes.campaign, { [entitiesId.campaign]: campaign.id as string }));
  };

  const onMouseEnter = () => {
    setVisible(true);
  };

  const onMouseLeave = () => {
    setVisible(false);
  };
  const campaignPopoverActions = React.useMemo(() => {
    const campaignActions: Array<CampaignPopoverAction> = [
      {
        actionName: 'Delete',
        onAction: (campaign: Campaign) => {
          openModal(MODALS.CAMPAIGN_DELETE_MODAL, { campaign });
        },
        disabled: campaignState?.campaignStatus !== CampaignStatus.draft,
        icon: <DeleteIcon />,
        actionColor: PaletteColor.ErrorMain,
      },
    ];
    if (campaign.program === null) {
      campaignActions.push({
        actionName: 'Add to Program',
        onAction: (campaign: Campaign) => {
          openModal(MODALS.ADD_TO_PROGRAM, { campaign });
        },
        icon: <LibraryAddIcon />,
      });
    } else {
      campaignActions.push({
        actionName: 'Reassing Program',
        onAction: (campaign: Campaign) => {
          openModal(MODALS.REASSING_PROGRAM, { campaign });
        },
        icon: <ForwardOutlinedIcon />,
      });
    }
    return campaignActions;
  }, [campaign]);

  const onPopoverAction = (onAction: CampaignPopoverAction['onAction']) => {
    campaignState && onAction(campaignState);
    campaignActionsPopover.closePopover();
  };

  const renderCampaignTileAction = (campaignAction: CampaignPopoverAction) => {
    const { actionName, icon, onAction, disabled = false, actionColor } = campaignAction;
    return (
      <MenuItem
        key={actionName}
        onClick={() => onPopoverAction(onAction)}
        sx={{ color: actionColor }}
        disabled={disabled}
      >
        {icon}
        <ListItemText disableTypography>{actionName}</ListItemText>
      </MenuItem>
    );
  };

  return (
    <React.Fragment>
      <Paper
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        variant="elevation"
        elevation={1}
        className={classes.card}
      >
        <TileBar visible={visible} onView={onViewCampaignPage} />
        <CampaignTile campaign={campaign} activeLink />
        <TileBar visible={visible} onActions={onOpenCampaignActionMenu} />
        <Menu
          anchorEl={campaignActionsPopover.el}
          keepMounted
          open={campaignActionsPopover.isOpen}
          onClose={campaignActionsPopover.closePopover}
        >
          {campaignPopoverActions.map(renderCampaignTileAction)}
        </Menu>
      </Paper>
      <AddToProgram />
      <ReassingProgram />
    </React.Fragment>
  );
};

export default CampaignsListTile;
