import {
  TicketingPricingTierCreateManyInput,
  useTicketingPricingTierCreateManyMutation,
  useTicketingPricingTierDeleteByFilterMutation,
} from '@vizsla/graphql';

type TicketingOptionsPricingTiersOutput = {
  createNewPricingTiers: (data: Array<TicketingPricingTierCreateManyInput>) => Promise<void>;
  deletePricingTiers: (ids: Array<string>) => Promise<void>;
  creatingPricingTiers: boolean;
  deletingPricingTiers: boolean;
};

export const useTicketingOptionsPricingTiers = (): TicketingOptionsPricingTiersOutput => {
  const [ticketingPricingTierCreateManyMutation, { loading: creatingPricingTiers }] =
    useTicketingPricingTierCreateManyMutation();

  const createNewPricingTiers = async (data: Array<TicketingPricingTierCreateManyInput>) => {
    await ticketingPricingTierCreateManyMutation({
      variables: {
        data,
      },
      refetchQueries: ['TicketingOptionsList'],
    });
  };

  const [ticketingPricingTierDeleteByFilterMutation, { loading: deletingPricingTiers }] =
    useTicketingPricingTierDeleteByFilterMutation();

  const deletePricingTiers = async (ids: Array<string>) => {
    await ticketingPricingTierDeleteByFilterMutation({
      variables: {
        filter: {
          id: {
            in: ids,
          },
        },
      },
    });
  };

  return {
    createNewPricingTiers,
    deletePricingTiers,
    creatingPricingTiers,
    deletingPricingTiers,
  };
};
