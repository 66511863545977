import React from 'react';
import { FormApi } from 'final-form';
import { Field, Form, FormSpy, FormSpyRenderProps } from 'react-final-form';
import { Grid, Typography } from '@mui/material';

import { useModal, useNotification } from '@vizsla/hooks';
import {
  Modal,
  TextField,
  CheckboxField,
  DateField,
  RadioGroupField,
  SelectField,
  TimeField,
} from '@vizsla/components';
import { DateFormatPatterns } from '@vizsla/constants';
import { getNowDate, toDateTimeFromISO, validateWithSchema } from '@vizsla/utils';

import { useCampaign, useCampaignId } from 'src/hooks/campaign';
import { useExperiences } from 'src/hooks/experiences';
import { ExperienceTypeRadioGroupField } from 'src/components/experience';
import { ExperienceType } from 'src/types/experience';
import { MODALS } from 'src/constants/modals';
import {
  EXPERIENCE_REGISTRATION_CATEGORY_OPTIONS,
  EXPERIENCE_TICKETING_CATEGORY_OPTIONS,
} from 'src/constants/experience';
import type { ExperienceContextSchema } from 'src/constants/validationSchemas/experience';
import { DateType } from 'src/constants/campaign';
import { buildExperience } from 'src/utils/campaign';
import { t } from 'src/utils/template';
import { getExperienceFormSchema, getExperienceMaxEndDate } from 'src/utils/experience';

const ExperienceEditModal = () => {
  const campaignId = useCampaignId();
  const { data: campaign } = useCampaign(campaignId);
  const { createExperience, updating } = useExperiences(campaignId);

  const { isOpen, closeModal } = useModal(MODALS.EXPERIENCE_CREATE_MODAL);

  const notification = useNotification();

  const initialExperience = {
    sameAsCampaignDates: false,
    experienceType: ExperienceType.registration,
    dateType: DateType.Single,
  };

  const validate = (experienceValues: any) => {
    const context = {
      campaignStartDate: campaign?.startDate,
      campaignEndDate: campaign?.endDate,
    };
    const experienceFormSchema = getExperienceFormSchema(experienceValues);

    return validateWithSchema<ExperienceContextSchema>(experienceFormSchema, experienceValues, {
      context,
    });
  };

  const onSubmit = async (experienceFormData: any, form: FormApi) => {
    try {
      const experienceData = buildExperience(experienceFormData, campaign);

      await createExperience(experienceData, campaignId);

      closeModal();
      setTimeout(() => form.reset(), 200);
      notification.success(t('experience_create_success'));
    } catch (error) {
      notification.error(t('experience_create_error'));
      console.error({ error });
    }
  };

  if (initialExperience === null) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth>
      <Modal.Title>
        <Typography variant="h3" align="center">
          Create Experience
        </Typography>
      </Modal.Title>

      <Form
        onSubmit={onSubmit as any}
        subscription={{ submitting: true }}
        validate={validate}
        initialValues={initialExperience}
      >
        {({ handleSubmit, submitting, hasValidationErrors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Content>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Modal.ContentText>Experience Type</Modal.ContentText>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="experienceType"
                      component={ExperienceTypeRadioGroupField}
                      defaultValue={ExperienceType.registration}
                      width={230}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Modal.ContentText>General</Modal.ContentText>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="name"
                      label="Experience Name"
                      component={TextField}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormSpy subscription={{ values: true }}>
                      {(spyRenderProps: FormSpyRenderProps) => {
                        const {
                          values: { experienceType },
                        } = spyRenderProps;
                        const currentExperienceType = experienceType || ExperienceType.registration;

                        return (
                          <Field
                            name="experienceCategory"
                            label="Experience Category"
                            component={SelectField}
                            options={
                              currentExperienceType === ExperienceType.registration
                                ? EXPERIENCE_REGISTRATION_CATEGORY_OPTIONS
                                : EXPERIENCE_TICKETING_CATEGORY_OPTIONS
                            }
                            disabled={submitting}
                            fullWidth
                            required
                          />
                        );
                      }}
                    </FormSpy>
                  </Grid>
                  <Grid item xs={12}>
                    <Modal.ContentText>Experience Dates</Modal.ContentText>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Same as Campaign Dates"
                      name="sameAsCampaignDates"
                      component={CheckboxField}
                      disabled={submitting}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <FormSpy subscription={{ values: true }}>
                  {(spyRenderProps: FormSpyRenderProps) => {
                    const { values } = spyRenderProps;
                    const { sameAsCampaignDates, dateType, endDate: experienceEndDate } = values;

                    const visibleDates = !sameAsCampaignDates;
                    const visibleDateRange = dateType === DateType.Range;
                    const currentDate = toDateTimeFromISO(getNowDate());
                    const maxEndDate = getExperienceMaxEndDate(campaign?.endDate);

                    spyRenderProps.form.change(
                      'endDate',
                      visibleDateRange ? experienceEndDate : null,
                    );

                    if (!visibleDates) {
                      return null;
                    }

                    return (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            name="dateType"
                            component={RadioGroupField}
                            row
                            options={[
                              {
                                label: 'Single Date',
                                value: DateType.Single,
                              },
                              {
                                label: 'Date Range',
                                value: DateType.Range,
                              },
                            ]}
                            disabled={submitting}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            label="Start Date"
                            name="startDate"
                            component={DateField}
                            inputFormat={DateFormatPatterns.fullMonthDayYear}
                            disabled={submitting}
                            fullWidth
                            minDate={currentDate}
                            maxDate={maxEndDate}
                            required
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            label="Start Time"
                            name="startTime"
                            component={TimeField}
                            disabled={submitting}
                            fullWidth
                            required
                          />
                        </Grid>
                        {visibleDateRange && (
                          <React.Fragment>
                            <Grid item xs={6}>
                              <Field
                                label="End Date"
                                name="endDate"
                                component={DateField}
                                inputFormat={DateFormatPatterns.fullMonthDayYear}
                                disabled={submitting}
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Field
                                label="End Time"
                                name="endTime"
                                component={TimeField}
                                disabled={submitting}
                                fullWidth
                                required
                              />
                            </Grid>
                          </React.Fragment>
                        )}
                      </Grid>
                    );
                  }}
                </FormSpy>
              </Modal.Content>
              <Modal.Actions>
                <Modal.Action type="secondary" content="Cancel" onAction={closeModal} />
                <Modal.Action
                  type="primary"
                  content="Create"
                  onAction={handleSubmit}
                  loading={submitting}
                  disabled={updating || submitting || hasValidationErrors}
                />
              </Modal.Actions>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};

export default ExperienceEditModal;
