import {
  File,
  User,
  StoreAssetSimpleFragmentFragment,
  StoreHistoryEventFragmentFragment,
  ExperienceStoreAssetFragmentFragment,
} from '@vizsla/graphql';

type StoreAssetFragmentFragment = {
  __typename?: 'StoreAsset';
  id?: string | null;
  name?: string | null;
  description?: string | null;
  storeAvailability?: boolean | null;
  swagbagAvailability?: boolean | null;
  price?: number | null;
  unitCost?: number | null;
  continueSalesWhenOutOfStock?: boolean | null;
  notifyWhenStockMinimumIsAchieved?: boolean | null;
  stockMinimumBoundary?: number | null;
  note?: string | null;
  variantsPossible?: any | null;
  total?: number | null;
  aviable?: number | null;
  sales?: number | null;
  profit?: number | null;
  image?: {
    __typename?: 'File';
    id?: string | null;
    fileId?: string | null;
    filename?: string | null;
    downloadUrl?: string | null;
  } | null;
};

export type StoreAsset = StoreAssetFragmentFragment;

export type StoreAssetSimple = StoreAssetSimpleFragmentFragment;

export type ExperienceStoreAsset = ExperienceStoreAssetFragmentFragment;

export type StoreHistoryEvent = StoreHistoryEventFragmentFragment;

export type DashboardStoreAsset = {
  id: string;
  name: string;
  imageUrl: string | null;
  price: number;
  countOfTotal: number;
  countOfAvailable: number;
  profitAmount: number;
  salesAmount: number;
};

export enum StoreAssetFormFields {
  NAME = 'name',
  DESCRIPTION = 'description',
  IMAGE = 'image',
  STORE_AVAILABILITY = 'storeAvailability',
  SWAGBAG_AVAILABILITY = 'swagbagAvailability',
  RECOMMENDED_PRICE = 'price',
  UNIT_COST = 'unitCost',
  CONTINUE_SALES_wHEN_OUT_OF_STOCK = 'continueSalesWhenOutOfStock',
  NOTIFY_WHEN_STOCK_MINIMUM_IS_ACHIEVED = 'notifyWhenStockMinimumIsAchieved',
  STOCK_MINIMUM_BOUNDARY = 'stockMinimumBoundary',
  NOTES = 'note',
}

export enum StoreAsseInventoryFormFields {
  QUANTITY = 'quantity',
  SIZE_ATTRIBUTE = 'sizeAttribute',
  STYLE_ATTRIBUTE = 'styleAttribute',
  DATE_RECEIVED = 'dateReceived',
  RECIPIENTS = 'recipients',
  ORDER_COST = 'orderCost',
  NOTES = 'note',
}

export type StoreAssetCreateOrUpdateFormValues = Partial<{
  [StoreAssetFormFields.NAME]: string;
  [StoreAssetFormFields.DESCRIPTION]: string;
  [StoreAssetFormFields.IMAGE]: File;
  // TO DO [StoreAssetFormFields.S]: boolean;
  [StoreAssetFormFields.SWAGBAG_AVAILABILITY]: boolean;
  [StoreAssetFormFields.RECOMMENDED_PRICE]: number;
  [StoreAssetFormFields.UNIT_COST]: number;
  [StoreAssetFormFields.CONTINUE_SALES_wHEN_OUT_OF_STOCK]: boolean;
  [StoreAssetFormFields.NOTIFY_WHEN_STOCK_MINIMUM_IS_ACHIEVED]: boolean;
  [StoreAssetFormFields.STOCK_MINIMUM_BOUNDARY]: number;
  [StoreAssetFormFields.NOTES]: string;
  [StoreAssetFormFields.STORE_AVAILABILITY]: boolean;
}>;

export type StoreAssetUpdateOrDeleteImageModalArgs = {
  storeAsset: StoreAsset;
};

export type StoreAssetUpdateImageFormValues = Partial<{
  [StoreAssetFormFields.IMAGE]: File;
}>;

export type StoreAssetInventoryAddOrAdjustFormValues = Partial<{
  [StoreAsseInventoryFormFields.QUANTITY]: number;
  [StoreAsseInventoryFormFields.SIZE_ATTRIBUTE]: string;
  [StoreAsseInventoryFormFields.STYLE_ATTRIBUTE]: string;
  [StoreAsseInventoryFormFields.DATE_RECEIVED]: string;
  [StoreAsseInventoryFormFields.RECIPIENTS]: User[];
  [StoreAsseInventoryFormFields.ORDER_COST]: number;
  [StoreAsseInventoryFormFields.NOTES]: string;
  variantsValues: JSON;
}>;

export enum StoreHistoryEventType {
  Add = 'ADD',
  Adjust = 'ADJUST',
  Purchase = 'PURCHASE',
}

export type StoreHistortEventCreateMutationResult = {
  storeHistoryEventCreateWithRecalc: {
    data: StoreHistoryEventFragmentFragment;
    assetCountOfTotal: number;
  };
};

export type ExperienceStoreAssetActionUpdateOrDeleteModalArgs = {
  experienceStoreAsset: ExperienceStoreAsset;
};

export enum ExperienceStoreAssetFormFields {
  STORE_ASSET = 'storeAsset',
  PRICE = 'price',
}

export type ExperienceStoreAssetAddOrUpdateFormValues = Partial<{
  [ExperienceStoreAssetFormFields.STORE_ASSET]: StoreAssetSimple;
  [ExperienceStoreAssetFormFields.PRICE]: number;
}>;
