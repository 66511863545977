import React from 'react';
import clsx from 'clsx';
import { Tooltip, Typography, Theme, Radio, RadioProps, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { HelpOutline } from '@mui/icons-material';

import { isFunction } from '@vizsla/utils';

import { Shade, PaletteColor, BorderRadius, FontSize } from 'src/theme';

interface TileButtonStyleProps {
  height: number | string;
  width: number | string;
}

const useStyles = makeStyles<Theme, TileButtonStyleProps>((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    width: styleProps => styleProps?.width,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: styleProps => styleProps.height,
    width: styleProps => {
      return styleProps.width;
    },
    borderRadius: BorderRadius.M,
    border: '2px solid',
    borderColor: Shade.Teal[100],
    color: theme.palette.secondary.light,
    backgroundColor: PaletteColor.White,
    'input:hover ~ &': {
      borderColor: theme.palette.secondary.main,
      transition: 'all 200ms linear',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      borderColor: Shade.Gray[100],
      background: Shade.Gray[100],
      color: PaletteColor.ContrastText,
      '& .MuiTypography-root': {
        color: PaletteColor.ContrastText,
      },
    },
  },
  checkedIcon: {
    borderColor: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.light,
    color: PaletteColor.ContrastText,
    'input:hover ~ &': {
      backgroundColor: theme.palette.secondary.main,
      transition: 'all 200ms linear',
    },
  },
  content: {
    display: 'block',
  },
  tooltip: {
    position: 'absolute',
    top: '15%',
    left: '80%',
    zIndex: 1,
  },
  tooltipText: {
    fontSize: FontSize.S,
  },
  textContainer: {
    textAlign: 'center',
  },
  whiteText: {
    color: PaletteColor.White,
  },
  secondaryText: {
    color: theme.palette.secondary.light,
  },
  innerIcon: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 6,
  },
  button: {
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      background: 'transparent',
    },
  },
}));

const CHECKED = true;
const DEFAULT_HEIGHT = 110;
const DEFAULT_WIDTH = 215;

export interface TileRadioButtonProps extends RadioProps {
  value?: any;
  text?: string;
  innerIcon?: React.ReactNode;
  tooltipText?: string;
  height?: number | string;
  width?: number | string;
  type?: 'radio' | 'button';
}

export const TileRadioButton: React.FC<TileRadioButtonProps> = ({
  innerIcon,
  text,
  tooltipText,
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
  type,
  ...props
}) => {
  const classes = useStyles({ height, width });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (props?.onClick && isFunction(props?.onClick)) {
      props?.onClick(event);
    }
  };

  const renderTooltip = () => {
    if (!tooltipText) {
      return null;
    }

    return (
      <div className={classes.tooltip}>
        <Tooltip
          placement="top"
          arrow
          title={
            <Typography variant="body2" className={clsx(classes.tooltipText, classes.whiteText)}>
              {tooltipText}
            </Typography>
          }
        >
          <HelpOutline style={{ fontSize: FontSize.L }} />
        </Tooltip>
      </div>
    );
  };

  const renderContent = (isChecked: boolean) => (
    <div className={classes.content}>
      <div className={classes.innerIcon}>{innerIcon}</div>
      <div className={classes.textContainer}>
        <Typography
          className={clsx({ [classes.whiteText]: isChecked, [classes.secondaryText]: !isChecked })}
          variant="button"
        >
          {text}
        </Typography>
      </div>
    </div>
  );

  switch (type) {
    case 'button':
      return (
        <Button className={clsx(classes.container, classes.button)} onClick={handleClick}>
          {renderContent(!CHECKED)}
          {renderTooltip()}
        </Button>
      );
    default:
      return (
        <Radio
          className={classes.root}
          disableRipple
          color="default"
          checkedIcon={
            <div className={clsx(classes.container, classes.checkedIcon)}>
              {renderContent(CHECKED)}
              {renderTooltip()}
            </div>
          }
          icon={
            <div className={classes.container}>
              {renderContent(!CHECKED)}
              {renderTooltip()}
            </div>
          }
          {...props}
        />
      );
  }
};
