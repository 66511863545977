import React from 'react';
import {
  ApolloError,
  QueryFunctionOptions,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';

import {
  usePagination,
  usePaginationQueryVariables,
  useApolloCacheQueryManager,
} from '@vizsla/hooks';
import {
  ExperienceCreateMutationVariables,
  ExperienceListQueryVariables,
  ExperienceCreateMutation,
  ExperienceListResponse,
  ExperienceFilter,
  Experience,
} from '@vizsla/graphql';
import { mutableList } from '@vizsla/utils';

import {
  EXPERIENCE_CREATE_MUTATION,
  EXPERIENCE_UPDATE_MUTATION,
  EXPERIENCE_DELETE_MUTATION,
  EXPERIENCE_LIST_QUERY,
  EXPERIENCE_FRAGMENT,
} from 'src/graphql';

type ExperienceListQueryResponse = { experiencesList: ExperienceListResponse };

enum ExperienceTypeName {
  experience = 'Experience',
}

type ExperiencesResult = {
  data: Array<Experience>;
  loading: boolean;
  error: ApolloError | undefined;
  getExperienceFromCacheById: (experienceId: string) => Experience | null;
  createExperience: (experienceData: any, campaignId: string) => void;
  updateExperience: (experienceData: any, experienceId: string) => void;
  deleteExperience: (experienceId: string) => void;
  creating: boolean;
  updating: boolean;
  deleting: boolean;
};

export const useExperiences = (
  campaignId: string,
  experienceFilter?: ExperienceFilter,
  queryOptions?: QueryFunctionOptions,
): ExperiencesResult => {
  const apolloClient = useApolloClient();
  const pagination = usePagination();
  const paginationQueryVariables = usePaginationQueryVariables();

  const filterByCampaignId = {
    campaign: {
      id: {
        equals: campaignId,
      },
    },
  };

  const filter = experienceFilter ?? filterByCampaignId;
  const experienceQueryVariables = {
    variables: {
      filter,
      ...paginationQueryVariables,
    },
  };

  const { data, loading, error } = useQuery<
    ExperienceListQueryResponse,
    ExperienceListQueryVariables
  >(EXPERIENCE_LIST_QUERY, {
    ...experienceQueryVariables,
    skip: !campaignId,
    ...queryOptions,
  });

  const { updateQuery: updateExperienceListQuery } = useApolloCacheQueryManager<
    ExperienceListQueryResponse,
    ExperienceListQueryVariables
  >({
    query: EXPERIENCE_LIST_QUERY,
    typeName: 'ExperienceListResponse',
    ...experienceQueryVariables,
  });

  const experiences = React.useMemo(
    () => data?.experiencesList.items || [],
    [data?.experiencesList.items],
  );

  React.useEffect(() => {
    pagination?.onCountChange(experiences.length);
  }, [experiences, pagination]);

  const [createExperienceMutation, { loading: creating }] = useMutation<
    ExperienceCreateMutation,
    ExperienceCreateMutationVariables
  >(EXPERIENCE_CREATE_MUTATION);
  const [updateExperienceMutation, { loading: updating }] = useMutation(EXPERIENCE_UPDATE_MUTATION);
  const [deleteExperienceMutation, { loading: deleting }] = useMutation(EXPERIENCE_DELETE_MUTATION);

  const getExperienceFromCacheById = React.useCallback(
    (experienceId: string): Experience | null => {
      try {
        return apolloClient.readFragment<Experience>({
          id: `${ExperienceTypeName.experience}:${experienceId}`,
          fragment: EXPERIENCE_FRAGMENT,
        });
      } catch (error) {
        console.error({ error });
      }

      return null;
    },
    [apolloClient],
  );

  const createExperience = React.useCallback(
    async (experienceData: any, campaignId: string) => {
      try {
        const result = await createExperienceMutation({
          variables: {
            data: {
              ...experienceData,
              campaign: {
                connect: {
                  id: campaignId,
                },
              },
            },
          },
        });
        const experience = result?.data?.experienceCreate;

        if (experience !== undefined) {
          updateExperienceListQuery(query => {
            mutableList<Experience>(query.experiencesList.items).add(experience as Experience);
            query.experiencesList.count += 1;
          });
        }
      } catch (error) {
        console.error({ error });
        throw error;
      }
    },
    [createExperienceMutation, updateExperienceListQuery],
  );

  const updateExperience = React.useCallback(
    async (experienceData: any, experienceId: string) => {
      try {
        await updateExperienceMutation({
          variables: {
            data: experienceData,
            filter: {
              id: experienceId,
            },
          },
        });
      } catch ({ error }) {
        console.error({ error });
        throw error;
      }
    },
    [updateExperienceMutation],
  );

  const deleteExperience = React.useCallback(
    async (experienceId: string) => {
      try {
        await deleteExperienceMutation({
          variables: {
            id: experienceId,
          },
        });

        updateExperienceListQuery(query => {
          mutableList<Experience>(query.experiencesList.items).removeById(experienceId);
          query.experiencesList.count -= 1;
        });
      } catch (error) {
        console.error({ error });
        throw error;
      }
    },
    [deleteExperienceMutation, updateExperienceListQuery],
  );

  return {
    data: experiences,
    loading,
    error,
    getExperienceFromCacheById,
    createExperience,
    updateExperience,
    deleteExperience,
    creating,
    updating,
    deleting,
  };
};
