import React from 'react';
import { useForm } from 'react-final-form';
import _ from 'lodash';

import { Field, useDataBaseSelectField, DataBaseSelectField } from '@vizsla/components';
import { Attendee, AttendeesNamesListQuery, User } from '@vizsla/graphql';
import { getUserFullName } from '@vizsla/utils';

import { ATTENDEES_NAMES_LIST_QUERY } from 'src/graphql';
import { getAttendeeSearchFilter } from 'src/utils/attendee';

export const AddOfflineDonationFundraiserSelectField: React.FC = () => {
  const { getFieldState, change } = useForm();
  const teamFieldState = getFieldState('campaignInfo.fundraisingTeam');
  const campaignFieldState = getFieldState('campaignInfo.campaign');

  const [isDisabled, setIsDisabled] = React.useState(false);

  React.useEffect(() => {
    if (!_.isNil(teamFieldState?.value)) {
      change('campaignInfo.fundraiser', undefined);
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [change, teamFieldState?.value]);

  const selectFundraiserFieldProps = useDataBaseSelectField<AttendeesNamesListQuery, Attendee>(
    {
      query: ATTENDEES_NAMES_LIST_QUERY,
      getQueryItems: 'attendeesList.items',
      getCustomQueryVariables: searchText => ({
        filter: getAttendeeSearchFilter(searchText, campaignFieldState?.value),
        skip: !campaignFieldState?.value,
      }),
    },
    {
      getOptionLabel: (attendee: Attendee) => {
        return `${getUserFullName(attendee.user as User)} (${attendee.user?.email as string})`;
      },
    },
  );

  return (
    <Field
      fullWidth
      name="campaignInfo.fundraiser"
      label="Fundraiser"
      disabled={isDisabled}
      component={DataBaseSelectField}
      {...selectFundraiserFieldProps}
    />
  );
};
