import React from 'react';
export const useStripeCardElementsState = () => {
    const [securedFields, setSecuredFields] = React.useState({});
    const onChangeSecuredField = React.useCallback((field) => (changedObj) => {
        const newSecuredFields = Object.assign(Object.assign({}, securedFields), { [field]: changedObj });
        setSecuredFields(newSecuredFields);
    }, [securedFields]);
    return {
        securedFields,
        onChangeSecuredField,
    };
};
