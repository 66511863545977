import React from 'react';
import { VerifiedUserOutlined, PersonOutline, LocalAtm } from '@mui/icons-material';

import { RadioGroup, RadioGroupProps, TileRadioButton } from 'src/components/shared';
import { CampaignType } from 'src/types/campaign';
import { FontSize } from 'src/theme';

interface CampaignTypeRadioGroupProps extends RadioGroupProps {
  onChangeCampaignType: (campaignType: CampaignType) => void;
}

export const CampaignTypeRadioGroup: React.FC<CampaignTypeRadioGroupProps> = ({
  onChangeCampaignType,
  defaultValue,
}) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const campaignType = event.target.value;

    onChangeCampaignType(campaignType as CampaignType);
  };

  return (
    <RadioGroup defaultValue={defaultValue} onChange={onChange}>
      <TileRadioButton
        value={CampaignType.registrationAndTicketing}
        text="Registration & Ticketing"
        tooltipText="A registration & ticketing campaign is based on an event to raise funds. It usually, but not always, includes a peer-to-peer fundraising component."
        innerIcon={<VerifiedUserOutlined style={{ fontSize: FontSize.XXXL }} />}
      />
      <TileRadioButton
        value={CampaignType.personalMission}
        text="Personal Mission"
        tooltipText="A personal mission campaign (aka DIY fundraising or crowdfunding) empowers individuals to create personal fundraising campaigns to support your cause. When, where, and how donations are raised is up to the fundraiser."
        innerIcon={<PersonOutline style={{ fontSize: FontSize.XXXL }} />}
      />
      <TileRadioButton
        value={CampaignType.impactGiving}
        text="Impact Giving"
        tooltipText="An impact giving campaign (aka donation button) links donors directly from a website or social media page to a secure donation page."
        innerIcon={<LocalAtm style={{ fontSize: FontSize.XXXL }} />}
      />
    </RadioGroup>
  );
};
