import React from 'react';
import { Grid, styled } from '@mui/material';
import { Form } from 'react-final-form';

import { useExperienceImages } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { Modal, Field } from '@vizsla/components';
import { File } from '@vizsla/graphql';

import { useExperienceId } from 'src/hooks/experiences';
import { ImageInputField } from 'src/components/forms';
import { ExperienceHeaderLogoImageUpdateSchema } from 'src/constants/validationSchemas/experience';

const StyledFormNode = styled('form')(({ theme }) => ({
  display: 'contents',
}));

interface ExperienceHeaderLogoCreateEditFormProps {
  experienceLogoImage?: File;
  closeModal: () => void;
  onSubmit: (formValues: Record<string, any>) => Promise<void>;
}

export const ExperienceHeaderLogoCreateEditForm: React.FC<
  ExperienceHeaderLogoCreateEditFormProps
> = ({ experienceLogoImage, closeModal, onSubmit }) => {
  const experienceId = useExperienceId();

  const initialValues = {
    experienceLogoImage,
  };

  const { updatingExperienceLogo } = useExperienceImages(experienceId);

  const validate = React.useCallback(async (formValues: Record<string, any>) => {
    return validateWithSchema(ExperienceHeaderLogoImageUpdateSchema, formValues);
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({ handleSubmit, submitting, pristine, validating, invalid }) => {
        const isSubmitLoading = submitting || validating || updatingExperienceLogo;
        const isSubmitDisabled = isSubmitLoading || pristine || invalid;

        return (
          <StyledFormNode onSubmit={handleSubmit}>
            <Grid container alignItems="center" direction="column">
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name="experienceLogoImage"
                  label="New Image"
                  width={300}
                  height={200}
                  component={ImageInputField}
                  maxFiles={1}
                  imageDim={[300, 120]}
                />
              </Grid>

              <Grid item xs={12} mt={2}>
                <Modal.Actions>
                  <Modal.Action
                    type="secondary"
                    content="Cancel"
                    onAction={closeModal}
                    disabled={isSubmitDisabled}
                  />

                  <Modal.Action
                    type="primary"
                    content="Save"
                    onAction={handleSubmit}
                    disabled={isSubmitDisabled}
                    loading={isSubmitLoading}
                  />
                </Modal.Actions>
              </Grid>
            </Grid>
          </StyledFormNode>
        );
      }}
    />
  );
};
