import {
  CheckpointNotificationValue,
  UnitOfMeasure,
  UnitsOfMeasureType,
} from 'src/types/registrationOption';
import { TransactionFeesType } from 'src/types/experience';

export const UNITS_OF_MEASURE_OPTIONS = [
  {
    groupName: UnitsOfMeasureType.Imperial,
  },
  {
    label: UnitOfMeasure.Yards,
    value: UnitOfMeasure.Yards,
  },
  {
    label: UnitOfMeasure.Miles,
    value: UnitOfMeasure.Miles,
  },
  {
    groupName: UnitsOfMeasureType.Metric,
  },
  {
    label: UnitOfMeasure.Meters,
    value: UnitOfMeasure.Meters,
  },
  {
    label: UnitOfMeasure.Kilometers,
    value: UnitOfMeasure.Kilometers,
  },
];

export const REGISTRATION_OPTION_SELECT_OPTIONS = [
  {
    label: TransactionFeesType.organization,
    value: TransactionFeesType.organization,
  },
  {
    label: TransactionFeesType.supporter,
    value: TransactionFeesType.supporter,
  },
  {
    label: TransactionFeesType.organizationSupporter,
    value: TransactionFeesType.organizationSupporter,
  },
];

export const CHECKPOINT_NOTIFICATION_OPTIONS = [
  {
    label: 'Send only to attendee',
    value: CheckpointNotificationValue.OnlyAttendee,
  },
  {
    label: 'Send only to Campaign Staff',
    value: CheckpointNotificationValue.OnlyCampaignStaff,
  },
  {
    label: 'Send to attendee AND Campaign Staff',
    value: CheckpointNotificationValue.AttendeeAndCampaignStaff,
  },
];
