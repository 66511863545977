import { useState } from 'react';

import {
  useExperienceGalleryAttachImages,
  useExperienceGalleryDetachImages,
  useExperienceGalleryList,
} from '@vizsla/hooks';
import { File } from '@vizsla/graphql';

export function useExperienceGallery() {
  const [loading, setLoading] = useState(false);

  const { data: images, refresh } = useExperienceGalleryList();
  const { attach } = useExperienceGalleryAttachImages();
  const { detach } = useExperienceGalleryDetachImages();

  const attachImages = async (payload: File | File[]) => {
    setLoading(true);

    try {
      const images = Array.isArray(payload) ? payload : [payload];

      await attach(images);
      await refresh();
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  const detachImage = async (file: File) => {
    setLoading(true);

    try {
      await detach([file]);
      await refresh();
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  return {
    images,
    loading,
    attachImages,
    detachImage,
  };
}
