import { useApolloClient } from '@apollo/client';
import React from 'react';

import {
  EarlyBirdRegistrationInvitation,
  EarlyBirdRegistrationInvitationsQuery,
  useEarlyBirdRegistrationInvitationCreateMutation,
  useEarlyBirdRegistrationInvitationDeleteMutation,
  useEarlyBirdRegistrationInvitationsQuery,
} from '@vizsla/graphql';

import { EARLY_BIRD_REGISTRATION_INVITATIONS_QUERY } from 'src/graphql';
import {
  EarlyBirdRegistrationInvitationSubmitValues,
  EarlyBirdRegistrationInvitationType,
} from 'src/types/earlyBirdRegistration';

const EARLY_BIRD_REGISTRATION_INVITATIONS_LIST_TYPENAME =
  'EarlyBirdRegistrationInvitationListResponse';

const useEarlyBirdRegistrationInvitations = (experienceId: string) => {
  const apolloClient = useApolloClient();

  const variables = React.useMemo(
    () => ({
      filter: {
        experience: {
          id: {
            equals: experienceId,
          },
        },
      },
    }),
    [experienceId],
  );

  const { data: earlyBirdRegistrationInvitationsQueryResult, loading } =
    useEarlyBirdRegistrationInvitationsQuery({
      variables,
    });

  const [earlyBirdRegistrationInvitationCreateMutation, { loading: creating }] =
    useEarlyBirdRegistrationInvitationCreateMutation();

  const [earlyBirdRegistrationInvitationDeleteMutation, { loading: deleting }] =
    useEarlyBirdRegistrationInvitationDeleteMutation();

  const earlyBirdRegistrationInvitations = earlyBirdRegistrationInvitationsQueryResult
    ?.earlyBirdRegistrationInvitationsList?.items as Array<EarlyBirdRegistrationInvitation>;

  const getEarlyBirdRegistrationInvitationsFromCache = () => {
    const data = apolloClient.readQuery<EarlyBirdRegistrationInvitationsQuery>({
      query: EARLY_BIRD_REGISTRATION_INVITATIONS_QUERY,
      variables,
    });

    return data?.earlyBirdRegistrationInvitationsList?.items;
  };

  const addEarlyBirdRegistrationInvitationToCache = (
    earlyBirdRegistrationInvitation?: EarlyBirdRegistrationInvitation | null,
  ) => {
    if (!earlyBirdRegistrationInvitation) {
      return;
    }

    const earlyBirdRegistrationInvitationsFromCache =
      getEarlyBirdRegistrationInvitationsFromCache();

    if (!earlyBirdRegistrationInvitationsFromCache) {
      return;
    }

    const updatedEarlyBirdRegistrationInvitations = [
      ...earlyBirdRegistrationInvitationsFromCache,
      earlyBirdRegistrationInvitation,
    ];

    apolloClient.writeQuery<EarlyBirdRegistrationInvitationsQuery>({
      query: EARLY_BIRD_REGISTRATION_INVITATIONS_QUERY,
      variables,
      data: {
        earlyBirdRegistrationInvitationsList: {
          items: updatedEarlyBirdRegistrationInvitations,
          __typename: EARLY_BIRD_REGISTRATION_INVITATIONS_LIST_TYPENAME,
        },
      },
    });
  };

  const removeEarlyBirdRegistrationInvitationFromCache = (invitationId: string) => {
    try {
      const earlyBirdRegistrationInvitationsFromCache =
        getEarlyBirdRegistrationInvitationsFromCache();

      if (!earlyBirdRegistrationInvitationsFromCache) {
        return;
      }

      const updatedEarlyBirdRegistrationInvitations =
        earlyBirdRegistrationInvitationsFromCache.filter(
          invitation => invitation.id !== invitationId,
        );

      apolloClient.writeQuery<EarlyBirdRegistrationInvitationsQuery>({
        query: EARLY_BIRD_REGISTRATION_INVITATIONS_QUERY,
        data: {
          earlyBirdRegistrationInvitationsList: {
            items: updatedEarlyBirdRegistrationInvitations,
            __typename: EARLY_BIRD_REGISTRATION_INVITATIONS_LIST_TYPENAME,
          },
        },
        variables,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const createEarlyBirdRegistrationInvitation = async ({
    id,
    invitationType,
  }: EarlyBirdRegistrationInvitationSubmitValues) => {
    const relationName =
      invitationType === EarlyBirdRegistrationInvitationType.attendee ? 'attendee' : 'team';

    const { data: invitation } = await earlyBirdRegistrationInvitationCreateMutation({
      variables: {
        data: {
          experience: {
            connect: {
              id: experienceId,
            },
          },
          invitationType,
          [relationName]: {
            connect: {
              id,
            },
          },
        },
      },
    });
    addEarlyBirdRegistrationInvitationToCache(
      invitation?.earlyBirdRegistrationInvitationCreate as
        | EarlyBirdRegistrationInvitation
        | undefined
        | null,
    );
  };

  const deleteEarlyBirdRegistrationInvitation = async (invitationId: string) => {
    await earlyBirdRegistrationInvitationDeleteMutation({
      variables: {
        id: invitationId,
      },
    });
    removeEarlyBirdRegistrationInvitationFromCache(invitationId);
  };

  return {
    earlyBirdRegistrationInvitations,
    createEarlyBirdRegistrationInvitation,
    deleteEarlyBirdRegistrationInvitation,
    loading,
    creating,
    deleting,
  };
};

export default useEarlyBirdRegistrationInvitations;
