import React from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { FabProps, Fab, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PaletteColor } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  floatingButton: {
    '&.MuiFab-root': {
      backgroundColor: 'transparent',
      color: PaletteColor.DefaultLight,
      boxShadow: 'none',
      transition: `all 0.2s ${theme.transitions.easing.easeOut}`,
    },
    '&:hover': {
      backgroundColor: PaletteColor.ErrorMain,
      color: PaletteColor.ContrastText,
    },
  },
}));

export const FloatingCloseButton: React.FC<FabProps> = props => {
  const classes = useStyles();

  return (
    <Fab aria-label="close" className={classes.floatingButton} {...props}>
      <CloseIcon />
    </Fab>
  );
};
