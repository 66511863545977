import React from 'react';
import { Grid, Skeleton } from '@mui/material';

export const TicketingOptionEditFormSkeleton: React.FC = () => {
  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={6}>
        <Skeleton variant="text" height={64} />
      </Grid>

      <Grid item xs={6}>
        <Skeleton variant="text" height={64} />
      </Grid>

      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={150} />
      </Grid>

      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={75} />
      </Grid>

      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={75} />
      </Grid>

      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={75} />
      </Grid>

      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={75} />
      </Grid>

      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={75} />
      </Grid>

      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={75} />
      </Grid>
    </Grid>
  );
};
