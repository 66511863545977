import { gql } from '@apollo/client';

export const CAMPAIGN_QUESTION_EXPERIENCE_FRAGMENT = gql`
  fragment CampaignQuestionExperienceFragment on Experience {
    id
    name
  }
`;

export const DEFAULT_QUESTIONS_BY_CATEGORY_QUERY = gql`
  query DefaultQuestionsByCategory {
    defaultQuestionsList {
      items {
        id
        type
        title
        category
        isMandatory
      }
    }
  }
`;

export const CAMPAIGN_DEFAULT_QUESTION_FRAGMENT = gql`
  fragment DefaultCampaignQuestionFragment on DefaultCampaignQuestion {
    id
    isEnabled
    isRequired
    shouldDisplay
    order
    experience {
      items {
        ...CampaignQuestionExperienceFragment
      }
    }
    question {
      id
      title
      name
      category
      type
      format
      options
      userGroup
      isMandatory
    }
  }
  ${CAMPAIGN_QUESTION_EXPERIENCE_FRAGMENT}
`;

export const CAMPAIGN_CUSTOM_QUESTION_FRAGMENT = gql`
  fragment CustomCampaignQuestionFragment on CustomCampaignQuestion {
    id
    title
    isEnabled
    isRequired
    userGroup
    type
    format
    options
    order
    experience {
      items {
        ...CampaignQuestionExperienceFragment
      }
    }
  }
  ${CAMPAIGN_QUESTION_EXPERIENCE_FRAGMENT}
`;

export const CAMPAIGN_CUSTOM_QUESTION_LIST_QUERY = gql`
  query CampaignCustomQuestionList($filter: CustomCampaignQuestionFilter) {
    customCampaignQuestionsList(filter: $filter) {
      items {
        id
        type
        options
        experience {
          items {
            ...CampaignQuestionExperienceFragment
          }
        }
      }
    }
  }
  ${CAMPAIGN_QUESTION_EXPERIENCE_FRAGMENT}
`;

export const CAMPAIGN_QUESTION_LIST_QUERY = gql`
  query CampaignQuestions(
    $id: ID!
    $defaultQuestionsFilter: DefaultCampaignQuestionFilter
    $customQuestionsFilter: CustomCampaignQuestionFilter
  ) {
    campaign(id: $id) {
      id
      defaultCampaignQuestions(filter: $defaultQuestionsFilter) {
        items {
          ...DefaultCampaignQuestionFragment
        }
      }
      customCampaignQuestions(filter: $customQuestionsFilter) {
        items {
          ...CustomCampaignQuestionFragment
        }
      }
    }
  }
  ${CAMPAIGN_DEFAULT_QUESTION_FRAGMENT}
  ${CAMPAIGN_CUSTOM_QUESTION_FRAGMENT}
`;

export const CAMPAIGN_CUSTOM_QUESTION_CREATE_MUTATION = gql`
  mutation customCampaignQuestionCreate($data: CustomCampaignQuestionCreateInput!) {
    customCampaignQuestionCreate(data: $data) {
      ...CustomCampaignQuestionFragment
    }
  }
  ${CAMPAIGN_CUSTOM_QUESTION_FRAGMENT}
`;

export const CAMPAIGN_DEFAULT_QUESTIONS_CREATE_MANY_MUTATION = gql`
  mutation CampaignDefaultQuestionsCreateMany($data: [DefaultCampaignQuestionCreateManyInput]!) {
    defaultCampaignQuestionCreateMany(data: $data) {
      items {
        id
      }
      count
    }
  }
`;

export const UPDATE_DEFAULT_CAMPAIGN_QUESTION_MUTATION = gql`
  mutation UpdateDefaultCampaignQuestion($id: ID!, $data: DefaultCampaignQuestionUpdateInput!) {
    defaultCampaignQuestionUpdate(data: $data, filter: { id: $id }) {
      id
      isEnabled
      isRequired
      shouldDisplay
      experience {
        items {
          ...CampaignQuestionExperienceFragment
        }
      }
    }
  }
  ${CAMPAIGN_QUESTION_EXPERIENCE_FRAGMENT}
`;

export const UPDATE_CUSTOM_CAMPAIGN_QUESTION_MUTATION = gql`
  mutation UpdateCustomCampaignQuestion($id: ID!, $data: CustomCampaignQuestionUpdateInput!) {
    customCampaignQuestionUpdate(data: $data, filter: { id: $id }) {
      ...CustomCampaignQuestionFragment
      experience {
        items {
          ...CampaignQuestionExperienceFragment
        }
      }
    }
  }
  ${CAMPAIGN_QUESTION_EXPERIENCE_FRAGMENT}
  ${CAMPAIGN_CUSTOM_QUESTION_FRAGMENT}
`;

export const UPDATE_CUSTOM_CAMPAIGN_QUESTION_BY_FILTER_MUTATION = gql`
  mutation UpdateCustomQuestionsByFilter(
    $data: CustomCampaignQuestionUpdateByFilterInput!
    $filter: CustomCampaignQuestionFilter
  ) {
    customCampaignQuestionUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
        isEnabled
      }
    }
  }
`;

export const UPDATE_DEFAULT_CAMPAIGN_QUESTION_BY_FILTER_MUTATION = gql`
  mutation UpdateDefaultQuestionsByFilter(
    $data: DefaultCampaignQuestionUpdateByFilterInput!
    $filter: DefaultCampaignQuestionFilter
  ) {
    defaultCampaignQuestionUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
        isEnabled
      }
    }
  }
`;

export const DELETE_CUSTOM_CAMPAIGN_QUESTION = gql`
  mutation CustomCampaignQuestionDelete($data: CustomCampaignQuestionDeleteInput!) {
    customCampaignQuestionDelete(data: $data) {
      success
    }
  }
`;
