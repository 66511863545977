import React from 'react';
import { Grid } from '@mui/material';

import { SampleMuiComponent } from 'src/components/shared';

export const HomePage: React.FC = () => {
  return (
    <Grid container spacing={2} py={4} px={12}>
      <SampleMuiComponent />
    </Grid>
  );
};
