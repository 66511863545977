import * as yup from 'yup';

import { AddressSchema, PhoneSchemaRequired } from '@vizsla/constants';

export const VizslaOrganizationCreateSchema = yup.object({
  name: yup.string().required('Organization Name is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  logo: yup.object().nullable().notRequired(),
  ein: yup
    .string()
    .required('EIN number is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(9, 'Must be exactly 9 digits')
    .max(9, 'Must be exactly 9 digits'),
  email: yup.string().email('Invalid Email').required('Email is required'),
  contactPhone: PhoneSchemaRequired,
  contactAddress: AddressSchema,
});
export const VizslaOrganizationUpdateSchema = yup.object({
  name: yup.string().required('Organization Name is required'),
  ein: yup
    .string()
    .required('EIN number is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(9, 'Must be exactly 9 digits')
    .max(9, 'Must be exactly 9 digits'),
  contactEmail: yup.string().email('Invalid Email').required('Email is required').nullable(),
  contactPhone: PhoneSchemaRequired,
  contactAddress: AddressSchema,
  logo: yup.object().nullable().notRequired(),
});

export const VizslaOrganizationLocationSchema = yup.object({
  timeZone: yup.string().required('Time Zone is required'),
  currency: yup.string().required('Currency is required'),
  language: yup.string().required('Language is required'),
  preferredDateFormat: yup.string().required('Preferred Date Format is required'),
});

export const VizslaOrganizationSocialMediaSchema = yup.object({
  socialMedia: yup.string().required('Social Media is required'),
  uRL: yup.string().required('URL is required'),
});
