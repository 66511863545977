import React from 'react';
import { Field, Form } from 'react-final-form';
import { Grid } from '@mui/material';

import { PartnershipOrganization, useCreatePartnershipMutation } from '@vizsla/graphql';
import { Modal, SelectField, TextField } from '@vizsla/components';
import { MODALS, ORGANIZATION_TYPE } from '@vizsla/constants';
import { useCurrentOrganization, useModal, useNotification } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';

import { ImageInputField } from 'src/components/forms';
import { partnershipCreateSchema } from 'src/constants/validationSchemas/partnership';
import { t } from 'src/utils/template';

import { ModalContent } from './styles';

const PartnershipModal = () => {
  const [loading, setLoading] = React.useState(false);
  const { isOpen, closeModal } = useModal(MODALS.PARTNERSHIP_CREATE_MODAL);

  const onCloseModal = () => {
    closeModal();
  };
  const { organizationId } = useCurrentOrganization();
  const INITIAL_VALUES = {
    name: '',
    type: '',
  };
  const [CreatePartnership, { loading: loadingUpdatePartnership }] = useCreatePartnershipMutation();

  const validate = React.useCallback(async (formValues: PartnershipOrganization) => {
    return validateWithSchema(partnershipCreateSchema, formValues);
  }, []);

  const notification = useNotification();

  const onSubmit = async (data: PartnershipOrganization) => {
    try {
      setLoading(true);
      await CreatePartnership({
        variables: {
          data: {
            name: data.name as string,
            type: data.type,
            email: data.email as string,
            ...(data?.avatar?.fileId
              ? {
                  avatar: {
                    create: {
                      fileId: data.avatar?.fileId,
                      filename: data.avatar?.filename,
                      public: data.avatar?.public,
                    },
                  },
                }
              : {}),
            organization: {
              connect: {
                id: organizationId,
              },
            },
          },
        },
        refetchQueries: ['PartnershipOrganizationsList'],
        awaitRefetchQueries: true,
      });
      notification.success(t('partnership_create_success'));
      setLoading(false);
      closeModal();
    } catch (error) {
      notification.error(t('partnership_create_error'));
      console.error({ error });
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} fullWidth>
      <Form
        initialValues={INITIAL_VALUES}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, submitting, hasValidationErrors }) => {
          const isSubmitDisabled = submitting || hasValidationErrors;

          return (
            <form onSubmit={handleSubmit}>
              <Grid container display="flex" alignItems="center" flexDirection="column" spacing={1}>
                <Modal.Title>New Partnership</Modal.Title>
                <ModalContent>
                  <Field
                    fullWidth
                    name="avatar"
                    label="Add Logo"
                    width={100}
                    height={100}
                    component={ImageInputField}
                  />

                  <Field fullWidth name="name" label="Name" component={TextField} required />
                  <Field
                    fullWidth
                    name="type"
                    label="Type"
                    component={SelectField}
                    options={ORGANIZATION_TYPE}
                    required
                  />
                  <Field fullWidth name="email" label="Email" component={TextField} required />
                </ModalContent>
                <Grid item xs={12}>
                  <Modal.Actions>
                    <Modal.Action
                      type="secondary"
                      content="Cancel"
                      onAction={onCloseModal}
                      disabled={isSubmitDisabled}
                    />
                    <Modal.Action
                      type="primary"
                      content="Save"
                      onAction={handleSubmit}
                      disabled={isSubmitDisabled}
                      loading={loading}
                    />
                  </Modal.Actions>
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export { PartnershipModal };
