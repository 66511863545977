import React from 'react';
import { Typography, Grid } from '@mui/material';
import { useLocation, matchPath, Link } from 'react-router-dom';

import { getLocationPaths } from '@vizsla/utils';
import { useOrganizationById, useOrganizationId } from '@vizsla/hooks';

import * as appRoutes from 'src/constants/routes';

interface BreadcrumbsPathProps {
  path: string;
  index: number;
  organizationName: string | null | undefined;
}

const getMenuItems = (organizationName: string | null | undefined) => {
  return [
    {
      title: '',
      path: appRoutes.adminRoot,
    },
    {
      title: 'Settings',
      path: appRoutes.adminSettings,
    },
    {
      title: 'Organizations',
      path: appRoutes.adminOrganizations,
    },
    {
      title: 'Organizations',
      path: appRoutes.adminOrganization,
    },
    {
      title: 'Payments',
      path: appRoutes.adminSettingsPayments,
    },
    {
      title: organizationName ?? '...',
      path: appRoutes.adminOrganizationProfileInfo,
    },
    {
      title: organizationName ?? '...',
      path: appRoutes.adminOrganizationPaymentMethods,
    },
    {
      title: organizationName ?? '...',
      path: appRoutes.adminOrganizationUsers,
    },
    {
      title: organizationName ?? '...',
      path: appRoutes.adminOrganizationSubscriptionPlans,
    },
    {
      title: organizationName ?? '...',
      path: appRoutes.adminOrganizationPaymentHistory,
    },
  ];
};

const BreadcrumbsPath: React.FC<BreadcrumbsPathProps> = ({ path, index, organizationName }) => {
  const menuItems = getMenuItems(organizationName);
  const area = menuItems.find(item => Boolean(matchPath(item.path, path)));

  if (!area) {
    return null;
  }

  return (
    <React.Fragment>
      {index > 1 && (
        <Grid key="/" item>
          <Typography color="textSecondary">&#8594;</Typography>
        </Grid>
      )}
      <Grid key="item" item>
        <Typography
          sx={{ textDecoration: 'none' }}
          component={Link}
          to={path}
          color="textSecondary"
        >
          {area.title}
        </Typography>
      </Grid>
    </React.Fragment>
  );
};

interface BreadcrumbsProps {}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = () => {
  const location = useLocation();

  const organizationId = useOrganizationId();
  const { organization } = useOrganizationById(organizationId);

  const organizationName = organization?.name;

  const paths = getLocationPaths(location.pathname, 1);

  return (
    <Grid container spacing={1}>
      {paths.map((path, index) => (
        <BreadcrumbsPath key={path} path={path} index={index} organizationName={organizationName} />
      ))}
    </Grid>
  );
};
