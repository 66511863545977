import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Typography,
  CircularProgress,
} from '@mui/material';

import { useModal, useCurrentOrganization, useNotification } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';
import { useCampaignUpdateMutation } from '@vizsla/graphql';

import { PROGRAMS_LIST_QUERY } from 'src/graphql';
import { MODALS } from 'src/constants/modals';

export const ReassingProgram = () => {
  const notification = useNotification();
  const { organizationId } = useCurrentOrganization();
  const [programId, setProgramId] = React.useState<string>('');
  const { isOpen, closeModal, args } = useModal(MODALS.REASSING_PROGRAM);
  const [updateCampaignMutation, { loading: updating }] = useCampaignUpdateMutation();

  const {
    data,
    loading: loadingPrograms,
    refetch,
  } = useQuery(PROGRAMS_LIST_QUERY, {
    variables: {
      filter: { organization: { id: { equals: organizationId } } },
    },
  });
  const programData = React.useMemo(() => {
    return data?.programsList?.items;
  }, [data, loadingPrograms]);

  const handleSelect = (e: any) => {
    setProgramId(e.target.value);
  };
  const campaign = React.useMemo(() => {
    if (args) {
      return args.campaign;
    }
    return {};
  }, [args]);

  React.useEffect(() => {
    setProgramId(campaign?.program?.id);
  }, [campaign]);

  const onClick = async () => {
    try {
      await updateCampaignMutation({
        variables: {
          data: {
            id: campaign?.id,
            program:
              campaign?.program !== null
                ? {
                    reconnect: {
                      id: programId,
                    },
                  }
                : undefined,
          },
        },
      });
      notification.success('Successfully Reassigned Program');
      closeModal();
    } catch ({ error }) {
      notification.error('Failed Reassing Program');
      console.error({ error });
      throw error;
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      fullWidth
      customClasses={[`backgroundColor: 'red'`]}
    >
      <Modal.Title>
        <Typography textAlign="center" variant="h2">
          Reassing Program
        </Typography>
      </Modal.Title>
      <Modal.Content>
        {!loadingPrograms && programData ? (
          <FormControl fullWidth>
            <InputLabel id="select-program">Select Program</InputLabel>
            <Select labelId="select-Program" value={programId} onChange={handleSelect}>
              {programData.map(item => (
                <MenuItem key={item?.id} value={item?.id || 'none'}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <Modal.Actions>
              <Modal.Action type="secondary" content="Cancel" onAction={closeModal} />
              <Modal.Action
                type="primary"
                content="Update"
                onAction={onClick}
                loading={updating}
                disabled={false}
              />
            </Modal.Actions>
          </FormControl>
        ) : (
          <CircularProgress size={48} color="secondary" />
        )}
      </Modal.Content>
    </Modal>
  );
};
