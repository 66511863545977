import React from 'react';
import { Add } from '@mui/icons-material';

import { useModal, useNotification, useDonationOptions } from '@vizsla/hooks';
import { TableBottomAction, TableColumn, TableData, TableRowAction } from '@vizsla/components';
import { formatMoney } from '@vizsla/utils';
import { DonationOption, DonationOptionCreateInput } from '@vizsla/graphql';

import { useExperienceId } from 'src/hooks/experiences';
import { ConfirmationDeleteDialog, PaperBlock, TextWithImageCell } from 'src/components/shared';
import { DonationOptionModal } from 'src/modals';
import { MODALS } from 'src/constants/modals';
import { t } from 'src/utils/template';

export default function ExperienceDonationOptions() {
  const notification = useNotification();

  const experienceId = useExperienceId();

  const {
    loading,
    donationOptions,
    createDonationOption,
    updateDonationOption,
    deleteDonationOption,
  } = useDonationOptions(experienceId);

  const { openModal } = useModal();

  const onCreateDonationOption = () => {
    openModal(MODALS.DONATION_OPTION_MODAL, {
      titleText: 'Add Donation Option',
      submitButtonText: 'Add',
      shouldDisplayImageField: true,
      onSubmit: async (values: DonationOptionCreateInput) => {
        const objectName = 'Donation Option';
        try {
          await createDonationOption(values);
          notification.success(
            t('entity_create_success', {
              objectName,
            }),
          );
        } catch (errorMessage) {
          notification.error(
            t('entity_create_error', {
              objectName,
              errorMessage,
            }),
          );
        }
      },
    });
  };

  const onEditDonationOption = (donationOption: DonationOption) => {
    // disabled cos i need to destructure __typename from request
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { __typename, image, ...initialValues } = donationOption;
    openModal(MODALS.DONATION_OPTION_MODAL, {
      titleText: 'Edit Donation Option',
      submitButtonText: 'Update',
      initialValues,
      onSubmit: async (values: any) => {
        const objectName = 'Donation Option';

        const { description, price, id } = values;
        try {
          await updateDonationOption({ description, price, id });
          notification.success(
            t('entity_update_success', {
              objectName,
            }),
          );
        } catch (errorMessage) {
          notification.error(
            t('entity_update_error', {
              objectName,
              errorMessage,
            }),
          );
        }
      },
    });
  };

  const onDeleteDonationOption = (donationOption: DonationOption) => {
    openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
      objectName: 'Donation Option',
      deleteFunction: () => deleteDonationOption(donationOption.id ?? ''),
    });
  };

  const columns: Array<TableColumn> = [
    {
      key: 'price',
      dataPath: 'price',
      title: 'Price',
      render: (price: number, row: DonationOption) => {
        return <TextWithImageCell text={formatMoney(price, 2)} image={row?.image?.downloadUrl} />;
      },
    },
    {
      key: 'description',
      dataPath: 'description',
      title: 'Description',
    },
  ];

  const rowActions: Array<TableRowAction> = [
    {
      actionName: 'Edit',
      onAction: onEditDonationOption,
    },
    {
      actionName: 'Delete',
      onAction: onDeleteDonationOption,
    },
  ];

  const bottomActions: Array<TableBottomAction> = [
    {
      icon: <Add />,
      actionName: 'add donation option',
      onAction: onCreateDonationOption,
    },
  ];

  return (
    <React.Fragment>
      <PaperBlock collapsing title="Donation Options" defaultOpened>
        <TableData
          dataSource={donationOptions}
          columns={columns}
          loading={loading}
          bottomActions={bottomActions}
          rowActions={rowActions}
        />
      </PaperBlock>
      <DonationOptionModal />
      <ConfirmationDeleteDialog />
    </React.Fragment>
  );
}
