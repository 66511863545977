import React from 'react';

import { useExperienceLanding } from '@vizsla/providers';

import { CollapsingList } from './CollapsingList';
import {
  ActionButtonsSection,
  RaisedProgressBarSection,
  RegistrationOptionsSection,
  OurStorySection,
  SwitchLandingLeaderBoard,
  SwitchLandingActivity,
} from './sections';

export const ContentSection = () => {
  const { loading } = useExperienceLanding();

  return (
    <React.Fragment>
      <CollapsingList loading={loading} title="Buttons (Call to Actions)">
        <ActionButtonsSection />
      </CollapsingList>

      <CollapsingList loading={loading} title="Raised Progress Bar">
        <RaisedProgressBarSection />
      </CollapsingList>

      <CollapsingList loading={loading} title="Our Story">
        <OurStorySection />
      </CollapsingList>

      <CollapsingList loading={loading} title="Registrations">
        <RegistrationOptionsSection />
      </CollapsingList>

      <SwitchLandingLeaderBoard title="Leaderboard" />

      <SwitchLandingActivity title="Activity" />
    </React.Fragment>
  );
};
