import React from 'react';
import _ from 'lodash';
import { Grid, Typography } from '@mui/material';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { parseHtmlText } from '@vizsla/utils';

import { RichTextEditor } from 'src/components/shared';
import { PaletteColor } from 'src/theme';

type RichEditorFieldProps = {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  maxTextLength?: number;
  height?: number;
  note?: string;
};

export const RichEditorField: React.FC<RichEditorFieldProps> = ({
  input: { onChange, value },
  meta: { initial, pristine, touched, error },
  height,
  maxTextLength,
  note,
  ...rest
}) => {
  const initialEditorState = React.useMemo(() => {
    if (!initial) {
      return EditorState.createEmpty();
    }

    const { contentBlocks, entityMap } = htmlToDraft(initial);

    return EditorState.createWithContent(
      ContentState.createFromBlockArray(contentBlocks, entityMap),
    );
  }, [initial]);

  const [editorState, setEditorState] = React.useState<EditorState>(initialEditorState);

  const selectionState = React.useMemo(() => {
    return editorState.getSelection();
  }, [editorState]);

  const textLength = React.useMemo(() => {
    const htmlText = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    if (htmlText) {
      const parsedText = parseHtmlText(htmlText);
      return parsedText ? parsedText?.length - 1 : 0;
    }

    return 0;
  }, [editorState]);

  const onEditorStateChange = React.useCallback(
    (editorState: EditorState) => {
      const convertedState = draftToHtml(convertToRaw(editorState.getCurrentContent()));

      setEditorState(editorState);
      onChange(convertedState);
    },
    [onChange],
  );

  // When form.reset() is called the field becomes pristine
  // And its value is reverted to initial or empty.
  // Forcing selection is supposed to help preserve the cursor position
  // When editor is focused, but returned to initial state.
  React.useEffect(() => {
    const isFormReset = pristine && (initial ? value === initial : value === '');

    if (isFormReset) {
      const initialStateWithSelection = selectionState.getHasFocus()
        ? EditorState.forceSelection(initialEditorState, selectionState)
        : initialEditorState;
      onEditorStateChange(initialStateWithSelection);
    }
  }, [initial, pristine, onEditorStateChange, value, initialEditorState, selectionState]);

  return (
    <React.Fragment>
      <RichTextEditor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        height={height}
        {...rest}
      />
      <Grid container paddingTop="5px">
        <Grid item xs={10}>
          {touched && error ? (
            <Typography variant="caption" color={PaletteColor.ErrorMain}>
              {error}
            </Typography>
          ) : (
            <Typography variant="caption" color={PaletteColor.GrayText}>
              {note}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2} textAlign="right">
          {!_.isNil(maxTextLength) && (
            <Typography variant="caption" color={PaletteColor.GrayText}>
              {textLength}/{maxTextLength}
            </Typography>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
