export enum AllocationType {
  SpecificRegistrationOption = 'Specific Registration Option',
  TopFundraisers = 'Top Fundraisers',
  TeamCaptains = 'Team Captains',
  SpecificTeams = 'Specific Teams',
}

export const ALLOCATION_TYPE_OPTIONS = [
  {
    label: 'Top Fundraisers',
    value: AllocationType.TopFundraisers,
  },
  {
    label: 'Team Captains',
    value: AllocationType.TeamCaptains,
  },
  {
    label: 'Specific Teams',
    value: AllocationType.SpecificTeams,
  },
];
