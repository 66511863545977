import React from 'react';
import { Grid } from '@mui/material';
import { useField } from 'react-final-form';

import { Field, DateField, RadioGroupField, TimeField } from '@vizsla/components';

import { DateType } from 'src/constants/campaign';

interface CampaignDetailsDatesInputsProps {
  shouldRenderDateTypeSwitch: boolean;
  dateType: DateType;
  errorState: string | undefined;
  errorStateCallback: (value: string | undefined) => void;
}

export const CampaignDetailsDatesInputs: React.FC<CampaignDetailsDatesInputsProps> = ({
  shouldRenderDateTypeSwitch,
  dateType,
  errorState,
  errorStateCallback,
}) => {
  const startDateField = useField('startDate');
  const endDateField = useField('endDate');
  const startTimeField = useField('startTime');
  const endTimeField = useField('endTime');

  const validateDates = React.useCallback(
    (startDate, endDate) => {
      if (!endDate || !startDate) {
        errorStateCallback(undefined);
        return undefined;
      }
      const newErrorMessage =
        startDate >= endDate ? 'Start date must be before end date' : undefined;
      if (errorState !== newErrorMessage) {
        errorStateCallback(newErrorMessage);
      }
      return newErrorMessage;
    },
    [errorState, errorStateCallback],
  );
  const validateTime = (startTime, endTime) => {
    return startTime >= endTime ? 'Start Time must be before end Time' : undefined;
  };
  React.useEffect(() => {
    validateDates(startDateField.input.value, endDateField.input.value);
  }, [startDateField.input.value, endDateField.input.value, validateDates]);

  const renderDateFieldStartDate = (name: string, label: string) => (
    <Grid item xs={3}>
      <Field
        name={name}
        label={label}
        fullWidth
        component={props => <DateField {...props} error={errorState} />}
        validate={value => validateDates(value, endDateField.input.value)}
      />
    </Grid>
  );
  const renderDateFieldEndDate = (name: string, label: string) => (
    <Grid item xs={3}>
      <Field
        name={name}
        label={label}
        fullWidth
        component={props => <DateField {...props} error={errorState} />}
        validate={value => validateDates(startDateField.input.value, value)}
      />
    </Grid>
  );
  const renderTimeFieldStartTime = (name: string, label: string) => (
    <Grid item xs={3}>
      <Field
        name={name}
        label={label}
        fullWidth
        component={props => <TimeField {...props} error={errorState} />}
        validate={value => validateTime(value, endTimeField.input.value)}
      />
    </Grid>
  );

  const renderTimeFieldEndTime = (name: string, label: string) => (
    <Grid item xs={3}>
      <Field
        name={name}
        label={label}
        fullWidth
        component={props => <TimeField {...props} error={errorState} />}
        validate={value => validateTime(startTimeField.input.value, value)}
      />
    </Grid>
  );

  const renderFields = () => {
    switch (dateType) {
      case DateType.Single: {
        return (
          <React.Fragment>
            {renderDateFieldStartDate('startDate', 'Start Date *')}
            {renderTimeFieldStartTime('startTime', 'Start Time *')}
          </React.Fragment>
        );
      }
      case DateType.Range: {
        return (
          <React.Fragment>
            {renderDateFieldStartDate('startDate', 'Start Date *')}
            {renderTimeFieldStartTime('startTime', 'Start Time *')}
            {renderDateFieldEndDate('endDate', 'End Date *')}
            {renderTimeFieldEndTime('endTime', 'End Time *')}
          </React.Fragment>
        );
      }
      default:
        return null;
    }
  };

  const renderSwitch = () => {
    if (!shouldRenderDateTypeSwitch) {
      return null;
    }
    return (
      <Grid item xs={12}>
        <Field
          name="dateType"
          options={[
            { label: 'Single Date', value: DateType.Single },
            { label: 'Date Range', value: DateType.Range },
          ]}
          component={RadioGroupField}
        />
      </Grid>
    );
  };

  return (
    <Grid container spacing={2}>
      {renderSwitch()}
      {renderFields()}
    </Grid>
  );
};
