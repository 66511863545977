import React, { ChangeEvent } from 'react';
import { Checkbox, Chip, FormControlLabel, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Add } from '@mui/icons-material';

import { useModal, useNotification } from '@vizsla/hooks';
import { BottomActionButton } from '@vizsla/components';
import { ExperienceBibAssignment, User } from '@vizsla/graphql';

import { useExperienceBibAssignment, useExperienceId } from 'src/hooks/experiences';
import { TagInput } from 'src/components/shared';
import { RenderTag } from 'src/components/forms';
import { ExperienceIndividualBibCreateModal } from 'src/modals';
import { MODALS } from 'src/constants/modals';
import { getUserFullName } from 'src/utils/user';
import { t } from 'src/utils/template';
import { FontSize, PaletteColor } from 'src/theme';

const useStyles = makeStyles(() => ({
  checkboxLabel: {
    fontSize: FontSize.Default,
    color: PaletteColor.BlackText,
  },
  checkboxStyle: {
    '&.MuiButtonBase': {
      padding: '6px',
    },
  },
}));

export const CampaignExperienceIndividualBibAssignmentSection: React.FC = () => {
  const classes = useStyles();
  const experienceId = useExperienceId();
  const notification = useNotification();
  const { openModal } = useModal();

  const {
    loading,
    individualBibAssignments,
    allowIndividualBibAssignment,
    updateBibSettings,
    updatingBibSettings,
    deleteAssignment,
    deletingAssignment,
  } = useExperienceBibAssignment(experienceId);

  const isChecked = Boolean(allowIndividualBibAssignment);
  const isLoading = loading || deletingAssignment;
  const isDisabled = loading || deletingAssignment || updatingBibSettings;

  const handleChangeIndividualAssignmentStatus = async (event: ChangeEvent<any>) => {
    const value = event.target.checked as boolean;
    try {
      await updateBibSettings({ allowIndividualBibAssignment: value });
      notification.success(t('experience_update_success'));
    } catch (error) {
      notification.error(t('experience_update_error'));
    }
  };

  const handleCreateIndividualBib = () => {
    openModal(MODALS.INDIVIDUAL_BIB_CREATE_MODAL);
  };

  const handleDelete = async (chip: ExperienceBibAssignment) => {
    openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
      deleteFunction: () => deleteAssignment(chip?.id as string),
      objectName: `${chip?.bibNumber} - ${getUserFullName(chip?.attendee?.user as User)}`,
      objectType: 'Individual Bib number',
    });
  };

  const renderTag: RenderTag = (chip: ExperienceBibAssignment) => (
    <Chip
      disabled={isDisabled}
      variant="outlined"
      key={chip?.id}
      label={`${chip?.bibNumber} - ${getUserFullName(chip?.attendee?.user as User)}`}
      onDelete={() => handleDelete(chip)}
    />
  );

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <FormControlLabel
          classes={{
            label: classes.checkboxLabel,
          }}
          label="Assign bibs to individual attendees"
          control={
            <Checkbox
              name="allowIndividualBibAssignment"
              disabled={updatingBibSettings}
              checked={isChecked}
              onChange={handleChangeIndividualAssignmentStatus}
              className={classes.checkboxStyle}
            />
          }
        />
      </Grid>
      {isChecked && (
        <React.Fragment>
          {Boolean(individualBibAssignments.length) && (
            <Grid item>
              <TagInput
                value={individualBibAssignments}
                placeholder=""
                renderTag={renderTag}
                disabled={isDisabled}
                loading={isLoading}
                readOnly
                fullWidth
              />
            </Grid>
          )}

          <Grid item>
            <BottomActionButton
              icon={<Add />}
              onClick={() => handleCreateIndividualBib()}
              disabled={updatingBibSettings}
            >
              Add Individual Bib
            </BottomActionButton>
            <ExperienceIndividualBibCreateModal />
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};
