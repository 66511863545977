import React from 'react';
import { Avatar, AvatarProps, Grid, Theme, Typography, TypographyProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { User } from '@vizsla/graphql';

import { getUserFullName } from 'src/utils/user';

const useStyles = makeStyles<Theme>(theme => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  default: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

interface UserAvatarFullNameProps extends AvatarProps {
  user: User;
  size?: 'small' | 'default' | 'large';
  typographyVariant?: TypographyProps['variant'];
}

export const UserAvatarFullName: React.FC<UserAvatarFullNameProps> = ({
  user,
  size = 'default',
  typographyVariant = 'subtitle2',
  ...props
}) => {
  const classes = useStyles();
  const fullName = getUserFullName(user);

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <Avatar
          className={classes[size]}
          alt={fullName}
          src={user.avatar?.downloadUrl || ''}
          {...props}
        />
      </Grid>
      <Grid item>
        <Typography variant={typographyVariant}>{fullName}</Typography>
      </Grid>
    </Grid>
  );
};
