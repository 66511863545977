import React, { useMemo, useState } from 'react';
import { Formik } from 'formik';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { Button, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// import { FormCheckbox } from 'shared/components';
import { paymentMethodFormSchema } from 'src/utils/paymentMethod';

import { Actions, Form, Section, Title } from './PaymentMethodForm.styles';

export interface Values {
  terms: boolean;
}

const INITIAL: Values = {
  terms: false,
};

interface Props {
  initial?: Values;

  /** @default "Submit" */
  labelSubmit?: string;

  /** @default "Cancel" */
  labelCancel?: string;

  onSubmit(values: Values, stripe: Stripe, elements: StripeElements): Promise<void>;

  onCancel?(): void;
}

export function PaymentMethodForm(props: Props) {
  const [submitting, setSubmitting] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const labelCancel = useMemo(() => props.labelCancel ?? 'Cancel', [props.labelCancel]);
  const labelSubmit = useMemo(() => props.labelSubmit ?? 'Submit', [props.labelCancel]);

  const loading = useMemo(() => submitting, [submitting]);

  const initial: Values = useMemo(() => {
    return { ...props.initial, ...INITIAL };
  }, [props.initial]);
  const handleSubmit = async (values: Values) => {
    if (!stripe || !elements) return;

    setSubmitting(true);
    await props.onSubmit(values, stripe, elements);
    setSubmitting(false);
  };

  const handleCancel = () => {
    props.onCancel?.();
  };

  return (
    <Formik<Values>
      initialValues={initial}
      validationSchema={paymentMethodFormSchema}
      onSubmit={handleSubmit}
    >
      {form => (
        <Form>
          <Section>
            <Title>Payment Method Information</Title>

            <PaymentElement />
            <FormControlLabel
              control={
                <Checkbox
                  id="terms"
                  name="terms"
                  color="primary"
                  checked={form.values.terms}
                  required
                />
              }
              id="terms"
              name="terms"
              label={
                <Typography variant="body2">
                  I authorize Vizsla inc., to automatically charge the credit card indicated in this
                  authorization form according to the terms outlined in lorem ipsum dolor asimet et
                  al consenectur obveserembum piterm.
                </Typography>
              }
              inputRef={form.validateForm}
              onChange={form.handleChange}
            />
          </Section>

          <Actions>
            <Button disabled={loading} onClick={handleCancel}>
              {labelCancel}
            </Button>
            <LoadingButton
              variant="contained"
              loading={loading}
              disabled={loading || !form.isValid}
              onClick={form.submitForm}
            >
              {labelSubmit}
            </LoadingButton>
          </Actions>
        </Form>
      )}
    </Formik>
  );
}
