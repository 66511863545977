import React, { ChangeEvent } from 'react';
import { Checkbox, Chip, FormControlLabel, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useModal, useNotification } from '@vizsla/hooks';
import { ExperienceBibAssignment } from '@vizsla/graphql';
import { NAN } from '@vizsla/constants';

import { useExperienceId, useExperienceBibAssignment } from 'src/hooks/experiences';
import { TagInput } from 'src/components/shared';
import { MODALS } from 'src/constants/modals';
import { ExcludedBibValidationErrors } from 'src/types/bibAssignment';
import { t } from 'src/utils/template';
import { FontSize, PaletteColor } from 'src/theme';

const useStyles = makeStyles(() => ({
  checkboxLabel: {
    fontSize: FontSize.Default,
    color: PaletteColor.BlackText,
  },
  checkboxStyle: {
    '&.MuiButtonBase': {
      padding: '6px',
    },
  },
}));

export const CampaignExperienceExcludeBibsSection: React.FC = () => {
  const classes = useStyles();
  const experienceId = useExperienceId();
  const notification = useNotification();

  const { openModal } = useModal();

  const {
    loading,
    excludedBibAssignments,
    individualBibAssignments,
    allowExcludedBibNumbers,
    updateBibSettings,
    updatingBibSettings,
    excludeAssignment,
    excludingAssignment,
    deleteAssignment,
    deletingAssignment,
  } = useExperienceBibAssignment(experienceId);

  const isLoading = loading || excludingAssignment || deletingAssignment;
  const isDisabled = isLoading || updatingBibSettings;
  const isChecked = !!allowExcludedBibNumbers;

  const validateExcludedBibNumber = (newExcludedBibNumber: string): string => {
    if (NAN.test(newExcludedBibNumber)) {
      return ExcludedBibValidationErrors.isNAN;
    }
    const duplicates = excludedBibAssignments.filter(
      (excludedBibNumber: Record<string, any>) =>
        excludedBibNumber?.bibNumber === Number(newExcludedBibNumber),
    );
    if (duplicates.length) {
      return ExcludedBibValidationErrors.isDuplicateAnotherExcludedBib;
    }
    const individualBibNumbers = individualBibAssignments.filter(
      (individuallyAssignedBib: ExperienceBibAssignment) =>
        individuallyAssignedBib?.bibNumber === Number(newExcludedBibNumber),
    );

    if (individualBibNumbers.length) {
      return ExcludedBibValidationErrors.isDuplicateAnotherIndividualBib;
    }

    return '';
  };

  const handleOnAdd = async (newExcludedBibNumber: string) => {
    const validationError = validateExcludedBibNumber(newExcludedBibNumber);

    if (validationError) {
      notification.error(t(validationError, { bibNumber: newExcludedBibNumber }));
    } else {
      try {
        await excludeAssignment(Number(newExcludedBibNumber));

        notification.success(
          t('experience_excluded_bib_number_create_success', { bibNumber: newExcludedBibNumber }),
        );
      } catch (e) {
        notification.error(
          t('experience_excluded_bib_number_create_error', { bibNumber: newExcludedBibNumber }),
        );
      }
    }
  };

  const handleDelete = async (chip: ExperienceBibAssignment) => {
    openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
      deleteFunction: () => deleteAssignment(chip?.id as string),
      objectName: chip?.bibNumber,
      objectType: 'Excluded Bib number',
    });
  };

  const handleChangeExcludeStatus = async (event: ChangeEvent<any>) => {
    const value = event.target.checked as boolean;
    try {
      await updateBibSettings({ allowExcludedBibNumbers: value });
      notification.success(t('experience_update_success'));
    } catch (error) {
      notification.error(t('experience_update_error'));
    }
  };

  const renderTag = (chip: ExperienceBibAssignment) => (
    <Chip
      disabled={isDisabled}
      variant="outlined"
      key={chip?.id}
      label={chip?.bibNumber}
      onDelete={() => handleDelete(chip)}
    />
  );

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <FormControlLabel
          classes={{
            label: classes.checkboxLabel,
          }}
          label="Exclude specific bib numbers from assignment"
          control={
            <Checkbox
              name="allowExcludedBibNumbers"
              disabled={updatingBibSettings}
              checked={isChecked}
              onChange={handleChangeExcludeStatus}
              className={classes.checkboxStyle}
            />
          }
        />
      </Grid>
      {isChecked && (
        <Grid item paddingBottom={3}>
          <TagInput
            value={excludedBibAssignments}
            placeholder="Add Number"
            renderTag={renderTag}
            onAdd={handleOnAdd}
            disabled={isDisabled}
            loading={isLoading}
            fullWidth
          />
        </Grid>
      )}
    </Grid>
  );
};
