import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getUserRoles, validateWithSchema } from '@vizsla/utils';
import { useAppAuth, useNotification } from '@vizsla/hooks';
import { Shade } from '@vizsla/theme';
import { LoginSchema } from '@vizsla/constants';
import { AuthTextField, Field } from '@vizsla/components';
import { SystemRoles } from '@vizsla/types';

import * as appRoutes from 'src/constants/routes';
import { useAllowedRoutes } from 'src/hooks/authorization';
import { OrganizationAuthLayout } from 'src/layouts';

import { StyledLink, StyledButton } from '../components/styled-components';

const useStyles = makeStyles((theme: Theme) => ({
  loginForm: {
    marginRight: 'auto',
    width: 440,
  },
  signupTextContainer: {
    marginTop: theme.spacing(4),
  },
  signupText: {
    color: Shade.Gray[400],
  },
  submitButton: {
    margin: theme.spacing(2.5, 0, 0, 0),
  },
}));

const AuthLoginPage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { isAuthenticated, login, logout } = useAppAuth();
  const { startRoutePath } = useAllowedRoutes();
  const notification = useNotification();

  if (isAuthenticated) {
    navigate(startRoutePath);
  }

  const onSubmit = React.useCallback(
    async data => {
      try {
        const newUser = await login(data);
        const userRoles = getUserRoles(newUser);

        if (
          userRoles.system.includes(SystemRoles.VizslaAdministrator) ||
          userRoles.system.includes(SystemRoles.OrganizationAdministrator)
        ) {
          return navigate(startRoutePath);
        }

        await logout();
        notification.error(`You don't have access to this app`);
        window.localStorage.clear();
        return navigate('/');
      } catch (e) {
        // todo: replace with toast message
        return {
          email: 'Incorrect email or password',
          password: 'Incorrect email or password',
        };
      }
    },
    [login, logout, navigate, notification, startRoutePath],
  );

  return (
    <OrganizationAuthLayout title="Welcome to Vizsla" caption="Login to your account">
      <Form onSubmit={onSubmit} validate={values => validateWithSchema(LoginSchema, values)}>
        {({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
          const isDisabled = submitting || pristine || hasValidationErrors;

          return (
            <form onSubmit={handleSubmit} className={classes.loginForm}>
              <Box mt={1}>
                <Field
                  name="email"
                  label="Email Address"
                  component={AuthTextField}
                  e2e-id="email"
                />
              </Box>
              <Box mt={1}>
                <Field
                  name="password"
                  label="Password"
                  component={AuthTextField}
                  type="password"
                  e2e-id="password"
                />
              </Box>
              <Box>
                <StyledButton loading={submitting} disabled={isDisabled} e2e-id="submit">
                  Log In
                </StyledButton>
              </Box>
              <Box mt={4}>
                <Typography className={classes.signupText} variant="caption">
                  Don&apos;t have an Account?&nbsp;
                </Typography>
                <StyledLink to={appRoutes.authRegistration}>Sign Up Now</StyledLink>
              </Box>
              <Box mt={1}>
                <StyledLink to={appRoutes.authForgotPassword}>Forgot Password?</StyledLink>
              </Box>
            </form>
          );
        }}
      </Form>
    </OrganizationAuthLayout>
  );
};

export { AuthLoginPage };
