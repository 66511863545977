import React from 'react';
import { Tooltip } from '@mui/material';

import { DeterminateProgressDashboard } from 'src/components/shared';

import {
  Container,
  ExtraContainer,
  ExtraGrid,
  Header,
  HelpIcon,
  Rows,
  Section,
  LabelText,
  ValueText,
  ExtraValueText,
  ExtraLabelText,
  Skeleton,
} from './MetricCard.styles';

export interface MetricCardData {
  label: string;
  value: string;
  description?: string;
}

interface PropsWithLoading {
  loading: true;
}

interface PropsWithData extends MetricCardData {
  icon?: React.ReactNode;
  percentage?: number;

  metrics?: MetricCardData[];
}

type Props = PropsWithLoading | PropsWithData;

export function MetricCard(props: Props) {
  if ('loading' in props) {
    return <Skeleton />;
  }

  return (
    <Container>
      <Rows>
        <Section>
          <Header>
            {props.icon}

            <LabelText>{props.label}</LabelText>

            {props.description && (
              <Tooltip title={props.description}>
                <HelpIcon />
              </Tooltip>
            )}
          </Header>

          <ValueText>{props.value}</ValueText>
        </Section>

        {typeof props.percentage === 'number' && (
          <Section>
            <DeterminateProgressDashboard
              size={70}
              thickness={8}
              value={props.percentage || 0}
              showCaption={false}
            />
          </Section>
        )}
      </Rows>

      {Array.isArray(props.metrics) && (
        <ExtraGrid>
          {props.metrics.map(metric => (
            <ExtraContainer key={metric.label}>
              <Header>
                <ExtraLabelText>{metric.label}</ExtraLabelText>

                {metric.description && (
                  <Tooltip title={metric.description}>
                    <HelpIcon />
                  </Tooltip>
                )}
              </Header>

              <ExtraValueText>{metric.value}</ExtraValueText>
            </ExtraContainer>
          ))}
        </ExtraGrid>
      )}
    </Container>
  );
}
