import React from 'react';
import { Field, Form } from 'react-final-form';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useNotification } from '@vizsla/hooks';
import { Button, DateField, TimeField } from '@vizsla/components';
import { validateWithSchema } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';

import { useExperienceId, useExperienceBibAssignment } from 'src/hooks/experiences';
import { BibAutoAssignDates, BibAutoAssignDatesFormData } from 'src/types/bibAssignment';
import { getAutoAssignDatesUpdateInput, getAutoAssignmentStatus } from 'src/utils/bibAssignment';
import { ExperienceBibAutoDatesFormSchema } from 'src/constants/validationSchemas/bibAssignment';
import { t } from 'src/utils/template';

import { BibAutoAssignmentStatusChip } from './BibAutoAssignmentStatusChip';

const useStyles = makeStyles((theme: Theme) => ({
  assignBtn: {
    margin: 0,
    padding: theme.spacing(1, 3),

    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const getInitialValues = (dates: BibAutoAssignDates) => ({
  autoAssignStartDate: dates?.start,
  autoAssignStartTime: dates?.start,
  autoAssignEndDate: dates?.end,
  autoAssignEndTime: dates?.end,
});

export const CampaignExperienceBibAutoAssignmentForm: React.FC = () => {
  const classes = useStyles();

  const notification = useNotification();

  const experienceId = useExperienceId();
  const { updateBibSettings, bibAutoAssignDates } = useExperienceBibAssignment(experienceId);

  const initialValues = React.useMemo(
    () => getInitialValues(bibAutoAssignDates),
    [bibAutoAssignDates],
  );

  const status = getAutoAssignmentStatus(bibAutoAssignDates);

  const onSubmit = React.useCallback(
    async (data: BibAutoAssignDatesFormData) => {
      const settingsUpdateInput = getAutoAssignDatesUpdateInput(data);

      try {
        await updateBibSettings(settingsUpdateInput);

        notification.success(t('experience_auto_assignment_dates_change_success'));
      } catch (e) {
        notification.error(t('experience_auto_assignment_dates_change_error'));
      }
    },
    [notification, updateBibSettings],
  );

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item my={1}>
        <Typography variant="body2">
          Please select the appropriate bib assignment method.
        </Typography>
      </Grid>

      <Grid item>
        <BibAutoAssignmentStatusChip status={status} />
      </Grid>

      <Grid item>
        <Form
          onSubmit={onSubmit as any}
          initialValues={initialValues}
          subscription={{
            submitting: true,
            initialValues: true,
            pristine: true,
            hasValidationErrors: true,
          }}
          validate={values => validateWithSchema(ExperienceBibAutoDatesFormSchema, values)}
        >
          {({ form, submitting, handleSubmit, pristine, hasValidationErrors }) => {
            const onCancel = () => {
              form.reset();
            };

            return (
              <form>
                <Grid container spacing={3}>
                  <Grid item container xs={11}>
                    <Grid item container xs={6} alignItems="center">
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" py={2}>
                          Start Date &amp; Time
                        </Typography>
                      </Grid>

                      <Grid item xs={5} pr={1}>
                        <Field
                          name="autoAssignStartDate"
                          label="Start Date"
                          component={DateField}
                          disabled={submitting}
                          inputFormat={DateFormatPatterns.shortMonthDayYear}
                          required
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={5} pl={1}>
                        <Field
                          name="autoAssignStartTime"
                          label="Start Time"
                          component={TimeField}
                          disabled={submitting}
                          required
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Typography variant="body2" align="center">
                          -
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container xs={5}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" py={2}>
                          End Date &amp; Time
                        </Typography>
                      </Grid>

                      <Grid item xs={6} pr={1}>
                        <Field
                          name="autoAssignEndDate"
                          label="End Date"
                          component={DateField}
                          disabled={submitting}
                          inputFormat={DateFormatPatterns.shortMonthDayYear}
                          required
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={6} pl={1}>
                        <Field
                          name="autoAssignEndTime"
                          label="End Time"
                          component={TimeField}
                          disabled={submitting}
                          required
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      className={classes.assignBtn}
                      color="primary"
                      onClick={handleSubmit}
                      loading={submitting}
                      disabled={pristine || hasValidationErrors}
                    >
                      Save
                    </Button>

                    <Button
                      variant="text"
                      className={classes.assignBtn}
                      color="info"
                      onClick={onCancel}
                      disabled={submitting || pristine}
                      sx={{
                        m: 0,
                        py: 1,
                        px: 3,

                        '& + &': {
                          ml: 1,
                        },
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </Grid>
    </Grid>
  );
};
