import React, { useContext } from 'react';
import { Grid, List, ListItem } from '@mui/material';

import { ThemeColorLabels } from '@vizsla/constants';

import { ExperienceLandingActions } from 'src/providers/campaign/ThankYouLanding.types';
import { LandingStateContext } from 'src/providers/campaign/ThankYouLandingContext';

import { ColorInputField, ColorLibraryField } from '../shared';

export const ThemeColorsSection = () => {
  const { state, dispatch } = useContext(LandingStateContext);

  return (
    <List component="div" disablePadding>
      <ListItem disablePadding sx={{ paddingBottom: '8px' }}>
        <Grid container spacing={1}>
          <Grid item>
            <ColorLibraryField
              color={state.theme.accentColor || ''}
              colorLibrary={state.theme.colorLibrary || []}
              onChange={color =>
                dispatch({ type: ExperienceLandingActions.updateAccentColor, payload: color })
              }
              onChangeLibrary={colorLibrary =>
                dispatch({
                  type: ExperienceLandingActions.updateColorLibrary,
                  payload: colorLibrary,
                })
              }
              label={ThemeColorLabels.accent}
            />
          </Grid>
          <Grid item>
            <ColorInputField
              color={state.theme.accentColor || ''}
              onChange={color =>
                dispatch({ type: ExperienceLandingActions.updateAccentColor, payload: color })
              }
            />
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );
};
