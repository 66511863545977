import { useQuery } from '@apollo/client';

import type { PaginationQueryVariables } from '@vizsla/hooks';
import { UserFilter } from '@vizsla/graphql';

import { USER_CONTACT_QUERY } from 'src/graphql/peopleCRM';

interface UseOthersListProps {
  paginationQueryVariables?: PaginationQueryVariables;
  searchQueryVariables?: UserFilter;
}

interface UseOthersListData {
  loading: boolean;
  others: {
    count: number;
    items: [];
  };
}

const useOthersList = ({
  paginationQueryVariables,
  searchQueryVariables,
}: UseOthersListProps): UseOthersListData => {
  const othersListVariables = {
    filter: {
      ...searchQueryVariables,
    },
    ...paginationQueryVariables,
  };

  const { data, loading } = useQuery(USER_CONTACT_QUERY, {
    variables: { ...othersListVariables },
  });
  return {
    loading,
    others: {
      count: data?.cRMContactsList?.count,
      items: data?.cRMContactsList?.items,
    },
  };
};

export { useOthersList };
