import React from 'react';
import { Form, Field } from 'react-final-form';
import { useMutation, isApolloError } from '@apollo/client';
import { Grid, LinearProgress, Typography, DialogActions, DialogTitle } from '@mui/material';

import { useModal, useNotification } from '@vizsla/hooks';
import { Button, TextField, NumberField, SelectField } from '@vizsla/components';
import { validateWithSchema } from '@vizsla/utils';
import { CrmContactCreateInput, useUsersListLazyQuery } from '@vizsla/graphql';
import { USA_STATES_OPTIONS } from '@vizsla/constants';

import { ContactCrmCreateSchema } from 'src/constants/validationSchemas/contactCRM';
import { CONTACT_CRM_CREATE_MUTATION } from 'src/graphql/peopleCRM';
import { t } from 'src/utils/template';
import { MODALS } from 'src/constants/modals';

type Response = {
  details: any;
};

const ContactAddModalContent: React.FC = () => {
  const notification = useNotification();

  const { isOpen, closeModal } = useModal(MODALS.PEOPLE_CRM_ADD_MODAL);

  const [formData, setFormData] = React.useState({} as CrmContactCreateInput);

  const [cRMContactCreate, { loading }] = useMutation(CONTACT_CRM_CREATE_MUTATION, {
    refetchQueries: ['CRMContactsList'],
    awaitRefetchQueries: true,
  });

  const [getUser, { data, loading: isLoading }] = useUsersListLazyQuery();

  const onSubmit = async (data: CrmContactCreateInput) => {
    const { data: responseData } = await getUser({
      variables: {
        filter: { email: { contains: data.email } },
      },
    });
    if (responseData?.usersList?.items?.length) {
      return { email: 'An account with the given email already exists.' };
    }
    try {
      await cRMContactCreate({
        variables: {
          data,
        },
      });
      notification.success('User created');
      closeModal();
      return {};
    } catch (e) {
      const response: Response = e.graphQLErrors[0];
      if (response?.details?.email) {
        notification.error('An account with the given email already exists.');
        return { email: 'An account with the given email already exists.' };
      }
      notification.error('Failed to create user');
      return {};
    }
  };

  const INITIAL_VALUES = {
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    phone1: { code: '1' },
    phone2: { code: '1' },
    note: '',
    address: {
      country: 'United States',
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid>
        <Grid item>
          <Typography mt={4} mb={1} variant="h4">
            Add People
          </Typography>
        </Grid>
        <Form
          keepDirtyOnReinitialize
          onSubmit={onSubmit}
          initialValues={INITIAL_VALUES}
          validate={values => validateWithSchema(ContactCrmCreateSchema, values)}
        >
          {({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
            const isSubmitDisabled = submitting || pristine || hasValidationErrors;
            return (
              <Grid container>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Contact Information</Typography>
                    </Grid>
                    {/* <Grid item xs={6}>
                      <Typography variant="subtitle2">Last Name</Typography>
                    </Grid> */}
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        name="firstName"
                        label="Contact Name *"
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        name="lastName"
                        label="Contact Last Name *"
                        component={TextField}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Typography variant="subtitle2">Middle Name</Typography>
                    </Grid> */}
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        name="middleName"
                        label="Contact Middle Name"
                        component={TextField}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Typography variant="subtitle2">Email Address</Typography>
                    </Grid> */}
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        name="email"
                        label="Contact Email Address *"
                        component={TextField}
                      />
                    </Grid>
                    {/* <Grid item xs={6}>
                      <Typography variant="subtitle2">Phone 1</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">Phone 2</Typography>
                    </Grid> */}
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        name="phone1.number"
                        label="Phone 1 *"
                        formatMask="+1 (###) ###-####"
                        isNumericString
                        component={NumberField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        name="phone2.number"
                        label="Phone 2 "
                        formatMask="+1 (###) ###-####"
                        isNumericString
                        component={NumberField}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Address</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name="address.street1"
                        label="Address"
                        component={TextField}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Field fullWidth name="address.city" label="City" component={TextField} />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        name="address.state"
                        label="State/Province"
                        component={SelectField}
                        options={USA_STATES_OPTIONS}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        name="address.zip"
                        label="Zip Code"
                        isNumericString
                        formatMask="#####"
                        component={NumberField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        name="address.country"
                        label="Country"
                        disabled
                        component={TextField}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Note</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Field fullWidth name="note" label="Note" requierd component={TextField} />
                    </Grid>

                    <DialogActions>
                      <Grid item>
                        <Button
                          onClick={handleSubmit}
                          disabled={isSubmitDisabled}
                          loading={loading || isLoading}
                        >
                          Save
                        </Button>
                      </Grid>
                    </DialogActions>
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        </Form>
      </Grid>
    </Grid>
  );
};

export { ContactAddModalContent };
