import React from 'react';
import styled from '@emotion/styled';

interface CardLayoutProps {
  active?: boolean;

  children: React.ReactNode;
}

interface CardProps {
  active?: boolean;
}

const HEIGHT = 218;
const WIDTH = 368;

const Card = styled.div<CardProps>`
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: ${props =>
    props.active ? '0 5px 25px 0 rgba(0, 0, 0, 0.28)' : '0 5px 15px 0 rgba(0, 0, 0, 0.08)'};
  padding: ${p => p.theme.spacing(3)};
  flex: 1;

  @media (max-width: ${p => p.theme.breakpoints.values.md}px) {
    width: 100%;
    flex: auto;
  }
`;

export function TransactionsCardsLayout(props: CardLayoutProps) {
  return <Card active={props.active}>{props.children}</Card>;
}
