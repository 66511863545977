import React from 'react';
import { Grid, Typography } from '@mui/material';

import { useStoreAsset } from 'src/hooks/storeAssets';
import {
  StoreAssetManagementInfoCards,
  StoreAssetManagementTabs,
} from 'src/components/storeAssets';

const StoreAssetManagementLayout: React.FC = ({ children }) => {
  const { storeAsset } = useStoreAsset();

  return (
    <Grid container spacing={2} direction="column">
      <Grid
        item
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid item xs={12}>
          <Typography variant="h2" whiteSpace="nowrap">
            {storeAsset.name}
          </Typography>
        </Grid>
      </Grid>

      <Grid item mt={1}>
        <StoreAssetManagementInfoCards />
      </Grid>

      <Grid container spacing={2} mt={2}>
        <StoreAssetManagementTabs>{children}</StoreAssetManagementTabs>
      </Grid>
    </Grid>
  );
};

export { StoreAssetManagementLayout };
