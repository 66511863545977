import { gql } from '@apollo/client';

import { USER_FRAGMENT } from '@vizsla/graphql';

import { FILE_FRAGMENT } from './common';

export const STORE_ASSET_FRAGMENT = gql`
  fragment StoreAssetFragment on StoreAsset {
    id
    name
    description
    image {
      ...FileFragment
    }
    storeAvailability
    swagbagAvailability
    price
    unitCost
    continueSalesWhenOutOfStock
    notifyWhenStockMinimumIsAchieved
    stockMinimumBoundary
    note
    variantsPossible
  }
  ${FILE_FRAGMENT}
`;

export const STORE_ASSET_SIMPLE_FRAGMENT = gql`
  fragment StoreAssetSimpleFragment on StoreAsset {
    id
    name
    description
    continueSalesWhenOutOfStock
    price
    image {
      ...FileFragment
    }
    vizslaOrganization {
      id
    }
  }
  ${FILE_FRAGMENT}
`;

export const STORE_HISTORY_EVENT_FRAGMENT = gql`
  fragment StoreHistoryEventFragment on StoreHistoryEvent {
    id
    quantity
    type
    dateReceived
    variantsValues
    orderCost
    createdAt
    createdBy {
      ...UserFragment
    }
    note
  }
  ${USER_FRAGMENT}
`;

export const EXPERIENCE_STORE_ASSET_FRAGMENT = gql`
  fragment ExperienceStoreAssetFragment on ExperienceStoreAsset {
    id
    price
    experience {
      id
    }
    storeAsset {
      ...StoreAssetSimpleFragment
    }
  }
  ${STORE_ASSET_SIMPLE_FRAGMENT}
`;

export const EXPERIENCE_STORE_SETTINGS_FRAGMENT = gql`
  fragment ExperienceStoreSettingsFragment on Experience {
    id
    isStoreEnabled
    storeAssets {
      count
      items {
        id
      }
    }
  }
`;

export const STORE_ASSET_QUERY = gql`
  query StoreAsset($id: ID!) {
    storeAsset(id: $id) {
      ...StoreAssetFragment
    }
  }
  ${STORE_ASSET_FRAGMENT}
`;

export const STORE_ASSETS_QUERY = gql`
  query StoreAssets($filter: StoreAssetFilter, $skip: Int, $first: Int) {
    storeAssetsList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        ...StoreAssetSimpleFragment
      }
    }
  }
  ${STORE_ASSET_SIMPLE_FRAGMENT}
`;

export const STORE_ASSET_CREATE_MUTATION = gql`
  mutation StoreAssetCreate($data: StoreAssetCreateInput!) {
    storeAssetCreate(data: $data) {
      ...StoreAssetSimpleFragment
    }
  }
  ${STORE_ASSET_SIMPLE_FRAGMENT}
`;

export const STORE_ASSET_UPDATE_MUTATION = gql`
  mutation StoreAssetUpdate($id: ID!, $data: StoreAssetUpdateInput!) {
    storeAssetUpdate(data: $data, filter: { id: $id }) {
      ...StoreAssetFragment
    }
  }
  ${STORE_ASSET_FRAGMENT}
`;

export const STORE_HISTORY_EVENT_QUERY = gql`
  query StoreHistoryEvent($id: ID!) {
    storeHistoryEvent(id: $id) {
      ...StoreHistoryEventFragment
    }
  }
  ${STORE_HISTORY_EVENT_FRAGMENT}
`;

export const STORE_HISTORY_EVENTS_QUERY = gql`
  query StoreHistoryEventsList($filter: StoreHistoryEventFilter, $skip: Int, $first: Int) {
    storeHistoryEventsList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        ...StoreHistoryEventFragment
      }
    }
  }
  ${STORE_HISTORY_EVENT_FRAGMENT}
`;

export const STORE_HISTORY_EVENT_CREATE_WITH_RECALC_MUTATION = gql`
  mutation StoreHistoryEventCreateWithRecalc($data: StoreHistoryEventCreateWithRecalcInput!) {
    storeHistoryEventCreateWithRecalc(input: $data) {
      storeHistoryEvent {
        id
      }
    }
  }
`;

export const EXPERIENCE_STORE_ASSETS_LIST_QUERY = gql`
  query ExperienceStoreAssetsList($filter: ExperienceStoreAssetFilter, $skip: Int, $first: Int) {
    experienceStoreAssetsList(
      filter: $filter
      sort: { createdAt: DESC }
      skip: $skip
      first: $first
    ) {
      count
      items {
        ...ExperienceStoreAssetFragment
      }
    }
  }
  ${EXPERIENCE_STORE_ASSET_FRAGMENT}
`;

export const EXPERIENCE_STORE_SETTINGS_QUERY = gql`
  query ExperienceStoreSettings($id: ID!) {
    experience(id: $id) {
      ...ExperienceStoreSettingsFragment
    }
  }
  ${EXPERIENCE_STORE_SETTINGS_FRAGMENT}
`;

export const EXPERIENCE_STORE_ASSET_CREATE_MUTATION = gql`
  mutation ExperienceStoreAssetCreate($data: ExperienceStoreAssetCreateInput!) {
    experienceStoreAssetCreate(data: $data) {
      ...ExperienceStoreAssetFragment
    }
  }
`;

export const EXPERIENCE_STORE_ASSET_UPDATE_MUTATION = gql`
  mutation ExperienceStoreAssetUpdate($id: ID!, $data: ExperienceStoreAssetUpdateInput!) {
    experienceStoreAssetUpdate(filter: { id: $id }, data: $data) {
      ...ExperienceStoreAssetFragment
    }
  }
  ${EXPERIENCE_STORE_ASSET_FRAGMENT}
`;

export const EXPERIENCE_STORE_ASSET_DELETE_MUTATION = gql`
  mutation ExperienceStoreAssetDelete($filter: ExperienceStoreAssetKeyFilter) {
    experienceStoreAssetDelete(filter: $filter) {
      success
    }
  }
  ${EXPERIENCE_STORE_ASSET_FRAGMENT}
`;

export const EXPERIENCE_STORE_SETTINGS_UPDATE_MUTATION = gql`
  mutation ExperienceStoreSettingsUpdate($id: ID!, $data: ExperienceUpdateInput!) {
    experienceUpdate(filter: { id: $id }, data: $data) {
      ...ExperienceStoreSettingsFragment
    }
  }
  ${EXPERIENCE_STORE_SETTINGS_FRAGMENT}
`;

export const VIZSLA_GET_ALL_ASSET_INVENTORY_QUERY = gql`
  query GetAllAssetsByOrganizationList(
    $filter: GetAllAssetInventoryFilter
    $skip: Int
    $first: Int
  ) {
    getAllAssetInventoriesList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        organizationId
        aviable
        storeAvailability
        swagbagAvailability
        name
        picture
        unitPrice
        total
        profit
        sales
        price
      }
    }
  }
`;

export const VIZSLA_GET_FILES = gql`
  query GetAllFileList($filter: FileFilter) {
    filesList(filter: $filter) {
      items {
        id
        downloadUrl
      }
    }
  }
`;

export const VIZSLA_GET_ASSET_INVENTORY_QUERY = gql`
  query getAllAssetInventory($id: ID!) {
    getAllAssetInventory(id: $id) {
      name
      variantsPossible
      unitPrice
      total
      swagbagAvailability
      storeAvailability
      sales
      profit
      picture
      organizationId
      id
      aviable
      description
      continueSalesWhenOutOfStock
      note
      notifyWhenStockMinimumIsAchieved
      price
      stockMinimumBoundary
    }
  }
`;

export const VIZSLA_GET_FILE = gql`
  query getAssetPicture($id: ID!) {
    file(id: $id) {
      downloadUrl
      fileId
      filename
      id
    }
  }
`;

export const PUBLIC_CLIENT_GET_STORE_ASSETS_BY_EXPERIENCE_VIEW_QUERY = gql`
  query getAssetByExperiencieViewsList($filter: GetAssetByExperiencieViewFilter!) {
    getAssetByExperiencieViewsList(filter: $filter) {
      items {
        available
        continueSalesWhenOutOfStock
        id
        assetId
        name
        picture
        price
        storeAvailability
        total
        assetPrice
        experienceStoreAssetId
      }
    }
  }
`;

export const STORE_GHOST_ITEM_CREATE_MUTATION = gql`
  mutation StoreGhostItemCreate($data: StoreInventoryItemCreateInput!) {
    storeInventoryItemCreate(data: $data) {
      id
    }
  }
`;

export const STORE_GHOST_ITEM_CREATE_EDIT_MUTATION = gql`
  mutation storeGhostItemUpdateByFilter(
    $data: StoreInventoryItemUpdateByFilterInput!
    $filter: StoreInventoryItemFilter
  ) {
    storeInventoryItemUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
      }
    }
  }
`;
