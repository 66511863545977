import React from 'react';
import { ApolloError } from '@apollo/client';

import { useApolloCacheQueryManager } from '@vizsla/hooks';
import {
  Campaign,
  ClientCampaignQuery,
  ClientCampaignQueryVariables,
  useClientCampaignQuery,
  useCampaignUpdateMutation,
  CLIENT_CAMPAIGN_QUERY,
} from '@vizsla/graphql';

import { CampaignDetailItem } from 'src/types/campaign';

type CampaignResult = {
  data: CampaignDetailItem | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  updateCampaign: (campaignData: any, refetchQueries?: string[]) => void;
  updating: boolean;
};

export const useCampaign = (campaignId: string): CampaignResult => {
  const variables: ClientCampaignQueryVariables = {
    id: campaignId,
  };

  const { data, loading, error } = useClientCampaignQuery({
    skip: !campaignId,
    variables,
  });

  const { updateQuery: updateCampaignQuery } = useApolloCacheQueryManager<
    ClientCampaignQuery,
    ClientCampaignQueryVariables
  >({
    query: CLIENT_CAMPAIGN_QUERY,
    typeName: 'Campaign',
    variables,
  });

  const campaign = data?.campaign as Campaign | undefined;

  const [updateCampaignMutation, { loading: updating }] = useCampaignUpdateMutation();

  const updateCampaign = React.useCallback(
    async (campaignData: any, refetchQueries?: string[]) => {
      try {
        const { data } = await updateCampaignMutation({
          variables: {
            data: {
              id: campaignId,
              ...campaignData,
            },
          },
          refetchQueries,
          awaitRefetchQueries: true,
        });

        if (data?.campaign) {
          updateCampaignQuery(queryResult => {
            queryResult.campaign = data?.campaign as Campaign;
          });
        }
      } catch ({ error }) {
        console.error({ error });
        throw error;
      }
    },
    [updateCampaignMutation, campaignId, updateCampaignQuery],
  );

  return { data: campaign, loading, updateCampaign, updating, error };
};
