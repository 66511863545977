import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import _ from 'lodash';

import { useModal } from '@vizsla/hooks';
import { Button } from '@vizsla/components';

import { useCampaignFAQs, useCampaignId } from 'src/hooks/campaign';
import { MODALS } from 'src/constants/modals';

const useStyles = makeStyles((theme: Theme) => ({
  wrapperContent: {
    padding: theme.spacing(2),
  },
}));

export const CampaignFaqDeleteDialog: React.FC = () => {
  const classes = useStyles();
  const { isOpen, closeModal, args } = useModal(MODALS.CAMPAIGN_FAQ_DELETE_MODAL);

  const campaignId = useCampaignId();
  const { deleteCampaignFaq, getCampaignFaqFromCacheById, deleting } = useCampaignFAQs(campaignId);

  const onCloseModal = () => {
    closeModal();
  };

  const onCancel = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  const onDelete = React.useCallback(async () => {
    if (_.isNil(args?.faqId)) {
      closeModal();
    }

    try {
      await deleteCampaignFaq(args.faqId);
      closeModal();
    } catch (error) {
      console.error({ error });
    }
  }, [closeModal, deleteCampaignFaq, args?.faqId]);

  if (_.isNil(args?.faqId)) {
    return null;
  }

  const campaignFaq = getCampaignFaqFromCacheById(args.faqId);

  return (
    <Dialog open={isOpen} onClose={onCloseModal}>
      <div className={classes.wrapperContent}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
            size="large"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: theme => theme.spacing(8, 8, 4, 8) }}>
          <Grid container justifyContent="center">
            <Typography variant="body1">
              <span>Delete Question</span>
              <b>{` "${campaignFaq?.question}" `}</b>?
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: theme => theme.spacing(0, 0, 2, 0) }}>
          <Grid container justifyContent="center">
            <Button color="info" variant="contained" onClick={onCancel} disabled={deleting}>
              Cancel
            </Button>
            <Button onClick={onDelete} loading={deleting}>
              Delete
            </Button>
          </Grid>
        </DialogActions>
      </div>
    </Dialog>
  );
};
