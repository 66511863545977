import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { PaperBlock } from '@vizsla/components';
import { getUserFullName } from '@vizsla/utils';
import { IndividualFundraising, TeamFundraising } from '@vizsla/graphql';
import { NO_APPLY_LABEL } from '@vizsla/constants';

import { ORDERS_BY_DONOR } from 'src/graphql/orders';
import { useSelectedTransactionRow } from 'src/hooks/transactions';

import { Paragraph, Subtitle } from './CampaignDetails.style';

export function CampaignDetails() {
  const { data: dataTransaction } = useSelectedTransactionRow();
  const { orderId } = useParams();

  const { data: dataDonor, loading } = useQuery(ORDERS_BY_DONOR, {
    variables: {
      orderId,
    },
  });

  const individualFundraiser = React.useMemo(() => {
    let fundraiser: IndividualFundraising | null = null;

    const fulfillments = dataDonor?.order?.donations?.items ?? [];

    for (const fulfillment of fulfillments) {
      const allocations = fulfillment?.donationAllocations?.items ?? [];
      const allocation = allocations.find(alloc => Boolean(alloc.individualFundraising));

      if (allocation) {
        fundraiser = allocation?.individualFundraising ?? null;
        break;
      }
    }

    return fundraiser;
  }, [dataDonor]);

  const teamFundraising = React.useMemo(() => {
    let fundraiser: TeamFundraising | null = null;

    const fulfillments = dataDonor?.order?.donations?.items ?? [];

    for (const fulfillment of fulfillments) {
      const allocations = fulfillment?.donationAllocations?.items ?? [];
      const allocation = allocations.find(alloc => Boolean(alloc.teamFundraising));

      if (allocation) {
        fundraiser = allocation?.teamFundraising ?? null;
        break;
      }
    }

    return fundraiser;
  }, [dataDonor]);

  const userFullName = React.useMemo(
    () => getUserFullName(individualFundraiser?.attendee?.user),
    [individualFundraiser],
  );

  if (!dataDonor || loading)
    return (
      <PaperBlock title="Campaign Details">
        <Grid container direction="row" spacing={15}>
          <Grid item>
            <Skeleton variant="text" width={80} height={20} />
            <Skeleton variant="rectangular" width={118} height={40} />
          </Grid>
          <Grid item>
            <Skeleton variant="text" width={80} height={20} />
            <Skeleton variant="rectangular" width={118} height={40} />
          </Grid>
          <Grid item>
            <Skeleton variant="text" width={80} height={20} />
            <Skeleton variant="rectangular" width={118} height={40} />
          </Grid>
          <Grid item>
            <Skeleton variant="text" width={80} height={20} />
            <Skeleton variant="rectangular" width={118} height={40} />
          </Grid>
        </Grid>
      </PaperBlock>
    );

  return (
    <PaperBlock title="Campaign Details">
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Paragraph variant="subtitle2">Campaign</Paragraph>
          <Subtitle> {dataTransaction?.CampaignName ?? NO_APPLY_LABEL}</Subtitle>
        </Grid>
        <Grid item>
          <Paragraph variant="subtitle2">Experience</Paragraph>
          <Subtitle>{dataTransaction?.ExperienceName ?? NO_APPLY_LABEL}</Subtitle>
        </Grid>
        <Grid item>
          <Paragraph variant="subtitle2">Fundraiser</Paragraph>
          <Subtitle> {userFullName ?? NO_APPLY_LABEL}</Subtitle>
        </Grid>
        <Grid item>
          <Paragraph variant="subtitle2">Team</Paragraph>
          <Subtitle> {teamFundraising ?? NO_APPLY_LABEL}</Subtitle>
        </Grid>
      </Grid>
    </PaperBlock>
  );
}
