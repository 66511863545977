import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import {
  useCurrentOrganization,
  usePagination,
  usePaginationQueryVariables,
  useSearchQueryVariables,
} from '@vizsla/hooks';
import { Attendee, AttendeeFilter } from '@vizsla/graphql';

const ATTENDEES_CRM_QUERY = gql`
  query AttendeesCRM($first: Int, $skip: Int, $filter: AttendeeFilter) {
    attendees: attendeesList(first: $first, skip: $skip, filter: $filter) {
      count
      items {
        id

        teamMembership {
          team {
            name
          }
        }

        fundraising {
          raised: allocatedDonations(
            groupBy: { query: { amount: { fn: { aggregate: SUM }, as: "total" } } }
          ) {
            groups {
              total: Float
            }
          }
        }

        user {
          id
          firstName
          lastName
          email

          avatar {
            downloadUrl
          }

          allocatedDonations(first: 1, orderBy: createdAt_DESC) {
            items {
              id
              createdAt
            }
          }

          campaigns: attendees(
            groupBy: {
              query: { experience: { campaign: { id: { as: "count", fn: { aggregate: COUNT } } } } }
            }
          ) {
            groups {
              count: Int
            }
          }
        }
      }
    }
  }
`;

export type AttendeeCRMRow = Attendee & {
  fundraising: {
    raised: {
      groups: [
        {
          total: number;
        },
      ];
    };
  };

  user: {
    id: string;
    campaigns: {
      groups: [
        {
          count: number;
        },
      ];
    };
  };
};

interface AttendeesCRMQuery {
  attendees: {
    count: number;
    items: AttendeeCRMRow[];
  };
}

interface AttendeesCRMQueryVariables {
  first?: number;
  skip?: number;
  filter?: AttendeeFilter;
}

export function useAttendeesCRM() {
  const pagination = usePagination();
  const paginationQuery = usePaginationQueryVariables();
  const searchQuery = useSearchQueryVariables(true);

  const { organizationId } = useCurrentOrganization();

  const organizationFilter: AttendeeFilter = useMemo(() => {
    return {
      experience: {
        campaign: {
          vizslaOrganization: {
            id: { equals: organizationId },
          },
        },
      },
    };
  }, [organizationId]);

  const { data: response, loading } = useQuery<AttendeesCRMQuery, AttendeesCRMQueryVariables>(
    ATTENDEES_CRM_QUERY,
    {
      variables: {
        ...paginationQuery,

        filter: {
          ...searchQuery,
          ...organizationFilter,
        },
      },
    },
  );

  useEffect(() => {
    pagination.onCountChange(response?.attendees.count ?? 0);
  }, [response]);

  const data = useMemo(() => response?.attendees.items ?? [], [response]);

  return {
    data,
    loading,
  };
}
