import * as themeUnits from '@vizsla/theme';

/** @deprecated use variables from &#64;vizsla/theme package */
export const {
  Display,
  JustifyContent,
  PaletteColor,
  Shade,
  FontSize,
  FontWeight,
  BorderRadius,
  LetterSpacing,
  AlignItems,
  theme,
} = themeUnits;
