import { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import moment from 'moment';

const QUERY = gql`
  query CampaignTeamsMetrics(
    $campaignId: ID!
    $todayDate: DateTime!
    $lastSevenDaysDate: DateTime!
  ) {
    campaign(id: $campaignId) {
      totalGoal: teamGoal

      teams {
        count
      }
    }

    lastSevenDays: donationAllocationsList(
      filter: {
        AND: [
          { teamFundraising: { campaign: { id: { equals: $campaignId } } } }
          { parents: { none: null } }
          { createdAt: { gte: $lastSevenDaysDate } }
        ]
      }
    ) {
      count
    }

    today: donationAllocationsList(
      filter: {
        AND: [
          { teamFundraising: { campaign: { id: { equals: $campaignId } } } }
          { parents: { none: null } }
          { createdAt: { gte: $todayDate } }
        ]
      }
    ) {
      count
    }
  }
`;

interface Response {
  campaign: {
    totalGoal: number;

    teams: {
      count: number;
    };
  };

  all: {
    count: number;
  };

  lastSevenDays: {
    count: number;
  };

  today: {
    count: number;
  };
}

interface Variables {
  campaignId: string;

  todayDate: string;
  lastSevenDaysDate: string;
}

interface CampaignTeamsMetrics {
  totalCount: number;
  totalGoal: number;

  totalCountLastSevenDays: number;
  totalCountToday: number;
}

export function useCampaignTeamsMetrics(campaignId?: string) {
  const [data, setData] = useState<CampaignTeamsMetrics>();
  const [loading, setLoading] = useState(false);

  const [executeQuery] = useLazyQuery<Response, Variables>(QUERY, { fetchPolicy: 'cache-first' });

  async function refetch(campaignId: string) {
    setLoading(true);

    const todayDate = moment().startOf('day').toISOString();
    const lastSevenDaysDate = moment().startOf('day').subtract(7, 'days').toISOString();

    const response = await executeQuery({
      variables: {
        campaignId,
        todayDate,
        lastSevenDaysDate,
      },
    });

    const campaign = response.data?.campaign;

    const totalCount = response.data?.campaign.teams.count ?? 0;
    const totalGoal = response.data?.campaign.totalGoal ?? 0;
    const totalCountToday = response.data?.today.count ?? 0;
    const totalCountLastSevenDays = response.data?.lastSevenDays.count ?? 0;

    const metrics: CampaignTeamsMetrics = {
      totalGoal,
      totalCount,
      totalCountToday,
      totalCountLastSevenDays,
    };

    setData(metrics);
    setLoading(false);

    return metrics;
  }

  useEffect(() => {
    if (campaignId) {
      refetch(campaignId);
    }
  }, [campaignId]);

  return {
    data,
    loading,

    refetch,
  };
}
