import {
  UserAvatar as SharedUserAvatar,
  UserAvatarFullNameInline as SharedUserAvatarFullNameInline,
  TeamAvatar as SharedTeamAvatar,
  TeamAvatarWithNameInline as SharedTeamAvatarWithNameInline,
  UserChip as SharedUserChip,
} from '@vizsla/components';

/** @deprecated use components from &#64;vizsla/components package */
export const UserAvatar = SharedUserAvatar;

/** @deprecated use components from &#64;vizsla/components package */
export const UserAvatarFullNameInline = SharedUserAvatarFullNameInline;

/** @deprecated use components from &#64;vizsla/components package */
export const TeamAvatar = SharedTeamAvatar;

/** @deprecated use components from &#64;vizsla/components package */
export const TeamAvatarWithNameInline = SharedTeamAvatarWithNameInline;

/** @deprecated use components from &#64;vizsla/components package */
export const UserChip = SharedUserChip;
