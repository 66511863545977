import { gql } from '@apollo/client';

export const EARLY_BIRD_REGISTRATION_OPTION_FRAGMENT = gql`
  fragment EarlyBirdRegistrationOptionFragment on EarlyBirdRegistrationOption {
    id
    startDate
    endDate
    registrationOption {
      id
      name
      registrationStartDate
      registrationEndDate
    }
  }
`;

export const EARLY_BIRD_REGISTRATION_INVITATION_FRAGMENT = gql`
  fragment EarlyBirdRegistrationInvitationFragment on EarlyBirdRegistrationInvitation {
    id
    invitationType
    sentDate
    attendee {
      id
      user {
        id
        avatar {
          id
          downloadUrl
        }
        firstName
        lastName
      }
    }
    team {
      id
      avatar {
        id
        downloadUrl
      }
      name
    }
  }
`;

export const EARLY_BIRD_REGISTRATION_SETTINGS_QUERY = gql`
  query EarlyBirdRegistrationSettings($id: ID!) {
    experience(id: $id) {
      id
      earlyBirdUrlCode
      earlyBirdRegistrationAvailable
      earlyBirdRegistrationsApplyTo
      earlyBirdRegistrationsMode
      earlyBirdRegistrationOptions {
        items {
          ...EarlyBirdRegistrationOptionFragment
        }
        count
      }
    }
  }
  ${EARLY_BIRD_REGISTRATION_OPTION_FRAGMENT}
`;

export const EARLY_BIRD_REGISTRATION_SETTINGS_UPDATE_MUTATION = gql`
  mutation earlyBirdRegistrationSettingsUpdate(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter!
  ) {
    experienceUpdate(data: $data, filter: $filter) {
      id
      earlyBirdRegistrationAvailable
      earlyBirdRegistrationsApplyTo
      earlyBirdRegistrationsMode
      earlyBirdRegistrationOptions {
        items {
          ...EarlyBirdRegistrationOptionFragment
        }
        count
      }
    }
  }
  ${EARLY_BIRD_REGISTRATION_OPTION_FRAGMENT}
`;

export const EARLY_BIRD_REGISTRATION_INVITATIONS_QUERY = gql`
  query earlyBirdRegistrationInvitations($filter: EarlyBirdRegistrationInvitationFilter!) {
    earlyBirdRegistrationInvitationsList(filter: $filter) {
      items {
        ...EarlyBirdRegistrationInvitationFragment
      }
    }
  }
  ${EARLY_BIRD_REGISTRATION_INVITATION_FRAGMENT}
`;

export const EARLY_BIRD_REGISTRATION_INVITATION_CREATE_MUTATION = gql`
  mutation earlyBirdRegistrationInvitationCreate(
    $data: EarlyBirdRegistrationInvitationCreateInput!
  ) {
    earlyBirdRegistrationInvitationCreate(data: $data) {
      ...EarlyBirdRegistrationInvitationFragment
    }
  }
  ${EARLY_BIRD_REGISTRATION_INVITATION_FRAGMENT}
`;

export const EARLY_BIRD_REGISTRATION_INVITATION_DELETE_MUTATION = gql`
  mutation earlyBirdRegistrationInvitationDelete($id: ID!) {
    earlyBirdRegistrationInvitationDelete(filter: { id: $id }) {
      success
    }
  }
`;
