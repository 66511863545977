import { gql } from '@apollo/client';

export const CAMPAIGN_TEAMS_LIST_QUERY = gql`
  query CampaignTeamsList($filter: TeamFundraisingFilter!) {
    teamFundraisingsList(filter: $filter) {
      items {
        avatar {
          id
          downloadUrl
        }
        id
        name
      }
    }
  }
`;

const BIB_ASSIGNMENT_FRAGMENT = gql`
  fragment BibAssignmentFragment on ExperienceBibAssignment {
    id
    type
    bibNumber
    bibPool {
      id
    }

    attendee {
      id
      user {
        id
        firstName
        lastName
      }
    }

    experience {
      id
    }
  }
`;

const BIB_POOL_FRAGMENT = gql`
  fragment BibPoolFragment on ExperienceBibPool {
    id
    name
    bibRanges
    registrationOption {
      id
      name
    }
    allocationType
    teamAllocation {
      items {
        id
        name
        avatar {
          id
          downloadUrl
        }
      }
    }
    bibAssignments {
      items {
        ...BibAssignmentFragment
      }
      count
    }
  }
  ${BIB_ASSIGNMENT_FRAGMENT}
`;

const EXPERIENCE_BIB_ASSIGNMENT_SETTINGS_FRAGMENT = gql`
  fragment ExperienceBibAssignmentSettingsFragment on Experience {
    allowIndividualBibAssignment
    allowExcludedBibNumbers

    bibAutoAssignEnabled
    bibAutoAssignStartDate
    bibAutoAssignEndDate

    bibPools {
      items {
        ...BibPoolFragment
      }
    }

    bibAssignments {
      items {
        ...BibAssignmentFragment
      }
    }
  }
  ${BIB_POOL_FRAGMENT}
  ${BIB_ASSIGNMENT_FRAGMENT}
`;

export const EXPERIENCE_BIB_ASSIGNMENT_SETTINGS_QUERY = gql`
  query ExperienceBibAssignmentSettings($id: ID!) {
    experience(id: $id) {
      ...ExperienceBibAssignmentSettingsFragment
    }
  }
  ${EXPERIENCE_BIB_ASSIGNMENT_SETTINGS_FRAGMENT}
`;

export const EXPERIENCE_BIB_ASSIGNMENT_SETTINGS_UPDATE_MUTATION = gql`
  mutation ExperienceBibAssignmentSettingsUpdate(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter
  ) {
    experienceUpdate(data: $data, filter: $filter) {
      ...ExperienceBibAssignmentSettingsFragment
    }
  }
  ${EXPERIENCE_BIB_ASSIGNMENT_SETTINGS_FRAGMENT}
`;

export const EXPERIENCE_BIB_POOL_CREATE_MUTATION = gql`
  mutation ExperienceBibPoolCreate($validatedData: JSON!, $additionalData: JSON!) {
    bibPoolCreate(validatedData: $validatedData, additionalData: $additionalData) {
      experienceBibPoolCreate
    }
  }
`;

export const EXPERIENCE_BIB_POOL_UPDATE_MUTATION = gql`
  mutation ExperienceBibPoolUpdate($validatedData: JSON!, $additionalData: JSON!) {
    bibPoolUpdate(validatedData: $validatedData, additionalData: $additionalData) {
      experienceBibPoolUpdate
    }
  }
`;

export const EXPERIENCE_BIB_POOL_DELETE_MUTATION = gql`
  mutation ExperienceBibPoolDelete($filter: ExperienceBibPoolKeyFilter!) {
    experienceBibPoolDelete(filter: $filter) {
      success
    }
  }
`;

export const EXPERIENCE_BIB_ASSIGNMENTS_LIST_QUERY = gql`
  query ExperienceBibAssignmentsList($filter: ExperienceBibAssignmentFilter) {
    experienceBibAssignmentsList(filter: $filter) {
      items {
        ...BibAssignmentFragment
      }
    }
  }
  ${BIB_ASSIGNMENT_FRAGMENT}
`;

export const EXPERIENCE_BIB_ASSIGNMENT_CREATE_MUTATION = gql`
  mutation ExperienceBibAssignmentCreate($data: ExperienceBibAssignmentCreateInput!) {
    experienceBibAssignmentCreate(data: $data) {
      ...BibAssignmentFragment
    }
  }
  ${BIB_ASSIGNMENT_FRAGMENT}
`;

export const EXPERIENCE_BIB_EXCLUDE_NUMBER_MUTATION = gql`
  mutation ExperienceBibExcludeNumber($experienceId: ID!, $bibNumber: Int!) {
    bibExcludeNumber(experienceId: $experienceId, bibNumber: $bibNumber) {
      deleted
      bibAssignment {
        ...BibAssignmentFragment
      }
    }
  }
  ${BIB_ASSIGNMENT_FRAGMENT}
`;

export const EXPERIENCE_BIB_ASSIGN_INDIVIDUAL_NUMBER_MUTATION = gql`
  mutation ExperienceBibAssignIndividualNumber(
    $experienceId: ID!
    $attendeeId: ID!
    $bibNumber: Int!
  ) {
    bibAssignIndividualNumber(
      experienceId: $experienceId
      attendeeId: $attendeeId
      bibNumber: $bibNumber
    ) {
      deleted
      bibAssignment {
        ...BibAssignmentFragment
      }
    }
  }
  ${BIB_ASSIGNMENT_FRAGMENT}
`;

export const EXPERIENCE_BIB_ASSIGN_BATCH_MUTATION = gql`
  mutation ExperienceBibAssignBatch($experienceId: ID!, $sortBy: String!, $order: String!) {
    bibAssignBatch(experienceId: $experienceId, sortBy: $sortBy, order: $order) {
      success
    }
  }
`;

export const EXPERIENCE_BIB_ASSIGNMENT_CREATE_MANY_MUTATION = gql`
  mutation ExperienceBibAssignmentCreateMany($data: [ExperienceBibAssignmentCreateManyInput!]!) {
    experienceBibAssignmentCreateMany(data: $data) {
      items {
        ...BibAssignmentFragment
      }
    }
  }
  ${BIB_ASSIGNMENT_FRAGMENT}
`;

export const EXPERIENCE_BIB_ASSIGNMENT_DELETE_MANY_MUTATION = gql`
  mutation ExperienceBibAssignmentDeleteMany($filter: ExperienceBibAssignmentFilter!) {
    experienceBibAssignmentDeleteByFilter(filter: $filter) {
      success
    }
  }
`;

export const EXPERIENCE_BIB_ASSIGNMENT_DELETE_MUTATION = gql`
  mutation ExperienceBibAssignmentDelete($filter: ExperienceBibAssignmentKeyFilter!) {
    experienceBibAssignmentDelete(filter: $filter) {
      success
    }
  }
`;
