import React from 'react';
import { Grid, GridProps, IconButton, Theme, Zoom } from '@mui/material';
import { MoreHorizOutlined, RemoveRedEyeOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { JustifyContent } from 'src/theme';

const useStyles = makeStyles<Theme, TileBarProps>(theme => ({
  container: {
    left: 0,
    top: 0,
    position: 'absolute',
    padding: theme.spacing(2),
  },
  circleBtn: {
    background: theme.palette.background.paper,
    boxShadow: '4px 4px 3px -3px rgba(34, 60, 80, 0.8)',
    height: '45px',
    width: '45px',
    '&:hover': {
      background: theme.palette.grey.A100,
    },
  },
}));

interface TileBarProps {
  onView?: () => void;
  onActions?: (event: React.MouseEvent<HTMLDivElement>) => void;
  visible?: boolean;
}

export const TileBar: React.FC<GridProps & TileBarProps> = ({
  visible = false,
  onView,
  onActions,
  ...props
}) => {
  const classes = useStyles({ visible });
  return (
    <Grid
      container
      classes={classes}
      justifyContent={JustifyContent.FlexEnd}
      spacing={1}
      {...props}
    >
      <Zoom in={visible}>
        <Grid item onClick={onView}>
          <IconButton className={classes.circleBtn} size="large">
            <RemoveRedEyeOutlined fontSize="small" />
          </IconButton>
        </Grid>
      </Zoom>
      <Zoom in={visible}>
        <Grid
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            onActions?.(event);
          }}
          item
        >
          <IconButton className={classes.circleBtn} size="large">
            <MoreHorizOutlined fontSize="small" />
          </IconButton>
        </Grid>
      </Zoom>
    </Grid>
  );
};
