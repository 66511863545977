import { Attendee, RegistrationOption, TeamFundraising } from '@vizsla/graphql';

export enum EarlyBirdRegistrationsApplyTo {
  allRegistrationOptions = 'All Registration Options',
  specificOptions = 'Specific Options',
}

export enum EarlyBirdRegistrationsMode {
  everyoneWithTheLink = 'Everyone with the link',
  specificAttendeesAndTeams = 'Specific Attendees and Teams',
}

export enum EarlyBirdRegistrationInvitationType {
  attendee = 'Attendee',
  team = 'Team',
}

export type EarlyBirdRegistrationInvitationFormValues = {
  invitationType: EarlyBirdRegistrationInvitationType;
  attendeeOrTeam: Attendee | TeamFundraising | null;
};

export type EarlyBirdRegistrationInvitationSubmitValues = {
  invitationType: EarlyBirdRegistrationInvitationType;
  id: string;
};

export type AllEarlyBirdRegistrationOptionsFormValue = {
  startTime?: string | null;
  endTime?: string | null;
  startDate?: string | null;
  endDate?: string | null;
};

export type EarlyBirdRegistrationOptionFormValue = {
  id?: string | null;
  registrationOption?: RegistrationOption | null;
} & AllEarlyBirdRegistrationOptionsFormValue;

export type EarlyBirdRegistrationSettingsFormValues = {
  earlyBirdRegistrationOptions?: Array<EarlyBirdRegistrationOptionFormValue> | null;

  allEarlyBirdRegistrationOptions?: {
    startTime?: string;
    startDate?: string;
    endTime?: string;
    endDate?: string;
  } | null;

  earlyBirdRegistrationsApplyTo?: EarlyBirdRegistrationsApplyTo;
  earlyBirdRegistrationsMode?: EarlyBirdRegistrationsMode;
};
