import { gql } from '@apollo/client';
import { INBOX_FRAGMENT, INBOX_FRAGMENT_WITH_LAST_MESSAGE, INBOX_MESSAGE_FRAGMENT, } from './InboxFragments';
export const INBOXES_QUERY = gql `
  query InboxesList($filter: InboxFilter!) {
    inboxesList(filter: $filter, sort: [{ createdAt: DESC }]) {
      items {
        ...InboxFragmentWithLastMessage
      }
    }
  }
  ${INBOX_FRAGMENT_WITH_LAST_MESSAGE}
`;
export const SEND_MESSAGE_MUTATION = gql `
  mutation SendMessage($data: InboxMessageCreateInput!) {
    inboxMessage: inboxMessageCreate(data: $data) {
      ...InboxMessageFragment
      inbox {
        id
      }
    }
  }
  ${INBOX_MESSAGE_FRAGMENT}
`;
export const INBOX_MESSAGES_QUERY = gql `
  query InboxMessages($inboxId: ID!) {
    inboxMessages: inboxMessagesList(
      filter: { inbox: { id: { equals: $inboxId } } }
      sort: [{ createdAt: ASC }]
    ) {
      items {
        ...InboxMessageFragment
      }
    }
  }
  ${INBOX_MESSAGE_FRAGMENT}
`;
export const DELETE_INBOX_MUTATION = gql `
  mutation DeleteInboxByUser($inboxId: ID!, $userId: ID!) {
    inboxUpdate(data: { id: $inboxId, deletedBy: { connect: { id: $userId } } }) {
      id
    }
  }
`;
export const MARK_INBOX_MESSAGES_AS_READ_BY_INBOX_ID_MUTATION = gql `
  mutation MarkInboxMessagesAsRead($messageId: ID!, $userId: ID!) {
    inboxMessageUpdate(data: { id: $messageId, readBy: { connect: { id: $userId } } }) {
      id
    }
  }
`;
export const INBOX_MESSAGE_SUBSCRIPTION = gql `
  subscription NewInboxMessage {
    InboxMessage(
      filter: {
        mutation_in: [create]
        node: { createdBy: { is_self: false }, inbox: { recipients: { some: { is_self: true } } } }
      }
    ) {
      node {
        ...InboxMessageFragment
        inbox {
          ...InboxFragment
        }
      }
    }
  }
  ${INBOX_MESSAGE_FRAGMENT}
  ${INBOX_FRAGMENT}
`;
export const INBOX_CREATE_MUTATION = gql `
  mutation InboxCreate($data: InboxCreateInput!) {
    inboxCreate(data: $data) {
      ...InboxFragment
    }
  }
  ${INBOX_FRAGMENT}
`;
