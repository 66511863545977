import React from 'react';
import { useApolloClient } from '@apollo/client';
import { isNil } from 'lodash';

import {
  ExperienceStoreAssetsListQuery,
  ExperienceStoreAssetsListDocument,
  ExperienceStoreAssetCreateInput,
  useExperienceStoreAssetCreateMutation,
  ExperienceStoreAssetDeleteInput,
  useExperienceStoreAssetDeleteMutation,
  ExperienceStoreAssetUpdateMutationVariables,
  useExperienceStoreAssetUpdateMutation,
} from '@vizsla/graphql';

import { ExperienceStoreDataProviderContext } from 'src/providers/storeAssets';
import type { ExperienceStoreAsset } from 'src/types/storeAssets';

export const useExperienceStoreAssets = () => {
  const apolloClient = useApolloClient();

  const contextData = React.useContext(ExperienceStoreDataProviderContext);
  const { queryVariables, assetsIds, refetch } = contextData;

  const [experienceStoreAssetCreateMutation, { loading: creating }] =
    useExperienceStoreAssetCreateMutation();

  const [experienceStoreAssetDeleteMutation, { loading: deleting }] =
    useExperienceStoreAssetDeleteMutation();

  const [experienceStoreAssetUpdateMutation, { loading: updating }] =
    useExperienceStoreAssetUpdateMutation();

  const getExperienceStoreAssetsListFromCache = React.useCallback(() => {
    const data = apolloClient.readQuery<ExperienceStoreAssetsListQuery>({
      query: ExperienceStoreAssetsListDocument,
      variables: queryVariables,
    });

    if (isNil(data)) {
      throw new Error('Error getting experience store assets data');
    }

    return data.experienceStoreAssetsList;
  }, [apolloClient, queryVariables]);

  const experienceStoreAssetsCacheWriteQuery = React.useCallback(
    (updatedCount: number, updatedStoreAssetsList: ExperienceStoreAsset[]) => {
      apolloClient.writeQuery<ExperienceStoreAssetsListQuery>({
        query: ExperienceStoreAssetsListDocument,
        variables: queryVariables,
        data: {
          experienceStoreAssetsList: {
            count: updatedCount,
            items: updatedStoreAssetsList,
            __typename: 'ExperienceStoreAssetListResponse',
          },
          __typename: 'Query',
        },
      });
    },
    [apolloClient, queryVariables],
  );

  const addStoreAssetToCache = React.useCallback(
    (rawNewStoreAsset: Omit<ExperienceStoreAsset, '__typename'>) => {
      const { items: currentStoreAssetsList, count } = getExperienceStoreAssetsListFromCache();

      const newStoreAsset: ExperienceStoreAsset = {
        ...rawNewStoreAsset,
        __typename: 'ExperienceStoreAsset',
      };

      const updatedCount = count + 1;
      const updatedStoreAssetsList = [newStoreAsset, ...currentStoreAssetsList];

      experienceStoreAssetsCacheWriteQuery(updatedCount, updatedStoreAssetsList);
    },
    [experienceStoreAssetsCacheWriteQuery, getExperienceStoreAssetsListFromCache],
  );

  const removeStoreAssetFromCacheById = React.useCallback(
    (id: string) => {
      const { items: currentStoreAssetsList, count } = getExperienceStoreAssetsListFromCache();

      const updatedCount = count - 1;
      const updatedStoreAssetsList = currentStoreAssetsList.filter(storeAsset => {
        return storeAsset.id !== id;
      });
    },
    [experienceStoreAssetsCacheWriteQuery, getExperienceStoreAssetsListFromCache],
  );

  const createExperienceStoreAsset = React.useCallback(
    async (inputData: ExperienceStoreAssetCreateInput) => {
      const { data } = await experienceStoreAssetCreateMutation({
        variables: { data: inputData },
      });

      if (isNil(data)) {
        throw new Error('Error while creating experience store asset');
      }
      refetch();
    },
    [addStoreAssetToCache, experienceStoreAssetCreateMutation],
  );

  const updateExperienceStoreAsset = React.useCallback(
    async (inputData: ExperienceStoreAssetUpdateMutationVariables) => {
      await experienceStoreAssetUpdateMutation({
        variables: inputData,
      });
      refetch();
    },
    [experienceStoreAssetUpdateMutation],
  );

  const deleteExperienceStoreAsset = React.useCallback(
    async (filter: ExperienceStoreAssetDeleteInput) => {
      const { data } = await experienceStoreAssetDeleteMutation({
        variables: { filter },
      });

      if (isNil(data)) {
        throw new Error('Error while deleting experience store asset');
      }
      refetch();
    },
    [experienceStoreAssetDeleteMutation, removeStoreAssetFromCacheById],
  );

  return {
    ...contextData,
    creating,
    updating,
    deleting,
    assetsIds,
    createExperienceStoreAsset,
    updateExperienceStoreAsset,
    deleteExperienceStoreAsset,
  };
};
