import React from 'react';
import { Form } from 'react-final-form';
import { FormApi } from 'final-form';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import _ from 'lodash';

import { useModal } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { Button, TextField, SelectField, Field } from '@vizsla/components';
import { CampaignFaq, Experience } from '@vizsla/graphql';
import { useNotification } from '@vizsla/hooks';

import { useCampaignFAQs, useCampaignId } from 'src/hooks/campaign';
import { useExperiences } from 'src/hooks/experiences';
import { RichEditorField } from 'src/components/forms';
import { MODALS } from 'src/constants/modals';
import { CAMPAIGN_FAQ_ALLOCATION_OPTIONS } from 'src/constants/campaign';
import { CampaignFaqValidationSchema } from 'src/constants/validationSchemas/campaign';
import { t } from 'src/utils/template';

export const CampaignFaqEditDialog: React.FC = () => {
  const { isOpen, closeModal, args } = useModal(MODALS.CAMPAIGN_FAQ_EDIT_MODAL);

  const campaignId = useCampaignId();
  const { updateCampaignFaq, updating } = useCampaignFAQs(campaignId);

  const notification = useNotification();

  const { data: experiences } = useExperiences(campaignId);
  const ALLOCATION_OPTIONS = experiences.map((experience: Experience) => ({
    label: experience.name || '',
    value: experience.id || '',
  }));

  const campaignFaq = args?.campaignFaq as CampaignFaq;

  const allocationInitialFormat =
    campaignFaq?.allocation?.items?.map((experience: Experience) => experience.id) ?? [];

  const initialValues = {
    allocation: allocationInitialFormat,
    userGroup: campaignFaq?.userGroup,
    question: campaignFaq?.question,
    answer: campaignFaq?.answer,
  };

  const onSubmit = async (campaignFaqData: any, form: FormApi) => {
    if (_.isNil(args?.campaignFaq)) {
      return;
    }
    const connectAllocation = campaignFaqData?.allocation?.map(id => {
      if (!allocationInitialFormat.includes(id)) {
        return id;
      }
      return null;
    });
    const connectAllocationFormated = campaignFaqData?.allocation
      ?.map(id => {
        if (!allocationInitialFormat.includes(id)) {
          return { id };
        }
        return null;
      })
      .filter(id => !!id);

    const disconnectAllocation = allocationInitialFormat.filter(
      id => !campaignFaqData?.allocation?.includes(id) && id,
    );

    const disconnectAllocationFormated =
      disconnectAllocation.map(id => ({
        id,
      })) ?? [];
    try {
      await updateCampaignFaq({
        variables: {
          data: {
            ...campaignFaqData,
            allocation: {
              disconnect: disconnectAllocationFormated,
              connect: connectAllocationFormated,
            },
          },
          filter: {
            id: args?.campaignFaq.id,
          },
        },
      });
      notification.success(t('campaign_faq_edit_success'));
      setTimeout(form.reset);
      closeModal();
    } catch (error) {
      notification.error(t('campaign_faq_edit_error'));
      console.error({ error });
    }
  };

  const onCloseModal = () => {
    closeModal();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onCloseModal}>
      <DialogTitle>
        <Grid>
          <IconButton
            aria-label="close"
            onClick={onCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
            size="large"
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid marginTop={4}>
          <Typography variant="h3" textAlign="center">
            Edit FAQ
          </Typography>
        </Grid>
      </DialogTitle>
      <Form
        onSubmit={onSubmit as any}
        initialValues={initialValues}
        validate={values => validateWithSchema(CampaignFaqValidationSchema, values)}
      >
        {({ handleSubmit, submitting, form }) => {
          const isLoading = submitting || updating;
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent sx={{ padding: theme => theme.spacing(4, 8, 4, 8) }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="allocation"
                      label="Allocation"
                      multiple
                      component={SelectField}
                      options={ALLOCATION_OPTIONS}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="userGroup"
                      label="User Groups"
                      multiple
                      component={SelectField}
                      options={CAMPAIGN_FAQ_ALLOCATION_OPTIONS}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="question"
                      label="Question"
                      component={TextField}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="answer"
                      label="Answer"
                      component={RichEditorField}
                      height={300}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ padding: theme => theme.spacing(0, 0, 4, 0) }}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      variant="text"
                      size="large"
                      color="info"
                      style={{ width: 100 }}
                      onClick={onCloseModal}
                      disabled={isLoading}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      style={{ width: 100 }}
                      size="large"
                      color="primary"
                      onClick={handleSubmit}
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </form>
          );
        }}
      </Form>
    </Dialog>
  );
};
