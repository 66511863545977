export var Locale;
(function (Locale) {
    Locale["US"] = "en-US";
})(Locale || (Locale = {}));
export const USA_STATES_OPTIONS = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
].map((state) => ({ label: state, value: state }));
export const GENDER_OPTIONS = ['Male', 'Female'].map((gender) => ({
    label: gender,
    value: gender,
}));
export const LANGUAGE_OPTIONS = ['English'].map((language) => ({
    label: language,
    value: language,
}));
export const NOTIFICATIONS_OPTIONS = ['Email', 'None'].map((notifications) => ({
    label: notifications,
    value: notifications,
}));
export const TIME_ZONE = [
    'Eastern Time (EST/EDT)',
    'Central Time(CST/CDT)',
    'Mountain Time (MST/MDT)',
    'Pacific Time (PST/PDT)',
    'Alaska Time',
    'Hawaii Time',
].map((timeZone) => ({
    label: timeZone,
    value: timeZone,
}));
export const CURRENCY = ['U.S Dollar'].map((currency) => ({
    label: currency,
    value: currency,
}));
export const PREFERRED_DATE_TIME_FORMAT = ['MM/DD/YYYY', 'DD/MM/YYYY'].map((dateFormat) => ({
    label: dateFormat,
    value: dateFormat,
}));
export const SOCIAL_MEDIA = ['Facebook', 'Youtube', 'Twitter', 'Instagram', 'LinkedIn'].map((socialMedia) => ({
    label: socialMedia,
    value: socialMedia,
}));
export const ORGANIZATION_TYPE = [
    'Corporations',
    'Individual',
    'Non-profit',
    'Potential',
    'Small Business',
    'Other',
].map((type) => ({
    label: type,
    value: type,
}));
export const FILE_MAX_SIZE = 8 * 1024 * 1024;
export const FILE_IMAGE_MIME_TYPES = ['image/jpeg', 'image/png', 'image/webp'];
export const NO_APPLY_LABEL = 'N/A';
export const NO_VALUE_LABEL = '-';
