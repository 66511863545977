import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Form, FormSpy } from 'react-final-form';
import { LoadingButton } from '@mui/lab';
import { DateTime } from 'luxon';

import { PaperBlock, Field } from '@vizsla/components';
import { CampaignGeneralFundraising } from '@vizsla/graphql';
import { useNotification } from '@vizsla/hooks';

import { SwitchCardField } from 'src/components/forms';
import { useCampaignFundraisingSettings, useCampaignId } from 'src/hooks/campaign';
import { CampaignFundraisingSettingsTypes } from 'src/types/campaign';
import faceBookIcon from 'src/assets/img/socials/facebook-icon.svg';

import { SocialNetworkCard } from './SocialNetworkCard';
import { FundraisingObligation } from './FundraisingObligation';

type FundraisingSettingsProps = {
  experienceId?: string;
  useFundraisingSettings?: (value: string) => any;
};

export const FundraisingSettings: React.FC<FundraisingSettingsProps> = ({
  experienceId,
  useFundraisingSettings = useCampaignFundraisingSettings,
}) => {
  const campaignId = useCampaignId();

  const notification = useNotification();

  const entityId = experienceId ?? campaignId;
  const { generalSettings, setSettings, loading } = useFundraisingSettings(entityId);

  const onSubmit = async (data: any) => {
    const { success } = await setSettings({
      settingsType: CampaignFundraisingSettingsTypes.generalSettings,
      data,
    });

    if (success) {
      notification.success('Saved information');
      setPrevState(saveState);
      setDisable(true);
    } else {
      notification.error('Failed to saved information');
      setDisable(true);
    }
  };

  const initialValues: CampaignGeneralFundraising = {
    pageSetUp: generalSettings?.pageSetUp || null,
    isEnabled: generalSettings?.isEnabled ?? true,
    isObligation: generalSettings?.isObligation ?? false,
    isWithoutPenalty: generalSettings?.isWithoutPenalty ?? false,
    date: generalSettings?.date || DateTime.now(),
    chargeDate: generalSettings?.chargeDate || DateTime.now(),
    time: generalSettings?.time || DateTime.now(),
    chargeTime: generalSettings?.chargeTime || DateTime.now(),
    isFacebookEnabled: generalSettings?.isFacebookEnabled ?? false,
    isInstagramEnabled: false,
  };

  const [prevState, setPrevState] = React.useState<CampaignGeneralFundraising>({
    ...initialValues,
  });
  const [saveState, setSaveState] = React.useState<CampaignGeneralFundraising>({
    ...initialValues,
  });

  const [disable, setDisable] = React.useState(true);

  const handleChanges = React.useCallback(
    data => {
      if (
        data.values.isObligation !== prevState?.isObligation ||
        data.values.isWithoutPenalty !== prevState?.isWithoutPenalty ||
        data.values.date !== prevState?.date ||
        data.values.time !== prevState?.time ||
        data.values.chargeDate !== prevState?.chargeDate ||
        data.values.chargeTime !== prevState?.chargeTime ||
        data.values.isFacebookEnabled !== prevState?.isFacebookEnabled
      ) {
        setDisable(false);
        setSaveState(data.values);
      } else {
        setDisable(true);
      }
    },
    [generalSettings],
  );

  return (
    <PaperBlock collapsing title="Fundraising Settings" defaultOpened>
      <Form initialValues={initialValues} onSubmit={onSubmit} keepDirtyOnReinitialize>
        {({ handleSubmit, pristine, submitting, form }) => (
          <form onSubmit={handleSubmit}>
            <FormSpy subscription={{ values: true }} onChange={handleChanges} />
            <Typography mb={1} variant="subtitle2">
              Fundraising Page Set-Up
            </Typography>
            <Grid container spacing={2}>
              <Grid container item height={500} spacing={4}>
                <Grid item flexGrow={1} flexBasis={0}>
                  <FundraisingObligation />
                </Grid>
                <Grid item flexGrow={1} flexBasis={0}>
                  <Field
                    name="isFacebookEnabled"
                    component={SwitchCardField}
                    image={<img src={faceBookIcon} alt="facebook icon" />}
                    title="Facebook Fundraising"
                    renderContent={(checked: boolean) => (
                      <SocialNetworkCard enabled={checked} socialNetworkName="Facebook" />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container item justifyContent="center" width="100%">
                <Button onClick={() => form.reset()} variant="text" disabled={disable}>
                  Cancel
                </Button>
                <LoadingButton loading={submitting || loading} disabled={disable} type="submit">
                  {disable ? 'Saved' : 'Save'}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </PaperBlock>
  );
};
