export const formantCardPaymentMethodBrand = (brand: string) => {
  if (brand === 'AMEX') return 'American Express';
  if (brand === 'DINERS') return 'Diners Club';
  if (brand === 'DISCOVER') return 'Discover';
  if (brand === 'JCB') return 'JCB';
  if (brand === 'MASTERDCARD') return 'MasterCard';
  if (brand === 'UNIONPAY') return 'UnionPay';
  if (brand === 'VISA') return 'Visa';

  return 'Unknown Card';
};
