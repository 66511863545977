import React from 'react';

import { TableData, TableColumn, UserAvatarFullNameInline } from '@vizsla/components';
import { useModal, usePagination } from '@vizsla/hooks';
import { formatDate, formatMoney } from '@vizsla/utils';
import { DateFormatPatterns, NO_APPLY_LABEL, NO_VALUE_LABEL } from '@vizsla/constants';

import { MODALS } from 'src/constants/modals';

import { FundraiserCRMRow, useFundraisersCRM } from './useFundraisersCRM';

const FUNDRAISERS_TABLE_COLUMNS: TableColumn<FundraiserCRMRow>[] = [
  {
    title: 'NAME',
    key: 'name',

    render(root, fundraiser) {
      if (!fundraiser?.attendee?.user) return NO_VALUE_LABEL;
      return <UserAvatarFullNameInline user={fundraiser.attendee.user} />;
    },
  },

  {
    title: 'TOTAL RAISED',
    key: 'totalRaised',

    render(root, fundraiser) {
      const [raised] = fundraiser.raised?.groups ?? [];

      return formatMoney(raised?.total ?? 0);
    },
  },

  {
    title: 'TEAM',
    key: 'team',

    render(root, fundraiser) {
      return fundraiser.attendee?.teamMembership?.team?.name ?? NO_APPLY_LABEL;
    },
  },

  {
    title: 'LAST ACTIVITY',
    key: 'lastActivity',

    render(root, fundraiser) {
      const [donation] = fundraiser.allocatedDonations?.items ?? [];

      if (!donation) return NO_VALUE_LABEL;
      return formatDate(donation.createdAt, DateFormatPatterns.fullMonthDayYear);
    },
  },
];

const FundraisersTable: React.FC = () => {
  const pagination = usePagination();

  const { openModal } = useModal();
  const { data, loading } = useFundraisersCRM();

  const handleClose = (row: FundraiserCRMRow) => {
    openModal(MODALS.PEOPLE_CRM_CONTACT_INFO_MODAL, { userId: row.attendee?.user?.id });
  };

  return (
    <TableData
      dataSource={data}
      columns={FUNDRAISERS_TABLE_COLUMNS}
      emptyMessage="There are no fundraisers in the organization"
      loading={loading}
      pagination={pagination}
      onRowClick={handleClose}
    />
  );
};

export { FundraisersTable };
