import type { RouteProps } from '@vizsla/types';

import * as appRoutes from 'src/constants/routes';

import {
  OrganizationUsers,
  OrganizationSubscriptionPlans,
  OrganizationInfo,
  OrganizationPaymentMethods,
  OrganizationPaymentHistory,
} from './components';
import { AdminOrganizationProfilePageIndex } from './AdminOrganizationProfilePageIndex';

export const adminOrganizationProfilePageRoutes: RouteProps[] = [
  {
    index: true,
    element: AdminOrganizationProfilePageIndex,
  },
  {
    path: appRoutes.adminOrganizationProfileInfo,
    element: OrganizationInfo,
  },
  {
    path: appRoutes.adminOrganizationSubscriptionPlans,
    element: OrganizationSubscriptionPlans,
  },
  {
    path: appRoutes.adminOrganizationPaymentMethods,
    element: OrganizationPaymentMethods,
  },
  {
    path: appRoutes.adminOrganizationUsers,
    element: OrganizationUsers,
  },
  {
    path: appRoutes.adminOrganizationPaymentHistory,
    element: OrganizationPaymentHistory,
  },
];
