import React from 'react';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import { FileInputValue } from '8base-react-sdk';

import { ImageInput, ImageInputProps } from 'src/components/shared';

interface ImageInputFieldProps extends ImageInputProps {
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
}

export const ImageInputField: React.FC<Omit<ImageInputFieldProps, 'value' | 'onChange'>> = ({
  label,
  input,
  meta,
  width,
  height,
  ...props
}) => {
  const { name, value, onChange: onChangeInput } = input;

  const onChange = React.useCallback(
    (value: FileInputValue | null) => {
      onChangeInput(value);
    },
    [onChangeInput],
  );

  return (
    <ImageInput
      {...props}
      value={value || null}
      onChange={onChange}
      label={label}
      name={name}
      height={height}
      width={width}
    />
  );
};
