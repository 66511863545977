import React from 'react';
import { FormSpy, useForm, Field } from 'react-final-form';
import { Grid, Typography } from '@mui/material';

import { SelectField } from '@vizsla/components';

import { DiscountAllocationTypes } from 'src/types/shared';
import { ALLOCATION_TYPE_OPTIONS } from 'src/constants/discount';
import { useExperiences } from 'src/hooks/experiences';
import { useCampaignId } from 'src/hooks/campaign';

export const CampaignDiscountFormAllocationSection: React.FC = () => {
  const campaignId = useCampaignId();
  const { data: experiences } = useExperiences(campaignId);
  const { change, getState } = useForm();

  const EXPERIENCES_OPTIONS = experiences.map(experience => ({
    label: experience.name || undefined,
    value: experience.id || undefined,
  }));

  return (
    <FormSpy subscription={{ submitting: true, values: true }}>
      {({ submitting }) => {
        const { values } = getState();
        const { allocationType } = values;
        const visibleExperienceType = allocationType === DiscountAllocationTypes.byExperience;

        if (!visibleExperienceType) {
          change('experienceType', null);
        }
        return (
          <Grid item>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6">Allocation</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      name="allocationType"
                      label="Allocation Type"
                      options={ALLOCATION_TYPE_OPTIONS}
                      component={SelectField}
                      disabled={submitting}
                      required
                      fullWidth
                    />
                  </Grid>
                  {visibleExperienceType && (
                    <Grid item xs={6}>
                      <Field
                        name="experienceType"
                        label="Experience"
                        options={EXPERIENCES_OPTIONS}
                        component={SelectField}
                        disabled={submitting}
                        required
                        fullWidth
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </FormSpy>
  );
};
