import { Typography, CircularProgress } from '@mui/material';
import styled from '@emotion/styled';

import { theme } from '@vizsla/theme';

const HEIGHT = 166;
const WIDHT = 459;

/// Container

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

/// Content

export const Content = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

/// Image

interface ImageProps {
  size: number;
  rounded?: boolean;
}

export const ImageEmpty = styled.img<ImageProps>`
  width: ${WIDHT}px;
  min-width: ${p => p.size}px;
  height: ${HEIGHT}px;
  object-fit: cover;
`;

ImageEmpty.defaultProps = {
  rounded: false,
};

/// Buttons

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  gap: ${theme.spacing(1.5)};
  justify-content: center;
  margin-top: ${theme.spacing(2)};
`;

/// Button

interface ButtonProps {
  disabled?: boolean;
}

export const Button = styled(Typography)<ButtonProps>`
  margin: 0;
  cursor: default;
  user-select: none;
  font-size: 14px;
  opacity: ${p => (p.disabled ? 0.4 : 1)};
`;

Button.defaultProps = {
  color: 'primary',
};

/// Loader

export const Loader = styled(CircularProgress)`
  position: absolute;
`;

export const ImageLogo = styled.img<ImageProps>`
  width: ${WIDHT}px;
  min-width: ${p => p.size}px;
  height: ${HEIGHT}px;
  object-fit: contain;
`;

ImageLogo.defaultProps = {
  rounded: false,
};
