import React from 'react';
import { Grid } from '@mui/material';

import { Field, TextField } from '@vizsla/components';

import { StoreAssetFormFields } from 'src/types/storeAssets';

const FormCommonInfoSection: React.FC = () => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Field
          fullWidth
          name={StoreAssetFormFields.NAME}
          label="Name"
          component={TextField}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          fullWidth
          name={StoreAssetFormFields.DESCRIPTION}
          label="Description"
          component={TextField}
        />
      </Grid>
    </React.Fragment>
  );
};

export { FormCommonInfoSection };
