import { DiscountAllocationTypes, DiscountCategoryTypes } from 'src/types/shared';

export const DISCOUNT_CATEGORY_OPTIONS = [
  {
    label: 'Partner',
    value: DiscountCategoryTypes.partner,
  },
  {
    label: 'Team',
    value: DiscountCategoryTypes.team,
  },
  {
    label: 'VIP',
    value: DiscountCategoryTypes.vip,
  },
  {
    label: 'Other',
    value: DiscountCategoryTypes.other,
  },
];

export const ALLOCATION_TYPE_OPTIONS = [
  {
    label: 'By Experience',
    value: DiscountAllocationTypes.byExperience,
  },
  {
    label: 'All Experiences',
    value: DiscountAllocationTypes.allExperiences,
  },
];
