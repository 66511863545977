import { concatDateWithTime } from '@vizsla/utils';
import { TicketingOptionCreateInput } from '@vizsla/graphql';

import { getNewPricingSettingsData } from 'src/utils/pricingSettings';

export const composeTicketingOptionCreateInput = (
  experienceId: string,
  formData: Record<string, any>,
): TicketingOptionCreateInput => {
  return {
    ticketName: formData.ticketName,
    tableNumber: formData.tableNumber,
    description: formData.description,

    ageRequirement: formData.ageRequirement,
    ageRequirementAsOfDate: formData.ageRequirementAsOfDate,

    attendeesPerTicket: formData.attendeesPerTicket,
    maximumPerOrder: formData.maximumPerOrder,

    minimumGroupTickets: formData.minimumGroupTickets,
    maximumGroupTickets: formData.maximumGroupTickets,

    maximumAttendees: formData.maximumAttendees,

    ticketingAvailabilityStartDateTime: concatDateWithTime(
      formData.ticketingAvailabilityStartDate,
      formData.ticketingAvailabilityStartTime,
    ),
    ticketingAvailabilityEndDateTime: concatDateWithTime(
      formData.ticketingAvailabilityEndDate,
      formData.ticketingAvailabilityEndTime,
    ),

    experience: {
      connect: {
        id: experienceId,
      },
    },

    pricingSettings: getNewPricingSettingsData(formData.pricingSettings),
  };
};
