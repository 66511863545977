import type { RouteProps } from '@vizsla/types';

import * as appRoutes from 'src/constants/routes';

import {
  CampaignDashboard,
  CampaignPeople,
  CampaignExperience,
  CampaignBasics,
  CampaignAdministration,
  CampaignFundraising,
  CampaignPages,
  CampaignDiscounts,
} from './components';

export const campaignPageRoutes: RouteProps[] = [
  {
    index: true,
    element: null,
  },
  {
    path: appRoutes.campaignDashboard,
    element: CampaignDashboard,
  },
  {
    path: appRoutes.campaignAdministration,
    element: CampaignAdministration,
  },
  {
    path: appRoutes.campaignPeople,
    element: CampaignPeople,
  },
  {
    path: appRoutes.campaignExperience,
    element: CampaignExperience,
  },
  {
    path: appRoutes.campaignBasics,
    element: CampaignBasics,
  },
  {
    path: appRoutes.campaignFundraising,
    element: CampaignFundraising,
  },
  {
    path: appRoutes.campaignPages,
    element: CampaignPages,
  },
  {
    path: appRoutes.campaignDiscounts,
    element: CampaignDiscounts,
  },
];
