import React from 'react';

import { StoreAssetsDashboardTable } from 'src/components/storeAssets';
import { StoreAssetCreateModal } from 'src/modals';

const StoreAssetsDashboardContent: React.FC = () => {
  return (
    <React.Fragment>
      <StoreAssetsDashboardTable />

      <StoreAssetCreateModal />
    </React.Fragment>
  );
};

export { StoreAssetsDashboardContent };
