import React from 'react';
import { Grid, LinearProgress } from '@mui/material';
import { Form, FormRenderProps, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';

import { useModal, useNotification, FormStep, useFormStepper } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';

import { MODALS } from 'src/constants/modals';
import {
  RegistrationOptionCreateFormStepFourSchema,
  RegistrationOptionCreateFormStepOneSchema,
  RegistrationOptionCreateFormStepThreeSchema,
  RegistrationOptionCreateFormStepTwoSchema,
} from 'src/constants/validationSchemas/registrationOption';
import {
  useRegistrationOptionPricingTiers,
  useRegistrationOptions,
} from 'src/hooks/registrationOption';
import { useExperienceById } from 'src/hooks/experiences';
import { t } from 'src/utils/template';
import { PricingType } from 'src/types/pricingSettings';
import { INITIAL_PRICING_TIER } from 'src/constants/pricingSettings';
import { getNewPricingTiersData } from 'src/utils/pricingSettings';
import { FormStepperController } from 'src/components/forms';

import {
  RegistrationOptionCreateFormStepOne,
  RegistrationOptionCreateFormStepTwo,
  RegistrationOptionCreateFormStepThree,
  RegistrationOptionCreateFormStepFour,
} from './components';
import { prepareValues } from './utils';

const FORM_STEPS: Array<FormStep> = [
  {
    render: () => <RegistrationOptionCreateFormStepOne />,
    validate: (formValues, step, validationOptions) =>
      validateWithSchema(RegistrationOptionCreateFormStepOneSchema, formValues, validationOptions),
  },
  {
    render: () => <RegistrationOptionCreateFormStepTwo />,
    validate: (formValues, step, validationOptions) =>
      validateWithSchema(RegistrationOptionCreateFormStepTwoSchema, formValues, validationOptions),
  },
  {
    render: () => <RegistrationOptionCreateFormStepThree />,
    validate: (formValues, step, validationOptions) =>
      validateWithSchema(
        RegistrationOptionCreateFormStepThreeSchema,
        formValues,
        validationOptions,
      ),
  },
  {
    render: () => <RegistrationOptionCreateFormStepFour />,
    validate: (formValues, step, validationOptions) =>
      validateWithSchema(RegistrationOptionCreateFormStepFourSchema, formValues, validationOptions),
  },
];

interface RegistrationOptionCreateFormProps {
  experienceId: string;
}

const INITIAL_VALUES = {
  ageRequirmentEnabled: false,
  registrationLimitsEnabled: false,
  groupRegistrationEnabled: false,
  attendeesCapEnabled: false,
  fundraisingMinimumEnabled: false,
  checkpointEnabled: false,
  registrationAvailabilityEnabled: false,
  pricingSettings: {
    pricingEnabled: false,
    allowCostToBeFundraisingCredit: false,
    pricingType: PricingType.Fixed,
    pricingTiers: [INITIAL_PRICING_TIER],
  },
};

export const RegistrationOptionCreateForm: React.FC<RegistrationOptionCreateFormProps> = ({
  experienceId,
}) => {
  const notification = useNotification();
  const { prev, next, render, isFirst, isLast, validate } = useFormStepper(FORM_STEPS);

  const { experience, loading: experienceLoading } = useExperienceById(experienceId);

  const { closeModal } = useModal(MODALS.REGISTRATION_OPTION_CREATE_MODAL);

  const { createRegistrationOption, creating, loading } = useRegistrationOptions(experienceId);

  const { createNewPricingTiers, creatingPricingTiers } = useRegistrationOptionPricingTiers();

  const onSubmit = async (formValues: Record<string, any>) => {
    try {
      formValues.ageRequirementAsOfDate = moment(experience?.startDate || null).subtract(
        formValues?.ageRequirement,
        'years',
      );
      const { pricingSettings } = await createRegistrationOption(prepareValues(formValues));

      const newPricingTiersData = getNewPricingTiersData(formValues, pricingSettings?.id);

      if (newPricingTiersData.length > 0) {
        await createNewPricingTiers(newPricingTiersData);
      }

      closeModal();
      notification.success(t('registration_option_create_success'));
    } catch (error) {
      notification.error(t('registration_option_create_error'));
    }
  };

  if (loading || experienceLoading) {
    return <LinearProgress />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ submitting: true }}
      initialValues={INITIAL_VALUES}
      validate={formValues =>
        validate(formValues, {
          context: { experience },
        })
      }
      mutators={{
        ...arrayMutators,
      }}
    >
      {({ handleSubmit }: FormRenderProps) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {render()}
              </Grid>

              <Grid item xs={12}>
                <FormSpy subscription={{ valid: true, submitting: true }}>
                  {({ valid, submitting }) => {
                    return (
                      <FormStepperController
                        onCancel={closeModal}
                        onBack={prev}
                        isFirst={isFirst}
                        onContinue={next}
                        valid={valid}
                        isLast={isLast}
                        submitting={submitting || creating || creatingPricingTiers}
                        onSubmit={handleSubmit}
                      />
                    );
                  }}
                </FormSpy>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
};
