import React from 'react';
import { Avatar, Box, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Attendee,
  TeamFundraising,
  useAttendeesListQuery,
  useTeamRTsListQuery,
} from '@vizsla/graphql';
import { Field } from '@vizsla/components';

import { EarlyBirdRegistrationInvitationType } from 'src/types/earlyBirdRegistration';
import { getUserFullName } from 'src/utils/user';
import { useCampaignId } from 'src/hooks/campaign';
import { AutocompleteField } from 'src/components/shared';

const useStyles = makeStyles({
  chip: {
    cursor: 'pointer',
    fontWeight: 'bold',
    background: 'transparent',
  },
});

export type ExperienceAttendeeOrTeamSelectProps = {
  type: 'Attendee' | 'Team' | string;
  name: string;
};

export const ExperienceAttendeeOrTeamSelect: React.FC<
  ExperienceAttendeeOrTeamSelectProps
> = props => {
  const classes = useStyles();

  const campaignId = useCampaignId();

  const { data: attendeesListQuery } = useAttendeesListQuery({
    variables: {
      filter: {
        experience: {
          campaign: {
            id: {
              equals: campaignId,
            },
          },
        },
      },
    },
  });

  const { data: teamRTsListQuery } = useTeamRTsListQuery({
    variables: {
      filter: {
        campaign: {
          id: {
            equals: campaignId,
          },
        },
      },
    },
  });

  const { type, name } = props;

  const attendees = (attendeesListQuery?.attendees?.items as Array<Attendee>) || [];

  const teams = (teamRTsListQuery?.teamFundraisingsList?.items as Array<TeamFundraising>) || [];

  const options = type === EarlyBirdRegistrationInvitationType.attendee ? attendees : teams;

  const getOptionLabel = (option: Attendee | TeamFundraising | null) => {
    if (type === EarlyBirdRegistrationInvitationType.attendee) {
      const attendee = option as Attendee;
      return getUserFullName(attendee.user);
    }
    const team = option as TeamFundraising;
    return team.name || '';
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Attendee | TeamFundraising,
  ) => {
    if (type === EarlyBirdRegistrationInvitationType.attendee) {
      const attendee = option as Attendee;
      const avatar = attendee.user?.avatar?.downloadUrl || '';
      const label = getUserFullName(attendee.user);
      return (
        <Box component="li" {...props}>
          <Chip avatar={<Avatar src={avatar} />} label={label} className={classes.chip} />
        </Box>
      );
    }
    const team = option as TeamFundraising;
    const avatar = team.avatar?.downloadUrl || '';
    const label = team.name;

    return (
      <Box component="li" {...props}>
        <Chip avatar={<Avatar src={avatar} />} label={label} className={classes.chip} />
      </Box>
    );
  };

  return (
    <Field
      name={name}
      component={AutocompleteField}
      options={options}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
    />
  );
};
