import React from 'react';
import { Box, styled, Typography } from '@mui/material';

import { useExperienceImages, useModal, useNotification } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import { ExperienceImagesModalType } from 'src/types/experience';
import { useExperienceId } from 'src/hooks/experiences';
import { t } from 'src/utils/template';

import { ExperienceHeaderLogoCreateEditForm } from './components';
import { getImageDataFromValue } from './utils';

const StyledModalContentBox = styled(Box)(({ theme }) => ({
  margin: 0,
  '& > *': {
    paddingBottom: theme.spacing(1),
  },
}));

export const ExperienceHeaderLogoCreateEditModal: React.FC = () => {
  const notification = useNotification();
  const experienceId = useExperienceId();
  const { createExperienceLogoImage, updateExperienceLogoImage } =
    useExperienceImages(experienceId);

  const { isOpen, closeModal, args } = useModal(MODALS.EXPERIENCE_HEADER_LOGO_UPDATE_MODAL);

  const experienceLogoImage = args?.experienceLogoImage;
  const modalType = args?.modalType;
  const isCreateModal = modalType === ExperienceImagesModalType.Create;

  const onCloseModal = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  const onSubmitCreateForm = React.useCallback(
    async (formValues: Record<string, any>) => {
      const preparedImage = getImageDataFromValue(formValues?.experienceLogoImage);
      try {
        await createExperienceLogoImage(preparedImage);
        notification.success(t('experience_logo_image_add_success'));
        closeModal();
      } catch (err) {
        notification.error(t('experience_logo_image_add_error'));
      }
    },
    [closeModal, createExperienceLogoImage, notification],
  );

  const onSubmitEditForm = React.useCallback(
    async (formValues: Record<string, any>) => {
      const preparedImage = getImageDataFromValue(formValues?.experienceLogoImage);
      try {
        await updateExperienceLogoImage(preparedImage);
        notification.success(t('experience_logo_image_update_success'));
        closeModal();
      } catch (err) {
        notification.error(t('experience_logo_image_update_error'));
      }
    },
    [closeModal, notification, updateExperienceLogoImage],
  );

  const onSubmit = isCreateModal ? onSubmitCreateForm : onSubmitEditForm;

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} fullWidth>
      <Modal.Title>
        <Typography variant="h3" align="center" paddingBottom={2}>
          {isCreateModal ? 'Add Image' : 'Edit Image'}
        </Typography>
      </Modal.Title>

      <StyledModalContentBox>
        <Modal.Content>
          <ExperienceHeaderLogoCreateEditForm
            closeModal={onCloseModal}
            experienceLogoImage={experienceLogoImage}
            onSubmit={onSubmit}
          />
        </Modal.Content>
      </StyledModalContentBox>
    </Modal>
  );
};
