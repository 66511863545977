export function getAttendeeSearchFilter(searchText: string, campaignId: string) {
  const splitByWordsFilter = searchText.split(' ').map((substr: string) =>
    ['firstName', 'lastName', 'email'].map((fieldName: string) => ({
      [fieldName]: { contains: substr },
    })),
  );

  const resultFilter = {
    user: {
      OR: [
        { firstName: { contains: searchText } },
        { lastName: { contains: searchText } },
        { email: { contains: searchText } },
        ...splitByWordsFilter.flat(1),
      ],
    },
    experience: {
      campaign: {
        id: {
          equals: campaignId,
        },
      },
    },
  };

  return resultFilter;
}

export function getAttendeeSearchFilterWithoutEmail(searchText: string, experienceId: string) {
  const splitByWordsFilter = searchText.split(' ').map((substr: string) =>
    ['firstName', 'lastName'].map((fieldName: string) => ({
      [fieldName]: { contains: substr },
    })),
  );

  const resultFilter = {
    filter: {
      user: {
        OR: [
          { firstName: { contains: searchText } },
          { lastName: { contains: searchText } },
          ...splitByWordsFilter.flat(1),
        ],
      },
      experience: {
        id: {
          equals: experienceId,
        },
      },
    },
  };

  return resultFilter;
}
