import React from 'react';
import { Grid, SelectChangeEvent } from '@mui/material';
import { Add, ReceiptOutlined } from '@mui/icons-material';
import _ from 'lodash';

import { TableData } from '@vizsla/components';
import type { TableColumn, TableRowAction } from '@vizsla/components';
import { useModal, useNotification, useApolloCacheQueryManager } from '@vizsla/hooks';
import { formatMoney, formatNumber } from '@vizsla/utils';
import {
  Experience,
  RegistrationOption,
  RegistrationPricingSetting,
  ExperienceQuery,
  ExperienceQueryVariables,
  useExperienceTransactionFeesQuery,
  useExperienceUpdateMutation,
} from '@vizsla/graphql';

import { EXPERIENCE_TRANSACTION_FEES_QUERY } from 'src/graphql';
import { EllipsisTypography, PaperBlock, Select, TooltipTextWrapper } from 'src/components/shared';
import { useExperienceId } from 'src/hooks/experiences';
import { MODALS } from 'src/constants/modals';
import { useRegistrationOptions } from 'src/hooks/registrationOption';
import { TransactionFeesType } from 'src/types/experience';
import { REGISTRATION_OPTION_SELECT_OPTIONS } from 'src/constants/registrationOption';
import { RegistrationOptionCreateModal, RegistrationOptionEditModal } from 'src/modals';
import { t } from 'src/utils/template';
import { PricngTiersList } from 'src/components/experiencePricing';

export const RegistrationOptionsSettings: React.FC = () => {
  const experienceId = useExperienceId();
  const notification = useNotification();

  const { registrationOptions, deleteRegistrationOption, loading, deleting } =
    useRegistrationOptions(experienceId);

  const [updateExperience, { loading: updating }] = useExperienceUpdateMutation();

  const { data: experienceTransactionFees, loading: loadingTransactionFees } =
    useExperienceTransactionFeesQuery({
      variables: {
        id: experienceId,
      },
    });

  const { updateQuery: updateExperienceTransactionFees } = useApolloCacheQueryManager<
    ExperienceQuery,
    ExperienceQueryVariables
  >({
    query: EXPERIENCE_TRANSACTION_FEES_QUERY,
    variables: {
      id: experienceId,
    },
    typeName: 'Experience',
  });

  const { openModal } = useModal();

  const openRegistrationOptionCreateDialog = () => {
    openModal(MODALS.REGISTRATION_OPTION_CREATE_MODAL, {
      experienceId,
    });
  };

  const openRegistrationOptionEditDialog = (registrationOption: RegistrationOption) => {
    openModal(MODALS.REGISTRATION_OPTION_EDIT_MODAL, {
      registrationOptionId: registrationOption.id,
    });
  };

  const handleChangeTransactionFees = async (event: SelectChangeEvent<TransactionFeesType>) => {
    const { value } = event.target;

    try {
      await updateExperience({
        variables: {
          data: { transactionFees: value },
          filter: {
            id: experienceId,
          },
        },
      });

      updateExperienceTransactionFees((query: any) => {
        query!.experience!.transactionFees = value;
      });

      notification.success(t('experience_update_success'));
    } catch (error) {
      notification.error(t('experience_update_error'));
    }
  };

  const renderName = (name: string) => {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <ReceiptOutlined />
        </Grid>
        <Grid item>
          <EllipsisTypography variant="h6" maxWidth={300}>
            {name}
          </EllipsisTypography>
        </Grid>
      </Grid>
    );
  };

  const COLUMNS: TableColumn<RegistrationOption>[] = [
    {
      title: 'Name',
      key: 'name',
      dataPath: 'name',
      render: renderName,
    },
    {
      title: 'Quantity',
      key: 'attendeesCount',
      dataPath: 'attendees.count',
      render: (count: number) => {
        return <EllipsisTypography>{formatNumber(count)}</EllipsisTypography>;
      },
    },
    {
      title: 'Price',
      key: 'price',
      dataPath: 'pricingSettings',
      render: (pricingSettings?: RegistrationPricingSetting) => (
        <PricngTiersList pricingSettings={pricingSettings} />
      ),
    },
    {
      title: 'Minimum Fundraising',
      key: 'fundraisingMinimum',
      dataPath: 'fundraisingMinimum',
      render: (fundraisingMinimum?: number) => (
        <EllipsisTypography>{formatMoney(fundraisingMinimum ?? 0)}</EllipsisTypography>
      ),
    },
  ];

  const ROW_ACTIONS: TableRowAction<RegistrationOption>[] = [
    {
      actionName: 'Edit',
      onAction: openRegistrationOptionEditDialog,
    },
    {
      actionName: 'Delete',
      onAction: (registrationOption: RegistrationOption) => {
        openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
          deleteFunction: () => deleteRegistrationOption(registrationOption?.id as string),
          objectName: registrationOption?.name,
          objectType: 'registration option',
        });
      },
    },
  ];

  const BOTTOM_ACTIONS = [
    {
      icon: <Add />,
      actionName: 'Add Registration',
      onAction: () => openRegistrationOptionCreateDialog(),
    },
  ];

  const transactionFees =
    experienceTransactionFees?.experience?.transactionFees || TransactionFeesType.organization;

  return (
    <React.Fragment>
      <PaperBlock
        collapsing
        defaultOpened
        title="Registration Options"
        loading={loading || loadingTransactionFees}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <TooltipTextWrapper
              width={450}
              type="attention"
              text="Transaction fees are charged for each order. Your organization can pay these fees or your supporters can cover the cost or you can split."
            >
              <Select
                fullWidth
                label="Transaction Fees"
                disabled={updating}
                options={REGISTRATION_OPTION_SELECT_OPTIONS}
                onChange={handleChangeTransactionFees as any}
                value={transactionFees}
              />
            </TooltipTextWrapper>
          </Grid>
          <Grid item>
            <TableData
              hideTitle={_.isEmpty(registrationOptions)}
              loading={loading || deleting}
              columns={COLUMNS}
              dataSource={registrationOptions}
              bottomActions={BOTTOM_ACTIONS}
              rowActions={ROW_ACTIONS}
            />
          </Grid>
        </Grid>
      </PaperBlock>
      <RegistrationOptionCreateModal />
      <RegistrationOptionEditModal />
    </React.Fragment>
  );
};
