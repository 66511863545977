import React from 'react';
import { Grid, Typography, styled } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';

import { useModal, usePagination, usePaginationQueryVariables } from '@vizsla/hooks';
import { ALL_STORE_SIZES_SELECT_OPTIONS, ALL_STORE_STYLES_SELECT_OPTIONS } from '@vizsla/constants';
import { Button } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import { useStoreHistory, useStoreAssetId, useStoreEventFilters } from 'src/hooks/storeAssets';
import { Select } from 'src/components/shared';

import { InventoryTable } from './InventoryTable';

const StyledActionButton = styled(Button)(({ theme }) => ({
  margin: 0,
  '&:not(:first-child)': {
    marginLeft: theme.spacing(1),
  },
}));

const StyledSelect = styled(Select)(() => ({
  '& fieldset': {
    border: 0,
  },
}));

const StoreAssetManagementInventoryTab: React.FC = () => {
  const storeAssetId = useStoreAssetId();

  const { openModal } = useModal();

  const pagination = usePagination();
  const paginationQueryVariables = usePaginationQueryVariables();

  const { sizeFilter, styleFilter, setSizeFilter, setStyleFilter } = useStoreEventFilters();

  const { storeHistoryEventsCount } = useStoreHistory(storeAssetId, paginationQueryVariables);

  React.useEffect(() => {
    pagination.onCountChange(storeHistoryEventsCount);
  }, [pagination, storeHistoryEventsCount]);

  const onOpenStoreAssetInventoryAddModal = React.useCallback(() => {
    openModal(MODALS.STORE_ASSET_INVENTORY_ADD_MODAL);
  }, [openModal]);

  const onOpenStoreAssetInventoryAdjustModal = React.useCallback(() => {
    openModal(MODALS.STORE_ASSET_INVENTORY_ADJUST_MODAL);
  }, [openModal]);

  const onSizeFilterChange = (value: string) => setSizeFilter(value);
  const onStyleFilterChange = (value: string) => setStyleFilter(value);

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Inventory</Typography>
      </Grid>

      <Grid item container justifyContent="space-between" wrap="nowrap">
        <Grid item whiteSpace="nowrap">
          <StyledActionButton onClick={onOpenStoreAssetInventoryAddModal}>
            <AddIcon /> Add
          </StyledActionButton>

          <StyledActionButton onClick={onOpenStoreAssetInventoryAdjustModal}>
            <RemoveIcon /> Adjust
          </StyledActionButton>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <InventoryTable />
      </Grid>
    </Grid>
  );
};

export { StoreAssetManagementInventoryTab };
