import React from 'react';
import { Box, styled } from '@mui/material';
import { isNil } from 'lodash';

import { useModal } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import type { StoreAssetUpdateOrDeleteImageModalArgs } from 'src/types/storeAssets';

import { StoreAssetUpdateImageForm } from './components';

const StyledModalContentBox = styled(Box)(({ theme }) => ({
  margin: 0,
  '& > *': {
    paddingBottom: theme.spacing(1),
  },
}));

const StoreAssetUpdateImageModal: React.FC = () => {
  const { isOpen, closeModal, args } = useModal(MODALS.STORE_ASSET_UPDATE_IMAGE_MODAL);

  const modalArgs: StoreAssetUpdateOrDeleteImageModalArgs | undefined = args;

  const onCloseModal = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  const storeAsset = modalArgs?.storeAsset;

  if (isNil(storeAsset)) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} fullWidth>
      <Modal.Title>Edit Image</Modal.Title>

      <StyledModalContentBox>
        <Modal.Content>
          <StoreAssetUpdateImageForm closeModal={onCloseModal} storeAsset={storeAsset} />
        </Modal.Content>
      </StyledModalContentBox>
    </Modal>
  );
};

export { StoreAssetUpdateImageModal };
