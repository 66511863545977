export const EXPERIENCE_REGISTRATION_CATEGORY_OPTIONS = [
  'Run/Walk',
  'Cycling',
  'Triathlon',
  'Obstacle Course Racing',
  "I'm Getting Creative",
].map((category: string) => ({ label: category, value: category }));

export const EXPERIENCE_TICKETING_CATEGORY_OPTIONS = [
  'Concert-Festival',
  'Dinner-Gala',
  "I'm Getting Creative",
].map((category: string) => ({ label: category, value: category }));
