import React from 'react';
import { FormControl, InputLabel, MenuItem, Select as SelectMaterial } from '@mui/material';

import { useCampaignListByOrganizationQuery } from '@vizsla/graphql';
import { useCurrentOrganization } from '@vizsla/hooks';

export const SelectCampaign: React.FC<{
  name: string;
  label: string;
  required?: boolean;
  onChange?: (e) => void;
}> = ({ name, label, required = false, onChange }) => {
  const { organizationId } = useCurrentOrganization();
  const { data: orgList, loading: orgLoading } = useCampaignListByOrganizationQuery({
    variables: { orgId: organizationId || '' },
  });

  return (
    <React.Fragment>
      <FormControl>
        <InputLabel id="campaign-select-id" required={required}>
          {label}
        </InputLabel>
        <SelectMaterial
          name={name}
          label={label}
          labelId="campaign-select-id"
          required={required}
          onChange={e => onChange?.(e.target.value)}
          error
        >
          {orgList?.campaignsList.items.map(item => (
            <MenuItem key={item?.id} value={item?.id || ''}>
              {item.name}
            </MenuItem>
          ))}
        </SelectMaterial>
      </FormControl>
    </React.Fragment>
  );
};
