import React from 'react';
import { Box, Grid, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Campaign } from '@vizsla/graphql';

import { CampaignsListTile } from './components';

interface CampaignsListTilesViewProps {
  campaigns: Campaign[];
  loading?: boolean;
}

const useStyles = makeStyles({
  container: {
    height: 'calc(100vh - 152px - 100px)',
    overflowY: 'scroll',
  },
});

const CampaignsListTilesView: React.FC<CampaignsListTilesViewProps> = ({
  campaigns,
  loading = false,
}) => {
  const classes = useStyles();

  if (loading) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Box className={classes.container}>
      <Grid container spacing={3}>
        {campaigns.map(campaign => (
          <Grid item key={campaign.id}>
            <CampaignsListTile campaign={campaign} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default React.memo(CampaignsListTilesView);
