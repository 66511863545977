import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import type { RouteProps } from '@vizsla/types';
import { useUserCurrentApp, useAppAuth, useClientRedirect } from '@vizsla/hooks';
import { ProtectedRoute, SwitchUserRoleBox, FullscreenLoader } from '@vizsla/components';

import { LayoutResolver } from 'src/layouts';
import * as appRoutes from 'src/constants/routes';
import { authRoutes } from 'src/routes/auth';
import { useAllowedRoutes } from 'src/hooks/authorization';

const renderNestedRoutes = (routesProps: RouteProps[] = []) => {
  const renderRouteContent = (routeProps: RouteProps) => {
    const { element, childRoutes, ...restProps } = routeProps;

    const Component = element as React.ComponentType;

    return (
      <Route key={routeProps.path || 'index'} element={Component && <Component />} {...restProps}>
        {renderNestedRoutes(childRoutes)}
      </Route>
    );
  };

  return routesProps.map(routeProps => renderRouteContent(routeProps));
};

export const App: React.FC = () => {
  const { isAuthenticated } = useAppAuth();
  const { startRoutePath, allowedRoutes, loading: routesLoading } = useAllowedRoutes();
  const { currentApp, loading: appLoading } = useUserCurrentApp();

  useClientRedirect();

  if (routesLoading || appLoading) {
    return <FullscreenLoader variant="light" />;
  }

  if (isAuthenticated && !currentApp) {
    return <SwitchUserRoleBox />;
  }

  const authenticationPath = appRoutes.auth;
  const isNotFoundRedirectNeeded = isAuthenticated && allowedRoutes.length !== 0;

  return (
    <Routes>
      <Route path={authenticationPath}>{renderNestedRoutes(authRoutes)}</Route>

      {allowedRoutes.map(({ element, childRoutes, ...routeProps }) => (
        <Route
          key={routeProps.path}
          {...routeProps}
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              authenticationPath={authenticationPath}
              redirectPath={startRoutePath}
              layout={LayoutResolver}
              component={element as React.ComponentType}
            />
          }
        >
          {renderNestedRoutes(childRoutes)}
        </Route>
      ))}

      {!isAuthenticated && <Route path="*" element={<Navigate to={authenticationPath} />} />}

      {isNotFoundRedirectNeeded && <Route path="*" element={<Navigate to={startRoutePath} />} />}
    </Routes>
  );
};

export default App;
