import * as yup from 'yup';

import { AddressSchema, PhoneSchemaRequired } from '@vizsla/constants';

export const OrganizationAddMemberSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Invalid Email').required('Email is required'),
  phone: PhoneSchemaRequired,
  // title: yup.string().required('Title is required'),
  role: yup.string().required('Rol is required'),
});
export const OrganizationEditMemberSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Invalid Email').required('Email is required'),
  phone: PhoneSchemaRequired,
  // title: yup.string().required('Title is required'),
  role: yup.string().required('Rol is required'),
});
