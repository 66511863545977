import styled from '@emotion/styled';
import { Link, Typography } from '@mui/material';

export const CardsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${p => p.theme.spacing(4)};
`;

export const WrapperPaperBlocks = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${p => p.theme.spacing(2)};
  gap: ${p => p.theme.spacing(3)};
  margin-bottom: ${p => p.theme.spacing(2)};
`;

export const LinkTransactions = styled(Link)`
  color: ${p => p.theme.palette.secondary.light};
  margin-top: ${p => p.theme.spacing(1)};
  cursor: pointer;
`;

export const WrapperTitle = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${p => p.theme.spacing(2)};
`;

export const Title = styled(Typography)`
  font-family: Inter;
`;

Title.defaultProps = {
  variant: 'h4',
};
