import { RouterEntitiesId as entitiesId } from '@vizsla/constants';

// Auth routes
export const auth = '/auth';
export const authLogin = '/auth/login';
export const authRegistration = '/auth/register';
export const authChangePassword = '/auth/change-password';
export const authForgotPassword = '/auth/forgot-password';
export const authForgotPasswordVerifyCode = '/auth/forgot-password/verify-code';
export const authConfirmationEmail = '/auth/confirm';
export const authCompleteRegistration = '/auth/complete';
export const myProfile = '/myProfile';

// Organization routes
export const home = '/home';
export const inbox = '/inbox';
export const campaigns = '/campaigns';
export const campaign = `/campaigns/:${entitiesId.campaign}`;
export const campaignDashboard = `/campaigns/:${entitiesId.campaign}/dashboard`;
export const campaignBasics = `/campaigns/:${entitiesId.campaign}/basics`;
export const campaignAdministration = `/campaigns/:${entitiesId.campaign}/administration`;
export const campaignExperience = `/campaigns/:${entitiesId.campaign}/experiences/:${entitiesId.experience}`;
export const campaignPages = `/campaigns/:${entitiesId.campaign}/campaign-pages`;
export const campaignPeople = `/campaigns/:${entitiesId.campaign}/people`;
export const campaignFundraising = `/campaigns/:${entitiesId.campaign}/fundraising`;
export const campaignDiscounts = `/campaigns/:${entitiesId.campaign}/discounts`;

// Partnerships
export const partnerships = '/partnerships';
export const partnershipDetails = `/partnerships/:${entitiesId.partnership}`;
// transactions
export const transactions = '/transactions';
export const transactionsDonations = `/transactions/:${entitiesId.order}/donation`;
export const transactionsRegistration = `/transactions/:${entitiesId.order}/registration`;
export const transactionsAssets = `/transactions/:${entitiesId.order}/assets`;
// Editor routes
export const editorExperienceTemplate = `/editor/:${entitiesId.campaign}/experience`;
export const editorIndividualFundraiserTemplate = `/editor/:${entitiesId.campaign}/individual-fundraiser`;
export const editorTeamFundraiserTemplate = `/editor/:${entitiesId.campaign}/team-fundraiser`;
export const editorDonationTemplate = `/editor/:${entitiesId.campaign}/donation`;
export const editorThankYouTemplate = `/editor/:${entitiesId.campaign}/thank-you`;

// Experiences Routes
export const experiences = '/experiences';
export const experiencesCreate = '/experiences/create';

// Organization Settings Routes
export const organizationSettings = '/settings';
export const organizationSettingsAccountDetails = '/settings/account-details';
export const organizationSettingsTeamMembers = '/settings/team-members';
export const organizationSettingsBilling = '/settings/billing';
export const organizationSettingsIntegrations = '/settings/integrations';
export const organizationSettingsCheckout = '/settings/checkout';
export const organizationSettingsNotifications = '/settings/notifications';

// CRM Routes
export const crm = '/people';

// Initi checkIn or Register
export const checkOrRegister = '/select-process';

// Store Assets Routes
export const storeAssets = '/assets';
export const storeAssetsAssetPage = `/assets/:${entitiesId.storeAsset}`;
export const storeAssetsAssetDetails = `/assets/:${entitiesId.storeAsset}/details`;
export const storeAssetsAssetInventory = `/assets/:${entitiesId.storeAsset}/inventory`;

// Admin Routes
export const adminRoot = '/admin';
export const adminOrganizations = '/admin/organizations';
export const adminOrganization = '/admin/organization';
export const adminOrganizationProfile = `/admin/organizations/:${entitiesId.organization}`;
export const adminOrganizationProfileInfo = `/admin/organizations/:${entitiesId.organization}/info`;
export const adminOrganizationSubscriptionPlans = `/admin/organizations/:${entitiesId.organization}/subscriptions`;
export const adminOrganizationPaymentMethods = `/admin/organizations/:${entitiesId.organization}/payments`;
export const adminOrganizationUsers = `/admin/organizations/:${entitiesId.organization}/users`;
export const adminOrganizationPaymentHistory = `/admin/organizations/:${entitiesId.organization}/payment-history`;
export const adminSettings = '/admin/settings';
export const adminSettingsPayments = '/admin/settings/payments';
