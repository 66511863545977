import React from 'react';
import { Box, styled } from '@mui/material';

import { useModal } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';

import { StoreAssetCreateForm } from './components';

const StyledModalContentBox = styled(Box)(({ theme }) => ({
  margin: 0,
  '& > *': {
    paddingBottom: theme.spacing(1),
  },
}));

const StoreAssetCreateModal: React.FC = () => {
  const { isOpen, closeModal } = useModal(MODALS.STORE_ASSET_CREATE_MODAL);

  const onCloseModal = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} fullWidth>
      <Modal.Title>Add Asset</Modal.Title>

      <StyledModalContentBox>
        <Modal.Content>
          <StoreAssetCreateForm closeModal={onCloseModal} />
        </Modal.Content>
      </StyledModalContentBox>
    </Modal>
  );
};

export { StoreAssetCreateModal };
