import React from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';

import {
  CampaignStaff,
  CampaignStaffCreateManyMutation,
  CampaignStaffCreateManyMutationVariables,
  CampaignStaffFilter,
  CampaignStaffListQuery,
  CampaignStaffUpdateByFilterInput,
} from '@vizsla/graphql';

import {
  CAMPAIGN_STAFF_CREATE_MUTATION,
  CAMPAIGN_STAFF_DELETE_MUTATION,
  CAMPAIGN_STAFF_LIST_QUERY,
  CAMPAIGN_STAFF_UPDATE_BY_FILTER_MUTATION,
} from 'src/graphql';

enum CampaignStaffTypeName {
  campaignStaffList = 'CampaignStaffListResponse',
  campaignStaff = 'CampaignStaff',
}

export const useCampaignStaff = (campaignId: string, customFilter?: CampaignStaffFilter) => {
  const apolloClient = useApolloClient();

  const filterByCampaignId = {
    campaign: {
      id: {
        equals: campaignId,
      },
    },
  };

  const filter = customFilter ?? filterByCampaignId;

  const { data, loading } = useQuery<CampaignStaffListQuery>(CAMPAIGN_STAFF_LIST_QUERY, {
    variables: {
      filter,
    },
    skip: !campaignId,
  });

  const campaignStaff = (data?.campaignStaffsList?.items as CampaignStaff[]) || [];

  const getCampaignStaffsFromCache = React.useCallback((): Array<CampaignStaff> => {
    try {
      const data = apolloClient.readQuery({
        query: CAMPAIGN_STAFF_LIST_QUERY,
        variables: {
          filter,
        },
      });

      return (data?.campaignStaffsList.items as CampaignStaff[]) || [];
    } catch (error) {
      console.error({ error });
      throw error;
    }
  }, [apolloClient, filter]);

  const addCampaignStaffsToCache = React.useCallback(
    (newCampaignStaffsList: CampaignStaff[]): void => {
      const currentStaffs = getCampaignStaffsFromCache();
      const updatedStaffs = [...currentStaffs, ...newCampaignStaffsList];

      try {
        apolloClient.writeQuery({
          query: CAMPAIGN_STAFF_LIST_QUERY,
          variables: {
            filter,
          },
          data: {
            campaignStaffsList: {
              items: updatedStaffs,
            },
            __typename: CampaignStaffTypeName.campaignStaffList,
          },
        });
      } catch (error) {
        console.error({ error });
        throw error;
      }
    },
    [apolloClient, filter, getCampaignStaffsFromCache],
  );

  const removeCampaignStaffsFromCacheById = React.useCallback(
    (campaignStaffIds: string[]): void => {
      const currentStaffs = getCampaignStaffsFromCache();

      const updatedStaffs = currentStaffs.filter(
        (campaignStaff: CampaignStaff) => !campaignStaffIds.includes(campaignStaff.id as string),
      );

      try {
        apolloClient.writeQuery({
          query: CAMPAIGN_STAFF_LIST_QUERY,
          variables: {
            filter,
          },
          data: {
            campaignStaffsList: {
              items: updatedStaffs,
            },
            __typename: CampaignStaffTypeName.campaignStaffList,
          },
        });
      } catch (error) {
        console.error({ error });
        throw error;
      }
    },
    [apolloClient, filter, getCampaignStaffsFromCache],
  );

  const [createCampaignStaffMutation, { loading: creating }] = useMutation<
    CampaignStaffCreateManyMutation,
    CampaignStaffCreateManyMutationVariables
  >(CAMPAIGN_STAFF_CREATE_MUTATION);

  const createCampaignStaff = async (userIds: string[]) => {
    if (!userIds.length) {
      return;
    }
    const campaignStaffToCreate = userIds.map((userId: string) => ({
      campaign: {
        connect: {
          id: campaignId,
        },
      },
      user: {
        connect: {
          id: userId,
        },
      },
    }));

    try {
      await createCampaignStaffMutation({
        variables: {
          dataToCreate: campaignStaffToCreate,
        },
        /*
          todo: remove refetch when SER-3541 will be fixed
          and use addCampaignStaffsToCache
          https://8base-dev.atlassian.net/browse/SER-3541
        */
        refetchQueries: ['CampaignStaffList'],
      });
      // addCampaignStaffsToCache(res.data?.createdStaffs?.items as CampaignStaff[]);
    } catch (error) {
      console.error({ error });
      throw error;
    }
  };

  const [updateStaffByFilterMutation, { loading: updating }] = useMutation(
    CAMPAIGN_STAFF_UPDATE_BY_FILTER_MUTATION,
  );

  interface UpdateCampaignStaffByIdsProps {
    data: CampaignStaffUpdateByFilterInput;
    idsToUpdate: string[];
    shouldAddResultToCache?: boolean;
    shouldRemoveIdsFromCache?: boolean;
  }

  const updateCampaignStaffByIds = async ({
    data,
    idsToUpdate,
    shouldAddResultToCache = false,
    shouldRemoveIdsFromCache = false,
  }: UpdateCampaignStaffByIdsProps) => {
    const filter = {
      id: {
        in: idsToUpdate,
      },
    } as CampaignStaffFilter;
    try {
      const res = await updateStaffByFilterMutation({
        variables: {
          data,
          filter,
        },
      });

      if (shouldAddResultToCache) {
        const updatedItems = res?.data?.updatedCampaignStaffs?.items;
        addCampaignStaffsToCache(updatedItems);
        return;
      }
      if (shouldRemoveIdsFromCache) {
        removeCampaignStaffsFromCacheById(idsToUpdate);
      }
    } catch (error) {
      console.error({ error });
      throw error;
    }
  };

  const [deleteCampaignStaffMutation, { loading: deleting }] = useMutation(
    CAMPAIGN_STAFF_DELETE_MUTATION,
  );

  const deleteCampaignStaff = async (campaignStaffIds: string[]) => {
    if (!campaignStaffIds.length) {
      return;
    }
    const filter = {
      id: {
        in: campaignStaffIds,
      },
    };

    try {
      await deleteCampaignStaffMutation({
        variables: {
          filter,
        },
      });
      removeCampaignStaffsFromCacheById(campaignStaffIds);
    } catch (error) {
      console.error({ error });
      throw error;
    }
  };

  return {
    campaignStaff,
    createCampaignStaff,
    deleteCampaignStaff,
    updateCampaignStaffByIds,
    loading,
    creating,
    deleting,
    updating,
  };
};
