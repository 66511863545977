import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Skeleton } from '@mui/material';

import { IndividualFundraising, Order, TeamFundraising } from '@vizsla/graphql';
import { getUserFullName } from '@vizsla/utils';

import { ORDERS_BY_DONOR } from 'src/graphql/orders';
import { useSelectedTransactionRow } from 'src/hooks/transactions';

import {
  Container,
  Content,
  Paragraph,
  Subtitle,
  Title,
  Wrapper,
} from './TransactionsCampaignCard.style';
import { TransactionsCardsLayout } from '../../TransactionsCardLayout';

const DEFAULT_VALUE = 'N/A';

interface OrderByDonorQuery {
  order: Order;
}

export function TransactionsCampaignCard() {
  const { data: dataTransaction } = useSelectedTransactionRow();

  const { orderId } = useParams();

  const { data: dataDonor, loading } = useQuery<OrderByDonorQuery>(ORDERS_BY_DONOR, {
    variables: {
      orderId,
    },
  });
  const individualFundraiser = React.useMemo(() => {
    let fundraiser: IndividualFundraising | null = null;

    const fulfillments = dataDonor?.order?.donations?.items ?? [];

    for (const fulfillment of fulfillments) {
      const allocations = fulfillment?.donationAllocations?.items ?? [];
      const allocation = allocations.find(alloc => Boolean(alloc.individualFundraising));

      if (allocation) {
        fundraiser = allocation?.individualFundraising ?? null;
        break;
      }
    }

    return fundraiser;
  }, [dataDonor]);

  const teamFundraising = React.useMemo(() => {
    let fundraiser: TeamFundraising | null = null;

    const fulfillments = dataDonor?.order?.donations?.items ?? [];

    for (const fulfillment of fulfillments) {
      const allocations = fulfillment?.donationAllocations?.items ?? [];
      const allocation = allocations.find(alloc => Boolean(alloc.teamFundraising));

      if (allocation) {
        fundraiser = allocation?.teamFundraising ?? null;
        break;
      }
    }

    return fundraiser;
  }, [dataDonor]);

  const userFullName = React.useMemo(
    () => getUserFullName(individualFundraiser?.attendee?.user),
    [individualFundraiser],
  );

  if (!dataDonor || loading) {
    return (
      <TransactionsCardsLayout>
        <Container>
          <Content>
            <Skeleton variant="text" width={288} height={35} />
          </Content>
          <Skeleton variant="rectangular" width={145} height={110} />
        </Container>
      </TransactionsCardsLayout>
    );
  }

  return (
    <TransactionsCardsLayout active>
      <Container>
        <Wrapper>
          <Title variant="h4">Campaign</Title>
        </Wrapper>

        <Content>
          <Wrapper>
            <Paragraph variant="subtitle2">Fundraiser:</Paragraph>
            <Subtitle> {userFullName ?? DEFAULT_VALUE}</Subtitle>
          </Wrapper>
          <Wrapper>
            <Paragraph variant="subtitle2">Team:</Paragraph>
            <Subtitle> {teamFundraising?.name ?? DEFAULT_VALUE}</Subtitle>
          </Wrapper>
          <Wrapper>
            <Paragraph variant="subtitle2">Campaign:</Paragraph>{' '}
            <Subtitle> {dataTransaction?.CampaignName ?? DEFAULT_VALUE}</Subtitle>
          </Wrapper>
          <Wrapper>
            <Paragraph variant="subtitle2">Experience:</Paragraph>{' '}
            <Subtitle> {dataTransaction?.ExperienceName ?? DEFAULT_VALUE}</Subtitle>
          </Wrapper>
        </Content>
      </Container>
    </TransactionsCardsLayout>
  );
}
