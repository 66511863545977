import React from 'react';
import _ from 'lodash';
import { Form } from 'react-final-form';
import { Grid, InputAdornment } from '@mui/material';

import { useModal } from '@vizsla/hooks';
import { Modal, Field, NumberField, TextField, Button } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import { BASE_NUMBER_FORMAT } from 'src/constants/formats';
import { ImageInputField } from 'src/components/forms';

// TODO add possibility to change image (delete shouldDisplayImageField arg when it would be added)_

export default function DonationOptionModal() {
  const { isOpen, closeModal, args } = useModal(MODALS.DONATION_OPTION_MODAL);

  const handleSubmit = async (formValues: any) => {
    const imageField = formValues.image;
    const image = !_.isNil(imageField)
      ? {
          image: {
            create: {
              fileId: imageField.fileId,
              fileName: imageField.fileName,
              public: imageField.public,
            },
          },
        }
      : {};

    if (args?.onSubmit) {
      await args?.onSubmit({ ...formValues, ...image }).then(() => closeModal());
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Title>{args?.titleText}</Modal.Title>
      <Modal.Content>
        <Form
          initialValues={args?.initialValues}
          onSubmit={handleSubmit}
          subscription={{ submitting: true, pristine: true }}
          render={({ handleSubmit, form, pristine, submitting }) => {
            const { submit, reset } = form;
            return (
              <form onSubmit={handleSubmit}>
                <Grid container justifyContent="center" spacing={2} direction="row">
                  {args.shouldDisplayImageField && (
                    <Grid item>
                      <Field
                        height={100}
                        width={100}
                        label="Image"
                        name="image"
                        component={ImageInputField}
                        disabled={submitting}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Field
                      label="Price"
                      name="price"
                      component={NumberField}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      numberFormat={BASE_NUMBER_FORMAT}
                      disabled={submitting}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Description"
                      name="description"
                      component={TextField}
                      disabled={submitting}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <Button disabled={pristine || submitting} onClick={reset}>
                      Clear
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button disabled={pristine || submitting} loading={submitting} onClick={submit}>
                      {args?.submitButtonText}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </Modal.Content>
    </Modal>
  );
}
