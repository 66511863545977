import React from 'react';
import _ from 'lodash';
import { Grid } from '@mui/material';
import { Add as AddIcon, EditOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { useModal } from '@vizsla/hooks';
import { TableData } from '@vizsla/components';
import type { TableBottomAction, TableColumn, TableRowAction } from '@vizsla/components';
import { CustomCampaignQuestion, Experience } from '@vizsla/graphql';

import { useCampaignId } from 'src/hooks/campaign';
import { useCampaignQuestions } from 'src/hooks/campaignQuestion';
import { CampaignQuestionCreateModal, CampaignQuestionEditModal } from 'src/modals';
import { MODALS } from 'src/constants/modals';

const useStyles = makeStyles(() => ({
  tableCells: {
    '& .MuiTableCell-root:nth-child(1)': {
      width: '35%',
      maxWidth: 300,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& .MuiTableCell-root:nth-child(2)': {
      width: '10%',
    },
    '& .MuiTableCell-root:nth-child(3)': {
      width: '40%',
      maxWidth: 300,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& .MuiTableCell-root:nth-child(4)': {
      width: '10%',
    },
    '& .MuiTableCell-root:nth-child(5)': {
      width: '5%',
    },
  },
}));

interface CampaignCustomQuestionsTableProps {
  campaignQuestions: Array<CustomCampaignQuestion>;
  handleSetIsManagingQuestions: (isManagingQuestions: boolean) => void;
}

export const CampaignCustomQuestionsTable: React.FC<CampaignCustomQuestionsTableProps> = ({
  campaignQuestions,
  handleSetIsManagingQuestions,
}) => {
  const classes = useStyles();

  const { openModal } = useModal();

  const campaignId = useCampaignId();

  const { deleteCampaignQuestion } = useCampaignQuestions(campaignId);

  const onOpenCampaignEditDialog = (campaignQuestion: CustomCampaignQuestion) => {
    openModal(MODALS.CAMPAIGN_QUESTION_EDIT_MODAL, { campaignQuestion });
  };

  const tableColumns: Array<TableColumn> = [
    {
      title: 'custom question',
      key: 'title',
      dataPath: 'title',
    },
    {
      title: 'type',
      key: 'type',
      dataPath: 'type',
    },
    {
      title: 'allocation',
      key: 'allocation',
      dataPath: 'experience.items',
      render: (experienceList: Experience[] = []) => {
        return !_.isEmpty(experienceList)
          ? experienceList.map(exp => exp?.name).join(', ')
          : 'All Experiences';
      },
    },
    {
      title: 'required',
      key: 'isRequired',
      dataPath: 'isRequired',
      align: 'center',
      render: (isRequired: boolean) => {
        return isRequired ? 'Yes' : 'No';
      },
    },
  ];

  const rowActions: Array<TableRowAction> = [
    {
      actionName: 'Edit',
      onAction: onOpenCampaignEditDialog,
    },
    {
      actionName: 'Delete',
      onAction: (campaignQuestion: CustomCampaignQuestion) => {
        openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
          deleteFunction: () => deleteCampaignQuestion(campaignQuestion?.id as string),
          objectName: campaignQuestion?.title,
          objectType: 'custom question',
        });
      },
    },
  ];

  const bottomActions: Array<TableBottomAction> = [
    {
      actionName: 'Manage Questions',
      icon: <EditOutlined />,
      onAction: () => handleSetIsManagingQuestions(true),
    },
    {
      actionName: 'Custom Question',
      icon: <AddIcon />,
      onAction: () => openModal(MODALS.CAMPAIGN_QUESTION_CREATE_MODAL),
    },
  ];

  return (
    <Grid className={classes.tableCells}>
      <TableData
        dataSource={campaignQuestions}
        columns={tableColumns}
        rowActions={rowActions}
        bottomActions={bottomActions}
      />
      <CampaignQuestionCreateModal />
      <CampaignQuestionEditModal />
    </Grid>
  );
};
