import * as yup from 'yup';

export const partnershipContactValidationSchema = yup.object({
  firstName: yup
    .string()
    .min(1, 'First Name is required')
    .max(100, 'First Name must be at most 500 characters')
    .required('First Name is required'),
  lastName: yup
    .string()
    .min(1, 'Last Name is required')
    .max(100, 'Last Name must be at most 500 characters')
    .required('Last Name is required'),
  email: yup
    .string()
    .min(1, 'Email is required')
    .max(100, 'Email must be at most 500 characters')
    .email('Email must be a valid email')
    .required('Email is required'),
  title: yup
    .string()
    .min(1, 'Title is required')
    .max(100, 'Title must be at most 500 characters')
    .required('Title is required'),
  phone: yup
    .string()
    .max(10, 'Phone must be at most 10 characters')
    .matches(/([0-9])\w+/g, 'Phone must be a number')
    .required('Phone is required'),
  notes: yup.string().max(500, 'Notes must be at most 500 characters').notRequired(),
});
