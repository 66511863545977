import React from 'react';

import { User, useVizslaOrganizationStaffQuery } from '@vizsla/graphql';
import {
  useModal,
  useOrganizationId,
  usePagination,
  usePaginationQueryVariables,
  useSearchQueryVariables,
} from '@vizsla/hooks';
import { TableColumn, TableData, UserAvatarFullNameInline } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';

const OrganizationUsers: React.FC = () => {
  const STAFF_TABLE_COLUMNS: Array<TableColumn> = [
    {
      title: 'NAME',
      key: 'name',
      render: (user?: User) => {
        if (!user) {
          return 'N/A';
        }
        return <UserAvatarFullNameInline user={user} />;
      },
    },
    {
      title: 'Email',
      key: 'email',
      render: (user?: User) => user?.email,
    },
    {
      title: 'status',
      key: 'status',
      render: (user?: User) => (user?.status === 'active' ? 'Active' : 'Inactive'),
    },
    {
      title: 'LAST ACTIVITY',
      key: 'lastActivity',
      // TODO: Replace with real value whenever is possible
      render: () => 'Just now',
    },
  ];
  const organizationId = useOrganizationId();
  const pagination = usePagination();
  const paginationQueryVariables = usePaginationQueryVariables();
  const { openModal } = useModal();

  const searchQueryVariables = useSearchQueryVariables(true);
  const { data, loading, error } = useVizslaOrganizationStaffQuery({
    variables: {
      id: organizationId,
      filter: { ...searchQueryVariables },
    },
  });
  const staff = data?.vizslaOrganization?.vizslaOrganizationStaff?.items?.map(
    stafforganization => stafforganization.user,
  );
  const staffOrganization = React.useMemo(() => {
    return {
      count: data?.vizslaOrganization?.vizslaOrganizationStaff?.count || 0,
      items: staff
        ? staff.map(user => {
            return {
              firstName: user?.firstName,
              lastName: user?.lastName,
              id: user?.id,
              email: user?.email,
              status: user?.status,
            };
          })
        : [],
    };
  }, [data, staff]);
  React.useEffect(() => {
    pagination.onCountChange(staffOrganization.count);
  }, [pagination, staffOrganization]);

  return (
    <TableData
      dataSource={staffOrganization.items}
      columns={STAFF_TABLE_COLUMNS}
      emptyMessage="There are no users in the organization"
      loading={loading}
      onRowClick={dataRow => {
        openModal(MODALS.PEOPLE_CRM_CONTACT_INFO_MODAL, { userId: dataRow.id });
      }}
    />
  );
};

export { OrganizationUsers };
