import React from 'react';
import { Grid } from '@mui/material';

import { TableData } from '@vizsla/components';

import { columns } from './columns';
import { useContactTeams } from './useContactTeams';

export const ContactTeams: React.FC = () => {
  const { data, loading, setSearch } = useContactTeams();

  return (
    <Grid item>
      <TableData
        tableProps={{ stickyHeader: true }}
        dataSource={data}
        onSearch={setSearch}
        columns={columns}
        tableContainerProps={{ style: { width: '100%' } }}
        loading={loading}
      />
    </Grid>
  );
};
