import _ from 'lodash';

import {
  CampaignTemplate,
  PUBLIC_TEMPLATES_BY_CAMPAIGN_ID_QUERY,
  PublicTemplatesByCampaignIdQuery,
  PublicTemplatesByCampaignIdQueryVariables,
  TemplateStructure,
  useCampaignTemplateCreateMutation,
  usePublicTemplatesByCampaignIdQuery,
  useTemplateTypeListQuery,
} from '@vizsla/graphql';
import { useApolloCacheQueryManager } from '@vizsla/hooks';

type UseCampaignPublicPagesHook = {
  campaignPublicTemplates: TemplateStructure[];
  connectTemplatesToCampaign: () => Promise<{ success: boolean }>;
  loading: boolean;
  updating: boolean;
  hasCampaignTemplates: boolean;
};

export const useCampaignPublicPages = (campaignId: string): UseCampaignPublicPagesHook => {
  const publicTemplatesQueryVariables = {
    variables: {
      campaignId,
    },
  };

  const { data: campaignTemplatesResponse, loading: campaignLoading } =
    usePublicTemplatesByCampaignIdQuery({ ...publicTemplatesQueryVariables, skip: !campaignId });

  const { updateQuery: updatePublicTemplatesQuery } = useApolloCacheQueryManager<
    PublicTemplatesByCampaignIdQuery,
    PublicTemplatesByCampaignIdQueryVariables
  >({
    query: PUBLIC_TEMPLATES_BY_CAMPAIGN_ID_QUERY,
    typeName: 'Campaign',
    ...publicTemplatesQueryVariables,
  });

  const { data: templateTypes, loading: templateTypesLoading } = useTemplateTypeListQuery();

  const [campaignTemplateCreateMutation, { loading: connecting }] =
    useCampaignTemplateCreateMutation();

  const campaignPublicTemplates =
    campaignTemplatesResponse?.campaign?.campaignTemplates?.templates?.items || [];

  const templateTypesList =
    templateTypes?.templateTypesList?.items?.map(template => template.type).filter(Boolean) || [];

  const campaignAlreadyHasTemplates =
    campaignPublicTemplates.length > 0 &&
    campaignPublicTemplates.length === templateTypesList.length;

  const loading = campaignLoading || templateTypesLoading;

  const connectTemplatesToCampaign = async (): Promise<{ success: boolean }> => {
    if (!campaignAlreadyHasTemplates) {
      try {
        const { data } = await campaignTemplateCreateMutation({
          variables: {
            data: {
              campaign: {
                connect: {
                  id: campaignId,
                },
              },
              templates: {
                create: templateTypesList.map(templateType => ({
                  templateType: {
                    connect: {
                      type: templateType,
                    },
                  },
                })),
              },
            },
          },
        });

        if (data?.campaignTemplateCreate) {
          updatePublicTemplatesQuery(queryResult => {
            if (queryResult?.campaign) {
              queryResult.campaign.campaignTemplates =
                data?.campaignTemplateCreate as CampaignTemplate;
            }
          });
        }

        return { success: true };
      } catch (e) {
        console.error({ e });
      }
    }

    return { success: false };
  };

  const hasCampaignTemplates = !_.isEmpty(campaignPublicTemplates);

  return {
    campaignPublicTemplates,
    connectTemplatesToCampaign,
    loading,
    updating: connecting,
    hasCampaignTemplates,
  };
};
