import React from 'react';
import { Box, styled } from '@mui/material';
import { isNil } from 'lodash';

import { useModal } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import type { ExperienceStoreAssetActionUpdateOrDeleteModalArgs } from 'src/types/storeAssets';

import { ExperienceStoreAssetUpdateForm } from './components';

const StyledModalContentBox = styled(Box)(({ theme }) => ({
  margin: 0,
  '& > *': {
    paddingBottom: theme.spacing(1),
  },
}));

const ExperienceStoreAssetUpdateModal: React.FC = () => {
  const { isOpen, args, closeModal } = useModal(MODALS.EXPERIENCE_STORE_ASSET_UPDATE_MODAL);

  const modalArgs: ExperienceStoreAssetActionUpdateOrDeleteModalArgs | undefined = args;

  if (isNil(modalArgs)) {
    return null;
  }

  const { experienceStoreAsset } = modalArgs;

  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth>
      <Modal.Title>Edit Store Item</Modal.Title>

      <StyledModalContentBox>
        <Modal.Content>
          <ExperienceStoreAssetUpdateForm
            experienceStoreAsset={experienceStoreAsset}
            closeModal={closeModal}
          />
        </Modal.Content>
      </StyledModalContentBox>
    </Modal>
  );
};

export { ExperienceStoreAssetUpdateModal };
