import React from 'react';
import { Grid, List, ListItem, SxProps } from '@mui/material';

import { EditorTextInputFieldSizeType } from '@vizsla/constants';
import { FontSize } from '@vizsla/theme';
import { ExperienceLandingActions, useLandingState } from '@vizsla/providers';

import { EditorTextInputField } from '../EditorTextInputField';
import { ActionButtonBackgroundTypeField } from './ActionButtonBackgroundTypeField';
import { VisibilityButton } from '../../shared';

export const ActionButtonsSection: React.FC = () => {
  const { state, dispatch } = useLandingState();

  const {
    registrationButtonLabel,
    registrationButtonBackgrondType,
    registrationButtonIsVisible,
    donationButtonBackgrondType,
    donationButtonIsVisible,
    donationButtonLabel,
    shareButtonLabel,
    shareButtonBackgrondType,
    shareButtonIsVisible,
  } = state.theme;

  const actionButtonSxProp: SxProps = {
    width: '173px',
    height: '40px',
    padding: '4px',
    fontSize: FontSize.Default,
  };

  return (
    <List component="div">
      <ListItem disablePadding sx={theme => ({ paddingBottom: theme.spacing(3) })}>
        <Grid container spacing={1}>
          <Grid item>
            <EditorTextInputField
              onChange={buttonLabel =>
                dispatch({
                  type: ExperienceLandingActions.updateRegistrationButtonLabel,
                  payload: buttonLabel,
                })
              }
              fieldSize={EditorTextInputFieldSizeType.small}
              fieldValue={registrationButtonLabel}
              fieldLabel="Register Button Label"
              inputProps={{
                sx: actionButtonSxProp,
              }}
            />
          </Grid>
          <Grid item>
            <ActionButtonBackgroundTypeField
              backgroundType={registrationButtonBackgrondType}
              onChange={backgroundType =>
                dispatch({
                  type: ExperienceLandingActions.updateRegistrationButtonBackgrondType,
                  payload: backgroundType,
                })
              }
            />
          </Grid>
          <Grid item>
            <VisibilityButton
              isVisible={registrationButtonIsVisible}
              onClick={() =>
                dispatch({
                  type: ExperienceLandingActions.updateIsRegistrationButtonVisible,
                  payload: !registrationButtonIsVisible,
                })
              }
            />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem disablePadding sx={theme => ({ paddingBottom: theme.spacing(3) })}>
        <Grid container spacing={1}>
          <Grid item>
            <EditorTextInputField
              onChange={buttonLabel =>
                dispatch({
                  type: ExperienceLandingActions.updateDonationButtonLabel,
                  payload: buttonLabel,
                })
              }
              fieldSize={EditorTextInputFieldSizeType.small}
              fieldValue={donationButtonLabel}
              fieldLabel="Donation Button Label"
              inputProps={{
                sx: actionButtonSxProp,
              }}
            />
          </Grid>
          <Grid item>
            <ActionButtonBackgroundTypeField
              backgroundType={donationButtonBackgrondType}
              onChange={backgroundType =>
                dispatch({
                  type: ExperienceLandingActions.updateDonationButtonBackgrondType,
                  payload: backgroundType,
                })
              }
            />
          </Grid>
          <Grid item>
            <VisibilityButton
              isVisible={donationButtonIsVisible}
              onClick={() =>
                dispatch({
                  type: ExperienceLandingActions.updateIsDonationButtonVisible,
                  payload: !donationButtonIsVisible,
                })
              }
            />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem disablePadding sx={theme => ({ paddingBottom: theme.spacing(3) })} divider>
        <Grid container spacing={1}>
          <Grid item>
            <EditorTextInputField
              onChange={buttonLabel =>
                dispatch({
                  type: ExperienceLandingActions.updateShareButtonLabel,
                  payload: buttonLabel,
                })
              }
              fieldSize={EditorTextInputFieldSizeType.small}
              fieldValue={shareButtonLabel}
              fieldLabel="Share Button Label"
              inputProps={{
                sx: actionButtonSxProp,
              }}
            />
          </Grid>
          <Grid item>
            <ActionButtonBackgroundTypeField
              backgroundType={shareButtonBackgrondType}
              onChange={backgroundType =>
                dispatch({
                  type: ExperienceLandingActions.updateShareButtonBackgrondType,
                  payload: backgroundType,
                })
              }
            />
          </Grid>
          <Grid item>
            <VisibilityButton
              isVisible={shareButtonIsVisible}
              onClick={() =>
                dispatch({
                  type: ExperienceLandingActions.updateIsShareButtonVisible,
                  payload: !shareButtonIsVisible,
                })
              }
            />
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );
};
