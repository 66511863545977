import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Typography,
  List,
  ListItem,
  Link,
  listItemClasses,
  listItemButtonClasses,
  ToggleButton,
  ToggleButtonGroup,
  SxProps,
} from '@mui/material';
import { ExitToAppOutlined as ExitIcon } from '@mui/icons-material';

import { useExperienceLanding, useLandingState } from '@vizsla/providers';
import { Button } from '@vizsla/components';
import { buildUrl } from '@vizsla/utils';
import { theme } from '@vizsla/theme';
import { useNotification } from '@vizsla/hooks';

import { useCampaignId } from 'src/hooks/campaign';
import * as appRoutes from 'src/constants/routes';
import * as entitiesId from 'src/constants/entities-id';

import { DesignSection } from './DesignSection';
import { ContentSection } from './ContentSection';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    minWidth: '25rem',
    maxWidth: '25rem',
    boxShadow: '0 5px 15px 0 rgba(171,171,171,0.4)',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    top: 0,
    justifyContent: 'space-between',
  },

  list: {
    overflow: 'auto',
    [`& .${listItemClasses.padding}`]: {
      padding: '16px 0px',
    },
    [`& .${listItemButtonClasses.root}`]: {
      padding: '16px 0',
    },
  },

  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },

  mainButtons: {},

  exit: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'in-line',
    fontSize: '14px',
    fontWeight: 'bold',
  },

  exitIcon: {
    marginLeft: '0.1rem',
    fontSize: '14px',
  },
});

enum TemplateSectionsEnum {
  design = 'Design',
  content = 'Content',
}

export const CampaignTemplateEditor = () => {
  const classes = useStyles();
  const notification = useNotification();

  const campaignId = useCampaignId();

  const { state: experienceLandingState, reset } = useLandingState();
  const { updating: updateLoading, loading: dataLoading, save } = useExperienceLanding();

  const [currentSection, setCurrentSection] = React.useState<TemplateSectionsEnum>(
    TemplateSectionsEnum.design,
  );

  const handleSwitchSection = (
    _: React.ChangeEvent<any>,
    templateSection: TemplateSectionsEnum,
  ) => {
    setCurrentSection(templateSection);
  };

  const handleSave = () => {
    try {
      save(experienceLandingState);
      notification.success('Settings saved successfully');
    } catch (error) {
      console.error(error);
      notification.error('Failed to save settings');
    }
  };

  const handleReset = () => {
    reset();
  };

  const campaignPagesUrl = buildUrl(appRoutes.campaignPages, {
    [entitiesId.campaign]: campaignId,
  });

  const toggleButtonGroupSxProp: SxProps = {
    '&>.MuiButtonBase-root': {
      padding: theme.spacing(0.55, 4),
    },
  };

  return (
    <div className={classes.container}>
      <List className={classes.list} sx={{ padding: '10px 20px' }}>
        <ListItem sx={{ padding: '12px 0' }}>
          <Typography variant="h4">{experienceLandingState?.templateTitle}</Typography>
        </ListItem>

        <ListItem divider sx={theme => ({ paddingY: theme.spacing(3) })} disablePadding>
          <Grid container justifyContent="center" columnSpacing={1}>
            <ToggleButtonGroup
              size="medium"
              exclusive
              value={currentSection}
              onChange={handleSwitchSection}
              sx={toggleButtonGroupSxProp}
            >
              <ToggleButton value={TemplateSectionsEnum.design}>
                {TemplateSectionsEnum.design}
              </ToggleButton>
              <ToggleButton value={TemplateSectionsEnum.content}>
                {TemplateSectionsEnum.content}
              </ToggleButton>
            </ToggleButtonGroup>
            <Grid item className={classes.exit}>
              <Link className={classes.exit} component={RouterLink} to={campaignPagesUrl}>
                <span>Exit editor</span>
                <ExitIcon className={classes.exitIcon} />
              </Link>
            </Grid>
          </Grid>
        </ListItem>

        {currentSection === TemplateSectionsEnum.design && <DesignSection />}
        {currentSection === TemplateSectionsEnum.content && <ContentSection />}

        <ListItem>
          <div className={classes.actionButtons}>
            <Button loading={updateLoading} disabled={dataLoading} onClick={handleSave}>
              Save
            </Button>
            <Button variant="text" onClick={handleReset}>
              Cancel
            </Button>
            <Button variant="text">
              <Link className={classes.exit} component={RouterLink} to={campaignPagesUrl}>
                Exit editor
                <ExitIcon className={classes.exitIcon} />
              </Link>
            </Button>
          </div>
        </ListItem>
      </List>
    </div>
  );
};
