import React from 'react';
import { CircularProgress, Grid, Menu, MenuItem, Theme } from '@mui/material';
import _ from 'lodash';
import { ArrowDropDown, FiberManualRecord as CircleIcon } from '@mui/icons-material';

import { useModal, usePopover } from '@vizsla/hooks';
import { CAMPAIGN_STATUS_LABELS, CAMPAIGN_STATUS_PALETTE } from '@vizsla/constants';

import { CampaignStatus } from 'src/types/campaign';
import { CampaignStatusChip } from 'src/components/campaign';
import { MODALS } from 'src/constants/modals';
import { STATUS_TRANSITIONS } from 'src/constants/campaign';
import { ConfirmationDialog } from 'src/components/shared';
import { useCampaign } from 'src/hooks/campaign';

interface CampaignChangeStatusChipProps {
  campaignId: string;
}

export const CampaignChangeStatusChip: React.FC<CampaignChangeStatusChipProps> = ({
  campaignId,
}) => {
  const popover = usePopover();

  const { data: campaign, loading, updateCampaign, updating } = useCampaign(campaignId);

  const runUpdate = React.useCallback(
    async (status: CampaignStatus) => {
      try {
        await updateCampaign(
          {
            campaignStatus: status,
          },
          ['CampaignsStatistics'],
        );
        popover.closePopover();
      } catch (error) {
        console.error({ error });
      }
    },
    [updateCampaign, popover],
  );

  const { openModal } = useModal();

  const openCampaignCreateModal = (status: CampaignStatus) => {
    openModal(MODALS.CONFIRMATION_MODAL, {
      onConfirm: () => runUpdate(status),
      message: 'Are you sure you want to complete this campaign?',
    });
  };

  if (loading || _.isNil(campaign) || _.isNil(campaign.campaignStatus)) {
    return null;
  }

  const onChangeStatus = async (status: CampaignStatus) => {
    if (status === CampaignStatus.completed) {
      openCampaignCreateModal(status);
      return;
    }

    runUpdate(status);
  };

  const renderMenuItem = (status: CampaignStatus) => {
    return (
      <MenuItem key={status} onClick={() => onChangeStatus(status)}>
        <CircleIcon
          sx={{
            fill: CAMPAIGN_STATUS_PALETTE[status],
          }}
        />
        {CAMPAIGN_STATUS_LABELS[status]}
      </MenuItem>
    );
  };

  const transitions = STATUS_TRANSITIONS[campaign.campaignStatus as CampaignStatus];

  const canChangeStatus = transitions.length > 0;

  const chipAdditionalProps = canChangeStatus
    ? {
        handleClick: popover.openPopover,
        icon: ArrowDropDown,
      }
    : {};

  return (
    <Grid container style={{ width: 'auto' }}>
      <Grid
        item
        sx={{
          marginRight: (theme: Theme) => theme.spacing(1),
        }}
      >
        <CampaignStatusChip
          status={(campaign.campaignStatus as CampaignStatus) || CampaignStatus.draft}
          size="medium"
          {...chipAdditionalProps}
        />
        <Menu
          id="basic-menu"
          anchorEl={popover.el}
          open={popover.isOpen}
          onClose={popover.closePopover}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {transitions.map((status: CampaignStatus) => renderMenuItem(status))}
        </Menu>
      </Grid>
      {updating && (
        <Grid item sx={{ marginTop: '4px' }}>
          <CircularProgress size={24} color="secondary" />
        </Grid>
      )}
      <ConfirmationDialog />
    </Grid>
  );
};
