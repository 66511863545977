import React from 'react';
import { Grid, Skeleton, styled } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { Button } from '@vizsla/components';
import { useExperienceId, useExperienceImages, useModal } from '@vizsla/hooks';

import { EMPTY_IMAGE } from 'src/assets';
import { MODALS } from 'src/constants/modals';
import { ExperienceImagesModalType } from 'src/types/experience';
import { ExperienceHeaderLogoCreateEditModal } from 'src/modals';

const DEFAULT_IMAGE_SIZE = 180;

const StyledImage = styled('img')(({ theme }) => ({
  display: 'block',
  maxWidth: '100%',
  maxHeight: DEFAULT_IMAGE_SIZE,
  borderRadius: 10,
  objectFit: 'contain',
}));

const StyledButtonsGrid = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  '&>.MuiButton-text': {
    padding: theme.spacing(1, 2),
  },
}));

export const CampaignExperienceHeaderLogoSection: React.FC = () => {
  const experienceId = useExperienceId();

  const {
    loadingLogoImage: loading,
    deleteExperienceLogoImage,
    experienceLogoImage,
    hasExperienceLogoImage,
  } = useExperienceImages(experienceId);

  const { openModal } = useModal();

  const logoImageUrl = experienceLogoImage?.downloadUrl || EMPTY_IMAGE;

  const onOpenHeaderLogoCreateUpdateModal = React.useCallback(
    (modalArgs: Record<string, any>) =>
      openModal(MODALS.EXPERIENCE_HEADER_LOGO_UPDATE_MODAL, modalArgs),
    [openModal],
  );

  const onOpenHeaderLogoDeleteModal = React.useCallback(
    () =>
      openModal(MODALS.CONFIRMATION_DELETE_MODAL, {
        deleteFunction: () => deleteExperienceLogoImage(experienceLogoImage?.id || ''),
        objectName: experienceLogoImage?.filename,
      }),
    [deleteExperienceLogoImage, experienceLogoImage, openModal],
  );

  const renderActionButtons = () => {
    if (!hasExperienceLogoImage) {
      return (
        <Button
          startIcon={<AddIcon />}
          onClick={() =>
            onOpenHeaderLogoCreateUpdateModal({ modalType: ExperienceImagesModalType.Create })
          }
          sx={{ margin: '0' }}
        >
          Add Image
        </Button>
      );
    }

    return (
      <React.Fragment>
        <Button
          variant="text"
          onClick={() =>
            onOpenHeaderLogoCreateUpdateModal({
              modalType: ExperienceImagesModalType.Edit,
              experienceLogoImage,
            })
          }
          sx={theme => ({ marginLeft: '0', padding: theme.spacing(1, 2) })}
          size="small"
        >
          Edit Image
        </Button>

        <Button
          variant="text"
          color="error"
          size="small"
          onClick={onOpenHeaderLogoDeleteModal}
          sx={{ marginLeft: '0' }}
        >
          Delete Image
        </Button>
      </React.Fragment>
    );
  };

  if (loading) {
    return <Skeleton height={170} width="100%" variant="rectangular" />;
  }

  return (
    <Grid container direction="column" alignItems="baseline">
      <Grid item>
        <StyledImage src={logoImageUrl} alt="Header Logo" />
      </Grid>

      <StyledButtonsGrid item mt={2}>
        {renderActionButtons()}
      </StyledButtonsGrid>

      <ExperienceHeaderLogoCreateEditModal />
    </Grid>
  );
};
