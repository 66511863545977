import * as yup from 'yup';

import {
  concatDateWithTime,
  formatDate,
  isDateGreaterOrEquals,
  isDateLessOrEquals,
} from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';
import { Experience, RegistrationOption } from '@vizsla/graphql';

import { EarlyBirdRegistrationsApplyTo } from 'src/types/earlyBirdRegistration';

const EARLY_BIRD_REGISTRATION_OPTION_SCHEMA = yup.object({
  id: yup.string().nullable(),
  registrationOption: yup.object().nullable(),
  startTime: yup.string().nullable().required('Start Time is required'),
  startDate: yup
    .string()
    .nullable()
    .required()
    .test(
      'start-date-should-be-less-than-the-experience-start-date',
      'Start date should be less than the Experience start date',
      (startDate: string | null | undefined, { options, parent, createError, path }) => {
        const { context } = options;

        const experience = context?.experience as Experience | undefined | null;

        const experienceStartDate = experience?.startDate;

        const { startTime } = parent;

        const earlyBirdStartDate = concatDateWithTime(startDate, startTime);

        if (isDateGreaterOrEquals(earlyBirdStartDate, experienceStartDate)) {
          const errorDateText =
            formatDate(experienceStartDate, DateFormatPatterns.shortMonthDayYear) ??
            'the Experience start date';

          return createError({
            message: `Start date should be less than ${errorDateText}`,
            path,
          });
        }

        return true;
      },
    )
    .test(
      'start-date-should-be-less-than-the-end-date',
      'Start date should be less than the end date',
      (startDate: string | null | undefined, { parent, createError, path }) => {
        const { startTime, endTime, endDate } = parent;

        const earlyBirdStartDate = concatDateWithTime(startDate, startTime);

        const earlyBirdEndDate = concatDateWithTime(endDate, endTime);

        if (isDateLessOrEquals(earlyBirdStartDate, earlyBirdEndDate)) {
          return true;
        }

        const errorDateText =
          formatDate(earlyBirdEndDate, DateFormatPatterns.shortMonthDayYear) ??
          'the Experience start date';

        return createError({
          message: `Start date should be less than ${errorDateText}`,
          path,
        });
      },
    ),
  endTime: yup.string().nullable().required('End Time is required'),
  endDate: yup
    .string()
    .nullable()
    .required()
    .test(
      'end-date-should-be-less-than-the-experience-end-date',
      'End date should be less than the Experience end date',
      (endDate: string | null | undefined, { options, parent, createError, path }) => {
        const { context } = options;

        const experience = context?.experience as Experience | undefined | null;

        const experienceEndDate = experience?.endDate;

        const { endTime } = parent;

        const earlyBirdEndDate = concatDateWithTime(endDate, endTime);

        if (isDateGreaterOrEquals(earlyBirdEndDate, experienceEndDate)) {
          const errorDateText =
            formatDate(experienceEndDate, DateFormatPatterns.shortMonthDayYear) ??
            'the Experience end date';

          return createError({
            message: `End date should be less than ${errorDateText}`,
            path,
          });
        }

        return true;
      },
    )
    .test(
      'end-date-should-be-less-than-registration-options-start-date',
      "End date should be less than Registration Option's start date",
      (endDate: string | undefined | null, { parent, createError, path }) => {
        const { endTime } = parent;

        const registrationOption = parent.registrationOption as RegistrationOption;

        const registrationOptionStartDate = registrationOption?.registrationStartDate;

        const earlyBirdEndDate = concatDateWithTime(endDate, endTime);

        if (isDateGreaterOrEquals(earlyBirdEndDate, registrationOptionStartDate)) {
          const errorDateText =
            formatDate(registrationOptionStartDate, DateFormatPatterns.shortMonthDayYear) ??
            "Registration Option's start date";

          return createError({
            message: `End date should be less than ${errorDateText}`,
            path,
          });
        }

        return true;
      },
    ),
});

export const EARLY_BIRD_REGISTRATION_OPTION_CREATE_SCHEMA = yup.object({
  earlyBirdRegistrationsApplyTo: yup.string().required(),
  earlyBirdRegistrationsMode: yup.string().required(),
  earlyBirdRegistrationOptions: yup
    .array()
    .nullable()
    .when('earlyBirdRegistrationsApplyTo', {
      is: (earlyBirdRegistrationsApplyTo: EarlyBirdRegistrationsApplyTo) =>
        earlyBirdRegistrationsApplyTo === EarlyBirdRegistrationsApplyTo.specificOptions,
      then: yup.array().of(EARLY_BIRD_REGISTRATION_OPTION_SCHEMA),
    }),
  allEarlyBirdRegistrationOptions: yup
    .object()
    .nullable()
    .when('earlyBirdRegistrationsApplyTo', {
      is: (earlyBirdRegistrationsApplyTo: EarlyBirdRegistrationsApplyTo) =>
        earlyBirdRegistrationsApplyTo === EarlyBirdRegistrationsApplyTo.allRegistrationOptions,
      then: EARLY_BIRD_REGISTRATION_OPTION_SCHEMA,
    }),
});
