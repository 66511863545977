import React from 'react';

import { MODALS } from '@vizsla/constants';
import { useDeletePartnershipContactMutation } from '@vizsla/graphql';
import { useModal, useNotification } from '@vizsla/hooks';

export const useRowActions = (partnershipId: string, setLoading: (bool) => void) => {
  const { openModal } = useModal(MODALS.PARTNERSHIP_CONTACT_CREATE);
  const notifications = useNotification();
  const [deleteContact] = useDeletePartnershipContactMutation({});

  const actions = React.useMemo(() => {
    return [
      {
        actionName: 'Edit',
        onAction: contact =>
          openModal(MODALS.PARTNERSHIP_CONTACT_CREATE, {
            partnershipId,
            contact: {
              id: contact?.id,
              firstName: contact.firstName,
              lastName: contact.lastName,
              email: contact.email,
              title: contact.title,
              phone: contact.phone,
              notes: contact?.notes,
            },
          }),
      },
      {
        actionName: 'Delete',
        onAction: async contact => {
          setLoading(true);
          try {
            await deleteContact({
              variables: { data: { id: contact.id } },
              refetchQueries: ['ListPartnershipContacts'],
            });
            notifications.success(`Contact deleted successfully`);
          } catch (error) {
            notifications.error(`Error while deleting the contact: ${error.message}`);
          }
          setLoading(false);
        },
      },
    ];
  }, [MODALS, partnershipId, deleteContact, setLoading, notifications]);

  return {
    actions,
  };
};
