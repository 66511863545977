import React from 'react';
import { Elements } from '@stripe/react-stripe-js';

import { useStripeOptions, useStripePromiseClient } from 'src/hooks/stripe';

interface Props {
  clientSecret?: string;
}

export const StripeProvider = (props: React.PropsWithChildren<Props>) => {
  const stripe = useStripePromiseClient();
  const options = useStripeOptions(props.clientSecret);

  return (
    <Elements stripe={stripe} options={options}>
      {props.children}
    </Elements>
  );
};
