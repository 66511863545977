import React from 'react';

import { useExperienceFormCollection } from 'src/hooks/experiences';
import { ExperienceFormCollectionController, ExperienceForm } from 'src/components/experience';

export const CampaignExperiencesFormCollection: React.FC = () => {
  const { fields, selectedForm, selectForm, addForm, removeForm, canAddForm, canRemoveForm } =
    useExperienceFormCollection();

  const forms = fields.map((formName: string, index: number) => (
    <ExperienceForm key={fields.value[index].uid} formName={formName} formIndex={index} />
  ));

  const formValues = [...fields.value];

  if (!forms.length) {
    return null;
  }

  return (
    <ExperienceFormCollectionController
      formValues={formValues}
      selectedForm={selectedForm}
      selectForm={selectForm}
      addForm={addForm}
      canAddForm={canAddForm}
      removeForm={removeForm}
      canRemoveForm={canRemoveForm}
    >
      {forms[selectedForm]}
    </ExperienceFormCollectionController>
  );
};
