import React from 'react';
import { Field, Form } from 'react-final-form';
import { FormApi } from 'final-form';
import { Grid } from '@mui/material';

import { useModal, useNotification } from '@vizsla/hooks';
import {
  DataBaseSelectField,
  NumberField,
  Button,
  useDataBaseSelectField,
} from '@vizsla/components';
import { validateWithSchema } from '@vizsla/utils';
import { Attendee, AttendeesListQuery, ExperienceBibAssignment, User } from '@vizsla/graphql';

import { useExperienceId, useExperienceBibAssignment } from 'src/hooks/experiences';
import { ATTENDEES_LIST_QUERY } from 'src/graphql';
import { MODALS } from 'src/constants/modals';
import { BASE_NUMBER_FORMAT } from 'src/constants/formats';
import { getAttendeeSearchFilterWithoutEmail } from 'src/utils/attendee';
import { getUserFullName } from 'src/utils/user';
import { ExperienceIndividualBibCreateFormSchema } from 'src/constants/validationSchemas/bibAssignment';
import { t } from 'src/utils/template';

export const ExperienceIndividualBibCreateForm: React.FC = () => {
  const experienceId = useExperienceId();
  const notification = useNotification();
  const { closeModal } = useModal(MODALS.INDIVIDUAL_BIB_CREATE_MODAL);

  const { individualBibAssignments, excludedBibAssignments, createIndividualAssignment } =
    useExperienceBibAssignment(experienceId);

  const excludedBibNumbers = excludedBibAssignments.map(
    (excludedBibNumber: ExperienceBibAssignment) => excludedBibNumber?.bibNumber,
  );

  const selectFieldProps = useDataBaseSelectField<AttendeesListQuery, Attendee>(
    {
      query: ATTENDEES_LIST_QUERY,
      getQueryItems: data => data?.attendeesList?.items ?? [],

      getCustomQueryVariables: (searchText: string) =>
        getAttendeeSearchFilterWithoutEmail(searchText, experienceId),
    },
    {
      getOptionLabel: (attendee: Attendee) => getUserFullName(attendee.user as User),
    },
  );

  const onSubmit = async (data: Record<string, any>, form: FormApi) => {
    try {
      await createIndividualAssignment(data?.attendee?.id, data?.bibNumber);

      notification.success(t('experience_individual_bib_number_create_success'));
      closeModal();
    } catch (error) {
      console.error(error);
      notification.error(t('experience_individual_bib_number_create_error'));
    }
  };

  const INITIAL_VALUES = {
    bibNumber: undefined,
    attendee: null,
  };

  return (
    <Form
      onSubmit={onSubmit as any}
      initialValues={INITIAL_VALUES}
      validate={values =>
        validateWithSchema(ExperienceIndividualBibCreateFormSchema, values, {
          context: {
            individualBibAssignments,
            excludedBibNumbers,
          },
        })
      }
      subscription={{ submitting: true }}
    >
      {({ submitting, pristine, handleSubmit, hasValidationErrors }) => {
        return (
          <form>
            <Grid container justifyContent="center" spacing={3} paddingTop={2}>
              <Grid item xs={8}>
                <Field
                  name="bibNumber"
                  label="Bib Number"
                  component={NumberField}
                  numberFormat={BASE_NUMBER_FORMAT}
                  disabled={submitting}
                  variant="outlined"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={8}>
                <Field
                  name="attendee"
                  label="Attendee"
                  component={DataBaseSelectField}
                  disabled={submitting}
                  required
                  fullWidth
                  {...selectFieldProps}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center" paddingTop={1}>
                  <Grid item>
                    <Button disabled={submitting} variant="text" onClick={closeModal as any}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      loading={submitting}
                      disabled={pristine || hasValidationErrors}
                      onClick={handleSubmit}
                    >
                      Assign
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
};
