import React, { useCallback, useMemo, useState } from 'react';
import { Grid, TextField as TField } from '@mui/material';
import { Field, FormSpy } from 'react-final-form';
import moment from 'moment';

import { DateField, NumberField, FormState } from '@vizsla/components';
import { DateFormatPatterns } from '@vizsla/constants';
import { useExperienceById, useExperienceId } from '@vizsla/hooks';

import { SwitchSectionField } from 'src/components/forms';

export const RegistrationOptionCreateFormStepTwo: React.FC = () => {
  const experienceId = useExperienceId();
  const { experience, loading: experienceLoading } = useExperienceById(experienceId);
  const [form, setForm] = useState<Record<string, any>>({});
  const ageRequirementAsOfDate = useMemo(() => {
    if (!form?.ageRequirmentEnabled) {
      return '';
    }
    const age = parseInt(form?.ageRequirement || 0, 10);
    const startDate = experience?.startDate || new Date();
    const dateMoment = moment(startDate).subtract(age, 'years').format('yyyy-MM-DD');
    return dateMoment;
  }, [form, experience]);
  const handleChanges = useCallback((state: FormState) => {
    setForm(state.values);
  }, []);

  return (
    <React.Fragment>
      <FormSpy subscription={{ values: true }} onChange={handleChanges} />
      <FormSpy subscription={{ values: true }}>
        {({ values, form }) => {
          console.error(form.getState());
          return (
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                <SwitchSectionField
                  name="ageRequirmentEnabled"
                  heading="Age Requirement"
                  fieldsToResetOnUncheck={['ageRequirement']}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        disabled={!values.ageRequirmentEnabled}
                        name="ageRequirement"
                        label="Age Requirement"
                        component={NumberField}
                        min={0}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TField
                        value={ageRequirementAsOfDate}
                        fullWidth
                        disabled
                        label="As of a Date"
                      />
                    </Grid>
                  </Grid>
                </SwitchSectionField>
              </Grid>
              <Grid item xs={12}>
                <SwitchSectionField
                  name="registrationLimitsEnabled"
                  heading="Registration Limits"
                  tooltip="Registration Limits"
                  fieldsToResetOnUncheck={[
                    'attendeesPerRegistration',
                    'maximumRegistrationPerOrder',
                  ]}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        required
                        disabled={!values.registrationLimitsEnabled}
                        name="attendeesPerRegistration"
                        label="Attendees per Registration"
                        component={NumberField}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Field
                        required
                        disabled={!values.registrationLimitsEnabled}
                        name="maximumRegistrationPerOrder"
                        label="Maximum Registration per Order"
                        component={NumberField}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </SwitchSectionField>
              </Grid>
              <Grid item xs={12}>
                <SwitchSectionField
                  name="groupRegistrationEnabled"
                  heading="Group Registration"
                  tooltip="Group Registration"
                  fieldsToResetOnUncheck={['groupRegistrationMinimum', 'groupRegistrationMaximum']}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        disabled={!values.groupRegistrationEnabled}
                        required
                        name="groupRegistrationMinimum"
                        label="Minimum"
                        component={NumberField}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Field
                        disabled={!values.groupRegistrationEnabled}
                        required
                        name="groupRegistrationMaximum"
                        label="Maximum"
                        component={NumberField}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </SwitchSectionField>
              </Grid>
            </Grid>
          );
        }}
      </FormSpy>
    </React.Fragment>
  );
};
