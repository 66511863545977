import React from 'react';
import { Field, Form } from 'react-final-form';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useNotification } from '@vizsla/hooks';
import { RadioGroupField, SelectField, Button } from '@vizsla/components';

import { useExperienceId, useExperienceBibAssignment } from 'src/hooks/experiences';
import { BibBatchAssignSort, BibSortOrder } from 'src/types/bibAssignment';
import {
  ASSIGNMENT_DIRECTION_OPTIONS,
  ASSIGNMENT_ORDER_OPTIONS,
} from 'src/constants/bibAssignment';
import { t } from 'src/utils/template';
import { FontSize } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  assignBtn: {
    margin: 0,
    padding: theme.spacing(1, 2),
  },
}));

const INITIAL_VALUES = {
  assignmentOrder: BibBatchAssignSort.RegistrationDateTime,
  assignmentDirectionType: BibSortOrder.ASC,
};

export const CampaignExperienceBibBatchAssignmentForm: React.FC = () => {
  const classes = useStyles();

  const notification = useNotification();

  const experienceId = useExperienceId();
  const { createBatchAssignments } = useExperienceBibAssignment(experienceId);

  const onSubmit = async (data: typeof INITIAL_VALUES) => {
    const sortBy = data?.assignmentOrder;
    const order = data?.assignmentDirectionType;

    try {
      await createBatchAssignments(sortBy, order);

      notification.success(t('experience_batch_assignment_success'));
    } catch (e) {
      notification.error(t('experience_batch_assignment_error'));
    }
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Typography variant="body2">
          Description about batch goes here lorem ipsum dolor sit amet, consectetur adipiscing.
        </Typography>
      </Grid>
      <Grid item>
        <Form
          onSubmit={onSubmit as any}
          initialValues={INITIAL_VALUES}
          subscription={{ submitting: true, initialValues: true }}
        >
          {({ submitting, pristine, handleSubmit }) => {
            return (
              <form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={10}>
                        <Field
                          name="assignmentOrder"
                          label="Assignment Order"
                          options={ASSIGNMENT_ORDER_OPTIONS}
                          component={SelectField}
                          disabled={submitting}
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="assignmentDirectionType"
                          options={ASSIGNMENT_DIRECTION_OPTIONS}
                          component={RadioGroupField}
                          disabled={submitting}
                          row
                          optionControlLabelProps={{
                            sx: {
                              '& .MuiFormControlLabel-label': {
                                fontSize: FontSize.Default,
                              },
                            },
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      onClick={handleSubmit}
                      className={classes.assignBtn}
                      loading={submitting}
                    >
                      Assign Now
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </Grid>
    </Grid>
  );
};
