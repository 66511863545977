var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { LinearProgress } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import _ from 'lodash';
import { useOrganizationById } from '@vizsla/hooks';
import { StripeIntegrationStatus } from '../../types';
const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;
export const StripeDataContext = React.createContext({});
const useInitStripe = (organizationId) => {
    var _a, _b, _c, _d;
    const { organization, organizationIsLoading } = useOrganizationById(organizationId);
    const [stripe, setStripe] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const noStripeAccountId = _.isNil((_a = organization === null || organization === void 0 ? void 0 : organization.stripeIntegration) === null || _a === void 0 ? void 0 : _a.stripeAccountID);
    const isStripeIntegrationDisconnected = ((_b = organization === null || organization === void 0 ? void 0 : organization.stripeIntegration) === null || _b === void 0 ? void 0 : _b.status) === StripeIntegrationStatus.Disconnected;
    const isStripeLoaded = !_.isNil(stripe);
    React.useEffect(() => {
        const fetchStripe = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (organizationIsLoading ||
                noStripeAccountId ||
                isStripeLoaded ||
                isStripeIntegrationDisconnected)
                return;
            setIsLoading(true);
            const fetchedStripe = yield loadStripe(REACT_APP_STRIPE_PUBLIC_KEY, {
                locale: 'en',
                stripeAccount: String((_a = organization === null || organization === void 0 ? void 0 : organization.stripeIntegration) === null || _a === void 0 ? void 0 : _a.stripeAccountID),
            });
            setStripe(fetchedStripe);
            setIsLoading(false);
        });
        fetchStripe();
    }, [
        organizationIsLoading,
        noStripeAccountId,
        isStripeLoaded,
        isStripeIntegrationDisconnected,
        (_c = organization === null || organization === void 0 ? void 0 : organization.stripeIntegration) === null || _c === void 0 ? void 0 : _c.stripeAccountID,
    ]);
    return {
        stripeAccountID: (_d = organization === null || organization === void 0 ? void 0 : organization.stripeIntegration) === null || _d === void 0 ? void 0 : _d.stripeAccountID,
        stripe,
        isLoading: organizationIsLoading || isLoading,
    };
};
export const StripeElementsProvider = ({ organizationId, noStripeMessage, children, }) => {
    const { stripeAccountID, stripe, isLoading } = useInitStripe(organizationId);
    if (isLoading) {
        return _jsx(LinearProgress, { color: "secondary" });
    }
    if (_.isNil(stripe)) {
        return _jsx(React.Fragment, { children: noStripeMessage });
    }
    return (_jsx(StripeDataContext.Provider, Object.assign({ value: { stripeAccountID } }, { children: _jsx(Elements, Object.assign({ stripe: stripe }, { children: children })) })));
};
