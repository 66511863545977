import React from 'react';
import { Grid, Typography, styled } from '@mui/material';
import { useForm } from 'react-final-form';
import _ from 'lodash';

import { formatMoney, getUserFullName } from '@vizsla/utils';
import { User, UsersListQuery, VizslaOrganizationStaff } from '@vizsla/graphql';
import { MONEY_FORMAT } from '@vizsla/constants';
import { useCurrentOrganization } from '@vizsla/hooks';
import {
  Modal,
  Field,
  NumberField,
  DateField,
  DataBaseMultiSelectField,
  useDataBaseSelectField,
  UserChip,
} from '@vizsla/components';

import { VIZSLA_ORGANIZATION_STAFF_QUERY } from 'src/graphql';
import {
  StoreAsseInventoryFormFields,
  StoreAssetInventoryAddOrAdjustFormValues,
} from 'src/types/storeAssets';
import { useStoreAsset } from 'src/hooks/storeAssets';

const StyledInlineButton = styled('button')(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 0,
  color: theme.customPalette.typography.color.link,
  '&:hover': {
    cursor: 'pointer',
  },
}));

const FormOrderInfoSection: React.FC = () => {
  const { organizationId } = useCurrentOrganization();
  const { storeAsset } = useStoreAsset();

  const { change, getFieldState } = useForm<StoreAssetInventoryAddOrAdjustFormValues>();
  const quantityFieldState = 0;

  const multiSelectFieldProps = useDataBaseSelectField<UsersListQuery, User>(
    {
      query: VIZSLA_ORGANIZATION_STAFF_QUERY,
      getQueryItems: data => {
        const vizslaOgranizationsStaff = _.get(
          data,
          ['vizslaOrganization', 'vizslaOrganizationStaff', 'items'],
          [],
        );

        return vizslaOgranizationsStaff.map((staff: VizslaOrganizationStaff) => staff.user);
      },
      getCustomQueryVariables: () => ({
        id: organizationId,
      }),
    },
    {
      getOptionLabel: (user: User) => `${getUserFullName(user)} (${user.email})`,
      renderTag: (user: User) => {
        return <UserChip key={user.id} user={user} sx={{ m: 2 }} />;
      },
    },
  );

  const setOrderCost = React.useCallback(
    (value: number) => {
      change(StoreAsseInventoryFormFields.ORDER_COST, value);
    },
    [change],
  );

  const renderSuggestedOrderCost = () => {
    const quantity = 0;
    if (_.isNil(quantity) || _.isNil(storeAsset.unitCost)) {
      return null;
    }

    const suggestedCost = storeAsset.unitCost * quantity;

    return (
      <Grid item xs={12} textAlign="right" sx={{ paddingTop: '0 !important' }}>
        <Typography variant="caption">
          Suggested order cost: {formatMoney(suggestedCost)}.{' '}
          <StyledInlineButton onClick={() => setOrderCost(suggestedCost)}>Set</StyledInlineButton>
        </Typography>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Modal.ContentText>Order Information</Modal.ContentText>
      </Grid>

      <Grid item xs={6}>
        <Field
          fullWidth
          name={StoreAsseInventoryFormFields.DATE_RECEIVED}
          label="Date Received"
          component={DateField}
        />
      </Grid>

      <Grid item xs={6}>
        <Field
          fullWidth
          name={StoreAsseInventoryFormFields.ORDER_COST}
          label="Order Cost"
          numberFormat={MONEY_FORMAT}
          component={NumberField}
        />
      </Grid>

      {renderSuggestedOrderCost()}

      <Grid item xs={12}>
        <Field
          fullWidth
          name={StoreAsseInventoryFormFields.RECIPIENTS}
          label="Received by"
          component={DataBaseMultiSelectField}
          {...multiSelectFieldProps}
        />
      </Grid>
    </React.Fragment>
  );
};

export { FormOrderInfoSection };
