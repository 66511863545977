import { create as createStore } from 'zustand';
export var PaymentGatewayStatus;
(function (PaymentGatewayStatus) {
    PaymentGatewayStatus["PROCESSING"] = "processing";
    PaymentGatewayStatus["SUCCEEDED"] = "succeeded";
    PaymentGatewayStatus["REJECTED"] = "rejected";
})(PaymentGatewayStatus || (PaymentGatewayStatus = {}));
const useStore = createStore(setState => {
    const initial = {
        status: undefined,
        provider: undefined,
        metadata: undefined,
    };
    return Object.assign(Object.assign({}, initial), { clean() {
            setState(initial);
        },
        setMetadata(metadata) {
            setState({ metadata });
        },
        setStatus(status) {
            setState({ status });
        },
        setProvider(provider) {
            setState({ provider });
        } });
});
export function usePaymentGateway() {
    const { status, provider, metadata, setProvider, setMetadata, setStatus, clean } = useStore();
    function initialize(provider, metadata) {
        setProvider(provider);
        setMetadata(metadata);
    }
    return {
        status,
        provider,
        metadata: metadata,
        setStatus,
        initialize,
        clean,
    };
}
