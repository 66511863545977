import React from 'react';
import { Grid } from '@mui/material';
import { FormApi } from 'final-form';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { DateTime } from 'luxon';
import _ from 'lodash';

import { useNotification, useModal } from '@vizsla/hooks';
import {
  Button,
  TextField,
  TimeField,
  RadioGroupField,
  CheckboxField,
  NumberField,
  DateField,
  GridDefaultPadding,
} from '@vizsla/components';
import { isValidDate, isDateEquals, trimTime, toDateTimeFromISO } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';
import { TicketingOption, TicketingPricingTier } from '@vizsla/graphql';

import { useTicketingOptions, useTicketingOptionsPricingTiers } from 'src/hooks/ticketingOption';
import { t } from 'src/utils/template';
import { composeTicketingOptionUpdateInput } from 'src/utils/ticketingOption';
import {
  onValidateTicketingOptionEditForm,
  TicketingOptionFormState,
} from 'src/constants/validationSchemas/ticketingOption';
import { MODALS } from 'src/constants/modals';
import { PricingTierFormAction, PricingType } from 'src/types/pricingSettings';
import {
  INITIAL_PRICING_TIER,
  PRICING_TYPE_SELECT_FIELD_OPTIONS,
} from 'src/constants/pricingSettings';
import { getNewPricingTiersData, getPricingTiersToDeleteIds } from 'src/utils/pricingSettings';
import { PricingTiersField } from 'src/components/experiencePricing';
import { RichEditorField, SectionExpanderField } from 'src/components/forms';

interface TicketingOptionEditFormProps {
  ticketingOption: TicketingOption;
}

export const TicketingOptionEditForm: React.FC<TicketingOptionEditFormProps> = ({
  ticketingOption,
}) => {
  const notification = useNotification();
  const { closeModal } = useModal(MODALS.TICKETING_OPTION_EDIT_MODAL);

  const initialValues = React.useMemo(() => {
    const initialPricingTiers = ticketingOption?.pricingSettings?.pricingTiers?.items.map(
      (item: TicketingPricingTier) => {
        return {
          ..._.pick(item, [
            'id',
            'price',
            'taxDeductibleType',
            'taxDeductiblePercentsAmount',
            'taxDeductibleCurrencyAmount',
          ]),
          startDate: toDateTimeFromISO(item.startDate),
          endDate: toDateTimeFromISO(item.endDate),
          startTime: toDateTimeFromISO(item.startDate),
          endTime: toDateTimeFromISO(item.endDate),
          formAction: PricingTierFormAction.toUpdate,
        };
      },
    );

    const pricingTiers =
      !_.isNil(initialPricingTiers) && initialPricingTiers.length > 0
        ? initialPricingTiers
        : [INITIAL_PRICING_TIER];

    return {
      ticketName: ticketingOption?.ticketName ?? null,
      tableNumber: ticketingOption?.tableNumber ?? null,
      description: ticketingOption?.description ?? null,

      [TicketingOptionFormState.AgeRequirementEnabled]: Boolean(
        ticketingOption?.ageRequirement || ticketingOption?.ageRequirementAsOfDate,
      ),
      ageRequirement: ticketingOption?.ageRequirement ?? null,
      ageRequirementAsOfDate: ticketingOption?.ageRequirementAsOfDate ?? null,

      [TicketingOptionFormState.TicketingLimitsEnabled]: Boolean(
        ticketingOption?.attendeesPerTicket || ticketingOption?.maximumPerOrder,
      ),
      attendeesPerTicket: ticketingOption?.attendeesPerTicket ?? null,
      maximumPerOrder: ticketingOption?.maximumPerOrder ?? null,

      [TicketingOptionFormState.GroupTicketsEnabled]: Boolean(
        ticketingOption?.minimumGroupTickets || ticketingOption?.maximumGroupTickets,
      ),
      minimumGroupTickets: ticketingOption?.minimumGroupTickets ?? null,
      maximumGroupTickets: ticketingOption?.maximumGroupTickets ?? null,

      [TicketingOptionFormState.TicketingAvailabilityEnabled]: Boolean(
        ticketingOption?.ticketingAvailabilityStartDateTime ||
          ticketingOption?.ticketingAvailabilityEndDateTime,
      ),

      ticketingAvailabilityStartDate: isValidDate(
        ticketingOption?.ticketingAvailabilityStartDateTime,
      )
        ? DateTime.fromISO(ticketingOption?.ticketingAvailabilityStartDateTime).toISODate()
        : null,

      ticketingAvailabilityStartTime: isValidDate(
        ticketingOption?.ticketingAvailabilityStartDateTime,
      )
        ? DateTime.fromISO(ticketingOption?.ticketingAvailabilityStartDateTime).toISOTime()
        : null,

      ticketingAvailabilityEndDate: isValidDate(ticketingOption?.ticketingAvailabilityStartDateTime)
        ? DateTime.fromISO(ticketingOption?.ticketingAvailabilityEndDateTime).toISODate()
        : null,

      ticketingAvailabilityEndTime: isValidDate(ticketingOption?.ticketingAvailabilityStartDateTime)
        ? DateTime.fromISO(ticketingOption?.ticketingAvailabilityEndDateTime).toISOTime()
        : null,

      [TicketingOptionFormState.AttendeeCapEnabled]: Boolean(ticketingOption?.maximumAttendees),
      maximumAttendees: ticketingOption?.maximumAttendees ?? null,

      pricingSettings: {
        id: ticketingOption?.pricingSettings?.id,
        pricingEnabled: ticketingOption?.pricingSettings?.pricingEnabled,
        allowCostToBeFundraisingCredit:
          ticketingOption?.pricingSettings?.allowCostToBeFundraisingCredit,
        pricingType: ticketingOption?.pricingSettings?.pricingType ?? PricingType.Fixed,
        pricingTiers,
      },
    };
  }, [ticketingOption]);

  const { updateTicketingOption, updating } = useTicketingOptions();

  const { createNewPricingTiers, deletePricingTiers, creatingPricingTiers, deletingPricingTiers } =
    useTicketingOptionsPricingTiers();

  const onSubmit = async (formData: Record<string, any>, form: FormApi<any, any>) => {
    try {
      const submitData = composeTicketingOptionUpdateInput(formData);

      const newPricingTiersData = getNewPricingTiersData(
        formData,
        ticketingOption.pricingSettings?.id,
      );

      const pricingTiersToDeleteIds = getPricingTiersToDeleteIds(formData);

      if (newPricingTiersData.length > 0) {
        await createNewPricingTiers(newPricingTiersData);
      }

      if (pricingTiersToDeleteIds.length > 0) {
        await deletePricingTiers(pricingTiersToDeleteIds);
      }

      await updateTicketingOption(ticketingOption?.id as string, submitData);

      notification.success(t('ticketing_option_update_success'));
      closeModal();
    } catch (error) {
      notification.error(t('ticketing_option_update_error'));
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={onValidateTicketingOptionEditForm}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({ form, handleSubmit, submitting, values, pristine }) => {
        const isSubmitting = submitting || updating || creatingPricingTiers || deletingPricingTiers;
        const canSubmit = !isSubmitting && !pristine;

        const ticketingAvailabilityMinEndDate = toDateTimeFromISO(
          trimTime(values?.ticketingAvailabilityStartDate),
        );
        const ticketingAvailabilityMaxStartDate = toDateTimeFromISO(
          trimTime(values?.ticketingAvailabilityEndDate),
        );

        const isStartDateEqualsEndDate = isDateEquals(
          trimTime(values?.ticketingAvailabilityStartDate),
          trimTime(values?.ticketingAvailabilityEndDate),
        );
        const ticketingAvailabilityMaxStartTime = isStartDateEqualsEndDate
          ? toDateTimeFromISO(values?.ticketingAvailabilityEndTime)
          : null;
        const ticketingAvailabilityMinEndTime = isStartDateEqualsEndDate
          ? toDateTimeFromISO(values?.ticketingAvailabilityStartTime)
          : null;

        return (
          <form name="ticketingOptionEdit" onSubmit={handleSubmit}>
            <Grid container>
              <GridDefaultPadding item xs={6}>
                <Field
                  required
                  name="ticketName"
                  label="Ticket Name"
                  component={TextField}
                  fullWidth
                />
              </GridDefaultPadding>

              <GridDefaultPadding item xs={6}>
                <Field name="tableNumber" label="Table Number" component={NumberField} fullWidth />
              </GridDefaultPadding>

              <GridDefaultPadding item xs={12}>
                <Field
                  name="description"
                  placeholder="Description (Recommended)"
                  component={RichEditorField}
                  note="This description will appear in the registration option the attendee views."
                />
              </GridDefaultPadding>

              <GridDefaultPadding item xs={12}>
                <SectionExpanderField
                  name={TicketingOptionFormState.AgeRequirementEnabled}
                  isActive={values.ageRequirementEnabled}
                  heading="Age Requirement"
                  labelCollapsed="All ages allowed"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        required
                        name="ageRequirement"
                        label="Age Requirement"
                        component={NumberField}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Field
                        required
                        name="ageRequirementAsOfDate"
                        label="As of Date"
                        component={DateField}
                        inputFormat={DateFormatPatterns.shortMonthDayYear}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </SectionExpanderField>
              </GridDefaultPadding>

              <GridDefaultPadding item xs={12}>
                <SectionExpanderField
                  name={TicketingOptionFormState.TicketingLimitsEnabled}
                  isActive={values.ticketingLimitsEnabled}
                  heading="Ticketing Limits"
                  tooltip="Ticketing Limits"
                  labelCollapsed="Unlimited"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        required
                        name="attendeesPerTicket"
                        label="Attendees per Ticket"
                        component={NumberField}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Field
                        required
                        name="maximumPerOrder"
                        label="Maximum per Order"
                        component={NumberField}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </SectionExpanderField>
              </GridDefaultPadding>

              <GridDefaultPadding item xs={12}>
                <SectionExpanderField
                  name={TicketingOptionFormState.GroupTicketsEnabled}
                  isActive={values.groupTicketsEnabled}
                  heading="Group Tickets"
                  tooltip="Group Tickets"
                  labelCollapsed="Not allowed"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        required
                        name="minimumGroupTickets"
                        label="Minimum"
                        component={NumberField}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Field
                        required
                        name="maximumGroupTickets"
                        label="Maximum"
                        component={NumberField}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </SectionExpanderField>
              </GridDefaultPadding>

              <GridDefaultPadding item xs={12}>
                <SectionExpanderField
                  name={TicketingOptionFormState.TicketingAvailabilityEnabled}
                  heading="Ticketing Availability"
                  labelCollapsed="Always available"
                  isActive={values.ticketingAvailabilityEnabled}
                >
                  <Grid container xs={12} spacing={2}>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item xs={4}>
                        <Field
                          required
                          name="ticketingAvailabilityStartDate"
                          label="Start Date"
                          component={DateField}
                          inputFormat={DateFormatPatterns.shortMonthDayYear}
                          maxDate={ticketingAvailabilityMaxStartDate}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <Field
                          required
                          name="ticketingAvailabilityStartTime"
                          label="Start Time"
                          component={TimeField}
                          maxTime={ticketingAvailabilityMaxStartTime}
                          fullWidth
                        />
                      </Grid>
                    </Grid>

                    <Grid item container xs={12} spacing={2}>
                      <Grid item xs={4}>
                        <Field
                          required
                          name="ticketingAvailabilityEndDate"
                          label="End Date"
                          component={DateField}
                          inputFormat={DateFormatPatterns.shortMonthDayYear}
                          minDate={ticketingAvailabilityMinEndDate}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <Field
                          required
                          name="ticketingAvailabilityEndTime"
                          label="End Time"
                          component={TimeField}
                          minTime={ticketingAvailabilityMinEndTime}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </SectionExpanderField>
              </GridDefaultPadding>

              <GridDefaultPadding item xs={12}>
                <SectionExpanderField
                  name={TicketingOptionFormState.AttendeeCapEnabled}
                  isActive={values.attendeeCapEnabled}
                  heading="Attendee Cap"
                  labelCollapsed="Unlimited"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        required
                        name="maximumAttendees"
                        label="Maximum Attendees"
                        component={NumberField}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </SectionExpanderField>
              </GridDefaultPadding>

              <GridDefaultPadding item xs={12}>
                <SectionExpanderField
                  name="pricingSettings.pricingEnabled"
                  isActive={values.pricingSettings.pricingEnabled}
                  heading="Pricing"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        name="pricingSettings.pricingType"
                        component={RadioGroupField}
                        containerOptions={{ direction: 'row', container: true }}
                        options={PRICING_TYPE_SELECT_FIELD_OPTIONS}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="pricingSettings.pricingTiers"
                        component={PricingTiersField}
                        mutators={form.mutators}
                        variant={values.pricingSettings.pricingType}
                      />
                    </Grid>
                    {/* This section is disabled by current business logic */}
                    {/* <Grid item xs={12}>
                      <Field
                        name="pricingSettings.allowCostToBeFundraisingCredit"
                        label="Allow Registration Cost to be Fundraising Credit."
                        component={CheckboxField}
                      />
                    </Grid> */}
                  </Grid>
                </SectionExpanderField>
              </GridDefaultPadding>

              <GridDefaultPadding item xs={12}>
                <Button color="info" variant="contained" onClick={() => closeModal()}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit} loading={isSubmitting} disabled={!canSubmit}>
                  Save
                </Button>
              </GridDefaultPadding>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
};
