import React from 'react';
import { Form } from 'react-final-form';
import { Grid, Skeleton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormApi } from 'final-form';

import { Button, Field } from '@vizsla/components';
import { validateHtmlTextLength } from '@vizsla/utils';

import { FontSize } from 'src/theme';
import { RichEditorField } from 'src/components/forms';
import { PaperBlock } from 'src/components/shared';
import { useCampaignFundraisingSettings, useCampaignId } from 'src/hooks/campaign';
import { CampaignFundraisingSettingsTypes } from 'src/types/campaign';

const useStyles = makeStyles(() => ({
  messageLabelHeader: {
    fontSize: FontSize.Default,
    fontWeight: 900,
    lineHeight: '20px',
  },
  messageLabelBody: {
    fontSize: FontSize.Default,
    lineHeight: '25px',
  },
}));

const INITIAL_APPEAL_MESSAGE = `<p>Family and friends,</p>
<p>Please help me to support organization by making a donation through my teams’s fundraing page for campaign_name. Even a small donation will help me achieve my goal of goal_amount. Thanks!!!</p>`;

const INITIAL_THANK_YOU_MESSAGE = `<p>Hi first_name,</p>
<p>Thank you for donating to organization through my teams’s fundraing page for campaign_name. Feel free to check back on my fundraising progress as I intent to reach my goal.</p>`;

type CampaignIndividualFundraisingSettingsProps = {
  experienceId?: string;
  useFundraisingSettings?: (value: string) => any;
};

export const CampaignIndividualFundraisingSettings: React.FC<
  CampaignIndividualFundraisingSettingsProps
> = ({ experienceId, useFundraisingSettings = useCampaignFundraisingSettings }) => {
  const classes = useStyles();

  const campaignId = useCampaignId();

  const entityId = experienceId ?? campaignId;
  const { individualSettings, setSettings, loading } = useFundraisingSettings(entityId);

  const INITIAL_VALUES = {
    isEnabled: individualSettings?.isEnabled ?? true,
    appealMessage: individualSettings?.appealMessage ?? INITIAL_APPEAL_MESSAGE,
    thankYouMessage: individualSettings?.thankYouMessage ?? INITIAL_THANK_YOU_MESSAGE,
  };

  const onSubmit = async (data: any) => {
    await setSettings({
      settingsType: CampaignFundraisingSettingsTypes.individualSettings,
      data,
    });
  };

  const onResetForm = (form: FormApi) => {
    form.reset();
  };

  return (
    <PaperBlock title="Individual Fundraising" collapsing defaultOpened>
      <Form initialValues={INITIAL_VALUES} onSubmit={onSubmit} onResetForm={onResetForm}>
        {({ submitting, pristine, handleSubmit, form }) => {
          return (
            <form>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  {loading ? (
                    <Skeleton height={510} variant="rectangular" />
                  ) : (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography component="p" className={classes.messageLabelHeader}>
                              Appeal Message
                            </Typography>
                            <Typography component="p" className={classes.messageLabelBody}>
                              Create a message for your fundraisers to send to supporters. Your
                              fundraisers can always edit upon creating a fundraising page.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              name="appealMessage"
                              label="Appeal message"
                              component={RichEditorField}
                              validate={value => validateHtmlTextLength(value, 1, 500)}
                              maxTextLength={500}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {/* This section is disabled by current business logic */}
                        {/* <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography component="p" className={classes.messageLabelHeader}>
                              Thank You Message
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              name="thankYouMessage"
                              label="Thank you message"
                              component={RichEditorField}
                              validate={value => validateHtmlTextLength(value, 1, 500)}
                              maxTextLength={500}
                            />
                          </Grid>
                        </Grid> */}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Button
                        disabled={submitting || pristine}
                        variant="text"
                        onClick={() => onResetForm(form)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button loading={submitting} disabled={pristine} onClick={handleSubmit}>
                        {pristine ? 'Saved' : 'Save'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Form>
    </PaperBlock>
  );
};
