import React from 'react';
import { Typography } from '@mui/material';

import { useModal } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';

import { ExperienceBibPoolCreateForm } from './components';

const ExperienceBibPoolCreateModal: React.FC = () => {
  const { isOpen, closeModal } = useModal(MODALS.BIB_POOL_CREATE_MODAL);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth customClasses={[`maxWidth: 'sm'`]}>
      <Modal.Title>
        <Typography variant="h3" align="center" paddingBottom={2}>
          New Bib Pool
        </Typography>
      </Modal.Title>
      <Modal.Content>
        <ExperienceBibPoolCreateForm />
      </Modal.Content>
    </Modal>
  );
};

export default ExperienceBibPoolCreateModal;
