import React from 'react';
import { Grid, Typography, styled } from '@mui/material';

import type { DashboardStoreAsset } from 'src/types/storeAssets';
import { EMPTY_IMAGE } from 'src/assets';

const StyledImageGrid = styled(Grid)(({ theme }) => ({
  width: '100px',
  '& > img': {
    display: 'block',
    width: '100%',
    height: '100%',
    maxHeight: '100px',
    objectFit: 'contain',
  },
}));

interface StoreAssetTableItemProps {
  assetName: DashboardStoreAsset['name'];
  assetImageUrl: DashboardStoreAsset['imageUrl'];
}

const TableNameCellContent: React.FC<StoreAssetTableItemProps> = ({ assetName, assetImageUrl }) => {
  const imageSrc = assetImageUrl ?? EMPTY_IMAGE;

  return (
    <Grid container wrap="nowrap" alignItems="center" gap={2.5}>
      <StyledImageGrid item>
        <img src={imageSrc} alt="Asset" />
      </StyledImageGrid>

      <Grid item>
        <Typography variant="body2" fontWeight="bolder">
          {assetName}
        </Typography>
      </Grid>
    </Grid>
  );
};

export { TableNameCellContent };
