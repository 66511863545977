import React from 'react';
import { Grid, FormHelperText, styled } from '@mui/material';
import { useForm, useFormState } from 'react-final-form';
import { isNil } from 'lodash';

import { FontSize, PaletteColor, composeStyles, STYLE_IMPORTANT } from '@vizsla/theme';
import { Modal, Field, CheckboxField, NumberField } from '@vizsla/components';

import { MONEY_FORMAT } from 'src/constants/formats';
import { StoreAssetFormFields, StoreAssetCreateOrUpdateFormValues } from 'src/types/storeAssets';

import { getStoreAvailabilityErrorText, calculateAssetMargin, calculateAssetProfit } from './utils';

const StyledPriceGrid = styled(Grid)(({ theme }) => ({
  paddingTop: composeStyles(theme.spacing(1), STYLE_IMPORTANT),
}));

const StyledPriceNote = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: FontSize.S,
  color: PaletteColor.GrayText,
}));

interface StoreAssetFormAvailabilitySectionProps {
  disabled?: boolean;
}

const StoreAssetFormAvailabilitySection: React.FC<StoreAssetFormAvailabilitySectionProps> = ({
  disabled = false,
}) => {
  const { batch, mutators } = useForm();
  const { values, errors: formErrors } = useFormState<StoreAssetCreateOrUpdateFormValues>();

  const { storeAvailability, swagbagAvailability, price, unitCost } = values;

  const oneOfOptionsSelectionStatus = React.useMemo(() => {
    if (isNil(storeAvailability) || isNil(swagbagAvailability)) {
      return undefined;
    }
    return storeAvailability || swagbagAvailability;
  }, [storeAvailability, swagbagAvailability]);

  React.useEffect(() => {
    if (!isNil(oneOfOptionsSelectionStatus) && !oneOfOptionsSelectionStatus) {
      const priceFileds = [StoreAssetFormFields.RECOMMENDED_PRICE, StoreAssetFormFields.UNIT_COST];

      batch(() => {
        for (const fieldName of priceFileds) {
          mutators.resetField(fieldName);
        }
      });
    }
  }, [mutators, batch, oneOfOptionsSelectionStatus]);

  const renderGridWithError = () => {
    const errorText = getStoreAvailabilityErrorText(formErrors);

    if (!errorText) {
      return null;
    }

    return (
      <Grid item xs={12} mt={-2}>
        <FormHelperText error>{errorText}</FormHelperText>
      </Grid>
    );
  };

  const renderAssetPriceNote = () => {
    if (isNil(price) || isNil(unitCost)) {
      return null;
    }

    const assetMargin = calculateAssetMargin(price, unitCost);
    const assetProfit = calculateAssetProfit(price, unitCost);

    return (
      <StyledPriceNote>
        Margin: {assetMargin} Profit: {assetProfit}
      </StyledPriceNote>
    );
  };
  const priceGridDisplay = oneOfOptionsSelectionStatus ? undefined : 'none';

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Modal.ContentText>Availability</Modal.ContentText>
      </Grid>

      <Grid item xs={12} paddingTop="0 !important">
        <Field
          name={StoreAssetFormFields.STORE_AVAILABILITY}
          label="Store"
          component={CheckboxField}
          required
          disabled={disabled}
        />

        <Field
          name={StoreAssetFormFields.SWAGBAG_AVAILABILITY}
          label="Swag Bag"
          component={CheckboxField}
          required
          disabled={disabled}
        />
      </Grid>

      {renderGridWithError()}

      <StyledPriceGrid item xs={6} display={priceGridDisplay}>
        <Field
          fullWidth
          name={StoreAssetFormFields.RECOMMENDED_PRICE}
          label="Recommended price ($)"
          component={NumberField}
          numberFormat={MONEY_FORMAT}
          required
          disabled={disabled}
        />
      </StyledPriceGrid>

      <StyledPriceGrid item xs={6} display={priceGridDisplay}>
        <Field
          fullWidth
          name={StoreAssetFormFields.UNIT_COST}
          label="Unit cost ($)"
          component={NumberField}
          numberFormat={MONEY_FORMAT}
          required
          disabled={disabled}
        />

        {renderAssetPriceNote()}
      </StyledPriceGrid>
    </React.Fragment>
  );
};

export { StoreAssetFormAvailabilitySection };
