import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Checkbox, FormControlLabel, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { COGNITO_ERROR_CODES, SignupSchema } from '@vizsla/constants';
import { validateWithSchema } from '@vizsla/utils';
import { useAppAuth } from '@vizsla/hooks';
import { PaletteColor, Shade } from '@vizsla/theme';
import { Button, Field, AuthTextField } from '@vizsla/components';

import * as appRoutes from 'src/constants/routes';
import { OrganizationAuthLayout } from 'src/layouts';

const useStyles = makeStyles((theme: Theme) => ({
  loginForm: {
    marginRight: 'auto',
    width: 440,
  },
  textContainer: {
    width: 440,
  },
  signupTextContainer: {
    marginTop: theme.spacing(4),
  },
  signupText: {
    color: Shade.Gray[400],
  },
  submitButton: {
    margin: theme.spacing(2.5, 0, 0, 0),
  },
  checkBoxLabel: {
    '& .MuiFormControlLabel-label': {
      color: PaletteColor.White,
    },
  },
  checkBox: {
    color: Shade.Gray[400],
    '&.Mui-checked': {
      color: Shade.Teal[600],
    },
  },
}));

type SignupErrorType = keyof typeof COGNITO_ERROR_CODES;

const AuthRegistrationPage: React.FC = () => {
  const classes = useStyles();

  const { isAuthenticated, register } = useAppAuth();
  const navigate = useNavigate();

  const [isAgreedToTerms, setAgreedToTerms] = React.useState<boolean>(false);

  const handleChangeAgreed = () => setAgreedToTerms(!isAgreedToTerms);

  if (isAuthenticated) {
    navigate(appRoutes.home);
  }

  const onSubmit = React.useCallback(
    async data => {
      try {
        await register(data);

        return navigate(appRoutes.authConfirmationEmail);
      } catch (e) {
        const errorCode: SignupErrorType = e?.code;
        const errorField =
          COGNITO_ERROR_CODES[errorCode]?.field || COGNITO_ERROR_CODES.UnknownError.field;
        const errorMessage = e.message ?? 'Incorrect email';

        // todo: replace with toast message
        return {
          [errorField]: errorMessage,
        };
      }
    },
    [navigate, register],
  );

  return (
    <OrganizationAuthLayout title="First things first." caption="Create your account.">
      <Form onSubmit={onSubmit} validate={values => validateWithSchema(SignupSchema, values)}>
        {({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
          const isDisabled = submitting || pristine || !isAgreedToTerms || hasValidationErrors;

          return (
            <form onSubmit={handleSubmit} className={classes.loginForm}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Field name="firstName" label="First Name" component={AuthTextField} />
                </Grid>
                <Grid item xs={12}>
                  <Field name="lastName" label="Last Name" component={AuthTextField} />
                </Grid>
                <Grid item xs={12}>
                  <Field name="email" label="Email Address" component={AuthTextField} />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="password"
                    label="Password"
                    component={AuthTextField}
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="confirmPassword"
                    label="Confirm password"
                    component={AuthTextField}
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    className={classes.checkBoxLabel}
                    label="I agree to the Vizsla's Terms and Privacy"
                    control={
                      <Checkbox
                        name="termsAndPrivacy"
                        checked={isAgreedToTerms}
                        onChange={handleChangeAgreed}
                        color="info"
                        className={classes.checkBox}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="left">
                <Grid item>
                  <Button
                    className={classes.submitButton}
                    type="submit"
                    color="secondary"
                    loading={submitting}
                    disabled={isDisabled}
                  >
                    Register
                  </Button>
                </Grid>
              </Grid>
              <Grid container justifyContent="left" className={classes.signupTextContainer}>
                <Grid item>
                  <Typography className={classes.signupText} variant="caption">
                    Already have an Account?&nbsp;
                  </Typography>
                  <Link
                    style={{ textDecoration: 'none', color: PaletteColor.TealText }}
                    to={appRoutes.authLogin}
                  >
                    Log In Now
                  </Link>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Form>
    </OrganizationAuthLayout>
  );
};

export { AuthRegistrationPage };
