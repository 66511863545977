import React from 'react';

import { ActivityPageLayout } from './ActivityPageLayout';
import { ActivityPageContent } from './ActivityPageContent';

export function ActivityFeed() {
  return (
    <ActivityPageLayout>
      <ActivityPageContent />
    </ActivityPageLayout>
  );
}
