import React from 'react';
import { FormSpy, useForm, Field } from 'react-final-form';
import { Grid, Typography } from '@mui/material';

import { DateField, RadioGroupField, TimeField } from '@vizsla/components';
import { DateFormatPatterns } from '@vizsla/constants';
import { getNowDate, toDateTimeFromISO } from '@vizsla/utils';

import { FontSize } from 'src/theme';
import { DiscountDatePickerTypes } from 'src/types/shared';
import { getMaxStartDate, getMinEndDate } from 'src/utils/campaignDiscounts';

import { DATE_PICKER_OPTIONS } from '../constants/campaignDiscountFormOptions';

export const CampaignDiscountFormDateTimeSection: React.FC = () => {
  const { change, getState } = useForm();

  return (
    <FormSpy subscription={{ submitting: true, values: true }}>
      {({ submitting }) => {
        const { values } = getState();
        const { datePickerType, startDate, endDate } = values;

        const visibleDateRange = datePickerType === DiscountDatePickerTypes.dateRange;

        const minStartDate = toDateTimeFromISO(getNowDate());
        const maxStartDate = toDateTimeFromISO(getMaxStartDate(datePickerType, endDate));
        const minEndDate = toDateTimeFromISO(getMinEndDate(datePickerType, startDate));

        if (!visibleDateRange) {
          change('endDate', null);
          change('endTime', null);
        }

        return (
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Dates</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  paddingTop: 0,
                }}
              >
                <Field
                  name="datePickerType"
                  options={DATE_PICKER_OPTIONS}
                  component={RadioGroupField}
                  disabled={submitting}
                  row
                  optionControlLabelProps={{
                    sx: {
                      '& .MuiFormControlLabel-label': {
                        fontSize: FontSize.Default,
                      },
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Grid container spacing={3}>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="startDate"
                          label="Start Date"
                          inputFormat={DateFormatPatterns.shortMonthDayYear}
                          component={DateField}
                          disabled={submitting}
                          minDate={minStartDate}
                          maxDate={maxStartDate}
                          required
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="startTime"
                          label="Start Time"
                          component={TimeField}
                          disabled={submitting}
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    {visibleDateRange && (
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Field
                            name="endDate"
                            label="End Date"
                            inputFormat={DateFormatPatterns.shortMonthDayYear}
                            component={DateField}
                            disabled={submitting}
                            minDate={minEndDate}
                            maxDate={null}
                            required
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="endTime"
                            label="End Time"
                            component={TimeField}
                            disabled={submitting}
                            required
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </FormSpy>
  );
};
