import React from 'react';
import { Grid, Theme, Typography } from '@mui/material';

import { FiltersLayout, Button } from '@vizsla/components';
import { useModal } from '@vizsla/hooks';

import { MODALS } from 'src/constants/modals';

import { CampaignsListLayoutSwitcher, RenderViews } from './components';

export enum CampaignLayoutView {
  Tile,
  Grid,
}

export const OrganizationSettingsTeamMembers: React.FC = () => {
  const [campaignLayoutView, setCampaignLayoutView] = React.useState<CampaignLayoutView>(
    CampaignLayoutView.Tile,
  );
  const onChangeCampaignLayoutView = (_: React.ChangeEvent, layoutView: CampaignLayoutView) => {
    setCampaignLayoutView(layoutView);
  };
  const { openModal } = useModal();

  return (
    <Grid>
      <Typography variant="h3" marginBottom={1}>
        Team Members
      </Typography>
      <Grid direction="column" spacing={2} container>
        <Grid item>
          <FiltersLayout
            rightComponent={
              <React.Fragment>
                <Grid item>
                  <CampaignsListLayoutSwitcher
                    campaignLayoutView={campaignLayoutView}
                    onChangeLayout={onChangeCampaignLayoutView}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ width: 180 }}
                    size="large"
                    color="primary"
                    onClick={() => openModal(MODALS.ORGANIZATION_MEMBER_ADD_MODAL)}
                  >
                    Add New Member
                  </Button>
                </Grid>
              </React.Fragment>
            }
            withSearch
            withPagination
          >
            <RenderViews layoutView={campaignLayoutView} />
          </FiltersLayout>
        </Grid>
      </Grid>
    </Grid>
  );
};
