import styled from '@emotion/styled';
import { Typography } from '@mui/material';

/// Form

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

/// Actions

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 0.5rem;
`;

/// Section

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

/// Title

export const Title = styled(Typography)`
  font-weight: bold;
`;
