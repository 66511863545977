import React from 'react';
import { ConfirmationNumberOutlined, ListAltOutlined } from '@mui/icons-material';

import { RadioGroup, RadioGroupProps, TileRadioButton } from 'src/components/shared';
import { ExperienceType } from 'src/types/experience';
import { FontSize } from 'src/theme';

export interface ExperienceTypeRadioProps extends RadioGroupProps {
  onChangeExperienceType: (experienceType: ExperienceType) => void;
  width?: string | number;
  height?: string | number;
}

// TODO: remove fontSize, set size in component TileRadioButton
export const ExperienceTypeRadioGroup: React.FC<ExperienceTypeRadioProps> = ({
  name,
  defaultValue,
  onChangeExperienceType,
  width = 322,
  height = 88,
}) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const campaignType = event.target.value;

    onChangeExperienceType(campaignType as ExperienceType);
  };

  return (
    <RadioGroup name={name} defaultValue={defaultValue} onChange={onChange}>
      <TileRadioButton
        value={ExperienceType.registration}
        text="Registration"
        width={width}
        height={height}
        innerIcon={<ListAltOutlined style={{ fontSize: FontSize.XXXL }} />}
      />
      <TileRadioButton
        value={ExperienceType.ticketing}
        text="Ticketing"
        width={width}
        height={height}
        innerIcon={<ConfirmationNumberOutlined style={{ fontSize: FontSize.XXXL }} />}
      />
    </RadioGroup>
  );
};
