import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { File } from '@vizsla/graphql';

import { EMPTY_IMAGE } from 'src/assets';

import { ExperienceImageLoader } from './ExperienceImageLoader';

interface ExperienceImageLayoutProps {
  children: React.ReactNode;
  image?: File | null;
  loading?: boolean;
}

const DEFAULT_IMAGE_SIZE = 180;
const DEFAULT_IMAGE_URL = EMPTY_IMAGE;

const useStyles = makeStyles({
  preview: {
    display: 'block',
    maxWidth: '100%',
    minHeight: DEFAULT_IMAGE_SIZE,
    maxHeight: DEFAULT_IMAGE_SIZE,
    borderRadius: 10,
    objectFit: 'contain',
  },
});

export function ExperienceImageLayout(props: ExperienceImageLayoutProps) {
  const styles = useStyles();

  const imageName = useMemo(() => {
    return props.image?.filename ?? 'Experience Header Image';
  }, [props.image]);

  const imageURL = useMemo(() => {
    return props.image?.downloadUrl ?? DEFAULT_IMAGE_URL;
  }, [props.image]);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        {props.loading ? (
          <ExperienceImageLoader />
        ) : (
          <img className={styles.preview} src={imageURL} alt={imageName} />
        )}
      </Grid>

      {props.children}
    </Grid>
  );
}
