import * as formats from '@vizsla/constants/dist/formats';

/** @deprecated use variables from &#64;vizsla/formats package */
export const {
  BASE_NUMBER_FORMAT,
  MONEY_FORMAT,
  DECIMAL_MONEY_FORMAT,
  PERCENT_FORMAT,
  YEAR_FORMAT,
  COUNT_FORMAT,
  RATIO_FORMAT,
  MONTH_FORMAT,
} = formats;
