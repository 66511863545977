import React from 'react';
import { FormControlLabel, List, ListItem, Radio, RadioGroup } from '@mui/material';

import { ExperienceLandingActions, useLandingState } from '@vizsla/providers';
import { HeroMediaType } from '@vizsla/constants';

import { FontSize } from 'src/theme';

export const HeroImageSection = () => {
  const { state, dispatch } = useLandingState();

  return (
    <List component="div" disablePadding>
      <ListItem disablePadding sx={{ paddingBottom: '8px' }}>
        <RadioGroup
          name="heroMediaType"
          row
          value={state.theme.heroMediaType}
          onChange={event =>
            dispatch({
              type: ExperienceLandingActions.updateHeroMediaType,
              payload: event.target.value,
            })
          }
        >
          <FormControlLabel
            color="primary"
            label="Single"
            control={<Radio />}
            value={HeroMediaType.single}
            sx={{
              '& .MuiButtonBase-root': {
                padding: '4px',
                marginLeft: '5px',
              },
              '& .MuiFormControlLabel-label': {
                fontSize: FontSize.Default,
              },
            }}
          />
          <FormControlLabel
            color="primary"
            label="Multiple"
            control={<Radio />}
            value={HeroMediaType.multiple}
            sx={{
              '& .MuiButtonBase-root': {
                padding: '4px',
                marginLeft: '8px',
              },
              '& .MuiFormControlLabel-label': {
                fontSize: FontSize.Default,
              },
            }}
          />
        </RadioGroup>
      </ListItem>
    </List>
  );
};
