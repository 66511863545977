import { gql } from '@apollo/client';

import { CampaignStatus } from 'src/types/campaign';

export const CAMPAIGNS_PAGE_CAMPAIGN_FRAGMENT = gql`
  fragment CampaignsPageCampaignFragment on Campaign {
    id
    name
    code
    contactEmail
    contactPhone {
      code
      number
    }
    logo {
      id
      downloadUrl
    }
    image {
      id
      downloadUrl
    }
    city
    state
    campaignType
    fundraisingGoal
    fundraiserGoal
    attendanceFundraisersCountGoal
    teamGoal
    teams {
      count
      items {
        id
        createdAt
      }
    }
    donorGoal
    donors {
      count
    }
    attendees: experiences(
      groupBy: { query: { attendees: { id: { fn: { aggregate: COUNT }, as: "count" } } } }
    ) {
      groups {
        count: Int
      }
    }
    fundraisers: experiences(
      groupBy: {
        query: { attendees: { fundraisingPageLink: { fn: { aggregate: COUNT }, as: "count" } } }
      }
    ) {
      groups {
        count: Int
      }
    }
    totalRaised
    startDate
    endDate
    campaignStatus
    webLink
    program {
      id
      name
    }
    createdAt
    updatedAt
    createdBy {
      id
      firstName
      lastName
    }
    experiences {
      count
      items {
        id
        name
        startDate
        endDate
        experienceLogoImage {
          id
          downloadUrl
        }
        image {
          id
          downloadUrl
        }
        experienceHeaderImage {
          id
          downloadUrl
        }
      }
    }
    dnsRecord {
      id
      slug
    }
    campaignTemplates {
      id
      templates {
        items {
          id
          templateType {
            type
            title
          }
        }
      }
    }
    campaignStatus
    teams {
      count
    }
  }
`;

export const CAMPAIGN_CREATE_MUTATION = gql`
  mutation CampaignCreate($data: CampaignCreateInput!) {
    campaignCreate(data: $data) {
      ...CampaignsPageCampaignFragment
    }
  }
  ${CAMPAIGNS_PAGE_CAMPAIGN_FRAGMENT}
`;

export const CAMPAIGN_UPDATE_MUTATION = gql`
  mutation CampaignUpdate($data: CampaignUpdateInput!) {
    campaign: campaignUpdate(data: $data) {
      ...CampaignsPageCampaignFragment
    }
  }
  ${CAMPAIGNS_PAGE_CAMPAIGN_FRAGMENT}
`;

export const CAMPAIGN_DELETE_MUTATION = gql`
  mutation CampaignDelete($data: CampaignDeleteInput!) {
    campaignDelete(data: $data, force: true) {
      success
    }
  }
`;

export const CAMPAIGNS_PAGE_QUERY = gql`
  query CampaignsPage($filter: CampaignFilter, $skip: Int, $first: Int) {
    campaignsList(filter: $filter, skip: $skip, first: $first) {
      items {
        ...CampaignsPageCampaignFragment
      }
      count
    }
  }
  ${CAMPAIGNS_PAGE_CAMPAIGN_FRAGMENT}
`;

export const CAMPAIGN_LIST_QUERY = gql`
  query CampaignList {
    campaignsList {
      items {
        id
        name
      }
    }
  }
`;

export const CAMPAIGN_LIST_WITH_EXPERIENCE = gql`
  query CampaignWithExperienceList($filter: CampaignFilter) {
    campaignsList(filter: $filter) {
      items {
        ...CampaignsPageCampaignFragment
      }
    }
  }
  ${CAMPAIGNS_PAGE_CAMPAIGN_FRAGMENT}
`;

export const CAMPAIGN_LIST_BY_ORGANIZATION_QUERY = gql`
  query CampaignListByOrganization($orgId: ID!) {
    campaignsList(filter: { vizslaOrganization: { id: { equals: $orgId } } }) {
      items {
        id
        name
      }
    }
  }
`;

export const CAMPAIGN_STAFFING_QUERY = gql`
  query CampaignStaffing($id: ID) {
    campaign(id: $id) {
      id
      campaignStaff {
        items {
          id
          user {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

export const CAMPAIGNS_STATISTICS_QUERY = gql`
  query CampaignsStatistics($organizationId: ID!) {
    totalCount: campaignsList(
      filter: { vizslaOrganization: { id: { equals: $organizationId } } }
    ) {
      count
    }
    totalActive: campaignsList(
      filter: {
        vizslaOrganization: { id: { equals: $organizationId } }
        campaignStatus: { equals: "${CampaignStatus.live}" }
      }
    ) {
      count
    }
    totalCompleted: campaignsList(
      filter: {
        campaignStatus: { equals: "${CampaignStatus.completed}" }
        vizslaOrganization: { id: { equals: $organizationId } }
      }
    ) {
      count
    }

    totalAttendees: vizslaOrganization(id: $organizationId) {
      campaigns(
        groupBy: {
          query: { experiences: { attendees: { id: { as: "count", fn: { aggregate: COUNT } } } } }
        }
      ) {
        groups {
          count: Int
        }
      }
    }

    totalRaised: campaignsList(
      filter: { vizslaOrganization: { id: { equals: $organizationId } } },
      groupBy: { query: { totalRaised: { as: "totalRaised", fn: { aggregate: SUM } } } }
    ) {
      groups {
        totalRaised: Int
      }
    }
  }
`;

export const CAMPAIGN_FUNDRAISING_SETTINGS_QUERY = gql`
  query FundraisingSettingsByCampaignId($filter: CampaignFundraisingSettingFilter) {
    campaignFundraisingSettingsList(filter: $filter) {
      items {
        id
        generalSettings {
          id
          isEnabled
          isObligation
          isWithoutPenalty
          pageSetUp
          chargeTime
          time
          date
          chargeDate
          isFacebookEnabled
          isInstagramEnabled
        }
        donationSettings {
          id
          isEnabled
          allowAnonymousDonations
          allowSelfDonations
          allowDonorsToOptIn
          allowDonorsToDedicateDonations
          coveredFees
          percentageAmount
          feesEnabled
          feesMessage
          roundupEnabled
          roundupMessage
        }
        teamSettings {
          id
          isEnabled
          teamCapabilities
          joiningTeamEnabled
          allowTeamsMakeTheirTeamInvite
          allowAttendeeRequestAdmissionOntoTeam
          maximumTeams
          maximumTeamMembers
          teamSharingEnabled
          appealMessage
          thankYouMessage
        }
        individualSettings {
          id
          isEnabled
          appealMessage
          thankYouMessage
        }
      }
    }
  }
`;

export const CAMPAIGN_FUNDRAISING_SETTINGS_CREATE_MUTATION = gql`
  mutation CampaignFundraisingSettingsCreate($createData: CampaignFundraisingSettingCreateInput!) {
    campaignFundraisingSettingCreate(data: $createData) {
      id
    }
  }
`;

export const CAMPAIGN_FUNDRAISING_SETTINGS_UPDATE_MUTATION = gql`
  mutation CampaignFundraisingSettingsUpdate(
    $updateData: CampaignFundraisingSettingUpdateInput!
    $settingsId: ID!
  ) {
    campaignFundraisingSettingUpdate(data: $updateData, filter: { id: $settingsId }) {
      id
    }
  }
`;

export const EXPERIENCE_FUNDRAISING_SETTINGS_QUERY = gql`
  query FundraisingSettingsByExperienceId($filter: ExperienceFundraisingFilter) {
    experienceFundraisingsList(filter: $filter) {
      items {
        id
        generalSettings {
          id
          isEnabled
          isObligation
          isWithoutPenalty
          pageSetUp
          chargeTime
          time
          date
          chargeDate
          isFacebookEnabled
          isInstagramEnabled
        }
        donationSettings {
          id
          isEnabled
          allowAnonymousDonations
          allowSelfDonations
          allowDonorsToOptIn
          allowDonorsToDedicateDonations
          coveredFees
          percentageAmount
          feesEnabled
          feesMessage
          roundupEnabled
          roundupMessage
        }
        teamSettings {
          id
          isEnabled
          teamCapabilities
          joiningTeamEnabled
          allowTeamsMakeTheirTeamInvite
          allowAttendeeRequestAdmissionOntoTeam
          maximumTeams
          maximumTeamMembers
          teamSharingEnabled
          appealMessage
          thankYouMessage
        }
        individualSettings {
          id
          isEnabled
          appealMessage
          thankYouMessage
        }
      }
    }
  }
`;

export const EXPERIENCE_FUNDRAISING_SETTINGS_CREATE_MUTATION = gql`
  mutation ExperienceFundraisingSettingsCreate($createData: ExperienceFundraisingCreateInput!) {
    experienceFundraisingCreate(data: $createData) {
      id
    }
  }
`;

export const EXPERIENCE_FUNDRAISING_SETTINGS_UPDATE_MUTATION = gql`
  mutation ExperienceFundraisingSettingsUpdate(
    $updateData: ExperienceFundraisingUpdateInput!
    $settingsId: ID!
  ) {
    experienceFundraisingUpdate(data: $updateData, filter: { id: $settingsId }) {
      id
    }
  }
`;

const USER_RECENT_FRAGMENT = gql`
  fragment USER_RECENT_FRAGMENT on User {
    firstName
    lastName
    email

    avatar {
      downloadUrl
    }
  }
`;

export const REGISTRATION_AND_TICKETING_RECENT_ACTIVITY_QUERY = gql`
  ${USER_RECENT_FRAGMENT}

  query RegistrationAndTicketingRecentActivity($id: ID!, $skip: Int, $first: Int) {
    campaign(id: $id) {
      allocatedDonations(
        first: $first
        skip: $skip
        sort: { createdAt: DESC }
        filter: { parents: { every: null } }
      ) {
        count
        items {
          id
          amount
          createdAt

          donor {
            ...USER_RECENT_FRAGMENT
          }

          individualFundraising {
            id
            attendee {
              user {
                ...USER_RECENT_FRAGMENT
              }
            }
          }

          teamFundraising {
            name
          }
        }
      }

      teams(sort: { createdAt: DESC }, first: $first) {
        items {
          id
          name
          createdAt
          fundraiserGoal
          avatar {
            id
            downloadUrl
          }
          members {
            items {
              id
              role
              attendee {
                user {
                  ...USER_RECENT_FRAGMENT
                }
              }
            }
            count
          }
          donations {
            items {
              id
              amount
            }
          }
          campaign {
            fundraisingSettings {
              teamSettings {
                maximumTeamMembers
              }
            }
          }
        }
        count
      }
    }
  }
`;

export const PERSONAL_MISSION_RECENT_ACTIVITY_QUERY = gql`
  query PersonalMissionRecentActivity($id: ID!, $first: Int) {
    campaign(id: $id) {
      donations(sort: { createdAt: DESC }, first: $first) {
        items {
          id
          amount
        }
        count
      }
    }
  }
`;

export const IMPACT_GIVING_RECENT_ACTIVITY_QUERY = gql`
  query ImpactGivingRecentActivity($id: ID!, $first: Int) {
    campaign(id: $id) {
      donations(sort: { createdAt: DESC }, first: $first) {
        items {
          id
          amount
        }
        count
      }
    }
  }
`;
