import React from 'react';
import { GroupOutlined as GroupIcon } from '@mui/icons-material';

import { InformationCard } from '@vizsla/components';
import { formatMoney } from '@vizsla/utils';

import { useCampaignDonationsMetrics, useCampaignId } from 'src/hooks/campaign';

export function TotalRaisedCard() {
  const campaignId = useCampaignId();

  const { data, loading } = useCampaignDonationsMetrics(campaignId);

  const raised = React.useMemo(() => data?.totalRaised ?? 0, [data]);
  const raisedFormatted = React.useMemo(() => formatMoney(raised), [raised]);

  return (
    <InformationCard
      title="Total Raised"
      value={raisedFormatted}
      icon={<GroupIcon color="secondary" fontSize="large" />}
      loading={loading}
    />
  );
}
