import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Field, useForm } from 'react-final-form';
import { FieldArrayRenderProps } from 'react-final-form-arrays';

import { DateFormatPatterns } from '@vizsla/constants';
import { EarlyBirdRegistrationOption } from '@vizsla/graphql';
import { DateField, TimeField } from '@vizsla/components';

import { EarlyBirdRegistrationsApplyTo } from 'src/types/earlyBirdRegistration';

import { FORM_SPACING } from '../constants';

type EarlyBirdRegistrationOptionFieldsProps = FieldArrayRenderProps<string, any> & {
  allFieldsName?: string;
};

const EarlyBirdRegistrationOptionFields: React.FC<EarlyBirdRegistrationOptionFieldsProps> = ({
  fields,
  allFieldsName = '',
}) => {
  const { getFieldState } = useForm();

  const { name: fieldsName } = fields;

  const earlyBirdRegistrationOptionsValue = getFieldState(fieldsName)?.value || [];

  const earlyBirdRegistrationsApplyTo = getFieldState('earlyBirdRegistrationsApplyTo')
    ?.value as EarlyBirdRegistrationsApplyTo;

  const renderFields = (
    name: string,
    registrationOptionName?: string | null,
    key?: string | null,
  ) => {
    return (
      <React.Fragment>
        <Grid xs={12} container item spacing={2} key={key}>
          {registrationOptionName && (
            <Grid item xs={12}>
              <Typography variant="h5">{registrationOptionName}</Typography>
            </Grid>
          )}
          <Grid item xs={3}>
            <Field
              name={`${name}.startDate`}
              label="Start Date"
              component={DateField}
              required
              inputFormat={DateFormatPatterns.shortMonthDayYear}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              name={`${name}.startTime`}
              label="Start Time"
              required
              component={TimeField}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              name={`${name}.endDate`}
              label="End Date"
              required
              component={DateField}
              inputFormat={DateFormatPatterns.shortMonthDayYear}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              name={`${name}.endTime`}
              label="End Time"
              required
              component={TimeField}
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <Grid container spacing={FORM_SPACING}>
      {earlyBirdRegistrationsApplyTo === EarlyBirdRegistrationsApplyTo.specificOptions
        ? fields.map((name, index) => {
            const fieldValue = earlyBirdRegistrationOptionsValue[index] as
              | EarlyBirdRegistrationOption
              | undefined
              | null;
            return renderFields(name, fieldValue?.registrationOption?.name, fieldValue?.id);
          })
        : renderFields(allFieldsName)}
    </Grid>
  );
};

export default EarlyBirdRegistrationOptionFields;
