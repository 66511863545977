import React from 'react';
import { Avatar, Box, Grid } from '@mui/material';
import {
  ConfirmationNumberOutlined,
  Edit,
  MonetizationOnOutlined,
  PeopleAltOutlined,
  ReceiptOutlined,
  RoomOutlined,
} from '@mui/icons-material';

import { useModal } from '@vizsla/hooks';
import { TableData, TableColumn, TableBottomAction } from '@vizsla/components';
import { User, CampaignStaff } from '@vizsla/graphql';

import { useCampaign, useCampaignId, useCampaignStaff } from 'src/hooks/campaign';
import { CampaignAdministrationIntegration, CampaignAddStaffDialog } from 'src/components/campaign';
import { PaperBlock, TileRadioButton, UserAvatarFullName } from 'src/components/shared';
import { MODALS } from 'src/constants/modals';
import { isRegistrationAndTicketing } from 'src/utils/campaign';
import { CampaignAddOfflineDonationModal, TeamRTCreateModal, SelectExperience } from 'src/modals';

const STAFFING_TABLE_COLUMNS: TableColumn<User>[] = [
  {
    title: 'name',
    key: 'name',
    render: (valueByDataPath: undefined, user: User) => <UserAvatarFullName user={user} />,
  },
  {
    title: 'email',
    key: 'email',
    dataPath: 'email',
  },
];

const CampaignAdministration: React.FC = () => {
  const campaignId = useCampaignId();
  const { data: campaign } = useCampaign(campaignId);

  const { campaignStaff, loading: campaignStaffLoading } = useCampaignStaff(campaignId);

  const staffUsers = campaignStaff?.map((item: CampaignStaff) => item.user as User) ?? [];

  const { openModal } = useModal();

  const onOpenAddStaffModal = () => {
    openModal(MODALS.CAMPAIGN_ADD_STAFF_MODAL);
  };

  const onOpenAddOfflineDonationModal = () => {
    openModal(MODALS.CAMPAIGN_ADD_OFFLINE_DONATION_MODAL);
  };

  const onOpenTeamRtCreateModal = () => {
    openModal(MODALS.TEAM_RT_CREATE_MODAL);
  };

  const onOpenSelectExperienceModal = (section: string) => {
    openModal(MODALS.SELECT_EXPERIENCE, {
      campaignId,
      section,
      dns: campaign?.dnsRecord?.slug,
    });
  };
  const bottomActions: TableBottomAction[] = [
    {
      actionName: 'Edit staff',
      icon: <Edit />,
      onAction: onOpenAddStaffModal,
    },
  ];

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <PaperBlock title="Offline actions" collapsing defaultOpened>
          <Box display="flex" justifyContent="space-between" flexGrow={1} flexBasis={0}>
            <TileRadioButton
              text="Add Donation"
              width="100%"
              innerIcon={<MonetizationOnOutlined fontSize="large" />}
              type="button"
              onClick={onOpenAddOfflineDonationModal}
            />
            <TileRadioButton
              text="Add Pledge"
              width="100%"
              innerIcon={<RoomOutlined fontSize="large" />}
              type="button"
              onClick={() => {
                onOpenSelectExperienceModal('Pledge');
              }}
            />
            {isRegistrationAndTicketing(campaign) && (
              <React.Fragment>
                <TileRadioButton
                  text="Add Registration"
                  width="100%"
                  innerIcon={<ReceiptOutlined fontSize="large" />}
                  type="button"
                  onClick={() => {
                    onOpenSelectExperienceModal('Registration');
                  }}
                />
                <TileRadioButton
                  text="Add Ticket"
                  width="100%"
                  innerIcon={<ConfirmationNumberOutlined fontSize="large" />}
                  type="button"
                  onClick={() => {
                    onOpenSelectExperienceModal('Ticket');
                  }}
                />
              </React.Fragment>
            )}
            <TileRadioButton
              text="Add Teams"
              innerIcon={<PeopleAltOutlined fontSize="large" />}
              onClick={onOpenTeamRtCreateModal}
              width="100%"
              type="button"
            />
          </Box>
        </PaperBlock>
      </Grid>

      <Grid item>
        <PaperBlock title="Staffing" collapsing defaultOpened>
          <TableData
            dataSource={staffUsers}
            columns={STAFFING_TABLE_COLUMNS}
            bottomActions={bottomActions}
            loading={campaignStaffLoading}
          />
          <CampaignAddStaffDialog />
        </PaperBlock>
      </Grid>

      <Grid item>
        <PaperBlock title="Integrations" collapsing defaultOpened>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <CampaignAdministrationIntegration
                title="Double the Donation"
                extra="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
                image={<Avatar>DD</Avatar>}
              />
            </Grid>
            <Grid item>
              <CampaignAdministrationIntegration
                title="Amply"
                extra="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
                image={<Avatar>AM</Avatar>}
              />
            </Grid>
          </Grid>
        </PaperBlock>
      </Grid>
      <CampaignAddOfflineDonationModal />
      <TeamRTCreateModal />
      <SelectExperience />
    </Grid>
  );
};

export { CampaignAdministration };
