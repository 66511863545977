import React from 'react';
import * as R from 'ramda';

import {
  useGetNotificationSettingsQuery,
  useVizslaOrganizationUpdateNotificationSettingsMutation,
} from '@vizsla/graphql';
import { useCurrentOrganization, useNotification } from '@vizsla/hooks';

import { NotificationSettingT, NotificationsGroupedT } from './types';

const mapNotificationToInitialValues = (notificationTypesGrouped: NotificationsGroupedT) => {
  if (!notificationTypesGrouped) return null;
  const reduced = Object.entries(notificationTypesGrouped).reduce((iv, [groupKey, groups]) => {
    const reducedGroup = groups.reduce((acc, groupNotif) => {
      acc[groupNotif.key as string] = {
        enabled: !!groupNotif.organization?.enabled,
        id: groupNotif.id as string,
      };
      return acc;
    }, {} as Record<string, NotificationSettingT>);
    iv[groupKey] = reducedGroup;
    return iv;
  }, {} as Record<string, Record<string, NotificationSettingT>>);
  return reduced;
};

export const useGetNotificationSettingsData = () => {
  const { organizationId, organizationIsLoading } = useCurrentOrganization();
  const [updateOrgNotifs] = useVizslaOrganizationUpdateNotificationSettingsMutation();
  const notification = useNotification();

  const { data: notificationSettings, loading: notificationSettingsLoading } =
    useGetNotificationSettingsQuery({
      variables: { organizationId: organizationId as string },
      skip: !organizationId,
    });

  const { notificationTypesGrouped, initialValues } = React.useMemo(() => {
    const items = notificationSettings?.notificationSettingsList?.items;
    if (!items?.length) return {};
    const groupedTypes = R.groupBy(a => a.type as string, items);
    const initialValues = mapNotificationToInitialValues(groupedTypes);

    return {
      notificationTypesGrouped: groupedTypes as NotificationsGroupedT,
      initialValues: initialValues as Record<string, Record<string, NotificationSettingT>> | null,
    };
  }, [notificationSettings?.notificationSettingsList?.items]);

  const onSubmit = React.useCallback(
    async (form: typeof initialValues) => {
      try {
        if (!form || !initialValues) throw new Error('Wrong form values');

        const { connect, disconnect } = Object.entries(form).reduce(
          (acc, [key, group]) => {
            Object.entries(group).forEach(([notifKey, notifValue]) => {
              if (initialValues[key][notifKey].enabled === notifValue.enabled) {
                return;
              }
              if (notifValue.enabled) {
                acc.connect.push(notifValue);
              } else {
                acc.disconnect.push(notifValue);
              }
            });
            return acc;
          },
          { connect: [], disconnect: [] } as {
            connect: NotificationSettingT[];
            disconnect: NotificationSettingT[];
          },
        );

        await updateOrgNotifs({
          refetchQueries: ['GetNotificationSettings'],
          variables: {
            id: organizationId as string,
            ...(connect?.length ? { connect: connect.map(c => ({ id: c.id })) } : {}),
            ...(disconnect?.length ? { disconnect: disconnect.map(d => ({ id: d.id })) } : {}),
          },
        });
        notification.success('Notification settings saved sucessfully!');
      } catch (error) {
        notification.error('Error while saving notification settings: ', error.message);
      }
    },
    [initialValues],
  );

  const editSection = React.useCallback(
    (group: NotificationsGroupedT[string], checked: boolean) => {
      return group.reduce((acc, val) => {
        return {
          ...acc,
          [val.key as string]: {
            enabled: checked,
            id: val.id as string,
          },
        };
      }, {}) as Record<string, NotificationSettingT>;
    },
    [],
  );

  return {
    onSubmit,
    initialValues: initialValues as Record<string, Record<string, NotificationSettingT>>,
    notificationTypesGrouped,
    loading: organizationIsLoading || !initialValues || notificationSettingsLoading,
    editSection,
  };
};
