import React from 'react';
import _ from 'lodash';
import { ApolloError } from '@apollo/client';

import { CampaignQuestionType, CampaignQuestion, CampaignQuestionCategories } from '@vizsla/types';
import { useApolloCacheQueryManager } from '@vizsla/hooks';
import {
  CampaignQuestionsDocument,
  CampaignQuestionsQueryVariables,
  CustomCampaignQuestion,
  CustomCampaignQuestionCreateInput,
  CustomCampaignQuestionUpdateInput,
  DefaultCampaignQuestion,
  DefaultCampaignQuestionUpdateInput,
  DefaultQuestion,
  CampaignQuestionsQuery,
  useCampaignQuestionsQuery,
  useDefaultQuestionsByCategoryQuery,
  useCustomCampaignQuestionCreateMutation,
  useCampaignDefaultQuestionsCreateManyMutation,
  useUpdateDefaultCampaignQuestionMutation,
  useUpdateCustomCampaignQuestionMutation,
  useUpdateCustomQuestionsByFilterMutation,
  useUpdateDefaultQuestionsByFilterMutation,
  useCustomCampaignQuestionDeleteMutation,
} from '@vizsla/graphql';
import { mutableList } from '@vizsla/utils';

type CampaignQuestionCreateInput = CustomCampaignQuestionCreateInput;

type CampaignQuestionUpdateInput =
  | DefaultCampaignQuestionUpdateInput
  | CustomCampaignQuestionUpdateInput;

type CampaignQuestionsHookResult = {
  data: {
    defaultQuestions: Array<DefaultCampaignQuestion>;
    customQuestions: Array<CustomCampaignQuestion>;
  };
  campaignHasNoDefaultQuestions: boolean;
  handleSetDefaultQuestions: (campaignId: string) => void;
  error: ApolloError | undefined;
  createCampaignQuestion: (
    campaignQuestionData: CampaignQuestionCreateInput,
  ) => Promise<CampaignQuestion | null>;
  updateCampaignQuestion: (
    campaignQuestionData: CampaignQuestionUpdateInput,
    campaignQuestionId: string,
    questionType: CampaignQuestionType,
  ) => Promise<CampaignQuestion | null>;
  deleteCampaignQuestion: (campaignQuestionId: string) => Promise<void>;
  toggleIsRequired: (
    campaignQuestionId: string | null | undefined,
    isEnabled: boolean,
    questionType: CampaignQuestionType,
  ) => Promise<CampaignQuestion | null>;
  toggleIsEnabled: (
    campaignQuestionId: string | null | undefined,
    isEnabled: boolean,
    questionType: CampaignQuestionType,
  ) => Promise<CampaignQuestion | null>;
  toggleEnableAll: (
    isEnabled: boolean,
    category: CampaignQuestionCategories,
  ) => Promise<Array<CampaignQuestion>>;
  connectCampaignQuestionToExperiences: (
    campaignQuestionId: string,
    experienceIds: Array<string>,
    campaignQuestionType: CampaignQuestionType,
  ) => Promise<void>;
  loading: boolean;
  creating: boolean;
  updating: boolean;
  deleting: boolean;
  settingDefaultQuestions: boolean;
};

export const useCampaignQuestions = (campaignId: string): CampaignQuestionsHookResult => {
  const campaignQuestionsQueryVariables = React.useMemo(
    () => ({
      id: campaignId,
      defaultQuestionsFilter: {
        shouldDisplay: {
          equals: true,
        },
      },
    }),
    [campaignId],
  );

  // Queries
  const { data, loading, error } = useCampaignQuestionsQuery({
    variables: campaignQuestionsQueryVariables,
  });

  const { updateQuery: updateCampaignQuestionsQuery } = useApolloCacheQueryManager<
    CampaignQuestionsQuery,
    CampaignQuestionsQueryVariables
  >({
    query: CampaignQuestionsDocument,
    typeName: 'Campaign',
    variables: campaignQuestionsQueryVariables,
  });

  const { data: initialDefaultQuestion, loading: initialDefaultQuestionLoading } =
    useDefaultQuestionsByCategoryQuery();

  // Mutations
  const [createCustomCampaignQuestionMutation, { loading: creating }] =
    useCustomCampaignQuestionCreateMutation();

  const [campaignDefaultQuestionCreateManyMutation, { loading: settingDefaultQuestions }] =
    useCampaignDefaultQuestionsCreateManyMutation({
      refetchQueries: ['CampaignQuestions'],
      awaitRefetchQueries: true,
    });

  const [updateDefaultCampaignQuestionMutation, { loading: updatingDefaultQuestion }] =
    useUpdateDefaultCampaignQuestionMutation();

  const [updateCustomCampaignQuestionMutation, { loading: updatingCustomQuestion }] =
    useUpdateCustomCampaignQuestionMutation();

  const [updateCustomQuestionByFilterMutation, { loading: updatingManyCustomQuestions }] =
    useUpdateCustomQuestionsByFilterMutation();

  const [updateDefaultQuestionByFilterMutation, { loading: updatingManyDefaultQuestions }] =
    useUpdateDefaultQuestionsByFilterMutation();

  const [deleteCustomCampaignQuestionMutation, { loading: deleting }] =
    useCustomCampaignQuestionDeleteMutation();

  const defaultQuestionItems = React.useMemo(
    () => initialDefaultQuestion?.defaultQuestionsList?.items || [],
    [initialDefaultQuestion?.defaultQuestionsList?.items],
  );

  const defaultCampaignQuestions = React.useMemo(
    () => data?.campaign?.defaultCampaignQuestions?.items || [],
    [data?.campaign?.defaultCampaignQuestions?.items],
  );
  const customCampaignQuestions = data?.campaign?.customCampaignQuestions?.items || [];

  const campaignHasNoDefaultQuestions = !loading && _.isEmpty(defaultCampaignQuestions);

  const campaignQuestions = {
    defaultQuestions: defaultCampaignQuestions as Array<DefaultCampaignQuestion>,
    customQuestions: customCampaignQuestions as Array<CustomCampaignQuestion>,
  };

  const removeCampaignCustomQuestionFromCacheById = React.useCallback(
    (campaignQuestionId: string) => {
      updateCampaignQuestionsQuery(query => {
        mutableList(query?.campaign?.customCampaignQuestions?.items || []).removeById(
          campaignQuestionId,
        );
      });
    },
    [updateCampaignQuestionsQuery],
  );

  const addCampaignCustomQuestionToCache = React.useCallback(
    customQuestion => {
      updateCampaignQuestionsQuery(query => {
        mutableList(query?.campaign?.customCampaignQuestions?.items || []).add(customQuestion);
      });
    },
    [updateCampaignQuestionsQuery],
  );

  const createCampaignQuestion = React.useCallback(
    async campaignQuestionCreateInput => {
      try {
        const { data } = await createCustomCampaignQuestionMutation({
          variables: {
            data: campaignQuestionCreateInput,
          },
        });
        const createdCustomCampaignQuestion = data?.customCampaignQuestionCreate || null;

        if (createdCustomCampaignQuestion) {
          addCampaignCustomQuestionToCache(createdCustomCampaignQuestion);
        }

        return createdCustomCampaignQuestion as CampaignQuestion;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [createCustomCampaignQuestionMutation, addCampaignCustomQuestionToCache],
  );

  const updateCampaignQuestion = React.useCallback(
    async (
      campaignQuestionData: CampaignQuestionUpdateInput,
      campaignQuestionId: string,
      questionType: CampaignQuestionType,
    ): Promise<CampaignQuestion | null> => {
      if (!campaignQuestionId) {
        return null;
      }

      try {
        if (questionType === CampaignQuestionType.Default) {
          const result = await updateDefaultCampaignQuestionMutation({
            variables: {
              id: campaignQuestionId,
              data: { ...campaignQuestionData, shouldDisplay: true },
            },
          });

          return (result?.data?.defaultCampaignQuestionUpdate ?? null) as CampaignQuestion | null;
        }

        if (questionType === CampaignQuestionType.Custom) {
          const result = await updateCustomCampaignQuestionMutation({
            variables: {
              id: campaignQuestionId,
              data: campaignQuestionData,
            },
          });

          return (result?.data?.customCampaignQuestionUpdate ?? null) as CampaignQuestion | null;
        }
        return null;
      } catch (error) {
        console.error({ error });
        throw error;
      }
    },
    [updateCustomCampaignQuestionMutation, updateDefaultCampaignQuestionMutation],
  );

  const connectCampaignQuestionToExperiences = async (
    campaignQuestionId: string,
    experienceIds: Array<string>,
    campaignQuestionType: CampaignQuestionType,
  ) => {
    try {
      const connectedExperienceIds = experienceIds.map(id => ({ id }));
      const campaignQuestionData = {
        experience: {
          reconnect: connectedExperienceIds,
        },
      };

      await updateCampaignQuestion(campaignQuestionData, campaignQuestionId, campaignQuestionType);
    } catch (error) {
      console.error({ error });
      throw error;
    }
  };

  const setDefaultQuestions = React.useCallback(
    async (campaignId: string, defaultQuestionItems: Array<DefaultQuestion>) => {
      const createManyData = defaultQuestionItems.map((defaultQuestion: DefaultQuestion) => {
        const isMandatory = defaultQuestion?.isMandatory;

        return {
          campaign: {
            connect: {
              id: campaignId,
            },
          },
          question: { connect: { id: defaultQuestion?.id } },
          isRequired: isMandatory,
        };
      });

      const needToSetDefaultQuestions =
        _.isEmpty(defaultCampaignQuestions) &&
        !settingDefaultQuestions &&
        !_.isEmpty(createManyData);

      if (needToSetDefaultQuestions) {
        try {
          const result = await campaignDefaultQuestionCreateManyMutation({
            variables: {
              data: createManyData,
            },
          });

          return result?.data?.defaultCampaignQuestionCreateMany || null;
        } catch (error) {
          console.error({ error });
          throw error;
        }
      }
      return null;
    },
    [campaignDefaultQuestionCreateManyMutation, defaultCampaignQuestions, settingDefaultQuestions],
  );

  const deleteCampaignQuestion = React.useCallback(
    async (campaignQuestionId: string) => {
      try {
        await deleteCustomCampaignQuestionMutation({
          variables: {
            data: {
              id: campaignQuestionId,
            },
          },
        });

        removeCampaignCustomQuestionFromCacheById(campaignQuestionId);
      } catch ({ error }) {
        console.error({ error });
        throw error;
      }
    },
    [deleteCustomCampaignQuestionMutation, removeCampaignCustomQuestionFromCacheById],
  );

  const toggleIsRequired = React.useCallback(
    async (
      campaignQuestionId: string | null | undefined,
      isRequired: boolean,
      questionType: CampaignQuestionType,
    ): Promise<CampaignQuestion | null> => {
      if (!campaignQuestionId) {
        return null;
      }

      try {
        const question = await updateCampaignQuestion(
          { isRequired },
          campaignQuestionId,
          questionType,
        );

        return question as CampaignQuestion | null;
      } catch (error) {
        console.error({ error });
        throw error;
      }
    },
    [updateCampaignQuestion],
  );

  const toggleIsEnabled = React.useCallback(
    async (
      campaignQuestionId: string | null | undefined,
      isEnabled: boolean,
      questionType: CampaignQuestionType,
    ): Promise<CampaignQuestion | null> => {
      if (!campaignQuestionId) {
        return null;
      }

      try {
        if (questionType === CampaignQuestionType.Default) {
          const question = await updateDefaultCampaignQuestionMutation({
            variables: { data: { isEnabled }, id: campaignQuestionId },
          });

          return question?.data?.defaultCampaignQuestionUpdate as CampaignQuestion | null;
        }

        if (questionType === CampaignQuestionType.Custom) {
          const question = await updateCustomCampaignQuestionMutation({
            variables: { data: { isEnabled }, id: campaignQuestionId },
          });

          return question?.data?.customCampaignQuestionUpdate as CampaignQuestion | null;
        }

        return null;
      } catch (error) {
        console.error({ error });
        throw error;
      }
    },
    [updateCustomCampaignQuestionMutation, updateDefaultCampaignQuestionMutation],
  );

  const toggleEnableAll = React.useCallback(
    async (
      isEnabled: boolean,
      category: CampaignQuestionCategories,
    ): Promise<Array<CampaignQuestion>> => {
      try {
        const defaultQuestionsFilter = {
          filter: {
            campaign: {
              id: {
                equals: campaignId,
              },
            },
            question: {
              isMandatory: {
                not_equals: true,
              },
              category: {
                equals: category,
              },
            },
          },
        };
        const defaultQuestionsUpdateRequest = updateDefaultQuestionByFilterMutation({
          variables: {
            data: {
              isEnabled: [
                {
                  set: isEnabled,
                },
              ],
            },
            ...defaultQuestionsFilter,
          },
        });

        const customQuestionsUpdateRequest = updateCustomQuestionByFilterMutation({
          variables: {
            data: {
              isEnabled: [
                {
                  set: isEnabled,
                },
              ],
            },
            filter: {
              campaign: {
                id: {
                  equals: campaignId,
                },
              },
              category: {
                equals: category,
              },
            },
          },
        });

        const [defaultQuestionUpdateResult, customQuestionUpdateResult] = await Promise.all([
          defaultQuestionsUpdateRequest,
          customQuestionsUpdateRequest,
        ]);

        const defaultQuestions =
          defaultQuestionUpdateResult?.data?.defaultCampaignQuestionUpdateByFilter?.items || [];

        const customQuestions =
          customQuestionUpdateResult?.data?.customCampaignQuestionUpdateByFilter?.items || [];

        return [...defaultQuestions, ...customQuestions] as Array<CampaignQuestion>;
      } catch (error) {
        console.error({ error });
        throw error;
      }
    },
    [campaignId, updateCustomQuestionByFilterMutation, updateDefaultQuestionByFilterMutation],
  );

  const handleSetDefaultQuestions = React.useCallback(
    async (campaignId: string) => {
      const campaignHasNoDefaultQuestions = !loading && _.isEmpty(defaultCampaignQuestions);

      if (!initialDefaultQuestionLoading && campaignHasNoDefaultQuestions) {
        await setDefaultQuestions(campaignId, defaultQuestionItems);
      }
    },
    [
      loading,
      defaultCampaignQuestions,
      defaultQuestionItems,
      initialDefaultQuestionLoading,
      setDefaultQuestions,
    ],
  );

  return {
    data: campaignQuestions,
    campaignHasNoDefaultQuestions,
    handleSetDefaultQuestions,
    error,
    createCampaignQuestion,
    updateCampaignQuestion,
    deleteCampaignQuestion,
    toggleIsRequired,
    toggleIsEnabled,
    toggleEnableAll,
    connectCampaignQuestionToExperiences,
    loading,
    creating,
    updating:
      updatingDefaultQuestion ||
      updatingCustomQuestion ||
      updatingManyCustomQuestions ||
      updatingManyDefaultQuestions,
    deleting,
    settingDefaultQuestions,
  };
};
