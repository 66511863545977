import { calcPercentage, formatMoney, formatPercentage } from '@vizsla/utils';

export const calculateAssetMargin = (price: number, unitCost: number) => {
  const margin = calcPercentage(price - unitCost, price);
  return formatPercentage(margin);
};

export const calculateAssetProfit = (price: number, unitCost: number) => {
  if (unitCost > price) return formatMoney(0);
  const profit = price - unitCost;
  return formatMoney(profit);
};
