import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon, DataArray } from '@mui/icons-material';
import { useCSVReader, useCSVDownloader } from 'react-papaparse';
import { useMutation } from '@apollo/client';

import { useModal, useNotification } from '@vizsla/hooks';
import { Button, Modal } from '@vizsla/components';

import { Shade } from 'src/theme';
import { MODALS } from 'src/constants/modals';
import { IMPORT_CRM_CREATE_MANY_MUTATION } from 'src/graphql';

const validateFilds = [
  'Supporter First Name*',
  'Supporter Last Name*',
  'Email Address*',
  'Phone Number*',
  'Address 1*',
  'City*',
  'State*',
  'Postal Code*',
];

const requiredFields = ['First Name', 'Last Name', 'Email', 'Number'];

const ContactImportModal: React.FC = () => {
  const notification = useNotification();
  const { isOpen, closeModal } = useModal(MODALS.PEOPLE_CRM_IMPORT_MODAL);

  const [data, setData] = React.useState([]);
  const [errorData, setErrorData] = React.useState([]);
  const [isError, setIsError] = React.useState(false);
  const [isLoad, setIsLoad] = React.useState(false);
  const [isDisable, setIsDisable] = React.useState(true);

  const { CSVReader } = useCSVReader();
  const { CSVDownloader, Type } = useCSVDownloader();

  React.useEffect(() => {
    setData([]);
    setErrorData([]);
    setIsDisable(true);
    setIsLoad(false);
  }, [isOpen]);

  const [importUsersFromCSV] = useMutation(IMPORT_CRM_CREATE_MANY_MUTATION, {
    refetchQueries: ['CRMContactsList'],
    awaitRefetchQueries: true,
  });

  const onCloseModal = () => {
    closeModal();
  };

  const fortmatUser = user => {
    const [firstName, lastName, email, number, street1, city, state, zip] = user;
    return {
      firstName,
      lastName,
      email,
      phone1: { code: '1', number },
      address: { street1, city, state, zip },
    };
  };

  const saveUsers = async () => {
    setIsLoad(true);
    try {
      const {
        data: {
          importUsersFromCSV: { success, data: importData },
        },
      } = await importUsersFromCSV({ variables: { data } });

      if (success) {
        notification.success(`${data.length} users created`);
        onCloseModal();
      } else {
        setErrorData(
          importData.map(user => {
            return { row: user.row, message: user.message };
          }),
        );
        notification.error(
          `error in ${importData.length} users download the CSV for more information`,
        );
      }
      setIsLoad(false);
    } catch (e) {
      notification.error('Failed to create users');
      setIsLoad(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onCloseModal}>
      <IconButton
        aria-label="close"
        onClick={onCloseModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#2A394A',
        }}
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <Grid container xs={12} justifyContent="center" alignContent="center">
        <Grid item xs={12} mt="40px">
          <Typography variant="h3" textAlign="center" fontSize="24px">
            Import File
          </Typography>
        </Grid>
        <Grid item xs={12} mt="5px">
          <Typography fontSize="14px" textAlign="center">
            Select your file
          </Typography>
        </Grid>

        <CSVReader
          onUploadAccepted={(results: any) => {
            const filterResult = results.data.filter(
              (item: any) => item[0] !== '\n' && item.length > 1,
            );

            const isColumnsFieldsValid = validateFilds.every(
              (field, index) =>
                field.toLowerCase().replace(/\s/g, '') ===
                results?.data[0][index]?.toLowerCase()?.replace(/\s/g, ''),
            );

            if (isColumnsFieldsValid) {
              const formatData = filterResult.reduce(
                (acum: any, user: any, index: number) =>
                  index > 0 ? [...acum, fortmatUser(user)] : acum,
                [],
              );
              setData(formatData);
              setIsDisable(false);
            } else {
              notification.error('Fields dont match');
            }
          }}
        >
          {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, ...props }: any) => (
            <Grid item mt="50px">
              <TextField
                id="standard-name"
                label="File"
                value={acceptedFile ? acceptedFile?.name : ''}
                disabled
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      {!acceptedFile ? (
                        <Button
                          {...getRootProps()}
                          sx={{ backgroundColor: '#009FB0', color: '#FFFFFF' }}
                        >
                          Browse
                        </Button>
                      ) : (
                        <Button
                          onClick={(event: any) => {
                            setErrorData([]);
                            setIsDisable(true);
                            getRemoveFileProps().onClick(event);
                          }}
                          sx={{ backgroundColor: '#CF362C', color: '#FFFFFF' }}
                        >
                          Remove
                        </Button>
                      )}
                    </React.Fragment>
                  ),
                }}
              />
              <Typography fontSize="12px">Formats: .csv. Maximun size: 10 MB</Typography>
              <ProgressBar style={{ backgroundColor: '#009FB0' }} />
              <Grid container justifyContent="center" mt="30px" mb="60px">
                <Grid item>
                  <Button
                    onClick={onCloseModal}
                    color="inherit"
                    sx={{ backgroundColor: Shade.Gray[50], color: Shade.Gray[600] }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  {!errorData.length ? (
                    <Button
                      loading={isLoad}
                      disabled={isDisable}
                      onClick={saveUsers}
                      sx={{ backgroundColor: Shade.Blue[500] }}
                    >
                      Imports
                    </Button>
                  ) : (
                    <CSVDownloader
                      filename="filename"
                      config={{
                        delimiter: ';',
                      }}
                      data={() => {
                        return errorData;
                      }}
                    >
                      <Button sx={{ backgroundColor: '#CF362C', color: '#FFFFFF' }}>Dowload</Button>
                    </CSVDownloader>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </CSVReader>
      </Grid>
    </Dialog>
  );
};

export { ContactImportModal };
