import React from 'react';
import { Form } from 'react-final-form';
import { FormApi } from 'final-form';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { Button, TextField, SelectField, Field, NumberField } from '@vizsla/components';
import { useModal, useNotification, useCurrentOrganization } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';
import { OrganizationRoles } from '@vizsla/types';
import { useVizslaOrganizationStaffUpdateMutation } from '@vizsla/graphql';

import { t } from 'src/utils/template';
import { MODALS } from 'src/constants/modals';
import { OrganizationEditMemberSchema } from 'src/constants/validationSchemas/organizationAddMember';

type OrganizationMemberEditValues = {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  phone: {
    number: string | undefined;
  };
  role: string | undefined;
};
interface OrganizationMemberEditModalProps {
  refetchUsers: () => void;
}

export const OrganizationMemberEditModal: React.FC<OrganizationMemberEditModalProps> = ({
  refetchUsers,
}) => {
  const { organization } = useCurrentOrganization();
  const { isOpen, closeModal, args } = useModal(MODALS.ORGANIZATION_MEMBER_EDIT_MODAL);

  const notification = useNotification();
  const [vizslaOrganizationStaffUpdateMutation] = useVizslaOrganizationStaffUpdateMutation();

  const rolesArray = Object.values(OrganizationRoles);
  const ROLES_OPTIONS = rolesArray.map(rol => ({
    label: rol || '',
    value: rol || '',
  }));
  const organizationMember = React.useMemo(() => args?.organizationMember || {}, [args]);
  const rolesOrganization = React.useMemo(
    () => organizationMember?.roles || [''],
    [organizationMember],
  );
  const isPending = React.useMemo(() => {
    return organizationMember?.user?.status === 'inactive';
  }, [organizationMember]);

  const onSubmit = async (
    userData: OrganizationMemberEditValues,
    form: FormApi<OrganizationMemberEditValues, OrganizationMemberEditValues>,
  ) => {
    try {
      await vizslaOrganizationStaffUpdateMutation({
        variables: {
          data: {
            user: {
              update: {
                phone: {
                  code: '1',
                  number: userData.phone.number,
                },
              },
            },
            // title: userData.title,
            roles: [userData?.role || ''],
          },
          filter: {
            id: organizationMember?.id,
          },
        },
      });

      notification.success(t('organization_update_member_success'));
      setTimeout(form.reset);
      closeModal();
      refetchUsers();
    } catch (error) {
      notification.error(t('organization_update_member_error'));
      console.error({ error });
    }
  };

  const INITIAL_VALUES: OrganizationMemberEditValues = React.useMemo(
    () => ({
      firstName: organizationMember?.user?.firstName,
      lastName: organizationMember?.user?.lastName,
      email: organizationMember?.user?.email,
      phone: {
        number: organizationMember?.user?.phone?.number,
      },
      status: isPending ? 'Pending' : '',
      role: rolesOrganization[0],
    }),
    [organizationMember, rolesOrganization, isPending],
  );
  const onCloseModal = () => {
    closeModal();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onCloseModal}>
      <DialogTitle>
        <Grid>
          <IconButton
            aria-label="close"
            onClick={onCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
            size="large"
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid marginTop={4}>
          <Typography variant="h3" textAlign="center">
            Edit Team Member
          </Typography>
          <Typography variant="body2" textAlign="center">
            Edit team member in {organization?.name || ''}.
          </Typography>
        </Grid>
      </DialogTitle>
      <Form
        initialValues={INITIAL_VALUES}
        onSubmit={onSubmit}
        validate={values => validateWithSchema(OrganizationEditMemberSchema, values)}
      >
        {({ handleSubmit, submitting, hasValidationErrors, form }) => {
          const isLoading = submitting;
          const isSubmitDisabled = isLoading || hasValidationErrors;
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent sx={{ padding: theme => theme.spacing(4, 8, 4, 8) }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="firstName"
                      label="First Name"
                      disabled
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      name="lastName"
                      label="Last Name"
                      component={TextField}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      label="Email Address"
                      component={TextField}
                      e2e-id="email"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="phone.number"
                      label="Phone Number"
                      required
                      formatMask="+1 (###) ###-####"
                      isNumericString
                      component={NumberField}
                      fullWidth
                    />
                  </Grid>
                  {isPending ? (
                    <Grid item xs={12}>
                      <Field
                        name="status"
                        label="User Status"
                        component={TextField}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  ) : null}
                  {/*  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name="title"
                      label="Title"
                      required
                      component={TextField}
                      disabled={isLoading}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <Field
                      name="role"
                      label="Rol"
                      component={SelectField}
                      options={ROLES_OPTIONS}
                      disabled={submitting}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ padding: theme => theme.spacing(0, 0, 4, 0) }}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      variant="text"
                      size="large"
                      color="info"
                      style={{ width: 100 }}
                      onClick={onCloseModal}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      style={{ width: 100 }}
                      size="large"
                      color="primary"
                      onClick={handleSubmit}
                      loading={isLoading}
                      disabled={isSubmitDisabled}
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </form>
          );
        }}
      </Form>
    </Dialog>
  );
};
