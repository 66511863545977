import React from 'react';
import { isNil } from 'lodash';

import {
  StoreAssetsQuery,
  StoreAssetsDocument,
  StoreAssetCreateInput,
  StoreAssetsQueryVariables,
  useStoreAssetCreateMutation,
  useGetAllAssetsByOrganizationListQuery,
  useGetAllFileListQuery,
  useStoreGhostItemCreateMutation,
} from '@vizsla/graphql';
import { useApolloCacheQueryManager } from '@vizsla/hooks';
import { mutableList } from '@vizsla/utils';

import type { StoreAssetSimple, DashboardStoreAsset } from 'src/types/storeAssets';

const mapStoreAssetsToDashboard = (storeAssets: any[]): DashboardStoreAsset[] => {
  return storeAssets.map(storeAsset => {
    return {
      id: storeAsset.id || '',
      name: storeAsset.name || '',
      price: storeAsset.unitPrice || 0,
      imageUrl: storeAsset.imageUrl || 0,
      countOfTotal: storeAsset.total || 0,
      countOfAvailable: storeAsset.aviable || 0,
      salesAmount: storeAsset.sales || 0,
      profitAmount: storeAsset.profit || 0,
    };
  });
};

type StoreAssetsDashboardDataHookResult = {
  storeAssets: Array<DashboardStoreAsset>;
  storeAssetsCount: number;
  creating: boolean;
  loading: boolean;
  createStoreAsset: (storeAsset: StoreAssetCreateInput) => Promise<void>;
};

export const useStoreAssetsDashboardData = (
  organizationId?: string,
  storeAssetsQueryOptions = {},
  filterQueryVariables = {},
): StoreAssetsDashboardDataHookResult => {
  let AllAssetsData: any = [];

  const storeAssetsVariables = {
    filter: {
      vizslaOrganization: {
        id: {
          equals: organizationId,
        },
      },
    },
  };

  const storeAllAssetsVariables = {
    filter: { organizationId: { equals: organizationId }, ...filterQueryVariables },
    ...storeAssetsQueryOptions,
  };

  const {
    data: assets,
    loading: loadAssets,
    refetch,
  } = useGetAllAssetsByOrganizationListQuery({
    variables: storeAllAssetsVariables,
    skip: !organizationId,
  });

  const searchIds =
    assets?.getAllAssetInventoriesList?.items?.map(({ picture }) => picture || ('' as any)) || [];

  const { data: assetsPictures, loading: loadAssetsPictures } = useGetAllFileListQuery({
    variables: { filter: { id: { in: searchIds } } },
  });

  if (assetsPictures?.filesList?.items.length && assets?.getAllAssetInventoriesList?.items.length) {
    const data = assets.getAllAssetInventoriesList.items.map((asset: any) => {
      const value = asset;
      const image = assetsPictures.filesList.items.filter(itF => itF.id === asset.picture);
      return { ...value, imageUrl: image[0]?.downloadUrl } as any;
    });
    AllAssetsData = mapStoreAssetsToDashboard(data);
  }

  const { updateQuery: updateStoreAssetsQuery } = useApolloCacheQueryManager<
    StoreAssetsQuery,
    StoreAssetsQueryVariables
  >({
    query: StoreAssetsDocument,
    typeName: 'StoreAssetListResponse',
    variables: storeAssetsVariables,
  });

  const [storeAssetCreateMutation, { loading: creating }] = useStoreAssetCreateMutation();

  const [storeInventoryItemCreate] = useStoreGhostItemCreateMutation();

  const storeAssetsCount = assets?.getAllAssetInventoriesList.count || 0;

  const addStoreAssetToCache = React.useCallback(
    (rawNewStoreAsset: StoreAssetSimple) => {
      updateStoreAssetsQuery(query => {
        mutableList(query.storeAssetsList.items).addToStart(rawNewStoreAsset);
        query.storeAssetsList.count += 1;
      });
    },
    [updateStoreAssetsQuery],
  );

  const createStoreAsset = React.useCallback(
    async (inputData: StoreAssetCreateInput) => {
      const { data } = await storeAssetCreateMutation({
        variables: { data: inputData },
      });

      if (isNil(data)) {
        throw new Error('Error while creating store asset');
      }

      const itemInput = {
        itemToSellWhenOutOfStock: data.storeAssetCreate.continueSalesWhenOutOfStock,
        storeAsset: { connect: { id: data.storeAssetCreate.id } },
        countOfTotal: 0,
      };
      const { data: itemData } = await storeInventoryItemCreate({
        variables: { data: itemInput },
      });

      if (isNil(itemData)) {
        throw new Error('Error while creating store  asset out of Stock');
      }

      refetch();
    },
    [addStoreAssetToCache, storeAssetCreateMutation],
  );

  const isLoading = loadAssets || loadAssetsPictures;

  return {
    storeAssets: AllAssetsData,
    storeAssetsCount,
    loading: isLoading,
    creating,
    createStoreAsset,
  };
};
