import React from 'react';

import {
  ExperienceStoreAssetsListQueryVariables,
  GetAssetByExperiencieViewsListQueryVariables,
  useGetAllFileListQuery,
  useGetAssetByExperiencieViewsListQuery,
} from '@vizsla/graphql';

import { useExperienceId } from 'src/hooks/experiences';
import type { ExperienceStoreAsset } from 'src/types/storeAssets';

interface ExperienceStoreDataProviderContextType {
  queryVariables: ExperienceStoreAssetsListQueryVariables;
  experienceStoreAssets: ExperienceStoreAsset[];
  isExperienceStoreAssetsLoading: boolean;
  assetsIds: string[] | [];
  refetch: () => void;
}

const ExperienceStoreDataProviderContext = React.createContext({
  isExperienceStoreAssetsLoading: false,
} as ExperienceStoreDataProviderContextType);

const ExperienceStoreDataProvider: React.FC = ({ children }) => {
  const experienceId = useExperienceId();
  let AllAssetsData: any = [];

  const queryVariables = React.useMemo(() => {
    const queryVariables: ExperienceStoreAssetsListQueryVariables = {
      filter: {
        experience: {
          id: { equals: experienceId },
        },
      },
    };

    return queryVariables;
  }, [experienceId]);

  const queryAssetsVariables = React.useMemo(() => {
    const queryVariables: GetAssetByExperiencieViewsListQueryVariables = {
      filter: {
        experienceId: { equals: experienceId },
      },
    };

    return queryVariables;
  }, [experienceId]);

  const {
    data: assets,
    loading: loadAssets,
    refetch,
  } = useGetAssetByExperiencieViewsListQuery({
    variables: queryAssetsVariables,
  });

  const assetsIds = (assets?.getAssetByExperiencieViewsList?.items?.map(
    ({ assetId }) => assetId as string,
  ) || []) as string[];

  const searchIds =
    assets?.getAssetByExperiencieViewsList?.items?.map(({ picture }) => picture || ('' as any)) ||
    [];

  const { data: assetsPictures, loading: loadAssetsPictures } = useGetAllFileListQuery({
    variables: { filter: { id: { in: searchIds } } },
  });

  if (
    assetsPictures?.filesList?.items.length &&
    assets?.getAssetByExperiencieViewsList?.items.length
  ) {
    AllAssetsData = assets.getAssetByExperiencieViewsList.items.map((asset: any) => {
      const value = asset;
      const image = assetsPictures.filesList.items.filter(itF => itF.id === asset.picture);
      return {
        id: value.experienceStoreAssetId,
        price: value.price,
        storeAsset: {
          id: value.assetId,
          name: value.name,
          description: value.description,
          price: value.assetPrice,
          image: { downloadUrl: image[0]?.downloadUrl },
          available: value.available,
          total: value.total,
        },
      } as any;
    });
  }

  const isLoading = loadAssets || loadAssetsPictures;

  return (
    <ExperienceStoreDataProviderContext.Provider
      value={{
        queryVariables,
        experienceStoreAssets: AllAssetsData || [],
        isExperienceStoreAssetsLoading: isLoading,
        assetsIds,
        refetch,
      }}
    >
      {children}
    </ExperienceStoreDataProviderContext.Provider>
  );
};

export { ExperienceStoreDataProvider, ExperienceStoreDataProviderContext };
