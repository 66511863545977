import { gql } from '@apollo/client';

import { STORE_ASSET_FRAGMENT } from './storeAsset';

export const REGISTRATION_OPTION_FRAGMENT = gql`
  fragment RegistrationOptionFragment on RegistrationOption {
    id
    name
    distance
    unitOfMeasure
    startWaveOrder
    description
    fundraisingMinimum
    ageRequirement
    ageRequirementAsOfDate
    attendeesPerRegistration
    maximumRegistrationPerOrder
    groupRegistrationMinimum
    groupRegistrationMaximum
    maximumAttendees
    fundraisingMinimum
    checkpointAmount
    checkpointDate
    checkpointNotification
    registrationStartDate
    registrationEndDate
    attendees {
      count
    }
    pricingSettings {
      id
      allowCostToBeFundraisingCredit
      pricingEnabled
      pricingType
      pricingTiers {
        items {
          id
          price
          taxDeductibleCurrencyAmount
          taxDeductiblePercentsAmount
          taxDeductibleType
          startDate
          endDate
        }
        count
      }
    }
  }
`;

export const REGISTRATION_OPTION_QUERY = gql`
  query RegistrationOption($id: ID!) {
    registrationOption(id: $id) {
      ...RegistrationOptionFragment
    }
  }
  ${REGISTRATION_OPTION_FRAGMENT}
`;

export const REGISTRATION_OPTION_UPDATE_MUTATION = gql`
  mutation RegistrationOptionUpdate($data: RegistrationOptionUpdateInput!) {
    registrationOptionUpdate(data: $data) {
      ...RegistrationOptionFragment
    }
  }
  ${REGISTRATION_OPTION_FRAGMENT}
`;

export const REGISTRATION_OPTIONS_LIST_QUERY = gql`
  query RegistrationOptionsList($filter: RegistrationOptionFilter) {
    registrationOptionsList(filter: $filter) {
      count
      items {
        ...RegistrationOptionFragment
      }
    }
  }
  ${REGISTRATION_OPTION_FRAGMENT}
`;

export const REGISTRATION_OPTION_DELETE_MUTATION = gql`
  mutation RegistrationOptionDelete($id: ID!) {
    registrationOptionDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;

export const REGISTRATION_OPTION_CREATE_MUTATION = gql`
  mutation RegistrationOptionCreate($data: RegistrationOptionCreateInput!) {
    registrationOptionCreate(data: $data) {
      ...RegistrationOptionFragment
    }
  }
  ${REGISTRATION_OPTION_FRAGMENT}
`;

export const REGISTRATION_OPTION_SWAG_BAG_CREATE_MUTATION = gql`
  mutation registrationOptionSwagbagCreate($data: RegistrationOptionSwagbagCreateInput!) {
    registrationOptionSwagbagCreate(data: $data) {
      id
    }
  }
`;

export const REGISTRATION_OPTION_SWAG_BAG_LIST_QUERY = gql`
  query registrationOptionSwagbagList($filter: RegistrationOptionSwagbagFilter!) {
    registrationOptionSwagbagsList(filter: $filter) {
      items {
        id
        storeAsset {
          ...StoreAssetFragment
        }
      }
    }
  }
  ${STORE_ASSET_FRAGMENT}
`;

export const REGISTRATION_OPTION_SWAG_BAG_DELETE_MUTATION = gql`
  mutation registrationOptionSwagbagDelete($filter: RegistrationOptionSwagbagKeyFilter!) {
    registrationOptionSwagbagDelete(filter: $filter) {
      success
    }
  }
`;

export const REGISTRATION_OPTION_SWAG_BAG_LIST_INVENTORY_QUERY = gql`
  query registrationOptionSwagbagListInventory($filter: GetAllAssetInventoryFilter!) {
    getAllAssetInventoriesList(filter: $filter) {
      items {
        id
        aviable
        swagbagAvailability
      }
    }
  }
`;
