import React from 'react';
import { Box, List, ListItemIcon, Paper, Skeleton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Shade } from '@vizsla/theme';
import { NavItem } from '@vizsla/components';

import { VIZSLA_LOGO } from 'src/assets';
import { useDrawerContext } from 'src/providers/DrawerProdiver';

interface StyleProps {
  isExpanded: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  root: ({ isExpanded }) => ({
    gridArea: 'sidebar',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(1),
    maxWidth: isExpanded ? 300 : 70,
    minWidth: isExpanded ? 240 : 70,
    zIndex: 3,
    backgroundColor: theme.customPalette.nav.item.background.primary,
  }),

  logo: {
    objectFit: 'contain',
    display: 'flex',
    padding: theme.spacing(0, 0, 2.5),
    height: 76,
    width: '100%',
  },

  powered8base: {
    justifyContent: 'center',
  },
}));

type ItemT = { title: string; icon: string; path: string };
export const DrawerSidebar: React.FC<{
  isLoading?: boolean;
  upperItems: ItemT[];
  lowerItems: ItemT[];
}> = ({ isLoading, upperItems, lowerItems }) => {
  const { isOpen } = useDrawerContext();
  const classes = useStyles({ isExpanded: isOpen });

  if (isLoading) {
    return (
      <Paper className={classes.root} square elevation={4}>
        <Skeleton sx={{ bgcolor: Shade.Gray[400] }} height="100%" width="100%" />
      </Paper>
    );
  }

  return (
    <Paper className={classes.root} square elevation={4}>
      <List disablePadding>
        <ListItemIcon>
          <img src={VIZSLA_LOGO} className={classes.logo} alt="Vizsla logo" />
        </ListItemIcon>
        {upperItems.map(item => {
          return (
            <NavItem
              key={item.path}
              to={item.path}
              icon={item.icon as any}
              title={item.title}
              isExpanded={isOpen}
            />
          );
        })}
      </List>
      <Box flexGrow={1} />
      <List disablePadding>
        {lowerItems.map(item => {
          return (
            <NavItem
              key={item.path}
              to={item.path}
              icon={item.icon as any}
              title={item.title}
              isExpanded={isOpen}
            />
          );
        })}
      </List>
    </Paper>
  );
};
