import { gql } from '@apollo/client';

export const VIZSLA_ORGANIZATION_UPDATE_MUTATION = gql`
  mutation VizslaOrganizationUpdate($data: VizslaOrganizationUpdateInput!) {
    vizslaOrganizationUpdate(data: $data) {
      id
    }
  }
`;

export const ORGANIZATIONS_QUERY = gql`
  query OrganizationsList($filter: VizslaOrganizationFilter, $skip: Int, $first: Int) {
    vizslaOrganizationsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        name
        createdAt
        createdBy {
          id
          lastName
          firstName
        }
        vizslaOrganizationStaff {
          count
          items {
            id
            roles
            user {
              firstName
              lastName
            }
          }
        }
        isActive
        campaigns {
          count
          items {
            id
            campaignStaff {
              count
            }
            donors {
              count
              items {
                id
                user {
                  id
                }
              }
            }
            experiences {
              count
              items {
                id
                attendees {
                  count
                  items {
                    id
                    user {
                      id
                    }
                  }
                }
                fundraisers: attendees(filter: { fundraisingPageLink: { is_not_empty: true } }) {
                  count
                  items {
                    id
                    user {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const VIZSLA_TOTAL_METRICS_QUERY = gql`
  query VizslaTotalMetrics {
    vizslaOrganizationsList {
      count
    }
    campaignsList {
      count
    }
    usersList {
      count
    }
    experiencesList {
      count
    }
  }
`;
